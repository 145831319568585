export function setLocalStorageWithExpiration(key, value, expirationInHours) {
    const now = new Date();
    const expirationTime = now.getTime() + expirationInHours * 60 * 60 * 1000;
    const item = {
        value: value,
        expirationTime: expirationTime
    };

    localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpiration(key) {
  
    const item = JSON.parse(localStorage.getItem(key));
    if (!item) {
        return false; 
    }

    const now = new Date().getTime();
    
    if (now > item.expirationTime) {
      
        localStorage.removeItem(key); 
        return false;
    }

    return true;
}