<section *ngIf="ap$ | async as ap" [class]="'is-' + ap">
  <div [ngSwitch]="ap">
    <div *ngSwitchCase="'mobile'">
      <div class="slider-wrapper">
        <div class="levels-boundary">
          <div class="controls-lockup">
            <div class="control control-left">
              <img *ngIf="activeSlide > 0" src="assets/images/rain-one/speed_level_up_prev_btn.png" alt="prev" (click)="prev()" />
            </div>
            <div class="control control-right">
              <img *ngIf="activeSlide < levels.length - 1" src="assets/images/rain-one/speed_level_up_next_btn.png" alt="next" (click)="next()" />
            </div>
          </div>
          <ul class="wifi-level-wrapper">
            <li
              *ngFor="let level of levels; index as i; first as isFirst"
              [id]="i"
              class="level-container"
              [attr.selectable]="isSelectable"
              [ngClass]="{
                selected: level.selected,
                'is-included': level.includedInSelection,
                'is-active-slide': i === activeSlide,
                'is-next-slide': i === activeSlide + 1,
                'is-prev-slide': activeSlide !== 0 && i === activeSlide - 1
              }"
            >
              <div class="level-card" [class.isSelectable]="isSelectable" (click)="setSelectedLevel(level.id)">
                <div class="header ">
                  <span>{{ level.level }}Mbps</span>
                </div>

                <div class="body">
                  <h2><span *ngIf="isWorkProduct">work</span> <span *ngIf="!isWorkProduct">home</span> wifi speed</h2>

                  <div class="speed-description-lockup">
                    <span>up to</span>
                    <h3>{{ level.speed }}</h3>

                    <p>
                      <span>Mbps</span><br />
                      download speed
                    </p>
                    <p *ngIf="level.level === '30'">included with <img src="/assets/images/rain-one/rain-one-logo.svg" alt="" /></p>
                  </div>
                </div>

                <div class="footer">
                  <p [innerHTML]="level.footerText"></p>
                </div>

                <div class="selected-overlay">
                  <div class="overlay">
                  </div>
                 
                  <!-- <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_143_2038)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M61 98C85.8528 98 106 77.8528 106 53C106 28.1472 85.8528 8 61 8C36.1472 8 16 28.1472 16 53C16 77.8528 36.1472 98 61 98Z"
                        fill="url(#paint0_linear_143_2038)"
                      />
                    </g>
                    <mask id="mask0_143_2038" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="16" y="8" width="90" height="90">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M61 98C85.8528 98 106 77.8528 106 53C106 28.1472 85.8528 8 61 8C36.1472 8 16 28.1472 16 53C16 77.8528 36.1472 98 61 98Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_143_2038)"></g>
                    <g filter="url(#filter1_i_143_2038)">
                      <path
                        d="M86 34.5093L77.758 28L52.195 62.5915L43.7315 55.036L37 62.8755L53.8645 78L59.6896 70.1176L86 34.5093Z"
                        fill="url(#paint1_linear_143_2038)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_143_2038"
                        x="0"
                        y="0"
                        width="122"
                        height="122"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="8" />
                        <feGaussianBlur stdDeviation="8" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.166242 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_143_2038" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_143_2038" result="shape" />
                      </filter>
                      <filter
                        id="filter1_i_143_2038"
                        x="37"
                        y="28"
                        width="49"
                        height="52"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_143_2038" />
                      </filter>
                      <linearGradient id="paint0_linear_143_2038" x1="61" y1="8" x2="61" y2="98" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#0077C8" />
                        <stop offset="0.5625" stop-color="#095691" />
                        <stop offset="1" stop-color="#142C4A" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_143_2038" x1="95.5" y1="118" x2="32.0932" y2="-9.04746" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#EEEEEE" />
                      </linearGradient>
                    </defs>
                  </svg> -->
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="radio-controls">
          <ul class="radio-wrapper">
            <li
              *ngFor="let level of levels; index as i; first as isFirst"
              (click)="selectslide(i)"
              [ngClass]="{ 'is-active-control': i === activeSlide }"
            >
              <span></span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngSwitchDefault>
      <ul class="wifi-level-list">
        <ng-template ngFor let-level [ngForOf]="levels">
          <li
            class="level-container"
            [attr.selectable]="isSelectable"
            [class.selected]="level.selected"
            [class.is-included]="level.includedInSelection"
          >
            <div class="level-card" (click)="setSelectedLevel(level.id)">
              <div class="header">
                <span>{{ level.level }}Mbps</span>
              </div>

              <div class="body">
                <h2>5G <span *ngIf="isWorkProduct">work</span> <span *ngIf="!isWorkProduct">home</span> wifi speed</h2>

                <!-- {{speedCondition}} -->
                <div class="speed-description-lockup">
                  <span>up to</span>
                  <h3>{{ level.speed }}</h3>
                  <p>
                    <span>Mbps</span><br />
                    download speed
                  </p>
                  <p *ngIf="level.level === '30'">included with <img src="/assets/images/rain-one/rain-one-logo.svg" alt="" /></p>
                </div>
              </div>

              <!-- <div class="footer">
                                <p [innerHTML]="level.footerText"></p>
                            </div> -->

              <div class="selected-overlay">
                <div class="overlay"></div>
              </div>
            </div>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</section>
