import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'rain-cancel-nvidia-confirmation-modal',
  templateUrl: './cancel-nvidia-confirmation-modal.component.html',
  styleUrls: ['./cancel-nvidia-confirmation-modal.component.scss']
})
export class CancelNvidiaConfirmationModalComponent {

  constructor(
    public activeModal: NgbActiveModal
  ) { }


  close() {
    this.activeModal.close()

  }
}
