import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CheckCoverageModule } from '@components/check-coverage/check-coverage.module';
import { ISearchResult } from '@components/map-search/map-search.component';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '@models/constants';
import { DataLayerService } from '@services/data-layer.service';
import { CoverageMapModes, GeoService } from '@services/geo.service';
import { isNil } from 'lodash';
import MarkerClustered from '@google/markerclustererplus';
import { BehaviorSubject } from 'rxjs';


const DEFAULT_ZOOM_LEVEL = 14;

@Component({
  selector: 'rain-our-network',
  templateUrl: './our-network.component.html',
  styleUrls: ['./our-network.component.scss']
})
export class OurNetworkComponent implements OnInit {

  @ViewChild('disclaimerCopy', {static: false}) disclaimerCopy:ElementRef

  public latitude: number = DEFAULT_LATITUDE;

  public longitude: number = DEFAULT_LONGITUDE;
   hasAddress: boolean;
   lastAddress: string;
   addressFound = false;

   coverageMapModes = CoverageMapModes

   selectedMode$ = new BehaviorSubject<CoverageMapModes>(CoverageMapModes.BothFourAndFiveG);

   @Input() zoom: number = DEFAULT_ZOOM_LEVEL;
   map: google.maps.Map;
   public minZoom: number = 5;
   public maxZoom: number = 16;

   @Input()
  public mode: CoverageMapModes;
  markerCluster: MarkerClustered;

  constructor( private dataLayerService: DataLayerService,
    private geoService: GeoService) { }

  ngOnInit(): void {
  }

  mapReady(map) {
    this.map = map;
    map.setOptions({ minZoom: this.minZoom, maxZoom: this.maxZoom });
    this.applyCoverageTile();
  }

  applyCoverageTile() {
    for (const layer of this.geoService.getCoverageLayers(this.mode)) {
      this.map.overlayMapTypes.push(layer);
    }
  }

  inputFocused() {
    this.hasAddress = false;
  }

  handleMapSearch(result: ISearchResult) {
    this.dataLayerService.coverageMapSearch('4G|5G');
    if (isNil(result) == false) {

      this.latitude = result.latitude;

      this.longitude = result.longitude;

      this.lastAddress = result.address;

      this.hasAddress = true;

    }
  }

  search() {
    if (this.hasAddress) {
      this.addressFound = true;
      // this.check5GCoverage();
    } else {
      this.addressFound = false;
    }

  }

  updateSelectionBtn(e:Event, type:CoverageMapModes) {
    this.selectedMode$.next(type)

  }

  showHideDisclaimerCopy() {
      const div = this.disclaimerCopy.nativeElement;

      if (div.classList.contains('is-hidden')) {
        div.classList.remove('is-hidden');
      }
      else {
        div.classList.add('is-hidden');
      }
  }
}
