import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {tap} from "rxjs/operators";
import {DeviceServiceV2} from "../service/device.service";
import {GetDeviceDetails} from "../actions/device.action";

export interface DeviceStateModel {
    deviceDetails: {};
}

@State<DeviceStateModel>({
    name: 'device',
    defaults: {
        deviceDetails: null,
    }
})
@Injectable({
    providedIn: 'root'
})
export class DeviceState {
    constructor(
        private store: Store,
        private deviceService: DeviceServiceV2,
    ) {
    }

    @Selector()
    static getDeviceDetails(state: DeviceStateModel) {
        return state.deviceDetails;
    }

    @Action(GetDeviceDetails)
    setDeviceDetails(ctx: StateContext<DeviceStateModel>, action: GetDeviceDetails) {
        return this.deviceService.getDeviceImage(action.msisdn).pipe(
            tap((response: any) => {
                if (response) {
                    const state = ctx.getState();
                    const updatedDeviceDetails = {...state.deviceDetails, [action.msisdn]: { image: response.phone_image, imei: response.imei }};
                    ctx.patchState({ deviceDetails: updatedDeviceDetails });
                }
            })
        );
    }
}
