import { Utils } from "src/app/utils";


export class InitAdBlockerChecker {
    static readonly type = Utils.Helpers.Type('[Core: AdBlockcer] Init AdBlocker checker');
    constructor() { }
}

export class InitAdBlockerCheckerSuccess {
    static readonly type = Utils.Helpers.Type('[Core: AdBlockcer] Init AdBlocker checker Success');
    constructor(public readonly payload: boolean) { }
}

export class ToggleAdBlockerModal {
    static readonly type = Utils.Helpers.Type('[Core: AdBlockcer] Toggle AdBlocker modal');
    constructor(public readonly payload: boolean) { }
}
