import { Utils } from '@Utils';

export class SetMobileSimsAmount {
    static type = Utils.Helpers.Type('[SIMS] Set Amount');
    constructor(public simAmount: number) { }
}

export class SetSimPrices {
    static type = Utils.Helpers.Type('[SIMS] Set Sim Prices');
    constructor() { }
}

export class SetMobileGigAmount {
    static type = Utils.Helpers.Type('[Gig] Set Amount');
    constructor(public gigAmount: number) { }
}
