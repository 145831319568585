import { BillingStoreService } from 'src/app/store/billing-store.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty, isNil, some } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { IProductDetail } from '@models/productDetail';
import { Statuses } from '@models/result';
import { IServiceDetail } from '@models/serviceDetail';
import { ConfigService } from '@services/config.service';
import { ProductService } from '@services/product.service';
import { UserService } from '@services/user.service';
import { SurveyModalComponent } from './survey-modal/survey-modal.component';
import moment from 'moment';
import { Select, Store } from '@ngxs/store';
import { CoreState } from 'src/app/core/store/state/core.state';
import { Observable } from 'rxjs';
import { ServicesState } from 'src/app/store/state/services.state';
import { BillingState } from 'src/app/core/store/state/billing.state';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss']
})
export class CancelModalComponent extends BaseComponent implements OnInit {

  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;
  @Select(ServicesState.hasPostPaid) hasPostPaid$: Observable<boolean>;
  @ViewChild('conflict', { static: true })
  CUSTOMER_A_PRODUCT_ID = '89dd4305-8f41-4d86-a097-416e1046e865';
  private twoForOneProduct = false;
  private conflict: TemplateRef<any>;
  public loading = true;
  id: string;
  message: string;
  service: IServiceDetail;
  type: string;
  billingDate: Date;
  currentLastDayOfTheMonthFullDate = moment().clone().endOf('month').format('DD MMMM yyyy');
  hasScheduledAction: boolean;
  public isMobileScreen: boolean;
  private primaryUserId: string;
  private primaryServiceId: string;
  products: IProductDetail[];
  upFrontBillingDate: string;

  constructor(

    private userService: UserService,
    private toastr: ToastrService,
    private configService: ConfigService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private productService: ProductService,
    public billingStore: BillingStoreService,
    private store: Store
  ) {
    super();
    this.isMobileScreen = window.innerWidth <= 560;
    setTimeout(() => {
      this.loading = false;
    },100);
  }

  ngOnInit() {
    let chosenPayDate = this.store.selectSnapshot(BillingState.getPaymentDate);
    let nextBillCycle;

    let todayMonth = moment().month();
    let todayDay = moment().date();
    let todayYear = moment().year();
    if (Number(+chosenPayDate.selectedPaymentDate > todayDay)) {
      nextBillCycle = moment({ date: Number(chosenPayDate.selectedPaymentDate), month: moment().month(), year: todayYear })
    } else {
      nextBillCycle = moment({ date: Number(chosenPayDate.selectedPaymentDate), month: todayMonth + 1, year: todayYear })
    }
    this.upFrontBillingDate = `${chosenPayDate.selectedPaymentDate}  
      ${(nextBillCycle)
      .startOf('month')
      .format('MMMM')
      .toString()} ${(nextBillCycle)
        .startOf('year')
        .format('YYYY')
        .toString()} 
      `;

    this.dateGenerator();

    this.populate();
  }

  static show(id: string, modalService: NgbModal, type, isRainOne = false) {
    const options: NgbModalOptions = { size: <any>'confirm', windowClass: 'slideInUp', centered: true };
    const modalRef = modalService.open(CancelModalComponent, options);
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.isRainOne = isRainOne;
  }

  populate() {

    this.productService.get()
      .pipe(switchMap(result => {

        if (result.status === Statuses.Success) {
          this.products = result.value;
        }
        else {
          this.products = [];
        }
        return this.userService
          .getService(this.id)
      }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(result => {

        if (result.status != Statuses.Success) {

          this.toastr.error('An error occurred while the service', null);

          return;

        } else {

          this.service = result.value;

          if (this.service.productId === '89dd4305-8f41-4d86-a097-416e1046e865') {
            this.twoForOneProduct = true;
            this.getVoucherDetails(this.service?.metadata?.payload.sales_order_line_id)
          }
          
          if (this.service.productId == this.configService.GOT_SIM_ID ||
            this.service.productId == this.configService.NEW_GOT_SIM_ID) {

            this.hasScheduledAction = false;

          } else {

            if (isEmpty(this.service.scheduledAction) == false) {

              const addonContainsScheduleAction = some(this.service.addons, addon => {

                const addonProduct = this.products.find(x => x.id == addon.id);

                if (isNil(addonProduct)) {
                  return false;
                }

                return this.service.scheduledAction.name == addonProduct.scheduledActionName;

              });

              if (addonContainsScheduleAction) {

                this.hasScheduledAction = true;

              } else {

                this.hasScheduledAction = false;
              }
            }
          }
        }
      });
  }

  getVoucherDetails(sales_order_line_id) {
    this.userService.getAssociatedPromoService(sales_order_line_id).subscribe((serviceResult) => {
      if (serviceResult?.value) {
        this.primaryUserId = serviceResult.value.primaryUserId;
        this.primaryServiceId = serviceResult.value.primaryServiceId;
      }
    });
  }

  cancelSIM() {
    this.activeModal.dismiss();
    const serviceId = this.service.id;
    const productId = this.service.productId;
    const msisdn = this.service.msisdn ? this.service.msisdn : '';
    
    const options: NgbModalOptions = { size: <any>'confirm', windowClass: 'slideInUp', centered: true };
    const modalRef = this.modalService.open(SurveyModalComponent, options);
    modalRef.componentInstance.serviceId = serviceId;
    modalRef.componentInstance.simName = this.service.name;
    modalRef.componentInstance.msisdn = msisdn;
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.productId = productId;
    modalRef.componentInstance.twoForOneProduct = this.twoForOneProduct;
    modalRef.componentInstance.primaryUserId = this.primaryUserId;
    modalRef.componentInstance.primaryServiceId = this.primaryServiceId;
  }

  dateGenerator() {
    const date = new Date();
    this.billingDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  }

}
