import { Injectable } from "@angular/core";
import { PRIMARY_5G_DUO_PRODUCT_ID } from "@models/constants";
import { Statuses } from "@models/result";
import { IServiceDetail, ServiceObject } from "@models/serviceDetail";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Navigate } from "@ngxs/router-plugin";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { SuccessErrorModalComponent } from "@pages/service-page/service-actions/success-error-modal/success-error-modal.component";
import { MigrateToRainOneService } from "@services/migrate-to-rain-one.service";
import { UserService } from "@services/user.service";
import { tap } from "rxjs/operators";
import { CancellationSuccessFiveGModalComponent } from "../../components/modals/cancellation-success-five-g-modal/cancellation-success-five-g-modal.component";
import { CancellationSuccessFourGModalComponent } from "../../components/modals/cancellation-success-four-g-modal/cancellation-success-four-g-modal.component";
import { CloseBottomSheet, ShowBottomSheet } from "../actions/bottom-sheet.actions";
import { CancelLegacyService, CancelRainOneService, ClearCancellationState, NavigateToCancellationSurvey, NavigateToCollectionConfirmation, PreCancel5GService, Revoke4GServiceCancellation, RevokePreCancel5GService,  SetServiceCancellationDetails, ShowCancellationSuccessMessage, StoreCancellationSurveyRequest } from "../actions/cancellation.actions";
import { ReverseCancellationModalComponent } from "../../components/modals/reverse-cancellation-modal/reverse-cancellation-modal.component";
import { GetAllServicesV2 } from "../actions/services.action";

interface CancellationStateModel {
	service: ServiceObject;
	cancellationSurveyPayload: any;
}

@State<CancellationStateModel>({
	name: 'cancellation',
	defaults: {
		service: null,
		cancellationSurveyPayload: null
	}
})

@Injectable({
	providedIn: 'root'
})
export class CancellationState {

	@Selector()
	static getCancellationService(state: CancellationStateModel) { return state?.service }

	@Selector([CancellationState.getCancellationService])
	static getCancellationServiceId(state: CancellationStateModel, service: IServiceDetail) { return service?.id }

	@Selector([CancellationState.getCancellationService])
	static getCancellationServiceProductType(state: CancellationStateModel, service: IServiceDetail) { return service?.product?.category?.toString() }

	@Selector([CancellationState.getCancellationServiceId])
	static isCancellationService2For1(state: CancellationStateModel, serviceId: string) { return serviceId === PRIMARY_5G_DUO_PRODUCT_ID }

	@Selector([CancellationState.getCancellationServiceProductType])
	static isCancellationService5G(state: CancellationStateModel, productType: string) { return productType === '5G' }

	@Selector([CancellationState.getCancellationServiceProductType])
	static getSurveyName(state: CancellationStateModel, productType: string) { return productType === '4G' ? 'Cancellation_4G' : 'Cancellation_5G' }

	@Selector([CancellationState.getCancellationService])
	static isCancellationServiceRainOne(state: CancellationStateModel, service: IServiceDetail) { 
		return service?.productName.toLowerCase().includes('4g mobile phone') || service?.productName.toLowerCase().includes('rainone');
	}

	@Selector([CancellationState.isCancellationServiceRainOne, CancellationState.getCancellationServiceProductType])
	static isCancellationService4GRainOne(state: CancellationStateModel, isRainOne: boolean, productType: string) { return isRainOne && productType !== '5G' }

	constructor(
		private userService: UserService,
		private modalService: NgbModal,
		private migrationService: MigrateToRainOneService
	) {}

	@Action(SetServiceCancellationDetails)
	SetServiceCancellationDetails(ctx: StateContext<CancellationStateModel>, action: SetServiceCancellationDetails) {
		const { service } = action ?? {};

		return ctx.patchState({
			service
		})
	}

	@Action(CancelLegacyService)
	CancelLegacyService(ctx: StateContext<CancellationStateModel>, action: CancelLegacyService) {

		const { question, answer, comments } = action ?? {};
		
		const payload = {
			serviceId: ctx.getState()?.service?.id, 
			reason: `Main cancellation reason: ${question?.title}, Sub cancellation reason: ${answer?.title}`, 
			collection_address: "",
			comments: comments ?? ""
		}

		return this.migrationService.cancelRainOneService(payload).pipe(
			tap({
				next: (result: any) => {					
					ctx.dispatch(new ShowCancellationSuccessMessage(ctx.getState().service?.product?.category?.toString()));
					return setTimeout(() => ctx.dispatch(new GetAllServicesV2()), 1000);
				},
				error: (error) => {
					const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
                    modalRef.componentInstance.data ={
                        title : "oops",
                        description : 'your cancellation request failed , please try again.',
                        buttonName : 'got it',
                    }
				}
			})
		)
	}

	@Action(Revoke4GServiceCancellation)
	Revoke4GServiceCancellation(ctx: StateContext<CancellationStateModel>, action: Revoke4GServiceCancellation) {
		return this.migrationService.reverseCancelRainOneService(action?.serviceId)
		.pipe(tap({
			next: () => {
				const showBottomSheet = window.innerWidth < 768;
				if (showBottomSheet) {
					ctx.dispatch(new ShowBottomSheet('reverse_cancellation_success'));
					return setTimeout(() => ctx.dispatch(new GetAllServicesV2()), 2000);
				}
				
				this.modalService.open(ReverseCancellationModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });

				return setTimeout(() => ctx.dispatch(new GetAllServicesV2()), 2000) 
			},
			error: () => {
				const data = {
					title : "oops",
					description : 'your cancellation request failed , please try again.',
					buttonName : 'got it',
				}
				const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
				modalRef.componentInstance.data = data;
			}
		}))
	}

	@Action(CancelRainOneService)
	CancelRainOneService(ctx: StateContext<CancellationStateModel>, action: CancelRainOneService) {
		const { question, answer, comments } = action ?? {};
		
		const payload = {
			serviceId: ctx.getState()?.service?.id, 
			reason: `Main cancellation reason: ${question?.title}, Sub cancellation reason: ${answer?.title}`, 
			collection_address: "",
			comments: comments ?? ""
		}
       	return this.migrationService.cancelRainOneService(payload).pipe(
            tap({
                next: res => {
                    ctx.dispatch(new ShowCancellationSuccessMessage(ctx.getState().service?.product?.category?.toString()));
					return setTimeout(() => ctx.dispatch(new GetAllServicesV2()), 2000);
                },
                error: err => {
					const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
                    modalRef.componentInstance.data ={
                        title : "oops",
                        description : 'your cancellation request failed , please try again.',
                        buttonName : 'got it',
                    }
                }
            }))
	
	}

	@Action(PreCancel5GService)
	PreCancel5GService(ctx: StateContext<CancellationStateModel>, action: PreCancel5GService) {
		const { question, info } = ctx.getState()?.cancellationSurveyPayload ?? {};
		const payload = {
			serviceId: ctx.getState()?.service?.id,
			fulfillmentType: action?.fufillmentType,
			collection_address: action?.collectionAddress,
			comments : info || null, 
			reason : question?.title,
			type : 'scheduled',
			contact_number : ctx.getState()?.service?.msisdn,
			service: ctx.getState()?.service
		  }
		return this.migrationService.preCancelRainOneService(payload).pipe(
            tap({
                next: res => {
                    ctx.dispatch(new ShowCancellationSuccessMessage(ctx.getState()?.service?.product?.category?.toString()));
					return setTimeout(() => ctx.dispatch(new GetAllServicesV2()), 2000);
                },
                error: err => {
                    const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
                    modalRef.componentInstance.data ={
                        title : "oops",
                        description : 'your cancellation request failed , please try again.',
                        buttonName : 'got it',
                    }
                }
            })
        );
	}

	@Action(RevokePreCancel5GService)
	RevokePreCancel5GService(ctx: StateContext<CancellationStateModel>, action: RevokePreCancel5GService) {
		return this.migrationService.revokeRainOnePrecancellation(action?.serviceId).pipe(
            tap({
                next: res => {
                    const showBottomSheet = window.innerWidth < 768;
					if (showBottomSheet) {
						ctx.dispatch(new ShowBottomSheet('reverse_cancellation_success'));
						return setTimeout(() => ctx.dispatch(new GetAllServicesV2()), 2000);
					}

					this.modalService.open(ReverseCancellationModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
					
					return setTimeout(() => ctx.dispatch(new GetAllServicesV2()), 2000)
				},
                error: err => {
					const data = {
						title : "oops",
						description : 'your cancellation request failed , please try again.',
						buttonName : 'got it',
					}
					const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
					modalRef.componentInstance.data = data;
                }
            })
        );
	}

	@Action(ShowCancellationSuccessMessage)
	ShowCancellationSuccessMessage(ctx: StateContext<CancellationStateModel>, action: ShowCancellationSuccessMessage) {
		const showBottomSheet = window.innerWidth < 768;
		const successMessageOptions = {
			'5G': {
				sheet: 'cancellation_success',
				modal: CancellationSuccessFiveGModalComponent
			},
			'4G': {
				sheet: 'cancellation_4G_success',
				modal: CancellationSuccessFourGModalComponent
			}
		}

		const successMessageOption = successMessageOptions?.[action?.type];

		if (showBottomSheet) {
			return ctx.dispatch(new ShowBottomSheet(successMessageOption?.sheet))
		} else {
			return this.modalService.open(successMessageOption?.modal, { size: 'confirm' as any,
                centered: true});
		}
	}

	@Action(NavigateToCancellationSurvey)
	NavigateToCancellationSurvey(ctx: StateContext<CancellationStateModel>) {
		const { id } = ctx.getState().service ?? null;
		if (!id) return;

		return ctx.dispatch([new Navigate([`manage/services/${id}/cancellation-survey`]), new CloseBottomSheet()])
	}

	@Action(NavigateToCollectionConfirmation)
	NavigateToCollectionConfirmation(ctx: StateContext<CancellationStateModel>) {
		const { id } = ctx.getState().service ?? null;
		if (!id) return;

		return ctx.dispatch(new Navigate([`manage/services/${id}/confirm-collection`]))
	}

	@Action(StoreCancellationSurveyRequest)
	StoreCancellationSurveyRequest(ctx: StateContext<CancellationStateModel>, action: StoreCancellationSurveyRequest) {
		return ctx.patchState({cancellationSurveyPayload: action?.payload});
	}

	@Action(ClearCancellationState)
	ClearCancellationState(ctx: StateContext<CancellationStateModel>) {
		return ctx.setState({
			service: null,
			cancellationSurveyPayload: null
		})
	}

	private getCancellationErrorMessage(errorMessage: string) {
		let message: string = "";
		message = errorMessage.includes('>') 
		  ? errorMessage.split('>')[1] 
		  : errorMessage;
	
		if (message.includes('Found scheduled action')) {
			message = 'You are not allowed to perform this action due to pending SIM cancellation request'
		}
	
		return message;
	  }

}