import { Injectable } from '@angular/core';
import * as QRCode from 'qrcode';

@Injectable({
  providedIn: 'root'
})
export class RainOneCompetitionModalService {

  generateQRCode(url: string): Promise<string> {
    return QRCode.toDataURL(url);
  }

}
