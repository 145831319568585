import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { FivegCoverageCheckModalComponent } from '@components/product/fiveg-coverage-check-modal/fiveg-coverage-check-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TsAndCsComponent } from '@pages/user-register-page/ts-andcs-modal/ts-andcs-modal.component';
import { ProductService } from '@services/product.service';

import { VoucherStatusPayload } from 'src/app/core/interfaces/voucher-status.payload.interface';
import { VoucherService } from 'src/app/core/services/voucher/voucher.service';
import { CartService } from '@services/cart.service';
import { InvalidVoucherModalComponent } from '@components/invalid-voucher-modal/invalid-voucher-modal.component';
import { VoucherReedemSuccessComponent } from '@components/voucher-reedem-success/voucher-reedem-success.component';
import { SuccessErrorModalComponent } from '@pages/migration-journey/components/success-error-modal/success-error-modal.component';
import { ALREADY_HAS_TWO_FOR_ONE } from '@models/constants';

const twoForOneProducts = [
  {
    "activeFrom": 946684800,
    "activeTo": 32503680000,
    "billingType": "perMonthUpFront",
    "config": {
      "twoInOnePromo": {
        "value": true
      },
      "dataPlan": {
        "value": "Unlimited home 5G premium"
      },
      "position": {
        "value": 6
      },
      "mobilePosition": {
        "value": 5
      },
      "speed": {
        "value": "unlimited"
      },
      "stream": {
        "value": "unlimited"
      },
      "migrate": {
        "normal": {
          "value": [
            {
              "productId": "11",
              "schedule": "endOfMonth"
            },
            {
              "productId": "9",
              "schedule": "endOfMonth"
            }
          ]
        }
      }
    },
    "currency": "ZAR",
    "description": "- Two 5G premium plans for the price of one\n- Ultra fast 5G download speeds\n-  Full HD video streaming & multiple HD streams\n- Unlimited home wifi \n\n## Includes free-to-use 5G indoor router\n- Simple plug and play\n- Optimised exclusively for the rain network\n- Free delivery",
    "silentSalesmanDescription": "- Ultra fast 5G download speeds*\n- Full HD video streaming\n- Multiple HD streams\n- Unlimited home wifi",
    "id": "8",
    "migrationImage": "assets/images/migrations/5G-premium.png",
    "migrationCornerImage": "assets/images/migrations/5G-premium-corner.png",
    "image": "assets/images/devices/premium.svg",
    "cornerImage": "assets/images/product-images/tile-assets/unlimited-home-premium-corner.png",
    "items": [
      {
        "category": "5G",
        "config": {
          "dataPlan": {
            "value": "Unlimited home 5G premium"
          },
          "friendly_name": {}
        },
        "description": "- Two routers for the price of one \n- Ultra fast 5G download speeds\n-  Full HD video streaming & multiple HD streams\n- Unlimited home wifi\n\n\n## Includes free-to-use 5G indoor router",
        "id": "89dd4305-8f41-4d86-a097-416e1046e865",
        "name": "Unlimited home 5G premium",
        "sku": "RAIN-DIR-00072",
        "tag": "Unlimited home 5G Premium DUO1"
      },
      {
        "category": "5G",
        "config": {
          "dataPlan": {
            "value": "Unlimited home 5G premium"
          },
          "friendly_name": {}
        },
        "id": "12bf79dc-f05b-46cf-a91c-d28b79ed84e1",
        "sku": "RAIN-DIR-00073",
        "tag": "Unlimited home 5G Premium DUO2"
      },
      {
        "category": "device",
        "config": {},
        "description": "\n- Simple plug and play\n- Optimised exclusively for the rain network\n- Free delivery\n\n\n",
        "id": "1fad4fe7-7eb8-4dce-8b49-33b01668f53d",
        "name": "5G Router",
        "sku": "RAIN-DIR-20009",
        "tag": "5G Router"
      },
      {
        "category": "device",
        "config": {},
        "id": "1fad4fe7-7eb8-4dce-8b49-33b01668f53d",
        "name": "5G Router",
        "sku": "RAIN-DIR-20009",
        "tag": "5G Router"
      },
      {
        "category": "serviceCredit",
        "config": {},
        "id": "fae7f88c-bf5b-4a15-9d4c-1d347bfab84d",
        "sku": "RAIN-DIR-20010",
        "tag": "serviceCredit"
      },
      {
        "category": "serviceCredit",
        "config": {},
        "id": "6274a994-50bf-4fe2-998f-fc4f3223dd62",
        "sku": "RAIN-DIR-20042",
        "tag": "serviceCredit"
      }
    ],
    "name": "unlimited home\n 5G premium",
    "price": 999,
    "productModalImage": "assets/images/silentSalesman/cpe.jpg",
    "sku": null,
    "type": "bundle"
  },
  {
    "category": "serviceCredit",
    "id": "6274a994-50bf-4fe2-998f-fc4f3223dd62",
    "name": "5G upfront",
    "sku": "RAIN-DIR-20042"
  },
  {
    "category": "device",
    "config": {},
    "description": "\n- Simple plug and play\n- Optimised exclusively for the rain network\n- Free delivery\n\n\n",
    "id": "1fad4fe7-7eb8-4dce-8b49-33b01668f53d",
    "name": "5G Router",
    "sku": "RAIN-DIR-20009",
    "tag": "5G Router"
  }
]

@Component({
  selector: 'rain-voucher-activate-modal',
  templateUrl: './voucher-activate-modal.component.html',
  styleUrls: ['./voucher-activate-modal.component.scss']
})
export class VoucherActivateModalComponent implements OnInit {
  @Input() voucherDetails: VoucherStatusPayload;
  public voucherForm: FormGroup;
  private payload: VoucherStatusPayload;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private productService: ProductService,
    private _svc: VoucherService,
    private cartService: CartService
    ) { }

  ngOnInit(): void {
    this.voucherForm = this.fb.group({
      voucher_code: this.fb.control(this.voucherDetails.voucherNumber, Validators.compose([Validators.required, Validators.minLength(4)])),
      terms: this.fb.control(false, Validators.requiredTrue)
    })
  }

  close() {
    this.activeModal.close();
    window.history.replaceState({}, document.title, "/")
  }

  viewTsAndCs() {
    const modalRef = this.modalService.open(TsAndCsComponent, { size: <any>'lg', windowClass: 'slideInUp' });
    modalRef.componentInstance.pdfSrc = encodeURIComponent('/assets/pdf/TermsRainOne.pdf');
  }


  public onSubmit() {
    if(this.voucherForm.invalid) return;
    const editedVoucher = this.voucherForm.getRawValue().voucher_code;
    this.payload = {...this.voucherDetails};

    if(editedVoucher !== this.payload.voucherNumber) {
      this.payload.voucherNumber = editedVoucher;
    }

    this._svc.checkStatus(this.payload).subscribe({
      next: (res) => this.onValidVoucher(res.redeemed),
      error: (_) => this.showInValidVoucherModal()
    });


  }

  private onValidVoucher(isRedeemded: boolean) {
    if(isRedeemded) return this.showInValidVoucherModal();
    localStorage.setItem('two_for_one', JSON.stringify(Object.assign({}, {...this.payload, redeemed: false})));
    
    this.productService.getById('38').subscribe(product => {
      
      const hasProduct = this.checkCart();
      if(hasProduct) {
        const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
        modalRef.componentInstance.title = 'heads up';
        modalRef.componentInstance.description = ALREADY_HAS_TWO_FOR_ONE;
        modalRef.componentInstance.btnName = 'got it';

        return modalRef;
      }
      const modalRef = this.modalService.open(FivegCoverageCheckModalComponent, {
        size: 'xl' as any,
        centered: true
      });
      modalRef.componentInstance.isTwoForOne = true;
      modalRef.componentInstance.product = product.value;
      
      this.close();
    });
  }

  private showInValidVoucherModal() {
    const modalRef = this.modalService.open(InvalidVoucherModalComponent, {centered: true, size: <any>'sm', windowClass: 'slideInUp d-flex'});
    this.close();
  }

  private checkCart() {
    if(sessionStorage.getItem('CART_CACHE')) {
      const cart = JSON.parse(sessionStorage.getItem('CART_CACHE'));      
      const promo = cart.value.find(i => i.productId === '38');

      if(promo) return true;

      return false;

    } else {
      return false;
    }
  }
 }
