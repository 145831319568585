import {Component, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from 'src/app/baseComponent';
import {Result, Statuses} from '@models/result';
import {IUserDetail} from '@models/userDetail';
import {AuthenticationService} from '@services/auth.service';
import {UserService} from '@services/user.service';
import {ElementService} from '@services/element.service';
import {CacheService} from '@services/cache.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isNil} from 'lodash';
import {WebsiteconfigService} from 'src/app/core/services/websiteconfig.service';
import {ConfirmSignOutModalComponent} from '@components/confirm-sign-out-modal/confirm-sign-out-modal.component';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {InboxState} from '@pages/notifications/store/inbox.state';
import {GetAllMessages, GetTotalUnreadMessagesCount, SetShowOptinInfo} from '@pages/notifications/store/inbox.actions';
import {ProfileService} from '@services/profile.service';
import {CoreState} from 'src/app/core/store/state/core.state';
import {EmptyCart} from 'src/app/store/actions/cart.action';
import { environment } from 'src/environments/environment';
import { SSOService } from 'src/app/core/services/sso.service';
import { ROUTES } from 'src/app/v2/utils/Constants';
import { ButtonClickedTracking } from 'src/app/store/actions/rain-analytics-tracker.actions';
import { ShowBottomSheet } from 'src/app/v2/store/actions/bottom-sheet.actions';
import { ASK_BOT_QUESTION } from '@models/constants';

@Component({
    selector: 'app-nav-modal',
    templateUrl: './nav-modal.component.html',
    styleUrls: ['./nav-modal.component.scss']
})
export class NavModalComponent extends BaseComponent implements OnInit {
    @Select(InboxState.getTotalMessagesCount) totalUreadMessages$: Observable<number>;
    @Select(CoreState.isNvidiaWhitelisted) isNvidiaWhitelisted$: Observable<boolean>;
    isSignedIn: boolean;
    userInfo: IUserDetail;
    displayPreferences = false;
    isMobileScreen: boolean;

    displayName: any;
    scrollToShop: () => void;
    arrearAmount: any;
    routes = ROUTES;

    constructor(
        private elem: ElementRef,
        private modalService: NgbModal,
        private router: Router,
        private authService: AuthenticationService,
        private userService: UserService,
        private toastr: ToastrService,
        private elementService: ElementService,
        public websiteConfig: WebsiteconfigService,
        private cacheService: CacheService,
        private store: Store,
        public activeModal: NgbActiveModal,
        private profileService: ProfileService,
        private sso: SSOService
    ) {
        super();
        this.isMobileScreen = window.innerWidth <= 560;
        this.scrollToShop = elementService.scrollToShop;
    }

    ngOnInit() {
        this.listenToGlobalNotificationSettings();
        this.getUserInfo();
    }

    listenToGlobalNotificationSettings() {
        this.websiteConfig.listenToGlobalNotificationSettings().subscribe(
            (res: any) => {
                this.displayPreferences = true;
                if (res?.notificationSettings) {
                    this.store.dispatch([new GetTotalUnreadMessagesCount(), new GetAllMessages(), new SetShowOptinInfo(true)]);
                } else {
                    this.router.navigateByUrl('/');
                }
            },
            error => {
                this.toastr.error('An error occurred while loading message settings', null);
            }
        );
    }

    home() {
        this.activeModal.close('home');
        this.elementService.scrollToHome();
        this.store.dispatch(new EmptyCart())


    }

    getUserInfo() {
        this.isSignedIn = this.authService.isSignedIn;

        if (this.isSignedIn) {
            this.userService
                .get()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((result: Result<IUserDetail>) => {
                    if (result.status != Statuses.Success) {
                        this.toastr.error('An error occurred while processing the request', null);
                        return;
                    } else {
                        this.userInfo = result.value;
                        this.displayName = this.userInfo.firstName;
                    }
                });
        }
    }

    goToExternalUrl(url) {
        window.open(url, '_blank');
    }

    handleSuccess(path: string) {
        this.onCloseModal();
        if (isNil(path) == false) {
            this.router.navigateByUrl(path);
        }
    }

    signIn() {
        this.profileService.clearCache();
        setTimeout(() => {
            this.activeModal.close('close modal');
        }, 100);
        this.router.navigate(['/login/home']);
    }

    signOut() {
        this.profileService.clearCache()
        this.activeModal.close('close modal');
        $('.slide-left')
            .addClass('slide-out')
            .removeClass('slide-left');
        setTimeout(() => {
            this.modalService.open(ConfirmSignOutModalComponent, {
                size: <any>'confirm',
                windowClass: 'slideInUp',
                centered: true
            });
        }, 300);
    }

    onCloseModal() {
        $('.slide-left')
            .addClass('slide-out')
            .removeClass('slide-left');

        setTimeout(() => {
            this.activeModal.close('close modal');
        }, 500);
    }

    async goToAskRain() {
        const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);

        if (!isAuthed) {
            window.location.href = environment.askRainUrl;
            return;
        }
        const encodedUrl = await this.sso.createUrl(environment.askRainUrl, null, true);
        window.open(encodedUrl, '_self');
    }

    askRain(promptKey: keyof typeof ASK_BOT_QUESTION) {
        const selectedPrompt = ASK_BOT_QUESTION[promptKey];
        if (selectedPrompt) {
          this.store.dispatch(new ShowBottomSheet("ask_rain_bot", selectedPrompt));
        }
      }    

      openRainGo() {
        return !this.authService.isSignedIn 
          ? window.open(environment.rainGoBaseUrl, '_self') 
          : this.sso.createUrl(environment.rainGoSSOUrl).then((encodedUrl) => {
              window.open(encodedUrl, '_self');
            });
      }
}
