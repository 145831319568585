<div class="modal-container">
    <div class="modal-header border-0">
        <div class="modal-img-close-btn z-index-5">
            <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')" />
        </div>
    </div>
    <div class="modal-body p-0  z-index-1">
        <div class="coverage-check top-0 z-index-1">
            <div class=" mb-5">
                <h2 class="main-text">
                    great news!<br> you’re in 5G coverage
                </h2>
                <p class="sub-text">
                    <span>
                        Here are our current 5G home wifi products.<br>
                    </span>
                    Click shop now to choose the one that’s right for you.
                </p>
            </div>

            <div>
                <div>
                    <div class="lockup row">
                        <div class="product">
                            <div class="device">
                                <img class="img-fluid device" src="/assets/images/fiveg/coverage-check-upfront-5g-basic-product.png" />
                            </div>
                            <div class="product-name">
                                unlimited 5G home basic
                            </div>
                        </div>

                        <div class="product">
                            <div class="device">
                                <img class="img-fluid" src="/assets/images/fiveg/coverage-check-upfront-5g-standard-product.png" />
                            </div>
                            <div class="product-name">
                                unlimited 5G home standard
                            </div>
                        </div>

                        <div class="product">
                            <div class="device">
                                <img class="img-fluid" src="/assets/images/fiveg/coverage-check-upfront-5g-premium-product.png" />
                            </div>
                            <div class="product-name">
                                unlimited 5G home premium
                            </div>
                        </div>
                    </div>
                </div>
                <div id="shop-btn" class="d-flex justify-content-center">
                    <button [class.btn-sm]="isMobile" [class.btn-md]="!isMobile" class="btn  btn-primary" role="button"
                        (click)="onGotToShopPage()">
                        shop now
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>