import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/baseComponent';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { VoucherService } from 'src/app/core/services/voucher/voucher.service';

@Component({
  selector: 'rain-two-for-one-coverage-modal',
  templateUrl: './two-for-one-coverage-modal.component.html',
  styleUrls: ['./two-for-one-coverage-modal.component.scss']
})
export class TwoForOneCovergaeModalComponent extends BaseComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
  ) {
    super();
  }
  ngOnInit() {}

  public moveToSignUp() {
    this.activeModal.close();
    return this.store.dispatch(new Navigate(['register/cart']));
  }

  public moveToLogin() {
    this.activeModal.close();
    return this.store.dispatch(new Navigate(['/login'],{returnUrl: '/cart'}));
  }
}
