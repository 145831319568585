<div class="modal-content border-0 px-lg-30 px-15">
  <div class="modal-img-close-btn z-index-5">
    <img
      src="../../assets/svg/close.svg"
      class="pointer"
      alt="Close"
      (click)="activeModal.dismiss('Cross click')"
      data-dismiss="modal"
      data-target="#FiveGCoverageModal"
      aria-label="Close"
    />
  </div>
  <div class="modal-header p-0 mt-20">
    <h1 class="text-primary modal-title mb-5 fiveg">great news - you're in <span class="text-uppercase">5G</span> coverage!</h1>
  </div>
  <hr class="line-1" />
  <br />
  <div ngbAutoFocus class="modal-body pb-20 pt-0 pl-0 pr-0" *ngIf="product">
    <div id="buyCustom">
      <div>
        <div class="card border-0" id="fiveg_premium">
          <div class="row d-flex" id="pro-block">
            <div id="featuredComp" class="block posiiton-relative col-lg-12 mt-5 block-custom-size-50">
              <div class="text-image-container">
                <div class="service-description">
                  <div>
                    <div class="row">
                      <div class="col-lg-12">
                        <h2 class="card-title text-primary  font-size-22  font-weight-500">
                          {{ product.name }}
                        </h2>
                      </div>
                    </div>
                    <markdown class="product-description text-gray-50 medium-font-size mb-10">
                      {{ product.silentSalesmanDescription || product.description }}
                    </markdown>
                    <div class="mt-10 mb-10" *ngIf="hasPostPaid$ | async">
                      <small class="d-sm-block d-none text-gray-50 custom-info" *ngIf="product?.config?.winterPromo?.value">
                        * After the first month, the price will return to R699 per month.
                      </small>
                    </div>
                  </div>
                  <div class="mt-10" *ngIf="!product?.config?.blackFriday?.value">
                    <div class="price-active">
                      <div class="custom-height">
                        <div class="text-primary font-size-30  font-weight-bold">
                          <span class="price">
                            R{{ product.price }}
                            p/m
                          </span>
                          <span class="font-size-20  font-weight-medium"><span class="font-size-14 font-weight-medium">(incl. VAT)</span></span>
                          <ng-template #winterPromoContentMobi>
                            <span *ngIf="!(isR1PromoActive$ | async)" class="small-font-size pt-3 font-weight-normal"
                              >for the first month<br />(incl. VAT)</span
                            >
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pr-20">
                  <img class="img-fluid custom-img" [src]="product.productModalImage" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="join-today-from-baner">
  <div class="join-text">join today from</div>
  <div class="join-price">{{ product.price | totalDueNow: +(closetsBillCycleDate$ | async) | currency: 'R' }}</div>
  <div class="join-sub-text">*subject to selected billing date</div>
</div>

<div class="modal-content border-0 px-lg-30 px-15 mb-15">
  <div class="mt-10" *ngIf="device">
    <div class="my-10 text-primary">Includes free-to-use 5G indoor router</div>
    <ul class="text-gray-50 medium-font-size">
      <markdown class="device-description">
        {{ device.description }}
      </markdown>
    </ul>
  </div>
  <div class="border-top mt-10 ">
    <div class="my-10 text-primary ">what's free-to-use?</div>
    <p class="medium-font-size text-gray-50">
      The 5G router is free-to-use for as long as you enjoy our 5G service. Should you switch to 4G or cancel your service, you will be required to
      return the router.
    </p>
  </div>
  <div class="d-flex justify-content-between mt-5 flex-sm-row flex-column-reverse border-top">
    <div data-id="link-click" data-text="Terms and conditions apply" class="terms-link text-primary" (click)="goTo('/legal?section=terms')">
      Terms and conditions apply.
    </div>
    <button
      [attr.data-text]="gmData"
      data-id="btn-click"
      class="btn btn-sm btn-primary ml-sm-5 mr-sm-10 mb-sm-0 mt-10"
      id="checkout-btn"
      (click)="checkout()"
    >
      add to cart
    </button>
  </div>
</div>
