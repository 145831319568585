import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';




@Component({
  selector: 'app-customer-rating-modal',
  templateUrl: './customer-rating-modal.component.html',
  styleUrls: ['./customer-rating-modal.component.scss']
})
export class CustomerRatingModalComponent  {

  @Input() email: string;
  @Input() rating: string;
  @Input() campaignId: string;

  public ratings = [
    {
      number: 1,
      image: 'assets/images/not-rated.png'
    },
    {
      number: 2,
      image: 'assets/images/not-rated.png'
    },
    {
      number: 3,
      image: 'assets/images/not-rated.png'
    },
    {
      number: 4,
      image: 'assets/images/not-rated.png'
    },
    {
      number: 5,
      image: 'assets/images/not-rated.png'
    }
  ]
  
  public ratedNumber: number;
  public loading = false;

  constructor(public activeModal: NgbActiveModal) { }


  close() {
    this.activeModal.close();
    window.history.replaceState({}, document.title, "/")
  }

  rate(index: number) {
    for (let i = 0; i < this.ratings.length; i++) {
      if (i <= index) {
        this.ratings[i].image = 'assets/images/rated.png';
      } else {
        this.ratings[i].image = 'assets/images/not-rated.png';
      }

      if (i === index) {
        this.ratedNumber = this.ratings[i].number;
      }
    }
  }

  submit() {
    this.loading = true;
    const domain = window.location.host;
    let returnBaseURL = '';
    if (domain.includes('sit') || domain.includes('precipitation') || domain.includes('odyssey')) {
      returnBaseURL = 'https://rate-us-sit.rain.co.za';
    } else {
      returnBaseURL = 'https://rate-us.rain.co.za';
    }
    
    const params = btoa(`campaign=${this.campaignId.replace(/|\|/g, '')};customer=${this.email.replace(/|\|/g, '')};feedback=${this.ratedNumber}`);
    window.open(`${returnBaseURL}/${params}`, '_self');
  }

}
