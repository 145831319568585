<div class="mb-5 modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
      src="../../assets/svg/close.svg" />
  </div>
</div>

<div class=" p-5 modal-body border-0 ">
  <div class="row m-0">
    <div class="col-12 mb-10 text-center">
      <p class="text-primary text-center h2 font-weight-bold modal-heading">your contact details have been submitted
      </p>
      <p>
        We’ll let you know when you’re in coverage.
      </p>
      <p>
        <span class="text-primary font-weight-bold">Before you go, just remember...<br></span>
        Your SIM cancellation will only happen at the <span class="text-primary">end of the month</span>. So, you’ll
        still be connected until then an
        dpayment for your last invoice for this month’s usage will go off on <span
          class="text-primary font-weight-bold">{{ currentLastDayOfTheMonthFullDate}}</span>. Tap below to confirm your
        cancellation.
      </p>
      <div>
        <button class="btn btn-sm btn-primary" (click)="confirm()">
          confirm
        </button>
      </div>
    </div>

  </div>
</div>