<div class="modal-header border-0" [class]="'is-'+(ap$ | async)">
    <div class="modal-img-close-btn z-index-5">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" (click)="close()" />
    </div>
</div>
<div class="modal-body" [class]="'is-'+(ap$ | async)">
    <div class="coverage-check">
        <div class=" mb-5">
            <h2 class="text-primary text-center h2 font-weight-bold modal-heading">
                heads up!
            </h2>
        </div>

        <div class="mb-10 mb-sm-0">
            <p>You won't be able to speed up your rain<strong>one</strong> home wifi while on our 4G network.</p>
            <p>But as soon as there's 5G coverage in your area, we will notify you and we'll automatically switch you to 5G, and you'll be able to speed up you 5G home wifi</p>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <button  data-id="btn-click" class="btn btn-md btn-primary text-white"
            id="check-coverage" role="button" (click)="close()" (keyup.enter)="close()">
            got it
        </button>
    </div>


</div>