<div class="modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
      data-dismiss="modal" data-target="#thankYouModal" src="../../assets/svg/close.svg" />
  </div>
</div>

<div class="mt-5 p-5 modal-body border-0 " *ngIf="!loading">
  <div class="row m-0">
    <div class="col-12 mb-10">
      <p class="text-primary h2 modal-heading">could your 5G signal <span class="bold-text">be better?</span> 
      </p>
      <p class="modal-content-text">
        We want to make sure you’re getting the best signal from your 5G router. Tap the button below and we’ll give you
        a call to help you out.
      </p>
      <div class="action-buttons">

        <button class="btn-md btn btn-outline-primary mr-3 btn-style" (click)="proceedToCancel();">
          cancel sim
        </button>

        <button class="btn btn-md btn-primary ml-3 btn-style" (click)="submit()">
          call me
        </button>
      </div>
    </div>

  </div>
</div>