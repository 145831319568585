import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AddressDetail } from '@models/addressDetail';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE, MAP_SEARCH_CONFIG } from '@models/constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { DeliveryAddress, IDeliveryAddress } from '@services/cart.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'app-collection-address',
  templateUrl: './collection-address.component.html',
  styleUrls: ['./collection-address.component.scss']
})
export class CollectionAddressComponent implements OnInit {

  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  addressForm: FormGroup;
  collectionAddress: IDeliveryAddress;
  latitude: number = DEFAULT_LATITUDE;
  longitude: number = DEFAULT_LONGITUDE;
  config = MAP_SEARCH_CONFIG;
  addressSearched = false;
  formattedAddress: string;
  formSubmit = false;
  streetNumberRegex = /(?:[0-9])/;

  streetNumberValidationMessages: ValidationErrors = {
    required: 'Street number is required.',
    pattern: 'Must contain at least 1 numeric i.e. (1-9).',
  };
  streetNameValidationMessages: ValidationErrors = {
    required: 'Street name is required.',
  };
  suburbValidationMessages: ValidationErrors = {
    required: 'Suburb is required.',
  };
  cityValidationMessages: ValidationErrors = {
    required: 'City is required.',
  };
  postalCodeValidationMessages: ValidationErrors = {
    required: 'Postal code is required.',
    invalidPostalCode: 'Must be a valid postal code.',
  };


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal,) { }

  ngOnInit() {
    this.addressForm = this.fb.group({
      address: new FormControl(''),
      streetName: this.fb.control('', Validators.required),
      streetNumber: this.fb.control('', Validators.compose([Validators.required, Validators.pattern(this.streetNumberRegex)])),
      suburb: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      postalCode: this.fb.control('', Validators.compose([Validators.required, this.checkPostalCode()])),
      complex: this.fb.control(''),
      flat: this.fb.control(''),
      notes: this.fb.control(''),
    })
  }

  private checkPostalCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const value = control.value;
      if (!value) {
        return null;
      }

      const validPostalCode = value !== '0000' && value.length === 4;
      return !validPostalCode ? { invalidPostalCode: true } : null;
    }
  }


  handleAddressChange = (result: Address) => {
    this.formSubmit = false;
    const address = AddressDetail.fromGoogle(result);

    this.collectionAddress = DeliveryAddress.mapfromGoogle(result);
    this.addressForm.get('streetName').setValue(address.streetName);
    this.addressForm.get('streetNumber').setValue(address.streetNumber);
    this.addressForm.get('suburb').setValue(address.suburb);
    this.addressForm.get('city').setValue(address.city);
    this.addressForm.get('postalCode').setValue(address.postalCode);
    this.addressForm.get('address').setValue('');


    this.latitude = result.geometry.location.lat();
    this.longitude = result.geometry.location.lng();
    this.addressSearched = true;
    this.formattedAddress = result.formatted_address;

    if (!this.collectionAddress.streetNumber) {
      this.addressForm.addControl("streetNumber", new FormControl('', [Validators.required]));
    }

    this.addressForm.get('address').patchValue('');
  };


  submit() {
    this.formSubmit = true;

    if (this.addressForm.invalid) {
      return;
    } else {

      const address = {
        formattedAddress: `${this.formattedAddress}, complex: ${this.addressForm.get('complex').value ? `${this.addressForm.get('complex').value}` : 'none'}, flat: ${this.addressForm.get('flat').value ? `${this.addressForm.get('flat').value}` : 'none'},  notes: ${this.addressForm.get('notes').value ? `${this.addressForm.get('notes').value}` : 'none'}`,
        lat: this.latitude,
        lon: this.longitude

      }
      this.submitted.next(address);
    }
  }

}
