import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dictionary, includes, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { Subject, Subscription, timer } from 'rxjs';
import { ResetCart } from '../store/actions/cart.action';
import { ConfigService } from './config.service';

/**
 * SessionItem @param
 */
class SessionItem {
  key: string;
  expiresAt: Date;
  value: any;
  expiryAmount: number;
}

/**
 * EXPIRE_UNIT @param
 */
const EXPIRE_UNIT = 'milliseconds';

/**
 *
 * @export
 * @class CacheService
 */
@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public onAdded: Subject<SessionItem> = new Subject<SessionItem>();
  public onRemoved: Subject<string> = new Subject<string>();
  public onExpired: Subject<string> = new Subject<string>();
  public onCleared: Subject<void> = new Subject<void>();
  public onError: Subject<string> = new Subject<string>();

  private subscriptions: Dictionary<Subscription> = {};

  constructor(private configService: ConfigService, private store: Store) { }

  clear(excludeList: string[] = []) {
    if (isEmpty(excludeList)) {
      for (const key in Object.keys(this.subscriptions)) {
        this.remove(key);
      }

      this.subscriptions = {};

      sessionStorage.clear();
      this.store.dispatch(new ResetCart());
    } else {
      const keys = [];
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);

        if (includes(excludeList, key) === false) {
          keys.push(key);
        }
      }

      for (const key of keys) {
        this.remove(key);
      }
    }

    this.onCleared.next();
  }

  remove(cacheKey: string) {
    const subscription = this.subscriptions[cacheKey];

    if (isNil(subscription) === false) {
      subscription.unsubscribe();
      delete this.subscriptions[cacheKey];
    }

    sessionStorage.removeItem(cacheKey);

    this.onRemoved.next(cacheKey);
  }

  setObject(cacheKey: string, item: any, expiryAmount: number = this.configService.CACHE_EXPIRY) {
    const expiresAt = moment()
      .add(expiryAmount, EXPIRE_UNIT)
      .toDate();

    const sessionItem: SessionItem = {
      key: cacheKey,
      expiresAt,
      value: item,
      expiryAmount
    };

    try {
      sessionStorage.setItem(cacheKey, JSON.stringify(sessionItem));

      const timerSubscription = timer(expiryAmount).subscribe(n => {
        this.expiry(cacheKey);
      });

      if (isNil(this.subscriptions[cacheKey]) === false) {
        this.subscriptions[cacheKey].unsubscribe();
      }

      this.subscriptions[cacheKey] = timerSubscription;

      this.onAdded.next(sessionItem);
    } catch (error) {
      this.remove(cacheKey);

      this.onError.next(cacheKey);
    }
  }

  set(cacheKey: string, item: string, expiryAmount: number = this.configService.CACHE_EXPIRY) {
    const expiresAt = moment()
      .add(expiryAmount, EXPIRE_UNIT)
      .toDate();

    const sessionItem: SessionItem = {
      key: cacheKey,
      expiresAt,
      value: item,
      expiryAmount
    };

    try {
      sessionStorage.setItem(cacheKey, JSON.stringify(sessionItem));

      const timerSubscription = timer(expiryAmount).subscribe(n => {
        this.onExpired.next(cacheKey);
      });

      if (isNil(this.subscriptions[cacheKey]) === false) {
        this.subscriptions[cacheKey].unsubscribe();
      }

      this.subscriptions[cacheKey] = timerSubscription;

      this.onAdded.next(sessionItem);
    } catch (error) {
      this.remove(cacheKey);

      this.onError.next(cacheKey);
    }
  }

  exists(cacheKey: string): boolean {
    const sessionItemStr = sessionStorage.getItem(cacheKey);

    if (isNil(sessionItemStr) || isEmpty(sessionItemStr)) {
      return false;
    }

    const sessionItem: SessionItem = this.parse(sessionItemStr);

    const isNotExpired =
      sessionItem.expiresAt.getTime() >
      moment()
        .toDate()
        .getTime();

    if (isNotExpired === false) {
      this.expiry(cacheKey);
    }

    return isNotExpired;
  }

  private expiry(cacheKey: string) {
    this.remove(cacheKey);
    this.onExpired.next(cacheKey);
  }

  private parse(sessionItemStr: string) {
    if (isEmpty(sessionItemStr)) {
      return null;
    }
    const sessionItem: SessionItem = JSON.parse(sessionItemStr);
    sessionItem.expiresAt = new Date(sessionItem.expiresAt);
    return sessionItem;
  }

  get(cacheKey: string, defaultValue: string = null): string {
    if (this.exists(cacheKey)) {
      const sessionItemStr = sessionStorage.getItem(cacheKey);
      const sessionItem: SessionItem = this.parse(sessionItemStr);

      this.set(cacheKey, sessionItem.value, sessionItem.expiryAmount);

      if (isNil(sessionItem.value)) {
        return null;
      }

      return sessionItem.value.toString();
    }

    return defaultValue;
  }

  getObject<T>(cacheKey): T {
    if (this.exists(cacheKey)) {
      const sessionItemStr = sessionStorage.getItem(cacheKey);
      const sessionItem: SessionItem = this.parse(sessionItemStr);

      this.setObject(cacheKey, sessionItem.value as T, sessionItem.expiryAmount);

      return sessionItem.value as T;
    }

    return null;
  }
}
