import { IRemoteInvoice } from "./remote/remoteInvoice";
import { ModelHelper } from "./modelHelper";
import { isNil } from "lodash";

export class InvoiceDetail {

  public static adapt(remote: IRemoteInvoice): IInvoiceDetail {
    const orderDetail = {

      billDate: ModelHelper.toDate(remote.bill_date),
      billNumber: ModelHelper.toNumber(remote.bill_number),
      billAmount: remote.bill_amount,
    } as IInvoiceDetail;

    if (isNil(remote.file) === false) {
      try {
        orderDetail.blob = ModelHelper.toBlob(remote.file);
      } catch (error) {
        orderDetail.blob = null;
      }
    }

    return orderDetail;
  }
}

export interface IInvoiceDetail {
  paymentDueDate: string;
  currentBalance: string;
  billStatus: string;
  billNumber: number;
  billDate: Date;
  billAmountDue: string;
  billAmount: string;
  blob?: Blob;
}
