import {Utils} from "@Utils";

export class SetOrder {
    static type = Utils.Helpers.Type('[ORDER] Set ORDER');

    constructor(public order: any) {
    }
}

export class CreateOrder {
    static type = Utils.Helpers.Type('[ORDER] Create ORDER');

    constructor() {
    }
}

export class SetDeliveryAddress {
    static type = Utils.Helpers.Type('[ORDER] Set Delivery Address');

    constructor(public deliveryAddress: any) {
    }
}

export class CreateOrderCheckoutId {
    static type = Utils.Helpers.Type('[ORDER] Create Order Checkout ID');

    constructor(public amount: number) {
    }
}