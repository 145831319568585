<button mat-flat-button
        class="custom-rounded button-custom"
        [ngClass]="buttonClass"
        [class.spinner]="loading"
        [disabled]="loading || disabled"
        [class.btn-primary]="btnPrimary"
        [class.deselected-button]="btnDeselected"
        [class.disabled]="disabled"
        >
    {{text}}
    <ng-content></ng-content>
</button>