import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { BillingService } from 'src/app/core/services/billing.service';
import { Observable, Subject } from 'rxjs';

import { take, takeUntil } from 'rxjs/operators';
import { SendBarcodeRefSMS, SendBarcodeRefSMSSuccess } from 'src/app/core/store/actions/sms.actions';
import { CoreState } from 'src/app/core/store/state/core.state';


@Component({
  selector: 'rain-barcode-generator',
  templateUrl: './barcode-generator.component.html',
  styleUrls: ['./barcode-generator.component.scss']
})
export class BarcodeGeneratorComponent implements OnInit, OnDestroy {
  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;
  @Input() accountNumber: string;
  @Input() amount: string;

  isMobileScreen: boolean;
  public _smsForm: FormGroup;
  public SMSStatus: string = null;

  private ngDestroy$: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private actions$: Actions
  ) {
    this.isMobileScreen = window.innerWidth <= 560;
    this._smsForm = this.fb.group({
      tel_number: this.fb.control('', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(11), Validators.pattern('^(0|[0-9][0-9]*)$')]))
    });

  }

  ngOnInit(): void {
    this.accountNumber = this.accountNumber ? `11964${this.accountNumber}` : null;
  }

  public sendRef() {
    this.store.dispatch(new SendBarcodeRefSMS({ reference: this.accountNumber, number: this._smsForm.get('tel_number').value }));
    this.watchForResponse();
  }

  private watchForResponse() {
    this.actions$.pipe(
      ofActionSuccessful(SendBarcodeRefSMSSuccess),
      takeUntil(this.ngDestroy$)
    )
      .subscribe({
        next: (res) => {
          if (res) {
            this.SMSStatus = res.payload.success;
          }
        }
      })
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}
