import { ModelHelper } from '@models/modelHelper';
import { IRemoteTower } from '@models/remote/remoteSimTowerDetail';
import { Feature, Point } from 'geojson';
import { isNull } from 'lodash';
/**
 * ITowerDetail @param
 */
export interface ITowerDetail {
  /**
   * type @param {string}
   */
  type: string;

  /**
   * azimuth @param {number}
   */
  azimuth: number[];

  /**
   * azimuthRad @param {number[]}
   */
  azimuthRad: number[];

  /**
   * siteId @param {string}
   */
  siteId: string;
  /**
   * siteName @param {string}
   */
  siteName: string;

  /**
   * longitude @param {number}
   */
  longitude: number;

  /**
   * latitude @param {number}
   */
  latitude: number;

  /**
   * technicalName @param {string}
   */
  technicalName: string;
}

/**
 *  TowerDetail @param
 */
export class TowerDetail {
  static adapt(remote: IRemoteTower): ITowerDetail {
    const towerDetail = {
      siteId: remote.siteid,
      latitude: ModelHelper.toFloat(remote.latitude),
      longitude: ModelHelper.toFloat(remote.longitude),
      siteName: remote.sitename,
      technicalName: remote.wbssitename
    } as ITowerDetail;
    return towerDetail;
  }

  static fromFeature(feature: Feature<Point>): ITowerDetail {
    let azimuth: number[] = ModelHelper.getFeatureProperty('azimuth', feature.properties, []);

    azimuth = azimuth.filter(x => isNull(x) == false);

    let azimuthRad = ModelHelper.getFeatureProperty('azimuthrad', feature.properties, []);

    azimuthRad = azimuthRad.filter(x => isNull(x) == false);

    return {
      latitude: feature.geometry.coordinates[1],
      longitude: feature.geometry.coordinates[0],
      siteName: ModelHelper.clean(ModelHelper.getFeatureProperty('name', feature.properties, null)),
      technicalName: '',
      siteId: feature.id,
      type: ModelHelper.clean(ModelHelper.getFeatureProperty('tech', feature.properties, '')),
      azimuth,
      azimuthRad
    } as ITowerDetail;
  }
}
