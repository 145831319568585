<div class="verify-wrapper">
  <h1>verify your <span>4G</span> mobile phone <span>SIM</span></h1>
  <hr />
  <p class="explain">Find your SIM number and type it in below. You can find the 6 character code at the back of your SIM package or below the small rain logo on <br> the back of your micro SIM. Simply click verify to complete the process.</p>
  <section>
   
    <div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <h3>verify your SIM:</h3>
     
        <!-- number start -->
        <p>enter SIM number</p>
        <div *ngIf="formSubmit && form.controls.simcode.errors">
          <p class="text-danger small-font-size" *ngIf="form.controls.simcode.errors.minlength || form.controls.simcode.errors.required">
            please provide 6 digit SIM number
          </p>

          <p class="text-danger small-font-size" *ngIf="form.controls.simcode.errors.invalidICCID">
            Invalid SIM number
          </p>
          <p class="text-danger small-font-size" *ngIf="form.controls.simcode.errors.notAvailable">
            This SIM code provided is already assigned to an order. Please try again.
          </p>
        </div>
        <input
          type="text"
          id="encoded_iccid"
          name="encoded_iccid"
          placeholder="(example: NKWT45)"
          maxlength="6"
          formControlName="encoded_iccid"
          required
          (change)="clearCustomErrors()"
        />

        <!-- number end -->
      </form>
      <img src="assets/images/SIM-ICCID-illustration.png" alt="" />
    </div>
  </section>
  <button (click)="onSubmit()" class="btn" [class.btn-primary]="form.invalid == false" [class.btn-tertiary]="form.invalid">
    verify <span>SIM</span>
  </button>
</div>

<ng-template #modalContent>
  <div class="mb-4 modal-header border-0 ">
    <div class="modal-img-close-btn z-index-5">
      <img alt="Close" aria-label="Close" class="pointer" (click)="dismiss()" src="../../assets/svg/close.svg" />
    </div>
  </div>
  <div class="modal-body border-0 p-5 text-center">
    <div class="p-2">
      <h2 class="text-primary">success!</h2>
    </div>
    <div class="p-2">
      Your new 4G mobile phone SIM has been verified and <br />
      linked to your rain account. You can now manage your <br />
      gigs, minutes and SMS on your myrain page.
    </div>
  </div>

  <div class="p-8 mb-10 text-center">
    <button class="btn btn-sm btn-primary mx-5" (click)="dismiss()">got it</button>
  </div>
</ng-template>
