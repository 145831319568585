import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProductState } from '../store/state/product.state';


@Injectable({
  providedIn: 'root'
})
export class RainOneLevelsConfigService {
  constructor(private store: Store) {}

  getRainOneLevels(product: any) { 
    const rainOneLevels = []
    let baseLevelProduct = product
    
    for (let option of baseLevelProduct?.config?.migration) {
      
        const product: any = this.store.selectSnapshot(ProductState.allProductsMap)?.[option?.id];
        const productIsSme = product?.name.includes("SME");
        
        const data = this.bytesToMB(product?.config?.base_subscription?.DATA, 0);
        const sms = product?.config?.base_subscription?.SMS;
        const min = product?.config?.base_subscription?.VOICE / 60;
        const multiplier = product?.config?.topup_multiplier?.DATA * 100
        
        if (product?.config?.level < 6) {
            rainOneLevels.push({
                level: product?.config?.level,
                id: (product?.config?.level)?.toString(),
                features: [
                  {
                    title: 'your',
                    description: (product?.config?.maxLines && product?.config?.maxLines == 1) ? "1 mobile phone line" : (productIsSme ? '5 mobile phone lines.' : '2 mobile phone lines.')
                  },
                  {
                    title: (product?.config?.maxLines && product?.config?.maxLines == 1) ? 'your SIM gets' : 'each get',
                    description: `${data} gigs | ${min} min | ${sms} sms`,
                    subText: 'every month'
                  },
                ],
                multiplier: multiplier,
                cost: product?.recurringPrice,
                selected: false,
                name: `rainOne Level ${product?.config?.level}`,
                includedInSelection: false,
                maxLines: product?.config?.maxLines ? product?.config?.maxLines : 2,
                disclaimer: product?.config?.level === 0 ? (`You get ${ productIsSme ? 5 : 2} free mobile SIMs with <span style="color:#0077c8;">rain<b>one</b></span>. Put them in your phones & enjoy FREE calls and data every month.`) : null
              })
            }
        }
        return rainOneLevels
    }

  private bytesToMB(bytes, toFixed = 2) {
    const megabytes = bytes / (1024 * 1024 * 1024);
    return megabytes.toFixed(toFixed);
  }
}
