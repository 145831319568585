import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { MAP_SEARCH_CONFIG } from '@models/constants';
import { AuthenticationService } from '@services/auth.service';
import { DeliveryAddress, IDeliveryAddress } from '@services/cart.service';
import { ConfigService } from '@services/config.service';

@Component({
  selector: 'app-investigate-modal',
  templateUrl: './investigate-modal.component.html',
  styleUrls: ['./investigate-modal.component.scss']
})
export class InvestigateModalComponent implements OnInit {

  @Output() submitted = new EventEmitter();

  @Input() fromButton: string;

  formSubmit: boolean;
  loading = false;
  form: FormGroup;
  showAddress = true;
  deliveryAddress: IDeliveryAddress;
  formattedAddress: string;
  isSignedIn: boolean;
  siteKey: string;

  addressValidationValidationMessages: ValidationErrors = {
    required: 'Address is required',
    invalid: 'Address is invalid',
  };

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService,
    private configService: ConfigService,
    private fb: FormBuilder
  ) {

    this.siteKey = this.configService.SITE_KEY;
  }

  ngOnInit() {
    this.isSignedIn = this.authService.isSignedIn;
    this.form = this.fb.group(
      {
        content: ['', [Validators.required]],
        address: ['', [Validators.required, this.verifyAddress]]
      });

    if (this.fromButton === 'rainMaker') {
      this.form.controls.address.setValidators([]);
      this.showAddress = false;
    }
  }

  handleAddressChange = (result: GoogleAddress) => {

    this.deliveryAddress = DeliveryAddress.mapfromGoogle(result);
    this.formattedAddress = result.formatted_address;

    this.form.get('address').setValue(result.formatted_address);
  }

  validateGoogleAddress(inputValue) {
    let invalid = false;

    if (isNil(this.deliveryAddress)) {
      invalid = true;
    } else if (inputValue !== this.formattedAddress) {
      invalid = true;
    }

    if (invalid) {
      return { invalid: true };
    }

    return null;
  }

  verifyAddress = (control: AbstractControl): { [key: string]: boolean } | null => {
    return this.validateGoogleAddress(control.value);
  }

  submit() {
    this.loading = true;

    this.formSubmit = true;
    if (this.form.invalid) {
      this.loading = false;
      return;
    }

    this.submitted.emit({
      address: this.formattedAddress,
      message: this.form.value.content
    });

  }



}
