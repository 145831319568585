<ng-container *ngIf="selectedService$ | async as service" >
<div *ngIf="activeBreakpoint$ | async as ap" [class]="'is-' + ap">
  <div class="banner">
    <div>assign SIM</div>
  </div>
  <div class="assign-page-wrapper">
    <section>
      <h3>what is assign SIM?</h3>
      <p>
        As the primary rain<b>one</b> account holder, you can give your 4G mobile phone SIMs to a family member. By assigning it to them,<br> they can
        manage the mobile phone line themselves.
      </p>
      <h3>they’ll be able to:</h3>
      <ul>
        <li>View their balance</li>
        <li>Add their own payment details</li>
        <li>Buy more gigs, minutes & sms anytime</li>
        <li>Port their existing number to rain</li>
      </ul>
      <div>
        <h3>just follow these simple steps:</h3>

        <div class="step-wrapper">
          <div class="step">
            <h3 *ngIf="ap !== 'mobile'">
              step 1
            </h3>
               <h3 *ngIf="ap === 'mobile'">
                1.
              </h3>
            <p>Give the 4G mobile phone SIM to a family member.</p>
          </div>
          <div class="step">
            <h3  *ngIf="ap !== 'mobile'">
              step 2
            </h3>
            <h3 *ngIf="ap === 'mobile'">
              2.
            </h3>
            <p>Enter their name & email address to send them an invitation to rain.</p>
          </div>
          <div class="step">
            <h3  *ngIf="ap !== 'mobile'">
              step 3
            </h3>
            <h3 *ngIf="ap === 'mobile'">
              3.
            </h3>
            <p>They’ll then use the invitation to create an account and associate the SIM.</p>
          </div>
          
        </div>
      </div>
    </section>
    <section>
      
      <form [formGroup]="form">
          <h3>assign 4G mobile phone SIM now:</h3>
        <input type="text" formControlName="name" placeholder="Family member’s name" required />
        <small *ngIf="form.get('name').touched && form.get('name').invalid" class="error-text">Name is required</small>
        <input type="email" formControlName="email" placeholder="Family member’s email address" required />
        <small *ngIf="form.get('email').touched && form.get('email').errors?.required" class="error-text">Email is required</small>
        <small *ngIf="form.get('email').touched && form.get('email').errors?.email" class="error-text">Invalid email</small>
        <p>Message (optional)</p>
        <textarea type="text" class="message-box" formControlName="message" ></textarea>
      </form>
      <div class="sim-wrapper">
      <div class="sim-description">
        <h4>you are assigning {{service[0].name}}</h4>
          <div>
            <img src="assets/images/fourg/4G_rain.png" alt="" />
            <div>
              <h3>{{service[0].name}}</h3>
              <p>every month:</p>
              <ul>
                <li>2 <span>free </span> gigs</li>
                <li>60 <span>free </span> minutes</li>
                <li>100 <span>free </span> sms</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="detail-wrapper">
          <div class="service-detail">
            <p>
              rainone: <span>{{ service[0].productName }}</span>
            </p>
            <p>
              my number: <span>{{ service[0].msisdn }}</span>
            </p>
            <p>
              SIM ID number (ICCID): <span>{{ service[0].iccid }}</span>
            </p>
        </div>
        <div class="button-container">
          <button class="btn" [ngClass]="form.valid? 'btn-primary' : 'btn-grey'" (click)="onSubmit()">assign <span class="text-uppercase" >SIM</span> </button>
        </div>
      </div>
    </div>
    </section>

  </div>
</div>
</ng-container>

<ng-template #modalContent > 
  <div class="mb-4 modal-header border-0 ">
    <div class="modal-img-close-btn z-index-5">
      <img alt="Close" aria-label="Close" class="pointer" (click)="dismiss()" src="../../assets/svg/close.svg" />
    </div>
  </div>
  <div class="modal-body border-0 p-5 text-center">
    <div class="p-2">
      <h2 class="text-primary">success!</h2>
    </div>
   <div class="p-2">The notification has been sent to {{form.get('name')}}</div>
  </div>
  
  <div class="p-8 mb-10 text-center" >
    
    <button class="btn btn-sm btn-primary mx-5"
      (click)="dismiss()">got it</button>
  </div>
</ng-template>