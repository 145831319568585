import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { BottomSheetName } from '../interfaces/bottom-sheet.interfaces';
import { MatBottomSheet,MatBottomSheetConfig  } from '@angular/material/bottom-sheet';
import { BottomSheetContainerComponent } from '../../components/bottom-sheet-components/bottom-sheet-container/bottom-sheet-container.component';
import { ChangeOpenBottomSheet, CloseBottomSheet, ShowBottomSheet } from '../actions/bottom-sheet.actions';

function loadDefault():BottomSheetStateModel {
    return {
        openSheetName: null,
        isOpen: false
    }
}

interface BottomSheetStateModel {
    openSheetName: BottomSheetName | null;
    isOpen: boolean
    data?: any,
}
@State<BottomSheetStateModel>({
    name: 'BottomSheetState',
    defaults: loadDefault()
})

@Injectable()
export class BottomSheetState {

    @Selector()
    static OpenSheetName(state: BottomSheetStateModel) { return state.openSheetName }
    @Selector()
    static GetSheetData(state: BottomSheetStateModel) { return state.data }

    constructor(private bottomSheet: MatBottomSheet, private readonly store: Store) { }    

    @Action(ShowBottomSheet)
    showBottomSheet(ctx: StateContext<BottomSheetStateModel>, action: ShowBottomSheet) {
        const { openSheetName, data } = action;
        
        ctx.patchState({
            openSheetName: openSheetName,
            isOpen: true,
            data
        });
        
        const config: MatBottomSheetConfig = {
            hasBackdrop: true,         
            disableClose: false
        };

        return this.bottomSheet.open(BottomSheetContainerComponent, config);
    }

    @Action(ChangeOpenBottomSheet)
    changeOpenSheet(ctx: StateContext<BottomSheetStateModel>, action: ChangeOpenBottomSheet) {
        const { openSheetName, data } = action;

        ctx.patchState({
            openSheetName: openSheetName,
            data
        });
    }

    @Action(CloseBottomSheet)
    closeBottomSheet(ctx: StateContext<BottomSheetStateModel>, action: CloseBottomSheet) {
        this.bottomSheet._openedBottomSheetRef?.dismiss()
        
        return ctx.patchState({
            openSheetName: null,
            isOpen: false
        });
    }

}