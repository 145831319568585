import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../baseComponent';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseComponent implements OnInit {
  private _name = '';
  private _checkboxId = '';
  private _checkboxValue = false;

  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  addressVerified: boolean;
  mustEnterAddress: boolean;

  @Input()
  set name(name: string) {
    this._name = name;
  }

  get name(): string {
    return this._name;
  }

  @Input()
  set checkboxId(checkboxId: string) {
    this._checkboxId = checkboxId ? checkboxId : 'customCheck1';
  }

  get checkboxId(): string {
    return this._checkboxId;
  }

  @Input() set checkboxValue(cb: boolean) {
    this._checkboxValue = cb;
  }

  public agreedValue: boolean = false;

  public mustVerifyAddress = false;

  constructor() {
    super();
  }

  ngOnInit() { }

  public toggleCheckbox(event) {
    event.stopPropagation();
    this.agreedValue = !this.agreedValue;

    if (this.agreedValue) {
      this.mustVerifyAddress = false;

      $('#pop-address-error-checkbox').hide('fast');
    }

    this.onChange.emit(this.agreedValue);
  }
}
