import { isEmpty } from "lodash"
import { IntlCallingResDetail, IntlCallingStateModel } from "src/app/store/state/intl-calling.state"

export interface IntlCallResBodyRemote{
    result: {
        page: number
        page_size: number
        total_pages: number
        total_results: number
        results: IntCallResDetailRemote []
    }
}

export interface IntCallResDetailRemote{
            id: string
            provider: string
            country: string
            number_prefix: string
            rate_per_minute: number
            extra: string
}

export class IntlCallResAdapter {
    public static adapt(remoteRes: IntlCallResBodyRemote): IntlCallingStateModel
    {
        const results = isEmpty(remoteRes.result.results)? [] : remoteRes.result.results.map(remoteProvider => IntlCallResDetailAdapter.adapt(remoteProvider))
        return {
            currentPage: remoteRes.result.page,
            results,
            totalPages: remoteRes.result.total_pages,
            totalResults: remoteRes.result.total_results
            
        }
    }
}


export class IntlCallResDetailAdapter {
    public static adapt(remoteResDetail: IntCallResDetailRemote ): IntlCallingResDetail
    {
        return {
            country: remoteResDetail.country,
            extra: remoteResDetail.extra,
            id: remoteResDetail.id,
            numberPrefix: remoteResDetail.number_prefix,
            provider: remoteResDetail.provider,
            ratePerMinute: remoteResDetail.rate_per_minute
        }
    }
}

