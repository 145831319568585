import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { CACHE_AUTH_TOKEN } from '@models/constants';
import { Observable } from 'rxjs';
import { Result } from '@models/result';
import { IAssociateStatus } from '@models/asociate-status.interface';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AssignAndClaimSimService {
  constructor(private _http: HttpClient, private tokenSVC: TokenService, private configService: ConfigService) {}

  // get pending association as owner
  getPendingAssignmentByServiceId(serviceId): Observable<IAssociateStatus> {
    const url: string = `${this.configService.BASE_API_URL}/V1/rain-web/service-association-service/service-assignment/_filter?service_id=${serviceId}&status=PENDING&status=CLAIMED`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      }),
      // params: new HttpParams()
      //   .set('service_id ', serviceId)
      //   .set('status', 'CLAIMED')
      //   .set('status', 'PENDING')
    };

    return this._http.get<IAssociateStatus>(url, httpOptions);
  }

  // associate service as owner
  associateSim(body: any): Observable<{}> {
    const url: string = `${this.configService.BASE_API_URL}/V1/rain-web/service-association-service/service-assignment`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post(url, body, httpOptions);
  }

  // claim sim as receiver /// the id is the Service association id(SaId)
  claimSim(SaId: string, body: any): Observable<{}> {
    const url: string = `${this.configService.BASE_API_URL}/V1/rain-web/service-association-service/service-assignment/${SaId}/_claim`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post(url, body, httpOptions);
  }


  // check Association by SaId
  getPendingAssignmentBySaId(SaId: string) {
    const url: string = `${this.configService.BASE_API_URL}/V1/rain-web/service-association-service/service-assignment/${SaId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<IAssociateStatus>(url, httpOptions);
  }

  //remove pending claim by user_id
    removeAssociateRqst(SaId: string): Observable<{}> {
      const url: string = `${this.configService.BASE_API_URL}/V1/rain-web/service-association-service/service-assignment/${SaId}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
        })
      };
  
      return this._http.delete(url, httpOptions);
    }
  }
