import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { ClearCart } from 'src/app/store/actions/cart.action';

@Component({
  selector: 'rain-create-work-account-modal',
  templateUrl: './create-work-account-modal.component.html',
  styleUrls: ['./create-work-account-modal.component.scss']
})
export class CreateWorkAccountModalComponent implements OnInit {
  @Output() createWorkAccount: EventEmitter<any> = new EventEmitter();
  constructor(private activeModal: NgbActiveModal,
    private store: Store) { }

  ngOnInit(): void {
  }

  public close() {
    this.store.dispatch([new ClearCart()]);
    this.activeModal.close();
    return this.store.dispatch(new Navigate(['/']));
  }

  createAccount(create) {
    this.createWorkAccount.emit(create);
    this.store.dispatch(new Navigate(['/company-details'])).toPromise().then(() => {
      this.activeModal.close();
    }); 
  }
}
