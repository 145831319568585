import { Injectable } from '@angular/core';

export const CustomBreakpointNames = {
    mobile: 'mobile',
    ipad: 'ipad',
    ipadPro: 'ipadPro',
    desktop: 'desktop',
};

export type BreakPoint = keyof typeof CustomBreakpointNames;

@Injectable({
    providedIn: 'root'
})
export class BreakpointsService {
    breakpoints: object = {
        '(min-width: 375px)': CustomBreakpointNames.mobile,
        '(min-width: 428px)': CustomBreakpointNames.mobile,
        '(max-width: 710px)': CustomBreakpointNames.mobile,
        '(min-width: 720px)': CustomBreakpointNames.ipad,
        '(min-width: 1024px)': CustomBreakpointNames.ipadPro,
        '(min-width: 1248px)': CustomBreakpointNames.desktop,
        '(min-width: 1440px)': CustomBreakpointNames.desktop
    };

    getBreakpoints(): string[] {
        return Object.keys(this.breakpoints);
    }

    getBreakpointName(breakpointValue: BreakPoint): BreakPoint {

        return this.breakpoints[breakpointValue];
    }

    constructor() {
    }
}
