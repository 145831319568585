import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckCoverageComponent } from './check-coverage.component';
import { MapModule } from '@components/map/map.module';

@NgModule({
    declarations: [
        CheckCoverageComponent
    ],
    imports: [
        CommonModule,
        MapModule
    ],
    exports: [CheckCoverageComponent],
    providers: [],
})
export class CheckCoverageModule { }
