import { OnDestroy, Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base',
  template: ''
})

export class BaseComponent implements OnDestroy {

  protected ngUnsubscribe = new Subject<void>();

  ngOnDestroy(): void {
    $('body').removeClass('modal-open');
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
