import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IUserDetail } from '@models/userDetail';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { CookieService } from '@services/cookie.service';
import { SupportService, IAddTicketRequest } from '@services/support.service';
import { isNil } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'rain-nvidia-sales-modal',
  templateUrl: './nvidia-sales-modal.component.html',
  styleUrls: ['./nvidia-sales-modal.component.scss']
})
export class NvidiaSalesModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private supportService: SupportService, private toastr: ToastrService, private cookieService:CookieService) {}
  @Select(CoreState.getUserProfile) userProfile$: Observable<IUserDetail>;

  nvidiaSalesForm: FormGroup;
  ticketRequest: IAddTicketRequest;
  private userDetails: IUserDetail;
  loading: boolean;

  ngOnInit(): void {
    this.userProfile$.subscribe((userDetail: IUserDetail) => {
      this.userDetails = userDetail;
    });
    this.nvidiaSalesForm = new FormGroup({
      name: new FormControl(`${this.userDetails.firstName} ${this.userDetails.lastName}`, [Validators.required]),
      number: new FormControl(this.userDetails.phone, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      email: new FormControl(this.userDetails.email, [Validators.required, Validators.email]),
      questions: new FormControl('', [Validators.required])
    });
  }
  submitSalesRequest() {
    const subject = 'NVIDIA SALES REQUEST';

    const body = {
      message: `I would like assistance purchasing the NVIDIA product`
    };
    this.ticketRequest = {
      name: this.nvidiaSalesForm.value.name,
      email: this.nvidiaSalesForm.value.email,
      subject : subject,
      body,
      captcha: '-',
      referenceId: this.userDetails.id,
      ga_tag: this.cookieService.getGATag()
    };
    this.submitForm();
  }

  submitForm() {
    this.supportService.addTicket(this.ticketRequest).subscribe((result: any) => {
      this.loading = false;

      if (isNil(result) === false) {
        if (!result?.ticket) {
          setTimeout(() => this.toastr.error('An error occurred while submitting. Please try again', null));
          return;
        } else {
          this.activeModal.close();
        }
      }
    });
  }
}
