import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { Result, Statuses } from '@models/result';
import { UserDetail } from '@models/userDetail';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@services/user.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
@Component({
  selector: 'app-confirm-contact-details-modal',
  templateUrl: './confirm-contact-details-modal.component.html',
  styleUrls: ['./confirm-contact-details-modal.component.scss']
})
export class ConfirmContactDetailsModalComponent extends BaseComponent implements OnInit {

  @Output() submitted: EventEmitter<any> = new EventEmitter();
  userForm: FormGroup;
  formSubmit = false;
  userDetails: any;

  nameValidationMessages: ValidationErrors = {
    required: 'First name is required.',
    pattern: 'First name must contain at least 2 characters.'
  };
  lastNameValidationMessages: ValidationErrors = {
    required: 'Last name is required.',
    pattern: 'Last name must contain at least 2 characters.'
  };
  emailValidationMessages: ValidationErrors = {
    required: 'Email is required.',
    pattern: 'Please enter a valid email address.',
  };
  cellNumberValidationMessages: ValidationErrors = {
    required: 'Cell number is required.',
    pattern: 'Please enter a valid cell number.'
  };

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    this.getUserService();
  }

  getUserService() {
    this.userService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: Result<UserDetail>) => {
        if (result.status != Statuses.Success) {
          this.toastr.error('An error occurred while processing the request', null);
          return;
        } else {
          this.userDetails = result.value;
          this.userForm = this.fb.group({
            firstName: this.fb.control(this.userDetails?.firstName || null, Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])),
            lastName: this.fb.control(this.userDetails?.lastName || null, Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])),
            phoneNumber: this.fb.control(this.userDetails?.phone || null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$|^[0-9]{12}$')])),
            email: this.fb.control(this.userDetails?.email || null, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]))
          })
        }
      });
  }

  submit() {
    this.formSubmit = true;

    if (this.userForm.invalid) {
      return;
    } else {

      const data = {
        firstName: this.userForm.value.firstName,
        lastName: this.userForm.value.lastName,
        email: this.userForm.value.email,
        phone: this.userForm.value.phoneNumber,
        idNumber: this.userDetails.idNumber || this.userDetails?.passportNumber
      }

      this.updatePersonalDetails(data);
    }

  }

  updatePersonalDetails(request: any) {
    this.userService
      .update(request)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: Result<boolean>) => {
        if (result.status != Statuses.Success) {
          this.toastr.error('An error occurred while processing the request', null);
        } else {
          this.activeModal.dismiss();
          this.successModal();
        }
      });
  }


  successModal() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'md' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title: 'success',
      description: 'Your details have been saved.',
      buttonName: 'ok'
    };

  }

}