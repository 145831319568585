<div class="mb-10 modal-header border-0 ">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
      src="../../assets/svg/close.svg" />
  </div>
</div>
<div class="modal-body border-0 p-5">
  <div class="text-center">
    <h2 class="text-primary">let's investigate</h2>
    <p class="mb-0">
      Please provide the detail of the issue you are experiencing.
    </p>
    <p>We will investigate and get back to you as soon as possible.</p>
  </div>
  <form [formGroup]="form" class="m-10 mt-20">
    <div class="form-group" *ngIf="showAddress">
      <sf-google-placesform-input [messages]="addressValidationValidationMessages"
        placeholder="please confirm the address where you are currently using rain" [control]="form.get('address')"
        (onAddressChange)="handleAddressChange($event)">
      </sf-google-placesform-input>

      <div *ngIf="formSubmit && form.controls.address.errors">
        <div *ngIf="form.controls.address.errors.required">
          <span class="text-danger small-font-size">address is required.</span>
        </div>
        <div *ngIf="!form.controls.address.errors.required && form.controls.address.errors.invalid">
          <span class="text-danger small-font-size">Please enter a valid street address.</span>
        </div>
      </div>
    </div>

    <div class="form-group mt-5">
      <h3 class="text-primary mb-5">comment:</h3>
      <textarea class="bg-gray" id="text-block" formControlName="content"></textarea>
      <div *ngIf="formSubmit && form.controls.content.errors">
        <div *ngIf="form.controls.content.errors.required">
          <span class="text-danger small-font-size">Please enter the details.</span>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer text-center d-flex justify-content-end border-0">
  <button class="btn btn-sm btn-primary" (click)="submit()" [disabled]="loading">
    <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    <span *ngIf="!loading">Submit</span>
  </button>
</div>