<div class="nvidia-sales-modal-container">
    <div class="close-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <br>
    <div class="page-title">
        <h2>not sure what to do?</h2>
    </div>
    <div class="page-content">
        <p>
            Leave your details with us and one of our sales agents will be in touch shortly.
        </p>
    </div>
    <form [formGroup]="nvidiaSalesForm">
        <div class="sales-forms">
            <div class="name-input-container">
                <input type="text" placeholder="Full name " formControlName="name" />
            </div>
            <div class="email-input-container">
                <input type="email" placeholder="Email address" formControlName="email" />
            </div>
            <div class="email-input-container">
                <input type="tel" placeholder="Contact number " formControlName="number" />
            </div>
            <div class="questions-input-container">
                <label for="questions">Questions</label>
                <input name="questions" type="text" formControlName="questions" />
            </div>
        </div>

        <div class="button-container">
            <button (click)="submitSalesRequest()" type="submit">submit</button>
        </div>
    </form>
</div>