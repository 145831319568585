import { Utils } from "src/app/utils";
import { BarcodeRefSMSPayload } from "../../services/sms/barcode-sms.interface";


export class SendBarcodeRefSMS {
    static readonly type = Utils.Helpers.Type('[Core: SMS] Send barcode reference');
    constructor(public readonly payload: Omit<BarcodeRefSMSPayload, 'name'>) { }
}

export class SendBarcodeRefSMSSuccess {
    static readonly type = Utils.Helpers.Type('[Core: SMS] Send barcode reference success');
    constructor(public readonly payload: any) { }
}

export class SendBarcodeRefSMSFail {
    static readonly type = Utils.Helpers.Type('[Core: SMS] Send barcode reference fail');
    constructor(public readonly payload: any) { }
}
