<div>
    <div class="mb-10 modal-header border-0 ">
        <div class="modal-img-close-btn z-index-5">
            <img alt="Close" aria-label="Close" class="pointer" (click)="close()" src="../../../assets/svg/close.svg" />
        </div>
    </div>

    <div class="modal-body border-0 text-center">

        <div class="copy">
            <h2 class="text-primary font-weight-bold my-5">score! you’re about to claim your FREE ultra-fast 5G</h2>

            <p class="mb-0">Please enter the voucher code we sent<br />you by email.</p>
        </div>

        <div class="form-lockup">
            <sf-form-input [control]="voucherForm.get('voucher_code')" [label]="'voucher number'"></sf-form-input>

            <div>
                <label class="accept" [ngClass]="{'text-primary font-weight-bold': voucherForm.get('terms').value}">
                    <input type="checkbox" [formControl]="voucherForm.get('terms')" [checked]="voucherForm.get('terms').value">
                    <span class="checkmark"></span>
                    <span class="accept-text text-primary">accept <a class="text-primary underline"
                            (click)="viewTsAndCs()">T's
                            and C's</a></span>
                </label>
            </div>
        </div>
    </div>


    <div class="text-center border-0 mb-20">
        <button class="btn btn-sm btn-primary mx-auto" (click)="onSubmit()" [disabled]="voucherForm.invalid">submit</button>
    </div>
</div>