<div class="modal-container" *ngIf="service">
    <div class="modal-content-wrapper">
        <div class="close-modal">
            <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
                (click)="onPurchaseReject()" />
        </div>
        <br>
        <div class="modal-title">
            <span>game on with rain<strong>one</strong>.</span>
            
        </div>
            <div class="bottom-sub-content">
                <span class="name-tag">hey {{userName}}</span>
                <p>Great news! Get your game on with GeForce NOW, with unlimited 5G home wifi from rain<b>one</b>.
                </p>
                <p> With rain<b>one</b>, you get unlimited 5G home wifi, PLUS two 4G mobile SIMs, each with 60 FREE minutes,
                     2 FREE gigs and 100 FREE sms every month. With national 4G mobile coverage on our new 4G mobile network.  </p>
            </div>
        
        <div class="modal-image-wrapper">
            <div class="modal-image">
                <img *ngIf="productType === '5G' else is4G" src="assets/images/nvidia/nvidia-migration-image.png" alt="5G upsell image">
                <ng-template #is4G>
                    <img src="/assets/images/nvidia/nvidia-shop-image.png" alt="4G upsell image">
                </ng-template>
            </div>

            <div class="bottom-sub-content">
             
            </div>

            <div class="terms-and-conditions">
                <span class="terms" (click)="gotoTerms()">Ts & Cs apply</span>
            </div>
        </div>
        <div class="button-container">
            <div class="right-buttons">

                <app-support-button 
                    btnClass="btn btn-sm btn-transparent border-primary text-primary on-register "
                    [dataId]="'btn-click'" [isPromo]="false" fromPage="coverage check" fromButton="productCallMe"
                    title="request sales call" id="btn-cust" [subject]="'rain sales support #'" [bypassModal]="false"
                    [productId]="service.productId" [showSignIn]="false" (click)="onOpenSupport()">
                </app-support-button>
                <button (click)="onContinueToBuy()" class="btn btn-sm btn-primary text-white buy-btn">buy</button>
            </div>
        </div>
    </div>
</div>