import {Utils} from "@Utils";

export class GetPaymentDate {
    static type = Utils.Helpers.Type('[PAYMENTDATE] GetDetails');
    constructor() { }
}

export class SetPaymentDate {
    static type = Utils.Helpers.Type('[PAYMENTDATE] Set Payment Date');
    constructor(public date: any) { }
}

export class GetFailedPayments {
    static type = Utils.Helpers.Type('[PAYMENTS] GetFailedPayments');
    constructor(public userId: string) { }
}

export class GetInvoices {
    static type = Utils.Helpers.Type('[INVOICES] GetAll');
    constructor() { }
}

export class GetActivePaymentMethod {
    static type = Utils.Helpers.Type('[PAYMENT METHOD] Get Method');
    constructor() { }
}

export class ClearBillingState {
    static type = Utils.Helpers.Type('[BILLING] Clear State');
    constructor() { }
}