import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {SetLoading} from "../actions/ui.actions";

export interface UIStateModel {
    loading: boolean;
}

@State<UIStateModel>({
    name: 'uiV2',
    defaults: {
        loading: false,
    }
})
@Injectable({
    providedIn: 'root'
})
export class UIStateV2 {

    @Selector()
    static getLoading(state: UIStateModel) {
        return state.loading;
    }

    @Action(SetLoading)
    setPaymentDate(ctx: StateContext<UIStateModel>, action: SetLoading) {
        return ctx.patchState({
            loading: action.loading
        });
    }
}