<section *ngIf="(fallbackCopy$ | async).popup_modal as copy">
    <div class="top-bar">
        <i (click)="close()">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <polygon id="path-1"
                        points="11.7181555 12.9694174 11.7181555 14.0494174 7.72215549 14.0494174 7.72215549 18.0994174 6.55215549 18.0994174 6.55215549 14.0494174 2.55615549 14.0494174 2.55615549 12.9694174 6.55215549 12.9694174 6.55215549 8.9194174 7.72215549 8.9194174 7.72215549 12.9694174">
                    </polygon>
                </defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="remove" transform="translate(-5.000000, -3.000000)">
                        <circle id="Oval-5" fill="#ECECEC" fill-rule="nonzero" cx="17" cy="15" r="12"></circle>
                        <g id="+"
                            transform="translate(16.315864, 16.611806) rotate(45.000000) translate(-16.315864, -16.611806) translate(8.815864, 1.611806)">
                            <g>
                                <use fill="#000000" xlink:href="#path-1"></use>
                                <use fill="#9B9B9B" xlink:href="#path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </i>
    </div>
    <div class="body">
        <h2 [innerHTML]="copy.title"></h2>

        <div [innerHTML]="copy.body"></div>
    </div>

    <div class="footer">
        <ul>
            <li>
                <button class="btn btn-sm btn-primary text-white" id="check-coverage" role="button"
                    (click)="close()">got it</button>
            </li>
        </ul>
    </div>
</section>