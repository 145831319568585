import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonV2Component} from "./button-v2.component";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    declarations: [ButtonV2Component],
    exports: [
        ButtonV2Component
    ],
    providers: [],
    imports: [
        CommonModule,
        MatIconModule,
    ]
})
export class ButtonV2Module {
}
