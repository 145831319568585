export interface ICreateEricaRequest {
    orderNumber: string;
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    idNumber: string;
    buildingComplex?: string;
    addressLine1?: string;
    addressLine2?: string;
    province?: string;
    postCode?: string;
    country?: string;

    notificationType?: NotificationTypes
}

export enum NotificationTypes {
    None = 0,
    SMS,
    Email
}