<div class="modal-header mb-10 border-0">
  <div class="modal-img-close-btn z-index-5 border-0 mb-5" (click)="activeModal.close(false)">
    <img src="assets/svg/close.svg" id="closeButton" class="pointer" alt="Close" />
  </div>
</div>

<div class="modal-body  m-0 mt-10 mb-10 border-0">
  <div class=" text-center">
    <h2 class="text-primary  pb-5 ">Updating payment details…</h2>
    <p class="pt-5">
      {{ message }}
    </p>
  </div>
</div>
<div class="modal-footer border-0" *ngIf="route">
  <button class="btn btn-sm btn-primary" (click)="gotoOrders()">{{ routeText }}</button>
</div>
