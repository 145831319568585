import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_AUTH_TOKEN } from '@models/constants';
import { ConfigService } from '@services/config.service';
import { TokenService } from '@services/token.service';
import { UserAddressPayload } from '../interfaces/user-address.interface';

@Injectable({
  providedIn: 'root'
})
export class UserAddressService {

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private tokenSVC: TokenService
  ) { }

  public setAddress(address: UserAddressPayload) {
    const url: string = `${this.configService.BASE_API_URL}/V1/rain-web/user-information-service/user-address`;
    // const url: string = `https://sit-bss-api.rain.network/V1/rain-web/user-information-service/user-address`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post(url, address, httpOptions);
  }
}
