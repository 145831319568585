<div class="cancel-four-g-success p-15">
	<div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
          data-dismiss="modal" data-target="#surveyModal" src="../../assets/svg/close.svg" />
	</div>
	<div class="heading">
		<h1 class="text-primary text-center h1-text-transform">your SIM cancellation is successful</h1>
	</div>

	<div class="text-content text-center mt-10">
		<ng-container *ngIf="hasPostPaid$ | async; else Upfront">
			<p>Cancellation is scheduled for the end of this month and your final payment for this SIM will be processed on your selected date next month.</p>
		</ng-container>
		<ng-template #Upfront>
			<p>Your SIM cancellation is scheduled for the end of your billing cycle, and your services will be available until then. No payment is scheduled to be collected.</p>
		</ng-template>
	</div>

	<div class="actions d-flex justify-content-center mt-10">
		<button-v2 class="mx-2" [buttonClass]="'cancel-modal-btn'" [text]="'got it'" (click)="close()"></button-v2>
	</div>
</div>