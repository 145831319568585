import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '@services/cart.service';
import { DataLayerService } from '@services/data-layer.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { ProductService } from '@services/product.service';
import { CACHE_GENERATE_VOUCHER } from '@models/constants';

@Component({
  selector: 'app-remove-cart-item-modal',
  templateUrl: './remove-cart-item-modal.component.html',
  styleUrls: ['./remove-cart-item-modal.component.scss']
})
export class RemoveCartItemModalComponent extends BaseComponent implements OnInit {
  @Input() id: string;
  @Output() confirmedEmitter = new EventEmitter<boolean>();

  constructor(
    public activeModal: NgbActiveModal,
    private cartService: CartService,
    private dataLayerservice: DataLayerService,
    private productService: ProductService) { super(); }

  ngOnInit() {
  }

  confirmed() {

    this.cartService.getMappedItems()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(products => {
        // Track removed product
        const removedProductIndex = products.findIndex(i => {
          return i.item.id === this.id;
        });

        if (removedProductIndex > -1) {
          this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
          this.confirmedEmitter.next(true);
        }

        if(this.id === '8'){
          localStorage.removeItem(CACHE_GENERATE_VOUCHER);
         }
         
        this.cartService.remove(products[removedProductIndex])
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(result => {
            this.activeModal.close();
          });

      });



  }
}
