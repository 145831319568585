/* eslint-disable @typescript-eslint/no-explicit-any */


const MAX_TRIES = 10;

export interface FindErrorOptions {
    extraKeys?: string[];
}

/**
 * Inspects an error object and returns the error string, or defaultError if no value was found.
 */
export function findError(errorObj: Record<string, any> | string, defaultError = '', options?: FindErrorOptions, currentTry = 0): string {
    const keysToCheck: string[] = [
        "error", "errors", "error_description", "errorMessage", "errormessage", "errorcode", "data",
        "message", "detail", "details", "response", "reason", "value", "fault", "faultstring",
        ...(options?.extraKeys ?? [])
    ];

    currentTry += 1;

    if (!errorObj || currentTry >= MAX_TRIES || typeof errorObj === "number") {
        return defaultError;
    }

    if (typeof errorObj === "string") {
        return errorObj;
    }

    if (Array.isArray(errorObj)) {
        if (!errorObj.length) {
            return defaultError;
        }
        return findError(errorObj[0], defaultError, options, currentTry);
    }

    if (typeof errorObj === "object") {
        for (const key of keysToCheck) {
            if (errorObj?.[key]) {
                return findError(errorObj[key], defaultError, options, currentTry);
            }
        }

    }

    //Fallback if nothing matches
    return defaultError;

}
