import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { TokenService } from './token.service';
import { CACHE_AUTH_TOKEN, NVIDIA_ASSETS_QUERY } from '@models/constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { Store } from '@ngxs/store';
import { SetSelectedServiceByID } from '../store/actions/services.actions';
import { RainOneProduct } from '../core/interfaces/rain-one-products.interface';
import { ProductState } from '../store/state/product.state';
import { WaitingListRequestBody } from '@pages/nvidia-landing-page/landing-page/nvidia-waiting-list.interface';
import { shareReplay, tap } from 'rxjs/operators';
import { NvidiaFetchObject, NvidiaFetchObjectImagesModel } from '../core/nvidia.interface';
import { ServicesState } from '../store/state/services.state';

@Injectable({
  providedIn: 'root'
})
export class NvidiaService {
  private graphqlEndpoint = 'https://public.games.geforce.com/graphql';

  appsQuery = NVIDIA_ASSETS_QUERY;

  constructor(
    private http: HttpClient,
    private configSVC: ConfigService,
    private tokenService: TokenService,
    private modalService: NgbModal,
    private store: Store,
    private configService: ConfigService,
    public activeModal: NgbActiveModal
  ) {}

  nvidiaPostHeaderBuilder(after: string = '') {
    return {
      query: `
    {
      apps(country: "US", language: "en_US", after: "${after}") {
          numberReturned
          pageInfo {
              endCursor
              hasNextPage
          }
          items {
              id
              title
              publisherName
              images {
                  KEY_ART
              }
              variants {
                  id
                  appStore
              }
          }
      }
  }
  `
    };
  }

  nvidiaPostHeaderImagesBuilder(after: string = '') {
    return {
      query: `
    {
      apps(country: "US", language: "en_US", after: "${after}") {
          numberReturned
          pageInfo {
              endCursor
              hasNextPage
          }
          items {
              images {
                  KEY_ART
              }
          }
      }
  }
  `
    };
  }

  fetchGameList(after?: string): Observable<NvidiaFetchObject> {
    const requestBody = this.nvidiaPostHeaderBuilder(after);
    return this.http.post<NvidiaFetchObject>(this.graphqlEndpoint, requestBody).pipe(shareReplay());
  }

  fetchGameListImages(after?: string): Observable<NvidiaFetchObjectImagesModel> {
    const requestBody = this.nvidiaPostHeaderImagesBuilder(after);
    return this.http.post<NvidiaFetchObjectImagesModel>(this.graphqlEndpoint, requestBody).pipe(shareReplay());
  }

  checkPaginationComplete(response: NvidiaFetchObject) {
    return response && response.data.apps.pageInfo.hasNextPage;
  }

  /**
   * @param {{email: string}} payload
   * @return {*}  {Observable<{result: {is_whitelisted: boolean}}>}
   * @memberof NvidiaService
   */
  public CheckWhitelist(payload: { email: string }): Observable<{ result: { is_whitelisted: boolean } }> {
    if (this.configSVC.SURVEY_URL) {
      const url: string = `${this.configSVC.SURVEY_URL}/whitelist/check/nvidia/${payload.email}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
        })
      };

      return this.http.get<{ result: { is_whitelisted: boolean } }>(url, httpOptions);
    }
  }

  cancelService(request: { serviceId: string; reason: string }) {
    let payload = {
      reason: request.reason,
      type: 'scheduled'
    };
    const requestPath = `${this.configSVC.SERVICE_PROXY_API}/service/${request.serviceId}/_cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };

    return this.http.post(requestPath, payload, httpOptions as any);
  }
  
  public revokeNvidiaCancellation(serviceId: string): Observable<any> {
    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${serviceId}/_revoke-cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };
    return this.http.post(requestPath, {}, httpOptions as any)
  }


  cancelModal(billingDay) {
    let date = new Date();
    const currentDay = new Date().getDay();
    const currentMonth = new Date().getMonth();
    date.setMonth(billingDay > currentDay ? currentMonth : currentMonth + 1);
    date.setDate(billingDay);
    const formattedDate = date.toLocaleDateString('en-gb', { day: 'numeric', month: 'long' });

    // const month = date.getMonth()
    const description = `With this request, your GeForce NOW membership plan will be scheduled for cancellation on ${formattedDate}. `;
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title: 'heads up!',
      description,
      buttonName: 'cancel'
    };

    return modalRef.componentInstance.accepted;
  }

  cancelDoneModal() {
    const description = `Your GeForce NOW membership plan cancellation will only happen at the end of your bill cycle. If you change your mind between now and then, you can reverse your cancellation.`;
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title: 'we’re sad to see you go',
      description,
      buttonName: 'got it'
    };
  }

  revokePendingActionModal(type: 'cancellation' | 'migration') {
    const description = `Are your sure you want to reverse your ${type}?`;
    const buttonName = 'yes';
    const cancelButtonName = 'no';
    const title = 'are you sure?';

    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title,
      description,
      buttonName,
      cancelButtonName
    };

    return modalRef.componentInstance.accepted;
  }

  revokeActionCompleteModal(type: 'cancellation' | 'migration') {
    const description = `Your ${type} has been reversed successfully`;
    const buttonName = 'got it';
    const title = 'success';

    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title,
      description,
      buttonName
    };
  }

  migrateModal(fromProduct: string, toProduct: string) {
    const description = `You are moving from <b>${fromProduct}</b> to <b>${toProduct}</b>. <br>
    Your new plan will take effect at your next bill`;
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title: 'confirm your plan change',
      description,
      buttonName: 'confirm'
    };

    return modalRef.componentInstance.accepted;
  }

  migrateCompleteModal(product: string) {
    const description = `You have successfully changed to GeForce NOW ${product} membership plan. Your new plan will take effect at your next bill`;
    const buttonName = 'got it';
    const title = 'plan change successful';

    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title,
      description,
      buttonName
    };
    setTimeout(() => {
      this.store.dispatch(new SetSelectedServiceByID());
    }, 1000);
  }

  getMigrateProductId(productTier: RainOneProduct, currentServiceProductId: string) {
    const migrationOptions = this.store.selectSnapshot(ProductState.GetNvidiaProductById(currentServiceProductId)).config.migration;
    const migrationId = migrationOptions.map(product => (product.name.includes(productTier.name) ? product.id : null))[0];
    return migrationId;
  }

  AddToNvidiaWaitingList(body: WaitingListRequestBody): Observable<WaitingListRequestBody> {
    const url: string = `${this.configService.BASE_API_URL}/waiting-list/add`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<WaitingListRequestBody>(url, body, httpOptions);
  }

  migrateNvidiaService(currentServiceId: string, request: { product_id: string }) {
    const requestPath = `${this.configSVC.SERVICE_PROXY_API}/service/${currentServiceId}/_migrate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };

    return this.http.patch(requestPath, request, httpOptions);
  }
}
