import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_AUTH_TOKEN } from '@models/constants';
import { Store } from '@ngxs/store';
import { ConfigService } from '@services/config.service';
import { TokenService } from '@services/token.service';
import { Utils } from 'src/app/utils';
import { CoreState } from '../../store/state/core.state';
import { BarcodeRefSMSPayload } from './barcode-sms.interface';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(private _http: HttpClient, private store: Store, private configService: ConfigService, private tokenService: TokenService) { }

  public sendBarcodeRef(msgDetails: BarcodeRefSMSPayload) {
    const url = this.configService.IN_STORE_SMS;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    const data = {
      name: msgDetails.name,
      reference: msgDetails.reference,
      msisdn: Utils.Formatters.formatContactNumber(msgDetails.number)
    }

    return this._http.post(url, data, httpOptions);
  }


}
