import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isNil } from 'lodash';
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, timeout } from "rxjs/operators";
import { CACHE_DEVICE, CACHE_IDM_TOKEN } from '@models/constants';
import { DeviceDetail, IDeviceDetail } from "@models/deviceDetail";
import { IRemoteDevice } from "@models/remote/remoteDevice";
import { Result, Statuses } from "@models/result";
import { AuthenticationService } from "./auth.service";
import { CacheService } from "./cache.service";
import { ConfigService } from './config.service';
import { ServiceHelper } from "./serviceHelper";
import { TokenService } from "./token.service";
import { TowerService } from './tower.service';
import { environment } from "src/environments/environment";

class RemoteDeviceResult {
  response: IRemoteDevice;
}
@Injectable({
  providedIn: "root"
})
export class DeviceService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private tokenService: TokenService,
    private cacheService: CacheService,
    private authService: AuthenticationService,
    private towerService: TowerService
  ) { }

  get(msisdn: string, ignoreCache: boolean = false): Observable<Result<IDeviceDetail>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error("Not Signed In"));
    }

    if (ignoreCache === false && this.isCached(msisdn)) {
      const deviceDetail = this.getCache(msisdn);

      return of(Result.success(deviceDetail));
    }

    const requestPath = `${this.configService.SICAP}` + "/mobileinformationbymsisdn?MSISDN=" + msisdn;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_IDM_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      switchMap((result: RemoteDeviceResult) => {

        const deviceDetail = DeviceDetail.adapt(result.response);

        if (isNil(deviceDetail.imei)) {

          if (environment.config !== 'sit') {
            return this.towerService.getSubscriberDetail(msisdn)
              .pipe(
                map(subResult => {
  
                  if (subResult.status == Statuses.Success) {
  
                    return Result.success({
                      imei: subResult.value.imei,
                      msisdn: msisdn,
                      model: null,
                      brandLogo: null,
                      brandName: null,
                      image: null
                    } as IDeviceDetail);
  
                  }
                  else {
  
                    return Result.notFound<IDeviceDetail>();
                  }
  
                })
              );  
            }
            
            
            this.setCache(deviceDetail.msisdn, deviceDetail);
            return of(Result.success(deviceDetail));
        }
          
        else {

          this.setCache(deviceDetail.msisdn, deviceDetail);

          return of(Result.success(deviceDetail));
        }
      }),
      catchError(result => ServiceHelper.handleError<IDeviceDetail>(result))
    );
  }

  setCache(msisdn: string, deviceDetail: IDeviceDetail) {
    this.cacheService.setObject(this.singleCacheKey(msisdn), deviceDetail);
  }

  private singleCacheKey(msisdn: string): string {
    return `${CACHE_DEVICE}${msisdn}`;
  }

  isCached(msisdn: string): boolean {
    return this.cacheService.exists(this.singleCacheKey(msisdn));
  }

  getCache(msisdn: string): IDeviceDetail {
    return this.cacheService.getObject<IDeviceDetail>(
      this.singleCacheKey(msisdn)
    );
  }
}
