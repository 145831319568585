import { Utils } from "src/app/utils";
import { PickupPoint } from "../../interfaces/pickup-locations.interface";
import { RicaStatusCopy } from "../../interfaces/rica.interface";

export class FetchPromoConfig {
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch Promo config');
}
export class FetchTopupConfig {
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch topup config');
}

export class FetchPromoConfigSuccess {
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch Promo config success');
    constructor(public readonly payload: { active: boolean}) { }
}

export class FetchPickupLocations {
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch pickup locations config');
}

export class FetchNvidiaActivated {
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch Nvidia Activated');
}

export class FetchPickupLocationsSuccess {
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch pickup locations config success');
    constructor(public readonly payload: Array<PickupPoint>) { }
}
export class FetchBlackFridayPromoConfig {
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch Black Friday Promo config');
}
export class SetProductsSourceUrl{
    public static type ="[Firebase] Set Products Source Url";
    constructor(public payload: string){}
}

export class FetchLoadSheddingFallbackCopy{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch Loadshedding fallback copy');
}

export class FetchRainFlags{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch rain flags');
}

export class FetchGiveAGigPromoConfig{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch Give A Gig Promo Config');
}
export class FetchRicaStatusCopy{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch RICA status copy');
}

export class FetchRicaStatusCopySuccess{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch RICA status copy success');
    constructor(public readonly payload: Array<RicaStatusCopy>) {}
}

export class FetchT20Productpage{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch T20 product page copy');
}

export class FetchT20Homepage{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch T20 home page copy');
}

export class FetchT2025thOfMonthpage{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch T20 25thOfMonth page copy');
}

export class FetchT20Payment5Gpage{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch T20 Payment5G page copy');
}

export class FetchT20Congratsmodal{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch T20 Congrats modal copy');
}

export class FetchT20HeadsUpmodal{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch Heads Up modal copy');
}

export class FetchT204GVerificationpage{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch 4G SIM Verification page copy');
}

export class FetchPromos{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch promos');
}
export class FetchDefaultSkins{
    static readonly type = Utils.Helpers.Type('[Firebase: Fetch] Fetch default skin');
}

