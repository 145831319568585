import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {tap} from "rxjs/operators";
import {
    ClearBillingState,
    GetActivePaymentMethod,
    GetFailedPayments,
    GetInvoices,
    GetPaymentDate,
    SetPaymentDate
} from "../actions/billing.action";
import {BillingServiceV2} from "../service/billing.service";
import {PaymentStatusObject} from "../../../core/store/state/billing.state";

export interface BillingStateModel {
    paymentDate: {};
    paymentMethod: {};
    failedPayment: {};
    invoices: [];
}

@State<BillingStateModel>({
    name: 'billingV2',
    defaults: {
        paymentDate: null,
        paymentMethod: null,
        failedPayment: null,
        invoices: null,
    }
})
@Injectable({
    providedIn: 'root'
})
export class BillingStateV2 {
    constructor(
        private billingService: BillingServiceV2,
    ) {
    }

    @Selector()
    static getPaymentDate(state: BillingStateModel) {
        return state.paymentDate;
    }

    @Selector()
    static getPaymentMethod(state: BillingStateModel) {
        return state.paymentMethod;
    }

    @Selector()
    static getInvoices(state: BillingStateModel) {
        return state.invoices;
    }

    @Action(GetPaymentDate)
    getPaymentDate(ctx: StateContext<BillingStateModel>, action: GetPaymentDate) {
        return this.billingService.getPaymentDate().pipe(
            tap((response: any) => {
                ctx.patchState({
                    paymentDate: response
                });
            })
        );
    }

    @Action(SetPaymentDate)
    setPaymentDate(ctx: StateContext<BillingStateModel>, action: SetPaymentDate) {
        return ctx.patchState({
            paymentDate: action.date
        });
    }

    @Action(GetActivePaymentMethod)
    setActivePaymentMethod(ctx: StateContext<BillingStateModel>, action: GetActivePaymentMethod) {
        return this.billingService.getActivePaymentMethod().pipe(
            tap({
                next: (response)=>{
                    ctx.patchState({paymentMethod: response?.value});
                }
            })
        );
    }

    @Action(GetInvoices)
    getInvoices(ctx: StateContext<BillingStateModel>, action: GetInvoices) {
        return this.billingService.getInvoices().pipe(
            tap((response: any) => {
                ctx.patchState({
                    invoices: response
                });
            })
        );
    }

    @Action(GetFailedPayments)
    GetLastFailedPayment(ctx: StateContext<BillingStateModel>, action: GetFailedPayments) {
        return this.billingService.GetLastFailedPayment(action.userId).pipe(
            tap({
                next: (res: PaymentStatusObject) => {
                    return ctx.patchState({
                        failedPayment: res?.data?.status ? res : null
                    });
                },
                error: (e: unknown) => {
                    return ctx.patchState({
                        failedPayment: null
                    })
                }
            }));
    }

    @Action(ClearBillingState)
    clearBillingState(ctx: StateContext<BillingStateModel>) {
        return ctx.setState({
            paymentDate: null,
            paymentMethod: null,
            failedPayment: null,
            invoices: null,
        });
    }
}
