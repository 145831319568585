import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {SetMobileGigAmount, SetMobileSimsAmount, SetSimPrices} from "../actions/mobile.actions";
import {BillingStateV2} from "./billing.state";
import {AuthenticationService} from "@services/auth.service";
import {GetPaymentDate} from "../actions/billing.action";
import {BillingState, PaymentDateObject} from "../../../core/store/state/billing.state";
import {UserState} from "./user.state";

export interface MobileStateModel {
    simAmount: number;
    priceAfterDiscount: number;
    priceBeforeDiscount: number;
    proRataAmount: number;
    gigAmount: number;
}

@State<MobileStateModel>({
    name: 'mobile',
    defaults: {
        simAmount: 2,
        priceAfterDiscount: 300,
        priceBeforeDiscount: 330,
        proRataAmount: 0,
        gigAmount: 0,
    }
})
@Injectable({
    providedIn: 'root'
})
export class MobileState {
    constructor(private store: Store, private authService: AuthenticationService) {
    }

    @Selector()
    static getSimAmount(state: MobileStateModel) {
        return state.simAmount;
    }

    @Selector()
    static getGigAmount(state: MobileStateModel) {
        return state.gigAmount;
    }

    @Selector()
    static getSimPrices(state: MobileStateModel) {
        return {
            priceAfterDiscount: state.priceAfterDiscount,
            priceBeforeDiscount: state.priceBeforeDiscount
        }
    }

    @Selector()
    static getProRataAmount(state: MobileStateModel) {
        return state.proRataAmount;
    }

    @Action(SetMobileSimsAmount)
    setSimAmount(ctx: StateContext<MobileStateModel>, action: SetMobileSimsAmount) {
        localStorage.setItem('mobileSimAmount', String(action.simAmount));
        let mobileStateModel = ctx.patchState({
            simAmount: action.simAmount
        });
        ctx.dispatch(SetSimPrices);
        return mobileStateModel;
    }

    @Action(SetMobileGigAmount)
    setGigAmount(ctx: StateContext<MobileStateModel>, action: SetMobileGigAmount) {
        return ctx.patchState({
            gigAmount: action.gigAmount
        });
    }

    @Action(SetSimPrices)
    async setSimPrices(ctx: StateContext<MobileStateModel>, action: SetSimPrices) {
        let state = ctx.getState();
        let simAmount = state.simAmount;
        let calculatedAmountWithoutDiscount = simAmount * 165;
        let amountWithDiscount = this.calculateTotalCost(simAmount);
        let proRataAmount = await this.calculateProRateAmount(amountWithDiscount);

        return ctx.patchState({
            priceAfterDiscount: amountWithDiscount,
            priceBeforeDiscount: calculatedAmountWithoutDiscount,
            proRataAmount: proRataAmount,
            simAmount: simAmount
        });
    }

    public calculateTotalCost(quantity: number): number {
        let unitPrice: number;

        if (quantity >= 5) {
            unitPrice = 120;
        } else if (quantity === 4) {
            unitPrice = 130;
        } else if (quantity === 3) {
            unitPrice = 140;
        } else if (quantity === 2) {
            unitPrice = 150;
        } else {
            unitPrice = 165;
        }
        return unitPrice * quantity;
    }

    async calculateProRateAmount(amountWithDiscount: number): Promise<number> {
        let paymentDate = this.store.selectSnapshot(BillingStateV2.getPaymentDate);
        let userDetails = this.store.selectSnapshot(UserState.getUserDetails);
        let billDate = userDetails?.bill_cycle_spec_detail?.cycle_period?.start ?? null;

        if (this.authService.isSignedIn && billDate) {
            return this.calculateProRateAmountGivenDate(amountWithDiscount, {value: billDate});
        }

        if (paymentDate) {
            return this.calculateProRateAmountGivenDate(amountWithDiscount, paymentDate);
        } else {
            if (this.authService.isSignedIn) {
                await this.store.dispatch(new GetPaymentDate()).toPromise();
                paymentDate = this.store.selectSnapshot(BillingStateV2.getPaymentDate);
                return this.calculateProRateAmountGivenDate(amountWithDiscount, paymentDate);
            } else {
                // get from old billing store because the date pickers buttons set it there
                const oldPaymentDate: PaymentDateObject = this.store.selectSnapshot(BillingState.getPaymentDate);
                return this.calculateProRateAmountGivenDate(amountWithDiscount, {value: oldPaymentDate.selectedPaymentDate});
            }
        }
    }

    calculateProRateAmountGivenDate(amountWithDiscount: number, paymentDate: any) {
        const today = new Date();
        let selectedPaymentDate = Number(paymentDate.value);

        // if today is before to chosen payment date then return next month if not return this month
        let determineWhichMonthIsCorrect = today.getDate() > selectedPaymentDate ? today.getMonth() + 1 : today.getMonth();
        let newDate = new Date(today.getFullYear(), determineWhichMonthIsCorrect, selectedPaymentDate);

        // Ensure the payment date is in the future
        if (newDate <= today) {
            return amountWithDiscount;
        }

        // Calculate the number of days in the correct month
        const endOfMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 0);
        const daysInMonth = endOfMonth.getDate();

        // Calculate the number of days from today until the payment date
        const diffTime = Math.abs(newDate.getTime() - today.getTime());
        // subtract 1 because we don't want to count today
        const daysUntilPayment = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

        // Calculate the pro rata amount
        const dailyRate = amountWithDiscount / daysInMonth;
        let deliveryDays = 3;
        let proRataAmount: number;
        if (daysUntilPayment <= deliveryDays) {
            proRataAmount = 1;
        } else {
            proRataAmount = dailyRate * (daysUntilPayment - deliveryDays);
        }

        return Math.round(proRataAmount * 100) / 100;
    }
}
