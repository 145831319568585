<div class="modal-content border-0 px-sm-20">
  <div class="modal-header mb-10 border-0">
    <div class="modal-img-close-btn z-index-5 border-0 mb-5" (click)="activeModal.close('Cross click');gotToMyOrders()">
      <img src="../../../assets/svg/remove.svg" id="closeButton" class="pointer" alt="Close" />
    </div>
  </div>

  <div class="modal-body  m-0 mt-10 mb-10 border-0">
    <div class=" text-center">
      <p class="text-primary h2 font-weight-bold">heads up</p>
      <p class="pt-5" [innerHTML]="message">
        
      </p>
    </div>
  </div>

  <div class="modal-footer border-top-0">
    <a *ngIf="showAccount" class="btn btn-sm btn-primary" [routerLink]="['/my-account']"
      (click)="activeModal.close('account')">
      account
    </a>

    <button class="btn btn-sm btn-primary" data-dismiss="modal" (click)="gotToMyOrders()">
      {{isPurchasingNvidia ? 'ok' : 'my orders'}}
    </button>
    <!-- <ng-template #isNotpurchasingNvidia>
      <button class="btn btn-sm btn-primary" data-dismiss="modal" (click)="gotToMyOrders()">
        my orders
      </button>
    </ng-template> -->
  </div>
</div>