import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rica-details-modal',
  templateUrl: './rica-details-modal.component.html',
  styleUrls: ['./rica-details-modal.component.scss']
})

/**
 * RicaDetailsModalComponent  @param
 */
export class RicaDetailsModalComponent implements OnInit {
  constructor(
    /**
     * activeModal @param {NgbActiveModal}
     */
    public activeModal: NgbActiveModal
  ) {}

  /**
   * init app
   */
  ngOnInit() {}
}
