<div class="mb-10 modal-header border-0 ">
    <div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
            src="../../assets/svg/close.svg" />
    </div>
</div>
<div class="modal-body border-0 p-5">
    <div class="text-center">
        <h2 class="text-primary">SIM Swap</h2>
        <p class="mb-10">
            Please provide your delivery details below. Our couriers will deliver your new SIM to this address.
        </p>
        <p>One of our agents will be in contact to confirm your details for security reasons before proceeding.</p>
    </div>
    <form [formGroup]="form" class="m-10 mt-20">
        <div class="form-group">
            <input id="address" formControlName="address" #address
                class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
                placeholder="search address" ngx-google-places-autocomplete [options]="config"
                (onAddressChange)="handleMapSearch($event)" autocomplete="off" />

            <div *ngIf="formSubmit && form.controls.address.errors">
                <div *ngIf="form.controls.address.errors.required">
                    <span class="text-danger small-font-size">address is required.</span>
                </div>
                <div *ngIf="!form.controls.address.errors.required && form.controls.address.errors.invalid">
                    <span class="text-danger small-font-size">Please enter a valid street address.</span>
                </div>
            </div>

            <input id="complex-name" formControlName="complex" #address
                class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
                placeholder="complex name or business name" />

            <input id="number" formControlName="number" #address
                class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
                placeholder="apt, flat or floor number" />

            <input id="deliver-notes" formControlName="deliveryNote" #address
                class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
                placeholder="special delivery notes" />

            <input id="alternative-number" maxlength="15" formControlName="alternativeNumber" #address
                class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
                placeholder="alternative contact number" />
                <div *ngIf="formSubmit && form.controls.alternativeNumber.errors">
                    <div *ngIf="form.controls.alternativeNumber.errors.required">
                        <span class="text-danger small-font-size">an alternative number is required.</span>
                    </div>
                </div>

        </div>

    </form>
</div>
<div class="modal-footer text-center d-flex justify-content-end border-0">
    <button class="btn btn-sm btn-primary" (click)="submit()" [disabled]="loading">
        <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="!loading">Submit</span>
    </button>
</div>