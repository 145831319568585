import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RAIN_ONE_LEVELS } from '@models/constants';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store, createSelector } from '@ngxs/store';
import { MigrateToRainOneService } from '@services/migrate-to-rain-one.service';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RainOneLevel } from 'src/app/core/interfaces/rain-one-level.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ChangeRainOneLevel } from 'src/app/store/actions/cart.action';
import { CartState } from 'src/app/store/state/cart.state';
import { ProductState } from 'src/app/store/state/product.state';
import { ServicesState } from 'src/app/store/state/services.state';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { GetAllServices, SetSelectedServiceByID } from 'src/app/store/actions/services.actions';
import { RainOneLevelsConfigService } from '@services/rain-one-levels-config.service';
import { Router } from '@angular/router';
import { FestiveBillingModalComponent } from '@pages/service-page/festive-billing-modal/festive-billing-modal.component';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { BillingState } from 'src/app/core/store/state/billing.state';
import { BillCycleOption } from 'src/app/core/interfaces/bill-cycle-option.interface';
import { IServiceDetail } from '@models/serviceDetail';

interface ShowFestiveBillingModalArgs {
  currentLevel: number;
  selectedLevel: number;
  inServicePage: boolean;
}

class RainOneModalSelectors {
  static shouldShowFestiveBillingModal({ currentLevel, selectedLevel, inServicePage }: ShowFestiveBillingModalArgs) {
    return createSelector(
      [
        FirebaseConfigsState.firstDowngradesDisabled,
        FirebaseConfigsState.twentyFifthDowngradesDisabled,
        BillingState.getSelectedBillCycle
      ],
      (firstDowngradesDisabled: boolean, twentyFifthDowngradesDisabled: boolean, billCycleOption: BillCycleOption): boolean => {

        const selectedLevelLowerThanCurrentLevel = selectedLevel < currentLevel;
        if (!inServicePage || !selectedLevelLowerThanCurrentLevel) {
          return false;
        }

        const { start_day } = billCycleOption ?? {};

        const matches1st = firstDowngradesDisabled && start_day === 1;
        const matches25th = twentyFifthDowngradesDisabled && start_day === 25;

        return matches1st || matches25th;
      }
    );
  }
}

@Component({
  selector: 'rain-one-levels-modal',
  templateUrl: './rain-one-levels-modal.component.html',
  styleUrls: ['./rain-one-levels-modal.component.scss']
})
export class RainOneLevelsModalComponent implements OnInit, OnDestroy {
  @Input() serviceOverride?: IServiceDetail;
  public levels;
  public selectedLevel: RainOneLevel;
  @ViewChild('ConfirmModalContent', { static: true }) ConfirmModalContent: TemplateRef<any>;
  @Select(CoreState.activeBreakpoint) public ap$: Observable<string>;
  isEnabled = this.store.selectSnapshot(FirebaseConfigsState.getFlags).au_dec_downgrade_disabled;
  baseLevelSelected = false;

  @Select(FirebaseConfigsState.twentyFifthDowngradesDisabled) twenty_fifth_downgrade_disabled$: Observable<boolean>;
  @Select(FirebaseConfigsState.twentyFifthCancellations) twenty_fifth_cancel_disabled$: Observable<boolean>;
  @Select(FirebaseConfigsState.firstDowngradesDisabled) first_downgrade_disabled$: Observable<boolean>;
  @Select(FirebaseConfigsState.firstCancellations) first_cancellation_disabled$: Observable<boolean>;

  paymentDate = this.store.selectSnapshot(BillingState.getSelectedBillCycle)?.start_day;
  currentService: IServiceDetail
  
  allServices = this.store.selectSnapshot(ServicesState.getAllServices);
  levelMap = new Map<number, string>([
    [0, 'rainOne'],
    [1, 'one'],
    [2, 'two'],
    [3, 'three'],
    [4, 'four'],
    [5, 'five'],
    [6, 'six'],
    [7, 'seven'],
    [8, 'eight'],
    [9, 'nine'],
    [10, 'ten']
  ]);

  currLevel = 0;
  public isMigrating: Boolean = false;
  public isSmeService : Boolean = false;
  private readonly destroy$ = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
    private modalService: NgbModal,
    private migrateToRainOneService: MigrateToRainOneService,
    private rainoneLevelsConfig: RainOneLevelsConfigService,
    private router: Router
  ) {
    this.checkIfMigrating();
  }

  ngOnInit(): void {
    this.currentService = this.serviceOverride ? this.serviceOverride : this.store.selectSnapshot(ServicesState.GetCurrentService);
    this.isSmeService = this.currentService?.productName?.includes("SME");
    const currentProduct = this.store.selectSnapshot(ProductState.allProductsMap)[this.currentService?.productId];
    const searchTerm = 'Level ';
    if (this.currentService && this.store.selectSnapshot(ProductState.allProductsMap)[this.currentService?.productId]?.name?.includes(searchTerm)) {
      const nameSplit = this.store.selectSnapshot(ProductState.allProductsMap)[this.currentService?.productId]?.name?.split(searchTerm);
      this.currLevel = +nameSplit[1];
    }

    this.levels = this.rainoneLevelsConfig.getRainOneLevels(currentProduct);
    
    const selector = window.location.href.includes('service') ? ServicesState.GetCurrentRainOneBundle : CartState.GetSelectedRainOneBundle;

    this.store
      .select(selector)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => {
          const name = res?.name;
          this.levels?.forEach(l => {
            l.selected = l.id === this.currLevel?.toLocaleString();
          });

          this.selectedLevel = this.levels?.find(l => l.id === this.currLevel.toLocaleString());
        }
      });

    this.captureLevel(this.levels[0]);
  }

  public captureLevel(level: RainOneLevel) {
    this.selectedLevel = level;
  }

  public onLevelSelect() {
    const inServicePage = this.router.url?.includes('service');
    let modalRef: NgbModalRef;
    const showFestiveModal = this.store.selectSnapshot(
      RainOneModalSelectors.shouldShowFestiveBillingModal({
        selectedLevel: this.selectedLevel?.level,
        currentLevel: this.currLevel,
        inServicePage
      })
    );
    if (showFestiveModal) {
      this.activeModal.close('close');
      modalRef = this.modalService.open(FestiveBillingModalComponent, {
        centered: true,
        size: 'sm',
        windowClass: 'slideInUp d-flex'
      });
      modalRef.componentInstance.isDowngrade = true;
      return;
    }
    if (!this.isMigrating) {
      return this.confirm();
    }
    this.baseLevelSelected = false;
    this.activeModal.close('close');
    this.modalService.open(this.ConfirmModalContent, {
      size: 'sm',
      windowClass: 'slideInUp',
      centered: true
    });
  }

  removeLevels() {
    this.baseLevelSelected = true;

    this.activeModal.close('close');
    this.modalService.open(this.ConfirmModalContent, {
      size: 'sm',
      windowClass: 'slideInUp',
      centered: true
    });
  }

  nameMatches(name: string, category: '5G' | '4G', level: number) {
    if (category === '4G') {
      return level > 0 ? name?.includes(`rainOne 4G unlimited any device Level ${level}`) : name === 'rainOne 4G unlimited any device';
    }

    return level > 0 ? name?.includes(`rainOne Level ${level}`) : name === 'rainOne';
  }

  private migrationOptions(
    level: number
  ): {
    serviceId: string;
    product_id: string;
    msisdn: string;
  }[] {
    const allServices = this.store.selectSnapshot(ServicesState.getAllServices);
    const parentService: IServiceDetail = allServices?.find((svc: IServiceDetail) => svc?.id === this.currentService?.parent_service_id);
    
    const childServices = allServices?.filter(service => service?.parent_service_id === parentService?.id);

    const allProducts = this.store.selectSnapshot(ProductState?.allProducts) ?? [];

    const parentProduct = allProducts?.find(p => p?.id === parentService?.productId);

    const migrationOptions = parentProduct?.config?.migrations ? parentProduct?.config?.migrations : parentProduct?.config?.migration;
    
    const newParentProduct = migrationOptions?.find(option => option?.name?.toLocaleLowerCase().includes(`level ${level}`)) || migrationOptions[level];
    
    const parentOption = {
      serviceId: parentService?.id,
      product_id: newParentProduct?.id,
      msisdn: parentService?.msisdn
    };

    const childOptions = childServices?.map(service => {
      const product = allProducts?.find(product => product?.id === service?.productId);
      const migrationOption = product?.config?.migration?.find(item => item?.name?.includes(`Level ${level}`));

      return {
        serviceId: service?.id,
        product_id: migrationOption?.id,
        msisdn: parentService?.msisdn
      };
    });

    return [parentOption];
  }

  public confirm() {
    const level = this.selectedLevel?.level;

    if (this.isMigrating) {
      
      const migrationLevelUpAPICall = this.migrationOptions(level).map(payload => this.migrateToRainOneService.levelMigrateRainOne(payload));

      const isUpgrading = level > this.currLevel;
      this.performMigration(migrationLevelUpAPICall, isUpgrading);
    }

    const hasPostPaid: boolean = this.store.selectSnapshot(ServicesState.hasPostPaid);
    this.store.dispatch(new ChangeRainOneLevel({ name: this.selectedLevel?.name, type: hasPostPaid ? 'postpaid' : 'upfront' }));
    this.modalService.dismissAll();
  }

  private performMigration(migrationLevelUpAPICall: Observable<any>[], isUpgrading: boolean) {
    forkJoin(migrationLevelUpAPICall)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.store.dispatch([new GetAllServices()]);
          setTimeout(() => this.store.dispatch(new SetSelectedServiceByID()), 1500);
          const modalRef = this.modalService.open(SuccessModalComponent, {
            size: <any>'confirm',
            windowClass: 'slideInUp',
            centered: true
          });

          modalRef.componentInstance.title = `level change successful`;
          modalRef.componentInstance.copy = isUpgrading
            ? `Your level up subscription will be added to your next bill with your full allocation`
            : `Your new level subscription will take effect from your next bill.`;
        },
        (err: any) => {
          const modalRef = this.modalService.open(SuccessModalComponent, {
            size: <any>'confirm',
            windowClass: 'slideInUp',
            centered: true
          });

          modalRef.componentInstance.title = `level change unsuccessful`;
          modalRef.componentInstance.copy = `Unable to change level. A customer service agent will contact you.`;
        }
      );
  }

  public dismiss() {
    this.modalService.dismissAll();
  }

  getServiceMappedWithCategoryList() {
    let foundServices = this.allServices?.filter(
      service => service?.parent_service_id === this.currentService?.parent_service_id || service?.id === this.currentService?.parent_service_id
    );
    return foundServices.map(fs => {
      return { ...fs, category: this.store.selectSnapshot(ProductState.allProductsMap)[fs?.productId]?.category };
    });
  }

  getDestinationProducts(paymentType, techType: '4G' | '5G') {
    const destinationLevel = this.baseLevelSelected ? '0' : this.selectedLevel.level.toString();
    if (destinationLevel === '0') {
      return [
        ...Object.values(this.store.selectSnapshot(ProductState.allProductsMap)).filter(
          val => (val as any).type === 'sim' && (val?.config?.paymentType === paymentType || !val?.config?.paymentType) && val?.name?.endsWith('rainOne')
        ),
        ...Object.values(this.store.selectSnapshot(ProductState.allProductsMap)).filter(
          val => (val as any).type === 'sim' && val?.name?.endsWith('4G mobile phone line')
        )
      ];
    }
    const allProducts = Object.values(this.store.selectSnapshot(ProductState.allProducts));

    const destBundle = allProducts?.filter(
      product =>
        product?.name?.includes(destinationLevel) &&
        product?.type === ('bundle' as any) &&
        product.config.paymentType === paymentType &&
        product?.name?.includes(techType)
    );

    const destItems = destBundle?.[0]?.items
      ?.map(item => allProducts?.find(product => product?.id === item?.id && (product?.type as any) === 'sim'))
      ?.filter(product => product !== undefined);

    return destItems;
  }

  private checkIfMigrating() {
    this.isMigrating = window.location.href.includes('/service');
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
