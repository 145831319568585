import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-reverse-cancellation-modal',
  templateUrl: './reverse-cancellation-modal.component.html',
  styleUrls: ['./reverse-cancellation-modal.component.scss']
})
export class ReverseCancellationModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
  }

}
