<div class="festive-billing-modal-container">
    <div class="close-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <div class="modal-title">
        <h2> heads ups!</h2>
    </div>
    <div class="festive-modal-content">
        <p>Due to changes in some of our business processes over the festive season, you unfortunately won’t be able to
            schedule a service <span *ngIf="isCancellation ">cancellation</span> <span
                *ngIf="isDowngrade">downgrade</span> from our website.
            Please contact support on 081 610 1000 for any further assistance.</p>
    </div>
    <div class="modal-button-container">
        <button (click)="confirm()">confirm</button>
    </div>
</div>