import { Component, OnDestroy, OnInit } from '@angular/core';
import { CONFIG_COMMENTS, CONFIG_FRIENDLY_NAME } from '@models/constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CartService } from '@services/cart.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddExtraPhoneLine } from 'src/app/store/actions/cart.action';

@Component({
  selector: 'rain-one-add-phone-line-modal',
  templateUrl: './rain-one-add-phone-line-modal.component.html',
  styleUrls: ['./rain-one-add-phone-line-modal.component.scss']
})
export class RainOneAddPhoneLineModalComponent implements OnInit, OnDestroy {
  private ngDestroy$: Subject<any> = new Subject();
  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
    private cartService: CartService
    ) { }

  ngOnInit(): void {
  }

  public addLine() {
    this.activeModal.close();
    this.store.dispatch(new AddExtraPhoneLine());
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}
