<div class="d-flex align-items-top">
  <div class="col-sm-12 buttons d-flex pl-0 mb-20 mt-10">
    <!-- <button #btn4G (click)="updateSelectionBtn($event, 'showFourG')" class="btn rounded-1 custom-sm-button no-radius-left py-5 btn-light" style="text-transform: none;">4G Mobile</button> -->
    <!-- <button #btn5G (click)="updateSelectionBtn($event, 'showFourG')" class="btn rounded-0 btn-light no-radius-left py-5">5G home</button> -->
    <!-- <button #btnBoth (click)="updateSelectionBtn($event, 'both')" class="btn custom-sm-button rounded-1 btn-light active no-radius-right py-5" style="text-transform: none;">4G & 5G Home</button> -->
  </div>
  <!-- search -->
  <!-- <div class="col-sm-6">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <i class="fa-solid fa-magnifying-glass"></i>
      </div>
      <input type="text" placeholder="search address" class="form-control" aria-label="Amount (to the nearest dollar)">
      <div class="btn btn-primary">search</div>
    </div>
  </div> -->
 
</div>
<!-- <div class="pl-20 py-4">
  
  <div class="form-check form-check-inline ">
    <input class="px-4 cust-check-box" type="checkbox" id="fourg " [value]="showFourG" [(ngModel)]="showFourG"
      (change)="updateSelection()">
    <label class="px-4 form-check-label cust-check-label text-primary" for="fourg">4G</label>
  </div>
  <div class="form-check form-check-inline ">
    <input class="px-4 cust-check-box" type="checkbox" id="fiveg" [value]="showFiveG" [(ngModel)]="showFiveG"
      (change)="updateSelection()">
    <label class="px-4 form-check-label cust-check-label text-primary" for="fiveg">5G</label>
  </div>
</div> -->

<div id="fiveg-map-legends" class="z-index-2 position-absolute ">
  <div *ngIf="showLegend" class="pl-10 pt-10">
    <!-- <img src="../../../assets/images/map/path830.png" class="map-text img-fluid"/> -->
    <img src="assets/images/rain-one/map-legend.png" class="map-text img-fluid"/>
  </div>
</div>



<div class="coverage-check position-relative top-0 z-index-1">
  <agm-map (mapReady)="mapReady($event)" [zoom]="zoom" [latitude]="_latitude" [longitude]="_longitude"
    [scrollwheel]="null" style="height: 600px;" [streetViewControl]="false" [zoomControl]="true" [styles]="styles">
    <agm-marker [latitude]="_latitude" [longitude]="_longitude"></agm-marker>
  </agm-map>
</div>