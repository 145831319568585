<div class="text-center mt-25 mb-25" *ngIf="loading">
  <div class="spinner-grow text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class=" " id="fiveg-not-in-coverage" *ngIf="!loading">
  <form [formGroup]="registerForm" (ngSubmit)="handleSubmit()">
    <!-- name -->
    <div class="form-group mt-5 mb-10">
      <sf-form-input label="First name" maxlength="24" [control]="registerForm.get('firstName')" [type]="'text'"
        [messages]="nameValidationMessages">
      </sf-form-input>
    </div>

    <!-- lastName -->
    <div class="form-group mt-13 mb-10">
      <sf-form-input maxlength="24" label="Last name" [control]="registerForm.get('lastName')" [type]="'text'"
        [messages]="lastNameValidationMessages">
      </sf-form-input>
    </div>

    <!-- email -->
    <div class="form-group mt-13 mb-10">
      <sf-form-input label="Email address" [control]="registerForm.get('email')" [type]="'email'"
        [messages]="emailValidationMessages">
      </sf-form-input>
    </div>

    <!-- cellNumber -->
    <div class="form-group mt-13 mb-10">
      <sf-form-input label="Cellphone number" [control]="registerForm.get('tel')" [type]="'tel'"
        [messages]="cellNumberValidationMessages">
      </sf-form-input>
    </div>

    <div class="form-group mb-10" *ngIf="showAddress">
      <input class="form-control p-0  bg-transparent  border-0 rounded-0 border-btm" formControlName="address"
        [options]="config" ngx-google-places-autocomplete (onAddressChange)="handleMapSearch($event)"
        [placeholder]="'home address: (where you’d like to use 5G)'" />
      <div *ngIf="submitted && lastAddress !== registerForm.value.address" class="text-danger small-font-size">
        <div *ngIf="submitted && registerForm.get('address').invalid">Valid address is required</div>
      </div>
    </div>

    <div id="submitButton" class="d-flex justify-content-end" *ngIf="notify4G === undefined && notify5G === undefined">
      <button [class.btn-primary]="lastAddress === registerForm.value.address && !registerForm.invalid"
        [class.btn-tertiary]="lastAddress !== registerForm.value.address || registerForm.invalid"
        class="btn btn-sm btn-primary" id="button-check-coverage">notify me</button>
    </div>

    <div class="d-flex justify-content-center" *ngIf="notify4G !== undefined && notify5G !== undefined">
      <div class="row">
        <button class="btn btn-sm btn-primary m-2 px-10" *ngIf="notify4G"
          [class.btn-primary]="lastAddress === registerForm.value.address && !registerForm.invalid"
          [class.btn-tertiary]="lastAddress !== registerForm.value.address || registerForm.invalid"
          (click)="handleSubmit('4G')" data-id="btn-click" data-text="notify me about 4G">
          notify me
        </button>

        <button class="btn btn-sm btn-primary m-2 px-10" *ngIf="notify5G"
          [class.btn-primary]="lastAddress === registerForm.value.address && !registerForm.invalid"
          [class.btn-tertiary]="lastAddress !== registerForm.value.address || registerForm.invalid"
          (click)="handleSubmit('5G')" data-id="btn-click" data-text="notify me about 5G">
          notify me
        </button>
      </div>

    </div>

  </form>
</div>