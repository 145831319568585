import { ModelHelper } from '@models/modelHelper';
import { get, isNil } from 'lodash';
import moment from 'moment';

import { IRemoteSubscriberDetail, IRemoteTowerStatus } from "@models/remote/remoteSimTowerDetail";
import { ITowerDetail, TowerDetail } from "./towerDetail";

export interface ISubscriberDetail {
    rat: string;
    offline: boolean;
    cached: boolean;
    lastMeasurement: any;
    capturedAt: any;
    rsrp: any;
    current4g: ITowerDetail;
    current5g: ITowerDetail;
    surrounding: ITowerDetail[];
    fourGConnected: Date;
    fiveGConnected: Date;
    tethered: boolean;
    policy: string;
    quota: string
    serviceName: string;
    imsi: string;
    imei: string;
    apn: string;
    active4gSiteStatus: SiteStatuses,
    active5gSiteStatus: SiteStatuses,
    msisdn?: string
}

export enum SiteStatuses {
    None = 0,
    Minor,
    Major,
    Critical
}

export interface ITowerStatus {
    isLoadshedding: boolean,
    alarmInfomation: {
        wbs_site_name?: string;
        alarm_run_time?: string
    },
    msisdn: string
}

export class SubscriberDetail {

    static adapt(remote: IRemoteSubscriberDetail): ISubscriberDetail {
        const surrounding = [];

        for (const key in remote.activeanchors) {

            const element = remote.activeanchors[key];

            surrounding.push(TowerDetail.adapt(element));
        }
        const simTowerDetail: ISubscriberDetail = {
            msisdn: remote?.msisdn,
            rat: remote.rat,
            offline: !remote.additionalfields?.contextinfo ? true : false,
            capturedAt: remote.captured_at,
            cached: remote.additionalfields?.cached,
            current4g: isNil(remote.active4g) ? null : TowerDetail.adapt(remote.active4g),
            current5g: isNil(remote.active5g) ? null : TowerDetail.adapt(remote.active5g),
            active4gSiteStatus: this.mapSiteStatus(remote.active4gSiteStatus),
            active5gSiteStatus: this.mapSiteStatus(remote.active5gSiteStatus),
            tethered: this.mapTethered(remote.tetherstatus),
            policy: remote.policy_counter_status,
            quota: remote.quota_status,
            serviceName: isNil(remote.service_name) ? "rain" : remote.service_name,
            imsi: isNil(remote.imsi) ? null : remote.imsi,
            imei: isNil(remote.imei) ? null : remote.imei,
            surrounding,
            fourGConnected: moment(remote.time4g, "YYYYMMDDhhms").toDate(),
            fiveGConnected: moment(remote.time5g, "YYYYMMDDhhms").toDate(),
            apn: ModelHelper.clean(remote.apn),

        } as ISubscriberDetail;

        const lastMeasurement = get(remote, 'additionalfields.last_measurements[0].measurements[0]', null);

        if (isNil(lastMeasurement) == false) {

            simTowerDetail.rsrp = get(lastMeasurement, "ltescrsrp", null);

            const lastTimeString = get(lastMeasurement, "lasttime", null);

            if (isNil(lastTimeString) == false) {
                simTowerDetail.lastMeasurement = ModelHelper.toDate(lastTimeString);
            }
        }

        return simTowerDetail;
    }

    static mapSiteStatus(active4gSiteStatus: string): SiteStatuses {

        switch (active4gSiteStatus) {
            case "CRITICAL": return SiteStatuses.Critical;
            case "MAJOR": return SiteStatuses.Major;
            case "MINOR": return SiteStatuses.Minor;
            default: return SiteStatuses.None
        }
    }

    static mapTethered(tetherstatus: number): boolean {
        switch (tetherstatus) {
            case 0:
                return true;
            case 1:
                return false;
            case 255:
                return null;
            default:
                return null;
        }
    }

    static mapTowerStatus(remoteStatus: IRemoteTowerStatus, msisdn: string): ITowerStatus {
      
        return {
            isLoadshedding: remoteStatus?.is_loadShedding,
            alarmInfomation: remoteStatus?.alarm_information,
            msisdn
        }
    }
}
