<div class="mb-10 modal-header border-0" *ngIf="data">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('cancel')" data-dismiss="modal"
      data-target="#thankYouModal" src="../../assets/svg/close.svg" />
  </div>
</div>
<div class="p-5 modal-body border-0">
  <div class="row m-0 mb-10">
    <div class="col-12">
      <p class="text-primary text-center h2 mb-0 pb-5 font-weight-bold modal-heading">
        {{data?.title}}</p>

      <div class="pl-0 pl-sm-10 pr-0 pr-sm-10">
        <p class="text-center">
          {{data?.description}}
        </p>
      </div>

      <button class="btn btn-md btn-primary mt-15 d-block mx-auto" data-dismiss="modal" aria-label="Close"
        data-target="#thankYouModal" (click)="activeModal.dismiss('cancel')">
        {{data?.buttonName}}
      </button>
    </div>
  </div>
</div>