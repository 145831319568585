import { MapSearchModule } from '../map-search/map-search-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapComponent } from './map.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';


@NgModule({
    declarations: [
        MapComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AgmCoreModule,
        MapSearchModule

    ],
    exports: [
        MapComponent,
        MapSearchModule,
    ],
    providers: [],
})
export class MapModule { }
