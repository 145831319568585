import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { VerifySimComponent } from "./verify-sim.component";

const routes: Routes = [
    {
        path: '',
        component: VerifySimComponent,
    },]

    @NgModule({
        imports: [RouterModule.forChild(routes)],
        exports: [RouterModule]
    })
    export class VerifySimRoutingModule { }