<div class="reverse-cancel-success p-15">
	<div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
          data-dismiss="modal" data-target="#surveyModal" src="../../assets/svg/close.svg" />
	</div>
	<div class="heading">
		<h1 class="text-primary text-center h1-text-transform">hey it's good to have you back</h1>
	</div>

	<div class="text-content text-center mt-10">
		<p>Your request cancellation will be reversed right now.
			Just so you know, cancellation reversals can only happen while your SIM is still scheduled for cancellation, not once it has been cancelled.</p>
	</div>

	<div class="actions d-flex justify-content-center mt-10">
		<button-v2 class="mx-2" [buttonClass]="'cancel-modal-btn'" [text]="'got it'" (click)="close()"></button-v2>
	</div>

</div>