import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { Result } from '@models/result';
import { AuthenticationService } from './auth.service';
import { CacheService } from './cache.service';
import { ServiceHelper } from './serviceHelper';
import { TokenService } from './token.service';
import { ConfigService } from './config.service';
import { IdleService } from './idle.service';
import { CACHE_PROFILE, CACHE_AUTH_TOKEN } from '@models/constants';
import { IUserDetail, UserDetail } from '@models/userDetail';
import { Store } from '@ngxs/store';
import { CoreState } from '../core/store/state/core.state';
import { GetUserProfileSuccess } from '../core/store/actions/auth.actions';

/**
 * PROFILE_WAIT @param
 */
const PROFILE_WAIT = 1000;
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private tokenService: TokenService,
    private cacheService: CacheService,
    private authService: AuthenticationService,
    private idleService: IdleService,
    private store: Store
  ) { }

  public get(ignoreCache: boolean = true, token?: string): Observable<Result<IUserDetail>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }
    if (ignoreCache === false && this.isCached()) {
      const userDetail = this.getCache();

      this.idleService.resetTimer();

      const result = Result.success(userDetail);

      return of(result);
    }

    /**
     * requestPath @param
     */

    const apiURl = this.configService.API_URL_V5;
    const requestPath = `${apiURl}/profile_summary`;

    /**
     * httpOptions @param
     */
    this.tokenService.get(CACHE_AUTH_TOKEN)

    let userToken = token === undefined ? this.tokenService.get(CACHE_AUTH_TOKEN) : token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: userToken
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((response: any) => {
        const userDetail = UserDetail.adapt(response);

        this.setCache(userDetail);

        return Result.success(userDetail);
      }),
      catchError(result => {
        return ServiceHelper.handleError<IUserDetail>(result);
      })
    );
  }
  

  public clearCache(): void {
    this.cacheService.remove(CACHE_PROFILE);
  }

  setCache(user: IUserDetail) {
    this.cacheService.setObject(CACHE_PROFILE, user);
  }

  isCached(): boolean {
    return this.cacheService.exists(CACHE_PROFILE);
  }

  getCache(): IUserDetail {
    const profile: IUserDetail = this.cacheService.getObject(CACHE_PROFILE);
    this.store.dispatch(new GetUserProfileSuccess(profile));
    return profile;
  }
}
