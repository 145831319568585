import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_AUTH_TOKEN } from '@models/constants';
import { ConfigService } from '@services/config.service';
import { TokenService } from '@services/token.service';
import { Observable } from 'rxjs';
import { VoucherRedeemPayload } from '../../interfaces/voucher-redeem-payload.interface';

import { VoucherStatusPayload } from '../../interfaces/voucher-status.payload.interface';
import { VoucherStatusResult } from '../../interfaces/voucher.interface';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  private _domain: string;
  constructor(private _http: HttpClient, private configService: ConfigService, private tokenService: TokenService) {
    this._domain = this.configService.VOUCHER;
  }

  public checkStatus(details: VoucherStatusPayload): Observable<any> {
    const url: string = `${this.configService.VOUCHER}/check-voucher?voucherNumber=${details.voucherNumber}&emailAddress=${details.emailAddress}`;

    return this._http.get<any>(url);
  }

  public redeem(details: VoucherRedeemPayload): Observable<any> {
    const url: string = `${this.configService.VOUCHER}/redeem-voucher`;

    return this._http.post<any>(url, details);
  }

  public validateVoucherStatus(voucherNum: string): Observable<VoucherStatusResult> {
    const url: string = `${this.configService.BASE_API_URL}/v1/voucher/${voucherNum}/validate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };
    const body = {customer_reference: null}
    return this._http.post<VoucherStatusResult>(url, body, httpOptions);
  }

  getAllBlackFridayPools() {
    const url: string = `${this.configService.BASE_API_URL}/v1/campaign/BF`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };
    return this._http.get<any>(url, httpOptions)
  }

  getSelectedVoucherPool(voucherPool) {
    const url: string = `${this.configService.BASE_API_URL}/v1/pool/${voucherPool}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };
    return this._http.post<any>(url, voucherPool,httpOptions)
  }
}
