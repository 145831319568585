import * as fromHelpers from './helpers';
import * as fromMappers from './custom-data-structures';
import * as fromFormatters from './formatters';


export const Utils = {
    Formatters: {...fromFormatters},
    Helpers: { ...fromHelpers },
    Mappers: {...fromMappers},

};
