<div class="modal-content border-0  px-10">
    <div class="modal-img-close-btn z-index-5">
        <img src="../../assets/svg/close.svg" class="pointer" alt="Close" (click)="activeModal.dismiss('Cross click')"
            data-dismiss="modal" data-target="#FiveGCoverageModal" aria-label="Close" />
    </div>
    <div ngbAutoFocus class="modal-body pb-20 pt-0 pl-0 pr-0 mt-20" *ngIf="product">
        <h2 class="font-weight-bold text-primary text-center">
            call back request received
        </h2>
        <div class="text-primary text-center">
            One of our agents will be in touch soon
        </div>
        <div class="d-flex justify-content-center mt-5 flex-sm-row flex-column">

            <button class="mx-2 btn btn-sm btn-primary mt-10" (click)="ok()">
                got it
            </button>


        </div>
    </div>
</div>