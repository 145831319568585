import { Utils } from "src/app/utils";


export class TakeMyMoney {
    static readonly type = Utils.Helpers.Type("[TMMN] Take money from customers account");
    constructor(public payload: number) { }
}

export class TakeMyMoneySuccess {
    static readonly type = Utils.Helpers.Type("[TMMN] Take money from customers account Success");
    constructor(public invoiceId: string, public initiatedTime: number) { }
}

export class TakeMyMoneyFail {
    static readonly type = Utils.Helpers.Type("[TMMN] Take money from customers account Fail");
    constructor(public payload: unknown) { }
}


export class CheckPaymentStatus {
    static readonly type = Utils.Helpers.Type('[TMMN] Check payment status');
    constructor(public invoiceId: string, public initiatedTime: number) { }
}

export class PaymentSuccessful {
    static readonly type = Utils.Helpers.Type('[TMMN] Check payment status Success');
    constructor(public status: string, public invoiceId: string) { }
}

export class PaymentFailed {
    static readonly type = Utils.Helpers.Type('[TMMN] Check payment status fail');
    constructor( public status: string) { }
}
