<div class="modal-container">
    <div class="modal-content-wrapper">
        <!-- <div class="close-modal">
            <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')" />
        </div> -->
        <br>
        <div class="modal-title">
            <span class="bold-text">looks like you're not in 5G coverage yet...</span>
            <div *ngIf="IsNew5gCustomer">
                <p>Oh no, you’ve ventured beyond the realm of 5G coverage. You can still get GeForce NOW,
                    but as a stand alone product without rainOne. This means that you won’t get access to
                    the 20% discount.
                </p>
                <p> Would you still like to go ahead? </p>
            </div>

                <p *ngIf="!IsNew5gCustomer">
                    Oh no, you’ve ventured beyond the realm of 5G coverage. 
                    GeForce NOW is only available to rain customers who are in 5G coverage. 
                </p> 
          
        </div>
        <div class="modal-image-wrapper">
            <div class="modal-image">
                <img src="/assets/images/nvidia/out-coverage-modal-asset.png" alt="not in coverage ">
                </div>

            <div class="bottom-sub-content">
             
            </div>
        </div>
        <div class="button-container">
            <div class="right-buttons" *ngIf="IsNew5gCustomer">
                <button class="btn btn-sm btn-transparent border-primary text-primary mr-4 notify-btn"
                (click)="removeNvidiaGoHome()">no, thanks</button>
                <button class="btn btn-sm btn-primary text-white buy-btn" (click)="removeRainOne()">yes</button>
            </div>

            <div class="right-buttons" *ngIf="!IsNew5gCustomer">
                <button class="btn btn-sm btn-primary text-white buy-btn"
                 (click)="removeNvidiaGoHome()">got it</button>
            </div>

        </div>
    </div>
</div>