export interface InboxMessage {
  categoryId: number;
  customerId: string;
  id: string;
  insertedAt: string;
  notificationId: string;
  readMore: boolean;
  message: string;
  shortDescription: string;
  status: MessageStatus;
  updatedAt: number;
}

export interface MessageCategory {
  description: string;
  enabled: boolean;
  id: number;
  insertedAt: number;
  meta?: any;
  updatedAt: number;
  unreadCount: number;
}
export interface NotificationChannel {
  accepted?: boolean;
  id: number;
  description: string;
  order?: number;
  customerPreference?: boolean;
}
export interface UserSettings {
  optinWhatsApp?: boolean;
  optWhatsAppDetails?: any;
  optinPromo: boolean;
  verifiedOptInPromo: boolean;
  availableChannels?: NotificationChannel[];
  showOptinInfo: boolean;
}
export enum MessageStatus { //TODO The API needs not depend on these seemingly autogenerated primary Keys
  READ = 2,
  UNREAD = 1
}

export enum NotificationChannels { //TODO The API needs not depend on these seemingly autogenerated primary Keys
  EMAIL = 3,
  WHATSAPP = 5,
  SMS = 4,
  PUSH_NOTIFICATION = 2
}

export enum NotificationCategories { //TODO The API needs not depend on these seemingly autogenerated primary Keys
  PROMO = 3,
  BILLING = 1,
  NETWORK = 2,
  ALL = -1,
  SETTINGS = -2
}

export const StaticCategories = {
  allCategories: {
    id: -1,
    description: 'all Messages',
    enabled: true,
    insertedAt: 0,
    updatedAt: 0,
    meta: { webImage: 'all_messages.png' },
    unreadCount: 0
  },
  settings: {
    id: -2,
    description: 'notification settings',
    meta: { webImage: 'notification_settings.png' },
    enabled: true,
    insertedAt: 0,
    updatedAt: 0,
    unreadCount: 0
  }
};
