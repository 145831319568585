import { BillCycleOption } from "src/app/core/interfaces/bill-cycle-option.interface";

export const parseBillingCycleDates = (dates: Array<BillCycleOption>): any[] => {
    return dates.map((d: BillCycleOption) => {
      const label = d.start_day === 1 ? `${d.start_day}st` : `${d.start_day}th`;
      return {
        value: String(d.start_day),
        id: d.id,
        label
      };
    });
  }
