import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockCopyPasteDirective } from '@directives/block-copy-directive.directive';
import { LazyImgDirective } from '@directives/lazy-img.directive';
import { StringNumberDirective } from '@directives/string-number-directive.directive';

@NgModule({
    declarations: [
        BlockCopyPasteDirective,
        LazyImgDirective,
        StringNumberDirective
    ],
    imports: [CommonModule],
    exports: [],
    providers: [],
})
export class DirectivesModule { }