<!-- <div class="container-fluid">
  <div class="bg-our-network">
    <div class="container">
      <h1 class="text-white">our network</h1>
    </div>
  </div>
</div> -->
<!-- <div class="header-container">
  <img class="img-item" src="./assets/bg/people-nerwork-rain.png" alt="" />
</div> -->
<div class="container spacer2">
  <div class="row">
    <div class="col p-10">
      <div class="copy">
        <div class="our-network"><b>our network</b></div>
        <!-- <p class="text-dark">
          We're proud to announce the launch of our new 4G only mobile network, now offering national coverage.
        </p> -->
        <div class="title">our 4G & 5G home network</div>
        <p class="text-dark">
          Our 4G/5G home network is also growing rapidly, reaching new locations every day. If you're not yet covered by our home network, please
          check back soon, as we're actively working to expand our coverage area. Check your home coverage below.
        </p>
        <p class="text-dark">
          Check your home coverage below.
        </p>
      </div>
      <div class="input-wrapper">
        <div >
          <div class="coverage-buttons-container" hidden>
            <button
              (click)="updateSelectionBtn($event, coverageMapModes.FourGMobile)"
              class="btn rounded-1 no-radius-left py-5 btn-light"
              [class.active]="(selectedMode$ | async) === coverageMapModes.FourGMobile"
              style="text-transform: none;"
            >
              4G mobile
            </button>
            <button
              (click)="updateSelectionBtn($event, coverageMapModes.BothFourAndFiveG)"
              [class.active]="(selectedMode$ | async) === coverageMapModes.BothFourAndFiveG"
              class="btn rounded-1 btn-light active no-radius-right py-5"
              style="text-transform: none;"
            >
              4G & 5G home
            </button>
          </div>
        </div>
        <div class="col col-6 col-md-8 network-search-input-container col-sm-12" style="z-index: 2;">
          <app-map-search
            (inputFocused)="inputFocused()"
            (onAddressChange)="handleMapSearch($event)"
            id="map-component"
            class="other full-width"

          >
          </app-map-search>
        </div>

      </div>


      <app-map  [latitude]="latitude" [longitude]="longitude" [mode]="selectedMode$ | async"></app-map>

      <div class="row">
        <div class="col">
          <p class="text-primary py-5 text-left">disclaimer</p>
          <div class="copy-disclaimer text-justify" #disclaimerCopy>
            <p>
              The information contained in this coverage map is for general information purposes only and does not guarantee actual coverage. While we
              endeavour to keep the information accurate and up to date, some factors influencing coverage varies over time such as terrain. Other
              factors include, but are not limited to a person’s residence in relation to the cellular enabled mobile device site and poor radio
              conditions within a person’s residential area as a result of environmental factors beyond rain’s control. rain makes no representations
              or warranties of any kind, whether expressed or implied, relating to the completeness, accuracy, reliability, suitability or
              availability with respect to the coverage map. Your indoor coverage may be affected by factors that may not be in our control and impact
              accuracy of coverage approximation.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
