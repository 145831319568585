import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { State, Selector, Store, Action, StateContext } from '@ngxs/store';
import { EricaDetail, EricaService, IEricaDetail } from '@services/erica.service';
import { tap } from 'rxjs/operators';
import { SuccessModalComponent } from 'src/app/shared/components/success-modal/success-modal.component';
import { AllowByIdPayload, KYCResult, RicaStatusCopy } from '../../interfaces/rica.interface';
import { WebsiteconfigService } from '../../services/websiteconfig.service';
import { FetchRicaStatusCopy, FetchRicaStatusCopySuccess } from '../actions/firebase.actions';
import * as fromRicaActions from '../actions/rica.actions';

export interface RicaStateModel {
    tracking: any;
    status: AllowByIdPayload;
    loading: boolean;
    loaded: boolean;
    submiited: boolean;
    statusCopy: Array<RicaStatusCopy>;
    error: boolean;
}

@State<RicaStateModel>({
    name: 'rica',
    defaults: {
        tracking: null,
        status: null,
        loading: false,
        loaded: false,
        submiited: false,
        statusCopy: null,
        error: false
    }
})
@Injectable()
export class RicaState {

    constructor(private store: Store, private _svc: EricaService, private modalSvc: NgbModal, private _webConfigSVC: WebsiteconfigService) { }

    @Selector()
    static status(state: RicaStateModel) { return state.status.data.status; }

    @Selector()
    static isLoaded(state: RicaStateModel) { return state.loaded; }

    @Selector()
    static getRicaStatusCopy(state: RicaStateModel) { 
        if(state.error) {
            return {
                copy: 'Unable to RICA entry',
                status: 7
            }
        }
        const statusId = state.status.data.status;
        const msg = state.statusCopy.find((cp) => cp.status === statusId);
        
        return msg;
     }

    @Selector()
    static getKYCResult(state: RicaStateModel):KYCResult { return state.status.data.kycResult.kyc_result }

    @Selector()
    static tracking(state: RicaStateModel) { return state.tracking; }

    @Selector()
    static isLoading(state: RicaStateModel) { return state.loading; }

    @Selector()
    static isSubmiited(state: RicaStateModel) { return state.submiited; }

    @Action(fromRicaActions.AllowById)
    AllowById(ctx: StateContext<RicaStateModel>, action: fromRicaActions.AllowById) {
        const payload = action.payload;

        return this._svc.allowById(payload).pipe(
            tap({
                next: (res) => ctx.dispatch(new fromRicaActions.AllowByIdSuccess(res)),
                error: (err) => ctx.dispatch(new fromRicaActions.AllowByIdFail(err)),
            })
        );
    }

    @Action(fromRicaActions.AllowByPassport)
    AllowByPassport(ctx: StateContext<RicaStateModel>, action: fromRicaActions.AllowByPassport) {
        const payload = action.payload;

        return this._svc.allowByPassport(payload).pipe(
            tap({
                next: (res) => ctx.dispatch(new fromRicaActions.AllowByPassportSuccess(res)),
                error: (err) => ctx.dispatch(new fromRicaActions.AllowByPassportFail(err)),
            })
        );
    }

    @Action(fromRicaActions.GetStatus)
    GetStatus(ctx: StateContext<RicaStateModel>, action: fromRicaActions.GetStatus) {
        const payload = action.payload;
        ctx.patchState({
            loading: true
        })
        return this._svc.getStatus(payload).pipe(
            tap({
                next: (res) => ctx.dispatch(new fromRicaActions.GetStatusSuccess(res)),
                error: (err) => ctx.dispatch(new fromRicaActions.GetStatusFail(err)),
            })
        );
    }

    @Action(fromRicaActions.GetStatusSuccess)
    GetStatusSuccess(ctx: StateContext<RicaStateModel>, action: fromRicaActions.GetStatusSuccess) {
        const payload = action.payload;;
        ctx.patchState({
            status: payload,
            loading: false
        });
    }

    @Action(fromRicaActions.GetAllowList)
    GetAllowList(ctx: StateContext<RicaStateModel>, action: fromRicaActions.GetAllowList) {
        const payload = action.payload;
        ctx.patchState({
            loaded: false,
            loading: true,
            error: false
        })

        return this._svc.getAllowList(payload).pipe(
            tap({
                next: (res) => ctx.dispatch(new fromRicaActions.GetAllowListSuccess(res)),
                error: (err) => ctx.dispatch(new fromRicaActions.GetAllowListFail(err)),
            })
        );
    }
    
    @Action(fromRicaActions.GetAllowListSuccess)
    GetAllowListSuccess(ctx: StateContext<RicaStateModel>, action: fromRicaActions.GetAllowListSuccess) {
        const payload = action.payload;

        ctx.patchState({
            status: payload,
            loaded: true,
            loading: false,
            error: false
        })
    }

    @Action(fromRicaActions.GetAllowListFail)
    GetAllowListFail(ctx: StateContext<RicaStateModel>, action: fromRicaActions.GetAllowListFail) {
        const payload = action.payload;

        ctx.patchState({
            loaded: false,
            loading: false,
            error: true
        })
    }

    @Action(fromRicaActions.DeleteAllowList)
    DeleteAllowList(ctx: StateContext<RicaStateModel>, action: fromRicaActions.DeleteAllowList) {
        const payload = action.payload;

        return this._svc.deleteAllowList(payload.ref, payload.body).pipe(
            tap({
                next: (res) => ctx.dispatch(new fromRicaActions.DeleteAllowListSuccess(res)),
                error: (err) => ctx.dispatch(new fromRicaActions.DeleteAllowListFail(err)),
            })
        )
    }

    @Action(fromRicaActions.ResendEricaMessage)
    ResendEricaMessage(ctx: StateContext<RicaStateModel>, action: fromRicaActions.ResendEricaMessage) {
        const payload = action.payload;
        ctx.dispatch({
            submiited: true
        });

        return this._svc.resendEricaMessage(payload).pipe(
            tap({
                next: (res) => ctx.dispatch(new fromRicaActions.ResendEricaMessagSuccess(res)),
                error: (err) => ctx.dispatch(new fromRicaActions.ResendEricaMessageFail(err)),
            })
        );
    }

    @Action(fromRicaActions.ResendEricaMessagSuccess)
    ResendEricaMessagSuccess(ctx: StateContext<RicaStateModel>, action: fromRicaActions.ResendEricaMessagSuccess) {
        const payload = action.payload;

        ctx.dispatch({
            submiited: false
        });

        const modalRef = this.modalSvc.open(SuccessModalComponent, {
            size: <any>'confirm',
            windowClass: 'slideInUp',
            centered: true
        });

        modalRef.componentInstance.copy = `We’ve sent you an SMS with a link to complete your online RICA.`
    }


    @Action(fromRicaActions.ResendEricaMessageFail)
    ResendEricaMessageFail(ctx: StateContext<RicaStateModel>, action: fromRicaActions.ResendEricaMessageFail) {

        ctx.dispatch({
            submiited: false
        });

        const modalRef = this.modalSvc.open(SuccessModalComponent, {
            size: <any>'confirm',
            windowClass: 'slideInUp',
            centered: true
        });

        modalRef.componentInstance.title = `your request has been<br>submitted`
        modalRef.componentInstance.copy = `We were unable to generate a new link`
    }

    @Action(fromRicaActions.GetTrackingInfo)
    GetTrackingInfo(ctx: StateContext<RicaStateModel>, action: fromRicaActions.GetTrackingInfo) {
        const payload = action.payload;

        return this._svc.getTrackingInfo(payload).pipe(
            tap({
                next: (res) => ctx.dispatch(new fromRicaActions.GetTrackingInfoSuccess(res)),
                error: (err) => ctx.dispatch(new fromRicaActions.GetTrackingInfoFail(err)),
            })
        );
    }

    @Action(fromRicaActions.GetTrackingInfoSuccess)
    GetTrackingInfoSuccess(ctx: StateContext<RicaStateModel>, action: fromRicaActions.GetTrackingInfo) {
        const payload = action.payload;

        ctx.patchState({
            tracking: payload
        });
    }

    @Action(FetchRicaStatusCopy)
    FetchRicaStatusCopy(ctx: StateContext<RicaStateModel>, action: FetchRicaStatusCopy) {

        return this._webConfigSVC.fecthRICAStatusCopy().pipe(
            tap({
                next: (res) => ctx.dispatch(new FetchRicaStatusCopySuccess(res)),
            })
        );
    }

    @Action(FetchRicaStatusCopySuccess)
    FetchRicaStatusCopySuccess(ctx: StateContext<RicaStateModel>, action: FetchRicaStatusCopySuccess) {
        const payload = action.payload;

        ctx.patchState({
            statusCopy: payload
        });
    }
}