import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CartService } from '@services/cart.service';
import { ClearCart, EmptyCart, RemoveRainOneFromCart, ResetCart, SetOrderType } from 'src/app/store/actions/cart.action';
import { CartState } from 'src/app/store/state/cart.state';

@Component({
  selector: 'app-cart-error-modal',
  templateUrl: './cart-error-modal.component.html',
  styleUrls: ['./cart-error-modal.component.scss']
})
export class CartErrorModalComponent implements OnInit {
  public isPurchasingNvidia: boolean = false;
  message: string;
  showAccount: boolean;

  constructor(public activeModal: NgbActiveModal, public store: Store, public router: Router) {}

  ngOnInit() {
    this.isPurchasingNvidia = this.store.selectSnapshot(CartState.GetOrderType) !== 'bundle';
  }

  static openModal(message: string, showAccount: boolean, modalService: NgbModal, store?: Store, cartSvc?: CartService) {
    const options: NgbModalOptions = { size: <any>'confirm', windowClass: 'slideInUp', centered: true, backdrop: 'static',
      keyboard: false };

    const modalRef = modalService.open(CartErrorModalComponent, options);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.showAccount = showAccount;
    modalRef.result
      .then(res => {
        if (res) { 
          if (window.location.href.includes('/cart') && store) {
            const orderType = store.selectSnapshot(CartState.GetOrderType);
            const actions: any[] = [];

            if (orderType === 'multi') {
              actions.push(new RemoveRainOneFromCart());
            } else if (orderType === 'bundle') {
              if (res !== 'isRoutingToOrdersPage') {
                actions.push([new Navigate(['/']), new ResetCart()]);
                cartSvc.clear();
              }
            }

            store.dispatch([...actions]);
          }
        }
      })
      .catch(err => console.log('err:', err));
  }

  public gotToMyOrders() {
    this.activeModal.close('isRoutingToOrdersPage');
    return this.store.dispatch([new Navigate(['/manage/orders']), new EmptyCart()]);
  }
}