<!-- Modal -->

<div class="bg-white">
<div>
    <div class="close-sign-in-wrapper px-sm-25 px-10 d-flex justify-content-between align-items-center">
      <div class="close m-0 p-0 pr-10" data-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
        <!-- <img src="/assets/images/xmark.svg" id="closeButton" class="cursor text-blue" alt="Close" /> -->
        <svg xmlns="http://www.w3.org/2000/svg" class="cursor" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <path d="M0.318647 14.0875C-0.0979358 14.5041 -0.117773 15.248 0.328565 15.6844C0.764985 16.1208 1.50888 16.1109 1.92546 15.6943L7.99567 9.6142L14.0758 15.6943C14.5023 16.1208 15.2363 16.1208 15.6727 15.6844C16.0992 15.2381 16.1091 14.514 15.6727 14.0875L9.60248 8.00738L15.6727 1.93718C16.1091 1.51068 16.1091 0.776704 15.6727 0.340284C15.2263 -0.086217 14.5023 -0.0961356 14.0758 0.330365L7.99567 6.41048L1.92546 0.330365C1.50888 -0.086217 0.755066 -0.106054 0.328565 0.340284C-0.107854 0.776704 -0.0979358 1.5206 0.318647 1.93718L6.39877 8.00738L0.318647 14.0875Z" fill="#787878"/>
        </svg>
      </div>

      <div>
        <a
          *ngIf="!isSignedIn"
          data-id="nav-click"
          data-text="side nav | sigin in"
          class=""
          (click)="signIn()"
        >
          sign in
        </a>

        <a
          *ngIf="isSignedIn"
          data-id="nav-click"
          data-text="side nav | sigin out"
          class=""
          (click)="signOut()"
        >
          sign out
        </a>
      </div>
    </div>
    <div class="pr-0 pl-sm-25 pl-10 py-5" style="background: linear-gradient(93deg, #0077C8 3.74%, #003A62 191.71%);">
      <div class="welcome-heading">
        <h2 *ngIf="!isSignedIn" class="text-white" style="font-weight: 400;">
          welcome <br />
          to <span class="font-weight-bold"> rain </span>
        </h2>
        <h2 *ngIf="isSignedIn" class="text-white" style="font-weight: 400;">
          hi there, <br />
         <span class="font-weight-bold">{{ displayName }}</span>
        </h2>

      </div>
    </div>

    <div class="bg-white modal-body pl-sm-25 pl-10">
      <ul class="list-group pl-0" (click)="onCloseModal()">
        <li class="list-group-item pl-0 border-0 bg-transparent active">
          <a
          class="grey-text"
          data-id="nav-click"
          data-text="shop"
          (click)="home(); activeModal.dismiss('shop'); scrollToShop()"
          >shop</a
          >
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent active">
          <a
            class="grey-text"
            data-id="nav-click"
            data-text="rainGo"
            (click)="activeModal.dismiss('rainGo'); openRainGo()"
            >rainGO</a>
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent">
          <a
            class="grey-text"
            data-id="nav-click"
            data-text="my account"
            (click)="activeModal.dismiss('my account')"
            [routerLink]="[routes.accountDetails]"
            >my account</a
          >
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent" *ngIf="isSignedIn">
          <a
            class="grey-text"
            data-id="nav-click"
            data-text="my orders"
            (click)="activeModal.dismiss('orders')"
            [routerLink]="['/manage/orders']"
            >my orders</a
          >
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent">
          <a
            class="grey-text askRain"
            data-id="nav-click"
            data-text="coverage-check"
            (click)="activeModal.dismiss('coverage check');askRain('coverageCheck')"
            >coverage check</a
          >
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent">
          <a
            class="grey-text"
            data-id="nav-click"
            data-text="setting up network"
            (click)="activeModal.dismiss('setting-up-network')"
            [routerLink]="[routes.fourGMobile]"
            >4G mobile</a>
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent">
          <a
            class="grey-text askRain"
            data-id="nav-click"
            data-text="international-dialling"
            (click)="activeModal.dismiss('international dialling');askRain('internationalDialling')"
            >international dialling</a>
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent">
          <a
            class="grey-text askRain"
            data-id="nav-click"
            data-text="store locator"
            (click)="activeModal.dismiss('store locator');askRain('storeLocator')"
          >store locator</a
          >
        </li>      

          <!--        <li class="list-group-item pl-0 border-0 bg-transparent" *ngIf="isSignedIn && displayPreferences">-->
          <!--          <span>-->
          <!--            <a-->
          <!--              class="grey-text"-->
          <!--              data-id="nav-click"-->
          <!--              data-text="notification preference"-->
          <!--              (click)="activeModal.dismiss('notification preference')"-->
          <!--              [routerLink]="['/notifications']"-->
          <!--              >my notifications-->
          <!--            </a>-->
          <!--          </span>-->
          <!--          <span class="badge">-->
          <!--            <span class="circle text-white">{{ totalUreadMessages$ | async }}</span>-->
          <!--          </span>-->
          <!--        </li>-->
          <!-- <li class="list-group-item pl-0 border-0 bg-transparent active">
              <a
                      class="grey-text"
                      data-id="nav-click"
                      data-text="our-network"
                      (click)="activeModal.dismiss('our-network')"
                      [routerLink]="['/our-network']">our network</a>
          </li> -->
        <li class="list-group-item pl-0 border-0 bg-transparent active">
          <a
            class="grey-text"
            data-id="nav-click"
            data-text="legal"
            (click)="activeModal.dismiss('legal')"
            [routerLink]="['/legal']"
            >legal</a
          >
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent active" >
          <a
            class="grey-text"
            data-id="nav-click"
            data-text="legal"
            (click)="activeModal.dismiss('nvidia')"
            [routerLink]="['/nvidia']"
            > <img class="nvidia-img" src="assets/images/nvidia/desktop-logo.svg" /></a
          >
        </li>
      </ul>

      <!-- <div class="border-bottom border-top">
        <div class="app-wrapper-mobi card-block pb-5 pt-5" *ngIf="isMobileScreen">
          <img
            alt="app gallery"
            data-id="exit-click"
            (click)="goToExternalUrl('https://appgallery.huawei.com/#/app/C103109563')"
            data-text="app gallery | https://appgallery.huawei.com/#/app/C103109563"
            src="/assets/images/huawei.png"
            class="img-fluid mr-7 cursor"
            width="55px"
            height="60px"
          />

          <img
            alt="google store"
            data-id="exit-click"
            (click)="goToExternalUrl('https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA')"
            data-text="google store | https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA"
            src="/assets/images/google-play-img.png"
            class="img-fluid mx-auto cursor"
            width="55px"
            height="60px"
          />

          <img
            alt="apple store"
            data-id="exit-click"
            (click)="goToExternalUrl('https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA')"
            data-text="apple store | https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA"
            src="/assets/images/apple-store-mobi.svg"
            class="img-fluid mx-auto cursor"
            width="55px"
            height="60px"
          />
        </div>

        <div class="pb-5 pt-5" *ngIf="!isMobileScreen">
          <div>
            <span class="grey-text">download the <strong>my rain app</strong></span>
          </div>
          <div class="app-wrapper d-md-flex flex-wrap">
            <div>
              <img
                data-id="exit-click"
                data-text="google store | https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA"
                class="py-2 app-store-img"
                src="/assets/images/download-app/google-play-img.png"
                alt=""
                (click)="goToExternalUrl('https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA')"
              />
            </div>

            <div class="mr-md-6">
              <img
                data-id="exit-click"
                data-text="app gallery | https://appgallery.huawei.com/#/app/C103109563"
                class="py-2 app-store-img"
                src="/assets/images/download-app/huawei.png"
                alt=""
                (click)="goToExternalUrl('https://appgallery.huawei.com/#/app/C103109563')"
              />
            </div>
            <div class="mr-md-6">
              <img
                data-id="exit-click"
                data-text="apple store | https://apps.apple.com/za/app/my-rain-app/id1642478404"
                class="py-2 app-store-img"
                src="/assets/images/apple-store-desktop.svg"
                alt=""
                (click)="goToExternalUrl('https://apps.apple.com/za/app/my-rain-app/id1642478404')"
              />
            </div>
          </div>
        </div>
      </div> -->

    
    </div>
  </div>
</div>
