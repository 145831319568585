import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
    GetAxiomUserDetails,
    GetPromoOptIn,
    GetUserDetails,
    GetUserPermissions,
    GetWhatsappDetails,
    SetLegacy4GLimit,
    SetPromoOptIn,
    SetWhatsappOptIn,
    UpdateUserDetailsOld
} from '../actions/user.action';
import {UserServiceV2} from "../service/user.service";
import {tap} from "rxjs/operators";
import {ToastService} from "../../utils/ToastService";
import {UserUpdateRequest} from '@models/userUpdateRequest';
import {IUserDetail} from '@models/userDetail';
import {defaultPermissions, permissions} from "../models/user.model";

export interface UserStateModel {
    userDetails: IUserDetail;
    axiomUserDetails: {}
    whatsappDetails: {}
    promoDetails: {}
    userPermissions: permissions
    userPermissionsList: {}
    loadingWhatApp: boolean
    loadingPromotions: boolean
}

@State<UserStateModel>({
    name: 'user',
    defaults: {
        userDetails: null,
        axiomUserDetails: null,
        whatsappDetails: null,
        promoDetails: null,
        userPermissions: defaultPermissions,
        userPermissionsList: {},
        loadingWhatApp: false,
        loadingPromotions: false
    }
})
@Injectable({
    providedIn: 'root'
})
export class UserState {
    constructor(
        private userService: UserServiceV2,
        private toast: ToastService
    ) {
    }

    @Selector()
    static getUserDetails(state: UserStateModel) {
        return state.userDetails;
    }

    @Selector()
    static getAxiomUserDetails(state: UserStateModel) {
        return state.axiomUserDetails;
    }

    @Selector()
    static getUserPermissions(state: UserStateModel) {
        return state.userPermissions;
    }

    @Selector()
    static getLoadingWhatApp(state: UserStateModel) {
        return state.loadingWhatApp;
    }

    @Selector()
    static getLoadingPromotions(state: UserStateModel) {
        return state.loadingPromotions;
    }

    @Selector()
    static getUserPermissionsList(state: UserStateModel) {
        return state.userPermissionsList;
    }

    @Selector()
    static getWhatsappDetails(state: UserStateModel) {
        return state.whatsappDetails;
    }

    @Selector()
    static getPromoDetails(state: UserStateModel) {
        return state.promoDetails;
    }

    @Action(GetUserDetails)
    setUserDetails(ctx: StateContext<UserStateModel>, action: GetUserDetails) {
        return this.userService.getUserDetails().pipe(
            tap((res: any) => {
                ctx.patchState({userDetails: res.value});
            })
        );
    }

    @Action(GetAxiomUserDetails)
    setAxiomUserDetails(ctx: StateContext<UserStateModel>, action: GetAxiomUserDetails) {
        return this.userService.getAxiomUserDetails().pipe(
            tap((res: any) => {
                ctx.patchState({axiomUserDetails: res});
            })
        );
    }

    @Action(GetUserPermissions)
    setUserPermissions(ctx: StateContext<UserStateModel>, action: GetUserPermissions) {
        return this.userService.getUserPermissions(action.userId).pipe(
            tap((res: any) => {
                ctx.patchState({
                    userPermissions: this.convertYOrNToBoolean(res.value.roleMap),
                    userPermissionsList: res.value.partyRoleList
                });
            })
        );
    }

    @Action(GetWhatsappDetails)
    getWhatsappDetails(ctx: StateContext<UserStateModel>) {

        ctx.patchState({loadingWhatApp: true});

        return this.userService.getOptInChannelDetails().pipe(
            tap((res: any) => {
                if (res.error == null) {
                    ctx.patchState({whatsappDetails: res.data, loadingWhatApp: false});
                } else {
                    this.toast.showToast('unable to get whatsapp subscription', 'error');
                }
            })
        );
    }

    @Action(SetWhatsappOptIn)
    setWhatsappOptIn(ctx: StateContext<UserStateModel>, action: { optIn: boolean; }) {

        
        return this.userService.optInChannel(action.optIn);

       
    }

    @Action(GetPromoOptIn)
    getPromoDetails(ctx: StateContext<UserStateModel>) {

        ctx.patchState({loadingPromotions: true});

        return this.userService.getOptInCategoryDetails().pipe(
            tap((res: any) => {
                if (res.error == null) {
                    ctx.patchState({promoDetails: res.data, loadingPromotions: false});
                } else {
                    this.toast.showToast('unable to get promo subscription', 'error');
                }
            })
        );
    }

    @Action(SetPromoOptIn)
    setPromoOptIn(ctx: StateContext<UserStateModel>, action: { optIn: boolean; }) {


        return this.userService.optInCategory(action.optIn);

    }

    @Action(SetLegacy4GLimit)
    setLegacy4GLimit(ctx: StateContext<UserStateModel>, action: SetLegacy4GLimit) {
        return this.userService.setLegacy4GLimit(action.serviceId, action.value);
    }

    @Action(UpdateUserDetailsOld)
    setUserDetailsOld(ctx: StateContext<UserStateModel>, action: { userDetails: UserUpdateRequest; }) {
        return this.userService.updateUserDetailsOld(action.userDetails);
    }

    private convertYOrNToBoolean(roleMap: []): permissions {
        const convertedRoleMap: permissions = {...defaultPermissions};
        for (const key in roleMap) {
            convertedRoleMap[key] = roleMap[key] === "Y";
        }
        return convertedRoleMap;
    }
}
