<div class="mb-10 modal-header border-0 ">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
      src="../../assets/svg/close.svg" />
  </div>
</div>
<div class="modal-body border-0 p-5 ">
  <div class="text-center">
    <h2 class="text-primary">let's investigate</h2>
    <p class="mb-0">
      Please provide the detail of the issue you are experiencing.
    </p>
    <p>We will investigate and get back to you as soon as possible.</p>
  </div>
  <form [formGroup]="userComplaintForm" (ngSubmit)="save()" class="m-10" id="form-support">
    <div class="form-group">
      <input id="name" class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
        formControlName="fullName" required placeholder="full name" />
      <div *ngIf="formSubmit && userComplaintForm.controls.fullName.errors">
        <div *ngIf="userComplaintForm.controls.fullName.errors.required">
          <span class="text-danger small-font-size">Name is required.</span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <input id="email" class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
        formControlName="email" required type="email" placeholder="email" />

      <div *ngIf="formSubmit && userComplaintForm.controls.email.errors">
        <div *ngIf="userComplaintForm.controls.email.errors.required">
          <p class="text-danger small-font-size">
            email is required.
          </p>
        </div>
        <div *ngIf="userComplaintForm.controls.email.errors.pattern">
          <p class="text-danger small-font-size">
            please enter a valid email address
          </p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <input id="cellNumber" type="tel"
        class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
        formControlName="cellNumber" required pattern="[0-9]+" placeholder="cellphone number" inputmode="numeric"
        maxlength="10" />

      <div *ngIf="formSubmit && userComplaintForm.controls.cellNumber.errors">
        <div *ngIf="userComplaintForm.controls.cellNumber.errors.required">
          <p class="text-danger small-font-size">
            cell number is required.
          </p>
        </div>
        <div *ngIf="
            userComplaintForm.controls['cellNumber'].hasError('minlength') ||
            userComplaintForm.controls['cellNumber'].hasError('maxlength')
          ">
          <p class="text-danger small-font-size">
            Please enter a valid phone number.
          </p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <input id="address" formControlName="address"
        class="form-control p-0 bg-transparent border-0 rounded-0 border-btm pt-3 py-5 medium-font-size"
        placeholder="physical address (where you are currently using rain)" ngx-google-places-autocomplete
        [options]="config" (onAddressChange)="handleMapSearch($event)" autocomplete="off" />

      <div *ngIf="formSubmit && userComplaintForm.controls.address.errors">
        <div *ngIf="userComplaintForm.controls.address.errors.required">
          <span class="text-danger small-font-size">address is required.</span>
        </div>
        <div *ngIf="
            !userComplaintForm.controls.address.errors.required && userComplaintForm.controls.address.errors.invalid
          ">
          <span class="text-danger small-font-size">Please enter a valid street address.</span>
        </div>
      </div>
    </div>
    <div class="formgroup">
      <h3 class="text-primary mb-5">service:</h3>
      <div class="rdio rdio-primary  radio-inline">
        <input type="radio" id="fourg" value="4g" formControlName="serviceType" class="mr-2" />
        <label for="fourg"> 4G </label><br />
      </div>
      <div class="rdio rdio-primary   radio-inline">
        <input type="radio" id="fiveg" value="5g" formControlName="serviceType" class="mr-2" />
        <label for="fiveg"> 5G </label><br />
      </div>

      <div *ngIf="formSubmit && userComplaintForm.controls.serviceType.errors">
        <div *ngIf="userComplaintForm.controls.serviceType.errors.required">
          <span class="text-danger small-font-size">Please select service type</span>
        </div>
      </div>
    </div>
    <div class="formgroup">
      <h3 class="text-primary mb-5">what would you like help with:</h3>
      <div class="rdio rdio-primary  rdio-primary radio-inline">
        <input type="radio" id="billing" value="billing" formControlName="query" class="mr-2" />
        <label for="billing"> Billing</label><br />
      </div>

      <div class="rdio rdio-primary radio-inline">
        <input type="radio" value="delivery" id="delivery" formControlName="query" class="mr-2" />
        <label for="delivery"> Delivery</label><br />
      </div>

      <div class="rdio rdio-primary radio-inline">
        <input type="radio" id="rica" value="rica" formControlName="query" class="mr-2" />
        <label for="rica"> RICA and Activation </label><br />
      </div>

      <div class="rdio rdio-primary radio-inline">
        <input type="radio" id="network" value="network" formControlName="query" class="mr-2" />
        <label for="network"> Network / Connectivity </label><br />
      </div>

      <div class="rdio rdio-primary radio-inline">
        <input type="radio" id="general" value="general" formControlName="query" class="mr-2" />
        <label for="general"> General enquiries </label><br />
      </div>

      <div *ngIf="formSubmit && userComplaintForm.controls.query.errors">
        <div *ngIf="userComplaintForm.controls.query.errors.required">
          <span class="text-danger small-font-size">Please select any category.</span>
        </div>
      </div>
    </div>
    <div class="formgroup mt-5">
      <h3 class="text-primary mb-5">comment:</h3>
      <textarea class="bg-gray" id="text-block" formControlName="content"></textarea>
      <div *ngIf="formSubmit && userComplaintForm.controls.content.errors">
        <div *ngIf="userComplaintForm.controls.content.errors.required">
          <span class="text-danger small-font-size">Please enter the details.</span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button type="submit" [ngClass]="
          (userComplaintForm.valid ? 'btn btn-primary' : 'btn btn-dark-grey disabled') +
          '  btn-sm mt-10 bg-button font-weight-normal submit-button'
        ">
        <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="!loading">Submit</span>
      </button>
    </div>
  </form>
</div>