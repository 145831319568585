import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CookieService {
  constructor() {}

  getGATag() {

    const cookie = {};

    document.cookie.split('; ').forEach(el =>  {

      const splitCookie = el.split('=');

      const key = splitCookie[0].trim();

      const value = splitCookie[1];

      cookie[key] = value;

    });

  return cookie["_ga"];

  }
}
