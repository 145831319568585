import {CACHE_AUTH_TOKEN} from './../models/constants';
import {CACHE_SUPPORT, CACHE_SUPPORT_ADDED, CACHE_SUPPORT_GROUP, CACHE_TOP_10_SUPPORT} from '@models/constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {includes, isEmpty, isNil, take} from 'lodash';
import {Observable, of} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';
import {v4} from 'uuid';
import {Result} from '@models/result';
import {CacheService} from './cache.service';
import {ConfigService} from './config.service';
import {ServiceHelper} from './serviceHelper';
import moment from 'moment';
import {TokenService} from '@services/token.service';

/**
 * ISupportItemDetail @param
 */
export interface ISupportItemDetail {
    /**
     * question @param {string}
     */
    question: string;

    /**
     * group @param {string}
     */
    group: string;

    /**
     * number @param {number}
     */
    number: number;

    /**
     * answer @param {string}
     */
    answer: string;
}

/**
 * IRemoteSupportItemDetail @param
 */
export interface IRemoteSupportItemDetail {
    /**
     * question @param {string}
     */
    question: string;

    /**
     * group @param {string}
     */
    group: string;

    /**
     * number @param {number}
     */
    number: number;

    /**
     * answer @param {string}
     */
    answer: string;
}

/**
 * SupportItemDetail @param {}
 */
export class SupportItemDetail {
    /**
     * IRemoteSupportItemDetail @param {}
     */
    static adapt(remote: IRemoteSupportItemDetail): ISupportItemDetail {
        return remote as ISupportItemDetail;
    }
}

/**
 * IAddTicketRequest @param
 */
export interface IAddTicketRequest {
    /**
     * captcha @param {any}
     */
    captcha: any;

    /**
     * name @param {string}
     */
    name: string;

    /**
     * email @param {string}
     */
    email: string;

    /**
     * subject @param {string}
     */
    subject: string;

    /**
     * body? @param {any}
     */
    body?: any;

    /**
     * referenceId @param {string}
     */
    referenceId: string;
    ga_tag?: string;
    gclId?: string;
    clickToClaimAmount?: number;
    xtenderCount?: any;
    selectedSpeed?: any;
}

/**
 * ISupportItemDetail @param
 */
export interface ISupportItemDetail {
    /**
     * id @param {string}
     */
    id: string;

    /**
     * name @param {string}
     */
    name: string;

    /**
     * description @param {string}
     */
    description: string;

    /**
     * path @param {string}
     */
    path: string;

    /**
     * pathTitle @param {string}
     */
    pathTitle: string;

    /**
     * pathElement @param {string}
     */
    pathElement: string;
}

/**
 * IFAQ @param
 */
export interface IFAQ {
    /**
     * id @param {string}
     */
    id: string;

    /**
     * name @param {string}
     */
    name: string;

    /**
     * description @param {string}
     */
    description: string;
}

/**
 * IFAQGroup @param
 */
export interface IFAQGroup {
    /**
     * deviceType @param {string}
     */
    deviceType: string;

    /**
     * items @param {IFAQ[]}
     */
    items: IFAQ[];

    /**
     * id @param {string}
     */
    id: string;
}

/**
 * ISupportGroupDetail @param
 */
export interface ISupportGroupDetail {
    /**
     * id @param {string}
     */
    id: string;

    /**
     * name @param {string}
     */
    name: string;

    /**
     * items @param {ISupportItemDetail[]}
     */
    items: ISupportItemDetail[];

    /**
     * icon @param {string}
     */
    icon: string;
}

@Injectable({
    providedIn: 'root'
})
export class SupportService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private cacheService: CacheService,
        private tokenService: TokenService
    ) {
    }

    public getTop10Questions(ignoreCache = false): Observable<Result<ISupportItemDetail[]>> {
        if (ignoreCache === false && this.isCached()) {
            const cached = this.getTop10Cache();

            return of(Result.success(cached));
        }

        const requestPath = this.configService.TOP_10_SUPPORT_QUESTION_API_URL;

        return this.http.get(requestPath).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {
                const details = response.items.map((rawSummary: IRemoteSupportItemDetail) =>
                    SupportItemDetail.adapt(rawSummary)
                );

                this.setTop10Cache(details);

                return Result.success(details);
            }),
            catchError(result => ServiceHelper.handleError<ISupportItemDetail[]>(result))
        );
    }

    public getQuestions(ignoreCache = false): Observable<Result<ISupportItemDetail[]>> {
        if (ignoreCache === false && this.isCached()) {
            const cached = this.getCache();

            return of(Result.success(cached));
        }

        const requestPath = this.configService.SUPPORT_QUESTION_API_URL;

        return this.http.get(requestPath).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {
                const details = response.items.map((rawSummary: IRemoteSupportItemDetail) =>
                    SupportItemDetail.adapt(rawSummary)
                );

                this.setCache(details);

                return Result.success(details);
            }),
            catchError(result => ServiceHelper.handleError<ISupportItemDetail[]>(result))
        );
    }

    public getGroups(ignoreCache: boolean = false): Observable<Result<ISupportGroupDetail[]>> {
        if (ignoreCache === false && this.cacheService.exists(CACHE_SUPPORT_GROUP)) {
            const cached = this.cacheService.getObject<ISupportGroupDetail[]>(CACHE_SUPPORT_GROUP);

            return of(Result.success(cached));
        }

        const requestPath = this.configService.SUPPORT_GROUP_ITEM_API_URL;

        return this.http.get(requestPath).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {
                const groups = response.items as ISupportGroupDetail[];

                groups.forEach(x => {
                    x.id = v4();

                    if (isNil(x.items) === false) {
                        x.items.forEach(y => {
                            y.id = v4();

                            if (isNil(y.pathTitle)) {
                                y.pathTitle = 'link';
                            }
                        });
                    } else {
                        x.items = [];
                    }
                });

                this.cacheService.setObject(CACHE_SUPPORT_GROUP, groups);

                return Result.success(groups);
            }),
            catchError(result => ServiceHelper.handleError<ISupportGroupDetail[]>(result))
        );
    }

    public getQuestionsByTerm(term: string, maxResult = 0, ignoreCache = false): Observable<ISupportItemDetail[]> {
        if (isNil(term) || isEmpty(term)) {
            return of([]);
        }

        return this.getQuestions(ignoreCache).pipe(
            map(result => {
                const filteredItems = result.value.filter(x => includes(x.question, term));

                if (maxResult > 0) {
                    return take(filteredItems, maxResult);
                }

                return filteredItems;
            })
        );
    }

    public addTicket(request: IAddTicketRequest): Observable<Result<any>> {
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // [HTTP_HEADER_RE_CAPTCHA]: request.captcha,
                Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)

            })
        };

        const requestPath = `${this.configService.NOTIFY_URL}/support/ticket`;
        const body = {
            name: request.name,
            email: request.email,
            subject: request.subject,
            body: request.body,
            referenceId: request.referenceId,
            ga_tag: request?.ga_tag,
            gclId: request?.gclId,
            xtenderCount: request?.xtenderCount,
            selectedSpeed: request?.selectedSpeed
        };

        return this.http.post(requestPath, body, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {
                return (response);
            }),
            catchError(result => ServiceHelper.handleError<boolean>(result))
        );
    }

    public addTicketWithResponse(request): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)

            })
        };

        const requestPath = `${this.configService.NOTIFY_URL}/support/ticket`;

        const body = {
            name: request.name,
            email: request.email,
            subject: request.subject,
            body: request.body,
            referenceId: request.referenceId
        };

        return this.http.post(requestPath, body, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {
                return Result.success(response);
            }),
            catchError(result => ServiceHelper.handleError<boolean>(result))
        );
    }

    /**
     *
     * ISupportItemDetail[] @param {setObject}
     */
    setCache(details: ISupportItemDetail[]) {
        this.cacheService.setObject(CACHE_SUPPORT, details);
    }

    /**
     *
     * isCached() @param {boolean}
     */
    isCached(): boolean {
        return this.cacheService.exists(CACHE_SUPPORT);
    }

    /**
     *  ISupportItemDetail[] @param {}
     */
    getCache(): ISupportItemDetail[] {
        return this.cacheService.getObject<ISupportItemDetail[]>(CACHE_SUPPORT);
    }

    getTop10Cache(): ISupportItemDetail[] {
        return this.cacheService.getObject<ISupportItemDetail[]>(CACHE_TOP_10_SUPPORT);
    }

    setTop10Cache(details: ISupportItemDetail[]) {
        this.cacheService.setObject(CACHE_TOP_10_SUPPORT, details);
    }

    setLocaStorage() {
        const expiry = moment()
            .add(1, 'day')
            .toDate()
            .getTime();

        const item = {
            value: 'set',
            expiry
        };

        localStorage.setItem(CACHE_SUPPORT_ADDED, JSON.stringify(item));
    }

    getLocalStorage() {
        const itemStr = localStorage.getItem(CACHE_SUPPORT_ADDED);

        if (!itemStr) {
            return false;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(CACHE_SUPPORT_ADDED);
            return false;
        }
        return true;
    }
}
