import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/layout/header/header.component';
import { LoginModule } from '@components/login/login.module';

@NgModule({
    declarations: [
        HeaderComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        LoginModule
    ],
    exports: [
        HeaderComponent],
    providers: [],
})
export class HeaderModule { }
