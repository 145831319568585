import { Injectable } from '@angular/core';
import { fixedProducts, IProductDetail, ProductCategories, ProductPaymentTypes, ProductTypes, ProductViewModel } from '@models/productDetail';
import { Result } from '@models/result';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { ProductService } from '@services/product.service';
import { tap } from 'rxjs/operators';
import { RainOneProduct } from 'src/app/core/interfaces/rain-one-products.interface';
import { ProductCatelogueService } from 'src/app/core/services/product-catelogue/product-catelogue.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { Utils } from 'src/app/utils';
import { ToHashMap } from 'src/app/utils/custom-data-structures';
import { sortAscendingBy } from 'src/app/utils/helpers/sort.helper';
import {
  FetchProductsFromCatelogue,
  FetchProductsFromCatelogueFail,
  FetchProductsFromCatelogueSuccess,
  GetActiveProducts,
  GetAllProducts,
  PrepareProductsForDisplay,
  SetBlackFridayProducts,
  SetPostPaidFiveGProducts,
  SetPostPaidFourGProducts,
  SetPostPaidICCIDProducts,
  SetSimDeliveryOptions,
  SetStandAlone4GAUProducts,
  SetUpfrontFiveGProducts,
  SetUpfrontFourGProducts,
  SetUpfrontICCIDProducts
} from '../actions/product.actions';
import * as data from '../../../assets/data/rain-one-products.json';
import * as sitData from '../../../assets/data/rain-one-products-sit.json';
import { environment } from 'src/environments/environment';
import {
  ARTSKINS,
  EXTENDERSKINS,
  FOURG_SIM_NO_LEGACY,
  FOURG_STANDALONE,
  PROSKINS,
  RAIN_MOBILE_DISPLAY_NAME
} from '@models/constants';
import {SelectableSkin} from "@pages/skin-selector/skin-model";

export interface ProductStateModel {
  activeProducts: IProductDetail[];
  test: string;
  allProducts: IProductDetail[];
  postPaidFiveGProducts: IProductDetail[];
  upfrontFiveGProducts: IProductDetail[];
  postPaidFourGProducts: IProductDetail[];
  upfrontFourGProducts: IProductDetail[];
  simDeliveryoptions: ProductViewModel[];
  postPaidICCID: IProductDetail[];
  upfrontICCID: IProductDetail[];
  payAsYouGo: {
    seveDaysPrepaid: string;
    giveAGig: string;
    t20: string;
  };
  productsToDisplay: { [key: string]: ProductViewModel[] };
  blackFridayProducts: { [key: string]: BlackFridayProductModel };
  allProductsMap: { [key: string]: IProductDetail };
  catelogue: { [type: string]: RainOneProduct[] };
  catelogue_isLoaded: boolean;
  catelogue_isLoading: boolean;
  standAlone4GAU: RainOneProduct
}
export interface BlackFridayProductModel {
  bf: string;
  original: string;
  boostedFrom: string;
  boostedTo: string;
  originalPrice: number;
}

export const bfProductsmap: { [key: string]: BlackFridayProductModel } = {
  'f33cc102-cacb-491f-84b7-080fd3c7bd0b': {
    bf: '5g_upfront_premium_black_friday',
    original: 'standard_5g_upfront_v1',
    boostedFrom: 'unlimited home 5G standard',
    boostedTo: 'unlimited home 5G premium',
    originalPrice: 999
  },
  '6c7e7644-9a6d-4e8e-a541-520dbf7f0512': {
    bf: '5g_postpaid_premium_black_friday',
    original: '9',
    boostedFrom: 'unlimited home 5G standard',
    boostedTo: 'unlimited home 5G premium',
    originalPrice: 999
  },
  'cca0fe79-34d4-462b-aacd-20fc7da8cd85': {
    bf: '5g_upfront_standard_black_friday',
    original: 'basic_5g_upfront_v1',
    boostedFrom: 'unlimited home 5G basic',
    boostedTo: 'unlimited home 5G standard',
    originalPrice: 739
  },
  'b236d2f9-f4cb-4efc-847b-b780f9dcf2f3': {
    bf: '5g_postpaid_standard_black_friday',
    original: '11',
    boostedFrom: 'unlimited home 5G basic',
    boostedTo: 'unlimited home 5G standard',
    originalPrice: 739
  }
};

@State<ProductStateModel>({
  name: 'product',
  defaults: {
    activeProducts: [],
    allProducts: [],
    allProductsMap: null,
    blackFridayProducts: bfProductsmap,
    test: '',
    postPaidFiveGProducts: [],
    upfrontFiveGProducts: [],
    postPaidFourGProducts: [],
    upfrontFourGProducts: [],
    productsToDisplay: null,
    simDeliveryoptions: [],
    postPaidICCID: [],
    upfrontICCID: [],
    payAsYouGo: {
      seveDaysPrepaid: 'a911af35-30d0-4e0d-a407-633d27ad8f3a',
      giveAGig: '4eb3ec7f-1a51-44cb-b2a9-f55ddd560b56',
      t20: 'ba41623e-c7e1-4186-9e4b-685de888685f'
    },
    catelogue: {},
    catelogue_isLoaded: false,
    catelogue_isLoading: false,
    standAlone4GAU: null
  }
})
@Injectable({ providedIn: 'root' })
export class ProductState implements NgxsOnInit {

  @Selector()
  static GetProductById(id: string) {
    return createSelector([ProductState], (state: any): IProductDetail | RainOneProduct => {
      const bundles: RainOneProduct[] = [...Utils.Mappers.FlattenHashMapArrays<RainOneProduct>(state.product.catelogue as any), state.product.allProducts];
      
      const product = bundles.find(b => b.id === id); 
      return product;
    });
  }

  @Selector()
  static GetstandAlone4GAU(state: ProductStateModel) {
      return state.standAlone4GAU;
  }

  @Selector([ProductState])
  static postPaidFiveGProducts(state: ProductStateModel) {
    return state.postPaidFiveGProducts;
  }
  @Selector([ProductState])
  static activeProducts(state: ProductStateModel) {
    return state.activeProducts;
  }

  @Selector([ProductState])
  static payAsYouGo(state: ProductStateModel) {
    return state.payAsYouGo;
  }

  @Selector([ProductState])
  static allProducts(state: ProductStateModel) {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any) as any;
    return [...rainOneProducts, ...state.allProducts];
  }

  @Selector([ProductState])
  static getRainOneProducts5G(state: ProductStateModel) {
   
    const catelogue = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any) as any;
    
    const filteredProducts = catelogue.filter((product) => {

      if (!product?.config?.legacy && 
          product?.type === 'sim' && 
          product?.category === '5G'
      ) {
          return product;
      }
    });
    return filteredProducts;
  }

  @Selector([ProductState])
  static getRainMobileSimProducts(state: ProductStateModel) {
   
    const catelogue = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any) as any;
    const filteredProducts = catelogue.filter((product) => 
        !product?.config?.legacy && 
        product?.type === 'sim' && 
        product?.config?.displayName?.toLowerCase()?.includes('mobile')
    );
    return filteredProducts;
  }
  
  @Selector([ProductState.getRainMobileSimProducts])
  static getRainMobileSimProductIds(state: ProductStateModel, rainMobileProducts: RainOneProduct[]) {
    return rainMobileProducts.map((product) => {
      return product?.id
    });
  }

  @Selector([ProductState.getRainOneProducts5G])
  static getRainOneProductIDs5G(state: ProductStateModel, rainOneProducts: RainOneProduct[]) {
    return rainOneProducts.map((product) => {
      return product?.id
    });
  }

  @Selector([ProductState])
  static allProductsMap(state: ProductStateModel) {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any) as any;
    const mappedRainOneprods = Utils.Mappers.ToHashMap(rainOneProducts, {}, 'id');

    return state.allProductsMap;
  }

  @Selector([ProductState])
  static newProductsMap(state: ProductStateModel) {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any) as any;
    const mappedRainOneprods = Utils.Mappers.ToHashMap(rainOneProducts, {}, 'id');
    return mappedRainOneprods;
  }

  @Selector([ProductState])
  static allProductsMaprain(state: ProductStateModel) {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any) as any;
    const mappedRainOneprods = Utils.Mappers.ToHashMap(rainOneProducts, {}, 'id');
    return { ...mappedRainOneprods, ...state.allProductsMap };
  }

  @Selector([ProductState])
  static upfrontFiveGProducts(state: ProductStateModel) {
    return state.upfrontFiveGProducts;
  }
  @Selector([ProductState])
  static postPaidFourGProduct(state: ProductStateModel) {
    return state.postPaidFourGProducts;
  }

  @Selector([ProductState])
  static upfrontFourGProducts(state: ProductStateModel) {
    return state.upfrontFourGProducts;
  }

  @Selector([ProductState])
  static blackFridayProducts(state: ProductStateModel): { [key: string]: BlackFridayProductModel } {
    return state.blackFridayProducts;
  }

  @Selector([ProductState])
  static allUpfrontProducts(state: ProductStateModel) {
    return [...state.upfrontFourGProducts, ...state.upfrontFiveGProducts];
  }

  @Selector([ProductState])
  static allPostPaidProducts(state: ProductStateModel) {
    return [...state.postPaidFiveGProducts, ...state.postPaidFourGProducts];
  }

  @Selector()
  static productViewModels(state: ProductStateModel) {
    return (productCategory: string) => {
      return state?.productsToDisplay ? state?.productsToDisplay[productCategory] : [];
    };
  }

  @Selector()
  static isBlackFridayProduct(state: ProductStateModel) {
    return (productId: string) => {
      return !!state?.blackFridayProducts[productId];
    };
  }

  @Selector()
  static getBlackFridayProduct(state: ProductStateModel) {
    return (productId: string) => {
      return state?.blackFridayProducts[productId];
    };
  }

  static productsToDisplay(productCategory: string) {
    return createSelector([ProductState], (state: ProductStateModel) => {
      return state?.productsToDisplay[productCategory];
    });
  }

  static iisBlackFridayProduct(productId: string) {
    return createSelector([ProductState], (state: ProductStateModel) => {
      return !!state?.blackFridayProducts[productId];
    });
  }

  static getProductById(productId: string) {
    return createSelector(
        [ProductState.GetCatelogueProducts],
        (catalogue:[]) => catalogue?.find(item => productId === item?.['id'])
    );
}

  @Selector()
  static postPaidProductIdsSet(state: ProductStateModel, allOrderProductIdsSet) {
    const twoForOneCustomerBProductId = '12bf79dc-f05b-46cf-a91c-d28b79ed84e1';
    const PAfterYU_UOPProductId = 'c1f9cd04-d592-4667-a606-ac2a05b74923';
    const postPaid5GIds = state.postPaidFiveGProducts
      .map(prod => prod.items)
      .reduce((prev, curr) => {
        return curr.concat(prev);
      }, [])
      .filter(product => product.category === ProductCategories.FiveG)
      .map(product => product.id);
      
    const postPaid4GIds = state.postPaidFourGProducts
      .map(prod => prod.items)
      .reduce((prev, curr) => {
        return curr.concat(prev);
      }, [])
      .filter(product => product.category === ProductCategories.FourG)
      .map(product => product.id);

    const postICCIDIds = state.postPaidICCID
      .map(prod => prod.items)
      .reduce((prev, curr) => {
        return curr.concat(prev);
      }, [])
      .filter(product => product.category === ProductCategories.FourG)
      .map(product => product.id);

    return new Set([...postPaid5GIds, ...postPaid4GIds, ...postICCIDIds, twoForOneCustomerBProductId, PAfterYU_UOPProductId]);
  }

  @Selector()
  static GetRainOne101SimProducts(state: ProductStateModel) {
    const rainOne101SimProducts = Utils.Mappers.FlattenHashMapArrays<RainOneProduct>(state.catelogue as any)
      .filter((p) => (p?.name?.includes("101") || p?.name?.includes("rainOne work")) && p?.type === 'sim' && p?.category==='5G')
    return rainOne101SimProducts;
  }

  @Selector()
  static GetRainOne101BundleProducts(state: ProductStateModel) {
    const rainOne101BundleProducts = Utils.Mappers.FlattenHashMapArrays<RainOneProduct>(state.catelogue as any)
      .filter((p) => (p?.name?.includes("101") || p?.name?.includes("rainOne work")) && p?.type === 'bundle' && p?.category==='5G')
    return rainOne101BundleProducts;
  }

  @Selector([ProductState.GetRainOne101BundleProducts])
  static GetRainOne101BundleIDs(state: ProductStateModel, rainOne101BundleProducts: RainOneProduct[]) {
    const rainOne101BundleIDs = rainOne101BundleProducts.map(product => {
      return product.id
    });
    return rainOne101BundleIDs;
  }

  @Selector([ProductState.GetRainOne101SimProducts])
  static GetRainOne101SimIDs(state: ProductStateModel, rainOne101SimProducts: RainOneProduct[]) {
    const rainOne101SimIDs = rainOne101SimProducts.map(product => {
      return product.id
    });
    return rainOne101SimIDs;
  }

  @Selector()
  static GetRainOneUOPIDs(state: ProductStateModel) {
    const allUOPProducts = Utils.Mappers.FlattenHashMapArrays<RainOneProduct>(state.catelogue as any)
      .filter((p) => p.name === 'rainOne 4G unlimited off-peak' && p.type === 'bundle')
      .map(product => product.id);
    return allUOPProducts;
  }

  @Selector([ProductState.GetR1UOPCatelogueProducts])
  static GetRainOneUOPSimIDs(state: ProductStateModel, rainOneUOPProducts) {
    const uopIDS = rainOneUOPProducts.map(product => { 
      return product?.id 
    })
    return uopIDS;
  }

  @Selector()
  static GetRainOneUnlimtedIDs(state: ProductStateModel, allOrderProductIdsSet) {
    const nonLegacy4GSIms = FOURG_SIM_NO_LEGACY;
    const allUOPProducts = Utils.Mappers.FlattenHashMapArrays<RainOneProduct>(state.catelogue as any)
      .filter((p) => p.name === "unlimited 4G\n for any device" && p.type === 'bundle' || nonLegacy4GSIms.includes(p.id))
      .map(product => product.id);

      
    return allUOPProducts;
  }

  @Selector()
  static GetCatelogueProducts(state: ProductStateModel): RainOneProduct[] {
    return Utils.Mappers.FlattenHashMapArrays<RainOneProduct>(state.catelogue as any);
  }

  @Selector()
  static GetRain101Skins(state: ProductStateModel): RainOneProduct[] {
    return state.catelogue['accessory'];
  }

  @Selector()
  static GetRain101aSkins(state: ProductStateModel): SelectableSkin[] {
    const skins = [];
    state.catelogue['accessory']?.filter(skin => {
      if (skin.name.toLocaleLowerCase().includes('the 101.a skin') && !ARTSKINS.includes(skin?.config?.color)) {
        skins.push({...skin,
          image: 'assets/images/rain-101/skin-colors/pro-xtender-101a/101/' +
              skin?.config?.colorSelector + '.webp',
          buttonImage: 'assets/images/rain-101/skin-colors/pro-xtender-101a/buttons/101/' +
              skin?.config?.colorSelector + '.webp'});
      }
    });
    return skins;
  }


  @Selector()
  static GetRain101aArtSkins(state: ProductStateModel): SelectableSkin[] {
    const skins = [];
    ARTSKINS?.forEach(artSkin => {
      const skin = state.catelogue['accessory']?.find((s) => s?.config?.color === artSkin);
      if (skin) {
        skins.push({...skin,
          image: 'assets/images/rain-101/skin-colors/pro-xtender-101a/101/' +
              skin?.config?.colorSelector + '.webp',
          buttonImage: 'assets/images/rain-101/skin-colors/pro-xtender-101a/buttons/101/' +
              skin?.config?.colorSelector + '.webp'});
      }
    })
    return skins;
  }

  @Selector()
  static GetRain101ProSkins(state: ProductStateModel): SelectableSkin[] {
    const skins = [];
    state.catelogue['accessory']?.filter(skin => {
        if (skin?.config?.form_factor.includes('pro') && PROSKINS.includes(skin.config.color)) {
            skins.push({...skin,
              image: 'assets/images/rain-101/skin-colors/pro-xtender-101a/pro/' +
                  skin?.config?.colorSelector + '.webp',
            buttonImage: 'assets/images/rain-101/skin-colors/pro-xtender-101a/buttons/pro/' +
                skin?.config?.colorSelector + '.webp',
                buttonSoldOutImage: 'assets/images/rain-101/skin-colors/pro-xtender-101a/buttons/pro/' +
                    skin?.config?.colorSelector + '-sold-out.webp'});
        }
    });
    return skins;
  }

  @Selector()
  static GetRain101ExtenderSkins(state: ProductStateModel): SelectableSkin[] {
    const skins = [];
    state.catelogue['accessory']?.filter(skin => {
      if (skin?.config?.form_factor.includes('extender') && EXTENDERSKINS.includes(skin.config.color)) {
        skins.push({...skin,
          image: 'assets/images/rain-101/skin-colors/pro-xtender-101a/extender/' +
              skin?.config?.colorSelector + '.webp',
          buttonImage: 'assets/images/rain-101/skin-colors/pro-xtender-101a/buttons/extender/' +
              skin?.config?.colorSelector + '.webp',
              buttonSoldOutImage: 'assets/images/rain-101/skin-colors/pro-xtender-101a/buttons/extender/' +
                  skin?.config?.colorSelector + '-sold-out.webp'});
      }
    });
    return skins;
  }



  @Selector()
  static GetL1FourGPhoneLine(state: ProductStateModel) {
    const catelogue = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any);
    // return catelogue.find((p: RainOneProduct) => p.name === 'rainONE L1 4G Mobile');
    return catelogue.find((p: RainOneProduct) => p.name === '4G mobile phone line');
  }

  @Selector()
  static GetRain101aAUBase(state: ProductStateModel): RainOneProduct {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'bundle') as any;

    return rainOneProducts.find((p: RainOneProduct) => p.name === 'rainOne 101a' && p.config.paymentType === 'upfront' && p.type === 'bundle');
  }
  
  @Selector()
  static GetRain101aPPBase(state: ProductStateModel): RainOneProduct {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'bundle') as any;

    return rainOneProducts.find((p: RainOneProduct) => p.name === 'rainOne 101a' && p.config.paymentType === 'postpaid' && p.type === 'bundle');
  }


  @Selector()
  static GetRainOneL1(state: ProductStateModel): RainOneProduct {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'bundle') as any;

    return rainOneProducts.find((p: RainOneProduct) => p.name === 'rainOne');
  }

  @Selector()
  static GetMobilePhoneLineProducts(state: ProductStateModel): RainOneProduct[] {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'sim') as any;

    return rainOneProducts.filter((p: RainOneProduct) => p?.name?.toLocaleLowerCase().includes('mobile phone line') && !!p?.config?.base_subscription);
  }
  @Selector([ProductState.GetMobilePhoneLineProducts])
  static GetMobilePhoneLineProductsIDs(state: ProductStateModel, mobileLines: RainOneProduct[]): string[] {
    return mobileLines?.map(product => {
      return product?.id
    })
  }

  @Selector()
  static GetRainOne101ISimProducts(state: ProductStateModel): RainOneProduct {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'isim') as any;

    return rainOneProducts
  }
  @Selector([ProductState.GetRainOne101ISimProducts])
  static GetRainOne101ISimProductsIDs(state: ProductStateModel, isimProducts: RainOneProduct[]): string[] {
    return isimProducts?.map(product => {
      return product.id
    })
  }
  @Selector()
  static GetRain101AUBase(state: ProductStateModel): RainOneProduct {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'bundle') as any;

    return rainOneProducts.find((p: RainOneProduct) => p.name === 'rainOne 101a' && p.config.paymentType === 'upfront' && p.type === 'bundle');
  }

  @Selector()
  static GetRain101PPBase(state: ProductStateModel): RainOneProduct {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'bundle') as any;

    return rainOneProducts.find((p: RainOneProduct) => p.name === 'rainOne 101a' && p.config.paymentType === 'postpaid' && p.type === 'bundle');
  }

  @Selector()
  static GetRainOneL1PP(state: ProductStateModel): RainOneProduct {
    const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'bundle') as any;
    return rainOneProducts.find((p: RainOneProduct) => p.name === 'rainOne' && p.config.paymentType === 'postpaid');
  }

//start of work products 
@Selector()
static GetRain101AUBaseWork(state: ProductStateModel): RainOneProduct {
  const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'bundle') as any;
  return rainOneProducts.find((p: RainOneProduct) => p.name === 'rainOne 101a work' && p.config.paymentType === 'upfront' && p.type === 'bundle');
}

@Selector()
static GetRain101PPBaseWork(state: ProductStateModel): RainOneProduct {
  const rainOneProducts = Utils.Mappers.FlattenHashMapArrays(state.catelogue as any).filter((p: any) => p.type === 'bundle') as any;

  return rainOneProducts.find((p: RainOneProduct) => p.name === 'rainOne 101a work' && p.config.paymentType === 'postpaid' && p.type === 'bundle');
}

//end of work products 


  @Selector([ProductState.GetCatelogueProducts])
  static Get5GCatelogueProducts(state: ProductStateModel, catelogue: RainOneProduct[]): RainOneProduct[] {
    const fiveGProducts = catelogue?.filter(product => {
      return product?.category === '5G' && product?.config?.legacy
    })
    return fiveGProducts
  }
  @Selector([ProductState.Get5GCatelogueProducts])
  static Get5GCatelogueProductsIDs(state: ProductStateModel, fiveGProducts: RainOneProduct[]): string[] {
    return fiveGProducts?.map((product: RainOneProduct) => {
      return product?.id
    })
  }
  @Selector([ProductState.GetCatelogueProducts])
  static Get4GCatelogueLegacyProductNames(state: ProductStateModel, catelogue: RainOneProduct[]): string[] {
    const fourGProducts = catelogue?.filter(product => {
      return product?.category === '4G' && (product?.config?.legacy);
    })
    return fourGProducts.map(product => product?.name)
  }

  @Selector([ProductState.GetCatelogueProducts])
  static Get4GCatelogueProducts(state: ProductStateModel, catelogue: RainOneProduct[]): RainOneProduct[] {
    const fourGProducts = catelogue?.filter(product => {
      return product?.category === '4G' && (product?.config?.legacy || product.config.paymentType === 'postpaid');
    })
    return fourGProducts
  }
  @Selector([ProductState.Get4GCatelogueProducts])
  static Get4GCatalogueProductIDs(state: ProductStateModel, fourGProducts: RainOneProduct[]): string[] {
    return fourGProducts?.map((product: RainOneProduct) => {
      return product?.id
    })
  }

  @Selector([ProductState.GetCatelogueProducts])
  static GetR1UOPCatelogueProducts(state: ProductStateModel, catelogue: RainOneProduct[]): RainOneProduct[] {
    const fiveGProducts = catelogue?.filter(product => {
      return product?.category === '4G' && !product?.config?.legacy && product?.config?.['spend-limit']
    })
    return fiveGProducts
  }


  @Selector([ProductState.GetCatelogueProducts])
  static GetRainMobileSIMProducts(state: ProductStateModel, catelogue: RainOneProduct[]): RainOneProduct[] {
    const rainMobileSIMProducts = catelogue?.filter(product => {
      
      return product?.category === '4G' && !product?.config?.legacy && product?.config?.displayName?.toLowerCase()?.includes(RAIN_MOBILE_DISPLAY_NAME) && product?.type === 'sim' });
      
    return rainMobileSIMProducts;
  }

  @Selector([ProductState.GetCatelogueProducts])
  static GetRainMobileBundleProducts(state: ProductStateModel, catelogue: RainOneProduct[]): RainOneProduct[] {
    const rainMobileProducts = catelogue?.filter(product => !product?.config?.legacy && product?.config?.displayName?.toLowerCase()?.includes(RAIN_MOBILE_DISPLAY_NAME)  && product?.type === 'bundle' );
    return rainMobileProducts
  }

  @Selector([ProductState.GetCatelogueProducts])
  static GetSpeedUpCatelogueProducts(state: ProductStateModel, catelogue: RainOneProduct[]): RainOneProduct[] {
    const speedUpProducts = catelogue?.filter(product => {
      return product?.type === 'addon' &&  product?.name?.toLocaleLowerCase().includes('mbps')
    })
    return speedUpProducts
  }
  @Selector([ProductState.GetSpeedUpCatelogueProducts])
  static GetSpeedUpCatelogueProductIDs(state: ProductStateModel, speedUpProducts: RainOneProduct[]): string[] {
    const productIds = speedUpProducts.map((product) => {
      return product?.id
    })
    return productIds
  }


  @Selector()
  static IsRainOne(id: string) {
    return createSelector([ProductState], (state: any): boolean => {
      if (state?.product?.catelogue) {
        const bundles: RainOneProduct[] = state?.product?.catelogue['bundle'] as any;
        const rainOneBundles = bundles?.filter(bundle => bundle?.name?.toLowerCase()?.includes("rainone"));
        const isRainOne = rainOneBundles?.filter(b => b.id === id);
        return !!isRainOne.length;
      }

    });
  }
  @Selector()
  static GetRainBundleByName(name: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const bundles: RainOneProduct[] = state.product.catelogue['bundle'] as any;
        const selectedLevel = bundles.find(b => b.name === name);
        return selectedLevel;
      }
    });
  }

  @Selector()
  static GetRainBundleByNameAndType(name: string, type: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const bundles: RainOneProduct[] = state.product.catelogue['bundle'] as any;
        const selectedLevel = bundles.find(b => b.name === name && b.config.paymentType === type);
        return selectedLevel;
      }
    });
  }

  @Selector()
  static GetNvidiaProductsByNameAndType(name: string, type: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const products: RainOneProduct[] = state.product.catelogue['nvidia'];
        const selectedProduct = products.find(b => b.name === name && b.config.paymentType === type);
        return selectedProduct;
      }
    });
  }

  @Selector()
  static GetNvidiaProductsByTierAndType(tier: string, type: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const products: RainOneProduct[] = state.product.catelogue['nvidia'];
        const selectedProduct = products.find(b => b.config.tier === tier && b.config.paymentType === type && !b.name.toLocaleLowerCase().includes('public'));
        return selectedProduct;
      }
    });
  }

  @Selector()
  static GetAllRainNvidiaProductsByType(type: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const products: RainOneProduct[] = state.product.catelogue['nvidia'];
        const filteredProducts = products.filter(b => b.config.paymentType === type && !b.name.toLocaleLowerCase().includes('free') && b.config.rainCustomer);

        return Utils.Helpers.sortAscendingBy(filteredProducts, 'price');
      }
    });
  }


  @Selector()
  static GetAllPublicNvidiaProductsByType(type: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const products: RainOneProduct[] = state.product.catelogue['nvidia'];
        const filteredProducts = products.filter(b => b.config.paymentType === type && !b.name.toLocaleLowerCase().includes('Entry') && !b.name.toLocaleLowerCase().includes('NVIDIA Free') && !b.config.rainCustomer);
        return Utils.Helpers.sortAscendingBy(filteredProducts, 'price');
      }
    });
  }

  @Selector()
  static GetPhoneLine(name: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const sims: RainOneProduct[] = state.product.catelogue['sim'] as any;
        // const selectedLevelSim = sims.find(b => b.name === `${name} 4G Mobile`);
        const selectedLevelSim = sims.find(b => b.name === `4G prepaid mobile phone SIM`);
        return selectedLevelSim;
      }
    });
  }
  @Selector()
  static GetAddonById(id: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const addons: RainOneProduct[] = [...state.product.catelogue['addon'] as any, ...state.product.catelogue['bundle'] as any, ...state.product.catelogue['nvidia']];
        const selectedAddon = addons.find(b => b.id === id);
        return selectedAddon;
      }
    });
  }

  @Selector()
  static GetRainOneProductById(id: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const products: RainOneProduct[] = Utils.Mappers.FlattenHashMapArrays<RainOneProduct>(state.product.catelogue);
        const selectedAddon = products.find(b => b.id === id);
        return selectedAddon;
      }
    });
  }

  @Selector()
  static GetNvidiaProductById(id: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const sims: RainOneProduct[] = state.product.catelogue['nvidia'] as any;
        const selectedAddon = sims.find(b => b.id === id);
        return selectedAddon;
      }
    });
  }

  @Selector()
  static GetPPRainOneBundle(name: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const addons: RainOneProduct[] = state.product.catelogue['bundle'] as any;
        const bundle = addons.find(b => b.name === name && b.config.paymentType === 'postpaid');
        return bundle;
      }
    });
  }
  @Selector()
  static GetAURainOneBundle(name: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const addons: RainOneProduct[] = state.product.catelogue['bundle'] as any;
        const bundle = addons.find(b => b.name === name && b.config.paymentType === 'upfront');
        return bundle;
      }
    });
  }

  @Selector()
  static GetPPRainOneWifiProduct(name: string) {
    return createSelector([ProductState], (state: any) => {
      if (state.product.catelogue) {
        const addons: RainOneProduct[] = state.product.catelogue['addon'] as any;
        const bundle = addons.find(b => b.name === name && b.config.paymentType === 'postpaid');
        return bundle;
      }
    });
  }

  @Selector()
  static GetAllSpeedUpIds(state: ProductStateModel) {
    const addons: RainOneProduct[] = state.catelogue.bundle['addon'];

    return addons.map((a) => a.id);
  }

  @Selector()
  static GetAllBundles(state: ProductStateModel) {
    return state.catelogue.bundle
  }

  @Selector()
  static CatelogueIsLoaded(state: ProductStateModel): Boolean {
    return state.catelogue_isLoaded;
  }

  @Selector()
  static CatelogueIsLoading(state: ProductStateModel): Boolean {
    return state.catelogue_isLoading;
  }

  @Selector()
  static GetNvidiaProducts(state: ProductStateModel) {
    return state.catelogue.nvidia
  }

  @Selector()
  static GetAllNvidiaBundleIds(state: ProductStateModel) {
    const nBundles: RainOneProduct[] = state.catelogue.nvidia;

    return nBundles.map((a) => a.id);
  }

  constructor(private productService: ProductService, private store: Store, private _prodCatelogueSVC: ProductCatelogueService) { }

  ngxsOnInit(ctx?: StateContext<any>) { }

  @Action(GetActiveProducts)
  getAllProducts(state: StateContext<ProductStateModel>, action: GetActiveProducts) {
    return this.productService.getActive().pipe(
      tap((products: Result<IProductDetail[]>) => {
        state.patchState({
          activeProducts: products.value,
          payAsYouGo: { ...state.getState().payAsYouGo, t20: 'ba41623e-c7e1-4186-9e4b-685de888685f' } //TODO: FIX
        });
        state.dispatch([
          new SetPostPaidFiveGProducts(products.value),
          new SetPostPaidFourGProducts(products.value),
          new SetUpfrontFiveGProducts(products.value),
          new SetUpfrontFourGProducts(products.value),
        ]);
      })
    );
  }

  @Action(GetAllProducts)
  getAddOns(ctx: StateContext<ProductStateModel>, action: GetAllProducts) {
    const state = ctx.getState();
    return this.productService.get(true).pipe(
      tap((products: Result<IProductDetail[]>) => {
        ctx.patchState({
          allProducts: products.value,
          allProductsMap: ToHashMap(products.value, state, 'id')
        });
        ctx.dispatch([
          new GetActiveProducts(),
          new SetSimDeliveryOptions(fixedProducts),
          new SetPostPaidICCIDProducts(products.value),
          new SetUpfrontICCIDProducts(products.value)
        ]);
      })
    );
  }

  @Action(SetBlackFridayProducts)
  setBfProducts(state: StateContext<ProductStateModel>, action: SetBlackFridayProducts) {
    return state.patchState({
      blackFridayProducts: bfProductsmap
    });
  }

  @Action(SetPostPaidFiveGProducts)
  setPostPaidFiveGProducts(state: StateContext<ProductStateModel>, action: SetPostPaidFiveGProducts) {
    const blackFriday = this.store.selectSnapshot(FirebaseConfigsState.getBlackFridayActive);
    const postPaidFiveG = action.activeProducts?.filter(product => {

      // Unlimited home 5G standard
      const fiveGBundleIds = blackFriday
        ? ['5g_postpaid_premium_black_friday', 'b236d2f9-f4cb-4efc-847b-b780f9dcf2f3', '5g_postpaid_standard_black_friday', '2']
        : ['11', '2', '9', '24'];
        return fiveGBundleIds.includes(product.id) || Object.prototype.hasOwnProperty.call(product, "grandfather");
    });
    state.patchState({
      postPaidFiveGProducts: postPaidFiveG
    });
    state.dispatch(new PrepareProductsForDisplay(postPaidFiveG, 'postPaid5G', '5G'));
  }

  @Action(SetPostPaidICCIDProducts)
  setPostPaidICCIDProducts(state: StateContext<ProductStateModel>, action: SetPostPaidICCIDProducts) {
    const payAsYouGoIds = Object.values(state.getState().payAsYouGo);
    const postPaidICCID = action.allProducts?.filter(
      product => {
        if(product && product.config) {
          if(product.config.hasOwnProperty('iccid') &&
          !product.config.hasOwnProperty('staff') &&
          !payAsYouGoIds.includes(product.id) &&
          !(product.paymentType === ProductPaymentTypes.UPFRONT)) return product;
        }
      }

    );
    state.patchState({
      postPaidICCID: postPaidICCID
    });
    state.dispatch(new PrepareProductsForDisplay(postPaidICCID, 'postPaidICCID', '4G'));
  }

  @Action(SetUpfrontICCIDProducts)
  setUpfrontICCIDProducts(state: StateContext<ProductStateModel>, action: SetUpfrontICCIDProducts) {
    const payAsYouGoIds = Object.values(state.getState().payAsYouGo);
    const upfrontICCID = action.allProducts?.filter(
      product => {
        if(product && product.config) {
          if(product.config.hasOwnProperty('iccid') &&
          !product.config.hasOwnProperty('staff') &&
          !payAsYouGoIds.includes(product.id) &&
          product.paymentType === ProductPaymentTypes.UPFRONT &&
          product.type === ProductTypes.Bundle) return product;
        }
      }

    );
    state.patchState({
      upfrontICCID: upfrontICCID
    });
    state.dispatch(new PrepareProductsForDisplay(upfrontICCID, 'upfrontICCID', '4G'));
  }

  @Action(SetPostPaidFourGProducts)
  setPostPaidFourGProducts(state: StateContext<ProductStateModel>, action: SetPostPaidFourGProducts) {
    const payAsYouGoIds = Object.values(state.getState().payAsYouGo);
    let postPaid4G = action.activeProducts?.filter(
      product =>
        product?.name &&
        (product?.name.includes('4G') || product?.name.includes('19 hours')) &&
        !(product.paymentType === ProductPaymentTypes.UPFRONT) &&
        !payAsYouGoIds.includes(product.id)
    );

    postPaid4G = postPaid4G.filter((p) => !p.id.includes('phones_4g_upfront'));
    state.patchState({
      postPaidFourGProducts: postPaid4G
    });
    state.dispatch(new PrepareProductsForDisplay(postPaid4G, 'postPaid4G', '4G'));
  }

  @Action(SetUpfrontFourGProducts)
  setUpfrontFourGProducts(state: StateContext<ProductStateModel>, action: SetUpfrontFourGProducts) {
    const payAsYouGoIds = Object.values(state.getState().payAsYouGo);
    let fourgProducts = action.allProducts?.filter(
      product =>
        product?.name &&
        (product?.name.includes('4G') || product?.name.includes('19 hours')) &&
        product.paymentType === ProductPaymentTypes.UPFRONT &&
        !payAsYouGoIds.includes(product.id)
    );

    fourgProducts = fourgProducts.filter((p) => !p.id.includes('phones_4g_upfront'));
    state.patchState({
      upfrontFourGProducts: fourgProducts
    });
    state.dispatch(new PrepareProductsForDisplay(fourgProducts, 'fourGUpfront', '4G'));
  }

  @Action(SetStandAlone4GAUProducts)
  setStandAlone4GAUProducts(state: StateContext<ProductStateModel>, action: SetStandAlone4GAUProducts) {
    const product4GStandalone = action.allProducts?.filter(product => {
        return product?.id === FOURG_STANDALONE;
    });
    // state.patchState({
    //   standAlone4GAUProducts: product4GStandalone
    // });
  }

  @Action(SetUpfrontFiveGProducts)
  setUpfrontFiveGProducts(state: StateContext<ProductStateModel>, action: SetUpfrontFiveGProducts) {
    const blackFriday = this.store.selectSnapshot(FirebaseConfigsState.getBlackFridayActive);
    const fiveGProducts = action.activeProducts?.filter(product => {
      const fiveGBundleIds = blackFriday ? ['5g_postpaid_premium_black_friday', '5g_postpaid_standard_black_friday', '2'] : ['11', '2', '9'];
      return fiveGBundleIds.includes(product.grandfather) && product.paymentType === ProductPaymentTypes.UPFRONT;
    });
    state.patchState({
      upfrontFiveGProducts: fiveGProducts
    });
    state.dispatch(new PrepareProductsForDisplay(fiveGProducts, 'fiveGUpfront', '5G'));
  }

  @Action(SetSimDeliveryOptions)
  setSimDeliveryOptions(state: StateContext<ProductStateModel>, action: SetSimDeliveryOptions) {
    const options = action.allProducts?.filter(
      product =>
        product?.id &&
        (product?.id?.includes('MrDFood') || product?.id.includes('takealot') || product?.id.includes('pargo') || product?.id.includes('hi-online'))
    );
    state.patchState({
      simDeliveryoptions: action.allProducts,
      productsToDisplay: { ...state.getState().productsToDisplay, findSim: options }
    });
  }

  @Action(PrepareProductsForDisplay)
  prepareProductsForDisplay(state: StateContext<ProductStateModel>, action: PrepareProductsForDisplay) {
    const productsToDisplay = state.getState().productsToDisplay;
    const viewPort = this.store.selectSnapshot(CoreState.activeBreakpoint);
    let forDisplay = this.mapViewModel(action.products, state.getState().allProducts);
    forDisplay =
      action.category === '4G' && viewPort === 'mobile' ? sortAscendingBy(forDisplay, 'mobilePosition') : sortAscendingBy(forDisplay, 'position');
    return state.patchState({
      productsToDisplay: { ...productsToDisplay, [action.dictionaryKey]: forDisplay }
    });
  }

  mapViewModel(productsToMap: IProductDetail[], allProducts: IProductDetail[]): ProductViewModel[] {
    return productsToMap?.map(product => {
      let formattedBillingType = product.formattedBillingType;
      const position = product?.config?.position?.value || 0;
      const dataText = product.name.replace(/[' ']|[\n]/g, '_');
      const mobilePosition = product?.config?.mobilePosition?.value || 0;
      const description = product.description;
      const requiredAddons = product.addons.filter(x => !x.optional);
      let totalPrice = 0;
      if (requiredAddons.length) {
        totalPrice = requiredAddons.reduce((totalPrice, currAddOn) => {
          const addOnProduct = allProducts.find(x => x.id == currAddOn.id);
          return totalPrice + addOnProduct.price;
        }, 0);
      } else {
        totalPrice = product.price;
      }

      let lineItem = {} as any;
      if (product.type === ProductTypes.Bundle) {
        lineItem = product.items.find(x => x.category === ProductCategories.FiveG || x.category === ProductCategories.FourG);
      }

      return {
        formattedBillingType,
        price: totalPrice,
        image: product.image,
        category: lineItem.category,
        id: product.id || '',
        productLineItemId: lineItem.id,
        name: product.name,
        GMAnalyticsName: product.name.replace('\n', ''),
        description,
        position,
        mobilePosition,
        selected: false,
        type: 'normal',
        tag: product.tag,
        isProduct: true,
        isBlackFriday: product?.config?.blackFriday?.value || false,
        isPromo: product?.config?.winterPromo?.value || false,
        isTwoInOnePromo: product?.config?.twoInOnePromo?.value || false,
        silentSalesmanDescription: product.silentSalesmanDescription || '',
        gmDataText: dataText.replace(/__/g, '_'),
        cornerImage: product.cornerImage || null,
        paymentType: product.paymentType,
        grandfather: product?.grandfather,
        grandchild: product?.grandchild
      };
    });
  }

  @Action(FetchProductsFromCatelogue)
  FetchProductCatogue(ctx: StateContext<ProductStateModel>, action: FetchProductsFromCatelogue) {
    const state = ctx.getState();
    ctx.patchState({
      catelogue_isLoading: true,
      catelogue_isLoaded: false
    });

    setTimeout(() => {
      const isSit = environment?.config == 'sit';
      return this._prodCatelogueSVC.fetchRainOneProducts().subscribe({
        next: (res: { result: Array<RainOneProduct> }) => {
          const backupData = isSit ? sitData : data;
          if (res && res.result) {
            
            ctx.dispatch(new FetchProductsFromCatelogueSuccess(res.result as any));
          } else {
            ctx.dispatch(new FetchProductsFromCatelogueSuccess(backupData.result as any));
          }

        },
        error: err => {

          const backupData = isSit ? sitData : data;
          ctx.dispatch(new FetchProductsFromCatelogueSuccess(backupData.result as any));
        }
      });
    }, 500);
  }

  @Action(FetchProductsFromCatelogueSuccess)
  FetchProductsFromCatelogueSuccess(ctx: StateContext<ProductStateModel>, action: FetchProductsFromCatelogueSuccess) {
    localStorage.removeItem('product');
    const state = ctx.getState();
    const payload = action.payload;
    const catelogue = Utils.Mappers.ToSortedHashMap<RainOneProduct[]>(payload, state.catelogue, 'type');
    const nvidiaProducts = catelogue.addon.filter((a) => a.config.subtype);
    catelogue["nvidia"] = nvidiaProducts;

    ctx.patchState({
      catelogue: catelogue,
      catelogue_isLoading: false,
      catelogue_isLoaded: true,
      standAlone4GAU: action.payload.find((p: RainOneProduct) => p.id === FOURG_STANDALONE)
    });
  }

  @Action(FetchProductsFromCatelogueFail)
  FetchProductsFromCatelogueFail(ctx: StateContext<ProductStateModel>, action: FetchProductsFromCatelogueFail) {

    ctx.patchState({
      catelogue_isLoading: false,
      catelogue_isLoaded: false
    });
  }
}
