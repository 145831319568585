<div class="payment-error-block-container" [ngClass]="{'manage': manage}"> <!-- add input for border -->
	<p>You had the following <strong>payment error:</strong></p>
	<div class="payment-error" *ngIf="error">
		<div class="status">
			<span class="bullet"></span>
			<span class="text" *ngIf="error?.status">{{error?.status}}</span>
		</div>
		<div class="suggestion" *ngIf="error?.suggestion as suggestion">
			<div class="header">
				{{suggestion?.header}}
			</div>
			<div class="bullet-points">
				<p *ngFor="let bulletPoint of suggestion?.bulletPoints">
					- {{ bulletPoint }}
				</p>
				<p>
					{{suggestion?.summaryMessage}}
				</p>
			</div>
		</div>
	</div> 
</div>