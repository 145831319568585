<div class="modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" />
  </div>
</div>
<div class="modal-body p-0  z-index-1">
  <div class="coverage-check ml-sm-20 ml-5 top-0 z-index-1">
    <div class=" mb-5">
      <h2 class=" h2 text-primary font-weight-bold mr-10 mt-10 mt-sm-25 modal-heading">
        let's check if you're in 5G coverage
      </h2>
    </div>
    <div class="mr-10 col-lg-6 col-md-12 mb-10 mb-sm-0">
      <input
        id="search"
        #search
        class="address-search-input py-2 icon-img medium-font-size"
        placeholder="physical address"
        ngx-google-places-autocomplete
        [options]="config"
        (onAddressChange)="handleMapSearch($event)"
        autocomplete="off"
      />
    </div>
    <span *ngIf="submitted && !addressVerified" class="text ">
      <small class="text-danger mt-2 mb-5 d-block">Invalid address</small>
    </span>
    <div class="d-xl-flex flex-xl-row flex-column align-items-center justify-content-between ml-5 ml-sm-0  mr-10  map-checkbox ">
      <div class="my-2 ">
        <app-checkbox name="Please confirm that this is the address where 5G will be used"></app-checkbox>
        <span *ngIf="submitted && !agreedValue" class="text">
          <small class="text-danger mt-2 mb-5 d-block">Please tick box confirming address is for 5G</small>
        </span>
      </div>
      <div class="banner-button mb-xl-0 mb-10 d-flex justify-content-center justify-content-sm-end top-0 ">
        <button
          [attr.data-text]="gmData"
          data-id="btn-click"
          class="btn btn-md btn-primary text-white"
          id="check-coverage"
          role="button"
          (click)="checkCoverage()"
          (keyup.enter)="checkCoverage()"
        >
          check <span class="text-uppercase">5G</span> coverage
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isMobile">
  <app-map [latitude]="latitude" [longitude]="longitude"></app-map>
  <p class="mt-5 px-10 small-font-size copy-right text-black l-h-0">
    <strong>Disclaimer:</strong> The information contained in this coverage map is for general information purposes only and does not guarantee actual
    coverage. While we endeavour to keep the information accurate and up to date, some factors influencing coverage varies over time such as terrain.
    Other factors include, but are not limited to a person’s residence in relation to the cellular enabled mobile device site and poor radio
    conditions within a person’s residential area as a result of environmental factors beyond rain’s control. rain makes no representations or
    warranties of any kind, whether expressed or implied, relating to the completeness, accuracy, reliability, suitability or availability with
    respect to the coverage map. Your indoor coverage may be affected by factors that may not be in our control and impact accuracy of coverage
    approximation.
  </p>
</div>
