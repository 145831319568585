<ng-container *ngIf="type === '5G' && !address; else survey">
  <app-collection-address  (submitted)="proceed($event);"></app-collection-address>
</ng-container>
<ng-template #survey>
  <div class="mb-10 modal-body border-0">
    <div class="row">
      <div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
          data-dismiss="modal" data-target="#surveyModal" src="../../assets/svg/close.svg" />
      </div>
      <div class="p-5 border-0 modal-body">
        <div class="text-center my-25" *ngIf="loading">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="row m-0 mb-5" *ngIf="!loading">
          <div class="col-md-12 mt-10">
  
            <div *ngIf="type; else SIMSwapTitle">
              <p class="text-primary text-center h2 mb-0 font-weight-bold modal-heading" [ngClass]="{'mobile-btn-size': (activeBreakpoint$ | async) === 'mobile'}">we're sorry to see you go</p>
            </div>
            <ng-template #SIMSwapTitle>
              <p class="text-primary text-center h2 mb-0 font-weight-bold modal-heading">before we continue</p>
            </ng-template>
  
  
  
  
            <p class="text-center" *ngIf="type; else SIMSwap">
              Please let us know why you're cancelling so we can improve.<br> It won't take long we promise:
            </p>
  
            <ng-template #SIMSwap>
              <p class="text-center">
                Please let us know why you’re requesting a SIM swap:
              </p>
            </ng-template>
  
            <div>
              <div class="row">
                <div class="col-md-6" [ngClass]="{'offset-md-4 offset-2': (activeBreakpoint$ | async) !== 'mobile', 'ml-10': (activeBreakpoint$ | async) === 'mobile'}">
                  <form [formGroup]="form" >
                    <div>
                      <div *ngFor="let question of surveyQuestions; let i = index">
                        <div class="radio-item"
                          [ngClass]="{'sim-swap': !type && surveyQuestions.length === 1}">
                          <input type="radio" [id]="'question-' + i" (click)="display(question)" name="question"
                            formControlName="question" [value]="question" />
                          <label [for]="'question-' + i">{{ question.title }}</label><br />
                        </div>
  
                        <div [ngClass]="{'sim-swap-answers-descktop': !type && surveyQuestions.length === 1}">
                          <div *ngFor="let answer of question.answers"
                            [className]="(selectedBlock == question.title ? 'd-block' : 'd-none') + ' radio-item answer-block ml-15'"
                            [id]="question.id">
                            <div *ngIf="answer.title">
                              <input type="radio" [checked]="answer.selected ? 'checked' : ''"
                                [id]="'answer-' + answer.id" name="answer" formControlName="answer" [value]="answer.id"
                                (click)="selectAnswer(question.id, answer.id)" />
                              <label [for]="'answer-' + answer.id" class="answer">{{ answer.title }}</label><br />
                            </div>
                          </div>
                        </div>
  
                        <div [id]="question.id"
                          [ngClass]="{'sim-swap-more-details': !type && surveyQuestions.length === 1}"
                          [className]="(selectedBlock == question.title ? 'd-block' : 'd-none') + ' answer-block mt-5'">
                          <div class="form-group mt-10" [ngClass]="{'ml-5 ml-sm-0': !type && surveyQuestions.length === 1}">
                            <label [for]="'label-' + i" class="label"
                              *ngIf="form.get('info').dirty && form.get('info').value">You can tell us
                              more here</label>
                            <input class="input py-2 medium-font-size" placeholder="You can tell us more here"
                              [name]="'question-' + question.id" formControlName="info" />
  
                          </div>
                        </div>
                      </div>
                    </div>
  
                  </form>
                  <p class="text-danger mt-10" *ngIf="formSubmited && form.invalid">Please give your feedback</p>
                </div>
              </div>
  
              <div class="d-flex mt-10 justify-content-center"
                *ngIf="!(!type && surveyQuestions.length === 1)">
  
                <button class="btn btn-primary btn-md" [ngClass]="{'disabled': form.invalid}" (click)="submit()"
                  [disabled]="loadingRequest || form.invalid">
                  submit
                  <span *ngIf="loadingRequest" class="spinner-grow spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
                </button>
  
              </div>
  
            </div>
            <div class="d-flex justify-content-center mt-10" *ngIf="!type && surveyQuestions.length === 1">
              <button class="btn btn-primary btn-md" [ngClass]="{'disabled': form.invalid}" (click)="submit()"
                [disabled]="loadingRequest || form.invalid">
                submit
                <span *ngIf="loadingRequest" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>