<div class="mb-10 modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('cancel')" data-dismiss="modal"
      data-target="#thankYouModal" src="../../assets/svg/close.svg" />
  </div>
</div>
<div class="p-5 modal-body border-0">
  <div class="row m-0 mb-10">
    <div class="col-12">
      <p class="text-primary text-center h2 mb-0 pb-5 font-weight-bold modal-heading">
        thanks for your feedback</p>

      <div class="pl-0 pl-sm-10 pr-0 pr-sm-10" *ngIf="type === '4G'; else fivegContent">
        <p class="text-center">
          Your cancellation for <span class="text-primary">
            '{{ simName }}'
          </span> will take effect at the end of the month.
        </p>

        <p class="text-center pl-0 pl-sm-10 pr-0 pr-sm-10">
          But if you change your mind between now and then, you can reverse your cancellation
        </p>
      </div>

      <ng-template #fivegContent>
        <p class="text-center">
          Your SIM cancellation will only happen at the end of the month. We’ve also sent you a mailer with next steps
          for collecting the router. But If you change your mind between now and then, you can reverse your
          cancellation.
        </p>
      </ng-template>

      <button class="btn btn-md btn-primary mt-10 d-block mx-auto" data-dismiss="modal" aria-label="Close"
        data-target="#thankYouModal" (click)="activeModal.dismiss('cancel')">
        got it
      </button>
    </div>
  </div>
</div>