import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ModalData {
  title: string;
  description: string;
  description2?: string;

  buttonName: string;
  cancelButtonName?: string;
}
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})

export class ConfirmationModalComponent implements OnInit, OnDestroy {
  @Input() data: ModalData;
  @Input() gmData: string;
  @Output() accepted: EventEmitter<any> = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.data = null;
  }

  confirm() {
    this.accepted.emit(true);
    this.close();
    //for testing will re add
    //window.location.reload();
  }

  cancel() {
    this.accepted.emit(false);
    this.close();
  }

  close() {
    this.activeModal.close();
  }

}
