import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { GetBillCycleOptions } from '../store/actions/billing.actions';
import { BillingState } from '../store/state/billing.state';


@Injectable({
	providedIn: 'root'
})
export class LoadBillCycleDatesResolver implements Resolve<boolean> {
	constructor(private store: Store, private route: ActivatedRoute) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
			return this.checkStore(route.params['query'])
	}

	private checkStore(query: string): Observable<boolean> {
			const hasLoaded = this.store.selectSnapshot(BillingState.isLoaded);
			if (hasLoaded) return of(true);
			this.store.dispatch(new GetBillCycleOptions);

            setTimeout(() => {
                return of(true);
            }, 1000);
	}
}
