import { Injectable } from '@angular/core';
import { CACHE_PROFILE } from '@models/constants';
import { BillCycleDetail, IUserDetail } from '@models/userDetail';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { CacheService } from '@services/cache.service';
import { UserService } from '@services/user.service';
import moment from 'moment';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { SetAllowedProductLimit,SwapRainOneAuForPP } from 'src/app/store/actions/cart.action';
import { Device } from '../../interfaces/device.interface';
import { AdBlockerService } from '../../services/ad-blocker/ad-blocker.service';
import { ConnectionService } from '../../services/connection.service';
import { DeviceChecker } from '../../services/device-checker/device-checker';
import { BarcodeRefSMSPayload } from '../../services/sms/barcode-sms.interface';
import { SmsService } from '../../services/sms/sms.service';
import * as fromAdBlockerActions from '../actions/ad-blocker.actions';
import * as fromUserActions from '../actions/auth.actions';
import {
  GetBillCycleOptions,
  GetUserBillingAccountStatus,
  IndicateSavedDate,
  SetSavedPaymentDate,
  SetSelectedBillCycle
} from '../actions/billing.actions';
import * as fromDeviceCheckerActions from '../actions/device-checker.actions';
import { AssignDeviceLayout } from '../actions/device-checker.actions';
import { AssignSlug } from '../actions/routing.actions';
import * as fromServiceConnectionActions from '../actions/service-connection.actions';
import * as fromSMSActions from '../actions/sms.actions';
import * as fromCoverageCheck from '../actions/coverage.actions';
import { UserAddressService } from '../../services/user-address.service';
import { UserAddressPayload } from '../../interfaces/user-address.interface';
import { LatLng } from '@agm/core';
import { NvidiaService } from '@services/nvidia.service';
import * as fromPromoActions from '../actions/promo.actions';
import { Navigate } from '@ngxs/router-plugin';

import { UserUpdateRequest } from '@models/userUpdateRequest';
import { SSOPayload } from '../../interfaces/sso.interface';
import { BreakPoint } from '../../services/layout/breakpoints.service';
import { CookieService } from 'ngx-cookie-service';

export interface    CoreStateModel {
  loading: boolean;
  loaded: boolean;
  authenticated: boolean;
  users_device: Device;
  layout: BreakPoint;
  profile: IUserDetail;
  idm_token: string;
  tokenSme: string;
  auth_token: string;
  tokens_loaded: boolean;
  user_role:string;
  routing: {
    slug: string;
  };
  connection: any;
  product_type: { [name: string]: string };
  coverage_check_address: GoogleAddress;
  saved_coverage_check_address: boolean;
  nvidia_whitelisted_config: {
    checked: boolean,
    is_whitelisted: boolean,
    ready_dispatch: boolean,
    user_email: string
  },
  page_loader: boolean;
}

@State<CoreStateModel>({
  name: 'core',
  defaults: {
    loading: false,
    loaded: false,
    authenticated: false,
    users_device: null,
    layout: null,
    profile: null,
    idm_token: null,
    tokenSme: null,
    auth_token: null,
    tokens_loaded: false,
    user_role:null,
    routing: {
      slug: ''
    },
    connection: null,
    product_type: null,
    coverage_check_address: null,
    saved_coverage_check_address: false,
    nvidia_whitelisted_config: {
      checked: false,
      is_whitelisted: false,
      ready_dispatch: false,
      user_email: null
    },
    page_loader: false,
  }
})
@Injectable()
export class CoreState implements NgxsOnInit {
  constructor(
    private store: Store,
    // private adBlockerSVC: AdBlockerService,
    private deviceChecker: DeviceChecker,
    private _smsSvc: SmsService,
    private _pSVC: UserService,
    private cacheService: CacheService,
    private _connectionSVC: ConnectionService,
    private _uAddressSVC: UserAddressService,
    private _nSvc: NvidiaService,
    private cookieService: CookieService

  ) { }

  ngxsOnInit() {
    const userDetails: any = this.cacheService.getObject(CACHE_PROFILE);
    if (userDetails) {
      this.store.dispatch(new fromUserActions.GetUserProfileSuccess(userDetails));
    }
  }

  @Selector()
  static isLoading(state: CoreStateModel) {
    return state.loading;
  }

  @Selector()
  static isLoaded(state: CoreStateModel) {
    return state.loaded;
  }

  @Selector()
  static isAuthenticated(state: CoreStateModel) {
    return state.authenticated;
  }

  @Selector()
  static isCurrentCustomer(state: CoreStateModel): Boolean {
    if (state.profile) {
      const criteria = 7;
      const today = moment(new Date());
      const createdDate = moment(state.profile.user_created_dated);

      return Boolean(today.diff(createdDate, 'days') >= criteria);
    }
    return state.authenticated;
  }

  @Selector()
  static hasBillCycle(state: CoreStateModel): boolean {
    return Boolean(state.profile?.bill_cycle_spec_detail);
  }

  @Selector()
  static isPostPaid(state: CoreStateModel): boolean {
    return Boolean(!state.profile.advance_billing);
  }

  @Selector()
  static getUserBillCycle(state: CoreStateModel): BillCycleDetail {
    return state.profile.bill_cycle_spec_detail;
  }

  @Selector()
  static getUserDevice(state: CoreStateModel) {
    return state.users_device;
  }

  @Selector()
  static getUserProfile(state: CoreStateModel): IUserDetail {
    return state.profile;
  }

  @Selector()
  static activeBreakpoint(state: CoreStateModel) {
    return state.layout;
  }

  @Selector()
  static getRainDeliveryTime(state: CoreStateModel) {
    return state.layout;
  }

  @Selector()
  static getTokens(state: CoreStateModel) {
    return { idm: state.idm_token, auth: state.auth_token, tokenSme: state.tokenSme };
  }

  @Selector()
  static hasTokensLoaded(state: CoreStateModel) {
    return state.tokens_loaded;
  }

  @Selector()
  static getRouteSlug(state: CoreStateModel) {
    return state.routing.slug;
  }

  @Selector()
  static getConnectionInfo(state: CoreStateModel) {
    return state.connection;
  }

  @Selector()
  static getUserInfoPayload(state: CoreStateModel): UserUpdateRequest {
    const userDetails = state.profile;
    const userInfo = {
      id: userDetails.id,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      phone: userDetails.phone,
      address: userDetails.addresses[0],
      idNumber: userDetails.idNumber
    }
    return userInfo;
  }

  @Selector()
  static isFallbackActive(name: string) {
    return createSelector([CoreState], (state: any) => {
      if (state.core.product_type && state.core.connection) {
        if (!state.core.connection[name]?.tower_type) {
          return false;
        }
        return state.core.connection[name] && state.core.product_type[name] !== state.core.connection[name]?.tower_type;
      }
    });
  }

  @Selector()
  static isNvidiaWhitelisted(state: CoreStateModel) {
    return state.nvidia_whitelisted_config.is_whitelisted;
  }

  @Selector()
  static isNvidiaWhitelistConfig(state: CoreStateModel) {
    return state.nvidia_whitelisted_config;
  }

  @Selector()
  static isAwaitingToCheckWhitelist(state: CoreStateModel): boolean {
    return state.nvidia_whitelisted_config.ready_dispatch;
  }

  @Selector()
  static GetUserRole(state: CoreStateModel) {
    return state.user_role;
  }
  @Selector()
  static GetPageLoader(state: CoreStateModel) {
    return state.page_loader;
  }

  @Action(AssignDeviceLayout)
  assignDeviceLayout(ctx: StateContext<CoreStateModel>, action: AssignDeviceLayout) {
    ctx.patchState({
      layout: action.payload
    });
  }

  @Action(fromDeviceCheckerActions.InitDeviceChecker)
  initDeviceChecker() {
    const type = this.deviceChecker.check();

    return this.store.dispatch(new fromDeviceCheckerActions.InitDeviceCheckerSuccess(type));
  }

  @Action(fromDeviceCheckerActions.InitDeviceCheckerSuccess)
  initDeviceCheckerSuccess(ctx: StateContext<CoreStateModel>, action: fromDeviceCheckerActions.InitDeviceCheckerSuccess) {
    const payload = action.payload;

    ctx.patchState({
      users_device: payload
    });
  }

  @Action(fromAdBlockerActions.InitAdBlockerChecker)
  initAdBlockerChecker(ctx: StateContext<CoreStateModel>) {
    // this.adBlockerSVC
    //   .init()
    //   .then(res => ctx.dispatch(new fromAdBlockerActions.InitAdBlockerCheckerSuccess(res)))
    //   .catch(res => ctx.dispatch(new fromAdBlockerActions.InitAdBlockerCheckerSuccess(res)));
  }

  @Action(fromSMSActions.SendBarcodeRefSMS)
  SendBarcodeRefSMS(ctx: StateContext<CoreStateModel>, action: fromSMSActions.SendBarcodeRefSMS) {
    const profile = ctx.getState().profile;
    const payload: BarcodeRefSMSPayload = {
      ...action.payload,
      name: `${profile.firstName} ${profile.lastName}`
    };
    this._smsSvc.sendBarcodeRef(payload).subscribe({
      next: res => ctx.dispatch(new fromSMSActions.SendBarcodeRefSMSSuccess(res)),
      error: err => ctx.dispatch(new fromSMSActions.SendBarcodeRefSMSFail(err))
    });
  }

  @Action(fromUserActions.GetUserProfile)
  GetUserProfile(ctx: StateContext<CoreStateModel>) {
    ctx.dispatch(new GetBillCycleOptions());
    ctx.patchState({
      loaded: false,
      loading: true
    });

    this._pSVC.get().subscribe({
      next: res => ctx.dispatch([new GetUserBillingAccountStatus(res.value?.email), new fromUserActions.GetUserProfileSuccess(res.value)]),
      error: err => ctx.dispatch(new fromUserActions.GetUserProfileFail(err))
    });
  }

  @Action(fromUserActions.GetUserProfileSuccess)
  GetUserProfileSuccess(ctx: StateContext<CoreStateModel>, action: fromUserActions.GetUserProfileSuccess) {

    const payload = action.payload;
    this.cacheService.setObject(CACHE_PROFILE, payload);

      if (typeof window.insightarc !== 'undefined') {
          if (payload?.id) {
              this.cookieService.set('insightArcId', payload.id, 1);
              const  userId = this.cookieService.get('insightArcId');
              window.insightarc.setContext({ userId });
          }
      }

    const billCycle = payload?.bill_cycle_spec_detail;
    ctx.patchState({
      loaded: true,
      loading: false,
      profile: payload,
      authenticated: !!payload
    });

    if (billCycle && Object.entries(billCycle).length > 0) {
      const option = {
        value: billCycle.cycle_period.start,
        type: 'BillCycleDate' as any,
        id: billCycle.bill_cycle_spec
      };

      ctx.dispatch([
        new SetAllowedProductLimit({ allowed4G: payload?.available4G, allowed5G: payload?.available5G }),
        new SetSavedPaymentDate(option),
        new SetSelectedBillCycle(billCycle.bill_cycle_spec),
        new IndicateSavedDate(),
        new SwapRainOneAuForPP(),
        new fromPromoActions.CheckWhitelist({email: payload.email}),
      ]);
    }
  }

  @Action(fromUserActions.GetUserProfileFail)
  GetUserProfileFail(ctx: StateContext<CoreStateModel>, action: fromUserActions.GetUserProfileFail) {
    ctx.patchState({
      loaded: false,
      loading: false,
      profile: null
    });
  }

  @Action(fromUserActions.AssignTokens)
  AssignTokens(ctx: StateContext<CoreStateModel>, action: fromUserActions.AssignTokens) {
    const payload = action.payload;
    ctx.patchState({
      tokens_loaded: true,
      idm_token: payload.idm,
      auth_token: payload.auth
    });
  }

  @Action(AssignSlug)
  AssignSlug(state: StateContext<CoreStateModel>, action: AssignSlug) {
    const slug = action.payload;

    state.patchState({
      routing: {
        slug
      }
    });
  }

  @Action(fromServiceConnectionActions.CheckConnectionStatus)
  CheckConnectionStatus(ctx: StateContext<CoreStateModel>, action: fromServiceConnectionActions.CheckConnectionStatus) {
    const payload = action.payload;

    this._connectionSVC.checkStatus(payload.msisdn).subscribe({
      next: res => {
        if (res) ctx.dispatch(new fromServiceConnectionActions.CheckConnectionStatusSuccess({ name: payload.name, connect: res }));
      },
      error: err => ctx.dispatch(new fromServiceConnectionActions.CheckConnectionStatusFail(err))
    });
  }

  @Action(fromServiceConnectionActions.CheckConnectionStatusSuccess)
  CheckConnectionStatusSuccess(ctx: StateContext<CoreStateModel>, action: fromServiceConnectionActions.CheckConnectionStatusSuccess) {
    const payload = action.payload;
    const connects = ctx.getState().connection;
    const newConnections = Object.assign({ ...connects }, { [payload.name]: payload.connect })

    ctx.patchState({
      connection: Object.assign({}, newConnections)
    });
  }

  @Action(fromServiceConnectionActions.AssignProductType)
  AssignProductType(ctx: StateContext<CoreStateModel>, action: fromServiceConnectionActions.AssignProductType) {
    const payload = action.payload;
    const types = ctx.getState().product_type;
    const newTypes = Object.assign({ ...types }, { [payload.name]: payload.type });

    ctx.patchState({
      product_type: Object.assign({}, newTypes)
    });
  }

  @Action(fromCoverageCheck.SetUserCoverageAddress)
  SetUserCoverageAddress(ctx: StateContext<CoreStateModel>, action: fromCoverageCheck.SetUserCoverageAddress) {
    const payload = action.payload;

    ctx.patchState({
      coverage_check_address: payload
    });

    // ctx.dispatch(new fromCoverageCheck.PostUserCoverageAddress());
  }

  @Action(fromCoverageCheck.PostUserCoverageAddress)
  PostUserCoverageAddress(ctx: StateContext<CoreStateModel>, action: fromCoverageCheck.PostUserCoverageAddress) {
    const state = ctx.getState();
    const orderId = action.payload;

    if(!state.profile) {
      ctx.patchState({
        saved_coverage_check_address: false
      });

      return;
    }

    if(state.profile && state.coverage_check_address) {
      ctx.patchState({
        saved_coverage_check_address: true
      });

      const coords = this.getAddressLatLng(state);
      const payload: UserAddressPayload = {
        user_id: state.profile.id,
        street_name: this.getAddressComponent('route', state)?.long_name ?? '',
        street_number: this.getAddressComponent('street_number', state)?.long_name ?? '',
        suburb: this.getAddressComponent('sublocality', state)?.long_name ?? '',
        city: this.getAddressComponent('locality', state)?.long_name ?? '',
        province: this.getAddressComponent('administrative_area_level_1', state)?.long_name ?? '',
        extra: {
          latitude: coords?.lat?.toString(),
          longitude: coords?.lng?.toString(),
          orderId
        }
      };
      this._uAddressSVC.setAddress(payload)
        .subscribe({
          next: (res) => ctx.dispatch(new fromCoverageCheck.PostUserCoverageAddressSuccess(res)),
          error: (err) => ctx.dispatch(new fromCoverageCheck.PostUserCoverageAddressFail(err))
        });
    }

  }

  @Action(fromCoverageCheck.PostUserCoverageAddressSuccess)
  PostUserCoverageAddressSuccess(ctx: StateContext<CoreStateModel>, action: fromCoverageCheck.PostUserCoverageAddressSuccess) {
    ctx.patchState({
      coverage_check_address: null,
      saved_coverage_check_address: false
    });
  }

  @Action(fromCoverageCheck.PostUserCoverageAddressFail)
  PostUserCoverageAddressFail(ctx: StateContext<CoreStateModel>, action: fromCoverageCheck.PostUserCoverageAddressFail) {
    ctx.patchState({
      coverage_check_address: null
    });
  }

  private getAddressComponent(type: string, state: CoreStateModel) {
    return state.coverage_check_address.address_components.find((c) => c.types.some((t) => t === type));
  }

  private getAddressLatLng(state: CoreStateModel): LatLng {
    return state.coverage_check_address.geometry.location;
  }

  @Action(fromUserActions.ClearProfile)
  ClearProfile(ctx: StateContext<CoreStateModel>, action: fromUserActions.ClearProfile) {
    ctx.patchState({
      profile: null,
      loading: false,
      loaded: false,
      authenticated: false,
      idm_token: null,
      auth_token: null,
      tokens_loaded: false,
      nvidia_whitelisted_config: {
        checked: false,
        is_whitelisted: false,
        ready_dispatch: false,
        user_email: null
      },
      user_role: null
    });
  }

  @Action(fromPromoActions.CheckWhitelist)
  CheckIfNvidiaWhitelisted(ctx: StateContext<CoreStateModel>, action: fromPromoActions.CheckWhitelist) {
    const payload = action.payload;
    this._nSvc?.CheckWhitelist(payload)?.subscribe({
      next: res => {
        if (res) ctx.dispatch(new fromPromoActions.CheckWhitelistSuccess(res.result));
      },
      error: err => ctx.dispatch(new fromPromoActions.CheckWhitelistFail(err))
    });
  }

  @Action(fromPromoActions.CheckWhitelistSuccess)
  CheckIfNvidiaWhitelistedSuccess(ctx: StateContext<CoreStateModel>, action: fromPromoActions.CheckWhitelistSuccess) {
    const response = action.payload;
    const state = ctx.getState();
    
    if(state.nvidia_whitelisted_config?.checked) return;

    ctx.patchState({
      nvidia_whitelisted_config: {
        checked: true,
        is_whitelisted: response.is_whitelisted,
        ready_dispatch: false,
        user_email: null
      }
    });
  }

  @Action(fromPromoActions.AwaitToCheckWhitelistV2)
  AwaitToCheckWhitelistV2(ctx: StateContext<CoreStateModel>, action: fromPromoActions.AwaitToCheckWhitelistV2) {
    const payload = action.payload;
    const state = ctx.getState();

    
    // if(state.nvidia_whitelisted_config?.checked) return;
    ctx.patchState({
      nvidia_whitelisted_config: {
        checked: true,
        is_whitelisted: payload.whitelisted,
        ready_dispatch: true,
        user_email: payload.email
      }
    });
  }
  @Action(fromPromoActions.AwaitToCheckWhitelist)
  AwaitToCheckWhitelist(ctx: StateContext<CoreStateModel>, action: fromPromoActions.AwaitToCheckWhitelist) {
    const payload = action.payload;
    const state = ctx.getState();
    
    if(state.nvidia_whitelisted_config?.checked) return;

    ctx.patchState({
      nvidia_whitelisted_config: {
        checked: false,
        is_whitelisted: false,
        ready_dispatch: true,
        user_email: payload.email
      }
    });

    ctx.dispatch(new Navigate(['/nvidia']));
  }

  @Action(fromPromoActions.CheckWhitelistFail)
  CheckIfNvidiaWhitelistedFail(ctx: StateContext<CoreStateModel>, action: fromPromoActions.CheckWhitelistFail) {
    ctx.patchState({
      nvidia_whitelisted_config: {
        checked: true,
        is_whitelisted: false,
        ready_dispatch: false,
        user_email: null
      }
    });
  }

  @Action(fromUserActions.UpdateUserPhoneDetails)
  UpdateUserPhoneDetails(ctx: StateContext<CoreStateModel>, action: fromUserActions.UpdateUserPhoneDetails) {
    const phone = action.payload;
    const userDetails = ctx.getState().profile;
    if(phone === userDetails.phone) return;
    
    const userInfo = {
      id: userDetails.id,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      phone: userDetails.phone,
      address: userDetails.addresses[0],
      idNumber: userDetails.idNumber
    }
    let payload: UserUpdateRequest = Object.assign({},userInfo, {phone: phone});
    return this._pSVC.update(payload).subscribe({
      next: (res) => {
        if(res) {
          ctx.patchState({
            profile: {
              ...userDetails,
              phone: phone
            }
          })
        }
      }
    })
  }

  @Action(fromUserActions.SSOSignIn)
  SSOSignIn(ctx: StateContext<CoreStateModel>, action: fromUserActions.SSOSignIn) {
    console.log({payload: action?.payload})
    const payload: SSOPayload = action.payload;
    const { redirectUrl } = action.payload;
    
    ctx.patchState({
      idm_token: payload.token,
      auth_token: payload.tauToken,
      tokenSme: payload?.tokenSme ?? null
    });
  }


  @Action(fromUserActions.UserRole)
  UserRole(ctx: StateContext<CoreStateModel>, action: fromUserActions.UserRole) {
    ctx.patchState({
      user_role: action?.payload
    });
  }

  @Action(fromUserActions.TogglePageLoader)
  TogglePageLoader(ctx: StateContext<CoreStateModel>, action: fromUserActions.TogglePageLoader) {
    ctx.patchState({
      page_loader: action.toggle
    });
  }
}