import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isEmpty, isNil} from 'lodash';
import {Address as GoogleAddress} from 'ngx-google-places-autocomplete/objects/address';
import {ToastrService} from 'ngx-toastr';
import {BaseComponent} from 'src/app/baseComponent';
import {CACHE_SUPPORT_ADDED, MAP_SEARCH_CONFIG} from '@models/constants';
import {Result, Statuses} from '@models/result';
import {CacheService} from '@services/cache.service';
import {DeliveryAddress, IDeliveryAddress} from '@services/cart.service';
import {ConfigService} from '@services/config.service';
import {IAddTicketRequest, SupportService} from '@services/support.service';
import {AuthenticationService} from '@services/auth.service';
import {UserService} from '@services/user.service';
import {IUserDetail} from '@models/userDetail';
import {AddressDetail} from '@models/addressDetail';
import {ConfirmationModalComponent} from '@components/confirm-modal/confirm-modal.component';
import {DataLayerService} from '@services/data-layer.service';

@Component({
    selector: 'app-user-complaint-modal',
    templateUrl: './user-complaint-modal.component.html',
    styleUrls: ['./user-complaint-modal.component.scss']
})
export class UserComplaintModalComponent extends BaseComponent implements OnInit {
    formSubmit: boolean;
    loading: boolean;
    userComplaintForm: FormGroup;
    deliveryAddress: IDeliveryAddress;
    formattedAddress: string;
    name: string;
    ticketRequest: IAddTicketRequest;
    siteKey: string;

    config = MAP_SEARCH_CONFIG;
    isSignedIn: boolean;
    userDetail: IUserDetail;

    constructor(
        private cacheService: CacheService,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private supportService: SupportService,
        private configService: ConfigService,
        private authService: AuthenticationService,
        private userService: UserService,
        private dataLayerService: DataLayerService
    ) {
        super();

        this.siteKey = this.configService.SITE_KEY;
    }

    ngOnInit() {
        this.isSignedIn = this.authService.isSignedIn;

        this.userComplaintForm = new FormGroup({

            fullName: new FormControl('', [Validators.required]),

            email: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),

            serviceType: new FormControl('', [Validators.required]),

            cellNumber: new FormControl('', [Validators.required, Validators.maxLength(12), Validators.minLength(10)]),

            query: new FormControl('', [Validators.required]),

            content: new FormControl('', [Validators.required]),

            address: new FormControl('', [Validators.required, this.verifyAddress]),
        });
        if (this.isSignedIn) {
            this.getUserDetail();
        }
    }

    validateGoogleAddress(inputValue) {

        let invalid = false;

        if (isNil(this.deliveryAddress)) {

            invalid = true;

        } else if (inputValue !== this.formattedAddress) {

            invalid = true;
        }

        if (invalid) {
            return {invalid: true};
        }

        return null;
    }

    verifyAddress = (control: AbstractControl): { [key: string]: boolean } | null => {
        return this.validateGoogleAddress(control.value);
    }

    handleMapSearch = (result: GoogleAddress) => {

        this.deliveryAddress = DeliveryAddress.mapfromGoogle(result);

        this.formattedAddress = result.formatted_address;

        this.userComplaintForm.get('address').setValue(result.formatted_address);

    }

    save() {

        this.formSubmit = true;

        if (this.userComplaintForm.invalid) {
            return;
        }

        if (this.cacheService.exists(CACHE_SUPPORT_ADDED) || this.supportService.getLocalStorage()) {
            this.showAlreadyLoggedTicketModal();
            return;
        }


        this.loading = true;
        const serviceType = this.userComplaintForm.controls.serviceType.value;
        this.name = this.userComplaintForm.controls.fullName.value;
        const message = this.userComplaintForm.controls.content.value;
        const email = this.userComplaintForm.controls.email.value;
        const cellNumber = this.userComplaintForm.controls.cellNumber.value;
        const query = this.userComplaintForm.controls.query.value;
        let subject = '';

        switch (query) {
            case 'billing':
                subject = `rain billing support #${cellNumber}`;
                break;
            case 'delivery':
                subject += `rain sales support #${cellNumber}`;
                break;
            case 'rica':
                subject += 'RICA';
                break;
            case 'network':
                subject = `rain network support #${cellNumber}`;
                break;
            case 'general':
                subject = `rain support #${cellNumber}`;
                break;
        }

        subject += '-' + email;

        const body: any = {
            serviceType
        };

        if (isNil(this.formattedAddress) === false) {
            body.address = this.formattedAddress;
        }

        body.message = message;

        this.ticketRequest = {
            name: this.name,
            email,
            subject,
            referenceId: cellNumber,
            body,
            captcha: null
        };
    }

    showAlreadyLoggedTicketModal() {
        const options = {size: 'confirm' as any, windowClass: 'slideInUp', centered: true};
        const modalRef = this.modalService.open(ConfirmationModalComponent, options);
        modalRef.componentInstance.data = {
            title: 'heads up',
            description: `You have already logged a ticket`,
            buttonName: 'ok'
        };
    }

    private openThankYou() {
        this.showThankYouModal(this.name, CACHE_SUPPORT_ADDED);
        this.dataLayerService.myAccountContactSupport();
    }

    showThankYouModal(name: string, cacheType: string) {
        const options = {size: 'confirm' as any, windowClass: 'slideInUp', centered: true};
        const modalRef = this.modalService.open(ConfirmationModalComponent, options);
        modalRef.componentInstance.data = {
            title: 'Thank you, ' + name,
            description: `<p>We have received your ticket.</p>
      <p>An agent will get back to you as soon as possible.</p>`,
            buttonName: 'ok'

        };
        this.cacheService.set(cacheType, 'set', 86400000);
        this.supportService.setLocaStorage();
    }

    getUserDetail() {
        this.userService.get().subscribe((result: Result<IUserDetail>) => {
            if (result.status === Statuses.Success) {
                this.userDetail = result.value;
                this.populateUserDetails();
            } else {
                setTimeout(() => this.toastr.error('An error occurred while processing request. Please try again', null));
            }
        });
    }

    private populateUserDetails() {

        if (isNil(this.userDetail) === false) {

            this.userComplaintForm.get('fullName').setValue(this.userDetail.firstName + ' ' + this.userDetail.lastName);

            this.userComplaintForm.get('email').setValue(this.userDetail.email);

            this.userComplaintForm.get('cellNumber').setValue(this.userDetail.phone);

            this.deliveryAddress = DeliveryAddress.mapFromUserDetail(this.userDetail.addresses);

            if ((isNil(this.deliveryAddress.streetNumber) || isEmpty(this.deliveryAddress.streetNumber)) &&
                (isNil(this.deliveryAddress.streetName) || isEmpty(this.deliveryAddress.streetName))) {
                this.formattedAddress = null;

                this.userComplaintForm.controls.address.setValue(null);

                return;
            }

            this.formattedAddress = AddressDetail.getFormatAddress(this.deliveryAddress);

            this.userComplaintForm.get('address').setValue(this.formattedAddress);


        }
    }
}
