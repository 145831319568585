import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { CartState } from 'src/app/store/state/cart.state';


@Injectable({
	providedIn: 'root'
})
export class CheckCartResolver implements Resolve<boolean> {
	constructor(private store: Store, private route: ActivatedRoute) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
			return this.checkCart()
	}

	private checkCart(): Observable<boolean> {
			const order = this.store.selectSnapshot(CartState.GetSelectedRainOneBundle);
			if (order) return of(true)
			this.store.dispatch(new Navigate(['/']));

			setTimeout(() => {
                return of(true);
            }, 1000);
	}
}
