import { MAP_SEARCH_CONFIG } from '../../../models/constants';
import { CartService, IDeliveryAddress } from '../../../services/cart.service';
import { InCoverageComponent } from '../migration-modals/in-coverage/in-coverage.component';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { CheckboxComponent } from '@components/checkbox/checkbox.component';
import { ISearchResult } from '@components/map-search/map-search.component';
import { CURRENT_ADDRESS, DEFAULT_LATITUDE, DEFAULT_LONGITUDE, CACHE_MIGRATION_ADDRESS } from '@models/constants';
import { IProductDetail } from '@models/productDetail';
import { Result } from '@models/result';
import { CacheService } from '@services/cache.service';
import { DataLayerService } from '@services/data-layer.service';
import { OutOfCoverageModalComponent } from '../out-of-coverage-modal/out-of-coverage-modal.component';
import { FivegProductExplainerModalComponent } from '../fiveg-product-explainer-modal/fiveg-product-explainer-modal.component';
import { Statuses } from '@models/result';
import { CoverageService } from '@services/coverage.service';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { DeliveryAddress } from '@services/cart.service';
import { Router } from '@angular/router';
import { SubjectService } from '@services/subject.service';
import { TwoForOneCovergaeModalComponent } from '../two-for-one-coverage-modal/two-for-one-covergae-modal/two-for-one-coverage-modal.component';
import { AuthenticationService } from '@services/auth.service';
import { Store } from '@ngxs/store';
import { PostUserCoverageAddress, SetUserCoverageAddress } from 'src/app/core/store/actions/coverage.actions';

@Component({
  selector: 'fiveg-coverage-check-modal',
  templateUrl: './fiveg-coverage-check-modal.component.html',
  styleUrls: ['./fiveg-coverage-check-modal.component.scss']
})
export class FivegCoverageCheckModalComponent extends BaseComponent implements OnInit, OnDestroy {

  public config = MAP_SEARCH_CONFIG;
  latitude: number = null;
  longitude: number = null;
  public product: IProductDetail = null;
  public msisdn: string = null;
  public currentProductId: string = null;
  public migrateProductId: string = null;
  public serviceId: string = null;
  public agreedValue: boolean = false;
  public addressVerified: boolean = false;
  public address: string;
  public submitted: boolean = false;
  public isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
  is4Gto5gMigrationCheck: boolean;
  addressDetail: any;
  deliveryAddress: IDeliveryAddress;
  fourgUpsellSubscription: Subscription;

  private idSubject = new BehaviorSubject(null);
  _id: string;
  gmData: string;
  get idSubject$(): Observable<string> {
    return this.idSubject.asObservable();
  }
  @ViewChild(CheckboxComponent, { static: true })
  private checkbox: CheckboxComponent;
  @Output()
  confirmedCoverage = new EventEmitter<any>();
  @Input()
  set id(id: string) {
    this._id = id;
    this.idSubject.next(id);
  }
  get id() {
    return this._id;
  }
  @Input() isTwoForOne: boolean = false;
  private _result: any;

  constructor(
    private coverageService: CoverageService,
    private cacheService: CacheService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private subjectService: SubjectService,
    private dataLayerService: DataLayerService,
    private authService: AuthenticationService,
    private cartService: CartService,
    private store: Store
  ) {
    super();
    this.fourgUpsellSubscription = this.subjectService.subscribeFourgUpsellResponse().subscribe(res => {
      this.close();
    })
  }

  ngOnInit() {
    this.clearValues()
    this.checkbox.onChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.agreedValue = value;
    });
    this.getAddress()
    this.setCurrentPosition();

    if (this.product) {
      let promo = ' - normal product - ';
      if (this.product?.config?.promo?.value) {
        promo = ' - promo product - ';
      } else if (this.product?.config?.blackFriday?.value) {
        promo = ' - black Friday product - ';
      }

      this.gmData = `R${this.product.price} ${promo} ${this.product.name.replace('\n', '')} - check coverage`;
    }
  }

  getAddress() {
    const address: ISearchResult = this.cacheService.getObject(CURRENT_ADDRESS);

    if (!isNil(address)) {
      this.address = address.address;
      this.latitude = address.latitude;
      this.longitude = address.longitude;
      this.addressVerified = true;
    } else {
      this.getUsersLocation();
      this.addressVerified = false;
    }
  }

  handleMapSearch = (result: GoogleAddress) => {
    if (result) {
      this._result = result;
      this.address = result.formatted_address;
      this.deliveryAddress = DeliveryAddress.mapfromGoogle(result);
      this.deliveryAddress.gps_coordinates = {
        longitude: '' + this.longitude,
        latitude: '' + this.latitude
      };
      this.latitude = result.geometry.location.lat();
      this.longitude = result.geometry.location.lng();
      if (this.is4Gto5gMigrationCheck) {
        const migrationAddress = {
          formattedAddress: result.formatted_address,
          streetName: this.deliveryAddress.streetName,
          streetNumber: this.deliveryAddress.streetNumber,
          suburb: this.deliveryAddress.suburb,
          city: this.deliveryAddress.city,
          postalCode: this.deliveryAddress.postalCode,
          lat: this.latitude,
          lon: this.longitude
        }
        this.cacheService.setObject(CACHE_MIGRATION_ADDRESS, migrationAddress);
      }
      this.cacheService.setObject(CURRENT_ADDRESS,
        {
          address: result.formatted_address,
          latitude: this.latitude,
          longitude: this.longitude
        }
      );
      this.addressVerified = true;
    } else {
      this.addressVerified = false;
    }
  }

  public checkCoverage() {
    this.submitted = true;
    if (this.addressVerified == false || this.agreedValue == false) {
      return;
    }
    
    this.store.dispatch(new SetUserCoverageAddress(this._result));
    this.coverageService
      .check(this.latitude, this.longitude, '5g')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: Result<boolean>) => {
        if (result.status === Statuses.Success) {
          let inCoverage = result.value;
          if (inCoverage) {
            this.dataLayerService.coverageMapSearch('5G');
            if (this.is4Gto5gMigrationCheck) {
              this.confirmedCoverage.emit(this.deliveryAddress);
            } else {
              this.isTwoForOne ? this.openTwoForOneCoverage() : this.openFiveGCoverage();
            }
          } else {
            this.dataLayerService.coverageMapSearch('4G');
            if (this.is4Gto5gMigrationCheck) {
              this.confirmedCoverage.emit(false);
            } else {
              this.openNotInCoverage();
            }
          }
          setTimeout(() => {
            $('body').addClass('modal-open');
          }, 500);
        }
      });
  }

  fourGtoFiveGMigrationInCoverageFlow() {
    const modalRef = this.modalService.open(InCoverageComponent, { size: <any>'md', windowClass: 'slideInUp', centered: true });
    modalRef.componentInstance.product = this.product;
    modalRef.componentInstance.msisdn = this.msisdn;
    modalRef.componentInstance.currentProductId = this.currentProductId;
    modalRef.componentInstance.migrateProductId = this.migrateProductId;
    modalRef.componentInstance.serviceId = this.serviceId;
  }


  private openFiveGCoverage() {
    this.activeModal.close();

    this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['unlimited-home-5G-standard-in-coverage']);
    const modalRef = this.modalService.open(FivegProductExplainerModalComponent, { size: <any>'xl', windowClass: 'slideInUp' });
    modalRef.componentInstance.product = this.product;
  }

  private openTwoForOneCoverage() {
    this.activeModal.close();
    this.cartService.add(this.product.id, {});
    if (!this.authService.isSignedIn) {
      const modalRef = this.modalService.open(TwoForOneCovergaeModalComponent, { size: <any>'md', windowClass: 'slideInUp', centered: true });
      modalRef.componentInstance.product = this.product;
    } else {
      this.router.navigate(['/cart']);
    }
  }

  private openNotInCoverage() {
    this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['unsuccessful-coverage-result']);
    const modalRef = this.modalService.open(OutOfCoverageModalComponent, { size: 'lg', windowClass: 'slideInUp' });
    modalRef.componentInstance.latitude = this.latitude;
    modalRef.componentInstance.longitude = this.longitude;
    modalRef.componentInstance.address = this.address;
    modalRef.componentInstance.product = this.product;
  }

  private setCurrentPosition() {
    if (isNil(this.latitude) || isNil(this.longitude)) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        });
      } else {
        this.latitude = DEFAULT_LATITUDE;
        this.longitude = DEFAULT_LONGITUDE;
      }
    }
  }

  close() {
    this.activeModal.close();
  }

  clearValues() {
    this.checkbox.agreedValue = false;
    this.agreedValue = false;
  }

  private getUsersLocation() {
    if ('geolocation' in navigator) {
      return navigator.geolocation.getCurrentPosition(
        (res) => {
          this.latitude = res.coords.latitude;
          this.longitude = res.coords.longitude;
          return
        },
        () => {
          this.latitude = -33.918861;
          this.longitude = 18.423300;
          return;
        }
      );
    }
    this.latitude = -33.918861;
    this.longitude = 18.423300;
    return;
  }

  ngOnDestroy(): void {
    this.cacheService.remove(CURRENT_ADDRESS);
    this.fourgUpsellSubscription.unsubscribe();
  }
}
