import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ConfigService} from "@services/config.service";
import {CACHE_AUTH_TOKEN, CACHE_SME_TOKEN, DEFAULT_POSTAL_CODE, RICA_TYPE_COURIER} from "@models/constants";
import {UIState} from "../../../shared/store/state/ui.state";
import {TokenService} from "@services/token.service";
import {Store} from "@ngxs/store";
import {AuthState} from "../../../core/store/state/auth.state";
import {MobileState} from "../state/mobile.state";
import {OrderStateV2} from "../state/order.state";
import {FulfillmentType} from "@models/orderDetail";
import {Result} from "@models/result";
import {CheckoutResponse} from "@models/checkoutRequest";
import {catchError, map, timeout} from "rxjs/operators";
import {ServiceHelper} from "@services/serviceHelper";
import {UserService} from "@services/user.service";
import {DeliveryType} from "@models/logisticDetail";

@Injectable({
    providedIn: 'root'
})
export class OrderServiceV2 {

    constructor(private http: HttpClient, private configService: ConfigService,
                private store: Store, private tokenService: TokenService, private userService: UserService) {
    }

    createOrder(): Observable<any> {
        const requestPath = `${this.configService.BASE_API_URL}/V1/rain-web/order-proxy/order`;
        const uiMode = this.store.selectSnapshot(UIState.GetUIMode);
        const smeToken = this.store.selectSnapshot(AuthState.getSmeToken) || this.tokenService.get(CACHE_SME_TOKEN);
        const orderToken = (uiMode === 'sme' && smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const body = this.createOrderBody();

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + orderToken
            })
        };
        return this.http.post(requestPath, body, httpOptions);
    }

    createOrderBody() {
        const deliveryAddress = this.store.selectSnapshot(OrderStateV2.getDeliveryAddress);
        const orderLines = this.createOrderLines(deliveryAddress);
        const isRainCourier = orderLines[0].product_configs.delivery.some(x => x.value === 'RAIN');
        const digitalIdentity = this.store.selectSnapshot(AuthState.getDigitalIdentity);

        let orderDeliveryType = 'scheduled';
        let rainCourierDeliveryConfigs: any[];

        if (isRainCourier) rainCourierDeliveryConfigs = orderLines[0].product_configs.delivery;
        if (!!rainCourierDeliveryConfigs.length) {
            if (rainCourierDeliveryConfigs.find(({name}) => name === 'courier_method').value !== 'Pickup') {
                orderDeliveryType = DeliveryType.Scheduled;
            } else {
                orderDeliveryType = DeliveryType.Collection;
            }
        }

        return {
            delivery: {
                instructions: '',
                time: null,
                type: orderDeliveryType,
                rica_type: RICA_TYPE_COURIER,
                sales_channel: 'RAIN WEB'
            },
            deliveryAddress: {
                building_name: deliveryAddress.buildingName ?? '',
                city: deliveryAddress.city ?? '',
                floor_level: deliveryAddress.floorLevel ?? '',
                postal_code: deliveryAddress.postalCode ?? DEFAULT_POSTAL_CODE,
                province: deliveryAddress.province ?? '',
                street_name: deliveryAddress.streetName ?? '',
                suburb: deliveryAddress.suburb ?? '',
                street_number: deliveryAddress.streetNumber ?? '',
                unit_number: deliveryAddress.unitNumber ?? '',
                gps_coordinates: {
                    longitude: deliveryAddress.gps_coordinates?.longitude ?? '',
                    latitude: deliveryAddress.gps_coordinates?.latitude ?? ''
                }
            },
            order_lines: orderLines,
            comments: JSON.stringify({
                'isIn5G': "{\"isIn5G\":true,\"location_id\":\"\"}"
            }),
            comment: '',
            user_id: digitalIdentity?.id ?? '',
        };
    }

    createOrderLines(deliveryAddress: { fulfillmentType: string; }) {
        const quantity = this.store.selectSnapshot(MobileState.getSimAmount);
        const deliveryType = deliveryAddress.fulfillmentType == FulfillmentType.PICKUP ? 'Pickup' : 'Delivery';

        return [
            {
                product_id: '6cf4e840-00e3-4f8c-a0a0-d3baacc95fac',
                number: 0,
                quantity: quantity,
                product_configs: {
                    delivery: [
                        {
                            name: 'courier_name',
                            value: 'RAIN'
                        },
                        {
                            name: 'courier_method',
                            value: deliveryType
                        }]
                }
            }
        ];
    }

    public getOrderCheckoutId(orderId: string, amount: number, orderNumber: string): Observable<Result<CheckoutResponse>> {
        const amountStr = (Math.round(amount * 100) / 100).toFixed(2);
        const userId = this.userService.getUserId();
        const type = 'register_and_pay';

        return this.getCheckoutId({
            amount: amountStr,
            order_id: orderId,
            type,
            orderNumber: orderNumber,
            cancelledUrl: 'google.co.za/cancel',
            failedUrl: 'google.co.za/failed',
            successUrl: 'google.co.za/success',
            customerId: userId
        });
    }

    public getCheckoutId(request: any): Observable<Result<CheckoutResponse>> {
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        // const requestPath = `${this.configService.PAYMENT_GATEWAY_URL}/payments/checkout`;
        const requestPath = `${this.configService.API_URL}/billing/checkout`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: this.tokenService.getTokenForAccountType()
            })
        };

        return this.http.post(requestPath, request, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                const checkout = CheckoutResponse.adapt(result);

                return Result.success(checkout);
            }),
            catchError(result => ServiceHelper.handleError<CheckoutResponse>(result))
        );
    }
}