import {Injectable} from '@angular/core';
import {PayDateService} from '@components/pay-date/pay-date.service';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {BillingService} from 'src/app/core/services/billing.service';
import {CartService} from '@services/cart.service';
import {ToastrService} from 'ngx-toastr';
import {Utils} from 'src/app/utils';
import {WebsiteconfigService} from 'src/app/core/services/websiteconfig.service';
import {BillCycleOption} from '../../interfaces/bill-cycle-option.interface';
import {BillCyclePayload} from '../../interfaces/bill-cycle-payload.interface';
import {PaymentDate} from '../../interfaces/payment-date.interface';
import * as fromBillingActins from '../actions/billing.actions';
import {
	GetBillCycleOptions,
	GetUserBillingAccountStatus,
	ResetUserBillingAccountStatus,
	SetBillCycle
} from '../actions/billing.actions';
import {CoreState} from './core.state';
import {ServicesState} from 'src/app/store/state/services.state';
import moment from 'moment';
import {tap} from 'rxjs/operators';
import {AccountStates, IAccountStatusDetail} from '@models/accountStatusDetail';
import {GetUserProfile} from '../actions/auth.actions';
import {AuthenticationService} from '@services/auth.service';
import {AuthState} from './auth.state';
import {UIState} from 'src/app/shared/store/state/ui.state';
import {PAYMENT_STATUS_TYPE} from '../types/payment-status-mapping.constant';
import {SetPaymentDate} from "../../../v2/store/actions/billing.action";
import { TokenService } from '@services/token.service';
import {UserService} from "@services/user.service";

export interface PaymentStatusObject {
	errors: null,
	data: {
		status: PAYMENT_STATUS_TYPE,
		message: string,
		merchantTransactionId: string,
		recordedDate: number
	}
}

export interface PaymentDateObject {
	selectedPaymentDate: number | string;
	type: 'PaymentDate' | 'BillCycleDate';
	displayDate: string;
	saved: boolean;
}

export interface BillingStateModel {
  loading: boolean;
  loaded: boolean;
  selected_bill_cycle: BillCycleOption;
  bill_cycle_options: {[id: string]: BillCycleOption};
  delivery_wait_time: number;
  payment_date: PaymentDateObject;
  hasSavedDate: boolean;
  billingAccountStatus: IAccountStatusDetail;
  failedPayment: PaymentStatusObject
}

@State<BillingStateModel>({
  name: 'billing',
  defaults: {
    loading: false,
    loaded: false,
    selected_bill_cycle: null,
	bill_cycle_options: {},
	delivery_wait_time: 3,
	payment_date: {
		selectedPaymentDate: null,
		displayDate: null,
		type: null,
		saved: false
	  },
	hasSavedDate: false,
	billingAccountStatus: null,
	failedPayment: null
  }
})
@Injectable()
export class BillingState {
    constructor(
        public store: Store,
        private websiteConfig: WebsiteconfigService,
		private billingDervice: BillingService,
		private _pDateSvc: PayDateService,
		private cartSvc: CartService,
		private toastr: ToastrService,
		private authSvc: AuthenticationService,
		private tokenService: TokenService,
		private userService: UserService
    ) {}

	@Selector()
	static getUserBillingAccountStatus(state: BillingStateModel) {
	  return state.billingAccountStatus;
	}


	@Selector()
	static hasAmountInArrears(state: BillingStateModel) {
	  return state.billingAccountStatus?.amountArrears >= 10  || state.billingAccountStatus?.amount >= 10;
	}

	@Selector()
	static arrearsAmount(state: BillingStateModel) {
	  return state.billingAccountStatus?.amount.toFixed(2);
	}

	@Selector([BillingState.getUserBillingAccountStatus, CoreState])
	static goodAccountStanding(state: BillingStateModel, accountState: IAccountStatusDetail) {

		if(accountState && accountState.accountState) {
			return (accountState?.accountState === AccountStates.Normal || accountState?.accountState === AccountStates.FuturePayRun)
		}
		return true;
	}

	@Selector()
    static isLoaded(state: BillingStateModel): boolean {
		return state.loaded;
	}

	@Selector()
    static hasSavedDate(state: BillingStateModel): boolean {
		return state.hasSavedDate;
	}

	@Selector()
    static billCycleOptions(state: BillingStateModel): Array<BillCycleOption> {
		return Utils.Mappers.FromHashMap(state.bill_cycle_options);
	}

	@Selector()
    static getDeliveryWaitTime(state: BillingStateModel): number {
		return state.delivery_wait_time;
	}

	@Selector()
    static getPaymentDate(state: BillingStateModel): PaymentDateObject {
		return state.payment_date;
	}

	@Selector()
    static GetLastFailedPayment(state: BillingStateModel): PaymentStatusObject {
		return state.failedPayment;
	}

	@Selector()
    static getNextBillCycleDate(state: BillingStateModel): string {
		let todayMonth = moment().month();
		let todayDay = moment().date();
		let todayYear = moment().year();
		if (Number(state.payment_date) > todayDay) {
			return moment({ date: Number(state.payment_date), month: moment().month(), year: todayYear }).format('DD MMM').toString()
		} else {
			return moment({ date: Number(state.payment_date), month: todayMonth + 1, year: todayYear }).format('DD MMM').toString()
		}
	}

	@Selector()
    static getClosestBillCycleDate(state: BillingStateModel): string {
		let today = parseInt(moment(new Date()).format('D'));;
		let cycle;

		const billCycleDates = Utils.Mappers.FromHashMap<BillCycleOption>(state.bill_cycle_options);

		billCycleDates.forEach((c, i) => {
			if(c.start_day < today || c.start_day === today) {
				let index = (i+1) > billCycleDates.length -1 ? 0 : (i+1);
				return cycle = billCycleDates[index];
			}
;
			if(c.start_day > today) return;
		});

		  return cycle.start_day.toString();
	}

	@Selector()
    static getSelectedBillCycle(state: BillingStateModel): BillCycleOption {
		return state.selected_bill_cycle;
	}

	@Selector()
    static isInArrears(state: BillingStateModel): boolean | null {
		const isPostPaid = ServicesState.hasPostPaid;
		const accountStatus = state.billingAccountStatus.accountState;
		const payDate = isPostPaid ? state.payment_date.selectedPaymentDate : state.selected_bill_cycle.start_day;
		const todayDay = moment().format('DD');

		if(accountStatus) {
			if (accountStatus === AccountStates.Grace) {
				return true;
			} else if (accountStatus === AccountStates.OverDue) {
				return true;
			} else if (accountStatus === AccountStates.OverdueHighspeed) {
				return true;
			}
			return false;
			// return accountStatus !== (AccountStates.Grace || AccountStates.OverDue || AccountStates.OverdueHighspeed);
		}

		return null;
	}

	@Action(GetBillCycleOptions)
	GetBillCycleOptions(ctx: StateContext<BillingStateModel>, action: fromBillingActins.GetBillCycleOptions) {
		ctx.patchState({
			loaded: false,
			loading: true
		});

		this.websiteConfig.fetchBillCycleOptions()
		.subscribe(
			{
				next: (res: any) => ctx.dispatch(new fromBillingActins.GetBillCycleOptionsSuccess(res)),
				error: (err: any) => ctx.dispatch(new fromBillingActins.GetBillCycleOptionsFail(err))

			}
		)
	}

	@Action(fromBillingActins.GetBillCycleOptionsSuccess)
	GetBillCycleOptionsSuccess(ctx: StateContext<BillingStateModel>, action: fromBillingActins.GetBillCycleOptionsSuccess) {
        const state = ctx.getState();
		const payload = action.payload;

		ctx.patchState({
			bill_cycle_options: Utils.Mappers.ToHashMap(payload, state.bill_cycle_options, 'id'),
			loaded: true
		});

		const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);

		if(!isPostPaid) {
			const profile = this.store.selectSnapshot(CoreState.getUserProfile);
			if(profile && profile?.bill_cycle_spec_detail?.bill_cycle_spec) {
				ctx.dispatch(new fromBillingActins.SetSelectedBillCycle(profile?.bill_cycle_spec_detail?.bill_cycle_spec))
			}
		}
	}

	@Action(fromBillingActins.SetPaymentDate)
	SetPaymentDate(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SetPaymentDate) {
		const option = action.payload;
		ctx.patchState({
			payment_date: {
				saved: false,
				selectedPaymentDate: option.value,
				displayDate: moment(option.value, 'DD').format('Do'),
				type: option.type
			}
		});
	}

	@Action(fromBillingActins.SetSavedPaymentDate)
	SetSavedPaymentDate(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SetSavedPaymentDate) {
		const option = action.payload;
		ctx.patchState({
			payment_date: {
				saved: true,
				selectedPaymentDate: option.value,
				displayDate: moment(option.value, 'DD').format('Do'),
				type: option.type
			},
		});
	}

	@Action(fromBillingActins.InitSavePaymentDate)
	InitSavePaymentDate(ctx: StateContext<BillingStateModel>, action: fromBillingActins.InitSavePaymentDate) {
		const token = this.tokenService.getTokenForAccountType();
		const consumerAndWorkTokens = [
			this.store.selectSnapshot(AuthState.getConsumerToken),
			this.store.selectSnapshot(AuthState.getSmeToken)
		]

		const workProduct = this.store.selectSnapshot(UIState.GetUIMode) === "sme";
		const workUserId = this.store.selectSnapshot(AuthState.getSmeToken) ? JSON.parse(atob(this.store.selectSnapshot(AuthState.getSmeToken)?.split('.')?.[1]))?.user_id : "";
		const consumerUserId = this.store.selectSnapshot(AuthState.getConsumerToken) ? JSON.parse(atob(this.store.selectSnapshot(AuthState.getConsumerToken)?.split('.')?.[1]))?.user_id : "";
		const userId = this.userService.getUserId(token);
		const state = ctx.getState();
		const type = state.payment_date.type;
		const profile = this.store.selectSnapshot(CoreState.getUserProfile);
		let id = (userId !== undefined ||userId !==  null) ? userId : profile.id
		localStorage.setItem('selected_payment_date', JSON.stringify(state.payment_date));
		switch (state.payment_date.type) {
			case 'BillCycleDate': {

				const newWorkCustomer = workProduct && !state?.payment_date.saved;

				const paymentDate: PaymentDate = {
					value: state.payment_date.selectedPaymentDate,
					type: type
				}

				if (!newWorkCustomer) {
					const data: BillCyclePayload = {
						user_id: id,
						bill_cycle_spec: Utils.Mappers.FromHashMap<BillCycleOption>(state.bill_cycle_options).find((bc) => String(bc.start_day) === String(state.payment_date.selectedPaymentDate)).id
					};

					ctx.dispatch(new SetBillCycle(data))
					ctx.dispatch(new fromBillingActins.SavePaymentDate(paymentDate))
					ctx.dispatch(new SetPaymentDate(paymentDate))
					return

				} else {
					const billCyclePayloads = [
						{
							user_id: consumerUserId,
							bill_cycle_spec: Utils.Mappers.FromHashMap<BillCycleOption>(state.bill_cycle_options).find((bc) => String(bc.start_day) === String(state.payment_date.selectedPaymentDate)).id,
						},
						{
							user_id: workUserId,
							bill_cycle_spec: Utils.Mappers.FromHashMap<BillCycleOption>(state.bill_cycle_options).find((bc) => String(bc.start_day) === String(state.payment_date.selectedPaymentDate)).id,
						},
					];

					billCyclePayloads.forEach((payload, i) => {
						ctx.dispatch(new SetBillCycle(payload, consumerAndWorkTokens?.[i]))
						return
					})
					ctx.dispatch(new fromBillingActins.SavePaymentDate(paymentDate, consumerAndWorkTokens?.[0]))
					return
				}

			}

			case 'PaymentDate': {
				const data: PaymentDate = {
					value: state.payment_date.selectedPaymentDate,
					type: type
				}

				return ctx.dispatch(new fromBillingActins.SavePaymentDate(data))
			}
		}
	}

	@Action(fromBillingActins.SetBillCycle)
	SetBillCycle(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SetBillCycle) {
		const payload = action.payload;

		this.billingDervice.setCycleOption(payload, action?.token)
		.subscribe(
			{
				next: (res: any) => ctx.dispatch(new fromBillingActins.SetBillCycleSuccess(res)),
				error: (err: any) => {
					ctx.dispatch(new fromBillingActins.SetBillCycleFail(err))
				}
			}
		);
	}

	@Action(fromBillingActins.SetBillCycleSuccess)
	SetBillCycleSuccess(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SetBillCycleSuccess) {
		const state = ctx.getState();

		ctx.patchState({
			payment_date: {
				...state.payment_date,
				saved: true
			},
		});

		ctx.dispatch([new fromBillingActins.IndicateSavedDate(), new GetUserProfile()]);
	}

	@Action(fromBillingActins.SetSelectedBillCycle)
	SetSelectedBillCycle(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SetSelectedBillCycle) {
		const state = ctx.getState();
		const id = action.payload;

		ctx.patchState({
			selected_bill_cycle: state.bill_cycle_options[id],
			payment_date: {
				...state.payment_date,
				saved: true
			},
		});
	}

	@Action(fromBillingActins.SavePaymentDate)
	SavePaymentDate(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SavePaymentDate) {
		const payload = action.payload;

		this._pDateSvc.updatePaymentDate(payload.value, (action?.token ?? ""))
		.subscribe(
			{
				next: (res: any) => ctx.dispatch(new fromBillingActins.SavePaymentDateSuccess(res)),
				error: (err: any) => ctx.dispatch(new fromBillingActins.SavePaymentDateFail(err))
			}
		)


	}

	@Action(fromBillingActins.SavePaymentDateSuccess)
	SavePaymentDateSuccess(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SavePaymentDateSuccess) {
		const state = ctx.getState();

		ctx.patchState({
			payment_date: {
				...state.payment_date,
				saved: true
			},
		});

		ctx.dispatch(new fromBillingActins.IndicateSavedDate());
	}

	@Action(fromBillingActins.IndicateSavedDate)
	IndicateSavedDate(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SavePaymentDateSuccess) {
		const state = ctx.getState();

		ctx.patchState({
			hasSavedDate: true
		});
	}

	@Action(fromBillingActins.SavePaymentDateFail)
	SavePaymentDateFail(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SavePaymentDateSuccess) {
		const payload = action.payload;

		this.toastr.error('There was an error while processing your paymant date : ' + payload.error.error);
	}

	@Action(fromBillingActins.ChechandSwitchAccountType)
	ChechandSwitchAccountType(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SavePaymentDateSuccess) {

		this.cartSvc.switchPackages();
	}

	@Action(fromBillingActins.FetchDeliveryWaitTime)
	FecthDeliveryWaitTime(ctx: StateContext<BillingStateModel>, action: fromBillingActins.FetchDeliveryWaitTime) {

		this.websiteConfig.fecthDeliveryWaitTime()
		.subscribe(
			{
				next: (res: any) => ctx.dispatch(new fromBillingActins.FecthDeliveryWaitTimeSuccess(res)),
				error: (err: any) => ctx.dispatch(new fromBillingActins.FecthDeliveryWaitTimeFail(err))
			}
		)
	}

	@Action(fromBillingActins.FecthDeliveryWaitTimeSuccess)
	FecthDeliveryWaitTimeSuccess(ctx: StateContext<BillingStateModel>, action: fromBillingActins.FecthDeliveryWaitTimeSuccess) {

		const payload = action.payload;
		ctx.patchState({
			delivery_wait_time: payload
		});
	}

	@Action(fromBillingActins.SetProRataData)
	SetProRataData(ctx: StateContext<BillingStateModel>, action: fromBillingActins.SetProRataData) {
		const email = action.payload;
		this.billingDervice.SetProRataData(email)
		.subscribe(
			{
				next: (res: any) => ctx.dispatch(new fromBillingActins.SetProRataDataSuccess(res)),
				error: (err: any) => ctx.dispatch(new fromBillingActins.SetProRataDataFail(err))

			}
		)
	}
	@Action(fromBillingActins.GetLastFailedPayment)
	GetLastFailedPayment(ctx: StateContext<BillingStateModel>, action: fromBillingActins.GetLastFailedPayment) {
		return this.billingDervice.GetLastFailedPayment().pipe(
			tap({
				next: (res: any) => {
					return ctx.patchState({
						failedPayment: res ? res : null
					});
				},
				error: (e: unknown) => {
					return ctx.patchState({
						failedPayment: null
					})
				}
			}));
	}

	@Action(fromBillingActins.GetUserBillingAccountStatus)
	getUserBillingAccountStatus(ctx: StateContext<BillingStateModel>, action: GetUserBillingAccountStatus) {
	  return this.billingDervice.getAccountStatus(action?.email).pipe(
		tap({
			next: (statusResult) => {
				ctx.patchState({
					billingAccountStatus: statusResult.value
				});

				return ctx.dispatch(new fromBillingActins.GetLastFailedPayment())
			},
			error: (e: unknown) => {
				this.toastr.error(`Failed to fetch user billing account status: ${e}`);
			}
		}));
	}

	@Action(fromBillingActins.ResetUserBillingAccountStatus)
	resetUserBillingAccountStatus(ctx: StateContext<BillingStateModel>, action: ResetUserBillingAccountStatus) {
	 return  ctx.patchState({
		loading: false,
		loaded: false,
		selected_bill_cycle: null,
		delivery_wait_time: 3,
		payment_date: {
			selectedPaymentDate: null,
			displayDate: null,
			type: null,
			saved: false
		},
		hasSavedDate: false,
		billingAccountStatus: null,
		failedPayment: null
	  });
	}

	@Action(fromBillingActins.ResetBillingDetails)
	ResetBillingDetails(ctx: StateContext<BillingStateModel>, action: fromBillingActins.ResetBillingDetails) {
	 return  ctx.patchState({
		selected_bill_cycle: null,
		delivery_wait_time: 3,
		payment_date: {
			selectedPaymentDate: null,
			displayDate: null,
			type: null,
			saved: false
		},
		hasSavedDate: false,
		billingAccountStatus: null,
		failedPayment: null
	  });
	}
}
