<div class="membership-modal-container">

    <div class="close-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <br>
    <div class="page-title">
        <h2>heads up</h2>
    </div>
    <div class="content-body">
        <p>Looks like you already have an NVIDIA membership. You can head over to<span (click)="navigateToMyRain()" class="my-rain"> my rain</span> and manage your
            membership</p>
    </div>
    <div class="button-container">
        <button (click)="navigateToMyRain()">got it</button>
    </div>
</div>