<div class="nvidia-upsell-modal-container">
    <div class="close-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <br>

    <div class="page-title">
        <h2> game on with <span class="bold-text"> rainone</span></h2>
    </div>
    <br>
    <div class="page-contents">
        <div class="user-greeting">
            Hey <span>{{userName}}</span>
        </div>
        <div class="upsell-message">
            <p>Great news! Get your game on with NVIDIA GeForce NOW, with unlimited 5G home wifi from
                rain<strong class="bold-text">one</strong> .</p>
            <br>
            <p>With rain<strong class="bold-text">one</strong> , you get unlimited 5G home wifi, PLUS two 4G mobile SIMs, each with 60
                FREE minutes, 2 FREE
                gigs and 100 FREE sms every month. With national 4G mobile coverage on our new 4G mobile network.
            </p>
        </div>
        <div class="shop-section">
            <div class="image-section">
                <img src="/assets/images/nvidia/nvidia-shop-image.png"  *ngIf="!migration">
                <img src="/assets/images/nvidia/nvidia-migration-image.png"  *ngIf="migration">
                <br>
                <p class="terms" (click)="navigateToTerms()">T&c's apply</p>
            </div>
            <div class="button-container">
                <app-support-button [selectedColor]="'#0077C8'" data-id="nvidia-request-a-call-button"
                data-value="data-selenium-id" [disabled]="isTicketCreationAllowed$ | async"
                btnClass="btn btn-transparent border-primary text-primary mr-4 purchaseJourney" [dataId]="'btn-click'"
                [isPromo]="false" fromPage="Nvidia" fromButton="productCallMe" title="request a call"
                id="btn-cust" [subject]="'request a call #'" [bypassModal]="false"
                [showSignIn]="false"></app-support-button>
                <br>
                <button (click)="BuyNow()" class="buyNowButton">buy</button>
            </div>
        </div>
    </div>