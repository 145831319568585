import { Utils } from "src/app/utils";
import { AllowByIdPayload, AllowByPassportPayload, DeleteAllowListByReferencePaload, RicaStatusPayload } from "../../interfaces/rica.interface";


export class GetStatus {
    static readonly type = Utils.Helpers.Type('[RICA: Status] Get rica status');
    constructor(public readonly payload: RicaStatusPayload) { }
}

export class GetStatusSuccess {
    static readonly type = Utils.Helpers.Type('[RICA: Status] Get rica status success');
    constructor(public readonly payload: any) { }
}

export class GetStatusFail {
    static readonly type = Utils.Helpers.Type('[RICA: Status] Get rica status fail');
    constructor(public readonly payload: any) { }
}

export class GetAllowList {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Get allow list');
    constructor(public readonly payload: string) { }
}
export class GetAllowListSuccess {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Get allow list success');
    constructor(public readonly payload: AllowByIdPayload) { }
}

export class GetAllowListFail {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Get allow list fail');
    constructor(public readonly payload: any) { }
}

export class DeleteAllowList {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Delete allow list');
    constructor(public readonly payload: {ref: string, body: DeleteAllowListByReferencePaload}) { }
}

export class DeleteAllowListSuccess {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Delete allow list success');
    constructor(public readonly payload: {ref: string, body: any}) { }
}

export class DeleteAllowListFail {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Delete allow list fail');
    constructor(public readonly payload: {ref: string, body: any}) { }
}

export class AllowById {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Allow by id');
    constructor(public readonly payload: AllowByIdPayload) { }
}
export class AllowByIdSuccess {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Allow by id success');
    constructor(public readonly payload: any) { }
}
export class AllowByIdFail {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Allow by id fail');
    constructor(public readonly payload: any) { }
}

export class AllowByPassport {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Allow by passport');
    constructor(public readonly payload: AllowByPassportPayload) { }
}
export class AllowByPassportSuccess {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Allow by passport success');
    constructor(public readonly payload: any) { }
}
export class AllowByPassportFail {
    static readonly type = Utils.Helpers.Type('[RICA: AllowList] Allow by passport fail');
    constructor(public readonly payload: any) { }
}

export class ResendEricaMessage {
    static readonly type = Utils.Helpers.Type('[RICA: Message] Resend erica message');
    constructor(public readonly payload: string) { }
}

export class ResendEricaMessagSuccess {
    static readonly type = Utils.Helpers.Type('[RICA: Message] Resend erica message success');
    constructor(public readonly payload: any) { }
}

export class ResendEricaMessageFail {
    static readonly type = Utils.Helpers.Type('[RICA: Message] Resend erica message fail');
    constructor(public readonly payload: any) { }
}

export class GetTrackingInfo {
    static readonly type = Utils.Helpers.Type('[RICA: Tracking] Get tracking info');
    constructor(public readonly payload: string) { }
}

export class GetTrackingInfoSuccess {
    static readonly type = Utils.Helpers.Type('[RICA: Tracking] Get tracking info success');
    constructor(public readonly payload: any) { }
}

export class GetTrackingInfoFail {
    static readonly type = Utils.Helpers.Type('[RICA: Tracking] Get tracking info fail');
    constructor(public readonly payload: any) { }
}

