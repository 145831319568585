<div class="modal-content border-0">
  <div class="modal-img-close-btn z-index-5">
    <img src="assets/svg/close.svg" class="pointer" alt="Close" data-dismiss="modal"
      data-target="#outOfFiveGCoverageModal" aria-label="Close" (click)="activeModal.dismiss('Cross click')" />
  </div>

  <div class="modal-body">
    <div class="modal-container">
      <ng-content *ngTemplateOutlet="getTemplate()"></ng-content>
    </div>
  </div>
</div>


<ng-template #mainContent>
  <div class="row">
    <div class="address-block">
      <h2 class="text-center text-primary display-3 intro-header font-weight-bold">
        looks like you're not in<br>coverage yet
      </h2>
      <p class="text-center mt-15 h3 font-weight-normal description">
        <!-- {{description}} -->
        Leave your details with us, and we’ll let you know as soon as you have <span *ngIf="(selectedUiMode$| async) === 'consumer'"> home</span><span *ngIf="(selectedUiMode$| async) === 'sme'"> work</span> coverage in your area.
      </p>

      <div>
        <div >
          <app-fiveg-notify id="fiveg-not-in-coverage" [latitude]="latitude" [product]="product" [longitude]="longitude"
            [notify5G]="notify5G" [notify4G]="notify4G" [address]="address" [showAddress]="false" [type]="type"
            (onSubmit)="handleSubmit($event)"></app-fiveg-notify>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmedContent>
  <div class="d-flex justify-content-center mt-20">
    <div class=" text-center">
      <h1 class="mt-10 text-primary pt-5">thanks, {{ name }}.<br />we have got your details.</h1>
      <p class="mt-10 text-primary pt-5" style="line-height:1.4em">
        we will let you know as soon as there is <br />
        5G coverage in your area.
      </p>
      <div class="mt-10 mb-10">
        <img src="assets/icons/icon-yes.png" />
      </div>
    </div>
  </div>
</ng-template>