import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RainOneProduct } from 'src/app/core/interfaces/rain-one-products.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ClearAllVas } from 'src/app/store/actions/cart.action';
import { UpdateRainOneBundleDescription } from 'src/app/store/actions/copy.actions';
import { CartState } from 'src/app/store/state/cart.state';
import { ProductState } from 'src/app/store/state/product.state';
import { VasRemovalDisclaimerModalComponent } from '../vas-removal-disclaimer-modal/vas-removal-disclaimer-modal.component';
import { LimitSalesTicketCreationState } from '@components/sales-modal/store/state/limit-ticket-creation.state';
import { GetTicketCreationTimerStatus } from '@components/sales-modal/store/actions/limit-ticket-creations.actions';
import { CookieService } from '@services/cookie.service';
import { UIState } from '../../store/state/ui.state';

@Component({
  selector: 'rain-rain-one-out-of-coverage-modal',
  templateUrl: './rain-one-out-of-coverage-modal.component.html',
  styleUrls: ['./rain-one-out-of-coverage-modal.component.scss']
})
export class RainOneOutOfCoverageModalComponent implements OnInit {
  @Select(LimitSalesTicketCreationState.isTicketCreationAllowed) isTicketCreationAllowed$: Observable<boolean>;
  public rainOneBundle: RainOneProduct;
  @Output() continuePurchase: EventEmitter<boolean> = new EventEmitter();
  @Output() notifyMe: EventEmitter<boolean> = new EventEmitter();
  @Select(CoreState.activeBreakpoint) activeBreakPoint$ : Observable<string>
  @Select(UIState.GetUIMode) selectedUiMode$ : Observable<string>;
  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
    private router: Router,
    private modalService: NgbModal,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    setInterval(() => {
      this.store.dispatch(new GetTicketCreationTimerStatus())
    }, 30000);
    this.rainOneBundle = this.store.selectSnapshot(ProductState.GetRainOneL1);
  }

  public onNotifyMe() {
    this.notifyMe.emit(true);
    this.continuePurchase.emit(false);
    return this.close();
  }

  public onContinueToBuy() {
    const hasSpeedUp = this.store.selectSnapshot(CartState.hasSpeedUp);
    localStorage.setItem('continue_with_4g', 'true');
    this.store.dispatch(
      new UpdateRainOneBundleDescription({title: 'unlimited 4G Home wifi',speedDescription: 'Best effort 4G speeds', routerDescription: '5G ready free-to-use router'})
    );

    this.close(true);
    if(hasSpeedUp) {
      this.store.dispatch(new ClearAllVas);

      const modalRef = this.modalService.open(VasRemovalDisclaimerModalComponent, {
        size: 'upsell-check' as any,
        windowClass: 'slideInUp',
        centered: true
      });
    }
  }

  onOpenSupport() {
    this.activeModal.close();
  }

  public close(override = false) {
    this.continuePurchase.emit(override);
    this.activeModal.close();
  }

  navigate(){
    this.router.navigateByUrl('/legal');
    this.activeModal.close();
  }

  openTerms() {
    this.router.navigateByUrl('/legal?section=terms');
    this.activeModal.close();
  }

}
