import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isNil } from 'lodash';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { CURRENT_ADDRESS, MAP_SEARCH_CONFIG } from '@models/constants';
import { CacheService } from '@services/cache.service';
export interface ISearchResult {
  latitude?: number;
  longitude?: number;
  address: string;
}

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent implements AfterViewInit {
  formattedAddress = '';
  @Input() hideShopButton = false;

  @Input() selectedOverLay: string;
  
  public config = MAP_SEARCH_CONFIG;
  @Input()
  public set initAddress(address: string) {
    if (this.frm) {
      this.frm.get('address').setValue(address);
    }
  }

  @Input()
  placeholder: string = 'enter address';

  @Input()
  useCachedAddress: boolean = true;


  @Output() onAddressChange: EventEmitter<ISearchResult> = new EventEmitter();
  @Output() inputFocused: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('placesRef', { static: false })
  public searchElementRef: GooglePlaceDirective;

  frm: FormGroup;
  lastAddress: string;

  constructor(private formBuilder: FormBuilder, private cacheService: CacheService) {
    this.frm = this.formBuilder.group({
      address: [this.initAddress]
    });
  }
  getAddressFromCache() {
    if (this.useCachedAddress) {
      const result: ISearchResult = this.cacheService.getObject(CURRENT_ADDRESS);

      if (isNil(result) == false) {
        this.onAddressChange.emit(result);

        this.frm.controls.address.setValue(result.address);
      }
    }
  }

  public reset() {
    this.frm.reset();
  }

  searchChange() {
    if (this.lastAddress !== this.frm.get('address').value) {
      this.onAddressChange.emit(null);
    }
  }

  ngAfterViewInit() {
    this.getAddressFromCache();
  }

  moveUp() {
    //TODO: we must remove jquery from this project
    var elem = $('.coverage-check');
    setTimeout(() => {
      $('html, body').animate(
        {
          scrollTop: elem.offset().top
        },
        1000
      );
    }, 100);
  }

  handleAddressChange(address: Address) {
    if (isNil(address.geometry) == false && isNil(address.geometry.location) == false) {
      const result: ISearchResult = {
        latitude: address.geometry.location.lat(),
        longitude: address.geometry.location.lng(),
        address: address.formatted_address
      };

      this.lastAddress = address.formatted_address;
      this.cacheService.setObject(CURRENT_ADDRESS, result);

      this.frm.get('address').setValue(this.lastAddress);

      this.onAddressChange.emit(result);
    } else {
      this.onAddressChange.emit(null);
    }
  }

  focused() {
    this.inputFocused.emit(true);
  }
}
