import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ICreateEricaRequest, NotificationTypes } from '@models/createEricaRequest';
import { ModelHelper } from '@models/modelHelper';
import { Result } from '@models/result';
import { AuthenticationService } from './auth.service';
import { ConfigService } from './config.service';
import { ServiceHelper } from './serviceHelper';
import { AllowByIdPayload, AllowByPassportPayload, DeleteAllowListByReferencePaload, RicaStatusPayload } from '../core/interfaces/rica.interface';
import { TokenService } from './token.service';
import { CACHE_AUTH_TOKEN, CACHE_IDM_TOKEN } from '@models/constants';

/**
 * IRemoteErica @param
 */
export interface IRemoteErica {
  /**
   *  status @param {string}
   */
  status: string;

  /**
   * order_number @param {string}
   */
  order_number: string;

  /**
   * message @param {string}
   */
  message: string;

  /**
   * url @param {string}
   */
  url: string;

  /**
   * response @param {number}
   */
  response: number;
}

/**
 * EricaDetail @param
 */
export class EricaDetail {
  public static adapt(remote: IRemoteErica): IEricaDetail {
    return {
      status: EricaDetail.mapStatus(remote.status),
      message: ModelHelper.clean(remote.message),
      orderNumber: remote.order_number,
      url: remote.url
    } as IEricaDetail;
  }
  private static mapStatus(status: string): EricaStatuses {
    switch (status) {
      case 'pending':
        return EricaStatuses.Pending;
      case 'expired':
        return EricaStatuses.Expired;
      case 'fail':
        return EricaStatuses.Fail;
      case 'user_action':
        return EricaStatuses.UserAction;
      default:
        return EricaStatuses.None;
    }
  }
}

/**
 *  IEricaDetail @param
 */
export interface IEricaDetail {
  /**
   * status @param {EricaStatuses}
   */
  status: EricaStatuses;

  /**
   * orderNumber @param {string}
   */
  orderNumber: string;

  /**
   * message @param {string}
   */
  message: string;

  /**
   * url @param {string}
   */
  url: string;
}

/**
 * EricaStatuses @param
 */
export enum EricaStatuses {
  None = 0,
  Pending,
  Expired,
  Fail,
  UserAction
}

@Injectable({
  providedIn: 'root'
})

/**
 * EricaService @param
 */
export class EricaService {
  public __domain: string = this.configService.WEB_E_RICA_URL;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private tokenService: TokenService
  ) { 
    this.__domain = this.configService.WEB_E_RICA_URL;
    
  }

  public create(request: ICreateEricaRequest): Observable<Result<string>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const params = {
      line1: ModelHelper.toEmptyString(request.addressLine1),
      line2: ModelHelper.toEmptyString(request.addressLine2),
      building_complex: ModelHelper.toEmptyString(request.buildingComplex),
      country: ModelHelper.toEmptyString(request.country),
      email: ModelHelper.toEmptyString(request.email),
      first_name: ModelHelper.toEmptyString(request.firstName),
      id_number: ModelHelper.toEmptyString(request.idNumber),
      last_name: ModelHelper.toEmptyString(request.lastName),
      mobile: ModelHelper.toEmptyString(request.mobile),
      order_number: request.orderNumber,
      post_code: ModelHelper.toEmptyString(request.postCode),
      province: ModelHelper.toEmptyString(request.province),
      method: this.mapMethod(request.notificationType),
      sms_msisdn: null
    };

    if (request.notificationType == NotificationTypes.SMS) {
      params['sms_msisdn'] = request.mobile.replace(/[\s\+\-\(\))]/, '');
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params
    };

    const url = `${this.configService.E_RICA_URL}/whitelist/`;

    return this.http.get(url, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        if (result.response === 200) {
          const url = result.url;

          return Result.success(url);
        }

        return Result.error<string>(result.message);
      }),
      catchError(result => ServiceHelper.handleError<string>(result))
    );
  }

  public get(orderNumber: string): Observable<Result<IEricaDetail>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const params = {
      order_number: orderNumber
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params
    };

    const url = `${this.configService.E_RICA_URL}/status/`;
    return this.http.get(url, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: IRemoteErica) => {
        const ericaDetail = EricaDetail.adapt(result);

        return Result.success(ericaDetail);
      }),
      catchError(result => ServiceHelper.handleError<any>(result))
    );
  }

  private mapMethod(notificationType: NotificationTypes): string {
    switch (notificationType) {
      case NotificationTypes.Email:
        return 'EMAIL';

      case NotificationTypes.SMS:
        return 'SMS';

      default:
        return '';
    }
  }

  public getStatus(payload: RicaStatusPayload): Observable<any> {
    const url: string = `${this.configService.WEB_E_RICA_URL}/status`;

    return this.http.post(url, payload);
  }

  

  /**
   * @param {string} orderNum
   * @return {*}  {Observable<any>}
   * @memberof EricaService
   */
  public getAllowList(orderNum: string): Observable<AllowByIdPayload> {
    const url: string = `${this.configService.WEB_E_RICA_URL}/allowlist/${orderNum}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)

      })
    };
    return this.http.get(url, httpOptions) as Observable<AllowByIdPayload>;
  }

  public deleteAllowList(ref: string, body: DeleteAllowListByReferencePaload): Observable<any> {
    const url: string = `${this.__domain}/allowlist/${ref}`;

    return this.http.patch(url, body);
  }

  public allowById(payload: AllowByIdPayload): Observable<any> {
    const url: string = `${this.__domain}/allowlist/id-number`;

    return this.http.post(url, payload);
  }

  public allowByPassport(payload: AllowByPassportPayload): Observable<any> {
    const url: string = `${this.__domain}/allowlist/passport`;

    return this.http.post(url, payload);
  }

  public resendEricaMessage(ref: string) {
    const url: string = `${this.configService.WEB_E_RICA_URL}/allowlist/${ref}/resend`;
    const defaultCOnfig = {
      sendSMS: true,
      sendEmail: true
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)

      })
    };

    return this.http.post(url, defaultCOnfig, httpOptions);
  }

  public getTrackingInfo(ref: string) {
    const url: string = `${this.__domain}/allowlist/tracking/${ref}`;
    const defaultCOnfig = {
      page: 0,
      pageSize: 0,
      sortOrder: "ASC"
    };

    return this.http.post(url, defaultCOnfig);
  }
}