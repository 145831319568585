import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Utils} from '@Utils';
import {CoreState} from '../store/state/core.state';
import {CoverageCheckedOnRegistration} from 'src/app/store/actions/cart.action';
import {ClearState} from 'src/app/store/actions/number-porting-actions';
import {ClearServicePolicies, ClearServicesState} from 'src/app/store/actions/services.actions';
import {AuthenticationService} from '@services/auth.service';
import {UIState} from 'src/app/shared/store/state/ui.state';
import {AuthState} from '../store/state/auth.state';
import {environment} from 'src/environments/environment';

interface SsoUrlParams {
    tauToken?: string,
    token?: string,
    redirectUrl?: string,
    tokenSme?: string,
    mode?: string,
    referrer: { [key: string]: string }
    config?: { [key: string]: any }
}

@Injectable({
    providedIn: 'root',
})
export class SSOService {
    private cryptor = new Utils.Helpers.Encryptor();
    private _defaultTargetDomain = ""

    constructor(
        private store: Store,
        private authService: AuthenticationService
    ) {
    }

    /**
     *
     * @description creates the url with the AES encrypted sso qParams to redirect to rainGo.co.za
     * @param targetDomain
     * @param {string} [redirectToSlug='my-rain']
     * @param noParams
     * @return {*}  {Promise<string>}
     * @memberof SSOService
     */
    public createUrl(targetDomain: string, redirectToSlug?: string, noParams?: boolean): Promise<string> {
        const tokens = this.store.selectSnapshot(AuthState.getTokens);
        const mode = this.store.selectSnapshot(UIState.GetUIMode);
        const hasPrepaid = this.store.selectSnapshot(AuthState.getHasPrepaid);

        const referrer = {
            origin: window.location.origin,
            page: window.location.pathname
        };

        const config = {
            hasPrepaid
        };

        const paramsMap: SsoUrlParams = {
            tauToken: tokens.token,
            token: tokens.tokenCredential,
            redirectUrl: redirectToSlug || 'home',
            config,
            referrer,
            tokenSme : tokens?.tokenSme,
        };

        if (tokens?.tokenSme) {
            paramsMap.tokenSme = tokens?.tokenSme;
            paramsMap.config.mode = mode
        }

        if(window.location.href.includes('www.precip') && targetDomain.includes('raingo')) {
          this._defaultTargetDomain = "https://www.sit.raingo.co.za/?sheet=sign_in";

        } else {
          this._defaultTargetDomain = targetDomain;

        }


        return new Promise((resolve, reject) => {
            const encryptedData = this.cryptor.encrypt(JSON.stringify(paramsMap));
            let encodeURIComponentString = encodeURIComponent(encryptedData);
            let concatString = noParams ? '?sso=' : '&sso='
            let url = new URL(this._defaultTargetDomain + concatString + encodeURIComponentString);

            if (url) {
                this.signOutBeforeSSO();
                resolve(url.href)
            }
            reject('unable to create url');
        });
    }

    /**
     *
     * @description a function to decrypt the sso payload
     * @param {string} fullUrl
     * @return {*}  {*}
     * @memberof SSOService
     */
    public decodeUrlParams(fullUrl: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const encodedData = this._getParamsByName('sso', fullUrl) as string;
            const decryptedData = this.cryptor.decrypt(encodedData);
            if (decryptedData) resolve(decryptedData);
            else reject('unable to decode sso payload')
        });
    }

    /**
     *
     * @description internal function to get the sso qParams when recieving the a redirect from rain.co.za
     * @private
     * @param {string} name
     * @param {string} url
     * @return {*}  {(string | null)}
     * @memberof SSOService
     */
    private _getParamsByName(name: string, url: string): string | null {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    private signOutBeforeSSO() {
        this.store.dispatch([
            new ClearServicesState(),
            new ClearServicePolicies(),
            new CoverageCheckedOnRegistration(false),
            new ClearState()
        ])
        localStorage.removeItem('selected_payment_date');
        this.authService.signOut();
        sessionStorage.clear();
    }
}
