<div class="modal-container">
    <div class="modal-header border-0">
        <div class="modal-img-close-btn z-index-5">
            <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
                (click)="close()" />
        </div>
    </div>
    <div class="modal-body p-0  z-index-1">
        <div class="header">
            <div class="header-col-left">
                <h2>heads up</h2>
                <p>In order to buy a rainone work product you need to <br>
                     create a rain work account.</p>
            </div>
        </div>

        <div class="actions-footer">
            <button (click)="createAccount(true)" class="btn btn-sm btn-primary text-white">create a work account</button>
        </div>
    </div>
</div>
