import { CustomerResource, ServiceObject } from "@models/serviceDetail";
import {Utils} from "@Utils";

export class SetGroupedServices {
    static type = Utils.Helpers.Type('[SERVICES] Set Grouped Service');

    constructor(public groupedServices: any) {
    }
}

export class GetServicePolicies {
    public static type = Utils.Helpers.Type('[SERVICES: Get Policies] get services active policies v2');
    constructor(public services: any){}
}

export class ClearSelectedService {
    public static type = Utils.Helpers.Type('[SERVICES: Clear selected service]');
}

export class SetSelectedService {
    public static type = Utils.Helpers.Type('[SERVICES: Set selected service]');
    constructor(public service: ServiceObject){}
}

export class FetchServicesIfNotFetched {
    public static type = Utils.Helpers.Type('[SERVICES: Get All Services] fetch all services if not fetched');
}

export class GetAllServicesV2 {
    public static type = Utils.Helpers.Type('[SERVICES: Get All Services] get services active v2');
}

export class GetAllUserResources {
    public static type = Utils.Helpers.Type('[SERVICES: Get All Services] get all user resources');
}

export class GetServicesOnlineStatus {
    public static type = Utils.Helpers.Type('[SERVICES: Get Connection Status] get service connection status');
    constructor(public resources: Record<string, CustomerResource>) {}
}

export class GetServicesNumberPortingStatus {
    public static type = Utils.Helpers.Type('[SERVICES: Get Port Status] get services number porting status');
    constructor(public services: ServiceObject[]) {}
}

export class GetBucketBalances {
    public static type = Utils.Helpers.Type('[SERVICES: Get Bucket Balances] get service bucket balances v2');
    constructor(public services: any) { }
}

export class ClearServicesState {
    public static type = Utils.Helpers.Type('[SERVICES: Clear] reset services state');
}

export class CancelNvidiaService {
    public static type = Utils.Helpers.Type('[SERVICES: Cancellation] cancels nvidia service by service ID');
    constructor(public serviceId: string) { }
}
export class RevokeNvidiaCancellation {
    public static type = Utils.Helpers.Type('[SERVICES: Cancellation] revokes a sheduled nvidia cancellation');
    constructor(public serviceId: string) { }
}