import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ISearchResult } from '@components/map-search/map-search.component';
import { isNil } from 'lodash';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '@models/constants';
import { CoverageMapModes } from '@services/geo.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataLayerService } from '@services/data-layer.service';
import { ElementService } from '@services/element.service';
import { FiveGCoverageSuccessListProductsModalComponent } from '@components/product/5g-coverage-success-list-products-modal/5g-coverage-success-list-products-modal.component';
import { OutOfCoverageModalComponent } from '@components/product/out-of-coverage-modal/out-of-coverage-modal.component';
import { Result, Statuses } from '@models/result';
import { BaseComponent } from 'src/app/baseComponent';
import { takeUntil } from 'rxjs/operators';
import { CoverageService } from '@services/coverage.service';
import { SubjectService } from '@services/subject.service';
import { Subscription } from 'rxjs';
import { ProductService } from '@services/product.service';
import { FourgUpsellComponent } from '@pages/fiveg-page/fourg-upsell/fourg-upsell.component';

@Component({
  selector: 'app-check-coverage',
  templateUrl: './check-coverage.component.html',
  styleUrls: ['./check-coverage.component.scss']
})
/**
 * CheckCoverageComponent @param
 */
export class CheckCoverageComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * CoverageMapModes @param {CoverageMapModes}
   */
  public CoverageMapModes = CoverageMapModes;
  /**
   *  scrollToShop @param
   */
  public scrollToShop: () => void;


  @Input()
  fromPage: string;

  /**
   *
   *latitude @param {number}
   */
  public latitude: number = DEFAULT_LATITUDE;

  /**
   * longitude @param {number}
   */
  public longitude: number = DEFAULT_LONGITUDE;

  /**
   *messages @param {string}
   */
  messages: string[];

  /**
   *hasAddress @param {boolean}
   */
  hasAddress: boolean;

  /**
   *lastAddress @param {string}
   */
  lastAddress: string;

  /**
   *
   *activeModal  @param {NgbActiveModal}
   */

  addressFound = false;

  fourgUpsellSubscription: Subscription;

  isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
  constructor(
    public activeModal: NgbActiveModal,
    private dataLayerService: DataLayerService,
    private elementService: ElementService,
    private modalService: NgbModal,
    private productService: ProductService,
    private subjectService: SubjectService,
    private coverageService: CoverageService
  ) {
    super();

    this.fourgUpsellSubscription = this.subjectService.subscribeFourgUpsellResponse().subscribe(res => {
      if (res) {
        this.modalService.dismissAll();
      }
    })
  }

  /**
   * setCurrentPosition @param
   */
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;

        this.longitude = position.coords.longitude;
      });
    }
  }

  /**
   * init app
   */
  ngOnInit() {
    if (screen.width > 767) {
      this.isMobile = null;
    }
    this.hasAddress = false;
    this.setCurrentPosition();

    // Track Coverage Check Modal
    this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['coverage-check']);
  }

  /**
   *@example  <app-map-search (onAddressChange)="handleMapSearch($event)" id="map-component" class="other"
   *[placeholder]="'physical address'">
   *</app-map-search>
   * @param result {ISearchResult}
   */
  handleMapSearch(result: ISearchResult) {
    this.dataLayerService.coverageMapSearch('4G|5G');
    if (isNil(result) == false) {

      this.latitude = result.latitude;

      this.longitude = result.longitude;

      this.lastAddress = result.address;

      this.hasAddress = true;

    }
  }

  search() {
    if (this.hasAddress) {
      this.addressFound = true;
      this.check5GCoverage();
    } else {
      this.addressFound = false;
    }

  }


  check5GCoverage(): void {

    this.coverageService
      .check(this.latitude, this.longitude, '5g')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: Result<boolean>) => {
        if (result.status === Statuses.Success) {
          if (result?.value) {
            const modalRef = this.modalService.open(FiveGCoverageSuccessListProductsModalComponent, { size: <any>'lg', windowClass: 'slideInUp d-flex' });
            modalRef.componentInstance.accepted.subscribe((result) => {
              if (result) {
                this.shop();
              }
            });
          } else {
            this.check4GCoverage();
          }
        }
      });
  }

  check4GCoverage(): void {

    this.coverageService
      .check(this.latitude, this.longitude, '4g')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: Result<boolean>) => {
        if (result.status === Statuses.Success) {
          if (result?.value) {
            this.showOutCoverageModal(true, false);

          } else if (!result?.value) {
            this.showOutCoverageModal(true, true);
          }
        }
      });
  }

  showOutCoverageModal(notify5G, notify4G) {
    const modalRef = this.modalService.open(OutOfCoverageModalComponent, { size: 'lg', windowClass: 'slideInUp' });
    modalRef.componentInstance.latitude = this.latitude;
    modalRef.componentInstance.longitude = this.longitude;
    modalRef.componentInstance.address = this.lastAddress;
    modalRef.componentInstance.notify5G = notify5G;
    modalRef.componentInstance.notify4G = notify4G;
    modalRef.componentInstance.fromPage = this.fromPage;
  }

  show4GProduct(): void {
    this.productService.getById('10').subscribe((product) => {
      this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['unlimited-home-5G-standard-in-coverage']);
      const modalRef = this.modalService.open(FourgUpsellComponent, { size: <any>'lg', windowClass: 'slideInUp', centered: true });
      modalRef.componentInstance.fromGotSim = false;
      modalRef.componentInstance.product = product.value;
      modalRef.componentInstance.fromPage = this.fromPage;
      modalRef.componentInstance.title = `<h2 class="text-primary modal-title mt-20 fiveg-title text-center">stay connected while you wait</h2>`;
      modalRef.componentInstance.mobileTitle = `<h2 class="text-primary modal-title mb-10 mt-20 fiveg-title text-center">stay connected<br> while you wait</h2>`;

    });

  }

  shop() {
    if (this.hasAddress && this.addressFound) {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }

      this.elementService.scrollToShop();
    }

  }

  inputFocused() {
    this.hasAddress = false;
  }

  ngOnDestroy(): void {
    this.fourgUpsellSubscription.unsubscribe();
  }

}
