import { Utils } from '@Utils';
import {UserUpdateRequest} from "@models/userUpdateRequest";

export class GetUserDetails {
  static type = Utils.Helpers.Type('[USER] GetDetails');
  constructor() { }
}

export class GetAxiomUserDetails {
  static type = Utils.Helpers.Type('[AXIOM USER] GetDetails');
  constructor() { }
}

export class GetUserPermissions {
  static type = Utils.Helpers.Type('[USER] GetPermissions');
  constructor(public userId: string) { }
}

export class GetWhatsappDetails {
  static type = Utils.Helpers.Type('[USER] GetWhatsappDetails');
  constructor() { }
}

export class SetWhatsappOptIn {
  static type = Utils.Helpers.Type('[USER] SetWhatsappOptIn');
  constructor(public optIn: boolean) { }
}

export class SetPromoOptIn {
  static type = Utils.Helpers.Type('[USER] SetPromoOptIn');
  constructor(public optIn: boolean) { }
}

export class SetLegacy4GLimit {
  static type = Utils.Helpers.Type('[LEGACY 4G LIMIT] Set 4G Limit');
  constructor(public serviceId: string, public value: string) { }
}

export class GetPromoOptIn {
  static type = Utils.Helpers.Type('[USER] GetPromoOptIn');
  constructor() { }
}

export class UpdateUserDetailsOld {
  static type = Utils.Helpers.Type('[USER] Update user details old');
  constructor(public userDetails: UserUpdateRequest) { }
}
