import { isNil } from 'lodash';
import { ModelHelper } from './modelHelper';
import { IRemoteOrder, IRemoteOrderLine } from './remote/remoteOrder';
import { LogisticDetail, LogisticStatus } from './logisticDetail';
import { IDictionary } from './dictionary';

export class OrderDetail {

  public static adapt(remote: IRemoteOrder): IOrderDetail {
    const payload = remote['result'] ? remote['result']: remote;
    const orderDetail:IOrderDetail = {
      id: payload.id || payload.order_id,
      orderComplete: payload.complete,
      orderDate: ModelHelper.toDate(payload.orderDate),
      items: this.mapOrderLines(payload.order_lines),
      status: LogisticDetail.mapStatus(payload.status),
      number: payload.number,
      deliveryAddress: payload.deliveryAddress,
      totalPrice: payload.total_price,
      delivery: payload.delivery,
      comments: payload.comments,
    };
    return orderDetail;
  }

  private static mapOrderLines(remoteOrderLines: IRemoteOrderLine[]): IOrderLineDetail[] {
    let orderLineDetail: IOrderLineDetail[] = [];
    remoteOrderLines?.forEach(remoteOrderLine => {
      if (!isNil(remoteOrderLine)) {
        const productId = remoteOrderLine.product_id;
        const productConfigs = remoteOrderLine.product_configs || {};
        const category = this.mapCategory(remoteOrderLine.category);
        const price = remoteOrderLine.unit_price;

        orderLineDetail.push({
          id: remoteOrderLine.id,
          productId: productId,
          productConfigs: productConfigs,
          category: category,
          price: price
        });
      }
    });
    return orderLineDetail;
  }

  private static mapCategory(remoteOrderLineCategory: string): OrderLineCategories {
    switch (remoteOrderLineCategory) {
      case 'product':
        return OrderLineCategories.Product;
      case 'delivery':
        return OrderLineCategories.Delivery;
      case 'device':
        return OrderLineCategories.Device;
      default:
        return OrderLineCategories.None;
    }
  }
}

export interface IOrderDetail {
  id: string;
  orderComplete: boolean;
  orderDate: Date;
  items: IOrderLineDetail[];
  status: LogisticStatus;
  number: string;
  deliveryAddress: {};
  totalPrice:string;
  delivery: any;
  comments: string;
}

export interface IOrderLineDetail<T = any> {
  id: string;
  price: any;
  productId: string;
  productConfigs: IDictionary<T>;
  category: OrderLineCategories;
}
export enum OrderLineCategories {
  None = 0,
  Delivery,
  Product,
  Device
}

export enum FulfillmentType {
  DELIVERY = 'Delivery',
  PICKUP = 'Pickup',
  STORE = 'drop at store',
  COLLECTION = 'collection'
}