import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BillingStates } from '@models/accountStatusDetail';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createSelector, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { FestiveBillingModalComponent } from '@pages/service-page/festive-billing-modal/festive-billing-modal.component';
import { Observable } from 'rxjs';
import { BillCycleOption } from 'src/app/core/interfaces/bill-cycle-option.interface';
import { RainOneWifiLevel } from 'src/app/core/interfaces/rain-one-level.interface';
import { BillingState } from 'src/app/core/store/state/billing.state';
import { CoreState } from 'src/app/core/store/state/core.state';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { ChangeWifiSpeed, ClearAllVas } from 'src/app/store/actions/cart.action';
import { SpeedUpWifiRequest, setAddonType } from 'src/app/store/actions/services.actions';
import { CartState } from 'src/app/store/state/cart.state';
import { ProductState } from 'src/app/store/state/product.state';
import { ServicesState } from 'src/app/store/state/services.state';
import { UIState, UIMode } from '../../store/state/ui.state';
import { includes } from 'lodash';

/**
 * currentSpeed
 * selectedLevel
 * paymentDate
 * twenty_fifth_downgrade_disabled
 */
interface ShowFestiveBillingModalArgs {
  currentSpeed: number;
  selectedLevel: number;
 
}

class RainOneModalSelectors {
  static shouldShowFestiveBillingModal({ currentSpeed, selectedLevel }: ShowFestiveBillingModalArgs) {
    return createSelector(
      [FirebaseConfigsState.firstDowngradesDisabled, FirebaseConfigsState.twentyFifthDowngradesDisabled, BillingState.getSelectedBillCycle],
      (firstDowngradesDisabled: boolean, twentyFifthDowngradesDisabled: boolean, billCycleOption: BillCycleOption): boolean => {
        const selectedLevelLowerThanCurrentLevel = selectedLevel < currentSpeed;
        if ( !selectedLevelLowerThanCurrentLevel) {
          return false;
        }

        const { start_day } = billCycleOption ?? {};

        const matches1st = firstDowngradesDisabled && start_day === 1;
        const matches25th = twentyFifthDowngradesDisabled && start_day === 25;

        return matches1st || matches25th;
      }
    );
  }
}

@Component({
  selector: 'rain-one-add-wifi-speed-modal',
  templateUrl: './rain-one-add-wifi-speed-modal.component.html',
  styleUrls: ['./rain-one-add-wifi-speed-modal.component.scss']
})
export class RainOneAddWifiSpeedModalComponent implements OnInit, OnDestroy {
  @Select(CoreState.activeBreakpoint) public ap$: Observable<string>;
  @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
  selectedLevel: RainOneWifiLevel = null;
  @Input() isSelectable!: boolean;
  @Input() speedIndexOveride: number = null;
  @ViewChild('fromPage') fromPage;
  @ViewChild('ppModal', { static: true }) ppModal: TemplateRef<any>;
  page: string;
  currentSpeedString;
  currentSpeed;
  userType;
  speedChange: 'imediate' | 'scheduled' | 'none';
  public levels = [];
  public purchaseSpeed: number = this.store.selectSnapshot(CartState.GetSelectedVasIndex);
  public selectedVasIndex;
  public isWorkProduct;
  @Select(FirebaseConfigsState.twentyFifthDowngradesDisabled) public twentyFifthDowngrades$: Observable<string>;

  paymentDate = this.store.selectSnapshot(BillingState.getSelectedBillCycle)?.start_day;
  isEnabled = this.store.selectSnapshot(FirebaseConfigsState.getFlags)?.au_dec_downgrade_disabled;

  @Select(FirebaseConfigsState.twentyFifthDowngradesDisabled) twenty_fifth_downgrade_disabled$: Observable<boolean>;
  @Select(FirebaseConfigsState.twentyFifthCancellations) twenty_fifth_cancel_disabled$: Observable<boolean>;
  @Select(FirebaseConfigsState.firstDowngradesDisabled) first_downgrade_disabled$: Observable<boolean>;
  @Select(FirebaseConfigsState.firstCancellations) first_cancellation_disabled$: Observable<boolean>;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
    private modalService: NgbModal,
    private actions$: Actions,
    private router: Router
  ) {}

  ngOnInit() {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
    this.currentSpeedString = this.store.selectSnapshot(ServicesState.getServiceSpeed())?.pop()?.policy;
    
    this.currentSpeed = this.splitSpeedString(this.currentSpeedString);
    const svc = this.store.selectSnapshot(ServicesState.SelectedService);
    const svcProduct = this.store.selectSnapshot(ProductState.GetProductById(svc?.productId))
    this.isWorkProduct = svcProduct?.name?.includes('work');
    this.userType = this.store.selectSnapshot(ServicesState.SelectedService)
      ? svcProduct?.config?.paymentType
      : 'upfront';

    this.levels = [
      {
        level: '30',
        id: '0',
        speed: '30',
        speedInt: 30,
        cost: '0',
        selected: this.isSelectable ? true : false,
        includedInSelection: false,
        footerText: '',
        // 'all you need',
        isSelectable: this.isSelectable
      },
      {
        level: '60',
        id: '1',
        speed: '60',
        speedInt: 60,
        cost: '200',
        selected: false,
        includedInSelection: false,
        footerText: '',
        // 'streaming on<br>multiple devices',
        isSelectable: this.isSelectable
      },
      {
        level: '100+',
        id: '2',
        speed: '100+',
        speedInt: 100,
        cost: '400',
        selected: false,
        includedInSelection: false,
        footerText: '',
        // 'best for multiple<br>4K streams',
        isSelectable: this.isSelectable
      }
    ];
    this.page = this.fromPage;

    if (this.isSelectable) {
      this.selectedVasIndex = this.speedIndexOveride ? this.speedIndexOveride : this.store.selectSnapshot(CartState.GetSelectedVasIndex);
      
      this.selectedLevel = this.selectedVasIndex ? this.levels?.[this.selectedVasIndex] : this.levels?.[0];
      
      this.levels.forEach(l => {
        l.includedInSelection = Boolean(parseInt(l.id) < this.selectedVasIndex),
        l.selected = Boolean(parseInt(l.id) === this.selectedVasIndex)
      });

      
    }

    this.actions$.pipe(ofActionSuccessful(ClearAllVas)).subscribe({
      next: _ => (this.selectedLevel = null)
    });
  }

  public captureLevel(level: RainOneWifiLevel) {
    if(level) {
      if (!level.isSelectable) return (this.selectedLevel = null);

      this.selectedLevel = level;
    }
  }

  public onLevelSelect() {
    let modalRef: NgbModalRef;

    if (this.page !== 'service') {
      this.store.dispatch(new ChangeWifiSpeed(parseInt(this.selectedLevel.id)));
    } else {
      const showFestiveModal = this.store.selectSnapshot(
        RainOneModalSelectors.shouldShowFestiveBillingModal({
          selectedLevel:parseInt(this.selectedLevel?.level) ,
          currentSpeed: parseInt(this.currentSpeed) ,
        })
      );
      if (showFestiveModal) {
        this.activeModal.close('close');
        modalRef = this.modalService.open(FestiveBillingModalComponent, {
          centered: true,
          size: 'sm',
          windowClass: 'slideInUp d-flex'
        });
        modalRef.componentInstance.isDowngrade = true;
        return;
      }
      if (this.userType === 'postpaid') {
        this.calculate();
        this.activeModal.close();
        const modalRef = this.modalService.open(this.ppModal, {
          size: 'sm',
          windowClass: 'slideInUp',
          centered: true
        });
      }
      if (this.userType === 'upfront') {
        this.store.dispatch(new SpeedUpWifiRequest(this.selectedLevel));
      }
    }
    this.activeModal.close('close');
  }



  dismiss() {
    this.modalService.dismissAll();
  }

  confirmAddonModal() {
    if (this.router.url.includes('/service/')) {
      localStorage.setItem('purchasing', 'speed up');
    }
    this.store.dispatch([new SpeedUpWifiRequest(this.selectedLevel), new setAddonType(this.speedChange as 'imediate' | 'scheduled' | 'none')]);

    this.dismiss();
  }

  calculate() {
    const newSpeed = this.selectedLevel?.speedInt;
    if (newSpeed > this.currentSpeed) {
      this.speedChange = 'imediate';
    } else if (newSpeed < this.currentSpeed) {
      this.speedChange = 'scheduled';
    } else if (newSpeed === this.currentSpeed) {
    } else {
      this.modalService.dismissAll();
    }
  }

  splitSpeedString(value: string = '30 Mbps') {
    const current: string = value?.toString();
    if (current?.match('High_Speed_Unlimited')) {
      return 100;
    } else {
      return +value.split('_')[2];
    }
  }

  ngOnDestroy(): void {
    this.selectedLevel;
  }
}
