import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/auth.service';
import { DataLayerService } from '@services/data-layer.service';
import { SSOService } from 'src/app/core/services/sso.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'rain-setting-up-network',
  templateUrl: './setting-up-network.component.html',
  styleUrls: ['./setting-up-network.component.scss']
})
export class SettingUpNetworkComponent implements OnInit {

  constructor( 
    private sso: SSOService,
    private dataLayerService: DataLayerService,
    private authService: AuthenticationService ) { }

  ngOnInit(): void {
  }
  
  openRainGo() {
    return !this.authService.isSignedIn 
      ? window.open(environment.rainGoBaseUrl, '_self') 
      : this.sso.createUrl(environment.rainGoSSOUrl).then((encodedUrl) => {
          window.open(encodedUrl, '_self');
        });
  }

}
