import { UserAddress } from '@services/coverageDetail';
import * as CoverageActions from '../actions/coverage.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export interface CoverageStateModel {
    address: UserAddress;
  }
  
  @State<CoverageStateModel>({
    name: 'CoverageState',
    defaults: {
      address: {
        streetNumber: '',
        streetName: '',
        city: '',
        suburb: '',
        postalCode: ''
      }
    }
  })
  
  @Injectable({ providedIn: 'root' })
  export class CoverageState {
    @Selector()
    static getUserAddress(state: CoverageStateModel) {
      return state.address;
    }
  
    @Action(CoverageActions.SetUserAddress)
    setUserAddress(ctx: StateContext<CoverageStateModel>, action: CoverageActions.SetUserAddress) {
      ctx.patchState({
          address: action.address,
      })
    }
  }
