import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { SetSelectedServiceByID, SetSlectedService } from 'src/app/store/actions/services.actions';
import { ServicesState } from 'src/app/store/state/services.state';

@Component({
  selector: 'rain-geforce-membership-modal',
  templateUrl: './geforce-membership-modal.component.html',
  styleUrls: ['./geforce-membership-modal.component.scss']
})
export class GeforceMembershipModalComponent implements OnInit {
  constructor(private ngModal: NgbModal, public activeModal: NgbActiveModal, private router: Router, private store: Store) {}

  ngOnInit(): void {
  }
  close() {
    this.activeModal.close();
  }

  navigateToMyRain() {
    const nvidiaService = this.store.selectSnapshot(ServicesState.getAllServices).find(s => s.product?.config?.subtype?.toLocaleLowerCase().includes('nvidia'))
    this.store.dispatch(new SetSlectedService(nvidiaService)).toPromise()
      .then(() => {
        this.router.navigate(['/manage-geforce']);
    })
    this.close()
  }
}
