import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { GetAllServices } from 'src/app/store/actions/services.actions';

@Component({
  selector: 'rain-cancel-plan-modal',
  templateUrl: './cancel-plan-modal.component.html',
  styleUrls: ['./cancel-plan-modal.component.scss']
})
export class CancelPlanModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal, private router: Router, private store : Store) {}

  ngOnInit(): void {


  }
  cancelPlan() {
    this.store.dispatch(new GetAllServices())
    this.activeModal.close()
    this.router.navigateByUrl('my-rain');
  }
}
