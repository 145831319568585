import { Pipe, PipeTransform } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

export const validationMessages: ValidationErrors = {
    required: 'This field is required',
    minlength: 'Too Short',
    maxlength: 'Too Long',
    pattern: 'Forbidden Entry',
    email: 'Invalid Entry',
    date: 'Invalid Date',
    notSelected: 'Atleast one option needs to be selected',
    inValid: "You've entered an invalid entry",
    notAlphaNumeric: 'There needs to be atleast 1 number',
};

@Pipe({
    name: 'errorKeys'
})
export class ErrorKeysPipe implements PipeTransform {
    transform(errors: ValidationErrors): string[] {
        if (!errors) {
            return;
        }
        return Object.keys(errors);
    }
}