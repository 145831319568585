<div class="modal-container" *ngIf="ap$ | async as ap" [class]="'is-' + ap">
  <div class="modal-header border-0">
    <div class="modal-img-close-btn z-index-5">
      <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" />
    </div>
  </div>
  <div class="modal-body p-0  z-index-1">
    <div class="header">
      <div class="header-col-left">
        <h2>speed <span class="elevated-text">up</span> your 5G <span *ngIf="isWorkProduct">work</span><span *ngIf="!isWorkProduct"> home</span> wifi</h2>
        <p *ngIf="isSelectable; else infoBlurb">Choose the 5G <span *ngIf="isWorkProduct">work</span> <span *ngIf="!isWorkProduct">home</span> wifi speed you need and add <br>
            it to your monthly subscription for R200 a level.</p>
        <ng-template #infoBlurb>
          <p>
            Once your rain<strong>one</strong> plan has been delivered & activated you can speed up your home<br>
            wifi anytime by adding it to your monthly subscription for R200 a level.
          </p>
        </ng-template>
      </div>
      <div class="header-col-right">
        <!-- <div class="add-to-cart-card" *ngIf="selectedLevel">
                    <div class="content">
                        <div class="body">
                            <div class="top-row">
                                <span>{{selectedLevel.level}}Mbps</span>
                                <span>R{{selectedLevel.cost}}</span>
                            </div>
                            <div class="bottom-row">
                                <span>at R159 per upgrade</span>
                            </div>
                        </div>
                    </div>
                </div> -->
        <div class="add-to-cart-card" *ngIf="selectedLevel">
          <div class="content">
            <div class="header">
              <span>{{ selectedLevel.level }}Mbps</span>
            </div>
            <div class="body">
              <div class="col-left">
                <span *ngIf="selectedLevel?.speedInt != 30 && currentSpeed !== selectedLevel?.speedInt">at R200 per level per month</span>
                <span *ngIf="selectedLevel?.speedInt === 30 && currentSpeed !== selectedLevel?.speedInt">included in rain<b>one</b></span>
                <span *ngIf="currentSpeed === selectedLevel?.speedInt">Your current speed</span>
            </div>
            <div class="col-right">
                <span >{{ selectedLevel.cost > 0 ? 'R'+selectedLevel.cost : 'FREE' }}</span>
            </div>
        </div>
    </div>
        </div>
      </div>
    </div>

    <div class="levels">
      <rain-one-wifi-levels [isSelectable]="isSelectable" [overrideSelectedLevel]="selectedVasIndex" [levels]="levels" (selectedLevel)="captureLevel($event)"></rain-one-wifi-levels>
    </div>
    <div class="actions-footer" [class.show-right]="!isSelectable">
      <button
        *ngIf="isSelectable; else showGotIt"
        (click)="onLevelSelect()"
        [disabled]="!this.selectedLevel || (page!=='service' && selectedLevel && purchaseSpeed.toString() === selectedLevel.id) || (!selectedLevel && purchaseSpeed === 0) || (currentSpeed && currentSpeed === selectedLevel?.speedInt)"
        class="btn btn-sm btn-primary text-white"
      >{{ this.page === 'service' ? 'confirm' : 'add to cart'}}
      </button>
      <ng-template #showGotIt>
        <button (click)="activeModal.close()" class="btn btn-sm btn-primary text-white">got it</button>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #ppModal>
  <ng-container>
    <div class="mb-4 modal-header border-0 ">
      <div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" (click)="dismiss()" src="../../assets/svg/close.svg" />
      </div>
    </div>
    <div class="modal-body border-0 p-5 text-center">
      <div class="p-2">
        <h2 class="text-primary">confirm your speed change</h2>
      </div>
      <div class="p-2 ml-16 mr-4 text-left">
        <b>You have selected <span class="text-primary">{{selectedLevel?.level}}Mbps</span> </b>
        <ul class="speed-list">
            <li *ngIf="selectedLevel?.level === '30'">This is included in your rainone subscription</li>
            <li *ngIf="selectedLevel?.level !== '30'">You will be billed <b>R{{selectedLevel.cost}}</b> month-to-month.</li>
            <li *ngIf="speedChange ==='scheduled'">Your new speed will take effect at your next bill.</li>
            <li *ngIf="speedChange ==='imediate'">Your new speed will be activated immediately with no <br> upfront charge.</li>
        </ul>
        </div>
    </div>

    <div class="p-8 mb-10 text-center">
      <button class="btn btn-sm btn-primary mx-5" (click)="confirmAddonModal()">Confirm</button>
    </div>
  </ng-container>
  
</ng-template>
