<section *ngIf="(ap$ | async) as ap" [class]="'is-'+ ap">

    <div [ngSwitch]="ap" class="levels-wrapper">
        <div *ngSwitchCase="'mobile'">
            <div class="slider-wrapper">
                <swiper-container class="level-wrapper" pagination="true" pagination-clickable="true"
                                  [slidesPerView]="1" #swiper>
                    <swiper-slide *ngFor="let level of levels" class="level-container is-mobile">
                        <div class="level-card" [class.is-selected]="selectedLevelId === level.id"
                             (click)="setSelectedLevel(level.id)">
                            <div class="header" [style.background-color]="originalColors[i]">
                                <span *ngIf="level.id !== '0' else baseHeading">level {{ level.level }}</span>
                                <ng-template #baseHeading>
                                    <span> <span class="light-text">on rain</span>one</span>
                                </ng-template>
                            </div>
                            <div class="body">
                                <ul>
                                    <ng-template ngFor let-feature [ngForOf]="level.features">
                                        <li>
                                            <sup>{{ feature.title }}</sup>
                                            <p [style.color]="'#0077C8'">{{ feature.description }}</p>
                                            <sub [style.color]="'#0077C8'"
                                                 *ngIf="feature.subText">{{ feature.subText }}</sub>
                                        </li>
                                    </ng-template>
                                </ul>
                            </div>

                            <div class="footer">
                                <p *ngIf="level.id !== '0' else baseDescription">
                                </p>

                                <ng-template #baseDescription>
                                    <p [innerHTML]="level.disclaimer"></p>
                                    <div class="base-pricing"><b>FREE</b></div>
                                </ng-template>
                                <div *ngIf="level.id !== '0'" class="level-circle"
                                     [style.background]="originalColors[level.level]">
                                    <span>{{ level.level }}</span>
                                </div>
                            </div>

                            <div class="selected-overlay" *ngIf="isSelectable">
                                <div class="overlay"></div>
                            </div>
                        </div>

                        <ng-container *ngIf="isSelectable">
                            <div class="add-to-cart-card">
                                <div class="content">
                                    <div class="body">
                                        <div class="col-left">
                                            <span>{{ level.level }} levels</span>
                                            <span>at R50 per level</span>
                                        </div>
                                        <div class="col-right">
                                            <span>R{{ level.cost }}.00</span>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="action-lockup">
                                        <button (click)="addLevel(level.id)">add to cart</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </swiper-slide>
                </swiper-container>
            </div>
        </div>
        <div *ngSwitchDefault>
            <ul class="level-wrapper">
                <ng-template ngFor let-level [ngForOf]="levels" let-i="index">
                    <li class="level-container" [attr.selectable]="isSelectable"
                        [class.selected]="level.selected && level.id === selectedLevelId"
                        [class.is-included]="level.includedInSelection">
                        <div class="level-card" (click)="setSelectedLevel(level.id)">
                            <div class="header" [style.background-color]="originalColors[i]">
                                <span *ngIf="level.id !== '0' else baseHeading">level {{ level.level }}</span>
                                <ng-template #baseHeading>
                                    <span> <span class="light-text">rain</span>one</span>
                                </ng-template>
                            </div>

                            <div class="body">
                                <ul>
                                    <ng-template ngFor let-feature [ngForOf]="level.features">
                                        <li>
                                            <sup>{{ feature.title }}</sup>
                                            <p [style.color]="'#0077c8'">{{ feature.description }} <span
                                                    class="span_every_month" [style.color]="'#0077c8'"
                                                    *ngIf="feature.subText">{{ feature.subText }}</span></p>
                                        </li>
                                    </ng-template>
                                </ul>
                            </div>

                            <div class="footer">
                                <p *ngIf="level.id !== '0' && (selectedUiMode$ | async) !=='sme' else baseDescription">
                                </p>
                                <ng-template #baseDescription>
                                    <p [innerHTML]="level.disclaimer"></p>
                                    <div *ngIf="(selectedUiMode$ | async) !=='sme'" class="base-pricing"><b>FREE</b>
                                    </div>
                                    <div *ngIf="level.id == '0'" class="base-pricing"><b>FREE</b></div>
                                </ng-template>
                                <div *ngIf="level.id !== '0'" class="level-circle"
                                     [style.background]="originalColors[level.level]"
                                     [ngClass]="{'sme-levels' : (selectedUiMode$ | async) ==='sme'}">
                                    <span>{{ level.level }}</span>
                                </div>
                            </div>

                            <div class="selected-overlay" *ngIf="isSelectable">
                                <div class="overlay"></div>
                            </div>
                        </div>
                    </li>
                </ng-template>
            </ul>
        </div>
    </div>
</section>