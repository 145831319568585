<div class="cancel-plan-modal-container">
    <div class="close-modal" (click)="activeModal.dismiss('Cross click')">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" />
      </div>
      <br>
      <div class="cancel-plan-modal-header">
        <h2>we're <span class="bold-text">sorry</span>  to see you go</h2>
      </div>
      <div class="cancel-plan-modal-content">
        <p>We've received your cancellation request. Please note that this  cancellation will only be finalized once your free-to-use 5G router has been returned to rain.</p>
      </div>
      <div class="cancel-plan-modal-actions">
        <button (click)="cancelPlan()">got it</button>
      </div>
</div>