import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { services } from './services';
import { AdBlockerComponent } from './components/ad-blocker/ad-blocker.component';
import { EmergencyCommsComponent } from './components/emergency-comms/emergency-comms.component';
import { resolvers } from './resolvers';


@NgModule({
  declarations: [
    AdBlockerComponent,
    EmergencyCommsComponent,
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    ...services,
    ...resolvers
  ],
  exports: [
    EmergencyCommsComponent
  ]
})
export class CoreModule { }
