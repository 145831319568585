<div class="nvidia-error-modal-container">
    <div class="close-modal" (click)="activeModal.dismiss('Cross click')">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" />
    </div>

    <div class="error-modal-header">
        <h2>oops!</h2>
    </div>
    <div class="error-modal-content">
        <p>
            your <span>{{reason}}</span> failed . Please try again
        </p>
    </div>
    <div class="button-container">
        <button-v2 [buttonClass]="'cancel-modal-btn'" [text]="'got it'" (click)="close()"></button-v2>
    </div>
</div>