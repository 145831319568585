import { Selector } from "@ngxs/store";
import { CoreState } from "src/app/core/store/state/core.state";
import { UIState } from "../store/state/ui.state";

export class ImageViewModel {
    @Selector([CoreState.activeBreakpoint])
    static getJoinNowBanner(breakPoint: string): string {        
        return `/assets/images/rain-one/join_now_banner_${breakPoint}.png`
    }
    @Selector([CoreState.activeBreakpoint])
    static getRainOneHomeBanner(breakPoint: string): string {        
        return `/assets/images/rain-one/rain-one-banner-r1-${breakPoint}.jpg`
    }
    @Selector([CoreState.activeBreakpoint])
    static getRainOneBundleBG(breakPoint: string): string {        
        return `/assets/images/rain-one/rain-one-bundle-background-${breakPoint}.png`
    }

    @Selector([CoreState.activeBreakpoint])
    static getJoinNowRightStar(breakPoint: string): string { 
        return `/assets/images/r1-promo/join_now_right_star_${breakPoint}.png`
    }
    @Selector()
    static competitionImageWin(breakPoint: string): string { 
        return `/assets/images/rain-one/five-g-win-win.png`
    }
    @Selector()
    static competitionImageBundle(breakPoint: string): string { 
        return `/assets/images/rain-one/CPE.png`
    }
    @Selector()
    static splashImageBundle(breakPoint: string): string { 
        return `/assets/images/rain-one/desktop-pay-later-splash.jpg`
    }
    @Selector()
    static mobileSimBundleMobi(breakPoint: string): string { 
        return `/assets/images/rain-101/header-banner-mobile-bundle-box-mobi.png`
    }
    @Selector()
    static mobileSimBundle(breakPoint: string): string { 
        return `/assets/images/rain-101/header-banner-mobile-bundle-box.png`
    }
    @Selector()
    static rain101RouterImage(breakPoint: string): string { 
        return `/assets/images/rain-101/rain-101-router-shadow.png`
    }
    @Selector()
    static rain101RouterImageMobi(breakPoint: string): string { 
        return `/assets/images/rain-101/header-banner-rain-101-router-mobi.png`
    }
    
    @Selector([CoreState.activeBreakpoint])
    static getSpeedUpStar(breakPoint: string): string { 
        return `/assets/images/r1-promo/speed_up_star_${breakPoint}.png`
    }

    @Selector([UIState.getSelectedSkinColor])
    static selectedSkinDisplayImage(selectedSkin: string): string { 
        return `/assets/images/rain-101/skin-colors/right-facing-routers/${selectedSkin}.png?v=1`
    }

    // TODO use this when reverting after promo
    @Selector([CoreState.activeBreakpoint])
    static getRainOneBanner(breakPoint: string): string {
        if(breakPoint === 'mobile'){
            return `/assets/images/home-page-carousel/mobile1.svg`
        }
        return `/assets/images/home-page-carousel/image1.svg`
    }
    @Selector()
    static blackFridayStarImage(breakPoint: string): string { 
        return `/assets/images/black-friday/bf-2023-star-1.png`
    }
    @Selector()
    static blackFridayStarImageMobi(breakPoint: string): string { 
        return `/assets/images/black-friday/bf-2023-star-mobi.png`
    }
}
