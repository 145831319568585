import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store, Selector } from '@ngxs/store';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TMMNActions } from '../actions/tmmn-actions';
import { BillingState } from './billing.state';
import { CoreState } from './core.state';
import { DebitAccountPayload } from '../types/debit-account-payload.interface';
import { TMMNService } from '@services/tmmn.service';
import { PAYMENT_STATUS_MAPPING } from '../types/payment-status-mapping.constant';

const getDefaults = (): TMMNStateModel => {
  return {
    paymentAmount: null,
    paymentInProgress: false
  };
};

interface TMMNStateModel {
  paymentAmount: number;
  paymentInProgress: boolean;
}

@State<TMMNStateModel>({
  name: 'TMMNState',
  defaults: getDefaults()
})
@Injectable()
export class TMMNState {
  @Selector()
  static paymentInProgress(state: TMMNStateModel) {
    return state.paymentInProgress;
  }

  constructor(
    public store: Store,
    private _svc: TMMNService
  ) { }

  private readonly _cancel$ = new Subject<void>();

  private getCancelEvent() {
    return this._cancel$.pipe(take(1));
  }

  @Action(TMMNActions.TakeMyMoney)
  TMMNActions(ctx: StateContext<TMMNStateModel>, action: TMMNActions.TakeMyMoney) {
    const { id: customerId } = this.store.selectSnapshot(CoreState.getUserInfoPayload) ?? {};
    if (!customerId) {
      ctx.dispatch(new TMMNActions.TakeMyMoneyFail('No User ID'));
      return;
    }

    ctx.patchState({
      paymentAmount: action.payload,
      paymentInProgress: true
    });
    const { invoiceId, minimumPayment } = this.store.selectSnapshot(BillingState.getUserBillingAccountStatus) ?? {};

    const payload: DebitAccountPayload = {
      invoiceId,
      minAmount: minimumPayment,
      amount: action.payload,
      meta: {}
    };

    const initiatedTime = Date.now();

    return this._svc.debit(customerId, payload).pipe(
      tap({
        next: () => ctx.dispatch(new TMMNActions.TakeMyMoneySuccess(invoiceId, initiatedTime)),
        error: (err: unknown) => ctx.dispatch(new TMMNActions.TakeMyMoneyFail(err))
      }),
      takeUntil(this.getCancelEvent())
    );
  }

  @Action(TMMNActions.TakeMyMoneySuccess)
  DebitAccountSuccess(ctx: StateContext<TMMNStateModel>, action: TMMNActions.TakeMyMoneySuccess) {
    const { invoiceId, initiatedTime } = action;
    return ctx.dispatch([
      new TMMNActions.CheckPaymentStatus(invoiceId, initiatedTime)
    ]);
  }

  @Action(TMMNActions.CheckPaymentStatus)
  CheckPaymentStatus(ctx: StateContext<TMMNStateModel>, action: TMMNActions.CheckPaymentStatus) {
    const { invoiceId, initiatedTime } = action;

    if (!invoiceId) {
      return ctx.dispatch(new TMMNActions.PaymentFailed('No Invoice ID.'));
    }

    return this._svc.pollForPaymentStatus(invoiceId, initiatedTime)
      .pipe(
        tap({
          next: res => {
            const { status } = res?.data ?? {};

            if (!status) {
              return ctx.dispatch(new TMMNActions.PaymentFailed('NO_DATA_MESSAGE'));
            }

            const mappingResult = PAYMENT_STATUS_MAPPING[status];

            if (mappingResult.successful) {
              return ctx.dispatch(new TMMNActions.PaymentSuccessful(mappingResult.status, invoiceId));
            } else {
              return ctx.dispatch(new TMMNActions.PaymentFailed(mappingResult.status));
            }
          },
          error: (e: unknown) => ctx.dispatch(new TMMNActions.TakeMyMoneyFail(e))
        }),
        takeUntil(this.getCancelEvent())
      );
  }

  @Action(TMMNActions.PaymentSuccessful)
  CheckPaymentStatusSuccess(ctx: StateContext<TMMNStateModel>, action: TMMNActions.PaymentSuccessful) {
    const { invoiceId, status } = action;
    const amount = ctx.getState().paymentAmount;
    const message = `Successfully debited account for R${amount}`;
    this._svc.paymentSuccess(invoiceId, status);

    ctx.patchState({
      paymentAmount: null,
      paymentInProgress: false
    });

  }

  @Action(TMMNActions.PaymentFailed)
  CheckPaymentStatusFail(ctx: StateContext<TMMNStateModel>, action: TMMNActions.PaymentFailed) {
    const { status } = action;
    this._svc.paymentFail(status);
    ctx.patchState({
      paymentAmount: null,
      paymentInProgress: false
    });

  }

  @Action(TMMNActions.TakeMyMoneyFail)
  DebitAccountFail(ctx: StateContext<TMMNStateModel>, action: TMMNActions.TakeMyMoneyFail) {
    const { paymentAmount } = ctx.getState();
    const message = `Payment for R${paymentAmount} failed. Please try again`;
    this._svc.paymentError(message);
    ctx.patchState({
      paymentAmount: null,
      paymentInProgress: false
    });
  }

  private onError(ctx: StateContext<TMMNStateModel>, errorMessage: string) {
    ctx.patchState({
      paymentAmount: null,
      paymentInProgress: false
    });
  }
}
