import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {CreateOrder, CreateOrderCheckoutId, SetDeliveryAddress, SetOrder} from "../actions/order.action";
import {OrderServiceV2} from "../service/order.service";
import {tap} from "rxjs/operators";

export interface OrderStateModel {
    order: any;
    orderId: string;
    orderNumber: string;
    orderPrice: number;
    orderCheckoutId: string;
    deliveryAddress: any;
}

@State<OrderStateModel>({
    name: 'orderV2',
    defaults: {
        order: null,
        orderId: '',
        orderNumber: '',
        orderPrice: 0,
        orderCheckoutId: '',
        deliveryAddress: null,
    }
})
@Injectable({
    providedIn: 'root'
})
export class OrderStateV2 {

    constructor(private orderService: OrderServiceV2) {
    }

    @Selector()
    static getOrder(state: OrderStateModel) {
        return state.order;
    }

    @Selector()
    static getDeliveryAddress(state: OrderStateModel) {
        return state.deliveryAddress;
    }

    @Selector()
    static getOrderId(state: OrderStateModel) {
        return state.orderId;
    }

    @Selector()
    static getOrderNumber(state: OrderStateModel) {
        return state.orderNumber;
    }

    @Selector()
    static getOrderPrice(state: OrderStateModel) {
        return state.orderPrice;
    }

    @Selector()
    static getOrderCheckoutId(state: OrderStateModel) {
        return state.orderCheckoutId;
    }

    @Action(SetOrder)
    setOrder(ctx: StateContext<OrderStateModel>, action: SetOrder) {
        return ctx.patchState({
            order: action.order
        });
    }

    @Action(SetDeliveryAddress)
    setDeliveryAddress(ctx: StateContext<OrderStateModel>, action: SetDeliveryAddress) {
        return ctx.patchState({
            deliveryAddress: action.deliveryAddress
        });
    }


    @Action(CreateOrder)
    createOrder(ctx: StateContext<OrderStateModel>, action: CreateOrder) {
        return this.orderService.createOrder().pipe(
            tap((response: any) => {
                ctx.patchState({
                    orderId: response.result.order_id,
                    orderNumber: response.result.number,
                    orderPrice: response.result.total_price
                });
            }));
    }

    @Action(CreateOrderCheckoutId)
    createOrderCheckoutId(ctx: StateContext<OrderStateModel>, action: CreateOrderCheckoutId) {
        let orderId = ctx.getState().orderId;
        let orderNumber = ctx.getState().orderNumber;
        let orderPrice = ctx.getState().orderPrice;
        return this.orderService.getOrderCheckoutId(orderId, orderPrice, orderNumber).pipe(
            tap((response: any) => {
                ctx.patchState({orderCheckoutId: response?.value?.id});
            }));
    }
}