import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { SHOW_ADDRESS_KEY } from '@models/constants';
import { Store } from '@ngxs/store';
import { AuthenticationService } from '@services/auth.service';
import { CacheService } from '@services/cache.service';
import { Observable, of } from 'rxjs';
import { GetBillCycleOptions } from '../store/actions/billing.actions';
import { BillingState } from '../store/state/billing.state';


@Injectable({
	providedIn: 'root'
})
export class SignedInCheckResolver implements Resolve<boolean> {
	constructor(
        private store: Store,
        private route: ActivatedRoute,
        private authSvc: AuthenticationService,
        private cacheService: CacheService,
        private router: Router
    ) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
			return this.isSignedIn()
	}

	private isSignedIn(): Observable<boolean> {
        const isAuthed = this.authSvc.isSignedIn
        if (!isAuthed) {
            this.cacheService.set(SHOW_ADDRESS_KEY, 'true');
            this.router.navigate(['/register', 'cart']);
            return of(false);
          }

          return of(true);
	}




}
