import { ServiceObject, ServiceStatuses } from "@models/serviceDetail";
import { RAIN_ONE_BASE_IDS } from "@models/constants";
import { Store } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { ProductState } from "src/app/store/state/product.state";
import { RainOneProduct } from "src/app/core/interfaces/rain-one-products.interface";


@Injectable({
    providedIn: 'root'
})

export class TransformDataServiceV2 {

    services: any[]
    products: RainOneProduct[]

    constructor(private store: Store) { }

    public inputServicesDataV2(services: ServiceObject[]) {
        this.services = services;
        this.products = this.store.selectSnapshot(ProductState?.GetCatelogueProducts);
        this.services.forEach(service => {
            service.productName = this.products?.find(product => product?.id === service?.product_id)?.name;
        });
        return this;
    }

    public filterActiveAndSuspendedServicesV2() {
        const suspendedFiveGPrepaid = this.services?.filter(
            service =>
              service?.status === 500 ||
              (service?.status === 400 && service?.bucket?.name.includes('5G-Prepaid') && service?.bucket?.description.includes('ACTIVE'))
          );
        this.services = this.services?.filter((service) => service?.status === ServiceStatuses?.Active || service?.status === ServiceStatuses?.Suspended || suspendedFiveGPrepaid);
        this.services = this.services.sort((a, b) => a?.sim_name?.localeCompare(b?.sim_name));
        return this;
    }

    public appendRainOneDetailsV2() {
        const userHasRainOneProduct = this.services?.find((service) =>
        (service?.productName?.toLowerCase().includes('rainone') ||
            service?.productName?.toLowerCase().includes('sme'))
        );

        this.services = this.services?.map((service) => {

            const displayName = this.products?.find(product => product?.id === service?.product_id)?.name;
            const isRainOne = service?.productName?.toLowerCase()?.includes('rainone') || service?.productName?.toLowerCase()?.includes('level');
            const canMigrate = !!(!service?.pending_migration &&
                this.products?.find(product => product?.id === service?.product_id)?.config?.migration?.find((config) => RAIN_ONE_BASE_IDS.includes(config?.id)) &&
                !userHasRainOneProduct);

            return { ...service, displayName, isRainOne, canMigrate };
        });

        return this;
    }

    public appendProductDetailV2() {
        this.services?.forEach((service) => {
            service.product = this.products?.find(product => product?.id === service?.product_id);
        })
        return this;
    }

}
