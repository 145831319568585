import {ToastrService} from "ngx-toastr";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private toastr: ToastrService) {
    }

    showToast(message: string, type: string) {
        switch (type) {
            case 'success':
                this.toastr.success(message);
                break;
            case 'error':
                this.toastr.error(message);
                break;
            case 'info':
                this.toastr.info(message);
                break;
            case 'warning':
                this.toastr.warning(message);
                break;
            default:
                console.error('Unsupported toast type:', type);
        }
    }
}
