import {DOCUMENT} from '@angular/common';
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {RainOneLevel} from 'src/app/core/interfaces/rain-one-level.interface';
import {CoreState} from 'src/app/core/store/state/core.state';
import {UIState} from '../../store/state/ui.state';
import Swiper from 'swiper';

@Component({
    selector: 'rain-one-levels',
    templateUrl: './rain-one-levels.component.html',
    styleUrls: ['./rain-one-levels.component.scss']
})
export class RainOneLevelsComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @Input() purchaseJourney: boolean = false;
    @Input() isSelectable: boolean = false;
    @Input() levels: Array<RainOneLevel>;
    @Output() selectedLevel: EventEmitter<RainOneLevel> = new EventEmitter(null);
    @Select(CoreState.activeBreakpoint) public ap$: Observable<string>;
    @Select(UIState.getColorPalette) public colors$: Observable<string[]>;
    @Select(UIState.GetUIMode) selectedUiMode$: Observable<string>;

    @Input() selectedLevelId?: string = '1'
    @ViewChild('swiper') swiperRef: ElementRef;
    swiper: Swiper;

    slideConfig;

    public originalColors = {
        0: '#0077C8',
        1: '#3392d3',
        2: '#3392d3',
        3: '#1985ce',
        4: '#0077c8',
        5: '#006bb4'
    }

    constructor(@Inject(DOCUMENT) private document: Document, private store: Store, private cdref: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.swiper = this.swiperRef?.nativeElement.swiper;
        this.setSelectedLevel('0');
        if (this.swiper) {

            this.swiper.on('activeIndexChange', (res) => {
                this.setSelectedLevel(String(res?.activeIndex))
            })
        }
    }

    ngAfterViewChecked(): void {
        this.cdref.detectChanges()
    }

    public setSelectedLevel(id: string) {
        if (!this.isSelectable) return;

        this.levels = this.levels.map(l => {
            l.selected = l?.id === id;
            l.includedInSelection = l?.id < id;
            return l;
        });

        const selectedLevel = this.levels?.find(l => l.id === id);

        this.selectedLevelId = selectedLevel?.id

        return this.selectedLevel.emit(selectedLevel);
    }

    public addLevel(id: string) {

        const selectedLevel = this.levels?.find(l => l.id === id);

        return this.selectedLevel.emit(selectedLevel);
    }

}