import { Utils } from "src/app/utils";

export class FetchClickToClaim {
  static type = Utils.Helpers.Type('[CLICK TO CLAIM] Fetch Click To Claim');
}

export class FetchClickToClaimSuccess {
  static type = Utils.Helpers.Type('[CLICK TO CLAIM] Fetch Click To Claim Success');
  constructor(public payload: any) { }
}   

export class FetchClickToClaimFailure {
  static type = Utils.Helpers.Type('[CLICK TO CLAIM] Fetch Click To Claim Failure');
  constructor(public payload: any) { }
}