import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-callback-request',
  templateUrl: './callback-request.component.html',
  styleUrls: ['./callback-request.component.scss']
})
export class CallbackRequestComponent implements OnInit {
  product: any;
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  ok() {
    this.modalService.dismissAll();
  }

}
