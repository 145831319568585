<section>
  <div class=" " id="intro-text">
   
    <div class="text-center mt-40 mb-50" *ngIf="loading">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="d-flex px-5 justify-content-center" *ngIf="!loading">
      <div class="accordion faq-width" [id]="'accordion2'">
        <div class="card text-left bg-transparent border-0" *ngFor="let item of supportQuestions; let i = index">
          <a class=" border-bottom py-5 card-header bg-transparent medium-font-size m-0 text-primary p-0 text-left "
            [id]="'heading' + i" data-toggle="collapse" [attr.data-text]="item.dataText" data-id="link-click"
            [attr.data-target]="'#collapse2' + i" aria-expanded="false" [attr.aria-controls]="'collapse2' + i">
            {{ item.name }}
          </a>
          <div class="icon"></div>

          <div [id]="'collapse2' + i" class="collapse " [attr.aria-labelledby]="'heading' + i"
            [attr.data-parent]="'#accordion2'">
            <div class="card-body p-0 pt-8">
              <markdown class="medium-font-size cut pt-2" id="markdown-block"><span [innerHTML]="getDescription(item)"></span></markdown>

              <!-- <button *ngIf="item.path" (click)="openSupportItem(item)"
                class="btn btn-primary ml-auto d-block my-5 btn-sm">
                {{ item.pathTitle }}
              </button>

              <button *ngIf="item.specialCase === 'openCoverageMap'" (click)="openCoverageMap()"
                class="btn btn-primary ml-auto d-block my-5 btn-sm">
                {{ item.pathTitle }}
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</section>