import { ServiceObject } from "@models/serviceDetail";

export function groupServices(services: ServiceObject[]) {
	if (!services?.length) {
		return []
	}
	
	const groups: any[][] = [];
	const parentMap = new Map<string, any>();
	const childMap = new Map<string, any[]>();

	services.forEach(service => {
		// If the service is a parent (no parent_service_id), add to parentMap
		if (service.parent_service_id === null || service?.type?.toLowerCase() === "assigned") {
			parentMap.set(service.id, service);
		} else {
			// If the service is a child, add to childMap under its parent_service_id
			if (!childMap.has(service.parent_service_id) && service?.type?.toLowerCase() !== "assigned") {
				childMap.set(service.parent_service_id, []);
			}
			childMap.get(service.parent_service_id).push(service);
		}
	});

	let legacyGroup = [];
	let mobileGroup = [];
	let mobileProductId = '1174f6df-0dbd-4ad7-ad9f-0da20ce866fb';

	parentMap.forEach((parentService, parentId) => {
		const group = [parentService];
		const children = childMap.get(parentId) || [];

		group.push(...children);
		if (parentService.product.id != mobileProductId && !parentService?.product?.config?.legacy) {
			groups.push(group);
		} else if (parentService.product.id == mobileProductId) {
			mobileGroup.push({
				...group[0],
				mobile: true
			});
		} else {
			legacyGroup.push({
				...group[0],
				legacy: true
			});
		}
	});

	return [...groups, legacyGroup, mobileGroup];
	
}