import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

@Component({
  selector: 'app-ts-andcs-modal',
  templateUrl: './ts-andcs-modal.component.html',
  styleUrls: ['./ts-andcs-modal.component.scss']
})
export class TsAndCsComponent {

  @Input() pdfSrc: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

}
