import { Utils } from "src/app/utils";
import { UIMode } from "../state/ui.state";


export namespace UIActions {

    export class ToggleValue {
        static readonly type = Utils.Helpers.Type('[UI: Toggle] Toggle value returns true or false');
        constructor(public readonly show: boolean) { }
    }
    export class SetSelectedSkinColor {
        static readonly type = Utils.Helpers.Type('[UI: Select] Set Selected Router Skin Color');
        constructor(public readonly skin: string) { }
    }
    export class SetSelectedColor {
        static readonly type = Utils.Helpers.Type('[UI: Select] Set Selected Router Color');
        constructor(public readonly color: string) { }
    }

    export class SwitchUIMode {
        static readonly type = Utils.Helpers.Type('[UI: Mode] Switch UI mode');
        constructor(public readonly payload: {mode: UIMode}) { }
    }
}
export class CloseModal {
    static readonly type = Utils.Helpers.Type('[UI: Modal] Modal closed');
    constructor(public readonly payload: {name: string}) { }
}

