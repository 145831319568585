import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'rain-rain-one-product',
  templateUrl: './rain-one-product.component.html',
  styleUrls: ['./rain-one-product.component.scss']
})
export class RainOneProductComponent implements OnInit {
  @Input() product: any = null;
  @Input() name: any;
  @Input() showBoost = false;
  @Select(CoreState.activeBreakpoint) ap$: Observable<string>;



  constructor() { }

  ngOnInit(): void {

  }

}

 





