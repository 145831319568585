import { NgModule } from '@angular/core';
import { OurNetworkComponent } from './our-network.component';
import { CheckCoverageModule} from '../check-coverage/check-coverage.module';
import { OurNetworkRoutingModule } from './our-network.routing';
import { MapModule } from '@components/map/map.module';
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        OurNetworkComponent
    ],
    imports: [
        CheckCoverageModule,
        OurNetworkRoutingModule,
        MapModule,
        AgmCoreModule,
        CommonModule
    ],
    exports: [
        OurNetworkComponent
    ]
})
export class OurNetworkModule {}