import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Select, Selector, Store } from '@ngxs/store';
import { AuthenticationService } from '@services/auth.service';
import { Observable, Subject } from 'rxjs';
import { RainOneProduct } from 'src/app/core/interfaces/rain-one-products.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  ChangeNvidiaTier,
  SetOrderType,
  StartNvidiaPurchase,
  StartNvidiaPurchaseWith5GMigrations,
  StartRainOnePurchase
} from 'src/app/store/actions/cart.action';
import { MigrateNvidia } from 'src/app/store/actions/services.actions';
import { OrderState } from 'src/app/store/state/order.state';
import { ProductState } from 'src/app/store/state/product.state';
import { ServicesState } from 'src/app/store/state/services.state';
import Swiper from 'swiper';
import { GeforceMembershipModalComponent } from './geforce-membership-modal/geforce-membership-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GeforceOrderModalComponent } from './geforce-order-modal/geforce-order-modal.component';
import { logging } from 'protractor';
import { NvidiaUpsellModalComponent } from './nvidia-upsell-modal/nvidia-upsell-modal.component';
import { WebsiteconfigService } from 'src/app/core/services/websiteconfig.service';
import { map, takeUntil } from 'rxjs/operators';
import { FiveGMigration, Memberships, NvidiaPurchaseStarted, SetSelectedNvidiaTier } from 'src/app/store/actions/nvidia.actions';
import { NvidiaState } from 'src/app/store/state/nvidia.state';
import { BillingState } from 'src/app/core/store/state/billing.state';
import { CartService } from '@services/cart.service';
import { CONFIG_COMMENTS, CONFIG_FRIENDLY_NAME } from '@models/constants';
import { FiveGSelectionModalComponent } from '@pages/my-rain/my-rain-content/modals/five-g-selection-modal/five-g-selection-modal.component';
import { Router } from '@angular/router';
import { IServiceDetail } from '@models/serviceDetail';
import { NvidiaService } from '@services/nvidia.service';
import { NvidiaSoldOutModalComponent } from './nvidia-sold-out-modal/nvidia-sold-out-modal.component';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { FestiveBillingModalComponent } from '@pages/service-page/festive-billing-modal/festive-billing-modal.component';
import { RainFlags } from 'src/app/core/interfaces/rain-flags.interface';

interface GeforceMembershipOptionsViewModel {
  activeBreakpoint: string;
  nvidiaItems: RainOneProduct[];
  goodAccountStanding: boolean;
  isSignedIn: boolean;
  canPurchaseNvidiaWithRainOne: boolean;
  canPurchaseNvidiaOnly: boolean;
}

class GeforceMembershipOptionsSelector {
  @Selector([
    CoreState.activeBreakpoint,
    NvidiaState.getPriorityStatus,
    BillingState.goodAccountStanding,
    CoreState.isAuthenticated,
    ServicesState.CanPurchaseNvidiaWithRainOne,
    ServicesState.CanPurchaseNvidiaOnly
  ])
  static getViewModel(
    activeBreakpoint: string,
    nvidiaItems: RainOneProduct[],
    goodAccountStanding: boolean,
    isSignedIn: boolean,
    canPurchaseRainNvidia: boolean,
    canPurchaseNonRainNvidia: boolean
  ) {
    const cantPurchaseNvidiaWithRainOne = isSignedIn && (!canPurchaseRainNvidia || !goodAccountStanding);
    const cantPurchaseNvidiaOnly = isSignedIn && (!canPurchaseNonRainNvidia || !goodAccountStanding);
    return {
      activeBreakpoint,
      nvidiaItems,
      goodAccountStanding,
      isSignedIn,
      cantPurchaseNvidiaWithRainOne,
      cantPurchaseNvidiaOnly
    };
  }
}

@Component({
  selector: 'rain-geforce-membership-options',
  templateUrl: './geforce-membership-options.component.html',
  styleUrls: ['./geforce-membership-options.component.scss']
})
export class GeforceMembershipOptionsComponent implements OnInit, OnDestroy {
  @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
  paymentDate = this.store.selectSnapshot(BillingState.getSelectedBillCycle)?.start_day;

  @Input('location') location: string;
  @Input() currentProduct;
  @Input() tiers: Array<RainOneProduct>;
  @Input() pendingAction: boolean;

  @Select(GeforceMembershipOptionsSelector.getViewModel) viewModel$: Observable<GeforceMembershipOptionsViewModel>;
  @ViewChild('swiper') swiperRef: ElementRef;

  canMigrate = false;
  swiper: Swiper;
  currentTier;
  isInArrears;
  rainOneL1Product: RainOneProduct;
  ngDestroy: Subject<any> = new Subject();
  private unsubscribe$: Subject<void> = new Subject<void>();
  twentyFifthDowngrades: boolean;
  firstDowngrades :boolean;

  constructor(
    private store: Store,
    private authSVC: AuthenticationService,
    private modalService: NgbModal,
    private nvSVC: NvidiaService
  ) {}

  ngOnInit(): void {
    this.isInArrears = this.store.selectSnapshot(BillingState.isInArrears);
    this.store.dispatch(new Memberships());
    this.store.selectSnapshot(OrderState.GetallNonCancelledOrders);
    this.canMigrate = this.currentProduct?.config?.tier === 'ultra' ? true : false;
    this.flags$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((flags: RainFlags) => {
      const twentyFifthDowngrades: boolean = flags?.twenty_fifth_downgrade_disabled;
      const firstDowngrades :boolean = flags?.first_downgrade_disabled;
      this.twentyFifthDowngrades = twentyFifthDowngrades;
      this.firstDowngrades= firstDowngrades;
    });
  }

  ngAfterViewInit() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  goPrev() {
    if (!this.swiper) this.swiper = this.swiperRef?.nativeElement.swiper;
    this.swiper.slidePrev();
  }

  goNext() {
    if (!this.swiper) this.swiper = this.swiperRef?.nativeElement.swiper;

    this.swiper.slideNext();
  }

  checkSelected(currentTierOption) {
    if (this.location === 'manage') {
      return this.currentProduct.name.includes(currentTierOption.name) ? true : false;
    }
    return false;
  }

  openModal() {
    this.openCantPurchaseNvidiaModal(NvidiaSoldOutModalComponent);
  }

  getRainPrice(rainNvidia: RainOneProduct) {
    const rainCustomerNvidia = this.store
      .selectSnapshot(ProductState.GetAllRainNvidiaProductsByType(rainNvidia?.config?.paymentType))
      .find(p => p?.config?.tier?.includes(rainNvidia?.config?.tier));
    const discountedPrice = rainCustomerNvidia?.price > 1 ? rainCustomerNvidia?.price * (80 / 100) : rainCustomerNvidia?.price;
    const discountedItem: RainOneProduct = {
      ...rainCustomerNvidia,
      price: discountedPrice,
      recurringPrice: discountedPrice
    };
    return discountedItem;
  }

  getNonRainPrice(rainNvidia: RainOneProduct) {
    const rainCustomerNvidia = this.store
      .selectSnapshot(ProductState.GetAllPublicNvidiaProductsByType(rainNvidia?.config?.paymentType))
      .find(p => p?.config?.tier?.includes(rainNvidia?.config?.tier));
    return rainCustomerNvidia;
  }

  public startNonRainNvidiaPurchaseJourney(nonRainTier: RainOneProduct) {
    const signedIn = this.store.selectSnapshot(CoreState.isAuthenticated);
    const canPurchaseNvidiaOnly = this.store.selectSnapshot(ServicesState.CanPurchaseNvidiaOnly);
    const hasRainOne = this.store.selectSnapshot(ServicesState.hasActiveRainOne5GService);

    if (!this.canPurchaseNvidia()) {
      return;
    }

    if (signedIn && !canPurchaseNvidiaOnly) return false;

    if (hasRainOne) {
      return this.store.dispatch(new StartNvidiaPurchase(this.getRainPrice(nonRainTier)?.id));
    }

    return this.store.dispatch(new StartNvidiaPurchase(nonRainTier?.id));
  }

  public startNvidiaWithRainOnePurchaseJourney(tier: RainOneProduct) {
    const signedIn = this.store.selectSnapshot(CoreState.isAuthenticated);
    const canPurchaseNvidiaWithRainOne = this.store.selectSnapshot(ServicesState.CanPurchaseNvidiaWithRainOne);
    // if (!this.canPurchaseNvidia()) {
    //   this.modalService.open(GeforceOrderModalComponent, {
    //     centered: true,
    //     size: 'sm',
    //     windowClass: 'slideInUp d-flex'
    //   });
    //   return;
    // }

    if (signedIn && !canPurchaseNvidiaWithRainOne) return false;

    if (this.isMigratableLegacy5GUser()) {
      // this.migratableLegacy5GUserPurchaseFlow(tier);
      this.store.dispatch(new SetOrderType('nvidia_with_5G_purchase'));
      this.store.dispatch([new StartNvidiaPurchase(tier?.id), new NvidiaPurchaseStarted(true)]);
      return;
    }

    this.store.dispatch([new StartNvidiaPurchase(tier?.id, true), new NvidiaPurchaseStarted(true)]);
    setTimeout(() => {
      this.store.dispatch(new SetOrderType('multi'));
    }, 500);
    return;
  }

  public migrateNvidiaTier(tier: RainOneProduct) {
    const goodAccountStanding = this.store.selectSnapshot(BillingState.goodAccountStanding);
    if (this.isInArrears || !goodAccountStanding) {
      return false;
    } else {
      const nvidiaService = this.store
        .selectSnapshot(ServicesState.getAllServices)
        .find(s => s.productName.toLocaleLowerCase().includes('nvidia')) as IServiceDetail;
      const nvidiaProduct = this.store.selectSnapshot(ProductState?.GetNvidiaProductById(nvidiaService?.productId)) ?? ({} as RainOneProduct);
      const canMigrate = nvidiaProduct?.config?.migration && !nvidiaService?.pending_migration && !nvidiaService?.scheduledAction;
      const selectedTier = nvidiaProduct?.config?.rainCustomer ? this.getRainPrice(nvidiaProduct) : tier;

      if (canMigrate) {
        this.store.dispatch(new MigrateNvidia(selectedTier));
      }
    }
  }

  private hasOpenOrders() {
    const isSignedIn = this.authSVC.isSignedIn;

    if (!isSignedIn) return false;

    const allOrders = this.store.selectSnapshot(OrderState.GetallNonCancelledOrders);
    if (allOrders && allOrders.length > 0) {
      const hasActiveNvidiaOrder = [];
      allOrders.forEach(o => {
        o.items.forEach(i => {
          const product = this.store.selectSnapshot(ProductState.GetNvidiaProductById(i.productId));

          if (product && product.config.subtype === 'nvidia') {
            hasActiveNvidiaOrder.push(o);
          }
        });
      });
      return Boolean(hasActiveNvidiaOrder.length > 0);
    }
    return false;
  }

  private canPurchaseNvidia() {
    const poorAccountStanding = this.store.selectSnapshot(CoreState.isAuthenticated) && !this.store.selectSnapshot(BillingState.goodAccountStanding);
    const allService = this.store.selectSnapshot(ServicesState.getAllServices);
    const hasActiveNvidiaService = allService.find(s => s.product?.config?.subtype?.toLocaleLowerCase().includes('nvidia'));

    if (hasActiveNvidiaService) {
      this.openCantPurchaseNvidiaModal(GeforceMembershipModalComponent);
      return false;
    }
    if (this.hasOpenOrders()) {
      this.openCantPurchaseNvidiaModal(GeforceOrderModalComponent);
      return false;
    }

    if (this.isInArrears || poorAccountStanding) return false;
    return true;
  }

  private openCantPurchaseNvidiaModal(modal) {
    this.modalService.open(modal, {
      centered: true,
      size: 'sm',
      windowClass: 'slideInUp d-flex'
    });
    return;
  }

  private isMigratableLegacy5GUser() {
    const hasMigratable5GServices = this.store.selectSnapshot(ServicesState.hasActiveMigratableLegacy5GService);
    const hasRainOne = this.store.selectSnapshot(ServicesState.hasActiveRainOne5GService);
    return !hasRainOne && hasMigratable5GServices;
  }

  private migratableLegacy5GUserPurchaseFlow(tier: RainOneProduct) {
    const rainTier = this.getRainPrice(tier);
    const nonRainTier = this.getNonRainPrice(tier);
    // const hasPendingMigration = this.store.selectSnapshot(ServicesState.getActiveLegacy5GServices)?.find((service: IServiceDetail) => { return service?.pending_migration })

    // if (!hasPendingMigration) {
    //   return this.open5GUpsellModal(rainTier, nonRainTier)
    // }
    return this.store.dispatch(new StartNvidiaPurchaseWith5GMigrations(nonRainTier?.id));
  }

  private open5GUpsellModal(rainTier: RainOneProduct, nonRainTier: RainOneProduct) {
    const modalRef = this.modalService.open(NvidiaUpsellModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'slideInUp d-flex'
    });
    modalRef.componentInstance.tier = rainTier;
    modalRef.componentInstance.nrTier = nonRainTier;
    return;
  }

  //TODO : Fix api call for migrations
  startNvidiaPlanJourney(tier: RainOneProduct) {
    let services = this.store.selectSnapshot(ServicesState.getAllServices);
    let nvidiaServices = services.find(svc => svc.name.includes('Nvidia'));
    const currentTier = this.currentProduct.config.tier;
    if (currentTier === 'ultra' && (this.twentyFifthDowngrades && this.paymentDate === 25) || (this.firstDowngrades && this.paymentDate === 1)) {
      let modalRef: NgbModalRef;
      const isDowngrade = true;
        modalRef = this.modalService.open(FestiveBillingModalComponent, {
          centered: true,
          size: 'sm',
          windowClass: 'slideInUp d-flex'
        });
        modalRef.componentInstance.isDowngrade = isDowngrade;
        return;
    }
    else if (currentTier === 'ultra'){
      this.nvSVC.migrateNvidiaService(nvidiaServices.id, { product_id: tier.id }).subscribe(
        res => {
          this.nvSVC.migrateModal(this.currentProduct.name, tier.name);
        },
        error => {
          console.error('Error migrating Nvidia service:', error);
        }
      );
    }
  }

  ngOnDestroy() {
    this.ngDestroy.next(null);
    this.ngDestroy.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
