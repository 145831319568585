<div class="accordion-wrapper">
    <div class="header" (click)="_isOpen = !_isOpen">
        <span>{{title}}</span>

        <i [class.is-open]="_isOpen">
            <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.54001 1.22102L0.178331 7.25008C-0.0698319 7.51761 -0.0576007 7.92166 0.206337 8.17524C0.470275 8.42882 0.896001 8.44554 1.18113 8.21352L6.12484 3.52357L7.05099 2.64497L7.75358 3.33573L12.8634 8.18322C13.146 8.44413 13.5963 8.44413 13.8789 8.18322C14.0143 8.0558 14.0904 7.8824 14.0904 7.7015C14.0904 7.52061 14.0143 7.3472 13.8789 7.21978L7.52364 1.16042C7.23333 0.922438 6.79545 0.949412 6.54001 1.22102Z"
                    fill="#979797" />
            </svg>
        </i>
    </div>
    <div class="body" [class.is-open]="_isOpen">
        <ng-content></ng-content>
    </div>
</div>