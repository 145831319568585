<div class="form-group" [class.mark-valid]="markValid">
    <div [class]="'rain-form-row ' + display + ' ' + type" [class.has-errors]="checkForErrors()">
      <div class="field-container" [class.is-focused]="isFocused || control?.value">
        <label [for]="formatLabel(label)" class="label" (click)="onLabelClick()" *ngIf="showLabel">{{ label }}</label>
        <input *ngIf="autocomplete !== 'off' else noAutocomplete" (keydown.enter)="onKeyPress($event)"
          (keyup)="KeyUp($event)" [name]="name ? name : formatLabel(label)" (focus)="isFocused = true" (blur)="onBlur()"
          [id]="formatLabel(label)" [type]="type" [formControl]="control" [type]="type" [minlength]="minlength" [maxlength]="maxlength"
          [autocomplete]="autocomplete" class="input py-2 medium-font-size" [placeholder]="placeholder"
          [ngClass]="{ input: label !== 'password', passwordInput: label === 'password' , blue: blueText}" />
  
        <ng-template #noAutocomplete>
          <input (keydown.enter)="onKeyPress($event)" (keyup)="KeyUp($event)" [name]="name ? name : formatLabel(label)"
            (focus)="isFocused = true" (blur)="onBlur()" autocomplete="off" [id]="formatLabel(label)" [type]="type"
            [formControl]="control" [type]="type" [maxlength]="maxlength" class="input py-2 medium-font-size"
            [ngClass]="{ input: label !== 'password', passwordInput: label === 'password',blue: blueText }" readonly
            onfocus="this.removeAttribute('readonly');" [placeholder]="placeholder" />
  
        </ng-template>
      </div>
      <div class="form-errors">
        <ul>
          <li *ngFor="let error of checkForErrors() | errorKeys">
            <p class="text-danger small-font-size">{{ messages[error] }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>