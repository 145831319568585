import * as PreCancellationsActions from '../actions/PreCancellations.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { MigrateToRainOneService } from '@services/migrate-to-rain-one.service';
import { Observable } from 'rxjs';
import { CancellationsRequest } from '@pages/service-page/plan-cancellation-page/cancellations.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessErrorModalComponent } from '@pages/service-page/service-actions/success-error-modal/success-error-modal.component';
import { state } from '@angular/animations';
import { CanPreCancel, ReversedPreCancellation } from '../actions/PreCancellations.actions';
import { CancelPlanModalComponent } from '@pages/service-page/cancel-plan-modal/cancel-plan-modal.component';


export interface PreCancellationsStateModel {
    cancellationDate: Date;
    cancellationsPayload: CancellationsRequest,
    serviceId: string;
    actionType: string,
    reversedCancellation: boolean,
    canPreCancel : boolean
}

@State<PreCancellationsStateModel>({
    name: 'PreCancellationsState',
    defaults: {
        cancellationDate: null,
        cancellationsPayload: {
            serviceId: '',
            fulfillmentType: null,
            collection_address: null,
            comments : null,
            reason : null,
            contact_number : '',
            type: ''
        },
        serviceId: '',
        actionType: '',
        reversedCancellation: false,
        canPreCancel : false
    }
})

@Injectable({ providedIn: 'root' })
export class PreCancellationsState {
    constructor(
        private migrationService: MigrateToRainOneService,
        private modalService: NgbModal
    ) { }

    @Selector()
    static getActionType(state: PreCancellationsStateModel) {
        return state.actionType;
    }
    @Selector()
    static CanPreCancel(state: PreCancellationsStateModel) {
        return state.canPreCancel;
    }

    @Selector()
    static scheduledCancellation(state: PreCancellationsStateModel) {
        return state.actionType.toLocaleLowerCase() === 'cancellation';
    }
    @Selector()
    static preCancellationReversed(state: PreCancellationsStateModel) {
        return state.reversedCancellation;
    }
    @Selector()
    static getCancellationDate(state: PreCancellationsStateModel) {
        return state.cancellationDate;
    } @Selector()
    static getCancellationsDetails(state: PreCancellationsStateModel) {
        return state.cancellationsPayload;
    }
    @Selector()
    static getServiceId(state: PreCancellationsStateModel) {
        return state.serviceId;
    }
    @Action(PreCancellationsActions.PreCancellationsServiceId)
    setServiceId(ctx: StateContext<PreCancellationsStateModel>, action: PreCancellationsActions.PreCancellationsServiceId) {
        ctx.patchState({
            serviceId: action.serviceId
        });
    }

    @Action(PreCancellationsActions.GetPreCancellationsDate)
    getCancellationDate(ctx: StateContext<PreCancellationsStateModel>, action: PreCancellationsActions.GetPreCancellationsDate): Observable<any> {
        return this.migrationService.rainOnePreCancellationsDate(action.serviceId).pipe(
            tap({
                next: res => {
                    ctx.patchState({
                        cancellationDate: res.cancellationDate
                    });
                },
                error: err => {
                    console.log('No Date');
                }
            })
        );
    }
    @Action(PreCancellationsActions.PreCancelRainOneService)
    preCancelRainOneService(ctx: StateContext<PreCancellationsStateModel>, action: PreCancellationsActions.PreCancelRainOneService): Observable<any> {
        return this.migrationService.preCancelRainOneService(action.cancellationsPayload).pipe(
            tap({
                next: res => {
                    ctx.patchState({
                        cancellationsPayload: action.cancellationsPayload,
                        actionType: res?.action_type
                    });
                    this.modalService.open(CancelPlanModalComponent, {
                        centered: true,
                        size: 'sm',
                        windowClass: 'slideInUp d-flex'
                      }
                      );
                },
                error: err => {
                    const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
                    modalRef.componentInstance.data ={
                        title : "oops",
                        description : 'your cancellation request failed , please try again.',
                        buttonName : 'got it',
                    }
                }
            })
        );
    }

    @Action(PreCancellationsActions.ReversedPreCancellation)
    SetServiceType(ctx: StateContext<PreCancellationsStateModel>, action: ReversedPreCancellation) {
      ctx.patchState({
        reversedCancellation : action.reversedCancellation
      });
    }

    @Action(PreCancellationsActions.CanPreCancel)
    CanPreCancel(ctx: StateContext<PreCancellationsStateModel>, action: CanPreCancel) {
      ctx.patchState({
        canPreCancel : action.canPreCancel
      });
    }
  

    @Action(PreCancellationsActions.RevokePreCancellation)
    revokePrecancellation(ctx: StateContext<PreCancellationsStateModel>, action: PreCancellationsActions.RevokePreCancellation): Observable<any> {
        return this.migrationService.revokeRainOnePrecancellation(action.serviceId)
        .pipe(
            tap({
                next: res => {
                    ctx.patchState({
                        actionType: 'none',
                    });
                    const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
                    modalRef.componentInstance.data ={
                        title : "hey it's good to have you back",
                        description : 'Your requested cancellation will be reversed right now.',
                        buttonName : 'got it',
                    }
                },
                error: err => {
                    const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
                    modalRef.componentInstance.data ={
                        title : "oops",
                        description : 'your cancellation reversal failed , please try again.',
                        buttonName : 'got it',
                    }

                }
            })
        );
    }

}
