import { IUserDetail } from "@models/userDetail";
import { Utils } from "src/app/utils";
import { SSOPayload } from "../../interfaces/sso.interface";
import { AxiomDigitalIdentityUserDetail } from "src/app/shared/interfaces/axiom/axiom.interfaces";


export class GetUserProfile {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Get user profile');
}

export class GetUserProfileSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Get user profile success');
    constructor(public readonly payload: IUserDetail) { }
}

export class GetUserProfileFail {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Get user profile fail');
    constructor(public readonly payload: any) { }
}



export class SetRainDeliveryTme {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Get Delivery Configs');
    constructor(public readonly deliveryTime: number) { }
}

export class AssignHasPrepaidFlag {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Assign hasPrepaid Flag');
    constructor(public readonly payload?: boolean) { }
}

export class AssignTokens {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Assign IDM Token');
    constructor(public readonly payload: { idm: string, auth: string }) { }
}

export class ClearProfile {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Clear user profile');
}

export class UpdateUserPhoneDetails {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Update user phone details');
    constructor(public readonly payload: string) { }
}

export class SSOSignIn {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Sign in user via sso');
    constructor(public readonly payload: SSOPayload) { }
}

export class UserRole {
    static type = Utils.Helpers.Type('[Core: Auth] Set user role');
    constructor(public payload: string) { }
}

export class SetTokens {
    static type = Utils.Helpers.Type('[Auth] Set user token credentials ');
    constructor(public  token?: string , public tokenCredentials? : string , public tokenSme? : string) { }
}

export class AssignAxiomUser {
    static type = Utils.Helpers.Type('[Auth] Assign axiom user');
    constructor(public payload: AxiomDigitalIdentityUserDetail) { }
}

export class StoreSSOReferrerObject {
    static type = Utils.Helpers.Type('[Auth] store referrer object from sso url params for context of last site');
    constructor(public referrer: {[key: string]: string}) { }
}

export class ClearTokens {  
    static type = Utils.Helpers.Type('[Auth] Clear user token credentials ');
}
export class TogglePageLoader {  
    static type = Utils.Helpers.Type('[Auth] Toggles page loader on SSO sign in ');
    constructor(public toggle: boolean){}
}