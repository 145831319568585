import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-port-pending-modal',
  templateUrl: './port-pending-modal.component.html',
  styleUrls: ['./port-pending-modal.component.scss']
})
export class PortPendingModalComponent {

  constructor(

    public activeModal: NgbActiveModal,
    private router: Router
  ) { }

  gotIt() {
    
    this.activeModal.close()
    this.router.navigate([`manage/services`]);
  }
}
