<div class="port-cancellation-container">
    <div class="close-port-in-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <br>
    <div class="port-header">
        <h3>port <span class="bold-text">cancellation failed</span>
        </h3>
    </div>
    <div class="port-content">
        <p>Your porting cancellation request has failed.</p>
    </div>
    <div class="port-in-status-button d-flex justify-content-between">
        <button-v2 [buttonClass]="'port-btn custom-port-btn'" [btnPrimary]="false" [text]="'request a call '" (click)="requestACall()"></button-v2>
        <button-v2 [buttonClass]="'port-btn '" [btnPrimary]="true" [text]="'got it '" (click)="close()"></button-v2>
    </div>
</div>