import { IServiceDetail, ServiceObject } from '@models/serviceDetail';
import { RainOneWifiLevel } from 'src/app/core/interfaces/rain-one-level.interface';
import { Utils } from 'src/app/utils';
import { IAddonRemoteResponse } from 'src/app/core/interfaces/speed-up.interface';
import { IRemoteOrder } from '@models/remote/remoteOrder';
import { RainOneProduct } from 'src/app/core/interfaces/rain-one-products.interface';
import { IServicePageConfig } from 'src/app/core/interfaces/service-page-config.interface';
import { UnassignSIMModel } from '../state/services.state';
import { AxiomDigitalIdentityUserDetail, BasePropertiesWithRole } from 'src/app/shared/interfaces/axiom/axiom.interfaces';

export const GET_ALL_SERVICES = Utils.Helpers.Type('[SERVICES] Get All User Services');
export const GET_USER_STANDING = Utils.Helpers.Type('[SERVICES] Get User Standing');
export const GET_PROMO_QUALIFYING_SERVICES = Utils.Helpers.Type('[SERVICES] Get Qualifying Service For Promo');
export const SET_PROMO_ACCEPT = Utils.Helpers.Type('[SERVICES] Set Promo Accept');
export const SET_BF_ACCEPT = Utils.Helpers.Type('[SERVICES] Set Black Fricay Accept');
export const CLEAR = Utils.Helpers.Type('[SERVICES] Clear State');

export class GetAllServices {
  public static type = GET_ALL_SERVICES;
}

export class GetUserStanding {
  public static type = GET_USER_STANDING;
}

export class SetPromoAccept {
  public static type = SET_PROMO_ACCEPT;
  constructor(public accpted: boolean) { }
}

export class GePromoQualifyingServices {
  public static type = GET_PROMO_QUALIFYING_SERVICES;
}

export class ClearServicesState {
  public static type = CLEAR;
}

export class BlackFridayAccept {
  public static type = SET_BF_ACCEPT;
  constructor(public accepted: boolean){}
}

export class SetSlectedService {
  public static type = Utils.Helpers.Type('[Services: Set] Set selected service');
  constructor(public readonly payload: IServiceDetail) {}
}

export class SetSelectedServiceByID {
  public static type = Utils.Helpers.Type('[Services: Set Service] Set selected service by service ID');
}

export class SetServiceName {
  public static type = Utils.Helpers.Type('[Services: Set] Set Service Name');
  constructor(public readonly payload: {name: string, serviceId: string}) {}
}

export class SpeedUpWifiRequest {
  public static type = Utils.Helpers.Type('[SERVICES: Wifi] Change wifi speed');
  constructor(public payload: RainOneWifiLevel) {}
}

export class SpeedUpWifiRequestSuccess {
  public static type = Utils.Helpers.Type('[SERVICES: Wifi] Change Wifi speed success');
  constructor(public results: IRemoteOrder, public selectedSpeed: string, public paymentType: string){}
}

export class SpeedUpWifiRequestFailure {
  public static type = Utils.Helpers.Type('[SERVICES: Wifi] Change Wifi speed failure');
  constructor(public error: any){}
}

export class GetServicePolicies {
  public static type = Utils.Helpers.Type('[SERVICES: Get Policies] get services active policies');
  constructor(public error: any){}
}



export class ClearServicePolicies {
  public static type = Utils.Helpers.Type('[SERVICES: Clear Policies] clears services active policies');
  // constructor(public error: any){}
}
export class SpeedUpModalStatusFalse {
  public static type = Utils.Helpers.Type('[Services: AddonModal to false] set modal state to false');
}

export class AddonStatus {
  public static type = Utils.Helpers.Type('[Services: Toggle AddonStatus] set Addon status');
  constructor(public isAddon: boolean ){}
}

export class setAddonType {
  public static type = Utils.Helpers.Type('[Services: AddonType] set Type')
  constructor(public type : 'imediate'| 'scheduled'| 'none'){}
}

export class SetCurrentServiceID {
  public static type = Utils.Helpers.Type('[Services: Set currentServiceID] set ServiceID')
  constructor(public serviceId: string){}
}
export class MigrateNvidia {
  public static type = Utils.Helpers.Type('[Services: Nvidia] Migrate Service')
  constructor(public productTier: RainOneProduct){}
}

export class CancelService {
  public static type = Utils.Helpers.Type('[Services: Cancel] request Service cancel')
  constructor(public serviceId: string){}
}

export class RevokeCancellation {
  public static type = Utils.Helpers.Type('[Services Revoke] Revoke cancellation')
  constructor(public serviceId: string){}
}
export class RevokeMigration {
  public static type = Utils.Helpers.Type('[Services Revoke] Revoke migration')
  constructor(public serviceId: string){}
}
export class FourGMigration {
  public static type = Utils.Helpers.Type('[Services] Checks if the current service is 4G service')
  constructor(public isFourG: boolean){}
}

export class ClearFourGMigration {
  public static type = Utils.Helpers.Type('[Services: Clear] Clear ClearFourGMigration');
}

export class InitMigration {
  public static type = Utils.Helpers.Type('[Services: Migration] Initiate migration')
  constructor(public payload: {type: '5G' | '4G', serviceId: string, productId?: string}){}
}

export class InitMigrationWithNvidia {
  public static type = Utils.Helpers.Type('[Services: Migration] Initiate migration with nvidia membership purchase')
  constructor(public payload: {type: '5G' | '4G', serviceId: string, productId?: string}){}
}
export class MigrationCompleted {
  public static type = Utils.Helpers.Type('[Services: Migration] Migration completed')
}
export class MakeMigration {
  public static type = Utils.Helpers.Type('[Services: Migration] Make migration')
}

export class ClearMigrationConfig {
  public static type = Utils.Helpers.Type('[Services: Migration] Clear migration config');
}
export class ClearCurrentServiceOnlineStatus {
  public static type = Utils.Helpers.Type('[Services: Status] Clear current service online status');
}

export class SetPageServiceConfig {
  public static type = Utils.Helpers.Type('[Services: Service Page] Set service page config');
  constructor(public payload: IServicePageConfig) {}
}
export class GetCurrentServiceConnectedTower {
  public static type = Utils.Helpers.Type('[Services: Status] Get current service connected tower');
  constructor(public serviceID: string) {}
}
export class ClearCurrentServiceConnectedTower {
  public static type = Utils.Helpers.Type('[Services: Status] Clear current service connected tower');
}

export class UnAssignSIM {
  public static type = Utils.Helpers.Type('[Services: Unassign SIM] Unassign SIM');
  constructor(public payload: UnassignSIMModel) {}
}

export class GetPrimaryAccount {
  public static type = Utils.Helpers.Type('[Services: Primary Account] Get primary account');
  constructor(public diUser:  AxiomDigitalIdentityUserDetail) {}
}

export class ClearAssignedSIMS {
  public static type = Utils.Helpers.Type('[Services: Assigned SIMS] Clear assigned SIMS');
}

export class SetSelectedServiceView {
  public static type = Utils.Helpers.Type('[Services] Set selected service view');
  constructor (public view: 'my wifi' | 'my mobile') {}
}