import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, NgZone, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormHelper } from '../../helpers/form.helper';
import { validationMessages } from '../../pipes';

@Component({
  selector: 'sf-form-input',
  templateUrl: './rain-input.component.html',
  styleUrls: ['./rain-input.component.scss']
})
export class RainInputComponent extends FormHelper implements AfterViewInit {
  @Input() control: FormControl;
  @Input() messages = validationMessages;
  @Input() public label: string;
  @Input() public index: string;
  @Input() public blueText: boolean = false;
  @Input() public maxlength: string = '255';
  @Input() public minlength: string;
  @Input() public name: string;
  @Input() public mask: string;
  @Input() public value: string;
  @Input() public type: string = 'text';
  @Input() public placeholder: string = '';
  @Input() public autocomplete: string = 'off';
  @Input() public display: 'column' | 'row' = 'column';
  @Input() public markValid: boolean = false;
  @Input() public focusInput: boolean = false;
  @Input() public inputClass = 'rain-v1';
  @Output() public onKeyEnter: EventEmitter<any> = new EventEmitter()
  @Output() public onKeyUp: EventEmitter<any> = new EventEmitter()

  public isFocused: boolean = false;

  constructor(@Inject(DOCUMENT) private dom: Document,private zone: NgZone) {
    super();
  }

  ngAfterViewInit() {
    if (this.focusInput) {
      return this.focusInputElement();
    }
    if (this.control) {
      this.InitFormControl(this.control);

      if (!this.control.value || !this.control.dirty) {
        this.control.valueChanges.subscribe({
          next: (res) => {
            if (res) {
              this.isFocused = true;
            }
          }
        })
      }
    }

  }

  public onBoundaryClick(e: Event) {
    return this.focusInputElement();
  }

  private focusInputElement() {
    const inputEl: HTMLInputElement = this.dom.querySelector(`input[name="${this.formatLabel(this.label)}"]`);
    if (inputEl !== undefined) inputEl.focus();
    this.isFocused = true;
  }

  public formatLabel(l: string) {
    return l.replace(' ', '_');
  }

  public onBlur() {

    if (this.control.value && this.control.value !== '') {
      return;
    }

    return this.isFocused = !this.isFocused;
  }

  public onKeyPress(e) {
    this.onKeyEnter.emit(e);
  }
  public KeyUp(e) {
    this.onKeyUp.emit(e);
  }

  public onLabelClick() {
    if (this.isFocused) return;

    return this.focusInputElement();
  }

  onTouchStart(event: TouchEvent) {
    event.preventDefault();
    (event.target as HTMLElement).focus();

  }

}
