import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Navigate} from '@ngxs/router-plugin';
import {Store} from '@ngxs/store';
import {AuthenticationService} from '@services/auth.service';
import {Observable, of} from 'rxjs';
import {FetchProductsFromCatelogue} from 'src/app/store/actions/product.actions';
import {tap} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class FetchCatelogueResolver implements Resolve<boolean> {
    constructor(private store: Store, private authSvc: AuthenticationService) {
    }

    resolve(): Observable<any> {
        // Initially return an empty observable to let the route activate
        return of(null).pipe(
            tap(() => {
                // Trigger data fetching asynchronously after the route has already activated
                this.checkStore();
            })
        );
    }

    private checkStore(): Observable<any> {
        const url = window.location.href;
        const isAuthed = this.authSvc.isSignedIn;
        if (url.includes('nvidia/manage')) {
            return this.store.dispatch(new Navigate(['/login/home']));
        }
        if (url.includes('nvidia/upgrade')) {
            return this.store.dispatch(new Navigate(['/login/home']));
        }
        if (isAuthed) {
            if (url.includes('nvidia/learn-more')) {
                return this.store.dispatch(new Navigate(['/nvidia']));
            }
            if (url.includes('nvidia/join-now')) {
                return this.store.dispatch(new Navigate(['/nvidia']));
            }
        }
        //TODO this is going to make it very chatty but clearing this cache is more work
        // const hasLoaded = this.store.selectSnapshot(ProductState.CatelogueIsLoaded);
        // if (hasLoaded) return of(true);
        return this.store.dispatch(new FetchProductsFromCatelogue());
    }
}
