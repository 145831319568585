import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Statuses } from '@models/result';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-pre-rica-modal',
  templateUrl: './pre-rica-modal.component.html',
  styleUrls: ['./pre-rica-modal.component.scss']
})
export class PreRicaModalComponent implements OnInit {
  name: string;
  @Output() accepted: EventEmitter<any> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal, private userService: UserService) { }

  ngOnInit(): void {
    this.getUserDetails();
  }

  getUserDetails() {
    this.userService.get().subscribe(result => {
      if (result.status == Statuses.Success) {
        this.name = result.value.firstName;
      }
    })
  }
  close() {
    this.activeModal.close();
  }

  continue() {
    this.accepted.emit(true);
    this.close();
  }

}
