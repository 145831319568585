import { Utils } from "src/app/utils";
import { VoucherStatus } from "../../interfaces/voucher.interface";
import { BarcodeRefSMSPayload } from "../../services/sms/barcode-sms.interface";
import { BlackFridayVouchers } from "../../interfaces/voucher-redeem-payload.interface";


export class CheckVoucherStatus {
    static readonly type = Utils.Helpers.Type('[Voucher: Status] Check voucher status');
    constructor(public readonly payload: string) { }
}

export class CheckVoucherStatusSuccess {
    static readonly type = Utils.Helpers.Type('[Voucher: Status] Check voucher status success');
    constructor(public readonly payload: VoucherStatus) { }
}

export class CheckVoucherStatusFail{
    static readonly type = Utils.Helpers.Type('[Voucher: Status] Check voucher status fail');
    constructor(public readonly payload: any) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type('[Voucher: Status] Clear voucher');
}


export class GetBlackFridayVoucher {
    static readonly type = Utils.Helpers.Type('[Voucher: Black Friday Voucher] Gets the black friday voucher code');
}
export class GetBlackFridayVoucherSuccess {
    static readonly type = Utils.Helpers.Type('[Voucher: Black Friday Voucher] Gets the black friday voucher code success');
    constructor(public payload : any) { }
}

export class GetBlackFridayVoucherPools {
    static readonly type = Utils.Helpers.Type('[Voucher: Black Friday Voucher] Gets the black friday voucher pools');
    constructor(public voucherPool : BlackFridayVouchers) { }
}

export class ClaimVoucher {
    static readonly type = Utils.Helpers.Type('[Voucher: Black Friday Voucher] Claim voucher pool');
    constructor(public payload: {pool: string}) {}
}

export class ClaimVoucherSuccess {
    static readonly type = Utils.Helpers.Type('[Voucher: Black Friday Voucher] Claim voucher pool success');
    constructor(public payload: {voucher_code: string, amount: number}) {}
}