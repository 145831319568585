import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssignDeviceLayout } from '../../store/actions/device-checker.actions';
import { BreakPoint, BreakpointsService } from './breakpoints.service';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    private activeBreakpoints: string[];
    constructor(
        private breakpointObserver: BreakpointObserver,
        private breakpointService: BreakpointsService,
        private store: Store
    ) {
    }

    subscribeToLayoutChanges(): Observable<string[]> {
        return this.breakpointObserver
            .observe(this.breakpointService.getBreakpoints())
            .pipe(map((observeResponse) => this.parseBreakpointsResponse(observeResponse.breakpoints, observeResponse)));
    }

    parseBreakpointsResponse(breakpoints, observeResponse): string[] {
        this.activeBreakpoints = [];

        Object.keys(breakpoints).map((key: BreakPoint) => {

            if (breakpoints[key]) {
                this.store.dispatch(new AssignDeviceLayout(this.breakpointService.getBreakpointName(key)))
            }
        });

        return this.activeBreakpoints;
    }
}
