import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  @Input() title: string = 'your request has been<br>submitted';
  @Input() copy: string;
  @Input() secondCopy: string;

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  ngOnInit(): void {}

  closeModal() {
    const currentUrl = this.router.url;

    if (currentUrl.includes('manage/sim-swap')) {
      this.router.navigate(['manage/services']);
    }

    this.activeModal.close();
  }
}
