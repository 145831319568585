import { Injectable } from '@angular/core';
import { IProductDetail } from '@models/productDetail';
import { IServiceDetail, IServiceGroup, IServicesGrouping, ServiceObject, ServicePolicy, ServicePolicyRemote, ServicePolicyResponse, ServiceStatuses } from '@models/serviceDetail';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { ProductService } from '@services/product.service';
import { PrimaryModel, UserService } from '@services/user.service';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { map, mergeAll, mergeMap, switchMap, tap } from 'rxjs/operators';
import { WebsiteconfigService } from 'src/app/core/services/websiteconfig.service';
import { sortDescendingBy } from 'src/app/utils/helpers';
import {
  AddonStatus,
  BlackFridayAccept,
  CancelService,
  ClearAssignedSIMS,
  ClearCurrentServiceConnectedTower,
  ClearCurrentServiceOnlineStatus,
  ClearFourGMigration,
  ClearMigrationConfig,
  ClearServicePolicies,
  ClearServicesState,
  FourGMigration,
  GePromoQualifyingServices,
  GetAllServices,
  GetCurrentServiceConnectedTower,
  GetPrimaryAccount,
  GetServicePolicies,
  InitMigration,
  InitMigrationWithNvidia,
  MakeMigration,
  MigrateNvidia,
  MigrationCompleted,
  RevokeCancellation,
  RevokeMigration,
  SetCurrentServiceID,
  SetPageServiceConfig,
  SetPromoAccept,
  SetSelectedServiceByID,
  SetSelectedServiceView,
  SetServiceName,
  SetSlectedService,
  SpeedUpModalStatusFalse,
  SpeedUpWifiRequest,
  SpeedUpWifiRequestFailure,
  SpeedUpWifiRequestSuccess,
  UnAssignSIM,
  setAddonType
} from '../actions/services.actions';
import { CartState } from './cart.state';
import { OrderState } from './order.state';
import { bfProductsmap, ProductState } from './product.state';
import { isEmpty, includes } from 'lodash';
import { SpeedUpService } from '@services/speed-up.service';
import { CacheService } from '@services/cache.service';
import { PAYMENT_STATUS, PAY_ORDER_ID, CACHE_PAY_NOW, UOP_IDS, FOURG_UNLIMITED_IDS, RAIN_MOBILE_DISPLAY_NAME } from '@models/constants';
import { Router } from '@angular/router';
import { IOrderDetail, OrderDetail } from '@models/orderDetail';
import { SetCurrentOrder } from '../actions/order.actions';
import { TransformDataService } from '../../services/transform-data-service';
import { CoreState } from 'src/app/core/store/state/core.state';
import { MigrateToRainOneService } from '@services/migrate-to-rain-one.service';
import { NvidiaService } from '@services/nvidia.service';
import { BillingState } from 'src/app/core/store/state/billing.state';
import { AuthenticationService } from '@services/auth.service';
import { RouterState } from '@ngxs/router-plugin';
import { RainOneProduct } from 'src/app/core/interfaces/rain-one-products.interface';
import { IServicePageConfig } from 'src/app/core/interfaces/service-page-config.interface';
import { UIState } from 'src/app/shared/store/state/ui.state';
import { AuthState } from 'src/app/core/store/state/auth.state';
import { BasePropertiesWithRole } from 'src/app/shared/interfaces/axiom/axiom.interfaces';
import { GetAllServicesV2 } from 'src/app/v2/store/actions/services.action';

export interface UnassignSIMModel {
  loginId: string;
  msisdn: string;
}

export interface ServicesStateModel {
  allServices: any[];
  groupedServices: { [id: string]: IServiceGroup };
  userStanding: string;
  promoQualifyingServices: IServiceDetail[];
  promoAccept: boolean;
  longestPromoTenure: number;
  blackFridayAccept: boolean;
  selectedService: IServiceDetail;
  servicePageConfig: IServicePageConfig;
  rainOneBundle: any;
  serviceID: string;
  policySpeeds: ServicePolicy[];
  addonCompleteModal: {
    customerType?: 'pp' | 'au' | null;
    show?: boolean;
    type?: 'imediate' | 'scheduled' | 'none';
    newSpeed?: string;
    currentSpeed?: string;
  };
  isAddon: boolean;
  isFourG: boolean;
  eligibleFor5GMigrations: boolean;
  migrations: {
    initiated: boolean;
    type: '5G' | '4G';
    serviceId: string;
    productId: string;
    completed: boolean;
  };
  currentServiceOnlineStatus: boolean;
  currentServiceConnectedTowerId: string;
  cancellationDate: Date;
  primaryAccountAssignedSIMS: PrimaryModel[];
  serviceView: 'my wifi' | 'my mobile';
  serviceConnectionStatuses: Record<string, any>;
}

//TODO  //Decide on proper error Handling

@State<ServicesStateModel>({
  name: 'services',
  defaults: {
    allServices: [],
    groupedServices: null,
    userStanding: 'GOOD',
    promoQualifyingServices: [],
    promoAccept: false,
    longestPromoTenure: 0,
    blackFridayAccept: false,
    selectedService: null,
    servicePageConfig: null,
    rainOneBundle: null,
    serviceID: null,
    policySpeeds: [],
    addonCompleteModal: {
      show: false,
      type: 'none',
      newSpeed: '',
      currentSpeed: '',
      customerType: null
    },
    isAddon: false,
    isFourG: false,
    eligibleFor5GMigrations: false,
    migrations: {
      initiated: false,
      type: null,
      serviceId: null,
      productId: null,
      completed: false
    },
    currentServiceOnlineStatus: false,
    currentServiceConnectedTowerId: null,
    cancellationDate: null,
    primaryAccountAssignedSIMS: [],
    serviceView: 'my wifi',
    serviceConnectionStatuses: {}
  }
})
@Injectable({ providedIn: 'root' })
export class ServicesState {
  constructor(
    private userService: UserService,
    public websiteConfig: WebsiteconfigService,
    private productService: ProductService,
    private store: Store,
    private speedUpService: SpeedUpService,
    private cacheService: CacheService,
    private router: Router,
    private transformDataService: TransformDataService,
    private migrationService: MigrateToRainOneService,
    private nvidiaService: NvidiaService,
    private authSvc: AuthenticationService
  ) { }

  @Selector()
  static getServiceConnectionStatuses(state: ServicesStateModel) {
    return state.serviceConnectionStatuses;
  }
  @Selector()
  static getAddonStatus(state: ServicesStateModel) {
    return state.isAddon;
  }
  @Selector()
  static getCurrentServiceOnlineStatus(state: ServicesStateModel) {
    return state.currentServiceOnlineStatus;
  }
  @Selector()
  static getCurrentServiceConnectedTowerSiteId(state: ServicesStateModel) {
    return state.currentServiceConnectedTowerId
  }
  @Selector()
  static getAllServices(state: ServicesStateModel) {
    return state.allServices;
  }

  @Selector()
  static getSelectedServiceView(state: ServicesStateModel) {
    return state.serviceView;
  }

  @Selector([ServicesState.getAllServices, ServicesState.getSelectedServiceView])
  static getSelectedServices(state: ServicesStateModel, allServices: IServiceDetail[], serviceView: 'my wifi' | 'my mobile') {
    const mobileServices = allServices.filter((service) => service?.product?.config?.displayName?.toLocaleLowerCase()?.includes(RAIN_MOBILE_DISPLAY_NAME));
    const nonMobileServices = allServices.filter((service) => !service?.product?.config?.displayName?.toLocaleLowerCase()?.includes(RAIN_MOBILE_DISPLAY_NAME));

    if (serviceView === 'my mobile') {
      return mobileServices;
    }

    return nonMobileServices;
  }

  @Selector()
  static GetGroupedServices(state: ServicesStateModel): { [id: string]: IServiceGroup } {
    return state.groupedServices;
  }

  //work
  @Selector()
  static getAllRainOneWorkServices(state: ServicesStateModel) {
    return state.allServices.filter(service => {
      if (service?.productName?.includes('work') || service?.productName?.includes('SME')) return service;
    });
  }
  @Selector()
  static getAllMobileServices(state: ServicesStateModel) {
    let mobileServices = state.allServices.filter(svc => svc.productName.includes("standalone 4G")
    )
    return mobileServices

  }
  
  
  @Selector()
  static getAllRainOneHomeServices(state: ServicesStateModel) {
    return state.allServices.filter(service => {
      if ((service.productName.includes('Level') || service.productName.includes('rainOne')) && !(service?.productName?.includes('work') || service?.productName?.includes('SME'))) return service;
    });
  }

  @Selector()
  static getAssignSIMS(ctx: ServicesStateModel) {
    return ctx.primaryAccountAssignedSIMS;
  }


  @Selector()
  static GetServicePageConfig(state: ServicesStateModel): IServicePageConfig {
    return state.servicePageConfig;
  }

  @Selector([RouterState])
  static GetSelectedServiceGroup(state: ServicesStateModel, router: any): IServiceGroup {
    const groups = state.groupedServices;
    const paramID = router.state.root.firstChild.firstChild.params.id;
    if (paramID) {
      return groups[paramID];
    }
  }

  @Selector()
  static isFourGMigration(state: ServicesStateModel) {
    return state.isFourG;
  }

  @Selector()
  static IsEligibleFor5GMigrations(state: ServicesStateModel) {
    return state.eligibleFor5GMigrations;
  }

  @Selector()
  static HasActiveService(state: ServicesStateModel) {
    const activeServices = state.allServices.filter(service => service.status === ServiceStatuses.Active);
    return Boolean(activeServices && activeServices.length > 0);
  }

  @Selector()
  static getAllRainOneServices(state: ServicesStateModel) {
    return state.allServices.filter(service => {
      if (service.productName.includes('Level') || service.productName.includes('rainOne')) return service;
    });
  }

  @Selector([
    RouterState,
    ProductState.getRainOneProductIDs5G,
    ProductState.GetRainOneUOPSimIDs,
    ProductState.GetRainOneUnlimtedIDs,
    ProductState.GetRainMobileSIMProducts])
  static CheckSelectedServiceIsLegacyService(
    state: ServicesStateModel,
    router,
    rain5GCatPIds: string[],
    rainOneUOPProductIDs: string[],
    rainOneUnlimitedProductIDs: string[],
    rainMobileProducts: []
  ) {

    const paramID = router.state.root.firstChild.firstChild.params.id;
    const gSvc = state.groupedServices[paramID];

    if (gSvc.category === '4G' && !rainMobileProducts.find((service) => service?.['productId'] === gSvc.serviceObject.productId)) {
      return false;
    }
    if (gSvc.category === '5G') {
      if (rain5GCatPIds.includes(gSvc.serviceObject.productId)) return false;
      return true;
    } else {
      if (rainOneUOPProductIDs.includes(gSvc.serviceObject.productId) || rainOneUnlimitedProductIDs?.includes(gSvc.serviceObject.productId))
        return false;

      return true;
    }
  }

  @Selector([ServicesState.getAllServices, ProductState.getRainOneProductIDs5G])
  static hasActiveRainOne5GService(state: ServicesStateModel, services: IServiceDetail[], rainOne5GProductIDs: string[]) {
    return services?.some(service => rainOne5GProductIDs?.includes(service?.productId));
  }

  @Selector([ServicesState.getAllServices, ProductState.getRainMobileSimProductIds])
  static hasActiveRainMobileService(state: ServicesStateModel, services: IServiceDetail[], rainMobileProductIDs: string[]) {
    return services?.some(service => rainMobileProductIDs?.includes(service?.productId));
  }

  @Selector([ServicesState.getAllServices, ProductState.GetRainOneUOPSimIDs, ProductState.GetRainOneUnlimtedIDs])
  static hasActiveRainOne4GService(
    state: ServicesStateModel,
    services: IServiceDetail[],
    rainOneUOPProductIDs: string[],
    rainOneUnlimitedProductIDs: string[]
  ) {
    return services?.some(service => rainOneUOPProductIDs?.includes(service?.productId) || rainOneUnlimitedProductIDs?.includes(service?.productId));
  }

  @Selector([ServicesState.getAllServices, ProductState.Get5GCatelogueProductsIDs])
  static hasActiveLegacy5GService(state: ServicesStateModel, services: IServiceDetail[], legacy5GIDs: string[]) {


    return services?.some(service => legacy5GIDs?.includes(service?.productId));
  }

  @Selector([ServicesState.getAllServices, ProductState.Get5GCatelogueProductsIDs])
  static getActiveLegacy5GServices(state: ServicesStateModel, services: IServiceDetail[], legacy5GIDs: string[]) {
    return services?.filter(service => legacy5GIDs?.includes(service?.productId));
  }

  @Selector([ServicesState.getAllServices, ProductState.Get5GCatelogueProductsIDs])
  static hasActiveMigratableLegacy5GService(state: ServicesStateModel, services: IServiceDetail[], legacy5GIDs: string[]) {
    return services?.some(service => legacy5GIDs?.includes(service?.productId) && service?.product?.config?.migration?.length);
  }

  @Selector([ServicesState.getAllServices, ProductState.Get5GCatelogueProductsIDs])
  static hasActiveNonMigratableLegacy5GService(state: ServicesStateModel, services: IServiceDetail[], legacy5GIDs: string[]) {
    return services?.some(service => legacy5GIDs?.includes(service?.productId) && !service?.product?.config?.migration?.length);
  }

  @Selector([ServicesState.getAllServices, ProductState.Get4GCatalogueProductIDs])
  static hasActiveLegacy4GService(state: ServicesStateModel, services: IServiceDetail[], legacy4GIDs: string[]) {
    return services?.some(service => legacy4GIDs?.includes(service?.productId));
  }

  @Selector([
    ServicesState.hasActiveRainOne5GService,
    ServicesState.hasActiveMigratableLegacy5GService,
    ServicesState.hasActiveNonMigratableLegacy5GService,
    ServicesState.hasActiveLegacy4GService,
    ServicesState.hasActiveRainOne4GService,
    ServicesState.HasActiveService
  ])
  static CanPurchaseNvidiaWithRainOne(
    state: ServicesStateModel,
    activeRainOne5GService: boolean,
    activeMigratable5GLegacyServices: boolean,
    activeNonMigratableLegacy5GService: boolean,
    active4GLegacyServices: boolean,
    activeRainOne4GServices: boolean,
    hasActiveService: boolean
  ) {
    const hasNo5GService = !activeRainOne5GService && (!activeMigratable5GLegacyServices || !activeNonMigratableLegacy5GService);
    const is4GOnlyCustomer = hasNo5GService && (active4GLegacyServices || activeRainOne4GServices);
    const isLegacy5GCustomer = !activeRainOne5GService && (activeMigratable5GLegacyServices || activeNonMigratableLegacy5GService);
    const isNewCustomer = !hasActiveService;

    return is4GOnlyCustomer || isLegacy5GCustomer || isNewCustomer;
  }
  @Selector([
    ServicesState.hasActiveRainOne5GService,
    ServicesState.hasActiveMigratableLegacy5GService,
    ServicesState.hasActiveNonMigratableLegacy5GService,
    ServicesState.HasActiveService
  ])
  static CanPurchaseNvidiaOnly(
    state: ServicesStateModel,
    activeRainOne5GService: boolean,
    activeMigratable5GLegacyServices: boolean,
    activeNonMigratableLegacy5GService: boolean,
    hasActiveService: boolean
  ) {
    const isNewCustomer = !hasActiveService;

    return activeRainOne5GService || activeMigratable5GLegacyServices || activeNonMigratableLegacy5GService || isNewCustomer;
  }

  @Selector()
  static hasNvidiaService(state: ServicesStateModel) {
    const services = state.allServices.find(s => s.product.config['subtype'] === ('nvidia' as any));
    return Boolean(services);
  }

  @Selector()
  static blackFridayAccept(state: ServicesStateModel) {
    return state.blackFridayAccept;
  }
  @Selector()
  static GetCurrentRainOneBundle(state: ServicesStateModel) {
    return state.rainOneBundle;
  }

  @Selector()
  static SelectedService(state: ServicesStateModel) {
    if (state.selectedService) {
      return state.selectedService;
    }
  }
  @Selector([RouterState.state])
  static GetCurrentService(state: ServicesStateModel, route) {
    const currentService = state.allServices.find(svc => svc?.id === route?.root?.firstChild?.params?.['id']);
    return currentService;
  }

  static fetchServiceById(serviceId: string) {
    return createSelector([ServicesState.getAllServices], (services: IServiceDetail[]) => {
      return services.find(service => service.id === serviceId);
    });
  }

  @Selector([OrderState.allPostPaidProductIdsSet])
  static allProductIdsSet(state, productIds) {
    return productIds;
  }

  @Selector()
  static getNewSpeed(state: ServicesStateModel) {
    return state.addonCompleteModal.newSpeed;
  }

  @Selector()
  static getServiceId(state: ServicesStateModel) {
    return state.serviceID;
  }
  @Selector()
  static getShowAddonModal(state: ServicesStateModel) {
    return state.addonCompleteModal;
  }

  @Selector([ProductState.postPaidProductIdsSet, OrderState.GetallNonCancelledOrders, ProductState.Get5GCatelogueProductsIDs, ProductState.Get4GCatalogueProductIDs])
  static hasPostPaid(state: ServicesStateModel, productIds: Set<any>, orders, legacy5GIDs, legacy4GIDs) {
    productIds.add('b236d2f9-f4cb-4efc-847b-b780f9dcf2f3');
    productIds.add('6c7e7644-9a6d-4e8e-a541-520dbf7f0512');

    const combinedIds = new Array(...legacy5GIDs, ...Array.from(productIds), ...legacy4GIDs);
    const filteredOrders = orders.filter((order: IOrderDetail) => order.status !== 5);
    let postPaidBecauseOfOrders = Boolean(filteredOrders?.some((order: IOrderDetail) => combinedIds.includes(order.id)));
    const activeServices = state.allServices.filter(service => service.status === ServiceStatuses.Active);

    return Boolean((activeServices.length > 0 && activeServices.some(service => combinedIds.includes(service.productId))) || postPaidBecauseOfOrders);
  }

  @Selector()
  static hasBlackFridayProduct(state) {
    const bfProductIds = Object.keys(bfProductsmap);
    return Boolean(state.allServices?.some(service => service.status === ServiceStatuses.Active && bfProductIds.includes(service.productId)));
  }

  @Selector()
  static hasNvidiaProduct(state) {
    return Boolean(
      state.allServices?.some(
        (service: IServiceDetail) => service.status === ServiceStatuses.Active && service.productName.toLocaleLowerCase().includes('nvidia')
      )
    );
  }

  @Selector()
  static hasGiveAGig(state) {
    return Boolean(state.allServices?.some(service => service.productId === '4eb3ec7f-1a51-44cb-b2a9-f55ddd560b56'));
  }

  @Selector([ProductState.allProducts])
  static prefferedFridayProduct(state, allProducts) {
    const bfProductIds = Object.keys(bfProductsmap);
    const bfServices: IServiceDetail[] = state.allServices?.filter(
      service => service.status === ServiceStatuses.Active && bfProductIds.includes(service.productId)
    );

    if (bfServices.length > 0) {
      const bfProductsMeta = bfServices.map(service => {
        return bfProductsmap[service?.productId];
      });
      let bfProducts = sortDescendingBy(bfProductsMeta, 'originalPrice');
      return bfProducts[0];
    }
    return null;
  }

  @Selector()
  static getPromoQualifyingServices(state: ServicesStateModel) {
    return state.promoQualifyingServices;
  }

  @Selector()
  static longestPromoTenure(state: ServicesStateModel) {
    return state.longestPromoTenure;
  }

  @Selector()
  static promoAccept(state: ServicesStateModel) {
    return state.promoAccept;
  }

  @Selector()
  static getCurrentProduct(state: ServicesStateModel) {
    return state.selectedService.product;
  }
  @Selector([ServicesState.getAllServices])
  static canMigrateUOP(state: ServicesStateModel, allServices: IServiceDetail[]) {
    const allUOPServices = allServices.filter(svc => {
      return UOP_IDS?.includes(svc?.productId) && svc?.status === ServiceStatuses?.Active;
    });
    const canMigrate = !allUOPServices?.some(svc => svc?.pending_migration);
    return canMigrate;
  }
  @Selector([ServicesState.getAllServices])
  static canMigrate4GUnlimited(state: ServicesStateModel, allServices: IServiceDetail[]) {
    const all4GUnlimitedServices = allServices.filter(svc => {
      return FOURG_UNLIMITED_IDS?.includes(svc?.productId) && svc?.status === ServiceStatuses?.Active;
    });
    const canMigrate = !all4GUnlimitedServices?.some(svc => svc?.pending_migration);
    return canMigrate;
  }

  @Selector()
  static GetMigrationsConfig(state: ServicesStateModel) {
    return state.migrations;
  }

  static getServiceSpeed() {
    return createSelector([ServicesState], (state: ServicesStateModel) => {
      const currentService = state.selectedService.id;
      return state.policySpeeds?.filter(service => service.serviceId === currentService);
    });
  }
  static getServiceSpeedbyId(Id: string) {
    return createSelector([ServicesState], (state: ServicesStateModel) => {
      const policy = state.policySpeeds?.filter(service => service.serviceId === Id);
      return policy[0].policy;
    });
  }

  @Action(SetPageServiceConfig)
  SetPageServiceConfig(ctx: StateContext<ServicesStateModel>, action: SetPageServiceConfig) {
    ctx.patchState({
      servicePageConfig: action.payload
    });
  }

  @Action(SetCurrentServiceID)
  setCurrentServiceId(ctx: StateContext<ServicesStateModel>, action: SetCurrentServiceID) {
    ctx.patchState({
      serviceID: action.serviceId
    });
  }

  @Action(UnAssignSIM)
  unAssignSIM(ctx: StateContext<ServicesStateModel>, action: UnAssignSIM) {

    return this.userService.unAssignSIMService(action.payload).pipe(
      tap(() => {
        console.log('Unassign SIM - success');
      })
    );
  }

  @Action(GetPrimaryAccount)
  getPrimaryAccount(ctx: StateContext<ServicesStateModel>, action: GetPrimaryAccount) {

    const diUser = action.diUser;
    let profile: BasePropertiesWithRole | string = null;

    const uiMode = this.store.selectSnapshot(UIState.GetUIMode);

    if (diUser?.relatedParty.length > 0) {
      profile = diUser?.relatedParty.find((reParty) => {
        return reParty?.role?.toLowerCase() === uiMode?.toLowerCase();
      });
    }
    else {
      profile = {
        id: diUser?.id,
      }
    }

    return this.userService.getPrimaryAccountHolder(profile?.id).pipe(
      tap({
        next: (result) => {

          ctx.patchState({
            primaryAccountAssignedSIMS: result
          })
        },
        error: (error) => {
          return error;
        }
      })
    );
  }

  @Action(GetAllServices)
  getAllServices(ctx: StateContext<ServicesStateModel>) {
    const state = ctx.getState();
    let uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    let smeToken = this.store.selectSnapshot(AuthState.getSmeToken);
    if (uiMode === 'sme') {
      return this.userService.getServices(true, smeToken).pipe(
        map((res: any) => res.value),
        tap(async (services: IServiceDetail[]) => {
          if (services) {

            const formattedData = (await this.transformDataService
              ?.inputServicesData(services)
              ?.filterActiveAndSuspendedServices()
              ?.appendRainOneDetails())
              ?.appendProductDetail()
              ?.assignProductType()
              ?.appendSpendLimitDetail()?.services;

            const svcLockup = this.sortServicesByParent(formattedData);

            if (formattedData.length && formattedData !== null && formattedData !== undefined) {
              ctx.patchState({
                allServices: formattedData,
                groupedServices: svcLockup
              });
            }

            const migrationTracker = this.store.select(OrderState.GetTrackingData);

            migrationTracker.subscribe({
              next: trackerData => {
                if (trackerData.ready) {
                  const filteredServices = services.filter(s => s.status === 0);
                  const rainOneBundleService = filteredServices?.find(s => s?.productName?.toLocaleLowerCase().includes('rainone'));

                  if (rainOneBundleService) {
                    const bundle = this.store.selectSnapshot(ProductState.GetRainBundleByName(rainOneBundleService?.productName));
                    if (bundle) {
                      ctx.patchState({
                        rainOneBundle: bundle
                      });
                    }
                  }

                  const catelogue = this.store.selectSnapshot(ProductState.GetCatelogueProducts);

                  const has4gUnlmtdServices = !trackerData.four_g.unlmtd.hasActive
                    ? filteredServices.filter(svc => {
                      if (
                        svc.productName.toLocaleLowerCase().includes('4g') &&
                        svc.productName.toLocaleLowerCase().includes('any device') &&
                        !svc.productName.toLocaleLowerCase().includes('19')
                      )
                        return svc;
                    })
                    : [];

                  const has4gUOPServices = !trackerData.four_g.uop.hasActive
                    ? filteredServices.filter(svc => {
                      if (svc.productName.toLocaleLowerCase().includes('19 hours unlimited')) return svc;
                    })
                    : [];
                  const has4gServices = Array(...has4gUnlmtdServices, ...has4gUOPServices);
                  const has5gServices = !trackerData.five_g.hasActive
                    ? filteredServices.filter(service => {
                      const product = catelogue?.find((product: RainOneProduct) => {
                        return product?.id === service?.productId;
                      });
                      if (product?.category === '5G' && service?.status === ServiceStatuses.Active) return service;
                    })
                    : [];
                  const hasPending4GMigration = has4gServices.filter(service => service.pending_migration);
                  const hasPending5GMigration = has5gServices.filter(service => service.pending_migration || service.scheduledAction !== null);

                  const isFourG = hasPending4GMigration.length === 0 && has4gServices.length > 0;

                  if (isFourG) {
                    if (has4gServices.length === 1) {
                      ctx.patchState({
                        selectedService: has4gServices[0]
                      });
                    } else if (has4gServices.length > 1) {
                      ctx.patchState({
                        selectedService: this.findMostExpensiveService(has4gServices)
                      });
                    }
                  }

                  let isFiveG = hasPending5GMigration.length === 0 && has5gServices.length > 0;

                  if (state.migrations.completed) isFiveG = false;
                  const mostExpensive5GService = this.findMostExpensiveService(has5gServices);
                  const orderType = this.store.selectSnapshot(CartState.GetOrderType);
                  if (orderType === 'nvidia_with_5G_purchase') {
                    return ctx.patchState({
                      ...state.migrations,
                      allServices: formattedData.length && formattedData !== null && formattedData !== undefined ? formattedData : services
                    });
                  }
                  return ctx.patchState({
                    allServices: formattedData.length && formattedData !== null && formattedData !== undefined ? formattedData : services,
                    isFourG: isFourG,
                    eligibleFor5GMigrations: isFiveG,
                    migrations: {
                      ...state.migrations,
                      serviceId: mostExpensive5GService?.id,
                      productId: mostExpensive5GService?.productId
                    }
                  });
                }
              }
            });
          }

          ctx.dispatch([new GePromoQualifyingServices()]);
        })
      );
    }
    return this.userService.getServices(true).pipe(
      map((res: any) => res.value),
      tap(async (services: IServiceDetail[]) => {
        if (services) {
          const formattedData = (await this.transformDataService
            ?.inputServicesData(services)
            ?.filterActiveAndSuspendedServices()
            ?.appendRainOneDetails())
            ?.appendProductDetail()
            ?.assignProductType()
            ?.appendSpendLimitDetail()?.services;

          const svcLockup = this.sortServicesByParent(formattedData);

          if (formattedData.length && formattedData !== null && formattedData !== undefined) {
            ctx.patchState({
              allServices: formattedData,
              groupedServices: svcLockup
            });
          }

          const migrationTracker = this.store.select(OrderState.GetTrackingData);

          migrationTracker.subscribe({
            next: trackerData => {
              if (trackerData.ready) {
                const filteredServices = services.filter(s => s.status === 0);
                const rainOneBundleService = filteredServices?.find(s => s?.productName?.toLocaleLowerCase().includes('rainone'));

                if (rainOneBundleService) {
                  const bundle = this.store.selectSnapshot(ProductState.GetRainBundleByName(rainOneBundleService?.productName));
                  if (bundle) {
                    ctx.patchState({
                      rainOneBundle: bundle
                    });
                  }
                }

                const catelogue = this.store.selectSnapshot(ProductState.GetCatelogueProducts);

                const has4gUnlmtdServices = !trackerData.four_g.unlmtd.hasActive
                  ? filteredServices.filter(svc => {
                    if (
                      svc.productName.toLocaleLowerCase().includes('4g') &&
                      svc.productName.toLocaleLowerCase().includes('any device') &&
                      !svc.productName.toLocaleLowerCase().includes('19')
                    )
                      return svc;
                  })
                  : [];

                const has4gUOPServices = !trackerData.four_g.uop.hasActive
                  ? filteredServices.filter(svc => {
                    if (svc.productName.toLocaleLowerCase().includes('19 hours unlimited')) return svc;
                  })
                  : [];
                const has4gServices = Array(...has4gUnlmtdServices, ...has4gUOPServices);
                const has5gServices = !trackerData.five_g.hasActive
                  ? filteredServices.filter(service => {
                    const product = catelogue?.find((product: RainOneProduct) => {
                      return product?.id === service?.productId;
                    });
                    if (product?.category === '5G' && service?.status === ServiceStatuses.Active) return service;
                  })
                  : [];
                const hasPending4GMigration = has4gServices.filter(service => service.pending_migration);
                const hasPending5GMigration = has5gServices.filter(service => service.pending_migration || service.scheduledAction !== null);

                const isFourG = hasPending4GMigration.length === 0 && has4gServices.length > 0;

                if (isFourG) {
                  if (has4gServices.length === 1) {
                    ctx.patchState({
                      selectedService: has4gServices[0]
                    });
                  } else if (has4gServices.length > 1) {
                    ctx.patchState({
                      selectedService: this.findMostExpensiveService(has4gServices)
                    });
                  }
                }

                let isFiveG = hasPending5GMigration.length === 0 && has5gServices.length > 0;

                if (state.migrations.completed) isFiveG = false;
                const mostExpensive5GService = this.findMostExpensiveService(has5gServices);
                const orderType = this.store.selectSnapshot(CartState.GetOrderType);
                if (orderType === 'nvidia_with_5G_purchase') {
                  return ctx.patchState({
                    ...state.migrations,
                    allServices: formattedData.length && formattedData !== null && formattedData !== undefined ? formattedData : services
                  });
                }
                return ctx.patchState({
                  allServices: formattedData.length && formattedData !== null && formattedData !== undefined ? formattedData : services,
                  isFourG: isFourG,
                  eligibleFor5GMigrations: isFiveG,
                  migrations: {
                    ...state.migrations,
                    serviceId: mostExpensive5GService?.id,
                    productId: mostExpensive5GService?.productId
                  }
                });
              }
            }
          });
        }

        ctx.dispatch([new GePromoQualifyingServices()]);
      })
    );
  }

  @Action(ClearServicePolicies)
  clearPolicies(ctx: StateContext<ServicesStateModel>, action: ClearServicePolicies) {
    ctx.patchState({
      policySpeeds: []
    });
  }

  @Action(SetServiceName)
  setServiceName(ctx: StateContext<ServicesStateModel>, action: SetServiceName) {
    const serviceId = action.payload.serviceId;
    const name = action.payload.name;

    ctx.patchState({
      allServices: [
        ...ctx.getState().allServices.map(service => {
          return service.id === serviceId ? { ...service, name } : service;
        })
      ]
    });
  }

  @Action(SetPromoAccept)
  setPromoAccept(ctx: StateContext<ServicesStateModel>, action: SetPromoAccept) {
    ctx.patchState({
      promoAccept: action.accpted
    });
  }

  @Action(BlackFridayAccept)
  blackFridayAccept(ctx: StateContext<ServicesStateModel>, action: BlackFridayAccept) {
    ctx.patchState({
      blackFridayAccept: action.accepted
    });
  }

  @Action(ClearServicesState)
  clearServicesState(ctx: StateContext<ServicesStateModel>, action: ClearServicesState) {
    ctx.patchState({
      allServices: [],
      userStanding: 'GOOD',
      promoQualifyingServices: [],
      promoAccept: false,
      longestPromoTenure: 0,
      migrations: {
        initiated: false,
        type: null,
        serviceId: null,
        productId: null,
        completed: false
      },
      serviceID: null,
      selectedService: null,
      groupedServices: null,
      servicePageConfig: null,
      rainOneBundle: null,
      primaryAccountAssignedSIMS: null,
      serviceConnectionStatuses: {}
    });
  }

  @Action(GePromoQualifyingServices)
  getMessagesForCategory(ctx: StateContext<ServicesStateModel>, action: GePromoQualifyingServices) {
    return forkJoin([this.productService.get()]) //ToDO
      .pipe(
        tap(res => {
          const products = res[0]?.value;
          const services = ctx.getState()?.allServices;
          let longestTenure = 0;
          const availableServices = services?.filter(s => {
            const tenure = this.tenure(s);
            if (tenure >= 6) longestTenure = this.tenure(s);
            return s.status === ServiceStatuses.Active && this.canMigate(s, products) && tenure >= 6;
          });

          ctx.patchState({
            promoQualifyingServices: availableServices,
            longestPromoTenure: longestTenure
          });
        })
      );
  }
  @Action(SetSlectedService)
  SetSlectedService(ctx: StateContext<ServicesStateModel>, action: SetSlectedService) {
    const service = action.payload;

    ctx.patchState({
      selectedService: service
    });
  }

  private tenure(service: IServiceDetail): number {
    const today = moment(new Date());
    const activationDate = moment(new Date(service.activationDate));
    return today.diff(activationDate, 'months');
  }

  private canMigate(service, products): boolean {
    if (!products.length) return false;
    const correspondingProduct: IProductDetail = products?.find(product => product.id === service.productId);
    return correspondingProduct?.name === 'unlimited 4G\n for any device' || correspondingProduct?.name === 'Unlimited home 5G basic';
  }

  @Action(SetSelectedServiceByID)
  setSelectedServiceById(ctx: StateContext<ServicesStateModel>) {
    const allServices: IServiceDetail[] = ctx.getState().allServices;
    const currentService = allServices.filter(service => service.id === ctx.getState().serviceID);
    ctx.patchState({
      selectedService: currentService[0]
    });
  }

  @Action(SpeedUpWifiRequest)
  changeWifiSpeed(ctx: StateContext<ServicesStateModel>, action: SpeedUpWifiRequest) {
    const currentProduct = this.store.selectSnapshot(ServicesState.SelectedService);
    const serviceId = currentProduct.id;

    const selectedService = this.store.selectSnapshot(ServicesState.getAllServices)
    const currentService = selectedService.find(service => service.id === serviceId)

    const productId = currentService.productId;
    const currentSpeed = ctx
      .getState()
      .policySpeeds.filter(service => service.serviceId === serviceId)
      .pop().policy;
    const selectedSpeed = action.payload;
    const paymentType = currentService?.product?.config?.paymentType;

    if (selectedSpeed?.speed === "100+") selectedSpeed.speed = "100"

    ctx.patchState({ addonCompleteModal: { currentSpeed, newSpeed: selectedSpeed.speed } });

    return this.store
      .select(ProductState.GetAllBundles)
      .pipe(
        mergeMap(val => {
          let objectArray = []
          const filteredProducts = val.filter(product => product?.items?.find(item => item?.id === productId)).find(product => product?.name === currentProduct.productName);

          objectArray.push(val.filter(product => product?.items?.find(item => item?.id === productId)).find(product => product?.name))
          return objectArray;
        }),
        map(product =>
          product.config.add_ons.map(item => {
            let items = this.store.selectSnapshot(ProductState.GetAddonById(item.id));
            return items;
          })
        ),
        map(addons => addons.filter(addon => {
          return addon.name.includes(selectedSpeed.speed)
        })),
        mergeAll(),
        map(addon => {
          return this.speedUpService.changeServiceSpeed(addon['id'], serviceId);
        }),
        mergeAll()
      )
      .pipe(
        tap({
          next: res => this.store.dispatch(new SpeedUpWifiRequestSuccess(res.result?.order, selectedSpeed.speed, paymentType.toString())),
          error: err => this.store.dispatch(new SpeedUpWifiRequestFailure(err))
        })
      );
  }

  @Action(SpeedUpWifiRequestSuccess)
  changeWifiSpeedSuccess(ctx: StateContext<ServicesStateModel>, action: SpeedUpWifiRequestSuccess) {
    let mappedRes;
    if (!!action.results) {
      mappedRes = OrderDetail.adapt(action.results);
      this.store.dispatch(new SetCurrentOrder(mappedRes));
    }

    ctx.dispatch([new GetAllServicesV2()]);

    if (action.paymentType.includes('upfront')) {
      if (mappedRes) {
        this.cacheService.setObject(PAYMENT_STATUS, 'orderPay');

        this.cacheService.setObject(PAY_ORDER_ID, mappedRes.id);

        this.cacheService.setObject(CACHE_PAY_NOW, 'TRUE');

        this.router.navigateByUrl('/payment-details');
      } else {
        ctx.patchState({
          addonCompleteModal: {
            show: true,
            type: 'scheduled',
            newSpeed: action.selectedSpeed,
            customerType: 'au'
          }
        });
      }
    } else if (action.paymentType.includes('postpaid')) {
      ctx.patchState({
        addonCompleteModal: {
          show: true,
          type: ctx.getState().addonCompleteModal.type === 'imediate' ? 'imediate' : 'scheduled',
          newSpeed: action.selectedSpeed,
          customerType: 'pp'
        }
      });
    }
  }

  @Action(SpeedUpWifiRequestFailure)
  changeWifiSpeedFailure(ctx: StateContext<ServicesStateModel>, action: SpeedUpWifiRequestFailure) {
    console.error('changeWifiSpeedFailure error:', action.error);
  }

  @Action(SpeedUpModalStatusFalse)
  setToFalse(ctx: StateContext<ServicesStateModel>, action: SpeedUpModalStatusFalse) {
    ctx.patchState({
      addonCompleteModal: {}
    });
  }

  @Action(ClearFourGMigration)
  ClearFourGMigration(ctx: StateContext<ServicesStateModel>, action: ClearFourGMigration) {
    ctx.patchState({
      isFourG: false
    });
  }

  @Action(FourGMigration)
  isFourGMigration(ctx: StateContext<ServicesStateModel>, action: FourGMigration) {
    ctx.patchState({
      isFourG: action.isFourG
    });
  }

  @Action(AddonStatus)
  setIsAddon(ctx: StateContext<ServicesStateModel>, action: AddonStatus) {
    const isPurchasingRainOne = this.store.selectSnapshot(CartState.GetSelectedRainOneBundle);
    if (isPurchasingRainOne && isPurchasingRainOne !== undefined && isEmpty(isPurchasingRainOne)) {
      ctx.patchState({
        isAddon: false
      });
    } else {
      ctx.patchState({
        isAddon: action.isAddon
      });
    }
  }

  @Action(setAddonType)
  setAddonType(ctx: StateContext<ServicesStateModel>, action: setAddonType) {
    ctx.patchState({
      addonCompleteModal: {
        type: action.type
      }
    });
  }

  @Action(InitMigration)
  InitMigration(ctx: StateContext<ServicesStateModel>, action: InitMigration) {
    const state = ctx.getState();
    const payload = action.payload;

    if (payload.productId) {
      ctx.patchState({
        migrations: {
          ...state.migrations,
          initiated: true,
          type: payload.type,
          serviceId: payload.serviceId,
          productId: payload.productId
        }
      });
    } else {
      ctx.patchState({
        migrations: {
          ...state.migrations,
          initiated: true,
          type: payload.type,
          serviceId: payload.serviceId
        }
      });
    }
  }

  @Action(InitMigrationWithNvidia)
  InitMigrationWithNvidia(ctx: StateContext<ServicesStateModel>, action: InitMigrationWithNvidia) {
    const state = ctx.getState();
    const payload = action.payload;

    if (payload.productId) {
      ctx.patchState({
        migrations: {
          ...state.migrations,
          initiated: true,
          type: payload.type,
          serviceId: payload.serviceId,
          productId: payload.productId
        }
      });
    } else {
      ctx.patchState({
        migrations: {
          ...state.migrations,
          initiated: true,
          type: payload.type,
          serviceId: payload.serviceId
        }
      });
    }
  }

  @Action(CancelService)
  cancelService(ctx: StateContext<ServicesStateModel>, action: CancelService) {
    const serviceId = action.serviceId;
    const reason = 'User Request';

    ctx.patchState({
      serviceID: serviceId
    });
    if (this.authSvc.isSignedIn) {
      const billingDay = this.store.selectSnapshot(BillingState.getPaymentDate);
      return this.nvidiaService.cancelModal(billingDay.selectedPaymentDate).pipe(
        switchMap((result: boolean) => {
          if (result) {
            return this.nvidiaService.cancelService({ serviceId, reason }).pipe(
              tap({
                next: () => {
                  this.nvidiaService.cancelDoneModal();
                  setTimeout(() => {
                    ctx
                      .dispatch(new GetAllServicesV2())
                      .toPromise()
                      .then(() => {
                        ctx.dispatch(new SetSelectedServiceByID());
                      });
                  }, 1000);
                },
                error: () => console.log('oops')
              })
            );
          }
        })
      );
    }
  }

  @Action(RevokeCancellation)
  revokeCancellation(ctx: StateContext<ServicesStateModel>, action: RevokeCancellation) {
    const serviceId = action.serviceId;

    ctx.patchState({
      serviceID: serviceId
    });

    return this.nvidiaService.revokePendingActionModal('cancellation').pipe(
      switchMap((result: Boolean) => {
        if (result) {
          return this.migrationService.reverseCancelRainOneService(action.serviceId).pipe(
            tap({
              next: () => {
                this.nvidiaService.revokeActionCompleteModal('cancellation');
                setTimeout(() => {
                  ctx
                    .dispatch(new GetAllServicesV2())
                    .toPromise()
                    .then(() => {
                      ctx.dispatch(new SetSelectedServiceByID());
                    });
                }, 1000);
              }
            })
          );
        }
      })
    );
  }

  @Action(RevokeMigration)
  revokeMigration(ctx: StateContext<ServicesStateModel>, action: RevokeMigration) {
    const serviceId = action.serviceId;

    ctx.patchState({
      serviceID: serviceId
    });

    return this.nvidiaService.revokePendingActionModal('migration').pipe(
      switchMap((result: Boolean) => {
        if (result) {
          return this.userService.cancelMigration(serviceId).pipe(
            tap({
              next: () => {
                this.store.dispatch(new GetAllServicesV2());
                this.nvidiaService.revokeActionCompleteModal('migration');
              }
            })
          );
        }
      })
    );
  }
  @Action(ClearCurrentServiceOnlineStatus)
  clearCurrentServiceOnlineStatus(ctx: StateContext<ServicesStateModel>, action: ClearCurrentServiceOnlineStatus) {
    return ctx.patchState({
      currentServiceOnlineStatus: false
    });
  }

  @Action(GetCurrentServiceConnectedTower)
  GetCurrentServiceConnectedTower(ctx: StateContext<ServicesStateModel>, action: GetCurrentServiceConnectedTower) {
    const serviceID = action.serviceID;
    const { imsi } = ctx.getState()?.allServices.find((service) => service.id === serviceID)


    return this.userService.getServiceConnectedTower(imsi).pipe(
      tap({
        next: (res: any) => {
          ctx.patchState({
            currentServiceConnectedTowerId: res?.location?.current?.cgi_nb_id
          })
        },
        error: (err) => {
          ctx.patchState({ currentServiceConnectedTowerId: null })
        }
      })
    );
  }
  @Action(ClearCurrentServiceConnectedTower)
  ClearCurrentServiceConnectedTower(ctx: StateContext<ServicesStateModel>, action: ClearCurrentServiceConnectedTower) {
    return ctx.patchState({
      currentServiceConnectedTowerId: null
    })
  }

  @Action(ClearAssignedSIMS)
  clearAssignedSIMS(ctx: StateContext<ServicesStateModel>) {

    return ctx.patchState({
      primaryAccountAssignedSIMS: []
    });
  }

  @Action(ClearMigrationConfig)
  ClearMigrationConfig(ctx: StateContext<ServicesStateModel>, action: ClearMigrationConfig) {
    ctx.patchState({
      eligibleFor5GMigrations: false,
      migrations: {
        initiated: false,
        type: null,
        serviceId: null,
        productId: null,
        completed: false
      }
    });
  }

  @Action(MigrationCompleted)
  MigrationCompleted(ctx: StateContext<ServicesStateModel>, action: MigrationCompleted) {
    ctx.patchState({
      migrations: {
        initiated: true,
        type: null,
        serviceId: null,
        productId: null,
        completed: true
      }
    });
  }

  @Action(SetSelectedServiceView)
  SetSelectedServiceView(ctx: StateContext<ServicesStateModel>, action: SetSelectedServiceView) {
    return ctx.patchState({
      serviceView: action?.view
    })
  }

  @Action(MigrateNvidia)
  migrateNvidia(ctx: StateContext<ServicesStateModel>, action: MigrateNvidia) {
    const user = this.store.selectSnapshot(CoreState.getUserProfile);
    const currentServiceProductId = this.store.selectSnapshot(ServicesState.SelectedService).productId;
    const product_id = this.nvidiaService.getMigrateProductId(action.productTier, currentServiceProductId);
    const newProductName = this.store.selectSnapshot(ProductState.GetNvidiaProductById(product_id)).config.tier;
    const unformattedAddress = user.addresses[0];
    const formattedAddress = {
      street_number: unformattedAddress.streetNumber,
      street_name: unformattedAddress.streetName,
      suburb: unformattedAddress.suburb,
      city: unformattedAddress.city,
      province: unformattedAddress.province,
      postal_code: unformattedAddress.postalCode
    };

    const payload = {
      product_id,
      userId: user.id,
      delivery_address: formattedAddress,
      msisdn: user.phone,
      courier_method: 'Delivery',
      courier: 'RAIN',
      paymentType: this.store.selectSnapshot(ServicesState.hasPostPaid) ? 'postpaid' : 'upfront',
      serviceId: ctx.getState().selectedService.id
    };

    return this.nvidiaService
      .migrateModal(
        this.store.selectSnapshot(ProductState.GetNvidiaProductById(ctx.getState().selectedService.productId)).config.tier,
        newProductName
      )
      .pipe(
        switchMap((result: Boolean) => {
          if (result) {
            return this.migrationService.migrateService(payload, true).pipe(
              tap({
                next: order => {
                  const orderNumber = order.result?.order;
                  if (!orderNumber) {
                    this.store.dispatch(new GetAllServicesV2());
                    this.nvidiaService.migrateCompleteModal(newProductName);
                  } else {
                    ///// use order number to do checkout
                  }
                }
              })
            );
          }
        })
      );
  }

  @Action(MakeMigration)
  MakeMigration(ctx: StateContext<ServicesStateModel>, action: MigrateNvidia) {
    const state = ctx.getState();
    const user = this.store.selectSnapshot(CoreState.getUserProfile);
    const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
    const migrationConfig = state.migrations;
    if (!migrationConfig) return;
    const selectedService: IServiceDetail = state.allServices.find((s: IServiceDetail) => s.id === migrationConfig.serviceId);

    const currentLegacyProduct = this.store.selectSnapshot(ProductState.GetProductById(migrationConfig.productId));
    const rainOneBundleID = selectedService.product?.config.migration[0].id;

    const unformattedAddress = user.addresses[0];

    const formattedAddress = {
      street_number: unformattedAddress.streetNumber,
      street_name: unformattedAddress.streetName,
      suburb: unformattedAddress.suburb,
      city: unformattedAddress.city,
      province: unformattedAddress.province,
      postal_code: unformattedAddress.postalCode
    };

    const payload = {
      product_id: rainOneBundleID,
      userId: user.id,
      delivery_address: formattedAddress,
      msisdn: user.phone,
      courier_method: 'Delivery',
      courier: null,
      paymentType: isPostPaid ? 'postpaid' : 'upfront',
      serviceId: migrationConfig.serviceId
    };

    return this.migrationService.migrateService(payload, true).pipe(
      tap({
        next: order => {
          const orderNumber = order.result?.order;
          this.store.dispatch(new ClearMigrationConfig());
          if (!orderNumber) {
            this.store.dispatch(new GetAllServicesV2());
          } else {
            ///// use order number to do checkout
          }
        }
      })
    );
  }

  findMostExpensiveService(services: IServiceDetail[]): IServiceDetail {
    if (services.length === 0) {
      return;
    }

    let highestPrice = services[0].uopMrc; // Assume the first number is the highest
    let highestService = services[0]; // Assume the first number is the highest

    for (let i = 1; i < services.length; i++) {
      if (services[i].uopMrc > highestPrice) {
        highestPrice = services[i].uopMrc;
        highestService = services[i];
      }
    }

    return highestService;
  }

  private sortServicesByParent(services: IServiceDetail[]) {
    const lockup = {};


    services.forEach((svc: IServiceDetail) => {

      if (svc.status === 6) return;
      if (svc.parent_service_id) {
        if (lockup[svc.parent_service_id]) {
          lockup[svc.parent_service_id].subServices[svc.id] = svc;
        } else {
          const parentSvc = services?.find((service) => service?.id === svc?.parent_service_id);
          const product = this.store.selectSnapshot(ProductState.GetProductById(parentSvc?.productId));
          const category = product?.category ? product?.category : product?.config?.subtype;
          lockup[svc.parent_service_id] = {
            isParentSvc: true,
            category: category,
            serviceObject: parentSvc,
            subServices: {
              [svc.id]: svc
            },
            isLegacy: this.checkIfIsLegacy(category, product, parentSvc.assigned_by !== null),
            isPrepaid: !svc.productName.toLowerCase().includes('7 days') && (svc.productName.toLowerCase().includes('prepaid') || svc.productName.toLowerCase().includes( 'pay'))
          };
        }
      } else {
        if (lockup[svc.id]) {
          return;
        } else {
          const product = this.store.selectSnapshot(ProductState.GetProductById(svc.productId));
          const category = product.category ? product.category : product.config.subtype;
          lockup[svc.id] = {
            isParentSvc: Boolean(services.find(s => s.parent_service_id === svc.id)),
            serviceObject: svc,
            subServices: {},
            category: category,
            isLegacy: this.checkIfIsLegacy(category, product, svc.assigned_by !== null),
            isPrepaid: (Boolean(svc.assigned_by !== null) || svc.productName.toLowerCase().includes('pay as you'))
          };
        }
      }
    });
    return lockup;
  }


  private checkIfIsLegacy(category: string, product, isAssignedBy: boolean): boolean {
    if (category === 'nvidia' || isAssignedBy) return false;

    const rain5GCatPIds = this.store.selectSnapshot(ProductState.getRainOneProductIDs5G);
    const rainOneUnlimitedProductIDs = this.store.selectSnapshot(ProductState.GetRainOneUnlimtedIDs);
    const rainOneUOPProductIDs = this.store.selectSnapshot(ProductState.GetRainOneUOPSimIDs);

    if (category === '5G') {
      if (rain5GCatPIds?.includes(product.id)) return false;
      return true;
    } else {
      if (product.name)
        if (rainOneUOPProductIDs?.includes(product.id) || rainOneUnlimitedProductIDs?.includes(product.id)) {
          return false;
        }
      return true;
    }
  }

}
