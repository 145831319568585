import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { isNil } from 'lodash';
import { GetUserProfile } from 'src/app/core/store/actions/auth.actions';
import { ClaimSim, DismissModals } from 'src/app/store/actions/assign-claim-sim.actions';
import { AssignAndClaimSimState } from 'src/app/store/state/assign-claim-sim.state';

@Component({
  selector: 'verify-sim-receiver',
  templateUrl: './verify-sim.component.html',
  styleUrls: ['./verify-sim.component.scss']
})
export class VerifySimComponent implements OnInit {
  form: FormGroup;
  formSubmitted: boolean = false;
  result: boolean;
  formSubmit: boolean;
  longICCID: string;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  constructor(private router: Router, private store: Store, private modalService: NgbModal) {
    const saId = this.store.selectSnapshot(AssignAndClaimSimState.getSaId);
  }

  ngOnInit(): void {
    $('body').addClass('.claim');
    this.store.dispatch(GetUserProfile)
    this.form = new FormGroup({
      encoded_iccid: new FormControl('', [Validators.required, Validators.minLength(5)])
      
    });

  }

  clearCustomErrors() {
    if (isNil(this.form.get('encoded_iccid').errors) == false && isNil(this.form.get('encoded_iccid').errors['notMatching']) == false) {
      this.form.get('encoded_iccid').setErrors(null);
    }
    this.form.updateValueAndValidity();
  }

  onSubmit() {
    this.formSubmit = true;

    if (this.form.invalid) {
      return;
    }


    const SIMICCID = this.form.get('encoded_iccid').value;

    this.store.dispatch(new ClaimSim(SIMICCID));
  }

  openModal() {
    const modalRef = this.modalService.open(this.modalContent, {
      size: 'sm',
      windowClass: 'slideInUp',
      centered: true
    });
  }

  dismiss() {
    this.modalService.dismissAll();
    this.store.dispatch(new DismissModals());
    // this.router.navigateByUrl(`service/${this.param}`)
  }
}
