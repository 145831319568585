import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import * as NvidiaActions from '../actions/nvidia.actions';
import { ProductState } from './product.state';
import { WebsiteconfigService } from 'src/app/core/services/websiteconfig.service';
import { ServicesState } from './services.state';
import { AddToWaitList, FetchGamesList, FiveGMigration, NvidiaPurchaseStarted, SetSelectedNvidiaTier, WaitingListQue } from '../actions/nvidia.actions';
import { WaitingListRequestBody } from '@pages/nvidia-landing-page/landing-page/nvidia-waiting-list.interface';
import { NvidiaService } from '@services/nvidia.service';
import { NvidiaGame, NvidiaGameImageInterface } from 'src/app/core/nvidia.interface';


export interface NvidiaStateModel {
  memberships: {
    ultra: boolean;
    priority: boolean;
  };
  selectedTier: string;
  hasMigrated: boolean;
  waitingList: WaitingListRequestBody;
  inWaitingList: boolean;
  gamesList: NvidiaGame[];
  searchedGameList: NvidiaGame[];
  loadingGameList: boolean; 
  gameListImages: NvidiaGameImageInterface[];
  loadingGameListImages: boolean; 
  purchaseStarted : boolean;
}

@State<NvidiaStateModel>({
  name: 'NvidiaState',
  defaults: {
    memberships: {
      ultra: false,
      priority: false
    },
    selectedTier: null,
    hasMigrated: false,
    waitingList: {
      full_name: '',
      email: '',
      contact_number: '',
      location: '',
      product: ''
    },
    inWaitingList: false,
    gamesList: [],
    searchedGameList: [],
    loadingGameList: true,
    gameListImages: [],
    loadingGameListImages: false,
    purchaseStarted : false
  }
})
@Injectable({ providedIn: 'root' })
export class NvidiaState {
  constructor(
    private webConfig: WebsiteconfigService,
    private nvidiaService: NvidiaService) { }

  @Selector([ServicesState.hasPostPaid, ProductState.GetNvidiaProducts])
  static getPriorityStatus(state: NvidiaStateModel, isPostPaid: boolean, nvidiaProducts) {
    const type = isPostPaid ? 'postpaid' : 'upfront';
    const products = nvidiaProducts.filter(nvidiaProduct => {
      return (nvidiaProduct?.config?.paymentType === type &&
        !nvidiaProduct?.name?.toLocaleLowerCase().includes('free') && !nvidiaProduct?.name?.toLocaleLowerCase().includes('public'))
    });

    let filteredProducts = [];
    
    products.forEach(product => {
      filteredProducts.push({
        ...product,
        active: state.memberships[product?.config?.tier]
      });
    });

    return filteredProducts;
  }

  @Selector()
  static getSelectedNvidiaTier(state: NvidiaStateModel) {
    return state.selectedTier;
  }

  @Selector()
  static checkForNvidiaMigration(state: NvidiaStateModel) {
    return state.hasMigrated;
  }


  
  @Selector()
  static isNvidiaPurchase(state: NvidiaStateModel) {
    return state.purchaseStarted;
  }

  @Selector()
  static inWaitingList(state: NvidiaStateModel) {
    return state.inWaitingList;
  }

  @Selector([NvidiaState])
  static getGamesList(state: NvidiaStateModel) {
    return state.gamesList;
  }

  @Selector([NvidiaState])
  static getGamesListImages(state: NvidiaStateModel) {
    return state.gameListImages;
  }

  @Selector([NvidiaState])
  static searchedGameList(state: NvidiaStateModel) {
    return state.searchedGameList;
  }

  @Selector([NvidiaState])
  static loadingGameList(state: NvidiaStateModel) {
    return state.loadingGameList;
  }

  @Selector([NvidiaState])
  static loadingGameListImages(state: NvidiaStateModel) {
    return state.loadingGameListImages;
  }

  @Selector([NvidiaState.getGamesList])
  static getUniqueGamePublishersTitles(_state: NvidiaStateModel, gamesList: NvidiaGame[]) {


    let publisherList: string[] = gamesList.reduce((accumulator, currentItem) => {

      if (!accumulator.includes(currentItem.publisherName)) {
        accumulator.push(currentItem.publisherName)
      }
      return accumulator;
    }, [])
    publisherList.sort((a, b) => {
      const titleA = a;
      const titleB = b;

      if (titleA < titleB) {
        return -1;
      }
      else if (titleA > titleB) {
        return 1;
      }
      return 0;
    })
    return publisherList;
  }

  static getGamesBySearchWordAndPublishers(searchTerm: string, searchPublisher?: string[]) {
    return createSelector([NvidiaState.getGamesList], (games: NvidiaGame[]) => {
      if (searchPublisher.length > 0) {

        let filteredNames: NvidiaGame[] = [];
        searchPublisher.forEach((value) => {
          const match: NvidiaGame[] = games.filter(game => game.publisherName === value);
          filteredNames = [...filteredNames, ...match];

        });
        const searchByTerm = filteredNames.filter(game => game.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
        return searchByTerm;
      }
      else {
        return games.filter(game => game.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
      }
    })
  }

  static getGamesByPublisher(searchPublisher: string[]) {
    return createSelector([NvidiaState.getGamesList], (games: NvidiaGame[]) => {
      let filteredNames: NvidiaGame[] = [];
      searchPublisher.forEach((value) => {
        const match: NvidiaGame[] = games.filter(game => game.publisherName === value);
        filteredNames = [...filteredNames, ...match];

      });

      return filteredNames;
      // return 
    })
  }

  @Action(NvidiaActions.SetSelectedNvidiaTier)
  getSelectedNvidiaTier(ctx: StateContext<NvidiaStateModel>, action: SetSelectedNvidiaTier) {
    ctx.patchState({
      selectedTier: action.tier
    });
  }

  @Action(NvidiaActions.FiveGMigration)
  checkForNvidiaMigration(ctx: StateContext<NvidiaStateModel>, action: FiveGMigration) {
    ctx.patchState({
      hasMigrated: action.hasMigrated
    });
  }


  @Action(NvidiaActions.NvidiaPurchaseStarted)
  checkForNvidiaPurchase(ctx: StateContext<NvidiaStateModel>, action: NvidiaPurchaseStarted) {
    ctx.patchState({
      purchaseStarted: action.purchaseStarted
    });
  }


  @Action(NvidiaActions.WaitingListQue)
  inWaitingList(ctx: StateContext<NvidiaStateModel>, action: WaitingListQue) {
    ctx.patchState({
      inWaitingList: action.inWaitingList
    });
  }

  @Action(NvidiaActions.Memberships)
  getMemberships(ctx: StateContext<NvidiaStateModel>) {
    return this.webConfig.fetchGeforceMemberships().subscribe({
      next: res => {
        ctx.patchState({
          memberships: res?.memberships
        });
      }
    });
  }


  @Action(NvidiaActions.AddToWaitList)
  addToWaitingList(ctx: StateContext<NvidiaStateModel>, action: AddToWaitList) {
    const body: WaitingListRequestBody = {
      full_name: action.payload.full_name,
      email: action.payload.email,
      contact_number: action.payload.contact_number,
      location: action.payload.location,
      product: action.payload.product
    };
    return this.nvidiaService.AddToNvidiaWaitingList(body)
  }

  @Action(NvidiaActions.FetchGamesList)
  getGamingList(ctx: StateContext<NvidiaStateModel>, action: FetchGamesList) {
    // [TODO]  - convert loop to interval with switchMap

    let sortedGameList: NvidiaGame[] = [];

    ctx.patchState({loadingGameList: true});

    return this.nvidiaService.fetchGameList().subscribe(fetchResults => {

      sortedGameList = fetchResults.data.apps.items.slice();
    
      if (fetchResults.data.apps.pageInfo.hasNextPage) {
        return this.nvidiaService.fetchGameList(fetchResults.data.apps.pageInfo.endCursor).subscribe(fetchResults => {
          sortedGameList = [...sortedGameList, ...fetchResults.data.apps.items]; 
          if (fetchResults.data.apps.pageInfo.hasNextPage) {
            return this.nvidiaService.fetchGameList(fetchResults.data.apps.pageInfo.endCursor).subscribe(fetchResults => {
              sortedGameList = [...sortedGameList, ...fetchResults.data.apps.items];
              sortedGameList.sort((a, b) => {
                const titleA = a.title.toLowerCase();
                const titleB = b.title.toLowerCase();
        
                if (titleA < titleB) {
                  return -1;
                }
                if (titleA > titleB) {
                  return 1;
                }
                return 0;
              })
              
              ctx.patchState({ gamesList: sortedGameList, loadingGameList: false })
            })
          }

        })
      }
    })
  }

  @Action(NvidiaActions.FilterGameListBySearch) 
  filterGamesBySearch(ctx: StateContext<NvidiaStateModel>, action: NvidiaActions.FilterGameListBySearch) {

      const { publishers, term } = action.payload;
      const state = ctx.getState();

      if (publishers.length > 0) {

        let filteredNames: NvidiaGame[] = [];
        publishers.forEach((value) => {
          const match: NvidiaGame[] = state.gamesList.filter(game => game.publisherName === value);
          filteredNames = [...filteredNames, ...match];

        });
        const searchByTerm = filteredNames.filter(game => game.title.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
        ctx.patchState({ searchedGameList: searchByTerm});
        return searchByTerm;
       
      }
      else {
        ctx.patchState({ searchedGameList: state.gamesList.filter(game => game.title.toLocaleLowerCase().includes(term.toLocaleLowerCase()))});
      }
  }

  @Action(NvidiaActions.FetchGameListImageLinks) 
  fetchGameListImageLinks(ctx: StateContext<NvidiaStateModel>, action: NvidiaActions.FetchGameListImageLinks) {
    ctx.patchState({loadingGameListImages: true})
    return this.nvidiaService.fetchGameListImages().subscribe(fetchResults => {
      ctx.patchState({loadingGameListImages: false})
      ctx.patchState({gameListImages: fetchResults.data.apps.items})
    });
  }

  @Action(NvidiaActions.ClearGameSearch)
  clearGamesSearch(ctx: StateContext<NvidiaStateModel>, action: NvidiaActions.ClearGameSearch) {

        ctx.patchState({searchedGameList: []})
  }
}
