import { IRemoteCheckout } from './remote/remoteCheckout';
import { ModelHelper } from './modelHelper';
import { isNil, isEmpty } from 'lodash';

export class CheckoutRequest {
  id?: string;
  amount?: string;
  type: string;
  reference?: string;
  order_id?: string;
  channel?: string;
  checkoutId?: string;
  userId?: string;
  arrear_billNo?: string;
  merchant_transaction_id?: string;
}

export class CheckoutResponse {
  amount?: number;
  type: string;
  id?: string;
  orderId?: string;
  completed?: boolean;
  message: string;
  status: CheckoutStatuses;

  static adapt(remote: IRemoteCheckout): CheckoutResponse {

    const status = this.mapStatus(remote.message);

    let checkout: CheckoutResponse = {
      orderId: ModelHelper.clean(remote.order_id),
      id: ModelHelper.clean(remote.checkout),
      type: ModelHelper.clean(remote.type),
      completed: remote.completed,
      message: remote.message,
      status
    };

    if (isNil(remote.completed)) {

      checkout.status = CheckoutStatuses.Failed;

      checkout.completed = true;
    }

    return checkout;
  }

  public static mapStatus(message: string): CheckoutStatuses {

    const firstSegment = isNil(message) == false && isEmpty(message) == false ? message.split(':')[0] : '';

    switch (firstSegment) {
      case "100.380.501": return CheckoutStatuses.TransactionTimeOut
      case "800.100.152": return CheckoutStatuses.PaymentDeclined
      case "100.380.401": return CheckoutStatuses.IncorrectOTP
      case "800.100.153": return CheckoutStatuses.IncorrectCCV
      case "100.390.112":
      case "100.390.105": return CheckoutStatuses.ThreeDTechnical
      case "800.100.157": return CheckoutStatuses.CardExpired
      case "800.100.151": return CheckoutStatuses.InvalidCard

      case "Transaction OK":
      case "Card registration done": return CheckoutStatuses.Success
      default: return CheckoutStatuses.None

    }
  }

  public static mapStatusMessage(status: CheckoutStatuses): string {

    switch (status) {
      case CheckoutStatuses.TransactionTimeOut: return "It seems you didn't capture your OTP in time and the transaction timed out. If you didn't see your 3D Secure page from your bank, please double check that your browser isn't preventing the page from loading. Please confirm your details and try again.";
      case CheckoutStatuses.PaymentDeclined: return "Please try again or contact your bank for assistance.";
      case CheckoutStatuses.IncorrectOTP: return "Your OTP was entered incorrectly. Please try again.";
      case CheckoutStatuses.IncorrectCCV: return "It seems your CVV details (3 digit number on the back of your card) are incorrect. Please double check your details and try again.";
      case CheckoutStatuses.ThreeDTechnical: return "Please re-enter your OTP, if this fails please check with your bank that your card is 3D secure.";
      case CheckoutStatuses.CardExpired: return "Your card expiry date was entered incorrectly. Please double check your details and try again.";
      case CheckoutStatuses.InvalidCard: return "We are receiving an invalid card error. Please double check your details and try again.";
      default: return "";
    }
  }
}


export enum CheckoutStatuses {
  None = 0,
  TransactionTimeOut,
  PaymentDeclined,
  IncorrectOTP,
  ThreeDTechnical,
  IncorrectCCV,
  CardExpired,
  InvalidCard,
  Success,
  Failed
}
