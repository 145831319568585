import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-voucher-reedem-success',
  templateUrl: './voucher-reedem-success.component.html',
  styleUrls: ['./voucher-reedem-success.component.scss']
})
export class VoucherReedemSuccessComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    localStorage.removeItem('two_for_one');
  }

  close() {
    this.activeModal.close();
  }



}
