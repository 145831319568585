import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Base64UrlService {

  constructor() { }
  
  decodeBase64Url(encodedUrl: string): string {
    try {
      const decodedUrl = atob(encodedUrl);
      return decodeURIComponent(decodedUrl);
    } catch (error) {
      console.error('Error decoding Base64 URL:', error);
      return '';
    }
  }
}
