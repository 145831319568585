import { WaitingListRequestBody } from '@pages/nvidia-landing-page/landing-page/nvidia-waiting-list.interface';
import { Utils } from 'src/app/utils';

export class UltraPlanEnabled {
  public static type = Utils.Helpers.Type('[NVIDIA] Checks if the nvidia ultra plan is enabled');
  constructor(public ultraEnabled: boolean) {}
}

export class Memberships {
  public static type = Utils.Helpers.Type('[NVIDIA] Checks if the nvidia memberships plan is enabled');
}

export class SetSelectedNvidiaTier {
  public static type = Utils.Helpers.Type('[NVIDIA] Sets the ID of the selected nvidia tier');
  constructor(public tier: string) {}
}


export class FiveGMigration {
  public static type = Utils.Helpers.Type('[NVIDIA : 5G migration] Checks to see if 5G migration has occurred through NVIDIA page');
  constructor(public hasMigrated: boolean) {}
}


export class AddToWaitList {
  public static type = Utils.Helpers.Type('[NVIDIA] Adds none whitelisted users to the nvidia waiting list');
  constructor(public payload: WaitingListRequestBody) {}
}

export class WaitingListQue {
  public static type = Utils.Helpers.Type('[NVIDIA] Checks to see if a customer is in the nvidia waiting list');
  constructor(public inWaitingList: boolean) {}
}

export class FetchGameListImageLinks {
  public static type = Utils.Helpers.Type('[NVIDIA] - Get only gaming image links');
}

export class FetchGamesList {
  public static type = Utils.Helpers.Type('[NVIDIA] - Get Nvidia Games for display.')
}

export class FilterGameListBySearch {
  public static type = Utils.Helpers.Type('[NVIDIA] - Get Nvidia Games by search term and publishers.');
  constructor(public payload: { term: string, publishers: string[]}) {}
}

export class ClearGameSearch {
  public static type = Utils.Helpers.Type('[NVIDIA] - Clear game search');
}

export class NvidiaPurchaseStarted {
  public static type = Utils.Helpers.Type('[NVIDIA] Checks to see if  an NVIDIA purchase has started');
  constructor(public purchaseStarted: boolean) {}
}

