<div class="nvidia-cancellation-container">
  <div class="close-modal" (click)="activeModal.dismiss('Cross click')">
    <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" />
  </div>
  <div class="content-container">
    <div class="modal-title">
      <h2>we’re sad to see you go</h2>
    </div>
    <div class="modal-contents">
      <p>Your GeForce NOW membership plan cancellation will only happen at the end of your bill cycle. If you change
        your mind between now and then, you can reverse your cancellation.</p>
    </div>
  </div>
  <div class="button-container">
    <button-v2 [buttonClass]="'cancel-modal-btn'" [text]="'got it'" (click)="close()"></button-v2>
  </div>
</div>