import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

@Component({
  selector: 'rain-cancellation-success-five-g-modal',
  templateUrl: './cancellation-success-five-g-modal.component.html',
  styleUrls: ['./cancellation-success-five-g-modal.component.scss']
})
export class CancellationSuccessFiveGModalComponent implements OnDestroy {

  constructor(private store: Store, public activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new Navigate(['/manage/services']))
  }

}
