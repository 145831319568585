import { IUserDetail } from '@models/userDetail';
import { UserService } from '@services/user.service';
import { AuthenticationService } from '@services/auth.service';
import { ConfigService } from '@services/config.service';
import { MAP_SEARCH_CONFIG } from '@models/constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAddTicketRequest } from '@services/support.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { isNil } from 'lodash';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { BaseComponent } from 'src/app/baseComponent';
import { DeliveryAddress, IDeliveryAddress } from '@services/cart.service';

@Component({
  selector: 'app-sim-swop-modal',
  templateUrl: './sim-swop-modal.component.html',
  styleUrls: ['./sim-swop-modal.component.scss']
})
export class SimSwopModalComponent extends BaseComponent implements OnInit {

  @Output() submitted = new EventEmitter();

  loading = false;


  formattedAddress: string;
  deliveryAddress: IDeliveryAddress;
  form: FormGroup;
  ticketRequest: IAddTicketRequest;
  config = MAP_SEARCH_CONFIG;
  formSubmit: boolean;
  siteKey: string;
  isSignedIn: boolean;
  userDetail: IUserDetail;
  name: string;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private configService: ConfigService,
    private authService: AuthenticationService,
    private userService: UserService,
  ) {
    super();
    this.siteKey = this.configService.SITE_KEY;
  }

  ngOnInit(): void {
    this.isSignedIn = this.authService.isSignedIn;
    this.form = new FormGroup(
      {
        address: new FormControl('', [Validators.required, this.verifyAddress]),
        complex: new FormControl(''),
        number: new FormControl(''),
        deliveryNote: new FormControl(''),
        alternativeNumber: new FormControl('',[Validators.required])
      });
  }

  verifyAddress = (control: AbstractControl): { [key: string]: boolean } | null => {
    return this.validateGoogleAddress(control.value);
  }

  validateGoogleAddress(inputValue) {
    let invalid = false;
    if (isNil(this.deliveryAddress)) {
      invalid = true;
    } else if (inputValue !== this.formattedAddress) {
      invalid = true;
    }

    if (invalid) {
      return { invalid: true };
    }

    return null;
  }



  handleMapSearch = (result: GoogleAddress) => {

    this.deliveryAddress = DeliveryAddress.mapfromGoogle(result);
    this.formattedAddress = result.formatted_address;
    this.form.get('address').setValue(result.formatted_address);
  }

  submit() {

    this.loading = true;

    this.formSubmit = true;
    if (this.form.invalid) {
      this.loading = false;
      return;
    }

    this.loading = true;

    let deliveryNote = '';

    if (this.form.controls.number.value) {
      deliveryNote += 'no: ' + this.form.controls.number.value + ' ';
    }

    if (this.form.controls.complex.value) {
      deliveryNote += this.form.controls.complex.value + ', ';
    }

    if (this.formattedAddress) {
      deliveryNote += this.formattedAddress + ' ';
    }

    if (this.form.controls.deliveryNote.value) {
      deliveryNote += ' -- DELIVERY NOTE -- :' + this.form.controls.deliveryNote.value;
    }

    deliveryNote+= '--Alternative Number -- : ' + this.form.controls.alternativeNumber.value+'--';



    this.submitted.emit({
      message: deliveryNote,
      address: this.formattedAddress
    });

  }


}
