import * as CryptoJS from 'crypto-js';

export class Encryptor {
    private password: string = 'ypxj6koh&4W^pD9H6dkVF#e0BHS1T!Vl7dVNxRl#3ra^r3s$rZ6a&6MW17%07!5p';

    public encrypt(data: string) {        
        return CryptoJS.AES.encrypt(data.trim(), this.password.trim()).toString();
    }

    public decrypt(data: string) {        
        return CryptoJS.AES.decrypt(data.trim(), this.password.trim()).toString(CryptoJS.enc.Utf8);
    }
}
