import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ChangeOpenBottomSheet, CloseBottomSheet } from 'src/app/v2/store/actions/bottom-sheet.actions';
import { BottomSheetName } from '../../../store/interfaces/bottom-sheet.interfaces';
import { BottomSheetState } from '../../../store/state/bottom-sheet-state';

@Component({
  selector: 'rain-bottom-sheet-container',
  templateUrl: './bottom-sheet-container.component.html',
  styleUrls: ['./bottom-sheet-container.component.scss']
})
export class BottomSheetContainerComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  OpenSheet$: Observable<BottomSheetName>;

  constructor(private store: Store, private bottomSheetRef: MatBottomSheetRef, private elementRef: ElementRef, private actions$: Actions) { }

  ngOnInit(): void {
    this.OpenSheet$ = this.store.select(BottomSheetState.OpenSheetName);
    this.listenForBackDropClick();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.bottomSheetRef?.afterOpened()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.setBottomSheetScrollConfig();
        })

      this.bottomSheetRef?.afterDismissed()
        .pipe(take(1))
        .subscribe(() => {
          this.enableBodyScroll()
          this.store.dispatch(new CloseBottomSheet())
        })

    }, 100)

    this.actions$.pipe(
      takeUntil(this.ngUnsubscribe),
      ofActionSuccessful(ChangeOpenBottomSheet)).subscribe({
        next: () => {
          setTimeout(() => {
            this.setBottomSheetScrollConfig()
          }, 100)
        }
      });
  }

  setBottomSheetScrollConfig() {

    this.disableBodyScroll()

    const handle = this.elementRef.nativeElement.querySelector('.handle');

    const container = document.querySelector('.mat-bottom-sheet-container') as HTMLElement;

    const sheetContent = this.elementRef.nativeElement.querySelector('.sheet-content-container') as HTMLElement;

    const minHeight = sheetContent.clientHeight;

    handle.addEventListener('touchmove', (event: TouchEvent) => {
      const movement = event?.touches?.[0]?.clientY;
      if (movement) {
        const newHeight = ((window?.innerHeight - movement) + 10);
        container.style.height = `${String(newHeight)}px`;
      }

      if (!movement && container.clientHeight < minHeight) {
        this.bottomSheetRef?.dismiss();
      }

      handle.addEventListener('touchend', (event: TouchEvent) => this.dismissSheetCheck(container.clientHeight, minHeight, handle))
    });

  }

  dismissSheetCheck(containerHeight: number, minHeight: number, handle: HTMLElement) {
    if (containerHeight < minHeight) {
      this.bottomSheetRef?.dismiss();
      handle.removeEventListener('touchend', (event) => this.dismissSheetCheck(containerHeight, minHeight, handle))
    }
  }

  enableBodyScroll() {
    document.body.style.overscrollBehaviorY = "initial"
    document.body.style.overflowY = "initial"
    document.querySelector('html')!.style.overscrollBehaviorY = "initial"
    document.querySelector('html')!.style.overflowY = "initial"
  }

  disableBodyScroll() {
    document.body.style.overscrollBehaviorY = "none"
    document.body.style.overflowY = "hidden"
    document.querySelector('html')!.style.overscrollBehaviorY = "none"
    document.querySelector('html')!.style.overflowY = "hidden"
  }

  listenForBackDropClick(): void {
    this.bottomSheetRef.backdropClick().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.store.dispatch(new CloseBottomSheet());
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
