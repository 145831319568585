<ng-container *ngIf="{
    ap: ap$ | async,
    image: image$ | async
} as viewModel">

    <div *ngIf="viewModel.ap as ap" [class]="'section section-product-bundle is-'+ ap">
        <div class="inner-section" [class.hideCard]="hideCard">
            <div class="top-section" [class.card-less-top]="hideCard">

                <div class="logo-lockup" *ngIf="!currentProduct">
                    <img src="assets/images/rain-one/rain-one-logo.svg" alt="Rain One"
                         *ngIf="ap !== 'mobile' else mobileLogo">
                    <ng-template #mobileLogo>
                        <img src="assets/images/rain-one/rain-one-logo.svg" alt="Rain One">
                    </ng-template>
                </div>
                <div *ngIf="!currentProduct" [class]="'price-ear price-ear-'+ap ">
                    <div>R559</div>
                    <div>month-to-month</div>
                </div>
            </div>

            <div class="product-lockup">
                <ul [class.card-less-spacing]="hideCard">
                    <li class="product">
                        <div class="card-header-section">
                            <h3>{{ currentProduct ? 'your current home wifi' : 'unlimited 5G home wifi' }}</h3>
                        </div>
                        <rain-rain-one-product [showBoost]="showBoost" [product]="products[0]"
                                               [name]="getCPEName()"></rain-rain-one-product>

                    </li>
                    <li class="plus">
                        <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 22.1875H40" stroke="#0077C8" stroke-width="3.93103" stroke-linecap="round"/>
                            <path d="M21 2.53125L21 40.5313" stroke="#0077C8" stroke-width="3.93103"
                                  stroke-linecap="round"/>
                        </svg>
                    </li>
                    <div class="addon-lockup">
                        <div class="card-header-section">
                            <h3>FREE monthly calls, sms & data for 2 phones*</h3>
                        </div>
                        <div class="wrapper mobile-product-wrapper">
                            <rain-rain-one-product [product]="products[1]" [name]="'SIM 1'"></rain-rain-one-product>
                            <rain-rain-one-product [product]="products[2]" [name]="'SIM 2'"></rain-rain-one-product>
                        </div>
                    </div>
                </ul>
            </div>

            <div class="action-lockup">
                <a (click)="navigate()">Ts & Cs apply</a>
                <div>

                    <div>
                        <app-support-button
                                btnClass="btn btn-sm btn-transparent border-primary text-primary mr-4"
                                [dataId]="'btn-click'" [isPromo]="false" [fromPage]="fromPage"
                                fromButton="productCallMe" title="request sales call" id="btn-cust"
                                [subject]="subject || 'rain sales support #'"
                                [bypassModal]="false" [productId]="rainOneL1Product.id" [showSignIn]="false"
                                [ga_tag]="getGaTag()"></app-support-button>
                    </div>
                    <div *ngIf="!currentProduct && !isUpsell">
                        <button data-id="buy-now-button" data-value="data-selenium-id" (click)="addToCart()"
                                [disabled]="isDisabled">buy now
                        </button>
                    </div>
                    <div *ngIf="currentProduct && !isUpsell" class="upgrade-button">
                        <button
                                (click)="navToMigrate()">upgrade for FREE
                        </button>
                    </div>

                    <div *ngIf="isUpsell">
                        <button (click)="emitAcceptUpsell()">accept</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>