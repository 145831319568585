import { Component, OnInit, Input  } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { EmptyCart } from 'src/app/store/actions/cart.action';
import { Navigate } from '@ngxs/router-plugin';
import { RemoveRainOneFromCart } from 'src/app/store/actions/cart.action';
import { Router } from '@angular/router';


@Component({
  selector: 'rain-nvidia-out-of-coverage-modal',
  templateUrl: './nvidia-out-of-coverage-modal.component.html',
  styleUrls: ['./nvidia-out-of-coverage-modal.component.scss']
})
export class NvidiaOutOfCoverageModalComponent implements OnInit {
  @Input() IsNew5gCustomer: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public removeNvidiaGoHome() {
    this.store.dispatch([new EmptyCart(), new Navigate(['/'])]);
    this.activeModal.close('Modal Closed');
  }

  public removeRainOne() {
    this.store.dispatch([new RemoveRainOneFromCart()]);
    this.router.navigate(['/payment-details']);
    this.activeModal.close('Modal Closed');
  }

}
