import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SubjectService {
    updateText = new Subject<boolean>();
    serviceUpdate = new Subject<string>();
    fourgUpsell = new Subject<boolean>();
    simSwap = new Subject<boolean>();
    cancel5G = new Subject<boolean>();
    other4GTO5GMigration = new Subject<boolean>();
    fivegTofiveMigration = new Subject<string>();
    reserveMigration = new Subject<boolean>();
    serviceCancellation = new Subject<boolean>();

    constructor() { }

    updateArrearsText() {
        this.updateText.next(true);
    }

    subscribeUpdateArrearsText(): Observable<any> {
        return this.updateText.asObservable();
    }

    pingServiceUpdate(id: string) {
        this.serviceUpdate.next(id);
    }

    fourgUpsellResponse(buy: boolean): void {
        this.fourgUpsell.next(buy);
    }

    subscribeFourgUpsellResponse(): Observable<any> {
        return this.fourgUpsell.asObservable();
    }

    simSwapResponse(buy: boolean): void {
        this.simSwap.next(buy);
    }

    subscribeSimSwapResponse(): Observable<any> {
        return this.simSwap.asObservable();
    }

    cancel5GResponse(buy: boolean): void {
        this.cancel5G.next(buy);
    }

    subscribeCancel5GResponse(): Observable<any> {
        return this.cancel5G.asObservable();
    }

    subscribePingServiceUpdate(): Observable<any> {
        return this.serviceUpdate.asObservable();
    }

    other4GTO5GMigrationResponse(loggedTicketSuccessfully: boolean): void {
        this.other4GTO5GMigration.next(loggedTicketSuccessfully);
    }

    subscribeOther4GTO5GMigrationResponse(): Observable<any> {
        return this.other4GTO5GMigration.asObservable();
    }

    fivegTofiveMigrationnResponse(schedule: string): void {
        this.fivegTofiveMigration.next(schedule);
    }

    subscribeFivegTofiveMigrationResponse(): Observable<any> {
        return this.fivegTofiveMigration.asObservable();
    }

    reserveMigrationResponse(reverse: boolean): void {
        this.reserveMigration.next(reverse);
    }

    subscribeReserveMigrationResponse(): Observable<any> {
        return this.reserveMigration.asObservable();
    }

    serviceCancellationResponse(cancel: boolean): void {
        this.serviceCancellation.next(cancel);
    }

    subscribeServiceCancellationResponse(): Observable<any> {
        return this.serviceCancellation.asObservable();
    }
}