import { ModelHelper } from '@models/modelHelper';

/**
 *  INeighbourDetail @param
 */
export interface INeighbourDetail {
  /**
   *  siteId @param {number}
   */
  siteId: number;

  /**
   *  siteName @param {string}
   */
  siteName: string;

  /**
   * latitude @param {number}
   */
  latitude: number;

  /**
   *  longitude @param {number}
   */
  longitude: number;

  /**
   *  distance @param {number}
   */
  distance: number;
}

/**
 * IRemoteNeighbour @param
 */
export interface IRemoteNeighbour {
  /**
   * location_id @param {number}
   */
  location_id: number;

  /**
   * site_name @param {string}
   */
  site_name: string;

  /**
   * latitude @param {number}
   */
  latitude: number;

  /**
   * longitude @param {number}
   */
  longitude: number;

  /**
   * distance_km @param {number}
   */
  distance_km: number;
}

/**
 * NeighbourDetail @param
 */
export class NeighbourDetail {
  static adapt(remote: IRemoteNeighbour): INeighbourDetail {
    /**
     * neighbour @param
     */
    //73 sitename 5g
    const neighbour = {
      siteId: remote.location_id,
      siteName: remote.site_name,
      latitude: remote.latitude,
      longitude: remote.longitude,
      distance: remote.distance_km
    };

    return neighbour;
  }
}
