<div class="content-wrapper p-xs-20 card need-help-card">
  <div class="header-wrapper">
    <div class="action-card-header header-titles">need <strong>help</strong>?</div>
    <div class="buttons-bg">
      <button class="btn btn-sm need-help-opt-btn" [class.need-help-active-btn]="activeTab === 'network'"
        (click)="toggleButton('network')">
        network
      </button>
      <button class="btn btn-sm need-help-opt-btn" [class.need-help-active-btn]="activeTab === 'billing'"
        (click)="toggleButton('billing')">
        billing
      </button>
    </div>
    <div class="card-content">
      <span class="need-help-network-content-title">
        I need <strong>{{ buttonTexts[activeTab] }} assistance</strong></span><br />
      <span> Please fill in the details below and one of our agents will get in touch shortly.</span>
    </div>
    <div class="need-help-form-wrapper" *ngIf="activeTab === 'network' && !pageConfig.isLegacyService">
      <form (ngSubmit)="submitForm()" [formGroup]="contactSupportFormNetwork">
        <!-- Product Select -->

        <mat-form-field>
          <mat-label class="choose-product-text">Choose product</mat-label>
          <mat-select formControlName="product" required>
            <mat-option *ngFor="let name of serviceNamesMap" [value]="name">{{name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Address Input -->
        <div class="form-group">
          <sf-google-placesform-input class="support-address-auto form-control textInputColor" id="addressInput"
            placeholder="Enter usage address" [control]="contactSupportFormNetwork.get('address')"
            (onAddressChange)="handleAddressChange($event, contactSupportFormNetwork)">
          </sf-google-placesform-input>
        </div>

        <!-- Comments Textarea -->
        <div class="form-group">
          <textarea id="commentTextarea" class="form-control" formControlName="comments" placeholder="Comments"
            maxlength="500" class="textInputColor"></textarea>
          <div
            *ngIf="contactSupportFormNetwork.get('comments').invalid && (contactSupportFormNetwork.get('comments').dirty || contactSupportFormNetwork.get('comments').touched)"
            class="text-danger">
            Valid comment required.
          </div>
        </div>

        <!-- Submit Button -->
        <div class="action-button-wrapper p-4">
          <button type="submit" class="btn btn-sm need-help-submit-btn" [disabled]="contactSupportFormNetwork.invalid || !addressSet">
            <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Submit</button>
        </div>
      </form>

    </div>
    <div class="need-help-form-wrapper" *ngIf="activeTab == 'billing' && !pageConfig.isLegacyService">

      <form (ngSubmit)="submitForm()" [formGroup]="contactSupportFormBilling">
        <!-- Product Select -->
        <!-- <mat-form-field>
          <mat-label>Choose product</mat-label>
          <mat-select formControlName="product" required>
            <mat-option *ngFor="let name of serviceNamesMap" [value]="name">{{name}}</mat-option>
          </mat-select>
        </mat-form-field> -->

        <!-- Comments Textarea -->
        <div class="form-group">
          <textarea id="commentTextarea" class="form-control" formControlName="comments" placeholder="Comments"
            maxlength="500" class="textInputColor"></textarea>
          <div
            *ngIf="contactSupportFormBilling.get('comments').invalid && (contactSupportFormBilling.get('comments').dirty || contactSupportFormBilling.get('comments').touched)"
            class="text-danger">
            valid comment required.
          </div>
        </div>

        <!-- Submit Button -->
        <div class="action-button-wrapper p-4">
          <button type="submit" class="btn btn-sm need-help-submit-btn" [disabled]="contactSupportFormBilling.invalid">
            <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Submit</button>
        </div>
      </form>

    </div>
    <div class="need-help-form-wrapper" *ngIf="activeTab == 'network' && pageConfig.isLegacyService">
      <form (ngSubmit)="submitForm()" [formGroup]="contactSupportFormLegacyNetwork">

        <!-- Address Input -->
        <div class="form-group">
          <sf-google-placesform-input class="form-control textInputColor support-address-auto" id="addressInput"
            placeholder="Enter usage address" [control]="contactSupportFormLegacyNetwork.get('address')"
            (onAddressChange)="handleAddressChange($event, contactSupportFormLegacyNetwork)">
          </sf-google-placesform-input>
        </div>

        <!-- Comments Textarea -->
        <div class="form-group">
          <textarea id="commentTextarea" class="form-control" formControlName="comments" placeholder="Comments"
            maxlength="600" class="textInputColor"></textarea>
          <div
            *ngIf="contactSupportFormLegacyNetwork.get('comments').invalid && (contactSupportFormLegacyNetwork.get('comments').dirty || contactSupportFormLegacyNetwork.get('comments').touched)"
            class="text-danger">
            Valid comment required.
          </div>
        </div>

        <!-- Submit Button -->
        <div class="action-button-wrapper p-4">
          <button type="submit" class="btn btn-sm need-help-submit-btn"
            [disabled]="contactSupportFormLegacyNetwork.invalid || !addressSet">
            <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Submit</button>
        </div>
      </form>

    </div>
    <div class="need-help-form-wrapper" *ngIf="activeTab == 'billing' && pageConfig.isLegacyService">
      <form (ngSubmit)="submitForm()" [formGroup]="contactSupportFormLegacyBilling">

        <!-- Comments Textarea -->
        <div class="form-group">
          <textarea id="commentTextarea" class="form-control" formControlName="comments" placeholder="Comments"
            maxlength="500" class="textInputColor"></textarea>
          <div
            *ngIf="contactSupportFormLegacyBilling.get('comments').invalid && (contactSupportFormLegacyBilling.get('comments').dirty || contactSupportFormLegacyBilling.get('comments').touched)"
            class="text-danger">
            valid comment required.
          </div>
        </div>

        <!-- Submit Button -->
        <div class="action-button-wrapper p-4">
          <button type="submit" class="btn btn-sm need-help-submit-btn"
            [disabled]="contactSupportFormLegacyBilling.invalid">
            <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Submit
          </button>
        </div>
      </form>

    </div>
  </div>

  <div class="overlay" *ngIf="showOverlay">

    <div class="feed-back" *ngIf="successMessage">
      <div class="success-message"> <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
          class="bi bi-check2-circle" viewBox="0 0 30 30">
          <path
            d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
        </svg>
        <p>Request successfully sent</p>
      </div>
    </div>
    <div class="feed-back" *ngIf="errorMessage">
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-x-circle"
        viewBox="0 0 30 30">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
      </svg>
      <p>You can only log one {{ buttonTexts[activeTab] }}<br> ticket per day.</p>
    </div>

    <button class="btn btn-sm need-help-got-it-btn" (click)="gotIt()">
      got it
    </button>
  </div>
</div>