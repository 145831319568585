import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-festive-billing-modal',
  templateUrl: './festive-billing-modal.component.html',
  styleUrls: ['./festive-billing-modal.component.scss']
})
export class FestiveBillingModalComponent implements OnInit {
  @Input() isCancellation: boolean;
@Input() isDowngrade : boolean;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }
  confirm() {
    this.activeModal.close();
  }
}
