<div class="modal-content border-0 px-sm-20">
  <div class="modal-header border-0 mb-10">
    <div class="modal-img-close-btn z-index-5">
      <img src="../../assets/svg/close.svg" class="pointer" alt="Close" (click)="activeModal.dismiss('close click')" />
    </div>
  </div>

  <div>

    <div class="text-center modal-body ">
      <h2 class="text-primary">ready to sign out?</h2>

      <p class="mb-0 pl-0 pr-0 pl-sm-25 pr-sm-25">
        Are you sure you want to sign out of your account now?
      </p>
    </div>

    <div class="modal-footer border-top-0 d-flex mb-10 justify-content-center">

      <button class="btn-width btn btn-sm btn-outline-blue m-2 px-10" (click)="signOut(false)">
        no
      </button>

      <button class="btn-width btn btn-sm btn-primary m-2 px-10" (click)="signOut(true)">
        yes
      </button>


    </div>

  </div>

</div>