<div id="video-modal" >
    <div class="modal-header border-0">
        <div class="modal-img-close-btn z-index-5">
            <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
                data-dismiss="modal" data-target="#thankYouModal" src="../../assets/svg/close.svg" />
        </div>
    </div>
    
    <div class="modal-body">
        <video width="100%" height="100%" playsinline controls #videoPlayerOne preload="none">
            <source *ngIf="(flags$ | async)?.sa_t20_promo else originalVideo" src="/assets/videos/RAIN -SA20-30-DIGITAL.mp4" type="video/mp4">
            <ng-template #originalVideo>
                <source src="/assets/videos/101-WEBSITE-30-SEC-1080p-31-OCT.mp4" type="video/mp4" >
            </ng-template>
            Your browser does not support the video tag.
        </video>
    </div>
</div>