import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AssignSimComponent } from "./assign-sim-page.component";
import { AuthenticatedGuard } from "src/app/guards/guards";

const routes: Routes = [
    {
        path: '',
        component: AssignSimComponent,
        canActivate: [AuthenticatedGuard]
    },]

    @NgModule({
        imports: [RouterModule.forChild(routes)],
        exports: [RouterModule]
    })
    export class AssignSimRoutingModule { }
    