<ng-container *ngIf="(viewModel$ | async) as viewModel">
    <!-- mobile -->
    <div class="options-wrapper" *ngIf="viewModel?.activeBreakpoint === 'mobile' || viewModel?.activeBreakpoint === 'ipad'">
        <!-- <span>
            <div class="prev" (click)="goPrev()">
                <img src="assets/images/rain-one/rain-one-carousel-btn.png" alt="">
            </div>
        </span>
        <span>
            <div class="next" (click)="goNext()">
                <img src="assets/images/rain-one/rain-one-carousel-btn.png" alt="">
            </div>
        </span> -->
        <swiper-container class="product-container" pagination="true" pagination-clickable="true" #swiper>
            <swiper-slide *ngFor="let item of viewModel?.nvidiaItems" class="product-item" [ngClass]="{'shop': location === 'shop'}">
                <div class="product-box" [ngClass]="checkSelected(item)? 'selected-product' : '' ">
                    <div class="sold-out" [ngClass]="{'sold-out-visible': location === 'shop' && !item?.active}" *ngIf="location==='shop'">
                        <div>
                            <p>sold-out</p>
                        </div>
                    </div>
                    <div class="header">{{ item?.config?.tier }}
                        <div class="top-box" *ngIf="checkSelected(item)">current plan</div>
                    </div>
                    <div class="product-body">
                        <div class="price-section">
                            <sub>month-to-month</sub>
                            <p> {{ item.price | currency:"R" }} </p>
                        </div>

                        <div class="discount-disclaimer">
                             <div class="disclaimer-background">
                                 <p>
                                     <strong>20% discount</strong>
                                     <br>
                                     for rain<strong>one</strong> customers
                                 </p>
                             </div>
                         </div>
    
                        <div class="features-section">
                            <ng-container *ngFor="let feature of item?.config?.features">
                                <div>
                                    <img src="assets/images/nvidia/list-check.png" alt="" />
                                    <span>{{ feature }}</span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="button-section" [ngClass]="{'manage': location==='manage'}">
                            <div *ngIf="location==='shop'" class="nvidia-purchase-block" >
                                <div class="nvidia-with-rainone sold-out-block show-sold-out" *ngIf="location === 'shop' && !item?.active">
                                    <p>
                                        Want GeForce NOW <span [style.fontWeight]="500">{{ item?.config?.tier }}</span> ?
                                        <br>
                                        Join the waitlist and we’ll let you know when a spot becomes available 
                                    </p>
                                    <button  class="waiting-list-button" (click)="openModal()">Join waiting list</button>
                                </div> 
                                <div class="nvidia-with-rainone purchase-block" *ngIf="location === 'shop' && item?.active">
                                    <p>
                                        GeForce NOW <span [style.fontWeight]="500">{{ item?.config?.tier }}</span> 
                                        <br>
                                        with rain<span [style.fontWeight]="700">one</span>.
                                    </p>
                                    <button [disabled]="viewModel?.cantPurchaseNvidiaWithRainOne" [ngClass]="{'disabled': viewModel?.cantPurchaseNvidiaWithRainOne}" (click)="startNvidiaWithRainOnePurchaseJourney(getRainPrice(item))">buy</button>
                                    <div class="discount-dog-ear">
                                        <span>
                                            <strong>20%</strong>
                                            <br>discount</span>
                                    </div>
                                </div>
                                <div class="nvidia-only purchase-block" *ngIf="location === 'shop' && item?.active">
                                    <p>
                                        GeForce NOW <span [style.fontWeight]="500">{{ item?.config?.tier }}</span>  
                                        <br>
                                        only.
                                    </p>
                                    <button [disabled]="viewModel?.cantPurchaseNvidiaOnly" [ngClass]="{'disabled': viewModel?.cantPurchaseNvidiaOnly}" (click)="startNonRainNvidiaPurchaseJourney(item)">buy</button>
                                </div>
                             
                            </div>
                            <button
                                class="btn btn-outline-blue btn btn-md  text-primary p-0 mb-4 font-weight-normal py-0 ml-auto mr-2" *ngIf="location==='manage'"
                                [disabled]="checkSelected(item) || pendingAction || !(viewModel?.goodAccountStanding)" (click)="migrateNvidiaTier(item)">
                                <span *ngIf="checkSelected(item) else notCurrentPlan">current plan</span>
                                <ng-template #notCurrentPlan>
                                    <span [ngClass]="{'disabled-button': isInArrears}">choose {{ item?.config?.tier }} plan</span>
                                </ng-template>
                            </button>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>

    <!-- desktop -->
    <div class="options-wrapper " *ngIf="viewModel?.activeBreakpoint === 'desktop' || viewModel?.activeBreakpoint === 'ipadPro'">

        <div class="product-container desktop-layout">
            <div *ngFor="let item of viewModel?.nvidiaItems;" class="product-item" [ngClass]="{'shop': location === 'shop'}">
                <div class="product-box" [ngClass]="checkSelected(item)? 'selected-product' : '' ">

                    <div class="sold-out" [ngClass]="{'sold-out-visible': location === 'shop' && !item?.active}" *ngIf="location==='shop'">
                        <div>
                            <p>sold-out</p>
                        </div>
                    </div>
                    <div class="header">{{ item?.config?.tier }}
                        <div class="top-box" *ngIf="checkSelected(item)">current plan</div>
                    </div>
                    <div class="product-body">
                        <div class="price-section">
                             <sub>month-to-month</sub>
                             <p> R{{item.price}} </p>
                         </div>
                         <div class="discount-disclaimer">
                             <div class="disclaimer-background">
                                 <p>
                                     <strong>20% discount</strong>
                                     <br>
                                     for rain<strong>one</strong> customers
                                 </p>
                             </div>
                         </div>
     
                         <div class="features-section">
                             <ng-container *ngFor="let feature of item?.config.features">
                                 <div>
                                     <img src="assets/images/nvidia/list-check.png" alt="" />
                                     <span>{{ feature }}</span>
                                 </div>
                             </ng-container>
                         </div>
                         <div class="button-section" [ngClass]="{'manage': location==='manage'}">
                            <div *ngIf="location==='shop'" class="nvidia-purchase-block">
                                <div class="nvidia-with-rainone sold-out-block show-sold-out" *ngIf="location === 'shop' && !item?.active">
                                    <p>
                                        Want GeForce NOW <span [style.fontWeight]="500">{{ item?.config?.tier }}</span> ?
                                        <br>
                                        Join the waitlist and we’ll let you know when a spot becomes available 
                                    </p>
                                    <button (click)="openModal()">Join waiting list</button>
                                </div>
                                <div class="nvidia-with-rainone purchase-block" *ngIf="location === 'shop' && item?.active">
                                    <p>
                                        GeForce NOW <span [style.fontWeight]="500">{{ item?.config?.tier }}</span> 
                                        <br>
                                        with rain<span [style.fontWeight]="700">one</span>.
                                    </p>
                                    <button [disabled]="viewModel?.cantPurchaseNvidiaWithRainOne" [ngClass]="{'disabled': viewModel?.cantPurchaseNvidiaWithRainOne}" (click)="startNvidiaWithRainOnePurchaseJourney(getRainPrice(item))">buy</button>
                                    <div class="discount-dog-ear">
                                        <span>
                                            <strong>20%</strong>
                                            <br>discount</span>
                                    </div>
                                </div>
                                <div class="nvidia-only purchase-block" *ngIf="location === 'shop' && item?.active">
                                    <p>
                                        GeForce NOW <span [style.fontWeight]="500">{{ item?.config?.tier }}</span>  
                                        <br>
                                        only.
                                    </p>
                                    <button [disabled]="viewModel?.cantPurchaseNvidiaOnly" [ngClass]="{'disabled': viewModel?.cantPurchaseNvidiaOnly}" (click)="startNonRainNvidiaPurchaseJourney(item)">buy</button>
                                </div>
                             
                            </div>

                            <button [ngClass]="{'disabled-button': isInArrears || !canMigrate}" *ngIf="location==='manage'"
                                 class="btn btn-outline-blue btn btn-md  text-primary p-0 mb-4 font-weight-normal py-0 ml-auto mr-2 migrate-button"
                                 [disabled]="checkSelected(item) || pendingAction || !viewModel?.goodAccountStanding " (click)="startNvidiaPlanJourney(getRainPrice(item), item)">
                                 <span *ngIf="checkSelected(item) else notCurrentPlan">current plan</span>
                                 <ng-template #notCurrentPlan>
                                     <span >choose {{ item?.config?.tier }} plan</span>
                                 </ng-template>
                            </button>
                         </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</ng-container>