<div class="modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
      (click)="hasAddress = false; activeModal.dismiss('Cross click')" />
  </div>
</div>
<div class="modal-body p-0  z-index-1">
  <div class="coverage-check ml-sm-20 ml-5 top-0 z-index-1 mx-10">
    <div class=" mb-5">
      <h2 class=" h2 text-primary font-weight mt-25 ">
        let's check if you're in coverage
      </h2>
    </div>

    <div class="mr-md-10 col-lg-6 col-md-12">
      <app-map-search (inputFocused)="inputFocused()" (onAddressChange)="handleMapSearch($event)" id="map-component"
        class="other " [placeholder]="'physical address'">
      </app-map-search>

    </div>
  </div>

  <div class="mb-10 p-5 pr-10">
    <div class="d-flex justify-content-end ">
      <button class="btn btn-md btn-primary" [class.btn-primary]="hasAddress" [class.btn-dark]="!hasAddress"
        id="button-search" id="button-shop" (click)="search()">search</button>
      <!-- <button data-text="Shop btn" data-id="btn-click" class="btn btn-md btn-primary" id="button-shop" (click)="shop()"
        *ngIf="addressFound">shop</button> -->
    </div>

  </div>

  <div *ngIf="addressFound && ((hasAddress && isMobile) || !isMobile)">
    <app-map [latitude]="latitude" [longitude]="longitude"></app-map>

    <p class="mt-5 px-10 small-font-size copy-right text-black l-h-0">
      <strong>Disclaimer:</strong> The information contained in this coverage map is for general information
      purposes only and does not guarantee actual coverage. While we endeavour to keep the information accurate and up
      to
      date, some factors influencing coverage varies over time such as terrain. Other factors include, but are not
      limited
      to a person’s residence in relation to the cellular enabled mobile device site and poor radio conditions within a
      person’s residential area as a result of environmental factors beyond rain’s control. rain makes no
      representations
      or warranties of any kind, whether expressed or implied, relating to the completeness, accuracy, reliability,
      suitability or availability with respect to the coverage map. Your indoor coverage may be affected by factors that
      may not be in our control and impact accuracy of coverage approximation.
    </p>
  </div>

</div>