import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { BillingState } from '../../core/store/state/billing.state';
import { FirebaseConfigsState } from '../../core/store/state/firebase-configs.state';
import { VoucherState } from '../../core/store/state/voucher.state';
import { CartState } from '../../store/state/cart.state';
import { ServicesState } from '../../store/state/services.state';

@Pipe({
  name: 'totalDueNow'
})
export class TotalDueNowPipe implements PipeTransform {
  constructor(
    private store: Store,

  ) {}

  transform(amount: number, selectedPaymentDate: number,config: {performIsPostPaidCalculation: boolean, applyVoucher: boolean, applyDefaultDelTime: boolean} = {performIsPostPaidCalculation: false, applyVoucher: false, applyDefaultDelTime: false} ): number  {
    const promoIsActive = this.store.selectSnapshot(FirebaseConfigsState.isR1PromoActive);
    const orderType = this.store.selectSnapshot(CartState.GetOrderType);
    let deliverDays =  !config.applyDefaultDelTime ? this.store.selectSnapshot(BillingState.getDeliveryWaitTime) : 0;

    if(promoIsActive) {
      if (promoIsActive) {
        return (this.store.selectSnapshot(CartState.fiveGProducts).length + this.store.selectSnapshot(CartState.fourGProducts).length);
      }
    }

    const isValidVoucher = this.store.selectSnapshot(VoucherState.isValid)
    const voucherAmount = this.store.selectSnapshot(VoucherState.getVoucherAmount);


    if (config.performIsPostPaidCalculation) {
      const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
      if(isPostPaid) {
        if(isValidVoucher && config.applyVoucher) {
          amount = Number(amount) - voucherAmount;
        }
        return amount;
      }
    }

    let today = moment().startOf('d'); 
    let todayMonth = moment().month();
    let todayDay =  moment().date();
    let todayYear = moment().year();

    if(orderType === 'nvidia' || orderType === 'nvidia_with_5G_purchase') deliverDays = 0;
    let nextBillCycle;
    let lastBillCycle;
    if(  Number(selectedPaymentDate > todayDay)){ 
      nextBillCycle = moment({date: Number(selectedPaymentDate),month: moment().month(),year: todayYear})
      lastBillCycle = moment({date: Number(selectedPaymentDate),month: this.determineLastMonth(todayMonth),year:  this.determineIfLastYear(todayMonth,todayYear)})
    } else {
      nextBillCycle = moment({date: Number(selectedPaymentDate),month: this.determineNextMonth(todayMonth),year: this.determineIfNextYear(todayMonth,todayYear)})
      lastBillCycle = moment({date: Number(selectedPaymentDate),month:todayMonth,year: todayYear})
    }

    let daysInCycle = nextBillCycle.diff(lastBillCycle,'days');
    
    const cartItems = [...this.store.selectSnapshot(CartState.fiveGProducts).map((fiveGProduct) => fiveGProduct.product), ...this.store.selectSnapshot(CartState.fourGProducts).map((fourGProduct) => fourGProduct.product), ...this.store.selectSnapshot(CartState.gotSimProducts).map((gotSimProducts) => gotSimProducts.product), this.store.selectSnapshot(CartState.GetSelectedNvidiaBundle), this.store.selectSnapshot(CartState.GetSelectedRainOneBundle)].filter((ci) => ci !== undefined);

    if(cartItems.some((product: any) => product?.tag?.toLocaleLowerCase().includes('gotsim')) ) {
      deliverDays = 0;
    }

    if(cartItems.length === 1 && cartItems[0]?.config?.subtype) {
      deliverDays = 0;
    }

    let proRateDays = cartItems.some(product => product?.config?.['rica_type']?.value === 'e-rica') ?  nextBillCycle.diff(today,'days') - 1 :  nextBillCycle.diff(today,'days') - deliverDays - 1; 

    let proRataRate = Number(amount) / daysInCycle; 
    let proRataAmount = proRataRate * proRateDays;
    
    if(isValidVoucher && config.applyVoucher) {
      
      proRataAmount = Number(proRataAmount) - voucherAmount;
    }
    if (proRataAmount < 1) return 1
    return proRataAmount
  }

  determineNextMonth(todayMonth) {
    return todayMonth < 11 ? todayMonth + 1 : 0;
  }

  determineIfNextYear(todayMonth, todayYear) {
    return todayMonth == 11 ? todayYear + 1 : todayYear;
  }

  determineLastMonth(todayMonth) {
    return todayMonth > 0 ? todayMonth - 1 : 11;
  }

  determineIfLastYear(todayMonth, todayYear) {
    return todayMonth === 0 ? todayYear - 1 : todayYear;
  }

  private getProRataDays(cartItems: Array<any>, nextBillCycle, deliverDays, includeDelDays: boolean = true) {
    let today = moment().startOf('d'); 
    if(!includeDelDays) {
     return cartItems.some(product => product.config['rica_type']?.value === 'e-rica') ?  nextBillCycle.diff(today,'days') - 1 :  nextBillCycle.diff(today,'days') - 1;
    }

    return cartItems.some(product => product.config['rica_type']?.value === 'e-rica') ?  nextBillCycle.diff(today,'days') - 1 :  nextBillCycle.diff(today,'days') - deliverDays - 1;
  }

}
