import { Utils } from "src/app/utils";


export class CheckWhitelist {
    static readonly type = Utils.Helpers.Type('[Promo: Whitelist] Check if whitelisted');
    constructor(public readonly payload: {email: string}) { }
}

export class CheckWhitelistSuccess {
    static readonly type = Utils.Helpers.Type('[Promo: Whitelist] Check if whitelisted success');
    constructor(public readonly payload: {is_whitelisted: boolean}) { }
}

export class CheckWhitelistFail {
    static readonly type = Utils.Helpers.Type('[Promo: Whitelist] Check if whitelisted fail');
    constructor(public readonly payload: any) { }
}

export class AwaitToCheckWhitelist {
    static readonly type = Utils.Helpers.Type('[Promo: Whitelist] Await for login to check whitelist');
    constructor(public readonly payload: {email: string}) { }
}

export class AwaitToCheckWhitelistV2 {
    static readonly type = Utils.Helpers.Type('[Promo: Whitelist] Await for login to check whitelist v2');
    constructor(public readonly payload: {email: string, whitelisted: boolean}) { }
}