import { IRemoteServiceUsage, IRemoteDailyUsageItem } from './remote/remoteServiceUsage';
import { ModelHelper } from './modelHelper';
import { isNil } from 'lodash';
import { isArray } from 'util';

/**
 * ServiceUsageDetail @param
 */
export class ServiceUsageDetail {
  // public static adapt(
  //   /**
  //    * remoteServiceUsage @param {IRemoteServiceUsage}
  //    */
  //   remoteServiceUsage: IRemoteServiceUsage
  // ): IServiceUsageDetail {
  //   /**
  //    * serviceUsageDetail @param {IServiceUsageDetail}
  //    */
  //   const serviceUsageDetail = {} as IServiceUsageDetail;
  //   if (isNil(remoteServiceUsage) == false) {
  //     const items = [];

  //     if (isNil(remoteServiceUsage.daily_usage) == false) {
  //       // const dailyUsageIsArray = isNil(remoteServiceUsage.daily_usage['0']) == false;
  //       const dailyUsageIsArray = isNil(remoteServiceUsage.daily_usage) === false;

  //       if (dailyUsageIsArray) {
  //         for (const key in remoteServiceUsage.daily_usage) {

  //           if (key !== 'billing_item') {
  //             const value = remoteServiceUsage.daily_usage[key];

  //             /**
  //              * usageItem @param {IRemoteDailyUsageItem}
  //              */
  //             const usageItem = value as IRemoteDailyUsageItem;

  //             /**
  //              * dailyUsage @param {IDailyUsageDetail}
  //              */
  //             const dailyUsage: IDailyUsageDetail = {
  //               /**
  //                * totalUsage @param {usageItem.total}
  //                */
  //               totalUsage: usageItem.total,

  //               /**
  //                * date @param {ModelHelper.toDate}
  //                */
  //               date: ModelHelper.toDate(usageItem.date),

  //               /**
  //                * offPeakUsage @param {usageItem.offpeak}
  //                */
  //               offPeakUsage: usageItem.offpeak,

  //               /**
  //                * peakUsage @param {usageItem.peak}
  //                */
  //               peakUsage: usageItem.peak
  //             };

  //             items.push(dailyUsage);
  //           }
  //         }
  //       } else {
  //         const usageItem = (remoteServiceUsage.daily_usage as any) as IRemoteDailyUsageItem;

  //         const dailyUsage = {
  //           totalUsage: usageItem.total,
  //           date: ModelHelper.toDate(usageItem.date),
  //           offPeakUsage: usageItem.offpeak,
  //           peakUsage: usageItem.peak
  //         };

  //         items.push(dailyUsage);
  //       }
  //     }

  //     serviceUsageDetail.dailyUsages = items;
  //   }

  //   return serviceUsageDetail;
  // }
  public static adapt(
    /**
     * remoteServiceUsage @param {IRemoteServiceUsage}
     */
    remoteServiceUsage: IRemoteServiceUsage
  ): IServiceUsageDetail {
    /**
     * serviceUsageDetail @param {IServiceUsageDetail}
     */
    const serviceUsageDetail = {} as IServiceUsageDetail;
    if (isNil(remoteServiceUsage) == false) {
      const items = [];

      if (isNil(remoteServiceUsage) == false) {
        // const dailyUsageIsArray = isNil(remoteServiceUsage.daily_usage['0']) == false;
        const dailyUsageIsArray = isNil(remoteServiceUsage) === false;

        if (dailyUsageIsArray) {
          for (const key in remoteServiceUsage) {

            if (key !== 'billing_item') {
              const value = remoteServiceUsage[key];

              /**
               * usageItem @param {IRemoteDailyUsageItem}
               */
              const usageItem = value as IRemoteDailyUsageItem;

              /**
               * dailyUsage @param {IDailyUsageDetail}
               */
              const dailyUsage: IDailyUsageDetail = {
                /**
                 * totalUsage @param {usageItem.total}
                 */
                totalUsage: usageItem.total,

                /**
                 * date @param {ModelHelper.toDate}
                 */
                date: ModelHelper.toDate(usageItem.date),

                /**
                 * offPeakUsage @param {usageItem.offpeak}
                 */
                offPeakUsage: usageItem.offpeak,

                /**
                 * peakUsage @param {usageItem.peak}
                 */
                peakUsage: usageItem.peak
              };

              items.push(dailyUsage);
            }
          }
        } else {
          const usageItem = (remoteServiceUsage.daily_usage as any) as IRemoteDailyUsageItem;

          const dailyUsage = {
            totalUsage: usageItem.total,
            date: ModelHelper.toDate(usageItem.date),
            offPeakUsage: usageItem.offpeak,
            peakUsage: usageItem.peak
          };

          items.push(dailyUsage);
        }
      }

      serviceUsageDetail.dailyUsages = items;
    }

    return serviceUsageDetail;
  }
}

/**
 * IServiceUsageDetail @param
 */
export interface IServiceUsageDetail {
  /**
   * dailyUsages @param {IDailyUsageDetail}
   */
  dailyUsages: IDailyUsageDetail[];
}

/**
 * IDailyUsageDetail @param
 */
export interface IDailyUsageDetail {
  /**
   * totalUsage @param {number}
   */
  totalUsage: number;

  /**
   * peakUsage @param {number}
   */
  peakUsage: number;

  /**
   * offPeakUsage @param {number}
   */
  offPeakUsage: number;

  /**
   * date @param {Date}
   */
  date: Date;
}
