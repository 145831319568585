import { type } from "os";
import { Utils } from "src/app/utils";
import { BillCycleOption } from "../../interfaces/bill-cycle-option.interface";
import { BillCyclePayload } from "../../interfaces/bill-cycle-payload.interface";
import { PaymentDate } from "../../interfaces/payment-date.interface";


export class GetBillCycleOptions {
    static readonly type = Utils.Helpers.Type('[Billing: Get] Get billing cycle options');
}

export class GetBillCycleOptionsSuccess {
    static readonly type = Utils.Helpers.Type('[Billing: Get] Get billing cycle options success');
    constructor(public readonly payload: Array<BillCycleOption>) {}
}

export class GetBillCycleOptionsFail {
    static readonly type = Utils.Helpers.Type('[Billing: Get] Get billing cycle options fail');
    constructor(public readonly payload: any) {}
}


export class SetBillCycle {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set user billing cycle');
    constructor(public readonly payload: BillCyclePayload, public token?: string) {}
}

export class GetUserBillingAccountStatus {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Get user account status');
    constructor(public readonly email: string) { }
}

export class ResetUserBillingAccountStatus {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Reset account status');
}

export class ResetBillingDetails {
    static readonly type = Utils.Helpers.Type('[Billing: Auth] Reset billing details');
}

export class SetBillCycleSuccess {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set user billing cycle success');
    constructor(public readonly payload: any) {}
}

export class SetBillCycleFail {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set user billing cycle fail');
    constructor(public readonly payload: any) {}
}

export class SetSelectedBillCycle {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set selected user billing cycle');
    constructor(public readonly payload: string) {}
}


export class SetPaymentDate {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set saved user payment date');
    constructor(public readonly payload: {value: number | string, type: 'PaymentDate' | 'BillCycleDate'}) {}
}

export class SetSavedPaymentDate {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set user payment date');
    constructor(public readonly payload: {value: number | string, type: 'PaymentDate' | 'BillCycleDate'}) {}
}

export class InitSavePaymentDate {
    static readonly type = Utils.Helpers.Type('[Billing: Save] Init Save user payment date');
}


export class SavePaymentDate {
    static readonly type = Utils.Helpers.Type('[Billing: Save] Save user payment date');
    constructor(public readonly payload: PaymentDate, public token?: string) {}
}

export class SavePaymentDateSuccess {
    static readonly type = Utils.Helpers.Type('[Billing: Save] Save user payment date success');
    constructor(public readonly payload: any) {}
}

export class SavePaymentDateFail {
    static readonly type = Utils.Helpers.Type('[Billing: Save] Save user payment date fail');
    constructor(public readonly payload: any) {}
}

export class ChechandSwitchAccountType {
    static readonly type = Utils.Helpers.Type('[Billing: Switch] Switch user account type');
}


export class FetchDeliveryWaitTime {
    static readonly type = Utils.Helpers.Type('[Billing: Fetch] Fetch delivery wait time');
}

export class FecthDeliveryWaitTimeSuccess {
    static readonly type = Utils.Helpers.Type('[Billing: Fetch] Fetch delivery wait time success');
    constructor(public readonly payload: number) {}
}
export class FecthDeliveryWaitTimeFail {
    static readonly type = Utils.Helpers.Type('[Billing: Fetch] Fetch delivery wait time fail');
    constructor(public readonly payload: any) {}
}

export class SwitchAccountTypes {
    static readonly type = Utils.Helpers.Type('[Billing: Switch] Switch account types');
    constructor(public readonly payload: 'PaymentDate' | 'BillCycleDate') {}
}

export class SetProRataData {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set pro rata data');
    constructor(public readonly payload: string) {}
}

export class GetLastFailedPayment {
    static readonly type = Utils.Helpers.Type('[Billing: Get] Get last failed payment');
}

export class SetProRataDataSuccess {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set pro rata data success');
    constructor(public readonly payload: any) {}
}
export class SetProRataDataFail {
    static readonly type = Utils.Helpers.Type('[Billing: Set] Set pro rata data fail');
    constructor(public readonly payload: any) {}
}

export class IndicateSavedDate {
    static readonly type = Utils.Helpers.Type('[Billing: Set] date is saved');
}