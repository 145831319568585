import {Component, Input} from '@angular/core';

@Component({
    selector: 'button-v2',
    styleUrls: ['./button-v2.component.scss'],
    templateUrl: './button-v2.component.html'
})

export class ButtonV2Component {
    @Input() btnPrimary: boolean = true;
    @Input() btnDeselected: boolean = false;
    @Input() loading: boolean = false;
    @Input() disabled: boolean = false;
    @Input() text: string;
    @Input() buttonClass = '';
}
