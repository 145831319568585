<div class="revoke-nvidia-cancellation-modal-container">
    <div class="close-modal" (click)="activeModal.dismiss('Cross click')">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" />
    </div>
    <div class="content-container">
        <div class="modal-title">
            <h2>hey it’s good to have you back</h2>
        </div>
        <div class="modal-contents">
            <p>Your request cancellation will be reversed right now.
                Just so you know, cancellation reversals can only happen while your membership is still scheduled for
                cancellation, not once it has been cancelled.</p>
        </div>
    </div>
    <div class="button-container">
        <button-v2 [buttonClass]="'cancel-modal-btn'" [text]="'got it'" (click)="close()"></button-v2>
    </div>
</div>