<div class="modal-content border-0 px-lg-30 px-15">
    <div class="modal-img-close-btn z-index-5">
      <img src="../../assets/svg/close.svg" class="pointer" alt="Close" (click)="activeModal.dismiss('Cross click')"
        data-dismiss="modal" data-target="#FiveGCoverageModal" aria-label="Close" />
    </div>
    <div class="m-header p-0 mt-20">
      <h1 class="text-primary modal-title mb-10 fiveg">
        great news! <br> you're in <span class="text-uppercase">5G</span> coverage!
      </h1>

    </div>
    <div class="m-body">
        <p>To redeem your fast <em>unlimited home 5G premium package</em>, and your free-to-use router, please login or sign up with rain.</p>
    </div>
    <div class="m-footer">
        <button data-id="btn-click" data-text="cancel user details updates"
          class="btn btn-sm btn-outline-primary mr-5" (click)="moveToSignUp()">sign up</button>

        <button data-id="btn-click" data-text="save user details" (click)="moveToLogin()" type="submit" class="btn btn-sm btn-primary">
          login
        </button>
    </div>
  </div>