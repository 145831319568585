import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { RainOneCompetitionModalService } from './rain-one-competition-modal.service';
import { Router } from '@angular/router';
import { CoreState } from 'src/app/core/store/state/core.state';
import { Observable } from 'rxjs';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';

@Component({
  selector: 'rain-rain-one-competition-modal',
  templateUrl: './rain-one-competition-modal.component.html',
  styleUrls: ['./rain-one-competition-modal.component.scss']
})
export class RainOneCompetitionModalComponent implements OnInit {

  qrCodeDataURL: string = '';
  urlToEncode: string;
 linkUrl: string = '';


  @Select(CoreState.activeBreakpoint) ap$ : Observable<string>

  public qrCode: string;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private rainOneCompetitionModalService :RainOneCompetitionModalService,
    private store: Store
     ) 
    {}

  ngOnInit(): void {
    this.linkUrl = this.store.selectSnapshot(FirebaseConfigsState.getPromos).september_competition.qrCode_url;
    this.generateQRCode();
}

async generateQRCode() {
  this.qrCode = await this.rainOneCompetitionModalService.generateQRCode(this.store.selectSnapshot(FirebaseConfigsState.getPromos).september_competition.qrCode_url);
 
}

openUrl() {
  window.open(this.linkUrl, '_blank');
}


public gotoTerms() {
  this.activeModal.close();
  this.router.navigateByUrl('/legal?section=competition');
}
}
