import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TokenService} from "@services/token.service";
import {ConfigService} from "@services/config.service";
import {Observable} from "rxjs";
import {CACHE_AUTH_TOKEN, CACHE_IDM_TOKEN} from "@models/constants";
import {catchError, map, timeout} from "rxjs/operators";
import {PaymentStatusObject} from "../../../core/store/state/billing.state";
import {Result} from "@models/result";
import {UIState} from "../../../shared/store/state/ui.state";
import {AuthState} from "../../../core/store/state/auth.state";
import {Store} from "@ngxs/store";
import {IPaymentDetail, PaymentDetail} from "@models/paymentDetail";
import {ServiceHelper} from "@services/serviceHelper";

@Injectable({
    providedIn: 'root'
})
export class BillingServiceV2 {
    constructor(
        private http: HttpClient,
        private store: Store,
        private tokenService: TokenService,
        private configService: ConfigService,
    ) {
    }

    getPaymentDate(): Observable<any> {
        const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
        const requestPath = `${this.configService.AXIOM_IDM_URL}/recurringpaymentdate`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idmToken
            })
        };
        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((res: any) => {
                return res;
            })
        );
    }

    public GetLastFailedPayment(userId: string): Observable<PaymentStatusObject | Result<unknown>> {

        const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
        const requestPath = `${this.configService.BASE_API_URL}/v1/bss-pg/payments/failed/${userId}`;

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: 'Bearer ' + idmToken
            })
        };

        return this.http.get<PaymentStatusObject>(requestPath, httpOptions)
    }

    public getInvoices() {    
        const requestPath = `${this.configService.API_URL}/billing/bill/`;

        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer')) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: this.tokenService.getTokenForAccountType()
            })
        };

        return this.http.get(requestPath, httpOptions);
    }

    getActivePaymentMethod() {
        let userMode = this.store.selectSnapshot(UIState.GetUIMode);
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken);

        let userToken = ((userMode !== 'consumer' && smeToken)) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);

        const requestPath = `${this.configService.API_URL}/billing/payment_method`;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: userToken
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                const payDetail = PaymentDetail.adapt(result);
                
                if (!payDetail.id) {
                    return Result.notFound<IPaymentDetail>();
                }

                return Result.success(payDetail);
            }),
            catchError(result => ServiceHelper.handleError<IPaymentDetail>(result))
        );
    }
}