<div class="mb-10  modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
      data-dismiss="modal" data-target="#retractCancelSim" src="../../assets/svg/close.svg" />
  </div>
</div>

<div class="p-5 modal-body border-0">
  <div class="row m-0 mb-5">
    <div class="col-12 text-center">
      <p class="text-primary large-font-size h2 font-weight-bold modal-heading">{{title}}</p>
      <p class="pt-5">{{body}}</p>
    </div>
  </div>
</div>

<div *ngIf="selectedQuestion === 'Switching to a different rain product'; else SIMSwap"
  class="d-flex justify-content-center mb-15">
  <button class="btn btn-sm btn-primary  mr-3" [ngClass]="{'mobile-btn-size': isMobile}" (click)="requestSwitch(true)">
    request switch
    <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>

  </button>

  <button *ngIf="type === '4G'" class="btn btn-sm btn-primary ml-3" [ngClass]="{'mobile-btn-size': isMobile}"
    (click)="requestSwitch(false)">
    no thanks
  </button>

  <app-support-button *ngIf="type === '5G'" title="no thanks" [subject]="'rain cancellation support #'" [serviceId]="serviceId"
    [currentProductId]="productId" [msisdn]="msisdn" [bypassModal]="true" [address]="address" [isMobile]="isMobile"
    fromButton="cancel_5g_no_thanks">
  </app-support-button>
</div>

<ng-template #SIMSwap>
  <div *ngIf="type === '4G'; else fivegContent" class="d-flex justify-content-center mb-15">
    <button class="btn btn-sm btn-primary ml-3" [ngClass]="{'mobile-btn-size': isMobile}"
    (click)="requestSIMSwap(true)">
      request SIM swap
    </button>

    <button class="btn btn-sm btn-primary ml-3" [ngClass]="{'mobile-btn-size': isMobile}"
      (click)="requestSIMSwap(false)">
      no thanks
    </button>
  </div>
  <ng-template #fivegContent>
    <div class="d-flex justify-content-center mb-15">
      <app-support-button title="no thanks" [subject]="'rain cancellation support #'" [serviceId]="serviceId"
        [currentProductId]="productId" [msisdn]="msisdn" [bypassModal]="true" [address]="address"
        fromButton="cancel_5g_no_thanks" [isMobile]="isMobile">
      </app-support-button>

      <app-support-button title="request SIM swap" [productId]="productId" [serviceId]="serviceId"
        btnClass="btn btn-sm btn-primary ml-3" [subject]="'rain sim support #'" [msisdn]="msisdn"
        fromButton="cancellationSurvey" [isMobile]="isMobile">
      </app-support-button>

    </div>
  </ng-template>
</ng-template>