import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalData } from '@components/confirm-modal/confirm-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-success-error-modal',
  templateUrl: './success-error-modal.component.html',
  styleUrls: ['./success-error-modal.component.scss']
})
export class SuccessErrorModalComponent implements OnInit, OnDestroy {

  @Input() data: ModalData;
  
  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService,) { }

  ngOnInit(): void {
    setTimeout(() => {
      if(!this.data){
        this.toastr.error('An error occurred while processing the request', null);
        this.activeModal.close();
      }
    }, 400);
  }


  ngOnDestroy(): void {
    if (this.data) this.data = null;
  }
}
