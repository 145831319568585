<div class="modal-container">
    <div class="modal-header border-0">
        <div class="modal-img-close-btn z-index-5">
            <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
                (click)="close()" />
        </div>
    </div>
    <div class="modal-body p-0  z-index-1">
        <div class="header">
            <div class="header-col-left">
                <h2>coverage address</h2>
                <p>We need to check if you're in 5G coverage. If you're not<br>, we'll keep you on our 4G network and switch you to 5G<br> as soon as it becomes available in your area.</p>
            </div>
        </div>

        <div class="actions-footer">
            <button (click)="close()" class="btn btn-sm btn-primary text-white">got it</button>
        </div>
    </div>
</div>
