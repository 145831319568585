import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_AUTH_TOKEN } from '@models/constants';
import { Store } from '@ngxs/store';
import { ConfigService } from '@services/config.service';
import { TokenService } from '@services/token.service';
import { Observable } from 'rxjs';
import { ConnectionStatus } from '../interfaces/connection-status.interface';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private _http: HttpClient, private store: Store, private configService: ConfigService, private tokenService: TokenService) { }

  public checkStatus(msisdn: string): Observable<ConnectionStatus> {
    const testDomain  = 'https://sit-bss-api.rain.network/V1/rain-web/service';
    const url = `${this.configService.LOAD_SHEDDING_API}/${msisdn}/connection/v2`;
   // const url = `${testDomain}/${msisdn}/connection/v2`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get(url, httpOptions) as Observable<ConnectionStatus>;
  }


}
