import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'rain-sa20-banner-billing',
  templateUrl: './sa20-banner-billing.component.html',
  styleUrls: ['./sa20-banner-billing.component.scss']
})
export class Sa20BannerBillingComponent {

  @Input('page') page:string;
  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;

}
