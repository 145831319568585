import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserService } from '@services/user.service';
import moment from 'moment';
import { map, tap } from 'rxjs/operators';
import { WebsiteconfigService } from 'src/app/core/services/websiteconfig.service';
import {
  InboxMessage,
  MessageCategory,
  MessageStatus,
  NotificationCategories,
  NotificationChannel,
  NotificationChannels,
  UserSettings
} from '../models/inbox.models';
import {
  DeleteMessage,
  GetAllCategories,
  GetAllMessages,
  GetAllNotificationChannels,
  GetCurrentMessage,
  GetMessagesForCategory,
  GetTotalUnreadMessagesCount,
  GetUserPreferredPromoNotificationChannel,
  GetUserPromoOptin,
  GetUserWhatsappOptin,
  MarkAsRead,
  Search,
  SetCurrentCategory,
  SetShowBack,
  SetShowCategories,
  SetShowMessage,
  SetShowMessageList,
  SetShowOptinInfo,
  SetShowSearch,
  SetShowWeather,
  SetUserPreferredPromoNotificationChannel,
  SetUserPromoOptin,
  SetUserWhatsappOptin
} from './inbox.actions';

export interface InboxStateModel {
  allMessages: InboxMessage[];
  currentMessage: InboxMessage;
  currentCategory: MessageCategory;
  currentCategoryMessageList: InboxMessage[];
  displayMessageList: InboxMessage[];
  totalUnreadMessagesCount: number;
  allCategories: MessageCategory[];
  showCategories: boolean;
  showMessageList: boolean;
  showMessage: boolean;
  showWeather: boolean;
  showSearch: boolean;
  showBack: boolean;
  noMessages: boolean;
  showSettings: boolean;
  userSettings: UserSettings;
}

//TODO  //Decide on proper error Handling

@State<InboxStateModel>({
  name: 'Inbox',
  defaults: {
    allMessages: [],
    currentMessage: null,
    currentCategory: null,
    currentCategoryMessageList: [],
    displayMessageList: [],
    allCategories: [],
    showCategories: true,
    showMessageList: false,
    showWeather: false,
    showMessage: false,
    noMessages: false,
    showSettings: true,
    showSearch: false,
    showBack: false,
    totalUnreadMessagesCount: 0,
    userSettings: {
      verifiedOptInPromo: false,
      optinPromo: false,
      optinWhatsApp: false,
      availableChannels: [],
      optWhatsAppDetails: null,
      showOptinInfo: true
    }
  }
})
@Injectable({ providedIn: 'root' })
export class InboxState {
  constructor(private userService: UserService, public websiteConfig: WebsiteconfigService) {}

  @Selector()
  static getAllMessages(state: InboxStateModel) {
    return state.allMessages;
  }

  @Selector()
  static getTotalMessagesCount(state: InboxStateModel) {
    return state.totalUnreadMessagesCount;
  }

  @Selector()
  static getAllCategories(state: InboxStateModel) {
    return state.allCategories;
  }

  @Selector()
  static showSearch(state: InboxStateModel) {
    return state.showSearch;
  }

  @Selector()
  static showSettings(state: InboxStateModel) {
    return state.showSettings;
  }

  @Selector()
  static userSettings(state: InboxStateModel) {
    return state.userSettings;
  }

  @Selector()
  static showMessage(state: InboxStateModel) {
    return state.showMessage;
  }

  @Selector()
  static currentMessage(state: InboxStateModel) {
    return state.currentMessage;
  }

  @Selector()
  static showMessageList(state: InboxStateModel) {
    return state.showMessageList;
  }

  @Selector()
  static currentCategoryMessageList(state: InboxStateModel) {
    return state.currentCategoryMessageList;
  }

  @Selector()
  static displayMessageList(state: InboxStateModel) {
    return state.displayMessageList;
  }

  @Selector()
  static showWeather(state: InboxStateModel) {
    return state.showWeather;
  }

  @Selector()
  static showCategories(state: InboxStateModel) {
    return state.showCategories;
  }

  @Selector()
  static showBack(state: InboxStateModel) {
    return state.showBack;
  }

  @Selector()
  static getCurrentCategoryDescription(state: InboxStateModel) {
    return state.currentCategory.description;
  }

  @Action(GetAllCategories)
  GetAllCategories(ctx: StateContext<InboxStateModel>) {
    const messages = ctx.getState().allMessages;
    return this.userService.getCategories().pipe(
      map((res: any) => res.data),
      tap((categories: MessageCategory[]) => {
        ctx.patchState({
          allCategories: categories.map(category => {
            return {
              ...category,
              unreadCount: messages.filter(m => m.categoryId === category.id && m.status == MessageStatus.UNREAD)
                ?.length
            };
          })
        });
      })
    );
  }

  @Action(GetMessagesForCategory)
  getMessagesForCategory(ctx: StateContext<InboxStateModel>, action: GetMessagesForCategory) {
    let allMessages = ctx.getState()?.allMessages;
    if (action.categoryId > 0) {
      allMessages = allMessages.filter(message => message.categoryId === action.categoryId);
    }

    ctx.patchState({
      currentCategoryMessageList: allMessages,
      displayMessageList: allMessages
    });
  }

  @Action(SetCurrentCategory)
  setCurrentCategory(ctx: StateContext<InboxStateModel>, action: SetCurrentCategory) {
      
    const state = ctx.getState();
    ctx.patchState({
      currentCategory: state.allCategories.find(cat => cat.id === action.categoryId)
    });
  }

  @Action(GetAllMessages)
  getAllMessages(ctx: StateContext<InboxStateModel>) {
    const data = {
      page: 0, //TODO make this dynamic/clean
      pageSize: 1000, //TODO make this dynamic/clean
      sortByField: ['insertedAt'], //TODO make this dynamic/clean
      sortByFieldSortOrder: 'DESC' //TODO make this dynamic/clean
    };

    return this.userService.getAllInboxes(data).pipe(
      map((res: any) => res.data),
      tap((incompleteMessages: InboxMessage[]) => {
        let processedMessages = incompleteMessages.map((message: InboxMessage) => {
          const day = moment(message?.insertedAt).format('DD');
          const month = moment(message?.insertedAt).format('MMM');
          const year = moment(message?.insertedAt).format('YYYY');

          return {
            ...message,
            insertedAt: `${day} ${month} ${year}`
          };
        });

        ctx.patchState({
          allMessages: processedMessages,
          displayMessageList: processedMessages
        });
        ctx.dispatch(new GetAllCategories());
      })
    );
  }

  @Action(GetCurrentMessage)
  getCurrentMessage(ctx: StateContext<InboxStateModel>, action: GetCurrentMessage) {
    const currMessage = ctx.getState().allMessages.find(m => m.notificationId === action.notificationId);
    return this.userService.getCustomerMessageUrl(action.notificationId).pipe(
      tap((res: any) => {
        ctx.patchState({
          currentMessage: { ...currMessage, message: res?.data }
        });
        if (res?.data) {
          ctx.dispatch(new MarkAsRead(action.notificationId));
        }
      })
    );
  }

  @Action(GetTotalUnreadMessagesCount)
  getTotalUnreadMessagesCount(ctx: StateContext<InboxStateModel>, action: GetTotalUnreadMessagesCount) {
    return this.userService.countUnreadMessages().pipe(
      tap((res: any) => {
        ctx.patchState({
          totalUnreadMessagesCount: res.data
        });
      })
    );
  }

  @Action(MarkAsRead)
  markAsRead(ctx: StateContext<InboxStateModel>, action: MarkAsRead) {
    const currMessage = ctx.getState().currentMessage;
    return this.userService.markInboxAsRead([action?.notificationId]).pipe(
      tap((res: any) => {
        ctx.patchState({
          currentMessage: { ...currMessage, status: MessageStatus.READ }
        });
      })
    );
  }

  @Action(DeleteMessage)
  deleteMessage(ctx: StateContext<InboxStateModel>, action: DeleteMessage) {
    return this.userService.deleteMessage(action.notificationId).pipe(
      tap((result: any) => {
        ctx.dispatch(new GetAllMessages());
      })
    );
  }

  @Action(SetShowSearch)
  SetShowSearch(ctx: StateContext<InboxStateModel>, action: SetShowSearch) {
    ctx.patchState({
      showSearch: action.showSearch
    });
  }

  @Action(SetShowOptinInfo)
  setShowOptinInfo(ctx: StateContext<InboxStateModel>, action: SetShowOptinInfo) {
    ctx.patchState({
      userSettings: { ...ctx.getState().userSettings, showOptinInfo: action.showOptinInfo }
    });
  }

  @Action(SetShowCategories)
  setShowCategories(ctx: StateContext<InboxStateModel>, action: SetShowCategories) {
    ctx.patchState({
      showCategories: action.showCategories
    });
  }

  @Action(SetShowMessageList)
  showMessageList(ctx: StateContext<InboxStateModel>, action: SetShowMessageList) {
    ctx.patchState({
      showMessageList: action.showMessageList
    });
  }

  @Action(SetShowMessage)
  showMessage(ctx: StateContext<InboxStateModel>, action: SetShowMessage) {
    ctx.patchState({
      showMessage: action.showMessage
    });
  }

  @Action(SetShowBack)
  showBack(ctx: StateContext<InboxStateModel>, action: SetShowBack) {
    ctx.patchState({
      showBack: action.showBack
    });
  }

  @Action(SetShowWeather)
  showWeather(ctx: StateContext<InboxStateModel>, action: SetShowWeather) {
    ctx.patchState({
      showWeather: action.showWeather
    });
  }

  @Action(Search)
  search(ctx: StateContext<InboxStateModel>, action: Search) {
    const state = ctx.getState();
    const messageList =
      action.searchTerm.length > 0
        ? state.currentCategoryMessageList.filter(m =>
            m.shortDescription.toLocaleLowerCase().includes(action.searchTerm.toLocaleLowerCase())
          )
        : state.currentCategoryMessageList;

    ctx.patchState({
      displayMessageList: messageList.sort((a, b) => {
        return a.status - b.status;
      })
    });
  }

  @Action(GetUserPreferredPromoNotificationChannel)
  getUserPrefferedNotificationChannel(
    ctx: StateContext<InboxStateModel>,
    action: GetUserPreferredPromoNotificationChannel
  ) {
    return this.userService.getPreferredChannel().pipe(
      tap((result: any) => {
        let channels = [...ctx.getState().userSettings?.availableChannels];

        channels = channels.map(channel => ({
          ...channel,
          accepted: result?.data?.channelId === channel.id
        }));

        ctx.patchState({
          userSettings: {
            ...ctx.getState().userSettings,
            availableChannels: channels
          }
        });
      })
    );
  }

  @Action(GetAllNotificationChannels)
  getAllNotificationChannels(ctx: StateContext<InboxStateModel>) {
    return this.userService.getAllChannels().pipe(
      tap((channels: any) => {
        let availableChannels = channels?.data as NotificationChannel[];
        let filteredList:NotificationChannel[] = availableChannels.filter(channel => {
          return channel?.customerPreference;
        });

        ctx.patchState({
          userSettings: {
            ...ctx.getState().userSettings,
            availableChannels: filteredList
          }
        });
        ctx.dispatch(new GetUserPreferredPromoNotificationChannel());
      })
    );
  }

  @Action(SetUserWhatsappOptin)
  setUserWhatsappOptin(ctx: StateContext<InboxStateModel>, action: SetUserWhatsappOptin) {
    const data = {
      channelId: NotificationChannels.WHATSAPP,
      accepted: action.optInWhatsApp
    };

    return this.userService.optInWhatsApp(data).pipe(
      tap(() => {
        ctx.dispatch(new GetUserWhatsappOptin());
      })
    );
  }
  @Action(GetUserWhatsappOptin)
  getUserWhatsappOptin(ctx: StateContext<InboxStateModel>, action: GetUserWhatsappOptin) {
    return this.userService.getOptInWhatsAppDetails().pipe(
      tap((result: any) => {
        ctx.patchState({
          userSettings: {
            ...ctx.getState().userSettings,
            optWhatsAppDetails: result?.data,
            optinWhatsApp: result?.data?.accepted && result?.data?.channelId === NotificationChannels.WHATSAPP
          }
        });
      })
    );
  }

  @Action(SetUserPromoOptin)
  setUserPromoOptin(ctx: StateContext<InboxStateModel>, action: SetUserPromoOptin) {
    const data = {
      categoryId: NotificationCategories.PROMO,
      accepted: action.optInPromo
    };

    return this.userService.optInPromo(data).pipe(
      tap(() => {
        ctx.dispatch(new GetUserPromoOptin());
      })
    );
  }

  @Action(GetUserPromoOptin)
  getUserPromoOptin(ctx: StateContext<InboxStateModel>, action: GetUserPromoOptin) {
    return this.userService.getOptInPromoDetails().pipe(
      tap((result: any) => {
        ctx.patchState({
          userSettings: {
            ...ctx.getState().userSettings,
            optinPromo: result?.data?.accepted && result?.data?.categoryId === NotificationCategories.PROMO,
            verifiedOptInPromo: true
          }
        });
      })
    );
  }

  @Action(SetUserPreferredPromoNotificationChannel)
  setUserPrefferedNotificationChannel(
    ctx: StateContext<InboxStateModel>,
    action: SetUserPreferredPromoNotificationChannel
  ) {
    const data = {
      channelId: action.channelId
    };

    return this.userService.setChannelPreference(data).pipe(
      tap(() => {
        ctx.dispatch(new GetAllNotificationChannels());
      })
    );
  }
}
