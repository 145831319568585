import {Injectable} from '@angular/core';
import {
  CACHE_EDIT_GIVE_A_GIG,
  CACHE_GIVE_A_GIG_PURCHASE_DETAILS,
  CONFIG_COMMENTS,
  CONFIG_FRIENDLY_NAME,
  CONFIG_ICCID
} from '@models/constants';
import {ProductDetail} from '@models/productDetail';
import {Navigate} from '@ngxs/router-plugin';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {CacheService} from '@services/cache.service';
import {CartItem, CartService, ICartItemMapped} from '@services/cart.service';
import {DataLayerService} from '@services/data-layer.service';
import {RainOneProduct} from 'src/app/core/interfaces/rain-one-products.interface';
import {TopUpService} from 'src/app/core/services/top-up.service';
import {
  AddExtraPhoneLine,
  AddToCart,
  ChangeNvidiaTier,
  ChangeRainOneLevel,
  ChangeWifiSpeed,
  CheckForOpenNvidiaOrder,
  ClearAllVas,
  ClearCart,
  ClearTopUpDetails,
  CoverageCheckedOnRegistration,
  EmptyCart,
  IsRainOneTopup,
  MigratingToR1FiveG,
  RegisterWithoutCompanyDetails,
  RemoveExtraPhoneLine,
  RemoveFromCart,
  RemoveNvidiaAddon,
  RemoveNvidiaFromCart,
  RemoveRainOneFromCart,
  RemoveWifiSpeed,
  ResetCart,
  SetAllowedProductLimit,
  SetOrderType,
  SetSelectedRouterSkin,
  SetTopUpAmounts,
  StartMobilePurchase,
  StartNvidiaPurchase,
  StartNvidiaPurchaseWith5GMigrations,
  StartRainOnePurchase,
  SwapNvidiaProducts,
  SwapRainOneAuForPP,
  SwapRainOneWifiAuForPP,
  TopUPPurchase,
  TopUPPurchaseFail,
  TopUPPurchaseSuccess,
  SetXtenderSpeed} from '../actions/cart.action';
import {ProductState} from './product.state';
import {ServicesState} from './services.state';
import {CoreState} from 'src/app/core/store/state/core.state';
import {OrderState} from './order.state';
import {InitMigration, InitMigrationWithNvidia} from '../actions/services.actions';
import {BillingState, PaymentDateObject} from 'src/app/core/store/state/billing.state';
import {UIState} from 'src/app/shared/store/state/ui.state';
import {IOrderDetail} from '@models/orderDetail';

export type OrderType = 'bundle' | 'topup' | 'addon' | 'nvidia' | 'multi' | 'nvidia_with_5G_purchase' | 'nvidia_with_4G_purchase' | 'mobile';
export interface CartStateModel {
  xtenderSpeed: string;
  gotSimProducts: ICartItemMapped[];
  fourGProducts: ICartItemMapped[];
  fiveGProducts: ICartItemMapped[];
  allCartItems: { [key: string]: CartItem[] };
  allowed5GInCart: number;
  allowed4GInCart: number;
  rainOneOrder: any;
  mobileOrder: any;
  nvidiaOrder: any;
  addOns: any[];
  vas: any[];
  accessories: any[];
  wifi_speed_index: number;
  isRainOneTopUp: boolean;
  top_up_amounts: { [type: string]: { price: number; quantity: number; displaySymbol: string } };
  top_up_order_result: any;
  order_type: OrderType;
  nvidiaConfig: {
    tier: string;
  };
  migratingToR1FiveG: string;
  coverageCheckedOnRegistration: boolean;
  registerWithoutCompanyDetails: boolean;
  selectedLevelForDataLayer: string;
  selectedRouterSkin: any;
}

export const DefaultAllowed5GInCart = 3;
export const DefaultAllowed4GInCart = 10;
@State<CartStateModel>({
  name: 'cart',
  defaults: {
    xtenderSpeed: '60',
    gotSimProducts: [],
    fourGProducts: [],
    fiveGProducts: [],
    allCartItems: null,
    allowed4GInCart: DefaultAllowed4GInCart,
    allowed5GInCart: DefaultAllowed5GInCart,
    rainOneOrder: null,
    mobileOrder: null,
    nvidiaOrder: null,
    addOns: [],
    vas: [],
    accessories: [],
    wifi_speed_index: 0,
    isRainOneTopUp: false,
    top_up_amounts: null,
    top_up_order_result: null,
    order_type: null,
    nvidiaConfig: {
      tier: null
    },
    migratingToR1FiveG: '',
    coverageCheckedOnRegistration: false,
    registerWithoutCompanyDetails: false,
    selectedLevelForDataLayer: '',
    selectedRouterSkin: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class CartState {
  constructor(
    private store: Store,
    private cartSVC: CartService,
    private dataLayerservice: DataLayerService,
    private _topUpSVC: TopUpService,
    private cacheSVC: CacheService
  ) {}

  @Selector()
  static gotSimProducts(state: CartStateModel) {
    return state.gotSimProducts;
  }

  @Selector()
  static getXtenderCount(state: CartStateModel) {
    return state.rainOneOrder?.name
  }

  @Selector()
  static fiveGProducts(state: CartStateModel) {
    return state.fiveGProducts;
  }

  @Selector()
  static getXtenderSpeed(state: CartStateModel) {
    return state.xtenderSpeed;
  }

  @Selector()
  static fourGProducts(state: CartStateModel) {
    return state.fourGProducts;
  }

  @Selector([CartState.fourGProducts])
  static GetMobileCartProduct(state: CartStateModel, fourGProducts: ICartItemMapped[]) {
    return fourGProducts?.[0]?.product;
  }

  @Selector([CartState.GetMobileCartProduct, BillingState.getPaymentDate, ProductState.GetRainMobileBundleProducts, OrderState.currentOrder])
  static getDataLayerFourGProductsCartProduct(state: CartStateModel, rainMobileBundle: RainOneProduct, paymentDateObject: PaymentDateObject, mobileBundleProducts: RainOneProduct[], currentOrder: IOrderDetail) {
    const itemName = `rain mobile`;
    const mobileProductIds = mobileBundleProducts?.map(product => product?.id);
    const orderMobileBundleItem = currentOrder?.items?.find(item => mobileProductIds?.includes(item?.productId));
    const bundle = rainMobileBundle || mobileBundleProducts?.find(product => product?.id === orderMobileBundleItem?.productId);
    const productId = bundle?.id;
    const totalProductValue = bundle?.price;
    const paymentDate = paymentDateObject?.selectedPaymentDate && paymentDateObject?.saved ? paymentDateObject?.selectedPaymentDate : 1;
    
    return {
      item_name: itemName,
      id: productId,
      quantity: 1,
      item_brand: 'rain',
      item_categories: {
        techType: '4G',
        selectedSpeed: null,
        selectedLevel: 0,
        selectedSkin: null,
        paymentDate: paymentDate
      },
      totalProductValue: totalProductValue || currentOrder?.totalPrice
    };
  }

  @Selector()
  static fourGProductsLimit(state: CartStateModel) {
    return state.allowed4GInCart;
  }

  @Selector()
  static fiveGProductsLimit(state: CartStateModel) {
    return state.allowed5GInCart;
  }

  @Selector()
  static hasSpeedUp(state: CartStateModel): boolean {
    return state.vas.length > 0;
  }
  @Selector()
  static coverageCheckedOnRegistration(state: CartStateModel): boolean {
    return state.coverageCheckedOnRegistration;
  }

  @Selector()
  static GetAllExtras(state: CartStateModel): RainOneProduct[] {
    const bundle = state.rainOneOrder;

    if ((bundle.name !== 'rainOne' || bundle.name !== 'rainOne 101') && !bundle.name.toLocaleLowerCase().includes('nvidia'))
      return [...state.addOns, ...state.vas, ...state.accessories, bundle];
    return [...state.addOns, ...state.vas, ...state.accessories];
  }

  @Selector()
  static GetAddons(state: CartStateModel): RainOneProduct[] {
    return state.addOns;
  }
  @Selector()
  static GetAccessories(state: CartStateModel): RainOneProduct[] {
    return state.accessories;
  }

  @Selector()
  static GetAddonsAsOrderLines(state: CartStateModel): any[] {
    return state.addOns.map(a => {
      return {
        config: {},
        productId: a.id
      };
    });
  }

  @Selector()
  static GetSelectedVasIndex(state: CartStateModel): number {
    return state.wifi_speed_index;
  }
  @Selector()
  static GetAllVas(state: CartStateModel): RainOneProduct[] {
    return state.vas;
  }

  @Selector()
  static GetAllVasAsOrderLines(state: CartStateModel): any[] {
    return state.vas.map(a => {
      return {
        config: {},
        productId: a.id
      };
    });
  }

  @Selector()
  static GetSelectedRainOneBundle(state: CartStateModel): RainOneProduct {
    if (state.rainOneOrder) return state.rainOneOrder;
  }

  @Selector()
  static GetSelectedMobileBundle(state: CartStateModel): RainOneProduct {
    if (state.mobileOrder) return state.mobileOrder;
  }

  @Selector()
  static GetSelectedNvidiaBundle(state: CartStateModel): RainOneProduct {
    if (state.nvidiaOrder) return state.nvidiaOrder;
  }
  @Selector()
  static IsMigratingTo5GR1(state: CartStateModel): string {
    return state.migratingToR1FiveG;
  }

  @Selector()
  static GetTopOrderResult(state: CartStateModel) {
    return state.top_up_order_result;
  }

  @Selector()
  static GetLevelName(state: CartStateModel) {
    return state.selectedLevelForDataLayer;
  }

  @Selector()
  static GetFMTTopOrderResult(state: CartStateModel) {
    const isSit = window.location.href.includes('sit.precipitation.co.za');
    if (isSit) {
      if (state.top_up_order_result && state.top_up_order_result.responseCode) {
        return {
          value: state.top_up_order_result,
          status: 1
        };
      }
    } else {
      if (state.top_up_order_result && state.top_up_order_result.responseCode === 'SUCCESS') {
        return {
          value: state.top_up_order_result,
          status: 1
        };
      }
    }
  }

  @Selector()
  static GetTopOrderAmount(state: CartStateModel) {
    const results = {};
    Object.keys(state.top_up_amounts).forEach(k => {
      if (state.top_up_amounts[k].quantity > 0) results[k] = state.top_up_amounts[k];
    });
    return results;
  }

  @Selector()
  static getIsRainOneTopUp(state: CartStateModel) {
    return state.isRainOneTopUp;
  }

  @Selector()
  static GetOrderType(state: CartStateModel) {
    return state.order_type;
  }

  @Selector()
  static GetNvidiaTier(state: CartStateModel) {
    return state.nvidiaConfig;
  }

  @Selector([
    CartState.GetSelectedRainOneBundle,
    CartState.GetAccessories,
    CartState.GetAllVas,
    ProductState.GetMobilePhoneLineProducts,
    ProductState.GetRainOne101SimProducts,
    ProductState.GetSpeedUpCatelogueProducts,
    ProductState.GetRainOne101BundleProducts,
    ProductState.GetCatelogueProducts,
    BillingState.getPaymentDate,
    UIState.GetUIMode,
    CartState.GetLevelName,
    OrderState.currentOrder
  ])
  static getDataLayerRainOne101CartProduct(
    state: CartStateModel,
    rainOne101Bundle: RainOneProduct,
    accessories: RainOneProduct[],
    vas: RainOneProduct[],
    mobilePhoneLineProducts: RainOneProduct[],
    rainOne101SimProducts: RainOneProduct[],
    rainOneSpeedProducts: RainOneProduct[],
    rainOne101BundleProducts: RainOneProduct[],
    products: RainOneProduct[],
    paymentDateObject: PaymentDateObject,
    uiMode: string,
    levelName: string,
    currentOrder: IOrderDetail
  ) {
    const rainOne101Skin = accessories.find(accessory => accessory?.config?.hexColor) ?? null;
    const rainOne101SimIds = rainOne101SimProducts.map(product => product?.id);
    const rainOneSpeedIds = rainOneSpeedProducts.map(product => product?.id);
    const orderLineRainOneSimItem = currentOrder?.items?.find(item => rainOne101SimIds.includes(item?.productId));
    const orderLineSpeedItem = currentOrder?.items?.find(item => rainOneSpeedIds.includes(item?.productId));

    const rainOne101SpeedItem = vas.find(vasItem => vasItem?.name?.toLocaleLowerCase()?.includes('mbps')) || orderLineSpeedItem;

    const selectedRainOne101Speed = !rainOne101SpeedItem?.price ? '30' : rainOne101SpeedItem?.price === 200 ? '60' : '+100';

    const rainOneSim = rainOne101SimProducts?.find(item => [rainOne101Bundle?.items?.find(item => rainOne101SimIds.includes(item?.id))?.id, orderLineRainOneSimItem?.productId]?.includes(item?.id));

    const rainOneBundle = rainOne101Bundle || rainOne101BundleProducts.find(product => product?.config?.level === rainOneSim?.config?.level)

    const isIn5G = localStorage.getItem('isIn5G') || !currentOrder ? true : JSON.parse(currentOrder?.comments)?.['isIn5G'];

    let uiType = 'Home';
    if (uiMode === 'sme') {
      uiType = 'Work';
    }

    const rainOneLevelPrice = rainOneSim?.price;

    let totalProductValue = 0;
    let bundleAndSpeedPrice = rainOne101Bundle?.price;

    if (rainOneLevelPrice) {
      totalProductValue += rainOneLevelPrice;
    }
    if (rainOne101SpeedItem?.price) {
      totalProductValue += rainOne101SpeedItem?.price;
      bundleAndSpeedPrice += rainOne101SpeedItem?.price;
    }

    const techType = isIn5G ? '5G' : '4G';
    const rainOneLevel = rainOneSim?.config?.level;
    const itemName = `${techType} rainOne 101 Unlimited ${uiType}`;
    const paymentDate = paymentDateObject?.selectedPaymentDate ?? 1;

    return {
      item_name: itemName,
      id: rainOneBundle?.id,
      quantity: 1,
      item_brand: 'Rain',
      item_categories: {
        techType: rainOneSim?.category,
        selectedSpeed: rainOne101SpeedItem ? selectedRainOne101Speed : '30',
        selectedLevel: rainOneLevel,
        selectedSkin: rainOne101Skin ? rainOne101Skin?.name : 'to be selected',
        paymentDate: paymentDate
      },
      totalProductValue: totalProductValue || currentOrder?.totalPrice
    };
  }

  @Selector([CartState.GetSelectedNvidiaBundle, ProductState.GetNvidiaProducts, BillingState.getPaymentDate, OrderState.currentOrder])
  static getDataLayerNvidiaCartProduct(
    state: CartStateModel,
    nvidiaBundle: RainOneProduct,
    products: RainOneProduct[],
    paymentDateObject: PaymentDateObject,
    currentOrder: IOrderDetail
  ) {
    const nvidiaProductIds = products?.map(product => product?.id);
    const orderNvidiaItem = currentOrder?.items?.find(item => nvidiaProductIds?.includes(item?.productId))
    const bundle = nvidiaBundle || products?.find(product => product?.id === orderNvidiaItem?.productId);
    const totalProductValue = bundle.recurringPrice;

    const paymentDate = paymentDateObject?.selectedPaymentDate && paymentDateObject?.saved ? paymentDateObject?.selectedPaymentDate : 1;

    return {
      item_name: `nvidia ${bundle?.config?.tier}`,
      id: bundle?.id,
      quantity: 1,
      item_brand: 'Nvidia',
      item_categories: {
        tier: bundle?.config?.tier,
        paymentDate: paymentDate
      },

      totalProductValue: totalProductValue || currentOrder?.totalPrice
    };
  }

  @Selector([
    CartState.getDataLayerRainOne101CartProduct, 
    CartState.getDataLayerNvidiaCartProduct, 
    CartState.getDataLayerFourGProductsCartProduct
  ])
  static getDataLayerItems(
    state: CartStateModel, 
    rainOne101CartProduct, 
    nvidiaCartProduct, 
    fourGProductsCartProduct) {
    const dataLayerItems = [];
    [rainOne101CartProduct, nvidiaCartProduct, fourGProductsCartProduct].forEach(item => {

      if (item?.id) {
        item?.totalProductValue ? dataLayerItems.push(item) : null;
      }
     
    });

    return dataLayerItems;
  }

  @Selector()
  static registerWithoutCompanyDetails(state: CartStateModel): boolean {
    return state.registerWithoutCompanyDetails;
  }

  @Selector()
  static getRainOneOrder(state: CartStateModel): any[] {
    const rainOneOrders = [];
    rainOneOrders.push(state.rainOneOrder);
    return rainOneOrders;
  }

  @Selector()
  static getSelectedRouterSkin(state: CartStateModel) {
    return state.selectedRouterSkin;
  }

  @Action(AddToCart)
  addToCart(ctx: StateContext<CartStateModel>, action: AddToCart) {
    const state = ctx.getState();
    const cartProductId = action.item.productId;
    const allProductsMaprain = this.store.selectSnapshot(ProductState.allProductsMaprain) as any;

    const isProductICCID = ProductDetail.containsConfig(allProductsMaprain[cartProductId], CONFIG_ICCID);
    //  || allProductsMaprain[cartProductId].type === 'bundle'
    const isProduct5G = allProductsMaprain[cartProductId].name.includes('5G');
    const isProduct4G =
      allProductsMaprain[cartProductId].name.includes('4G') ||
      allProductsMaprain[cartProductId].name.includes('19 hours') ||
      allProductsMaprain[cartProductId].name.includes('standalone');
    //
    const cartItem: ICartItemMapped = { item: action.item, product: allProductsMaprain[cartProductId] };
    if (isProductICCID) {
      return ctx.patchState({
        gotSimProducts: [...state.gotSimProducts, cartItem]
      });
    } else if (isProduct5G) {
      return ctx.patchState({
        fiveGProducts: [...state.fiveGProducts, cartItem]
      });
    } else if (isProduct4G) {
      ctx.patchState({
        fourGProducts: [...state.fourGProducts, cartItem]
      });
      // [NOTE] - Event moved to 4g mobile product page
      // return this.dataLayerservice.addToCart();
    }
    // Run datalayerEvent Here
  }

  @Action(RemoveFromCart)
  removeFromCart(ctx: StateContext<CartStateModel>, action: RemoveFromCart) {
    const state = ctx.getState();
    const cartItemId = action.item?.id;
    const cartProductId = action.item.productId;
    const mappedProducts = this.store.selectSnapshot(ProductState.allProductsMap);
    const isProductICCID = ProductDetail.containsConfig(mappedProducts[cartProductId], CONFIG_ICCID);
    const isProduct5G = mappedProducts[cartProductId].name.includes('5G');
    const isProduct4G = mappedProducts[cartProductId].name.includes('4G') || mappedProducts[cartProductId].name.includes('19 hours');
    const isRainOneBundle: boolean = state.rainOneOrder?.id === cartProductId;
    const isNvidiaBundle: boolean = state.nvidiaOrder?.id === cartProductId;
    if (isProduct4G) {
      return ctx.patchState({
        fourGProducts: state.fourGProducts.filter(p => p.item?.id !== cartItemId)
      });
    } else if (isProduct5G) {
      return ctx.patchState({
        fiveGProducts: state.fiveGProducts.filter(p => p.item?.id !== cartItemId)
      });
    } else if (isProductICCID) {
      return ctx.patchState({
        gotSimProducts: state.gotSimProducts.filter(p => p.item?.id !== cartItemId)
      });
    }

    if (isRainOneBundle) {
      ctx.patchState({
        rainOneOrder: null,
        order_type: state.nvidiaOrder ? 'nvidia' : null
      });
    }

    if (isNvidiaBundle) {
      ctx.patchState({
        nvidiaOrder: null,
        order_type: state.rainOneOrder ? 'bundle' : null
      });
    }
    // dispatch clear event
    this.store.dispatch(new ClearCart());
  }

  @Action(SetAllowedProductLimit)
  setAllowedProductLimit(ctx: StateContext<CartStateModel>, action: SetAllowedProductLimit) {
    return ctx.patchState({
      allowed4GInCart: action.limits.allowed4G,
      allowed5GInCart: action.limits.allowed5G
    });
  }

  @Action(SetOrderType)
  setOrderType(ctx: StateContext<CartStateModel>, action: SetOrderType) {
    if (!action.type) ctx.dispatch(new ClearCart());
    return ctx.patchState({
      order_type: action.type
    });
  }

  @Action(SetXtenderSpeed)
  xtenderSpeed(ctx: StateContext<CartStateModel>, action: SetXtenderSpeed) {
    return ctx.patchState({
      xtenderSpeed: action.xtenderSpeed
    });
  }

  @Action(ResetCart)
  reset(ctx: StateContext<CartStateModel>, action: ResetCart) {
    const state = ctx.getState();
    localStorage.removeItem('continue_with_4g');
    return ctx.patchState({
      gotSimProducts: [],
      fourGProducts: [],
      fiveGProducts: [],
      allCartItems: null,
      allowed4GInCart: 10,
      allowed5GInCart: 3,
      rainOneOrder: null,
      nvidiaOrder: null,
      nvidiaConfig: {
        tier: null
      },
      addOns: [],
      vas: [],
      wifi_speed_index: 0,
      top_up_amounts: null,
      top_up_order_result: null,
      order_type: null,
      registerWithoutCompanyDetails: false
    });
  }

  @Action(EmptyCart)
  EmptyCart(ctx: StateContext<CartStateModel>, action: EmptyCart) {
    const state = ctx.getState();
    this.cartSVC.clear().subscribe({
      next: res => {
        if (res) {
          ctx.patchState({
            gotSimProducts: [],
            fourGProducts: [],
            fiveGProducts: [],
            allCartItems: null,
            allowed4GInCart: 10,
            allowed5GInCart: 3,
            rainOneOrder: null,
            nvidiaOrder: null,
            nvidiaConfig: {
              tier: null
            },
            addOns: [],
            vas: [],
            wifi_speed_index: 0,
            top_up_amounts: null,
            top_up_order_result: null,
            order_type: null,
            registerWithoutCompanyDetails: false
          });

          localStorage.removeItem('isIn5G');

          return this.dataLayerservice.removeFromCart();
        }
      }
    });
  }

  @Action(ClearCart)
  ClearCart(ctx: StateContext<CartStateModel>, action: ClearCart) {
    const state = ctx.getState();
    const currentBundle = state.rainOneOrder || state.nvidiaOrder;
    this.cartSVC.getMappedItems().subscribe(products => {
      // Track removed product
      const removedProductIndex = products.findIndex(i => {
        return i?.item?.productId === currentBundle?.id;
      });

      if (removedProductIndex > -1) {
        this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
      }

      if (products[removedProductIndex]) {
        this.cartSVC.remove(products[removedProductIndex]).subscribe({
          next: _ => {
            return ctx.patchState({
              gotSimProducts: [],
              fourGProducts: [],
              fiveGProducts: [],
              allCartItems: null,
              allowed4GInCart: 10,
              allowed5GInCart: 3,
              rainOneOrder: null,
              addOns: [],
              vas: [],
              wifi_speed_index: 0,
              top_up_amounts: null,
              top_up_order_result: null,
              order_type: null,
              nvidiaOrder: null,
              nvidiaConfig: {
                tier: null
              },
              registerWithoutCompanyDetails: false
            });
          }
        });
      }
    });
    return ctx.patchState({
      gotSimProducts: [],
      fourGProducts: [],
      fiveGProducts: [],
      allCartItems: null,
      allowed4GInCart: 10,
      allowed5GInCart: 3,
      rainOneOrder: null,
      addOns: [],
      vas: [],
      wifi_speed_index: 0,
      top_up_amounts: null,
      top_up_order_result: null,
      order_type: null,
      nvidiaOrder: null,
      nvidiaConfig: {
        tier: null
      },
      registerWithoutCompanyDetails: false
    });
  }

  @Action(RemoveNvidiaFromCart)
  RemoveNvidiaFromCart(ctx: StateContext<CartStateModel>, action: RemoveNvidiaFromCart) {
    const state = ctx.getState();
    const currentBundle = state.nvidiaOrder;
    this.cartSVC.getMappedItems().subscribe(products => {
      // Track removed product
      const removedProductIndex = products.findIndex(i => {
        return i?.item?.productId === currentBundle?.id;
      });

      if (removedProductIndex > -1) {
        this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
      }

      if (products[removedProductIndex]) {
        this.cartSVC.remove(products[removedProductIndex]).subscribe({
          next: _ => {
            return ctx.patchState({
              nvidiaOrder: null,
              nvidiaConfig: {
                tier: null
              }
            });
          }
        });
      }
    });
    // return ctx.patchState({
    //   gotSimProducts: [],
    //   fourGProducts: [],
    //   fiveGProducts: [],
    //   allCartItems: null,
    //   allowed4GInCart: 10,
    //   allowed5GInCart: 3,
    //   rainOneOrder: null,
    //   addOns: [],
    //   vas: [],
    //   wifi_speed_index: 0,
    //   top_up_amounts: null,
    //   top_up_order_result: null,
    //   order_type: null,
    //   nvidiaOrder: null,
    //   nvidiaConfig: {
    //     tier: null
    //   }
    // });
  }

  @Action(StartRainOnePurchase)
  StartRainOnePurchase(ctx: StateContext<CartStateModel>, action: StartRainOnePurchase) {
    const state = ctx.getState();
    const hasAddedNvidiaBundle = state.nvidiaOrder;
    // if (state.rainOneOrder) return;
    const id = action.payload;
    const bundle = this.store.selectSnapshot(ProductState.GetCatelogueProducts).find((p: any) => p.type === 'bundle' && p.id === id);

    ctx.patchState({
      rainOneOrder: bundle,
      vas: [],
      addOns: [],
      order_type: hasAddedNvidiaBundle ? 'multi' : 'bundle'
    });
  }

  @Action(StartMobilePurchase)
  StartMobilePurchase(ctx: StateContext<CartStateModel>, action: StartMobilePurchase) {
    const state = ctx.getState();
    if (state.rainOneOrder) return;
    const id = action.payload;
    const bundle = this.store.selectSnapshot(ProductState.GetCatelogueProducts).find((p: any) => p.type === 'bundle' && p.id === id);

    return ctx.patchState({
      mobileOrder: bundle,
      vas: [],
      addOns: [],
      order_type: 'mobile'
    });
  }

  @Action(InitMigrationWithNvidia)
  InitMigrationWithNvidia(ctx: StateContext<CartStateModel>, action: InitMigrationWithNvidia) {
    const state = ctx.getState();
    const type = action.payload.type;
    if (state.rainOneOrder) return;
    const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
    let bundle = isPostPaid ? this.store.selectSnapshot(ProductState.GetRain101PPBase) : this.store.selectSnapshot(ProductState.GetRain101AUBase);
    // const bundle = this.store.selectSnapshot(ProductState.GetCatelogueProducts).find((p: any) => p.type === 'bundle' && p.id === id);
    return ctx.patchState({
      rainOneOrder: bundle,
      vas: [],
      addOns: [],
      order_type: type === '4G' ? 'nvidia_with_4G_purchase' : 'nvidia_with_5G_purchase'
    });
  }

  @Action(AddExtraPhoneLine)
  AddExtraPhoneLine(ctx: StateContext<CartStateModel>, action: AddExtraPhoneLine) {
    const state = ctx.getState();
    const currentSelectedBundle = state.rainOneOrder;
    const phoneLineProduct = this.store.selectSnapshot(ProductState.GetPhoneLine(currentSelectedBundle.name));

    let existingAddOns = [];

    if (state.addOns && state.addOns.length > 0) {
      existingAddOns = [...state.addOns, phoneLineProduct];
    } else {
      existingAddOns = [phoneLineProduct];
    }

    ctx.patchState({
      addOns: existingAddOns
    });
  }

  @Action(RemoveExtraPhoneLine)
  RemoveExtraPhoneLine(ctx: StateContext<CartStateModel>, action: RemoveExtraPhoneLine) {
    const state = ctx.getState();
    const productID = action.payload;
    let existingAddOns = state.addOns;
    let addOnsToRemove = existingAddOns.filter(p => p.id === productID);
    existingAddOns = existingAddOns.filter(p => p.id !== productID);

    if (addOnsToRemove.length > 1) addOnsToRemove.pop();
    else {
      addOnsToRemove = [];
    }

    existingAddOns = [...existingAddOns, ...addOnsToRemove];
    ctx.patchState({
      addOns: existingAddOns
    });
  }
  @Action(RemoveWifiSpeed)
  RemoveWifiSpeed(ctx: StateContext<CartStateModel>, action: RemoveWifiSpeed) {
    const state = ctx.getState();
    const productID = action.payload;
    let existingAddOns = state.vas;
    let addOnsToRemove = existingAddOns.filter(p => p.id === productID);
    existingAddOns = existingAddOns.filter(p => p.id !== productID);

    if (addOnsToRemove.length > 1) addOnsToRemove.pop();
    else {
      addOnsToRemove = [];
    }

    existingAddOns = [...existingAddOns, ...addOnsToRemove];

    ctx.patchState({
      vas: existingAddOns,
      wifi_speed_index: 0
    });
  }

  @Action(RemoveNvidiaAddon)
  RemoveNvidiaAddon(ctx: StateContext<CartStateModel>, action: RemoveNvidiaAddon) {
    const state = ctx.getState();

    ctx.patchState({
      nvidiaOrder: null,
      order_type: 'bundle'
    });
  }

  @Action(MigratingToR1FiveG)
  migrateToRainOne5G(ctx: StateContext<CartStateModel>, action: MigratingToR1FiveG) {
    ctx.patchState({
      migratingToR1FiveG: action.payload
    });
  }
  @Action(ChangeWifiSpeed)
  ChangeWifiSpeed(ctx: StateContext<CartStateModel>, action: ChangeWifiSpeed) {
    const state = ctx.getState();
    const index = action.payload;
    const currentSelectedBundle = state.rainOneOrder;

    const selectedWifiSpeed = this.store.selectSnapshot(ProductState.GetAddonById(currentSelectedBundle.config.add_ons[index].id));

    ctx.patchState({
      vas: [selectedWifiSpeed],
      wifi_speed_index: index
    });
  }

  @Action(ChangeRainOneLevel)
  ChangeRainOneLevel(ctx: StateContext<CartStateModel>, action: ChangeRainOneLevel) {
    const state = ctx.getState();
    const uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    const currentBundle = state.rainOneOrder;
    const levelName: string = action.payload.name;
    const type = action.payload.type;
    const bundle =
      uiMode === 'sme'
        ? this.store.selectSnapshot(ProductState.GetRainBundleByNameAndType(levelName, 'upfront'))
        : this.store.selectSnapshot(ProductState.GetRainBundleByNameAndType(levelName, type));
    const vas = state.vas;
    const addons = state.addOns;
    ctx.patchState({
      vas: [],
      addOns: [],
      selectedLevelForDataLayer: levelName
    });

    this.cartSVC.getMappedItems().subscribe(products => {
      // Track removed product
      const removedProductIndex = products.findIndex(i => {
        return i.item.productId === currentBundle.id;
      });

      if (removedProductIndex > -1) {
        this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
      }

      if (products[removedProductIndex]) {
        this.cartSVC.remove(products[removedProductIndex]).subscribe(result => {
          this.cartSVC
            .add(bundle.id, {
              [CONFIG_FRIENDLY_NAME]: '5G SIM',
              [CONFIG_COMMENTS]: undefined
            })
            .subscribe(() => {
              ctx.patchState({
                rainOneOrder: bundle,
                vas: vas,
                addOns: addons
              });
            });
        });
      }
    });
  }

  @Action(SwapRainOneAuForPP)
  SwapRainOneAuForPP(ctx: StateContext<CartStateModel>, action: SwapRainOneAuForPP) {
    const uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    const state = ctx.getState();
    const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);

    if (!isPostPaid) return;

    const currentSelectedBundle = state.rainOneOrder;
    if (!currentSelectedBundle) return;
    const PostPaidBundle =
      uiMode === 'sme'
        ? this.store.selectSnapshot(ProductState.GetAURainOneBundle(currentSelectedBundle?.name))
        : this.store.selectSnapshot(ProductState.GetPPRainOneBundle(currentSelectedBundle?.name));
    const vasIndex = state.wifi_speed_index;
    const addons = state.addOns;

    ctx.patchState({
      vas: [],
      addOns: []
    });

    this.cartSVC.getMappedItems().subscribe(products => {
      // Track removed product
      const removedProductIndex = products.findIndex(i => {
        return i.item.productId === currentSelectedBundle.id;
      });

      if (removedProductIndex > -1) {
        this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
      }

      if (products?.[removedProductIndex]) {
        this.cartSVC.remove(products?.[removedProductIndex]).subscribe(result => {
          this.cartSVC
            .add(PostPaidBundle?.id, {
              [CONFIG_FRIENDLY_NAME]: '5G SIM',
              [CONFIG_COMMENTS]: undefined
            })
            .subscribe(() => {
              if (vasIndex !== 0) {
                const vasID = PostPaidBundle.config.add_ons[vasIndex];
                const vas = this.store.selectSnapshot(ProductState.GetAddonById(vasID.id));
                ctx.patchState({
                  rainOneOrder: PostPaidBundle,
                  vas: [vas]
                });
              } else {
                ctx.patchState({
                  rainOneOrder: PostPaidBundle
                });
              }
            });
        });
      }
    });
  }

  @Action(SwapRainOneWifiAuForPP)
  SwapRainOneWifiAuForPP(ctx: StateContext<CartStateModel>, action: SwapRainOneWifiAuForPP) {
    const isPostPaid = this.store.select(ServicesState.hasPostPaid);
    const currentWifiSpeed = this.store.selectSnapshot(CartState.GetAllVas);

    if (!currentWifiSpeed || currentWifiSpeed.length === 0) return;
    if (isPostPaid && currentWifiSpeed[0].config.paymentType === 'postpaid') return;

    const ppWifiProduct = this.store.selectSnapshot(ProductState.GetPPRainOneWifiProduct(currentWifiSpeed[0].name));

    ctx.patchState({
      vas: [ppWifiProduct]
    });
  }

  @Action(SetTopUpAmounts)
  SetTopUpAmounts(ctx: StateContext<CartStateModel>, action: SetTopUpAmounts) {
    const payload = action.payload;

    ctx.patchState({
      top_up_amounts: payload
    });
  }

  @Action(SetSelectedRouterSkin)
  SetSelectedRouterSkin(ctx: StateContext<CartStateModel>, action: SetSelectedRouterSkin) {
    // this must always be in this order
    // let cteSkin = action.payload[0];
    // let extender1Skin = action.payload[1];
    // let extender2Skin = action.payload[2];
    ctx.patchState({
      accessories: action.payload,
      selectedRouterSkin: action.payload 
    });
  }

  @Action(IsRainOneTopup)
  IsRainOneTopUp(ctx: StateContext<CartStateModel>, action: IsRainOneTopup) {
    ctx.patchState({
      isRainOneTopUp: action.isRain1TopUp
    });
  }

  @Action(TopUPPurchase)
  TopUPPurchase(ctx: StateContext<CartStateModel>, action: TopUPPurchase) {
    const payload = action.payload;

    this._topUpSVC.purchase(payload).subscribe({
      next: res => {
        ctx.dispatch(new TopUPPurchaseSuccess(res));

        this.cacheSVC.setObject(CACHE_GIVE_A_GIG_PURCHASE_DETAILS, payload);
        this.cacheSVC.remove(CACHE_EDIT_GIVE_A_GIG);
      },
      error: err => ctx.dispatch(new TopUPPurchaseFail(err))
    });
  }

  @Action(TopUPPurchaseSuccess)
  TopUPPurchaseSuccess(ctx: StateContext<CartStateModel>, action: TopUPPurchaseSuccess) {
    const payload = action.payload;

    ctx.patchState({
      top_up_order_result: payload
    });
    this.store.dispatch(new Navigate(['/payment-details']));
  }

  @Action(TopUPPurchaseFail)
  TopUPPurchaseFail(ctx: StateContext<CartStateModel>, action: TopUPPurchaseFail) {
    const state = ctx.getState();
    const payload = action.payload;
  }

  @Action(ClearTopUpDetails)
  ClearTopUpDetails(ctx: StateContext<CartStateModel>, action: ClearTopUpDetails) {
    ctx.patchState({
      top_up_amounts: null,
      top_up_order_result: null,
      isRainOneTopUp: false
    });
  }

  @Action(ClearAllVas)
  ClearAllVas(ctx: StateContext<CartStateModel>, action: ClearAllVas) {
    const state = ctx.getState();
    const selectedVas = state.vas[0];

    ctx.patchState({ selectedLevelForDataLayer: '' });

    ctx.dispatch(new RemoveWifiSpeed(selectedVas.id));
  }

  @Action(StartNvidiaPurchase)
  StartNvidiaPurchase(ctx: StateContext<CartStateModel>, action: StartNvidiaPurchase) {
    const state = ctx.getState();
    const id = action.payload;
    const hasRainOneOrder = state.rainOneOrder;
    const currentOrderType = state.order_type;
    if (state.nvidiaOrder) return this.store.dispatch(new ChangeNvidiaTier(id));

    const bundle: any = this.store.selectSnapshot(ProductState.GetNvidiaProducts).find((p: any) => p.id === id);
    const calculatedPrice = bundle?.price;
    const nvidiaItem = {
      ...bundle,
      price: calculatedPrice,
      recurringPrice: calculatedPrice
    };

    return this.cartSVC
      .add(bundle.id, {
        [CONFIG_FRIENDLY_NAME]: bundle.name,
        [CONFIG_COMMENTS]: undefined
      })
      .subscribe({
        next: _ => {
          const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);
          let url: string;
          if (isAuthed) {
            url = action.isMultiPurchase ? 'purchase-wizard' : 'cart';
          } else {
            url = action.isMultiPurchase ? 'register/purchase/purchase-wizard' : 'register/cart';
          }

          ctx.patchState({
            nvidiaOrder: nvidiaItem,
            vas: [],
            addOns: [],
            order_type: currentOrderType !== null ? currentOrderType : hasRainOneOrder ? 'multi' : 'nvidia',
            nvidiaConfig: {
              tier: bundle.config.tier
            }
          });
          this.dataLayerservice.addToCart();
          this.store.dispatch(new Navigate([url]));
        },
        error: err => console.log('err:', err)
      });
  }
  @Action(StartNvidiaPurchaseWith5GMigrations)
  StartNvidiaPurchaseWith5GMigrations(ctx: StateContext<CartStateModel>, action: StartNvidiaPurchaseWith5GMigrations) {
    const state = ctx.getState();
    const id = action.payload;
    const hasRainOneOrder = state.rainOneOrder;
    const currentOrderType = state.order_type;
    if (state.nvidiaOrder) return this.store.dispatch(new ChangeNvidiaTier(id));

    const bundle: any = this.store.selectSnapshot(ProductState.GetNvidiaProducts).find((p: any) => p.id === id);
    const calculatedPrice = bundle?.price > 1 && bundle?.config?.rainCustomer ? bundle?.price * (80 / 100) : bundle?.price;
    const nvidiaItem = {
      ...bundle,
      price: calculatedPrice,
      recurringPrice: calculatedPrice
    };

    return this.cartSVC
      .add(bundle.id, {
        [CONFIG_FRIENDLY_NAME]: bundle.name,
        [CONFIG_COMMENTS]: undefined
      })
      .subscribe({
        next: _ => {
          const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);
          let url: string;
          if (isAuthed) {
            url = action.isMultiPurchase ? 'purchase-wizard' : 'cart';
          } else {
            url = action.isMultiPurchase ? 'register/purchase/purchase-wizard' : 'register/cart';
          }

          ctx.patchState({
            nvidiaOrder: nvidiaItem,
            vas: [],
            addOns: [],
            order_type: 'nvidia_with_5G_purchase',
            nvidiaConfig: {
              tier: bundle.config.tier
            }
          });
          this.dataLayerservice.addToCart();
          this.store.dispatch(new Navigate([url]));
        },
        error: err => console.log('err:', err)
      });
  }

  @Action(ChangeNvidiaTier)
  ChangeNvidiaTier(ctx: StateContext<CartStateModel>, action: ChangeNvidiaTier) {
    const state = ctx.getState();
    const id = action.payload;
    const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);

    const currentSelectedBundle = state.rainOneOrder;
    const bundle: any = this.store.selectSnapshot(ProductState.GetCatelogueProducts).find((p: any) => p.id === id);
    const url: string = this.store.selectSnapshot(CoreState.isAuthenticated) ? '/cart' : '/register/cart';

    ctx.patchState({
      vas: [],
      addOns: []
    });

    this.cartSVC.getMappedItems().subscribe(products => {
      // Track removed product
      const removedProductIndex = products.findIndex(i => {
        return i.item.productId === currentSelectedBundle?.id;
      });

      if (removedProductIndex > -1) {
        this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
      }

      if (products[removedProductIndex]) {
        this.cartSVC.remove(products[removedProductIndex]).subscribe({
          next: res => {
            this.cartSVC
              .add(bundle.id, {
                [CONFIG_FRIENDLY_NAME]: bundle.name,
                [CONFIG_COMMENTS]: undefined
              })
              .subscribe(() => {
                ctx.patchState({
                  nvidiaOrder: bundle,
                  nvidiaConfig: {
                    tier: bundle.config.tier
                  }
                });
                this.store.dispatch(new Navigate([url]));
              });
          }
        });
      }
    });
  }

  @Action(SwapNvidiaProducts)
  SwapNvidiaProducts(ctx: StateContext<CartStateModel>, action: SwapRainOneAuForPP) {
    const state = ctx.getState();
    const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
    const currentSelectedBundle: RainOneProduct = state.nvidiaOrder;
    const hasActiveNvidiaProduct = this.store.selectSnapshot(ServicesState.hasNvidiaProduct);

    if (!currentSelectedBundle || currentSelectedBundle.config.subtype !== 'nvidia') return;
    if (isPostPaid && currentSelectedBundle.config.paymentType === 'postpaid') return;
    else if (!isPostPaid && currentSelectedBundle.config.paymentType === 'upfront') {
      const hasActiveService = this.store.selectSnapshot(ServicesState.HasActiveService);

      if (!hasActiveService) return;
    }

    const bundle = this.store.selectSnapshot(
      ProductState.GetNvidiaProductsByTierAndType(currentSelectedBundle.config.tier, isPostPaid ? 'postpaid' : 'upfront')
    );

    this.cartSVC.getMappedItems().subscribe(products => {
      // Track removed product
      const removedProductIndex = products.findIndex(i => {
        return i.item.productId === currentSelectedBundle.id;
      });

      if (removedProductIndex > -1) {
        this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
      }

      if (products[removedProductIndex]) {
        this.cartSVC.remove(products[removedProductIndex]).subscribe(result => {
          this.cartSVC
            .add(bundle.id, {
              [CONFIG_FRIENDLY_NAME]: bundle.name,
              [CONFIG_COMMENTS]: undefined
            })
            .subscribe(() => {
              ctx.patchState({
                nvidiaOrder: bundle,
                nvidiaConfig: {
                  tier: bundle.config.tier
                }
              });
            });
        });
      }
    });
  }

  @Action(CheckForOpenNvidiaOrder)
  CheckForOpenNvidiaOrder(ctx: StateContext<CartStateModel>, action: CheckForOpenNvidiaOrder) {
    const state = ctx.getState();
    const nvidiaIDs = this.store.selectSnapshot(ProductState.GetNvidiaProducts).map(p => p.id);
    const allService = this.store.selectSnapshot(ServicesState.getAllServices);
    const allOrders = this.store.selectSnapshot(OrderState.GetallNonCancelledOrders);
    if (allOrders) {
      const hasActiveNvidiaService = allService.find(s => s.productName.toLocaleLowerCase().includes('nvidia'));
      const hasActiveNvidiaOrder = allOrders.find(o => o.items.find(ol => nvidiaIDs.includes(ol.productId)));
      const currentBundle = state.nvidiaOrder;

      if (hasActiveNvidiaOrder || hasActiveNvidiaService) {
        this.cartSVC.getMappedItems().subscribe(products => {
          // Track removed product
          const removedProductIndex = products.findIndex(i => {
            return i.item.productId === currentBundle.id;
          });

          if (removedProductIndex > -1) {
            this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
          }

          if (products[removedProductIndex]) {
            this.cartSVC.remove(products[removedProductIndex]).subscribe(result => {
              ctx.patchState({
                nvidiaOrder: null,
                nvidiaConfig: {
                  tier: null
                },
                order_type: state.rainOneOrder ? 'bundle' : null
              });
            });
          }
        });
      }
    }
  }

  @Action(RemoveRainOneFromCart)
  RemoveRainOneFromCart(ctx: StateContext<CartStateModel>, action: RemoveRainOneFromCart) {
    const state = ctx.getState();
    const currentBundle = state.rainOneOrder;
    this.cartSVC.getMappedItems().subscribe(products => {
      // Track removed product
      const removedProductIndex = products.findIndex(i => {
        return i.item.productId === currentBundle.id;
      });

      if (removedProductIndex > -1) {
        this.dataLayerservice.productRemoveFromCart(products[removedProductIndex].product);
      }

      if (products[removedProductIndex]) {
        this.cartSVC.remove(products[removedProductIndex]).subscribe(result => {
          ctx.patchState({
            rainOneOrder: null,
            order_type: 'nvidia'
          });
        });
      }
    });
  }

  @Action(InitMigration)
  Start5GLegacyMigration(ctx: StateContext<CartStateModel>, action: InitMigration) {
    const state = ctx.getState();
    const id = action.payload;
    const hasRainOneOrder = state.rainOneOrder;
    const migrationConfig = this.store.selectSnapshot(ServicesState.GetMigrationsConfig);

    const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
    const legacyBundle = this.store.selectSnapshot(ProductState.GetCatelogueProducts).find((p: any) => p.id === migrationConfig.productId);

    if (legacyBundle) {
      const rainoneBundle = this.store.selectSnapshot(ProductState.GetRainOneProductById(legacyBundle.config.migration[0].id));

      const speedUp = this.store.selectSnapshot(ProductState.GetRainOneProductById(rainoneBundle.config.add_ons[2].id));

      return this.cartSVC
        .add(rainoneBundle.id, {
          [CONFIG_FRIENDLY_NAME]: rainoneBundle.name,
          [CONFIG_COMMENTS]: undefined
        })
        .subscribe({
          next: _ => {
            const url: string = this.store.selectSnapshot(CoreState.isAuthenticated) ? '/cart' : '/register/cart';
            ctx.patchState({
              rainOneOrder: rainoneBundle,
              vas: [speedUp],
              addOns: [],
              order_type: hasRainOneOrder ? 'multi' : 'nvidia'
            });

            // this.store.dispatch(new Navigate([url]));
          },
          error: err => console.log('err:', err)
        });
    }
  }

  @Action(CoverageCheckedOnRegistration)
  CoverageCheckedOnRegistration(ctx: StateContext<CartStateModel>, action: CoverageCheckedOnRegistration) {
    return ctx.patchState({
      coverageCheckedOnRegistration: action?.checked
    });
  }

  @Action(RegisterWithoutCompanyDetails)
  RegisterWithoutCompanyDetails(ctx: StateContext<CartStateModel>, action: RegisterWithoutCompanyDetails) {
    return ctx.patchState({
      registerWithoutCompanyDetails: action?.checked
    });
  }
}
