<div class="modal-content border-0 px-10">
    <div class="modal-img-close-btn z-index-5">
        <img src="../../assets/svg/close.svg" class="pointer" alt="Close" (click)="activeModal.dismiss('Cross click')"
            data-dismiss="modal" data-target="#FiveGCoverageModal" aria-label="Close" />
    </div>
    <div ngbAutoFocus class="modal-body pb-20 pt-0 pl-0 pr-0 mt-20" *ngIf="product">
        <h2 class="font-weight-bold text-primary text-center">
            great news <br> you're in <span class="text-uppercase">5G</span> coverage!
        </h2>
        <div class=" text-primary text-center">
            Let us know if you'd like to talk to an agent about <br> switching now or if you'd like an agent to give you
            more <br>
            informtion before switching
        </div>
        <div class="d-flex justify-content-center mt-5 flex-sm-row flex-column">

            <button class="mx-2 btn btn-sm btn-outline-blue mt-10" (click)="requestSwitch('More info')">
                more info please
            </button>

            <button class="mx-2 btn btn-sm btn-primary  mt-10" (click)="requestSwitch('Switch now')">
                switch now
            </button>
        </div>
    </div>
</div>