import { IRemotePaymentMethod } from './remote/remotePaymentMethod';
import { ModelHelper } from './modelHelper';

export class PaymentDetail {
  static adapt(remotePaymentMethod: IRemotePaymentMethod): IPaymentDetail {
    let paymentDetail = {
      id: ModelHelper.clean(remotePaymentMethod.id),
      token: ModelHelper.clean(remotePaymentMethod.token),
      userId: ModelHelper.clean(remotePaymentMethod.user_id),
      name: ModelHelper.clean(remotePaymentMethod.friendly_name),
      cardType: ModelHelper.clean(remotePaymentMethod.card_type),
      cardNumber: remotePaymentMethod.card_number,
      cardExpiry: remotePaymentMethod.card_expiry
    } as IPaymentDetail;

    return paymentDetail;
  }
}

export interface IPaymentDetail {
  id: string;
  userId: string;
  token: string;
  name: string;
  cardType: String;
  cardNumber: string;
  cardExpiry: string;
}
