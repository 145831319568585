import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { CoreState } from "src/app/core/store/state/core.state";
import { ChangeOpenBottomSheet, ShowBottomSheet } from "../actions/bottom-sheet.actions";
import { CancelNvidiaConfirmationModalComponent } from "../../components/modals/cancel-nvidia-confirmation-modal/cancel-nvidia-confirmation-modal.component";
import { Injectable } from "@angular/core";
import { GetAllServicesV2 } from "../actions/services.action";
import { RevokeNvidiaCancellationModalComponent } from "../../components/modals/revoke-nvidia-cancellation-modal/revoke-nvidia-cancellation-modal.component";
import { NvidiaErrorModalComponent } from "../../components/modals/nvidia-error-modal/nvidia-error-modal.component";

@Injectable({
    providedIn: 'root'
})

export class SuccessErrorHandler {
    @Select(CoreState.activeBreakpoint) breakpoint$: Observable<string>


    constructor(
        private store: Store,
        private modalService: NgbModal
    ) { }

    public nvidiaCancellationSuccess() {
        this.breakpoint$.subscribe(res => {
            if (res !== 'desktop') {
                this.store.dispatch([new ChangeOpenBottomSheet("nvidia_cancellation_confirmation"), new GetAllServicesV2()])
            } else {
                this.store.dispatch(new GetAllServicesV2())
                this.modalService.open(CancelNvidiaConfirmationModalComponent, {
                    size: 'confirm' as any,
                    centered: true
                })
            }
        })
    }
    public revokeNvidiaCancellationSuccess() {
        this.breakpoint$.subscribe(res => {
            if (res !== 'desktop') {
                this.store.dispatch([new ShowBottomSheet("revoke_nvidia_cancellation"), new GetAllServicesV2()])
            } else {
                this.store.dispatch(new GetAllServicesV2())
                this.modalService.open(RevokeNvidiaCancellationModalComponent, {
                    size: 'confirm' as any,
                    centered: true
                })
            }
        })
    }

    public nvidiaError(reason: string) {
        this.breakpoint$.subscribe(res => {
            if (res !== 'desktop') {
                this.store.dispatch(new ChangeOpenBottomSheet("nvidia_error"))
            } else {
                let modalRef: NgbModalRef = this.modalService.open(NvidiaErrorModalComponent, {
                    size: 'confirm' as any,
                    centered: true
                })
                modalRef.componentInstance.reason = reason;
            }
        })
    }

}