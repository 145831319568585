import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-cancellation-success-modal',
  templateUrl: './cancellation-success-modal.component.html',
  styleUrls: ['./cancellation-success-modal.component.scss']
})
export class CancellationSuccessModalComponent {

  constructor(
    public activeModal: NgbActiveModal,

  ) { }


  close() {
    this.activeModal.close()
  }
}
