import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LimitSalesTicketCreationState} from '@components/sales-modal/store/state/limit-ticket-creation.state';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Select, Store} from '@ngxs/store';
import {NvidiaSalesModalComponent} from '@pages/nvidia-landing-page/nvidia-sales-modal/nvidia-sales-modal.component';
import {AuthenticationService} from '@services/auth.service';
import {CartService} from '@services/cart.service';
import {CookieService} from '@services/cookie.service';
import {NvidiaService} from '@services/nvidia.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RainOneProduct} from 'src/app/core/interfaces/rain-one-products.interface';
import {BillingService} from 'src/app/core/services/billing.service';
import {BillingState} from 'src/app/core/store/state/billing.state';
import {CoreState} from 'src/app/core/store/state/core.state';
import {StartNvidiaPurchase} from 'src/app/store/actions/cart.action';
import {ServicesState} from 'src/app/store/state/services.state';

@Component({
    selector: 'rain-nvidia-upsell-modal',
    templateUrl: './nvidia-upsell-modal.component.html',
    styleUrls: ['./nvidia-upsell-modal.component.scss']
})
export class NvidiaUpsellModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private billingService: BillingService,
        private nv: NvidiaService,
        private ngModal: NgbModal,
        private authSVC: AuthenticationService,
        private cartService: CartService,
        private router: Router,
        private cookieService: CookieService
    ) {
    }

    isMigrating = sessionStorage.getItem('is_migrating');
    migration;
    @Select(ServicesState.getAllServices) services$: Observable<any>;
    @Select(CoreState.getUserProfile) userProfile$: Observable<any>;
    @Select(LimitSalesTicketCreationState.isTicketCreationAllowed) isTicketCreationAllowed$: Observable<boolean>;

    hasRainOne = this.store.selectSnapshot(ServicesState.getAllRainOneServices).length > 1;
    @Input() tier;
    @Input() nrTier;
    userName = this.store.selectSnapshot(CoreState.getUserProfile)?.firstName;
    billingInfo = this.store.selectSnapshot(BillingState.billCycleOptions);
    nonRainUser;
    private rainOneL1Product: RainOneProduct;
    private ngDestroy: Subject<any> = new Subject();
    public isDisabled: Boolean = false;

    ngOnInit(): void {
        this.migration = this.isMigrating === 'true' ? true : false;

        this.services$.subscribe(res => {
            this.nonRainUser = res.length > 0 ? false : true;
        });

        if (this.authSVC.isSignedIn) {
            this.store
                .select(BillingState.getUserBillingAccountStatus)
                .pipe(takeUntil(this.ngDestroy))
                .subscribe({
                    next: account => {
                        if (account?.accountState === 5) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    },
                    error: _ => (this.isDisabled = false)
                });
        } else {
            this.isDisabled = false;
        }
    }

    RequestSalesCall() {
        this.activeModal.close();
        this.ngModal.open(NvidiaSalesModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'slideInUp d-flex'
        });
    }

    nvidiaOnlySale() {
        this.activeModal.close();
        this.store.dispatch(new StartNvidiaPurchase(this.nrTier.id));
    }

    BuyNow() {
        this.activeModal.close();
        this.router.navigateByUrl('/purchase/purchase-wizard');
    }

    navigateToTerms() {
        this.activeModal.close();
        this.router.navigateByUrl('/legal');
    }
}
