import { NgModule } from "@angular/core";
import { AssignSimComponent } from "./assign-sim-page.component";
import { CommonModule } from "@angular/common";
import { AssignSimRoutingModule } from "./assign-sim-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [AssignSimComponent],
    imports: [
        CommonModule,
        AssignSimRoutingModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [],
    providers:[],
})

export class AssignSimModule{
    
}