<div class="mb-10 modal-header border-0 ">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="close()" src="../../assets/svg/close.svg" />
  </div>
</div>

<div class="modal-body border-0 p-5 text-center">
  <span class="mb-5"><i class="fas icon fa-check-circle success"></i></span>

  <div >
    <h2 class="text-primary font-weight-bold my-5">you're already RICA'd</h2>

    <p>Good news, {{ name | titlecase }}!</p>

    <p class="mb-0">We've detected that your SIM has already been RICA'd. <br /></p>
  </div>
</div>

<div class="modal-footer text-center border-0 mt-5 mb-20">
  <button class="btn btn-sm btn-primary mx-auto" (click)="continue()">continue</button>
</div>
