import { Injectable } from '@angular/core';
import { WebConfig } from 'src/app/core/services/websiteconfig.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigStoreService {
  config: WebConfig;
  constructor() { }
}
