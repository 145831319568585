<div *ngIf="(OpenSheet$ | async) as openSheet">
	<rain-cancel-five-g-sheet *ngIf="openSheet === '5G_cancellation'"></rain-cancel-five-g-sheet>
	<rain-upgrade-five-g-bottom-sheet *ngIf="openSheet === '5G_upgrade'"></rain-upgrade-five-g-bottom-sheet>
	<rain-cancel-four-g-sheet *ngIf="openSheet === '4G_cancellation'"></rain-cancel-four-g-sheet>
	<rain-cancellation-success-sheet *ngIf="openSheet === 'cancellation_success'"></rain-cancellation-success-sheet>
	<rain-cancellation-success-four-g-sheet *ngIf="openSheet === 'cancellation_4G_success'"></rain-cancellation-success-four-g-sheet>
	<rain-revert-cancellation-success-sheet *ngIf="openSheet === 'reverse_cancellation_success'"></rain-revert-cancellation-success-sheet>
	<rain-number-porting-bottom-sheet *ngIf="openSheet === 'number_porting_success'"></rain-number-porting-bottom-sheet> 
	<rain-number-porting-failure-bottom-sheet *ngIf="openSheet === 'number_porting_failure'"></rain-number-porting-failure-bottom-sheet>
    <rain-sim-swap-confirmation-sheet *ngIf="openSheet === 'sim_swap'"></rain-sim-swap-confirmation-sheet>
	<rain-port-pending-bottom-sheet *ngIf="openSheet === 'number_porting_pending'"></rain-port-pending-bottom-sheet> 
	<rain-port-success-bottom-sheet *ngIf="openSheet === 'number_porting_complete'"></rain-port-success-bottom-sheet> 
	<rain-port-cancellation-bottom-sheet *ngIf="openSheet === 'number_porting_cancel'"></rain-port-cancellation-bottom-sheet> 
	<rain-port-failed-bottom-sheet *ngIf="openSheet === 'number_porting_failed'"></rain-port-failed-bottom-sheet> 
	<rain-sales-bottom-sheet *ngIf="openSheet === 'request_a_call'"></rain-sales-bottom-sheet> 
	<rain-port-cancellation-success-bottom-sheet *ngIf="openSheet === 'port_cancellation_success'"></rain-port-cancellation-success-bottom-sheet> 
	<rain-port-cancellation-failure-bottom-sheet *ngIf="openSheet === 'port_cancellation_failure'"></rain-port-cancellation-failure-bottom-sheet> 
	<rain-cancel-nvidia-sheet *ngIf="openSheet === 'nvidia_cancellation'"></rain-cancel-nvidia-sheet>
	<rain-cancel-nvidia-confirmation-sheet *ngIf="openSheet === 'nvidia_cancellation_confirmation'"></rain-cancel-nvidia-confirmation-sheet>
	<rain-postpaid-error-bottom-sheet *ngIf="openSheet === 'postpaid_error'"></rain-postpaid-error-bottom-sheet>
	<rain-ai-chat-bot-container-bottom-sheet *ngIf="openSheet === 'ask_rain_bot'" [sheetName]="openSheet"></rain-ai-chat-bot-container-bottom-sheet>
	<rain-nvidia-error-bottom-sheet *ngIf="openSheet === 'nvidia_error'"></rain-nvidia-error-bottom-sheet>
	<rain-revoke-nvidia-cancellation-bottom-sheet *ngIf="openSheet === 'revoke_nvidia_cancellation'"></rain-revoke-nvidia-cancellation-bottom-sheet>
	<rain-pay-arrears-sheet *ngIf="openSheet === 'pay_arrears_sheet'"></rain-pay-arrears-sheet>
</div>