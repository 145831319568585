<div class="mb-10 modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
      src="../../assets/svg/close.svg" />
  </div>
</div>

<div class=" p-5 modal-body border-0 " *ngIf="(activeBreakpoint$ | async) as ab">
  <div class="row">
    <div class="col-12">
      <p class="text-primary text-center h2 font-weight-bold modal-heading">we're sorry to see you go</p>
      <p class="pl-0 pl-sm-15 pr-0 pr-sm-15 text-center">
        The router you received with your 5G product is only free-to-use while the plan is active. Since you're
        cancelling, we'll need to get it back. <span *ngIf="ab !== 'mobile'">Please could you give us an address from where we
          can collect the router.</span>
      </p>

      <p *ngIf="ab === 'mobile'" class="text-center">Please could you give us an address from where we can collect the router.
      </p>

      <div class="form-group address-search pl-0 pl-sm-40 pr-0 pr-sm-40" [formGroup]="addressForm">
        <div class=" mt-5">
          <sf-google-placesform-input placeholder="search address" [control]="addressForm.get('address')"
            (onAddressChange)="handleAddressChange($event)">
          </sf-google-placesform-input>
        </div>
        <span *ngIf="formSubmit && (addressForm.controls.streetNumber.errors || 
        addressForm.controls.streetNumber.errors || addressForm.controls.streetName.errors || addressForm.controls.suburb.errors || 
        addressForm.controls.city.errors || addressForm.controls.postalCode.errors)">
          <span class="text-danger small-font-size">valid address is required.</span>
        </span>

        <div *ngIf="addressSearched">
          <!-- streetNumber -->
          <div class="form-group mt-15  mb-10">
            <sf-form-input label="street number" [control]="addressForm.get('streetNumber')" [type]="'text'"
              [messages]="streetNumberValidationMessages">
            </sf-form-input>
          </div>

          <!-- streetName -->
          <div class="form-group mt-13  mb-10">
            <sf-form-input label="street name" [control]="addressForm.get('streetName')" [type]="'text'"
              [messages]="streetNameValidationMessages">
            </sf-form-input>
          </div>

          <!-- suburb -->
          <div class="form-group mt-13  mb-10">
            <sf-form-input label="suburb" [control]="addressForm.get('suburb')" [type]="'text'"
              [messages]="suburbValidationMessages">
            </sf-form-input>
          </div>

          <!-- city -->
          <div class="form-group mt-13  mb-10">
            <sf-form-input label="city" [control]="addressForm.get('city')" [type]="'text'"
              [messages]="cityValidationMessages">
            </sf-form-input>
          </div>

          <!-- postalCode -->
          <div class="form-group mt-13  mb-10">
            <sf-form-input label="postal code" [control]="addressForm.get('postalCode')" [type]="'text'"
              [messages]="postalCodeValidationMessages">
            </sf-form-input>
          </div>

        </div>

        <!-- complex name -->
        <div class="form-group mt-13  mb-10">
          <sf-form-input  label="" placeholder="complex name or business name (optional)" [control]="addressForm.get('complex')" [type]="'text'"></sf-form-input>
        </div>

        <!-- flat,floor or apt -->
        <div class="form-group mt-13  mb-10">
          <sf-form-input  label="" placeholder="apt, flat or floor number (optional)" [control]="addressForm.get('flat')" [type]="'text'"></sf-form-input>
        </div>

        <!-- special notes -->
        <div class="form-group mt-13  mb-10">
          <sf-form-input label="" placeholder="special collection notes (optional)"  [control]="addressForm.get('notes')" [type]="'text'"></sf-form-input>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-10 mb-10 mt-sm-15">

        <button [className]="
                    (!addressForm.invalid ? 'btn-primary ' : 'btn-tertiary ') +
                    'btn btn-md  mb-xl-0 mb-8 font-weight-medium'
                  " class="btn btn-sm btn-primary  mb-xl-0 mb-8 font-weight-medium text-white" id="submit-address-btn"
          (click)="submit()">
          submit
        </button>
      </div>
    </div>


  </div>

</div>