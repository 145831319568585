import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors,Validators } from '@angular/forms';
import { startCase } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from '@services/cookie.service';
import { SupportService } from '@services/support.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { DataLayerService } from '@services/data-layer.service';
import { ConfigService } from '@services/config.service';
import { CacheService } from '@services/cache.service';
import { Store } from '@ngxs/store';
import { AddTicketCreationTimer } from '@components/sales-modal/store/actions/limit-ticket-creations.actions';
import { CACHE_SUPPORT_SALE_UPGRADE_ADDED } from '@models/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rain-sales-upgrade-modal',
  templateUrl: './sales-upgrade-modal.component.html',
  styleUrls: ['./sales-upgrade-modal.component.scss']
})
export class SalesUpgradeModalComponent extends BaseComponent implements OnInit {
  upgradeContactForm: FormGroup;
  loading: boolean;
  siteKey: string;
  private configSubscription: Subscription;
  whatsAppOptInCheck: boolean = true;
  whatsAppOptInMessage: string = 'Update WhatsApp status to OPT IN';
  @Input() subject: string = '';
  @Input() name: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() fromPage: string;
  @ViewChild('inputField') inputField!: ElementRef;

  nameValidationMessages: ValidationErrors = {
    required: 'Name is required.',
    pattern: 'Name must contain at least 2 characters.'
  };
  emailValidationMessages: ValidationErrors = {
    required: 'Email is required.',
    pattern: 'Please enter a valid email address.'
  };
  cellNumberValidationMessages: ValidationErrors = {
    required: 'Contact number is required.',
    pattern: 'Please enter a valid contact number.'
  };

  constructor(
    private fb: FormBuilder,
    private configService: ConfigService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private supportService: SupportService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private dataLayerService: DataLayerService,
    private cacheService: CacheService,
    private store: Store
  ) {
    super();  
  }

  ngOnInit(): void {
    this.configSubscription = this.configService.onChange.subscribe(() => {
      this.siteKey = this.configService.SITE_KEY;
    });
    // Initially, check if SITE_KEY is already available
    this.siteKey = this.configService.SITE_KEY;
    this.setForm();
    this.setCustomerDetails();
    this.dataLayerService.requestUpgradeCallModalPopped(this.subject);
  }

  submitTicket() {
    const body: any = {
      message: `<h2> subject: ${this.subject}</h2>
      <p style= "font-size: 14px;">(name): ${this.upgradeContactForm.value.name}</p>
      <p style= "font-size: 14px;">(tel): ${this.upgradeContactForm.value.number}</p>
      <p style= "font-size: 14px;">(email): ${this.upgradeContactForm.value.email}</p>
      <p style= "font-size: 14px;">(WhatsApp): ${this.whatsAppOptInMessage}</p>
      <p style= "font-size: 14px;">(platform): WEB</p>
      <p style= "font-size: 14px;">(ga_tag): ${this.cookieService.getGATag()}</p>`
    };

    const ticketRequest = {
      name: this.upgradeContactForm.value.name,
      address: null,
      email: this.upgradeContactForm.value.email,
      subject: this.subject.includes('#')
          ? `${this.subject || 'rain sales support'} ${this.upgradeContactForm.value.number}`
          : `${this.subject || 'rain sales support'} #${this.upgradeContactForm.value.number}`,
      referenceId: this.upgradeContactForm.value.number,
      body,
      captcha: null,
      ga_tag: this.cookieService.getGATag()
    };

    this.supportService
        .addTicket(ticketRequest)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result: any) => {
          this.loading = false
          if (result) {
            if (!result?.ticket) {
              setTimeout(() => this.toastr.error('An error occurred while submitting. Please try again', null));
              return;
            } else {
              this.loading = false;
              this.activeModal.close();
              this.openThankYou();
              this.dataLayerService.requestUpgradeCall(result?.ticket?.ticket?.id, this.subject);
              localStorage.setItem('CACHE_SUPPORT_SALE_UPGRADE_ADDED', JSON.stringify(true));
              this.cacheService.set(CACHE_SUPPORT_SALE_UPGRADE_ADDED, 'set', 86400000);
            }
          }
        });
  };

  setForm() {
    this.upgradeContactForm = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])],
      number: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$|^[0-9]{12}$')])],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')])]
    });
  }

  setCustomerDetails() {
    this.upgradeContactForm.controls.name.setValue(this.name + ' ' + this.lastName);
    this.upgradeContactForm.controls.email.setValue(this.email);
  }

  submit() {
    const isUpgradeTicketLogged = localStorage.getItem('CACHE_SUPPORT_SALE_UPGRADE_ADDED') ?? false;

    if (this.cacheService.exists(CACHE_SUPPORT_SALE_UPGRADE_ADDED) || isUpgradeTicketLogged) {
      this.showAlreadyLoggedTicketModal();
      return;
    } else {
      this.store.dispatch(new AddTicketCreationTimer(12));
      this.submitTicket();
    }
  }

  openThankYou() {
    const data = {
      title: 'thank you, <span class="bold">' + startCase(this.upgradeContactForm.value.name) + '</span>!',
      description: 'We have received your ticket. An agent will contact you as soon as they are available.',
      buttonName: 'got it'
    };
    const options = { size: 'md' as any, windowClass: 'slideInUp', centered: true };
    const thankYouModalRef = this.modalService.open(ConfirmationModalComponent, options);
    thankYouModalRef.componentInstance.data = data;
  }

  showAlreadyLoggedTicketModal() {
    this.activeModal.close();
    const options = { size: 'confirm' as any, windowClass: 'slideInUp', centered: true };
    const modalRef = this.modalService.open(ConfirmationModalComponent, options);
    modalRef.componentInstance.data = {
      title: 'heads up',
      description: `You have already logged a ticket`,
      buttonName: 'ok'
    };
  }

  whatsAppOptIn(): void {
    this.whatsAppOptInCheck = !this.whatsAppOptInCheck;
    if (!this.whatsAppOptInCheck) {
      this.whatsAppOptInMessage = 'No change to WhatsApp status';
    } else {
      this.whatsAppOptInMessage = 'Update WhatsApp status to OPT IN';
    }
  }
}
