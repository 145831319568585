


export interface IRemoteAccountStatus {
    accountState: string;
    amount: number;
    amountArrears: number;
    minimumPayment: number;
    amountCurrent: number;
    billingState: string;
    customerNodeId: string;
    invoiceId: string;
    tenure: number;
    payPromiseDate: string;
    arrears?: any[];
    flag?: string;
    currentPayDate?: number;
}

export enum AccountStates {
    None = 0,
    Normal,
    FuturePayRun,
    Grace,
    OverdueHighspeed,
    OverDue,
    PromiseToPay,
    PaymentRun,
    PickedUp,
    Processing,
    BlackList,
    Suspended,
    OverDuePromiseToPay
}

export enum BillingStates {
    None = 0,
    Suspended
}

export interface IAccountStatusDetail {
    accountState: AccountStates;
    billingState: BillingStates;
    minimumPayment: number;
    amount: number;
    amountArrears: number;
    amountCurrent: number;
    invoiceId: string;
    tenure: number;
    payPromiseDate: string;
    arrears?: any[];
    flag?: string;
    currentPayDate?: number;
    userId?: string
}

export class AccountStatusDetail {

    static adapt(remote: IRemoteAccountStatus): IAccountStatusDetail {
        const accountStatus: IAccountStatusDetail = {
            accountState: this.mapAccountState(remote.accountState),
            billingState: this.mapBillingState(remote.billingState),
            amount: remote.amount,
            minimumPayment: remote.minimumPayment,
            amountArrears: remote.amountArrears,
            amountCurrent: remote.amountCurrent,
            invoiceId: remote?.invoiceId,
            tenure: remote.tenure,
            payPromiseDate: remote?.payPromiseDate,
            arrears: remote?.arrears,
            flag: remote?.flag,
            currentPayDate: remote?.currentPayDate,
            userId: remote?.customerNodeId ?? null
        };

        return accountStatus;
    }

    static mapBillingState(accountState: string): BillingStates {
        switch (accountState) {
            case "SUSPENDED": return BillingStates.Suspended
            default: return BillingStates.None
        }
    }

    static mapAccountState(accountState: string): AccountStates {
        switch (accountState) {
            case "NORMAL": return AccountStates.Normal
            case "PAYMENT_RUN": return AccountStates.PaymentRun
            case "PTP": return AccountStates.PromiseToPay
            case "FUTUREPAYRUN": return AccountStates.FuturePayRun
            case "GRACE": return AccountStates.Grace
            case "OVERDUEHIGHSPEED": return AccountStates.OverdueHighspeed
            case "OVERDUE": return AccountStates.OverDue
            case "PICKED_UP": return AccountStates.PickedUp
            case "PROCESSING": return AccountStates.Processing
            case "BLACKLIST": return AccountStates.BlackList
            case "SUSPENDED": return AccountStates.Suspended
            case "OVERDUEPTP": return AccountStates.OverDuePromiseToPay

            default: return AccountStates.None
        }
    }
}