import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearCountryRates, GetCountryRates, GetMoreCountryRates } from '../actions/intl-calling.actions';
import { IntlCallingService } from '@services/intl-calling.service';
import { tap } from 'rxjs/operators';

export interface IntlCallingResDetail {
  country: string;
  extra: any;
  id: string;
  numberPrefix: string;
  provider: string;
  ratePerMinute: number;
}

export interface IntlCallingStateModel {
  searchableCountry?: string;
  currentPage: number;
  results: IntlCallingResDetail[];
  totalPages: number;
  totalResults: number;
  canPage?: boolean;
}

@State<IntlCallingStateModel>({
  name: 'IntlCalling',
  defaults: {
    searchableCountry: null,
    currentPage: null,
    results: [],
    totalPages: null,
    totalResults: 1,
    canPage: false
  }
})
@Injectable({ providedIn: 'root' })
export class IntlCallingState {
  @Selector()
  static getCountryRatesResult(state: IntlCallingStateModel) {
    return state.results;
  }

  @Selector()
  static getCanPage(state: IntlCallingStateModel) {
    const currentPage = state.currentPage;
    const totalPages = state.totalPages;
    const canPage = state.canPage;
    const res = {
      canPage,
      totalPages,
      currentPage
    };
    return res;
  }

  @Selector()
  static getTotalRes(state: IntlCallingStateModel) {
    return state.totalResults
  }

  constructor(private intlCallingSVC: IntlCallingService) {}

  @Action(GetCountryRates)
  getCountryRates(ctx: StateContext<IntlCallingStateModel>, action: GetCountryRates) {
    return this.intlCallingSVC.getCountry(action.searchableCountry).pipe(
      tap(res => {
        ctx.patchState({
          searchableCountry: action.searchableCountry,
          currentPage: res.currentPage,
          results: res.results,
          totalPages: res.totalPages,
          totalResults: res.totalResults,
          canPage: res.currentPage < res.totalPages ? true : false
        });
      })
    );
  }

  @Action(GetMoreCountryRates)
  getMoreCountryRates(ctx: StateContext<IntlCallingStateModel>, action: GetMoreCountryRates) {
    if (ctx.getState().canPage) {
      const newPage = ctx.getState().currentPage + 1;
      const totalPages = ctx.getState().totalPages;
      const country = ctx.getState().searchableCountry;
      let providerResults = ctx.getState().results;
      if (newPage <= totalPages)
        return this.intlCallingSVC.getCountry(country, newPage).pipe(
          tap(res => {
            let newProviderResults = providerResults.concat(res.results);
            ctx.patchState({
              searchableCountry: country,
              currentPage: res.currentPage,
              results: newProviderResults,
              totalPages: res.totalPages,
              totalResults: res.totalResults,
              canPage: res.currentPage < res.totalPages ? true : false
            });
          })
        );
    }
  }

  @Action(ClearCountryRates)
  clearCountryRates(ctx: StateContext<IntlCallingStateModel>){
    ctx.patchState({
        searchableCountry: null,
        currentPage: null,
        results: [],
        totalPages: null,
        totalResults: 1,
        canPage: false
    })
  }
}
