export  function sortAscendingBy<T,K extends keyof T>(arrayOfObjects: T[],key: K){
     return arrayOfObjects?.sort((a,b)=>{
        if( a[key] < b[key]){
            return  - 1
        }
            
        if( a[key] > b[key])
        {
            return  1  
        }
        return 0;
     })
}

export  function sortDescendingBy<T,K extends keyof T>(arrayOfObjects: T[],key: K){
    return arrayOfObjects?.sort((a,b)=>{
       if( a[key] > b[key]){
           return  - 1
       }
           
       if( a[key] < b[key])
       {
           return  1  
       }
       return 0;
    })
}