import { Injectable } from '@angular/core';
import { Device } from '../../interfaces/device.interface';
import { DeviceOS } from '../../types/device.types';


@Injectable({
    providedIn: 'root'
})
export class DeviceChecker {
    constructor() { }

    check(): Device {
        const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
        const os = this.checkOS(userAgent);
        const device = this.checkDevice(userAgent);
        
        return {
            os: os,
            type: device
        }
    }


    private checkOS(ua: string): DeviceOS {
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(ua)) {
            return "Windows Phone";
        }

        if (/android/i.test(ua)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(ua) && !window['MSStream']) {
            return "IOS";
        }

        if (/Mac OS/.test(ua) && !window['MSStream']) {
            return "MACOS";
        }

        if (/Windows/.test(ua) && !window['MSStream'] && !window['windows phone']) {
            return "Windows";
        }

        return "unknown";
    }

    private checkDevice(ua: string): 'mobile' | 'desktop' {
        const regexp = /android|iphone|kindle|ipad/i;
        const isMobileDevice = regexp.test(ua);

        if (isMobileDevice) {
            return 'mobile';
        } else {
            return 'desktop';
        }
    }

}
