<div class="modal-body">
  <div class="text-center p-10">
    <p class="text-primary extra-large-font-size pb-5">remove?</p>
    <p>
      Are you sure you want to remove this item from your cart?
    </p>
  </div>
</div>
<div class="modal-footer border-top-0 d-flex justify-content-center">
  <button class="btn no-btn btn-sm btn-primary" data-dismiss="modal" (click)="activeModal.dismiss('Cross click')">
    no
  </button>
  <button class="btn btn-sm btn-primary" (click)="confirmed()">
    yes
  </button>
</div>