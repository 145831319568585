import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreState } from 'src/app/core/store/state/core.state';
import { AssociateSim, DismissModals } from 'src/app/store/actions/assign-claim-sim.actions';
import { AssignAndClaimSimState } from 'src/app/store/state/assign-claim-sim.state';
import { ServicesState } from 'src/app/store/state/services.state';

@Component({
  selector: 'assign-sim',
  templateUrl: './assign-sim-page.component.html',
  styleUrls: ['./assign-sim-page.component.scss']
})
export class AssignSimComponent implements OnInit, OnDestroy {
  @Select(ServicesState.getAllServices) services$: Observable<any>;
  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;
  selectedService$: Observable<any>;
  form: FormGroup;
  sub: Subscription
  param;
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  @Select(AssignAndClaimSimState.showAssignSuccessModal) showSuccess$ : Observable<boolean>
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private store: Store, private modalService: NgbModal, private router : Router) {}

  ngOnInit() {
    this.sub = this.showSuccess$.subscribe((showModal) => {
      if(showModal) {
        this.openModal()
      }
    })
    this.param = this.route.snapshot.queryParams['id'];
    this.selectedService$ = this.services$.pipe(
      map(services =>
        services.filter(service => {
          return service.id === this.param ? service : null;
        })
      )
    );

    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['']
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.store.dispatch(
        new AssociateSim({
          serviceId: this.param,
          name: this.form.value.name,
          email: this.form.value.email,
          message: this.form.value.message
        })
      );
    } else {
      console.log('Form is not valid');
    }
  }
  
  openModal(){
    const modalRef = this.modalService.open(this.modalContent, {
      size: 'sm',
      windowClass: 'slideInUp',
      centered: true
    })

  }

  dismiss(){
    this.modalService.dismissAll()
    this.store.dispatch(new DismissModals)
    this.router.navigateByUrl(`service/${this.param}`)
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
