<div class="rain-number-failure-modal-container">
    <div class="close-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <div class="rain-number-modal-content">
        <h2>oops!</h2>
    </div>
    <div class="rain-number-modal-content">
        <p>
            Looks like the number you are trying to port is already registered with rain. Try a different one.
        </p>
    </div>
    <div class="rain-number-modal-actions">
        <button (click)="close()">retry</button>
    </div>
</div>