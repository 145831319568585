import { CACHE_AUTH_TOKEN } from './../models/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, timeout, catchError } from 'rxjs/operators';
import { Result } from '@models/result';
import { ConfigService } from './config.service';
import { ServiceHelper } from './serviceHelper';
import { SurveyQuestionDetail, ISurveyAddRequest } from '@models/surveyQuestionDetail';
import { RemoteSurveyRequest } from '@models/remote/remoteSurveyRequest';
import { Observable } from 'rxjs';
import { TokenService } from '@services/token.service';
@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private tokenService: TokenService
  ) { }


  getSurvey(surveyName: string) {
    const requestPath = `${this.configService.SURVEY_URL}/survey-service/survey/name/${surveyName}`

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((response: any) => {
        return Result.success(response);
      })
    );

  }

  public postSurvey(request: ISurveyAddRequest): Observable<Result<boolean>> {

    const requestPath = `${this.configService.SURVEY_URL}/survey-service/survey_response`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    const surveyApiRequest = this.mapRequest(request);
    return this.http.post(requestPath, surveyApiRequest, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map(() => {
        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  mapRequest(request: ISurveyAddRequest): RemoteSurveyRequest {

    const apiRequest: RemoteSurveyRequest = {
      full_name: request.fullName,
      email: request.email,
      survey_id: request.surveyId,
      answers: request.answers,
      additional_details: request.additionalDetails,
      contact_number: request.contactNumber
    };

    return apiRequest;
  }
}
