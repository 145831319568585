import { TowerService } from '@services/tower.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isNil, isNull } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { Statuses } from '@models/result';
import { AnswersDetail, ISurveyAddRequest, SurveyQuestionDetail } from '@models/surveyQuestionDetail';
import { IUserDetail } from '@models/userDetail';
import { SurveyService } from '@services/survey.service';
import { UserService } from '@services/user.service';
import { ThankSurveyComponent } from './thank-survey/thank-survey.component';
import { DataLayerService } from '@services/data-layer.service';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { SubjectService } from '@services/subject.service';
import { CacheService } from '@services/cache.service';
import { SupportService } from '@services/support.service';
import { CACHE_SUPPORT_SIM_CANCELLATION } from '@models/constants';
import { CancellationSurveyActionModalComponent } from '../../cancellation-survey-action-modal/cancellation-survey-action-modal.component';
import { ConfirmContactDetailsModalComponent } from '../../confirm-contact-details-modal/confirm-contact-details-modal.component';
import { SimSwopModalComponent } from '@components/support-button/sim-swop-modal/sim-swop-modal.component';
import { Actions, Select, Store, ofActionCompleted } from '@ngxs/store';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ServicesState } from 'src/app/store/state/services.state';
import { ActivatedRoute } from '@angular/router';
import { MigrateToRainOneService } from '@services/migrate-to-rain-one.service';
import { GetAllServices } from 'src/app/store/actions/services.actions';
import { SuccessErrorModalComponent } from '../../success-error-modal/success-error-modal.component';
import { IServiceDetail } from '@models/serviceDetail';

interface AnswerViewModal extends AnswersDetail {
  selected: boolean;
}

interface SurveyQuestionViewModal extends SurveyQuestionDetail {
  answers: AnswerViewModal[];
}

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss']
})
export class SurveyModalComponent extends BaseComponent implements OnInit, OnDestroy {

  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input()
  serviceId: string;
  surveyQuestions: SurveyQuestionViewModal[] = [];
  selectedBlock: string;
  userProfile: IUserDetail;
  form: FormGroup;
  @Input()
  simName: string;
  formSubmited: boolean;
  loading: boolean;
  loadingRequest = false;
  @Input()
  msisdn: string;
  imsi: string;
  address = null;
  @Input()
  type: string;
  @Input()
  productId: string;
  @Input()
  twoForOneProduct: boolean;
  @Input()
  primaryUserId: string;
  @Input()
  primaryServiceId: string;
  message: string;
  surveyId: string;
  answerId: string;
  dropDownIsOpen = false;
  isMobileScreen = false;
  selectedQuestion: string;
  simSwapSubscription: Subscription;
  isMobile = navigator.userAgent.match(/(iPhone|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
  reason: string;


  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private subjectService: SubjectService,
    private supportService: SupportService,
    private cacheService: CacheService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public towerDetailService: TowerService,
    private dataLayerService: DataLayerService,
    private store: Store,
    private migrateToRainOneService: MigrateToRainOneService,
    private actions$ : Actions
  ) {
    super();
    this.simSwapSubscription = this.subjectService.subscribeCancel5GResponse().subscribe(res => {
      if (res) {
        this.activeModal.dismiss();
        this.otherModal();

        this.cacheService.set(CACHE_SUPPORT_SIM_CANCELLATION, this.serviceId, 86400000);
        this.supportService.setLocaStorage();
      }
    });
  }

  ngOnInit() {
    this.isMobileScreen = window.innerWidth <= 500;
    this.populate();
  }

  buildForm() {
    this.form = new FormGroup({
      question: new FormControl('', Validators.required),
      answer: new FormControl('', Validators.required),
      info: new FormControl('', Validators.required)
    });
  }

  populate() {

    this.buildForm();

    this.loading = true;

    this.form.reset();

    this.formSubmited = false;

    this.buildForm();

    this.surveyQuestions = [];


    let userDetails = this.userService.get();

    let surveyName = '';
    if (!this.type) {
      surveyName = 'SIM_swap';
    } else {
      surveyName = this.type === '4G' ? 'Cancellation_4G' : 'Cancellation_5G';
    }

    let survey = this.surveyService.getSurvey(surveyName);

    let state = this.towerDetailService.getSubscriberDetail(this.msisdn)

    forkJoin([userDetails, survey, state])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(results => {

        const [userDetailResult, surveyResult, stateResult] = results;

        if (userDetailResult.status === Statuses.Success && surveyResult.status === Statuses.Success && stateResult.status === Statuses.Success) {
          this.imsi = stateResult.value.imsi || 'not available';
          const survey = surveyResult.value;
          this.surveyId = survey.result.id;

          survey?.result?.questions.forEach(question => {
            let answers: any[] = [];

            question.answers.forEach(answer => {
              answer.title.includes("Other")? answer.sequence = 2 : answer.sequence
              answers.push({ group: answer.group, 
                sequence: answer.sequence, 
                questionId: answer.questionId, 
                id: answer.id, title: answer.title, 
                selected: false });
            });
            answers?.sort((a, b) => (a.sequence < b.sequence ? -1 : 1))
            let group = answers.reduce((r, a) => {
              r[a.group] = [...r[a.group] || [], a];
              return r;
            }, {});

            Object.keys(group).forEach(key => {
              this.surveyQuestions.push({ id: group[key][0].questionId, sequence: group[key][0].sequence, title: key, answers: group[key] });
            });

          });

          this.surveyQuestions?.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));

          this.userProfile = userDetailResult.value;

          if (!this.type && this.surveyQuestions.length === 1) {
            this.display(this.surveyQuestions[0]);
          }
        }

        this.loading = false;

      });
  }
  proceed(address) {
   this.address = address;
  }

  buildsurveyForm(question) {
    this.form.removeControl('info');

    this.form.removeControl('answer');

    if (!isNil(question)) {
      if (question.answers.length > 0) {
        this.form.addControl('info', new FormControl(''));

        this.form.addControl('answer', new FormControl('', Validators.required));
      } else {
        this.form.addControl('info', new FormControl('', Validators.required));
      }
    }
  }

  public toggleDropDown() {
    return this.dropDownIsOpen = !this.dropDownIsOpen;
  }

  submit() {
    this.activeModal.dismiss();
    if (this.selectedQuestion === 'Switching to a different rain product' || this.selectedQuestion === 'I lost my SIM') {
      const options: NgbModalOptions = { size: <any>'survey', windowClass: 'slideInUp', centered: true };
      const modalRef = this.modalService.open(CancellationSurveyActionModalComponent, options);
      modalRef.componentInstance.type = this.type;
      modalRef.componentInstance.selectedQuestion = this.selectedQuestion;
      modalRef.componentInstance.msisdn = this.msisdn;
      modalRef.componentInstance.serviceId = this.serviceId;
      modalRef.componentInstance.productId = this.productId;
      modalRef.componentInstance.address = this.address;
      modalRef.componentInstance.confirmed.subscribe((result: boolean) => {
        if (result) {
          this.cancelSIM();
        }
      });

    } else if (this.type === '5G' && this.selectedQuestion === 'Other') {
      this.cancelSIM();
    } else {
      this.cancelSIM();
    }
  }

  otherModal() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'md' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title: 'thanks for your feedback',
      description: 'We’ve received your ticket. An agent will get in touch soon to process your cancellation. Please make sure your correct contact details are saved on our system.',
      buttonName: 'confirm contact details'
    };

    modalRef.componentInstance.accepted.subscribe((result) => {
      if (result) {
        this.modalService.open(ConfirmContactDetailsModalComponent, {
          size: 'md' as any,
          centered: true
        });
      }
    });
  }

  cancelSIM() {
    this.loadingRequest = true;
    this.formSubmited = true;
    const questionId = this.form.controls.question.value.id;

    if (this.form.invalid) {
      return;
    }

    const answerId = this.answerId ? this.answerId : this.form.controls.answer.value;

    const info = isNil(this.form.controls.info) ? '' : this.form.controls.info.value;

    let request: ISurveyAddRequest;

    if (isNull(this.userProfile) == false) {
      const fullName = this.userProfile.firstName + ' ' + this.userProfile.lastName;

      const email = this.userProfile.email;

      const contactNumber = this.userProfile.phone;
      request = {
        fullName: fullName,
        email: email,
        contactNumber: contactNumber,
        surveyId: this.surveyId,
        answers: [
          {
            comment: info,
            question_id: questionId,
            answer_id: answerId
          }
        ],
        additionalDetails: {
          serviceReference: this.serviceId,
          msisdn: this.msisdn,
          imsi: this.imsi
        }
      };

      if (this.address) {
        request.additionalDetails['collectionAddress'] = this.address.formattedAddress;
        request.additionalDetails['lat'] = this.address.lat;
        request.additionalDetails['lon'] = this.address.lon;
      }
    }

    this.surveyService
      .postSurvey(request)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result.status === Statuses.Success) {
          // Give time for BE to be updated before retreiving new data 
          // setTimeout(() => {
          // this.userService.onServiceUpdated.next();

          // }, 2000);
          this.loadingRequest = false;

          this.selectedBlock = '';

          this.activeModal.close();

          if (!this.type) {
            this.submitted.next(true);
          } else {
            this.submitSIMCancellation();
          }

        } else {
          this.loadingRequest = false;
          this.activeModal.close();
          this.errorModal('An error occurred while saving the feedback');

          return;
        }
      }, error => {
        this.loadingRequest = false;
      });
  }

  submitSIMCancellation() {

    let configs = [];

    configs.push({
      name: 'action',
      value: 'cancel'
    });

    if (this.type === '5G' && this.surveyQuestions?.length > 0) {
      this.reason = '';
      const answerId = this.answerId ? this.answerId : this.form.controls.answer.value;

      for (let index = 0; index < this.surveyQuestions.length; index++) {
        for (let innerIndex = 0; innerIndex < this.surveyQuestions[index].answers.length; innerIndex++) {
          if (this.surveyQuestions[index].answers[innerIndex].id === answerId) {
            this.reason = this.surveyQuestions[index].answers[innerIndex].title;
          }
        }
      }

      configs.push({
        name: 'type',
        value: 'scheduled'
      });

      configs.push({
        name: 'collection_address',
        value: this.address.formattedAddress
      });

      configs.push({
        name: 'comments',
        value: `Details:In 5G coverage (estimated): Yes, Main cancellation reason: ${this.selectedQuestion}, Sub cancellation reason: ${this.reason ? this.reason : 'nan'}, Additional cancellation comments: ${this.form.controls.info.value ? this.form.controls.info.value : 'nan'}`
      });

    }

    const body: any = {
      product_configs: {
        configs
      }
    };

    if (this.twoForOneProduct) {
      const data = {
        action: 'cancel',
          type: 'scheduled',
          reason: `Main cancellation reason: ${this.selectedQuestion}, Sub cancellation reason: ${this.reason ? this.reason : 'nan'}`,
          collection_address: this.type === '5G' ? this.address?.formattedAddress : "",
          comments: this.form.controls.info.value ? this.form.controls.info.value : 'nan',
          reference: this.userProfile.email
       }
      this.twoForOneCancellation(data);
    } else if (this.getIsRainOne()) {
       const payload ={serviceId: this.serviceId, reason: `Main cancellation reason: ${this.selectedQuestion}, Sub cancellation reason: ${this.reason ? this.reason : 'nan'}`, collection_address: this.type === '5G' ? this.address?.formattedAddress : "", comments:  this.form.controls.info.value ? this.form.controls.info.value : 'nan',}
       this.migrateToRainOneService.cancelRainOneService(payload).subscribe(() => {
        this.store.dispatch([new GetAllServices()]).toPromise().then(() => {
        setTimeout(() => {
            this.userService.onServiceUpdated.next()
          }, 2000)
        })
        const data = {
          title: `<p>we're sorry to see you go</p>`,
          description: `We've received your cancellation request. <br> Your rain<b>one</b> service and associated mobile phone lines are currently scheduled for cancellation.`,
          buttonName: 'got it'
        }
        const modalRef = this.modalService.open(ConfirmationModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
        modalRef.componentInstance.data = data
      })
    }
    
    else {
      this.normalCancellation(body)
    }

    
  }
  
  getIsRainOne() {
    const service = this.store.selectSnapshot(ServicesState.getAllServices)?.find((service) => service.id === this.serviceId);
    return service?.productName.toLowerCase().includes('4g mobile phone') || service?.productName.toLowerCase().includes('rainone')
  }

  normalCancellation(request) {
    this.loading = false;
    this.userService
    .cancelService(this.serviceId, request)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: (result: any) => {
        if (result.status === Statuses.Success) {
          setTimeout(() => {
            this.store.dispatch([new GetAllServices]).toPromise().then(() => {
              if (this.store.selectSnapshot(ServicesState.getAllServices)?.find((service) => {service.id === this.serviceId})?.scheduledAction === null) {
                this.refetchServices()
              }
            })
          }, 300)
          this.subjectService.serviceCancellationResponse(true);
          this.userService.onServiceUpdated.next()
          this.thankYouModal();
        } else if (result.status === Statuses.Conflict) {
          if (result.message.message.includes('>')) {
            this.message = result.message.message.split('>')[1];
          } else {
            this.message = result.message.message;
          }
  
          if (this.message.includes('Found scheduled action')) {
            this.message = 'You are not allowed to perform this action due to pending SIM cancellation request';
          }
  
          this.errorModal(this.message);
  
        } else {
          this.errorModal('An error occurred while cancelling a SIM');
        }
        
      },
      error: (error) => {
        this.errorModal('An error occurred while cancelling a SIM');
      }
    })
  }

  refetchServices() {
    return setTimeout(() => {
      this.store.dispatch([new GetAllServices])
    }, 5000)
  }

  thankYouModal() {
    const options: NgbModalOptions = { size: <any>'survey', windowClass: 'slideInUp', centered: true };
    const modalRef = this.modalService.open(ThankSurveyComponent, options);
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.show(this.simName);


    modalRef.componentInstance.accepted.subscribe((result) => {
      if (result) {
        if (!this.type) {
          this.subjectService.pingServiceUpdate(this.serviceId);
        }
      }
    });
  }

  twoForOneCancellation(request) {
    this.loading = false;
    this.userService
    .cancel2for1Service(this.primaryUserId, this.serviceId, request)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result: any) => {

      if (result.status === Statuses.Success) {
        this.subjectService.serviceCancellationResponse(true);
        setTimeout(() => {
          this.store.dispatch([new GetAllServices]).toPromise().then(() => {
            if (this.store.selectSnapshot(ServicesState.getAllServices)?.find((service) => {service.id === this.serviceId})?.scheduledAction === null) {
              this.refetchServices()
            }
          })
        }, 300)

        this.thankYouModal();
      } else if (result.status === Statuses.Conflict) {
        if (result.message.message.includes('>')) {
          this.message = result.message.message.split('>')[1];
        } else {
          this.message = result.message.message;
        }

        if (this.message.includes('Found scheduled action')) {
          this.message = 'You are not allowed to perform this action due to pending SIM cancellation request';
        }

        this.errorModal(this.message);

      } else {
        this.errorModal('An error occurred while cancelling a SIM');
      }
    }, error => {
      this.errorModal('An error occurred while cancelling a SIM');
    });
  }

  errorModal(message) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'confirm' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title: 'Heads up!',
      description: `<p class="pl-0 pl-sm-10 pr-0 pr-sm-10">${message}</p>`,
      buttonName: 'got it'
    };
  }

  display(question: any) {
    this.selectedQuestion = question.title;

    const answers = question?.answers;

    let count = 0;
    answers.forEach((answer: any) => {
      if (answer.title === '') {
        count++;
      }
    });

    if (count > 0) {
      this.setValidation(false);
      this.answerId = answers[0].id;
    } else {
      this.setValidation(true);
      this.answerId = null;
    }

    this.form.get('question').setValue(question);

    this.toggleDropDown();
    this.buildsurveyForm(question);

    this.selectedBlock = question.title;

    this.surveyQuestions.forEach(question => {

      if (question.title !== this.selectedBlock) {
        question.answers.forEach(answer => {
          answer.selected = false;
        });
      }
    });
  }

  setValidation(validate) {

    if (validate) {
      setTimeout(() => {
        this.form.controls.answer.setValidators([Validators.required]);
        this.form.controls.answer.markAsPristine();
        this.form.controls.answer.updateValueAndValidity();
      }, 20);
    } else {
      setTimeout(() => {
        this.form.controls.answer.clearValidators();
        this.form.controls.answer.markAsPristine();
        this.form.controls.answer.updateValueAndValidity();
      }, 20);
    }

  }

  selectAnswer(questionId, answerId) {
    this.surveyQuestions.forEach(surveyQuestion => {
      if (surveyQuestion.id == questionId) {
        surveyQuestion.answers.forEach(answer => {
          if (answer.id == answerId) {
            answer.selected = true;
          } else {
            answer.selected = false;
          }
        });
      } else {
        surveyQuestion.answers.forEach(answer => {
          answer.selected = false;
        });
      }
    });

  }

  ngOnDestroy(): void {
    this.simSwapSubscription.unsubscribe();
    this.ngUnsubscribe.next(null)
    this.ngUnsubscribe.complete()
  }

}
