import {Injectable} from "@angular/core";
import {JwtHelperService} from "@auth0/angular-jwt";
import {includes, isNil} from "lodash";
import moment from 'moment';
import {Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../baseComponent';
import {CacheService} from "./cache.service";
import {UIState} from "../shared/store/state/ui.state";
import {AuthState} from "../core/store/state/auth.state";
import {CACHE_AUTH_TOKEN, CACHE_SME_TOKEN} from "@models/constants";
import {Store} from "@ngxs/store";

@Injectable({
    providedIn: "root"
})
export class TokenService extends BaseComponent {

    public onExpired: Subject<string> = new Subject<string>();

    private tokenKeys: string[] = [];

    jwtHelper: JwtHelperService;

    constructor(private cacheService: CacheService, private store: Store) {
        super();

        this.jwtHelper = new JwtHelperService();

        cacheService.onExpired
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(cacheKey => {

                if (includes(this.tokenKeys, cacheKey)) {
                    this.expire(cacheKey);
                }
            });
    }

    public get(key: string): string {
        return this.cacheService.get(key);
    }

    public remove(key: string) {

        const indexOf = this.tokenKeys.indexOf(key, 0);

        if (indexOf >= 0) {
            this.tokenKeys.splice(indexOf, 1);
        }

        this.cacheService.remove(key);
    }

    public expire(key: string) {
        this.remove(key);
        this.onExpired.next(key);
    }

    public set(key: string, token: string, expiry: number = null) {

        if (isNil(expiry)) {
            const expiryDate = this.jwtHelper.getTokenExpirationDate(token);
            expiry = moment(expiryDate).diff(moment(), "millisecond");
        }

        this.tokenKeys.push(key);

        this.cacheService.set(
            key,
            token,
            expiry
        );
    }

    public validate(key: string) {

        const token = this.get(key);

        if (isNil(token)) {
            return false;
        }

        const isExpired = this.jwtHelper.isTokenExpired(token);

        return isExpired === false;
    }

    public getTokenForAccountType() {
        const userMode = this.store.selectSnapshot(UIState.GetUIMode);
        const smeToken = this.store.selectSnapshot(AuthState.getSmeToken) || this.get(CACHE_SME_TOKEN);

    const userToken = ((userMode !== 'consumer') && smeToken) ? smeToken : this.get(CACHE_AUTH_TOKEN);

    return userToken;
  }
}
