import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-revoke-nvidia-cancellation-modal',
  templateUrl: './revoke-nvidia-cancellation-modal.component.html',
  styleUrls: ['./revoke-nvidia-cancellation-modal.component.scss']
})
export class RevokeNvidiaCancellationModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.activeModal.close()
  }
}
