import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { DebitAccountPayload } from "../core/store/types/debit-account-payload.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PaymentStatusResponse } from "../core/store/types/payment-status.interface";
import { Utils } from "../utils";
import { retryWhen } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigService } from "./config.service";
import { TokenService } from "./token.service";
import { CACHE_AUTH_TOKEN } from "@models/constants";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class TMMNService {

  private successMessageSubject = new Subject<string>();
  private failMessageSubject = new Subject<string>();
  private errorMessageSubject = new Subject<string>();

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private configService: ConfigService,
    private tokenService: TokenService) { }

  pollForPaymentStatus(invoiceId: string, initiatedTimeStamp: number) {

    const maxAttempts = 8;
    const delay = 4000;

    return this.getPaymentStatus(invoiceId)
      .pipe(
        Utils.Helpers.pollUntil({
          initialDelay: delay,
          maxAttempts,
          pollInterval: delay,
          responsePredicate: (res: PaymentStatusResponse) => res?.data?.recordedDate >= initiatedTimeStamp,
          errorMessage: "Response took too long"
        }),
        retryWhen(Utils.Helpers.onErrorCodeRetryStrategy({
          errorCodesToInclude: [404, 502, 503],
          maxRetryAttempts: maxAttempts,
        }))
      )
  }

  getPaymentStatus(invoiceId: string) {
    const url = `${this.configService.BASE_API_URL}/V1/rain-web/payments/status/${invoiceId}`;
    const apiKey = environment.apiKey;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        apiKey: apiKey,
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    }

    return this.http.get<PaymentStatusResponse>(url, httpOptions);
  }

  debit(customerId: string, data: DebitAccountPayload) {
    const url = `${this.configService.BASE_API_URL}/V1/rain-web/payment/debit`;
    const apiKey = environment.apiKey;

    const httpOptions = {
      headers: new HttpHeaders({
        subject: customerId,
        "Content-Type": "application/json",
        apiKey: apiKey,
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(url, data, httpOptions);
  }

  paymentSuccess(invoiceId: string, status: string) {
    // this.toastr.success(status);
    this.successMessageSubject.next(status);
  }
  paymentFail(status: string) {
    // this.toastr.error(status);
    this.failMessageSubject.next(status);
  }

  paymentError(message: string) {
    // this.toastr.error(message);
    this.errorMessageSubject.next(message);
  }

  getSuccessMessage() {
    return this.successMessageSubject.asObservable();
  }

  getFailMessage() {
    return this.failMessageSubject.asObservable();
  }

  getErrorMessage() {
    return this.errorMessageSubject.asObservable();
  }

}