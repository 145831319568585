<div class="mb-10 modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
      src="../../assets/svg/close.svg" />
  </div>
</div>

<div class="p-5 modal-body border-0 " *ngIf="!loading">
  <div class="row m-0" *ngIf="!hasScheduledAction">
    <div class="col-12 mb-10 text-center">
      <p class="text-primary text-center h2 font-weight-bold modal-heading">are you sure you want to cancel '{{ service?.name }}'?</p>
      <p *ngIf="(hasPostPaid$ | async);else upfront">
        Your SIM cancellation will only happen at the <span class="text-primary">end of the month</span>. So, you'll
        still be connected until then and
        payment for your last invoice for this month's usage will go off on
        <span class="text-primary font-weight-bold">{{billingStore.paydate}} {{ billingDate | date: 'MMMM yyyy' }}</span>
      </p>
      <div *ngIf="(activeBreakpoint$ | async)  !== 'mobile'; else mobileView">
        <button class="btn-md btn btn-outline-primary mr-3 btn-style" (click)="activeModal.dismiss('cancel')" [disabled]="loading">
          no
        </button>
        <button class="btn btn-md btn-primary ml-3 btn-style" (click)="cancelSIM()" [disabled]="loading">
          yes
        </button>
      </div>
      <ng-template #upfront>
        <p >
          Your SIM cancellation will only happen at the end of your billing cycle <span class="text-primary">{{this.upFrontBillingDate}}</span> and your services will be available until then.  
          No payment is scheduled to be collected 
        </p>
      </ng-template>
      <ng-template #mobileView>
        <div class="cancel-actions">
          <button class="btn-md btn btn-outline-primary mr-3 btn-style" (click)="cancelSIM()" [disabled]="loading">
            yes
          </button>
          <button class="btn btn-md btn-primary ml-3 btn-style" (click)="activeModal.dismiss('cancel')" [disabled]="loading">
            no
          </button>
        </div>
      </ng-template>
    </div>

    <!--  -->

  </div>

  <div class="row m-0 mb-10" *ngIf="hasScheduledAction">
    <div class="col-12 text-center">
      <p class="text-primary large-font-size">
        Let’s try again
      </p>
      <p class="pt-5 medium-font-size">
        Looks like you've removed unlimited off-peak from this plan. You’ll have to add it before we can process your
        cancellation.
      </p>
      <button class="btn btn-sm btn-primary" (click)="activeModal.dismiss('cancel')">
        ok
      </button>
    </div>
  </div>
</div>


<ng-template #conflict let-conflict>
  <div class="mb-10 modal-header border-0 ">
    <div class="modal-img-close-btn z-index-5">
      <img alt="Close" aria-label="Close" class="pointer" (click)="conflict.dismiss('Cross click')"
        src="assets/svg/close.svg" />
    </div>
  </div>
  <div class="modal-body border-0 p-5 text-center">
    <p class="text-primary h1">Heads up!</p>
    <p class="my-10">{{ message }}</p>
    <button class=" btn btn-sm btn-primary" (click)="conflict.dismiss('ok')">
      ok
    </button>
  </div>
</ng-template>