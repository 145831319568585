import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { ConfigService } from './config.service';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ResetCart } from '../store/actions/cart.action';


class BaseIdleService {
  private count: number = 0;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  private resetOnTrigger: boolean = false;
  private _timerAmmount: number = 0;

  public onExpired: Subject<number> = new Subject<number>();

  set timerAmount(value: number) {
    this._timerAmmount = value;
  }

  constructor() {
    this.onExpired.subscribe(n => { });
  }

  public startTimer() {

    this.resetTimer();
  }

  public stopTimer() {

    if (this.timerSubscription) {

      this.timerSubscription.unsubscribe();
    }
  }

  public resetTimer() {
    this.stopTimer();

    if (this._timerAmmount > 0) {
      this.timer = timer(this._timerAmmount);
      this.timerSubscription = this.timer.subscribe(n => {
        this.timerComplete(n);
      });
    }
  }

  private timerComplete(n: number) {
    this.onExpired.next(++this.count);

    if (this.resetOnTrigger) {
      this.startTimer();
    }
  }
}

@Injectable({
  providedIn: "root"
})
export class IdleService extends BaseIdleService implements OnDestroy {

  protected ngUnsubscribe = new Subject();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  constructor(configService: ConfigService) {
    super();

    configService.onChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(x => {
        this.timerAmount = configService.IDLE_CACHE_EXPIRY;
      });

  }
}

@Injectable({
  providedIn: "root"
})
export class CartExpiryService extends BaseIdleService {
  constructor(configService: ConfigService, private store: Store) {
    super();

    configService.onChange.subscribe(x => {
      this.timerAmount = configService.CART_EXPIRY;
    });

    this.onExpired.subscribe(x => {
      this.store.dispatch(new ResetCart());
    });
  }

  public startTimer(): void {
      super.startTimer();
  }
  
}