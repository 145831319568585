import { Component } from '@angular/core';
import { Select, Store} from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreState } from '../../../../core/store/state/core.state';
import { DataLayerService } from '@services/data-layer.service';
import { SSOService } from '../../../../core/services/sso.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'rain-sim-for-data',
  templateUrl: './sim-for-data.component.html',
  styleUrls: ['./sim-for-data.component.scss']
})
export class SimForDataComponent {

  constructor(
    private store: Store,
    private dataLayerService: DataLayerService,
    private sso: SSOService,
  ){}
  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;

  openRainGoIfSignedIn() {
    const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);
    
    if (!isAuthed) {
      window.location.href = environment.rainGoBaseUrl;
      return
    }

    this.dataLayerService.rainGoClicked();
    return this.sso.createUrl(environment.rainGoSSOUrl).then(
      (encodedUrl) => {
        window.open(encodedUrl, '_self')
      }
    );
  }
}
