import { CheckCoverageComponent } from '../../../components/check-coverage/check-coverage.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { SupportService, ISupportItemDetail } from '@services/support.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/auth.service';
import { ElementService } from '@services/element.service';
import { isNil } from 'lodash';
import { Statuses } from '@models/result';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ServicesState } from 'src/app/store/state/services.state';
import { CoreState } from 'src/app/core/store/state/core.state';

/**
 * @ignore
 */
@Component({
  selector: 'app-about-my-rain-one-faq-right',
  templateUrl: './about-my-rain-one-faq-right.component.html',
  styleUrls: ['./about-my-rain-one-faq-right.component.scss']
})
export class AboutMyRainOneRightFAQComponent extends BaseComponent implements OnInit {

  @Input() fromPage: string;

  /**
   *loading @param {boolean}
   */
  loading: boolean;

  /**
   *supportQuestions @param {any}
   */

  supportQuestions: any[] = [
    {
      dataText: "level up 4G mobile phone line",
      description: "<p>If you need more gigs and minutes every month, you can level up anytime. The cost of your level up will be added to your monthly bill.</p>",
      path: "/",
      pathTitle: "level up 4G mobile phone line",
      name: "level up 4G mobile phone line",
      id: 95
    },
    {
      dataText: "speed up 5G wifi",
      description: "<p>If you need more 5G wifi speed, you can level up speed anytime. The cost of your speed level up will be added to your monthly bill.</p> ",
      name: "speed up 5G wifi",
      path: "/",
      pathTitle: "join rain ",
      id: 96
    },
    {
      dataText: "check my balances",
      description: "<p>To check your balance, sign into my<span class='font-weight-bold'>rain</span> or sms ‘balance’ to 136.​ </p>",
      name: "check my balances",
      path: "/",
      pathTitle: "check my balances",
      id: 97
    },
    {
      dataText: "buy more",
      description: "<p>If you’re running low on data, minutes or sms, you can buy more anytime on <a class='font-weight-bold-a' href=\"https://www.raingo.co.za/\" target=\"_blank\"><span class='font-weight-bold-a'>rain<strong>Go</strong></span></a>. These ‘buy more’ purchases are once-off and they never expire. So, whatever you don’t use this month, will still be there for you next month.</p>",
      name: "buy more",
      path: "/",
      pathTitle: "buy more",
      id: 97
    },
    {
      dataText: "about my 4G mobile phone line",
      description: `<h4 class='text-primary'>can I use voicemail? ​​</h4>
                    <p>We do not offer traditional voicemail services, but you'll receive an SMS notification for missed calls.​</p>
                    <h4 class='text-primary'>can I make international calls?​</h4>
                    <p>Yes, you can. Sign in to your <b>myrain</b> and switch on international calling, view call rates per country and buy more for your wallet.​</p>
                    <p>In <b>myrain</b> you can buy as much as you need more for your international calling wallet.​</p>
                    <p>Your top-ups do not expire, and the balance can be viewed on your rain dashboard. ​</p>
                    <h4 class='text-primary'>how much am I paying?</h4>
                    <p>To find out how much you'll be charged per country, view our international call rates.</p>
                    <p>Please refer to the rate card to check that the country and dialing prefix you want to call is included in the list.  Please note that Ts & Cs apply.</p>
                    <h4 class='text-primary'>can I use hotspot?</h4>
                    <p>Yes, you can enable hotspot by accessing your smartphone settings.</p>
                    `,
      name: "about my 4G mobile phone line",
      path: "/",
      pathTitle: "about my 4G mobile phone line",
      id: 99

    },
    {
      dataText: "port my number",
      description: "<p>You can keep your old phone number by porting it to your new rain 4G mobile phone SIM. It’s really quick and easy. Just go to your myrain page and request your number to be ported. </p>",
      name: "port my number",
      path: "/",
      pathTitle: "join rain ",
      id: 100
    }
  ];

  /**
   *isSignedIn @param {false}
   */
  isSignedIn = false;

  

  @Select(ServicesState.hasPostPaid)
  hasPostPaid$: Observable<boolean>

  /**
   * Default methods supportService,Router, ToastrService, elementService, Auth service,  Modal service,
   *
   * @param supportService
   * @param router
   * @param toastr
   * @param elementService
   * @param authService
   * @param modalService
   */
  constructor(
    private supportService: SupportService,
    private router: Router,
    private toastr: ToastrService,
    private elementService: ElementService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private store: Store
  ) {
    super();
  }

  /**
   * init app check if user is signed in
   */
  ngOnInit() {
    this.isSignedIn = this.authService.isSignedIn;
    this.loading = false;
  }

  signIn() {
    this.router.navigate(['/my-rain']);
  }

  /**
   * open support FAQs when user clicks on markdown
   */
  openSupportItem(item: ISupportItemDetail) {
    if (isNil(item.pathElement) === false) {
      this.elementService.scrollToElement(item.path, item.pathElement, 200, 1000);
    } else if (item.path.includes('https://')) {
      window.open(item.path, '_blank');
    } else {
      this.router.navigateByUrl(item.path);
    }
  }

  /**
   * get supported top 10 Questions
   */
  getSupportQuestions() {
    this.supportService
      .getTop10Questions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result.status !== Statuses.Success) {
          
          this.loading = false;
          setTimeout(() => this.toastr.error('An error occurred while getting the details', null));

          /**
           *@returns {boolean} returns the value
           */
          return;
        } else {
          this.loading = false;
          this.supportQuestions = result.value;
        }
      });
  }

  /**
   * scrolls to anchor shop on FAQs page
   */
  scrollToShop() {
    this.elementService.scrollToShop();
  }

  goTo(link) {
    this.router.navigateByUrl(link);
  }
  getDescription(item: any){
    const hasPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
    if(!hasPostPaid && (item.name.includes('billing') || item.name.includes('switch to'))){
      return  item?.upfrontDescription;
    }
    return item.description;
  }
  /**
   * Sets current location on map using map coordinates of user, when the user has shared their location
   */
  openCoverageMap() {
    this.modalService.open(CheckCoverageComponent, { size: 'xl' as any, windowClass: 'slideInUp' });
  }
}
