<div>
    <div class="mb-1 modal-header border-0 ">
        <div class="modal-img-close-btn z-index-5">
            <img alt="Close" aria-label="Close" class="pointer" (click)="close()" src="../../../assets/svg/close.svg" />
        </div>
    </div>

    <div class="modal-body border-0 text-center">

        <div class="copy">
            <h2 class="text-primary font-weight-bold my-5">oops, your voucher code is invalid</h2>

            <p class="mb-0">It’s either been used already, or it’s expired. <br>
                To continue, please try again with a valid code.</p>
        </div>
    </div>


    <div class="text-center border-0 action-buttons">
        <app-support-button></app-support-button>
        <button class="btn btn-sm btn-primary mx-auto" (click)="close()" >got it</button>
    </div>
</div>