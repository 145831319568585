<div class="modal-content border-0 px-sm-20">
  <div class="modal-header border-0 mb-5">
    <div class="modal-img-close-btn z-index-5">
      <img src="../../assets/svg/close.svg" class="pointer" alt="Close" (click)="activeModal.dismiss('close click')" />
    </div>
  </div>
  <div>
    <div class="text-center modal-body">
      <p class="text-primary text-center h2 font-weight-bold modal-heading"> {{title}}</p>
      <p class="mb-0 pl-0 pr-0 pl-sm-25 pr-sm-25">
        {{description}}
      </p>
    </div>
    <div class="modal-footer border-top-0 d-flex mb-10 justify-content-center">
      <button class="btn-width btn btn-md btn-primary m-2 px-10" (click)="confirm()">
        {{btnName}}
      </button>
    </div>
  </div>
</div>