<div class="form-group " [class.mark-valid]="markValid" [ngClass]="inputClass">
  <div [class]="'rain-form-row ' + display + ' ' + type" [class.has-errors]="checkForErrors()" [ngClass]="inputClass">
    <div class="field-container" [class.is-focused]="isFocused || control?.value" [ngClass]="inputClass">

      <!-- <label [for]="formatLabel(label)" class="label">{{label}}</label> -->
      <input (keydown.enter)="onKeyPress($event)" ngx-google-places-autocomplete (keyup)="KeyUp($event)"
        (onAddressChange)="handleAddressChange($event)" (onAddressSearch)="handleAddressSearch($event)" [name]="name ? name : formatLabel(placeholder)"
        [options]="config" #placesRef="ngx-places" (focus)="isFocused = true" (blur)="onBlur()"
        [id]="formatLabel(placeholder)" [type]="type" [formControl]="control" [placeholder]="placeholder"
        [autocomplete]="autocomplete" class="py-2 icon-img medium-font-size"
        [ngClass]="inputClass"
        />
    </div>
    <div class="form-errors">
      <ul>
        <li *ngFor="let error of checkForErrors()  | errorKeys">
          <p class="text-danger small-font-size">{{messages[error]}}</p>
        </li>
      </ul>
    </div>
  </div>
</div>