import { Utils } from "src/app/utils";
import { Device } from "../../interfaces/device.interface";
import { BreakPoint } from "../../services/layout/breakpoints.service";


export class InitDeviceChecker {
    static readonly type = Utils.Helpers.Type('[Core: Device] Init device checker');
    constructor() { }
}

export class InitDeviceCheckerSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Device] Init device checker Success');
    constructor(public readonly payload: Device) { }
}

export class AssignDeviceLayout {
    static readonly type = Utils.Helpers.Type('[Core: Device] Assign device layout');
    constructor(public readonly payload: BreakPoint) { }
}
