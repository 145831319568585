import { IRemoteLogistic } from './remote/remoteLogistic';

export class LogisticDetail {
  number: string;
  status: LogisticStatus;
  deliveryType: DeliveryType;

  static adapt(remote: IRemoteLogistic): LogisticDetail {
    return {
      number: remote.number,
      status: LogisticDetail.mapStatus(remote.status),
      deliveryType: LogisticDetail.mapDeliveryType(remote.delivery.type)
    };
  }

  static mapStatus(status: string): LogisticStatus {
    status = status?.toLowerCase();
    switch (status) {
      case 'payment not completed':
        return LogisticStatus.PaymentNotCompleted;
      case 'order processed':
        return LogisticStatus.OrderProcessed;
      case 'queued for delivery':
        return LogisticStatus.QueuedForDelivery;
      case 'partially delivered':
        return LogisticStatus.PartiallyDelivered;
      case 'delivered':
        return LogisticStatus.Delivered;
      case 'order cancelled':
        return LogisticStatus.OrderCancelled;
      case 'queued for collection':
        return LogisticStatus.QueuedForCollection;
      case 'partially collected':
        return LogisticStatus.PartiallyCollected;
      case 'collected':
        return LogisticStatus.Collected;
      case 'queued for rica':
        return LogisticStatus.QueuedForRICA;
      case 'partial rica':
        return LogisticStatus.PartialRICA;
      case 'resource requested':
        return LogisticStatus.ResourceRequested;
      case 'resource allocated':
        return LogisticStatus.ResourceAllocated;
      case 'rica complete':
        return LogisticStatus.RICAComplete;
      case 'resource allocation failure':
        return LogisticStatus.ResourceAllocationFailure;
      default:
        return LogisticStatus.PaymentNotCompleted;
    }
  }
  private static mapDeliveryType(deliveryType: string): DeliveryType {
    deliveryType = deliveryType.toLowerCase();
    switch (deliveryType) {
      case 'scheduled':
        return DeliveryType.Scheduled;
      case 'collection':
        return DeliveryType.Collection;
      case 'rica':
        return DeliveryType.RICA;
      default:
        return DeliveryType.Scheduled;
    }
  }
}

export enum OrderStatuses {
  None = 0,
  PaymentFailedDelivery,
  OrderProcessedDelivery,
  InTransit,
  DeliveryFailed,
  Delivered,
  DeliveryProcessed,
  PartiallyDelivered,
  ActivatedDelivered,
  CancelledDelivery,

  PaymentFailedCollection,
  OrderProcessedCollection,
  CollectionProcessed,
  PartiallyCollected,
  ActivatedCollected,
  CancelledCollection,

  PaymentFailedRICA,
  OrderProcessedRICA,
  RICAProcessed,
  PartialRICA,
  ActivatedRICA,
  CancelledRICA
}

export enum LogisticStatus {
  none = 0,
  PaymentNotCompleted,      //1
  OrderProcessed,           //2
  QueuedForDelivery,        //3
  PartiallyDelivered,       //4
  Delivered,                //5
  OrderCancelled,           //6
  QueuedForCollection,      //7
  PartiallyCollected,       //8
  Collected,                //9
  QueuedForRICA,            //10
  PartialRICA,              //11
  RICAComplete,             //12
  ResourceRequested,        //13
  ResourceAllocated,        //14
  ResourceAllocationFailure //15
}

export enum DeliveryType {
  Scheduled = 'scheduled',
  Collection = 'collection',
  RICA = 'RICA'
}
