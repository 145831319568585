import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, TemplateRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IProductDetail, ProductCategories, ProductTypes } from '@models/productDetail';
import { DataLayerService } from '@services/data-layer.service';
import { CartService } from '@services/cart.service';
import { Router } from '@angular/router';
import { Configs } from '@components/product/product-modals/fourg-sim-modal-copy/fourg-sim-modal-copy.component';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { SubjectService } from '@services/subject.service';
import { ProfileService } from '@services/profile.service';
import { Statuses } from '@models/result';
import { forkJoin, Observable, of } from 'rxjs';
import { every, isEmpty, isNil, uniq } from 'lodash';
import { ProductService } from '@services/product.service';
import { ElementService } from '@services/element.service';
import { ServicesState } from 'src/app/store/state/services.state';
import { Select } from '@ngxs/store';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'app-fourg-upsell',
  templateUrl: './fourg-upsell.component.html',
  styleUrls: ['./fourg-upsell.component.scss']
})
export class FourgUpsellComponent extends BaseComponent implements OnInit {
  @ViewChild('cartError', { static: true }) cartError: TemplateRef<any>;
  @Input()
  public product: any;

  imgUrl = 'assets/images/promoUsell.png';
  noThanksUpSell: string;
  requestSalesCallUpSell: string;
  buyNowUpSell: string;
  fromGotSim: boolean;
  @Input()
  isGotSim: any;

  @Select(ServicesState.hasPostPaid)
  hasPostPaid$: Observable<boolean>

  @Input()
  title: string;

  @Input()
  mobileTitle: string;

  @Input()
  fromPage: string;
  private available4G: number;
  private cartProducts: IProductDetail[];
  private amountOf4GInCart: number = 0;
  private showContinueButton: boolean;
  private errorMsg: string;
  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;

  constructor(
    public activeModal: NgbActiveModal,
    private dataLayerService: DataLayerService,
    private cartService: CartService,
    private router: Router,
    private modalService: NgbModal,
    private subjectService: SubjectService,
    private elementService: ElementService,
    private profileService: ProfileService,
    private productService: ProductService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getAvailableSims();
    // Track modal view
    this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['successful-coverage-result']);

    this.buyNowUpSell = `R${this.product.price} - ${this.product.name.replace('\n', '')} - buy now - upsell`;
    this.noThanksUpSell = `R${this.product.price} - ${this.product.name.replace('\n', '')} - no thanks - upsell`;
  }

  acceptOffer() {

    if (this.amountOf4GInCart >= this.available4G) {
      this.showContinueButton = false;
      this.errorMsg = 'You have reached the maximum amount of 4G service for this account.';
      const modalRef = this.modalService.open(this.cartError, { size: <any>'confirm', windowClass: 'slideInUp' });
      return;
    }
    this.subjectService.fourgUpsellResponse(true);

    this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['unlimited-4G-in-coverage' + this.isGotSim]);
    const configs: Configs = {} as Configs;

    if (!configs.friendly_name) {
      configs.friendly_name = this.cartService.getNewSimName();
    }

    this.cartService
      .add(this.product.id, configs)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.activeModal.close();
        this.router.navigate(['/cart']);
      });

  }

  getCartItems() {
    this.cartService
      .getItems()
      .pipe(
        switchMap(cartItems => {
          if (isEmpty(cartItems)) {
            return of([]);
          }

          const observables = uniq(cartItems.map(x => x.productId)).map(x => this.productService.getById(x));

          return forkJoin(observables).pipe(
            map(results => {
              const succesfullProducts = results.filter(x => x.status == Statuses.Success).map(x => x.value);

              return cartItems.map(cartItem => {
                return succesfullProducts.find(y => y.id === cartItem.productId);
              });
            })
          );
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: (products: IProductDetail[]) => this.cartProducts = products
      });
  }

  getAvailableSims() {
    this.profileService.get().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        if (res.status === Statuses.Success) this.available4G = res.value.available4G;
      },
      error: _ => { },
      complete: () => this.getCartItems()
    })
  }

  checkCategory() {
    if (this.available4G === 0) {

      this.showContinueButton = false;
      this.errorMsg = `You may only have ${this.available4G} 4G Product in the Cart. Please complete current transaction first`;
      return this.modalService.open(this.cartError, { size: <any>'confirm', windowClass: 'slideInUp' });
    }

    if (isNil(this.cartProducts) || isEmpty(this.cartProducts)) {
      this.acceptOffer();
    } else {
      for (const product of this.cartProducts) {

        if (product.name.toLowerCase().includes('4g') || product.name.toLowerCase().includes('19 hours')) {
          this.amountOf4GInCart++;
        }
      }
      if (this.amountOf4GInCart <= this.available4G - 1) {
        this.acceptOffer();
      } else {
        this.errorMsg = `You may only have ${this.available4G} 4G Product in the Cart. Please complete current transaction first`;
        const mRef = this.modalService.open(this.cartError, { size: <any>'confirm', windowClass: 'slideInUp' });

        return mRef
      }

    }
  }

  public rejectOffer() {
    this.subjectService.fourgUpsellResponse(false);

    this.activeModal.close();
  }


}
