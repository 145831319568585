<div [class]="'product-container is-type-' + product.type +' is-'+(ap$ | async)" *ngIf="product">
    <div class="img-container">
        <img [src]="product.image" alt="">
        <img *ngIf="showBoost" class="boost-img"
        src="/assets/images/rain-one/keepboost.png">
    </div>

    <div class="decription-lockup">
        <h2 *ngIf="name" [innerHtml]="name"></h2>
        <h3 *ngIf="product.type === 'sim'">every month:</h3>

        <ul>
            <ng-template ngFor let-description [ngForOf]="product.descriptions">
                <li>
                    <p [innerHTML]="description"></p>
                </li>
            </ng-template>
        </ul>
    </div>
</div>