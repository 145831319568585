<div class="geforce-orders-modal wrapper">
    <div class="close-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <br>
    <div class="page-title">
        <h2>heads up</h2>
    </div>
    <div class="content-body">
        <p>Looks like you already have an NVIDIA membership order. You can head over to<span (click)="navigateToMyOrders()" class="my-orders"> my orders</span> and complete your payment</p>
    </div>
    <div class="button-container">
        <button (click)="close()">got it</button>
    </div>
</div>