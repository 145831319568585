import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { PortSimPending } from 'src/app/store/actions/number-porting-actions';

@Component({
  selector: 'rain-rain-number-failure-modal',
  templateUrl: './rain-number-failure-modal.component.html',
  styleUrls: ['./rain-number-failure-modal.component.scss']
})
export class RainNumberFailureModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal, private store: Store) {}

  ngOnInit(): void {}
  close() {
    this.activeModal.close();
    this.store.dispatch(new PortSimPending(false));
  }
}
