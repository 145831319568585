import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rain-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() title: string;
  public _isOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
