<div class="promo-upsell">
  <div class=" modal-img-close-btn z-index-5">
    <img
      src="../../assets/svg/close.svg"
      class="pointer"
      alt="Close"
      (click)="activeModal.dismiss('Cross click')"
      data-dismiss="modal"
      data-target="#FiveGCoverageModal"
      aria-label="Close"
    />
  </div>

  <div class="modal-header d-lg-block d-none">
    <div [innerHtml]="title"></div>
    <div class="lead-text text-center">
      Good news! You’re in 4G coverage. This means you can try our
      <span class="text-primary"> 4G for any device </span> <br />
      for only <span class="text-primary font-weight-bold"> 499 </span> a
      month while you wait for 5G to be available in your area.
    </div>
  </div>

  <div class="small-modal-header d-lg-none d-block">
    <div [innerHtml]="mobileTitle"></div>
    <div class="mb-5 lead-text text-center">
      This means you can try our <span class="text-primary"> 4G for any device </span> for only
      <span class="text-primary font-weight-bold">499</span> a month while
      you wait for 5G to be available in your area.
    </div>
  </div>
 
  <div class="promo-upsell__background">
    <div class="modal-body">
      <img
        class="promo-upsell__image"
        src="/assets/images/fourg/4g-upfront-in-coverage.png"
        alt="5G for the price of 4G"
      />
    </div>
  </div>
  <div class="modal-footer text-center">
    <div class="row">
      <button
        class="btn btn-sm btn-outline-primary m-2 px-10"
        (click)="rejectOffer()"
        [attr.data-text]="noThanksUpSell"
        data-id="btn-click"
      >
        no thanks
      </button>

      <button
        class="btn btn-sm btn-primary m-2 px-10"
        (click)="checkCategory()"
        [attr.data-text]="buyNowUpSell"
        data-id="btn-click"
      >
        buy now
      </button>
    </div>
  </div>
</div>
<!-- Modal -->
<ng-template #cartError let-modal>
  <div id="cartError" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-content shadow">
      <div class="text-center p-10">
        <p class="text-primary extra-large-font-size pb-5">heads up</p>
        <p class="text-danger">
          {{ errorMsg }}
        </p>
      </div>

      <div class="modal-footer border-top-0">
        <button class="btn btn-sm btn-primary" data-dismiss="modal" (click)="modal.close('cancel')">
          cancel
        </button>
        <button
          class="btn btn-sm btn-primary"
          (click)="emptyCart(); modal.close('continue')"
          *ngIf="showContinueButton"
        >
          continue
        </button>
      </div>
    </div>
  </div>
</ng-template>
