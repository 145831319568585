import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

@Component({
  selector: 'rain-geforce-order-modal',
  templateUrl: './geforce-order-modal.component.html',
  styleUrls: ['./geforce-order-modal.component.scss']
})
export class GeforceOrderModalComponent implements OnInit {
  constructor(private ngModal: NgbModal, public activeModal: NgbActiveModal, private router: Router, private store: Store) {}

  ngOnInit(): void {}
  navigateToMyOrders() {
    this.activeModal.close();
    this.router.navigate(['/manage/orders']);
  }
  close() {
    this.activeModal.close();
  }
}
