<ng-container *ngIf="(activeBreakpoint$ | async) as activeBreakpoint">

  <div class="container-fluid bg-light-grey sat20" *ngIf="page != 'home'; else homePage">
    <div class="container sat20">
      <div class="sat20-grid">
        <div class="sat20-grid__copy text-primary text-align-center-mobi">
          <div class="heading">collect instore & score a FREE <strong>SA20 bat & ball</strong></div>
          <div class="legal pt-5"><a href="/legal?section=competition" class="text-decoration-underline"
              target="_blank">While stocks last. Ts & Cs apply.</a></div>
        </div>
        <div class="sat20-grid__image">
          <img src="./assets/images/t20/sat20-bat-logo.png" class="mw100" alt="" *ngIf="activeBreakpoint !== 'mobile'">
          <img src="./assets/images/t20/mobi-bat-sat20-delivery.png" class="mw100" alt=""
            *ngIf="activeBreakpoint === 'mobile'">
        </div>
       
      </div>
    </div>
  </div>
  <ng-template #homePage>
    <div class="container sat20 sat-20-home-page bg-light-blue">
      <div class="sat20-grid">
        <div class="sat20-grid__copy text-primary">
          <div class="heading">collect instore & score a FREE <strong>SA20 bat & ball</strong></div>
          <div class="legal pt-5" *ngIf="activeBreakpoint !== 'mobile'"><a href="/legal?section=competition"
              class="text-decoration-underline" target="_blank">While stocks last. Ts & Cs apply.</a></div>
        </div>
        <div class="sat20-grid__image">
          <img src="./assets/images/t20/sat20-bat-logo.png" class="mw100" alt="" *ngIf="activeBreakpoint !== 'mobile'">
          <img src="./assets/images/t20/mobi-sat20-bat-logo.png" class="mw100" alt=""
            *ngIf="activeBreakpoint === 'mobile'">
        </div>
        <div *ngIf="activeBreakpoint === 'mobile'" class="legal pt-5 pb-10 pl-10">
          <a href="/legal?section=competition" class="text-decoration-underline" target="_blank">While stocks last. Ts &
            Cs apply.</a>
        </div>
      </div>
    </div>
  </ng-template>

</ng-container>