<section class="carousel-block">
    <div class="slider-wrapper">
    <ngx-slick-carousel class="carousel" [config]="carouselConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <div class="slider-image">
            <img [src]="slide.img" alt="Slide image">
          </div>
          <div class="slider-copy" [ngClass]="slide.textClass" [innerHTML]="slide.text"></div>
        </div>
      </ngx-slick-carousel>
    </div>
</section>

  