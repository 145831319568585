export interface permissions {
    "porting": boolean,
    "topUp": boolean,
    "changePaymentDate": boolean,
    "purchase": boolean,
    "speedUp": boolean,
    "simSwap": boolean,
    "changeCCdetails": boolean,
    "partialPayment": boolean,
    "paymentSettleAccount": boolean,
    "cancellations": boolean,
    "updateAccountDetails": boolean,
    "assignAsim": boolean,
    "prepaid5G": boolean,
    "migrations": boolean,
    "levelUp": boolean
}

export const defaultPermissions = {
    porting: true,
    topUp: true,
    changePaymentDate: true,
    purchase: true,
    speedUp: true,
    simSwap: true,
    changeCCdetails: true,
    partialPayment: true,
    paymentSettleAccount: true,
    cancellations: true,
    updateAccountDetails: true,
    assignAsim: true,
    prepaid5G: true,
    migrations: true,
    levelUp: true
}