import { ValidationErrors } from "@angular/forms";
import { RainOneLevel } from "../core/interfaces/rain-one-level.interface";

export const COLOR_CONSTANTS = {
  "rainBlue": "#0077c8",
  "indigo": "#00afea",
  "purple": "#6f42c1",
  "pink": "#e83e8c",
  "red": "#dc3545",
  "orange": "#fa982e",
  "yellow": "#f9f871",
  "green": "#5b9330",
  "teal": "#178acc",
  "cyan": "#17a2b8",
  "greenStatus": "#70ad47",
  "notificationBlue": "#3676c0",
  "active": "#22c093",
  "speedUpButtonDefault": '#ffffff'
}

export const a30Id = 'b52b558f-8ec7-4449-b033-adbbc9ed6348';
export const pro60NoExtId = '59a94b1b-0098-416b-9247-22577faef5ae';
export const pro601ExtId = '59a94b1b-0098-416b-9247-22577faef5ad';
export const pro602ExtId = '59a94b1b-0098-416b-9247-22577faef5ac';
export const pro100NoExtId = '059d2900-6419-4de0-a9f1-7f6b0abe838a';
export const pro1001ExtId = '16b9ecdb-7d2a-4778-9cb4-7ec4c0d1243d';
export const pro1002ExtId = '16b9ecdb-7d2a-4778-9cb4-7ec4c0d1243c';

export const work30Id = 'dddabbd9-93a9-467a-8f2d-6ca395346b7f';
export const proWork60NoExtId = 'a2c2ec9e-e846-47f1-964a-2b65c263605d';
export const proWork601ExtId = 'a2c2ec9e-e846-47f1-964a-3b65c263605d';
export const proWork602ExtId = 'a2c2ec9e-e846-47f1-964a-4b65c263605d';
export const proWork100NoExtId = 'c7dba48b-c0d8-4d0b-b588-4463b77c7c5d';
export const proWork1001ExtId = '28e54ad8-9f0b-46a4-842f-ab6a23680857';
export const proWork1002ExtId = '28e54ad8-9f0b-46a4-842f-ab6a23680886';

export const a30IdPostpaid = '50a6de3d-fc60-4167-9818-4c11c36b26c9';
export const pro60IdPostpaid = '30de10dc-a5af-43a6-ade2-aeee65fb43a1';
export const pro100IdPostpaid = '61a6109d-7624-44ea-977e-b6c8b5c64907';

export const NUMBER_REPLACE = '{NUMBER}';

export const DEFAULT_LATITUDE: number = -26.0505936;
export const DEFAULT_LONGITUDE: number = 28.0222714;
export const DEFAULT_ZOOM_LEVEL = 14;
export const DEFAULT_POSTAL_CODE = '0000';
export const CONFIG_SPEND_LIMIT = 'spend-limit';
export const CONFIG_MIGRATE = 'migrate';
export const CONFIG_FRIENDLY_NAME = 'friendly_name';
export const CONFIG_OFF_PEAK = 'offPeak';
export const CONFIG_PEAK = 'peak';
export const CONFIG_RESET_TRY = 'reset_try';
export const CONFIG_TARIF = 'tarif';
export const CONFIG_VALUE_ADDS = 'value_adds';
export const CONFIG_CONFIGS = 'configs';
export const CONFIG_POSITION = 'position';
export const HTTP_HEADER_API_KEY = 'apikey';
export const HTTP_HEADER_KEY = 'key';
export const CONFIG_BETA = 'beta';
export const CACHE_BETA_PROGRAMS = 'CACHE_BETA_PROGRAMS';
export const CACHE_PARGO = 'CACHE_PARGO';
export const CACHE_SUPPORT = 'CACHE_SUPPORT';
export const CACHE_MML_DATA = 'CACHE_MML_DATA';
export const CACHE_TOP_10_SUPPORT = 'CACHE_TOP_10_SUPPORT';
export const CACHE_SUPPORT_GROUP = 'CACHE_SUPPORT_GROUP';
export const DEFAULT_TARRIF = 50;
export const CACHE_CART_RESULT = 'CACHE_CART_RESULT';
export const ORIGIN = 'ORIGIN';
export const RICA_TYPE = 'rica_type';
export const RICA_TYPE_COURIER = 'courier';
export const RICA_TYPE_E_RICA = 'e-rica';
export const CACHE_SUPPORT_ADDED = 'CACHE_SUPPORT_ADDED';
export const CACHE_4GTO5G_MIGRATION = 'CACHE_4GTO5G_MIGRATION_ADDED';
export const CACHE_SUPPORT_SALE_ADDED = 'CACHE_SUPPORT_SALE_ADDED';
export const CACHE_SUPPORT_BILLING_ADDED = 'CACHE_SUPPORT_BILLING_ADDED';
export const CACHE_SUPPORT_NETWORK_ADDED = 'CACHE_SUPPORT_NETWORK_ADDED';
export const CACHE_SUPPORT_SALE_UPGRADE_ADDED = 'CACHE_SUPPORT_SALE_UPGRADE_ADDED';
export const CACHE_SUPPORT_SIM_CANCELLATION = 'CACHE_SUPPORT_SIM_CANCELLATION';
export const CACHE_PRODUCTS_KEY = 'CACHE_PRODUCTS';
export const CACHE_LOGISTIC = 'CACHE_LOGISTIC';
export const CACHE_ORDERS = 'CACHE_ORDERS';
export const CACHE_PAY_NOW = 'CACHE_PAY_NOW';
export const CACHE_GIVE_A_GIG_PURCHASE_DETAILS = 'CACHE_GIVE_A_GIG_PURCHASE_DETAILS';
export const CACHE_7_DAYS_PURCHASE_DETAILS = 'CACHE_7_DAYS_PURCHASE_DETAILS';
export const CACHE_END_PLAN = 'CACHE_END_PLAN';
export const CACHE_REJECT_7_DAYS_PRODUCT = 'CACHE_REJECT_7_DAYS_PRODUCT';
export const CACHE_BUY_7_DAYS_PRODUCT = 'CACHE_BUY_7_DAYS_PRODUCT';
export const CACHE_EDIT_GIVE_A_GIG = 'CACHE_EDIT_GIVE_A_GIG';
export const CACHE_SCHEDULE_MIGRATION = 'CACHE_SCHEDULE_MIGRATION';
export const CACHE_4G_5G_MIGRATION_DETAILS = 'CACHE_4G_5G_MIGRATION_DETAILS';
export const CACHE_MIGRATION_ADDRESS = 'CACHE_MIGRATION_ADDRESS';
export const CACHE_IDM_TOKEN = 'CACHE_IDM_TOKEN';
export const CONFIG_ICCID = 'iccid';
export const CONFIG_COMMENTS = 'comments';
export const CART_PRE_RICA_STATUS = 'CART_PRE_RICA_STATUS';
export const CONFIG_STAFF = 'staff';
export const CONFIG_DATA_PLAN = 'dataPlan';
export const CONFIG_SPEED = 'speed';
export const CONFIG_STREAM = 'stream';
export const CONFIG_SIM_TYPE = 'sim_type';
export const SIM_TYPE_VIRTUAL = 'virtual';
export const SIM_TYPE_PHYSICAL = 'physical';
export const PAY_NOW_TYPE = 'PAY_NOW_TYPE';
export const PAYMENT_STATUS = 'status';
export const PAYMENT_REDIRECT = 'redirect';
export const PAY_ORDER_ID = 'PAY_ORDER_ID';
export const ORDER_HAS_5G_PRODUCT = 'ORDER_HAS_5G_PRODUCT';
export const SERVICE_META_DATA_CHARACTERISTICS = 'characteristics';
export const MAP_SEARCH_CONFIG = {
  componentRestrictions: {
    country: ['ZA']
  },
  types: ['address']
};
export const CACHE_CART = 'CART_CACHE';
export const SHOW_ADDRESS_KEY = 'showAddress';
export const COOKIERBANNER = 'displayCookieBanner';
export const NO_MORE_4G_SIM_MESSAGE =
  "We’re sorry but we can’t complete this order as you’ve reached your limit of 4G SIMs in your account. You only have {NUMBER} available, please remove some products to continue.";
export const NO_MORE_5G_SIM_MESSAGE =
  "We’re sorry but we can’t complete this order as you’ve reached your limit of 5G SIMs in your account. You only have {NUMBER} available, please remove some products to continue.";

// export const NO_MORE_RAIN_ONE_MESSAGE =
// "We’re sorry but we can’t complete this order as you’ve reached your limit for your account. You only have {NUMBER} available, either check 'My Orders' for any active orders";
export const NO_MORE_RAIN_ONE_MESSAGE =
  "<p>Unfortunately we can't complete this order as you have reached the rain<span class='font-weight-bold'>one</span> 101 limit per account.</p><p>If you do not have an active rain<span class='font-weight-bold'>one</span> 101 product, please check ‘my orders’ and complete your pending order.</p>";


export const NO_MORE_RAIN_ONE_WORK_MESSAGE =
  "<p>Unfortunately we can't complete this order as you have reached the rain<span class='font-weight-bold'>one work</span> limit per account.</p><p>If you do not have an active rain<span class='font-weight-bold'>one work</span> product, please check ‘my orders’ and complete your pending order.</p>";


export const NO_MORE_RAIN_MOBILE_MESSAGE =
  "<p>Unfortunately we can't complete this order as you have reached the rain<span class='font-weight-bold'>mobile</span> limit per account.</p><p>If you do not have an active rain<span class='font-weight-bold'>mobile</span> product, please check ‘my orders’ and complete your pending order.</p>";

export const NO_MORE_NVIDIA_MESSAGE =
  "<p>Unfortunately we cant complete this order as you have reached the GeForce NOW limit per account.</p><p>If you do not have an active GeForce NOW product, please check ‘my orders’ and complete your pending order.</p>";

export const ALREADY_HAS_TWO_FOR_ONE =
  "We’re sorry but we can’t complete this order as you’ve reached your limit of Only 1 5G 2for1 in your account";

export const ALREADY_HAS_FREE_TWO_FOR_ONE =
  "We’re sorry but we can’t complete this order as you already have the Only 1 5G 2for1 in your cart";
export const BETA_ERROR_MESSAGE = 'You are not allowed to buy one of these beta products.';

export const CACHE_COURIER_STATUS = 'CACHE_COURIER_STATUS';
export const CACHE_UPDATE_PASSPORT = 'CACHE_UPDATE_PASSPORT';
export const CACHE_GENERATE_VOUCHER = 'CACHE_GENERATE_VOUCHER';
export const CACHE_LOGISTIC_STATUS = 'CACHE_LOGISTIC_STATUS';
export const CACHE_CURRENT_ORDER = 'CURRENT_ORDER';
export const CACHE_CURRENT_DELIVERY_ADDRESS = 'CURRENT_DELIVERY_ADDRESS';
export const CACHE_CURRENT_DELIVERY_CONFIGS = 'CURRENT_DELIVERY_CONFIGS';
export const CACHE_CURRENT_ORDER_ID = 'CURRENT_ORDER_ID';
export const CACHE_PROFILE = 'CACHE_PROFILE';
export const CACHE_SERVICES = 'CACHE_SERVICES';
export const CACHE_SERVICE = 'CACHE_SERVICE_';
export const CACHE_DEVICE = 'CACHE_DEVICE_';
export const CACHE_AUTH_TOKEN = 'CACHE_AUTH_TOKEN';
export const CACHE_SME_TOKEN = 'CACHE_SME_TOKEN';
export const CACHE_Credential_TOKEN = 'CACHE_Credential_TOKEN';
export const CACHE_REMEMBER_ME = 'CACHE_REMEMBER_ME';
export const CURRENT_ADDRESS = 'CURRENT_ADDRESS';
export const CPEX_TAC_CODE = '86510304';
export const CPE_PRO_TAC_CODE_V1 = '86720604';
export const CPE_PRO_TAC_CODE_V2 = '86073004';
export const CACHE_ADDRESS_SEARCH = 'CACHE_ADDRESS_SEARCH';
export const ORIGIN_GOT_SIM = 'gotSIM';
export const CACHE_RAIN_NETWORK_INFO = 'CACHE_RAIN_NETWORK_INFO';
export const RAIN_ONE_LEVELS: RainOneLevel[] = [
  {
    level: 1,
    id: '1',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '3 gigs | 90 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: '10',
    cost: '75',
    selected: false,
    name: 'rainOne Level 1',
    includedInSelection: false
  },
  {
    level: 2,
    id: '2',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '4 gigs | 120 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: '15',
    cost: '150',
    selected: false,
    name: 'rainOne Level 2',
    includedInSelection: false
  },
  {
    level: 3,
    id: '3',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '5 gigs | 150 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '20',
    cost: '225',
    selected: false,
    name: 'rainOne Level 3',
    includedInSelection: false
  },
  {
    level: 4,
    id: '4',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '6 gigs | 180 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '25',
    cost: '300',
    selected: false,
    name: 'rainOne Level 4',
    includedInSelection: false
  },
  {
    level: 5,
    id: '5',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '7 gigs | 210 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '25',
    cost: '375',
    selected: false,
    name: 'rainOne Level 5',
    includedInSelection: false
  }
];

export const RAIN_ONE_BASE_IDS = [
  '50a6de3d-fc60-4167-9818-4c11c36b26c9',
  'dcf722e0-7d2b-4b79-acb9-1a22397b437b'
]

export const RAINONE_5G_SIM = [
  "fb90bbb6-d448-43ad-bf28-953aabafd173",
  "b925c9cf-1bd3-4a91-a0b7-aa7a6a38a404",
  "1ff445a7-6ef6-4801-8519-dc2418cb2a49",
  "1fc1048b-ddb6-45dd-af3f-ea453aa9f0e9",
  "f1f2bc4a-3305-4feb-b174-c1e14f7c9d04",
  "c59fb78d-20d1-40ff-b1b2-0433c118e80f",
  "6c78833a-16f3-41dd-a910-13b255d97070",
  "b26ade56-2898-401a-9fa6-eb8dca1fb8a4",
  "5bdb25a6-a303-455e-8f28-85516d6284a5",
  "b211df10-d99e-490a-808d-ca0e3db5a7bc",
  "6d2c9e53-09fa-40ad-8b14-a35a1427f472",
  "bfe60b11-4f6f-4f02-9116-a07a8c152628",
  "bb512fe4-8dee-44aa-9826-59cd2e76f93a",
  "eabcd40c-2b4c-4d64-91bc-22128a00ca08",
  "e6ebcf11-71f5-4813-81b3-fff56d01b5f7",
  "bad023f0-a558-4e27-b460-4e05e84c4726",
  "314fe36c-0ba8-46e6-b98b-0d426685ed3f",
  "6187d598-7958-46b8-8734-474b720e8961",
  "475a87bb-54a3-4030-bc54-7c7ce6c88c42",
  "e81eeb35-189c-4407-8249-55d41121611f",
  "2abec1e2-3bc2-45ac-a9b1-7d84348b2a93",
  "763c93fd-ed4e-4da1-a06b-02ce51981125"
  //  "56793b62-6a5c-46b1-8ebb-cd180b253aa8"
]

export const FOURG_SIM_NO_LEGACY = [
  "a12113ab-b9d8-4706-a08a-3d04b52da876",
  "57004cbe-ba70-4010-a9c4-efad90bde591",
  "bac0e334-8741-428b-ba9f-d3157dc04f6c",
  "ab2f0952-adc1-4a0a-a6d0-6cae148151cd",
  "45c1418e-eb54-4a67-9e83-a1ff0bfcad90",
  "d3e7b3d2-1300-4acc-b75b-5f4016854b54",
  "fcc4fc4e-fb34-413d-b85c-8ff9f226d028",
  "1e1ea291-227a-4bce-8acf-4c6a72692b85",
  "4593f208-2e17-4401-9faa-ef2c24010d19",
  "43af99b6-6298-457f-8995-97a802795db1",
  "27725f8b-156e-463a-b886-7349080f85d1",
  "bbea5648-a2b6-467f-b9a9-96e3c8f19bbe",
  "e23ffd75-2fc9-4862-a48a-29ee23121ec9",
  "3a321f19-f8e8-460f-8efe-c923d0732c95",
  "28939e65-fba5-4807-864f-8f1d108c9120",
  "4b4e6ff7-092f-400a-b0d4-8aa651bb604d",
  "8c8bf56e-9401-4c4f-97c5-0b9ba2098775",
  "b7830e05-080a-4f48-88cf-0a435850ec66",
  "76b23967-35bd-493b-bcb5-3dc14969d088",
  "36e20612-e257-492a-ac71-9ba6ecf1babf",
  "4f386ac5-ae50-48ea-8d79-ffde3fa22b6d",
  "78ec5a8b-fc7d-48bd-8ad0-de20d38751b8",
  "3158c070-4ff0-4548-b24d-a5ef4647ac51",
  "065970b9-ae0f-474f-b15e-4c787b09b951",
  "d6257097-457e-4f5a-a537-3cd1c9d2cda1",
  "5e570e55-fef5-4ba6-bd23-50eb3d1263b4",
  "3bd4f9a0-41ea-4ab2-8f97-09e0673263fa",
  "3211d5ef-642f-4728-9c94-8cb2c4e77dd4",
  "09ecbf2a-d40a-477a-ae39-43b5f6de5ab6",
  "ff727c5b-a31d-4d05-9c55-36158c9ec2e6",
  "761aca98-67ee-4f16-ba82-7367986faa90",
  "c49be4a8-1370-457e-8dbc-e3dac20f886b",
  "28c15f87-7b61-4e63-9759-1fa046cb5060",
  "7c44fa21-59f9-4e3d-be48-077fe41db640",
  "008ae5ab-074f-480e-a8bf-115e3d271a13",
  "d81f1c35-5956-44f6-937d-1c193c86aa68",
  "97d25289-480a-4fa9-939f-d68573f09869",
  "57b94db3-7a67-4d2d-8a8e-7914e4496f41",
  "12ef8059-aa3d-4fde-9483-655c75f09974",
  "f247c333-280b-49f9-a876-956d860265bd",
  "76db4c2f-7e81-4c7c-85a1-2f390060873d",
  "ebaff822-2c3c-4eac-89b4-1d3d26d2bc6b"
]


export const FOURG_UNLIMITED_IDS = [
  "db3495c4-e9b5-4ea0-9a6a-30cdb3b52d62",
  "0268ed3c-0def-433d-ad37-8ccf629dbd68",
  "7a827301-9dd9-45fa-a1e1-b790fcda0f97",
  "f53c737d-a61a-47af-a486-9b371acb6a96",
  "73315190-9121-466e-a98e-2a0e6a8c6f4f"
]


export const UOP_IDS = [
  'c1f9cd04-d592-4667-a606-ac2a05b74923',
  '5d14e80e-5a64-4248-ac69-ed6bf94fb544',
  '11ba7b04-1f57-42ff-9ccc-9355f319a981',
  '70e483ec-deee-4727-9499-348ba07b020d',
  '4806296d-8631-438c-bd2a-719491e90e90'
];

export const LECACY_PRODUCTS = [
  ...FOURG_SIM_NO_LEGACY,
  ...FOURG_UNLIMITED_IDS,
  ...UOP_IDS
]

export const NVIDIA_IDS = [
  '4e4a8a59-12a1-4f18-bcd5-e32f4b9222d7',
  'ccce762f-f3d5-4cdb-8102-88c2bd70b899',
  '45e497d9-f108-4c52-9b8f-f38345c0ee61',
  '99adb2a0-036c-44f7-99b1-a755d44eee75',
  'a7d390d2-beff-4ba8-9fe7-3061a2074bb3',
  '6d52eed9-1828-47f3-9015-4d23087eb6fd',
  'c1f8a970-68c8-4b59-abd4-544761de257f',
  '2170ea26-d0f3-46a1-a8a6-47d41810b36a',
  '99adb2a0-036c-44f7-99b1-a755d44eee75',
  'a7d390d2-beff-4ba8-9fe7-3061a2074bb3',
  '168d61bc-c992-4485-811b-371a99b086c8',
  'e95f57c2-9878-4206-986d-c54bfddea090'
]


export const RAIN_101_LEVELS: RainOneLevel[] = [
  {
    level: 0,
    id: '0',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '2 gigs | 60 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: null,
    cost: '',
    selected: false,
    name: 'rainOne 101a',
    includedInSelection: false,
    disclaimer: 'You get 2 free mobile SIMs with <span style="color:#0077c8;">rain<b>one</b></span>. Put them in your phones & enjoy FREE calls and data every month.'
  },
  {
    level: 1,
    id: '1',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '3 gigs | 90 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: '10',
    cost: '75',
    selected: false,
    name: 'rainOne 101a Level 1',
    includedInSelection: false
  },
  {
    level: 2,
    id: '2',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '4 gigs | 120 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: '15',
    cost: '150',
    selected: false,
    name: 'rainOne 101a Level 2',
    includedInSelection: false
  },
  {
    level: 3,
    id: '3',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '5 gigs | 150 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '20',
    cost: '225',
    selected: false,
    name: 'rainOne 101a Level 3',
    includedInSelection: false
  },
  {
    level: 4,
    id: '4',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '6 gigs | 180 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '25',
    cost: '300',
    selected: false,
    name: 'rainOne 101a Level 4',
    includedInSelection: false
  },
  {
    level: 5,
    id: '5',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '7 gigs | 210 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '25',
    cost: '375',
    selected: false,
    name: 'rainOne 101a Level 5',
    includedInSelection: false
  }
];

export const RAIN_101a_LEVELS: RainOneLevel[] = [
  {
    level: 0,
    id: '0',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '2 gigs | 60 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: null,
    cost: '',
    selected: false,
    name: 'rainOne 101a',
    includedInSelection: false,
    disclaimer: 'You get 2 free mobile SIMs with <span style="color:#0077c8;">rain<b>one</b></span>. Put them in your phones & enjoy FREE calls and data every month.'
  },
  {
    level: 1,
    id: '1',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '3 gigs | 90 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: '10',
    cost: '75',
    selected: false,
    name: 'rainOne 101a Level 1',
    includedInSelection: false
  },
  {
    level: 2,
    id: '2',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '4 gigs | 120 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: '15',
    cost: '150',
    selected: false,
    name: 'rainOne 101a Level 2',
    includedInSelection: false
  },
  {
    level: 3,
    id: '3',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '5 gigs | 150 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '20',
    cost: '225',
    selected: false,
    name: 'rainOne 101a Level 3',
    includedInSelection: false
  },
  {
    level: 4,
    id: '4',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '6 gigs | 180 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '25',
    cost: '300',
    selected: false,
    name: 'rainOne 101a Level 4',
    includedInSelection: false
  },
  {
    level: 5,
    id: '5',
    features: [
      {
        title: 'your',
        description: '2 mobile phone lines'
      },
      {
        title: 'each get',
        description: '7 gigs | 210 min | 100 sms',
        subText: 'every month'
      },
    ],
    multiplier: '25',
    cost: '375',
    selected: false,
    name: 'rainOne 101a Level 5',
    includedInSelection: false
  }
];


export const RAIN_101_SME_LEVELS: RainOneLevel[] = [
  {
    level: 0,
    id: '0',
    features: [
      {
        title: 'your',
        description: '5 mobile phone lines'
      },
      {
        title: 'each get',
        description: '2 gigs | 60 min | 100 sms',
        subText: 'every month'
      },

    ],
    multiplier: null,
    cost: '',
    selected: false,
    name: 'rainOne 101a work',
    includedInSelection: false,
    disclaimer: 'You get 5 free mobile SIMs with <span style="color:#0077c8;">rain<b>one work</b></span>. Put them in your phones & enjoy FREE calls and data every month.'
  },
  {
    level: 1,
    id: '1',
    features: [
      {
        title: 'your',
        description: '5 mobile phone lines'
      },
      {
        title: 'each get',
        description: '3 gigs | 90 min | 100 sms',
        subText: 'every month'
      },

    ],
    cost: '150',
    selected: false,
    name: 'rainOne 101a work Level 1',
    includedInSelection: false
  },
  {
    level: 2,
    id: '2',
    features: [
      {
        title: 'your',
        description: '5 mobile phone lines'
      },
      {
        title: 'each get',
        description: '4 gigs | 120 min | 100 sms',
        subText: 'every month'
      },

    ],
    cost: '300',
    selected: false,
    name: 'rainOne 101a work Level 2',
    includedInSelection: false
  },
  {
    level: 3,
    id: '3',
    features: [
      {
        title: 'your',
        description: '5 mobile phone lines'
      },
      {
        title: 'each get',
        description: '5 gigs | 150 min | 100 sms',
        subText: 'every month'
      },
    ],
    cost: '450',
    selected: false,
    name: 'rainOne 101a work Level 3',
    includedInSelection: false
  },
  {
    level: 4,
    id: '4',
    features: [
      {
        title: 'your',
        description: '5 mobile phone lines'
      },
      {
        title: 'each get',
        description: '6 gigs | 180 min | 100 sms',
        subText: 'every month'
      },
    ],
    cost: '600',
    selected: false,
    name: 'rainOne 101a work Level 4',
    includedInSelection: false
  },
  {
    level: 5,
    id: '5',
    features: [
      {
        title: 'your',
        description: '5 mobile phone lines'
      },
      {
        title: 'each get',
        description: '7 gigs | 210 min | 100 sms',
        subText: 'every month'
      },
    ],
    cost: '750',
    selected: false,
    name: 'rainOne 101a work Level 5',
    includedInSelection: false
  }
];

export const RAINONE_101_5G_SIM = [
  "35c3634f-9d32-4208-9814-268cc1bfcda5",
  "83ee47aa-c408-4ab6-bc3c-ed887c973b0a",
  "7f73d95c-065a-4917-884b-c456351358a1",
  "7fbb6954-5310-43aa-a1f6-54587055e6f2",
  "dcbc6f7b-2370-404c-a336-268c35825ea6",
  "46e38bc2-0f25-4da0-9f28-ff79d5701c7f",
  "8a54367e-0be5-400e-b407-53473c4db377",
  "ff651094-a74d-4eb4-840d-963c7df1b83d",
  "c2aa4c93-7803-441e-a4f6-73e708c361dd",
  "f1c78016-d11f-47c2-9bf8-4e3b4c14d770",
  "be78730e-c30e-450f-8730-0641baecc8c9",
  "b5e58969-54cf-4355-bf22-d4b205e26b81",
  "b8cda5c7-ab31-4d85-95fe-7e7b7ba09706",
  "2406ba41-a633-4a4a-951f-5f1635c49185",
  "2e2c2c3e-ba79-4a49-8f14-9c236bb4de42",
  "5bbe55f6-bfca-44b6-8973-e7716288f2eb",
  "963bd067-5cd5-4a04-b07d-f288ecdd0bb5",
  "aa23658e-d9a9-41dc-9be8-8a336f413e1f",
  "abcc87fd-0ed5-4645-9ca5-20c2a1030869",
  "0694bac4-ada9-4167-9906-002f75126216",
  "5cf9b219-9afd-41c2-b76a-7c799c171c25",
  "4805437b-42e9-444e-a186-65a0daf7b89b",
  "d757af11-4816-4533-8caa-6dbac2a70b9b",
  "94c2fbf3-b8f9-4143-9057-62c0f378b0a7",
  "62c8754b-5843-4629-b3a3-fa88b402fdf5",
  "559d1fb2-ef51-4257-ab12-6a378cca6da1",
  "d6ff2d3c-8339-4c7d-a596-07a460aaefce",
  "135992f9-df28-4713-9345-319b039ac61e",
  "4e04a867-83da-42fc-91d7-2974450ff2ac",
  "41d0ac29-9346-43ee-adde-b386a56c1a0c",
  "95bb64e1-ce50-40a2-914b-e44691e90af9",
  "e5ac2957-437f-44e9-89cf-e469014adbc2",
  "bb4122c1-7969-45d6-9c8b-99e6fc7624dc",
  "7030454f-c7e3-4a92-a6de-c5940aa39313",
  "17ccd792-bdcb-4109-85b9-b2610a805d38",
  "4ee50e3e-bb82-4b98-bfc1-ff6a39ca7e09",
  "a2214af8-5828-4ee4-809d-1077d7f9826a",
]


export const FOUR_G_MOBILE_IDS = [
  "1174f6df-0dbd-4ad7-ad9f-0da20ce866fb",
  "65420718-0ca6-41e0-ad3b-bc60b1c685f4",
  "df4fc235-0a2c-4d1e-9867-3149100cced9",
  "902248dd-f0be-4c1f-9893-4b919a234625",
  "6cf4e840-00e3-4f8c-a0a0-d3baacc95fac"
]

export const RAINONE_SME_IDS = [
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "9f7f2015-052e-4361-acf0-df8697fd428d",
  "5f99f184-c399-455e-9101-9b8e81944daf",
  "d6358eb1-5937-492d-a5bf-a63eff24f1e8",
  "2107c954-ffdc-4e6c-98d7-b32e778525ab",
  "10d10817-d6ea-4d2b-84a7-723b5d3576b4",
  "f1c82a57-c732-46c0-857b-9fe9ac30dcc7",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",

  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",

  "5f99f184-c399-455e-9101-9b8e81944daf",
  "9f7f2015-052e-4361-acf0-df8697fd428d",
  "d6358eb1-5937-492d-a5bf-a63eff24f1e8",
  "2107c954-ffdc-4e6c-98d7-b32e778525ab",
  "10d10817-d6ea-4d2b-84a7-723b5d3576b4",
  "f1c82a57-c732-46c0-857b-9fe9ac30dcc7",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",

  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",

  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",

  "d6358eb1-5937-492d-a5bf-a63eff24f1e8",
  "9f7f2015-052e-4361-acf0-df8697fd428d",
  "5f99f184-c399-455e-9101-9b8e81944daf",
  "2107c954-ffdc-4e6c-98d7-b32e778525ab",
  "10d10817-d6ea-4d2b-84a7-723b5d3576b4",
  "f1c82a57-c732-46c0-857b-9fe9ac30dcc7",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",

  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",

  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",

  "2107c954-ffdc-4e6c-98d7-b32e778525ab",
  "9f7f2015-052e-4361-acf0-df8697fd428d",
  "5f99f184-c399-455e-9101-9b8e81944daf",
  "d6358eb1-5937-492d-a5bf-a63eff24f1e8",
  "10d10817-d6ea-4d2b-84a7-723b5d3576b4",
  "f1c82a57-c732-46c0-857b-9fe9ac30dcc7",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",

  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",

  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",

  "10d10817-d6ea-4d2b-84a7-723b5d3576b4",
  "9f7f2015-052e-4361-acf0-df8697fd428d",
  "5f99f184-c399-455e-9101-9b8e81944daf",
  "d6358eb1-5937-492d-a5bf-a63eff24f1e8",
  "2107c954-ffdc-4e6c-98d7-b32e778525ab",
  "f1c82a57-c732-46c0-857b-9fe9ac30dcc7",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",

  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",

  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",

  "f1c82a57-c732-46c0-857b-9fe9ac30dcc7",
  "9f7f2015-052e-4361-acf0-df8697fd428d",
  "5f99f184-c399-455e-9101-9b8e81944daf",
  "d6358eb1-5937-492d-a5bf-a63eff24f1e8",
  "2107c954-ffdc-4e6c-98d7-b32e778525ab",
  "10d10817-d6ea-4d2b-84a7-723b5d3576b4",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "5524501f-ebbf-4b5c-bac0-ff18024f7a21",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "edbe57ef-0877-4642-8d64-ea25b34c7627",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "261541a5-b599-4698-9ae5-b9434b842198",
  "1e01fcc2-ad61-48c5-af76-18c09f51dc53",
  "93c97b07-e12d-42ef-a59a-3bb7c39cf592",
  "34619c4c-4386-4bc9-8a00-43d7e0feb803",
  "15ab20bd-4396-41e9-9976-6f16cbbc59ec",
  "2b607bd0-a492-440d-9f8e-121fbc170071",
  "465d11eb-2028-4cbc-8d62-d5250f2b14da",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "9f7f2015-052e-4361-acf0-df8697fd428d", ,
  "261541a5-b599-4698-9ae5-b9434b842198",
  "c2aa4c93-7803-441e-a4f6-73e708c361dd",
  "24779028-ecc1-4b9c-bf20-52817628742b",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "5f99f184-c399-455e-9101-9b8e81944daf",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "1e01fcc2-ad61-48c5-af76-18c09f51dc53",
  "c2aa4c93-7803-441e-a4f6-73e708c361dd",
  "dfba1014-a788-4f3d-ae06-fe8bbf9ba3e8",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "d6358eb1-5937-492d-a5bf-a63eff24f1e8",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "93c97b07-e12d-42ef-a59a-3bb7c39cf592",
  "c2aa4c93-7803-441e-a4f6-73e708c361dd",
  "8310d7e4-1b5d-4f19-a25d-1ed62308076a",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "2107c954-ffdc-4e6c-98d7-b32e778525ab",
  "edbe57ef-0877-4642-8d64-ea25b34c7627",
  "34619c4c-4386-4bc9-8a00-43d7e0feb803",
  "c2aa4c93-7803-441e-a4f6-73e708c361dd",
  "0bbea895-171c-4d3b-b55a-35c44d9b54a3",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "10d10817-d6ea-4d2b-84a7-723b5d3576b4",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "15ab20bd-4396-41e9-9976-6f16cbbc59ec",
  "c2aa4c93-7803-441e-a4f6-73e708c361dd",
  "b627d5b5-f08e-4238-8991-7e397e592796",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "f1c82a57-c732-46c0-857b-9fe9ac30dcc7",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "2b607bd0-a492-440d-9f8e-121fbc170071",
  "c2aa4c93-7803-441e-a4f6-73e708c361dd",
  "dddabbd9-93a9-467a-8f2d-6ca395346b7f",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "9f7f2015-052e-4361-acf0-df8697fd428d",
  "5524501f-ebbf-4b5c-bac0-ff18024f7a21",
  "261541a5-b599-4698-9ae5-b9434b842198",
  "c2912335-b41b-4e79-b6e6-e2dc4a7e14f9",
  "e9b92819-0ee7-496b-9e5b-aa87288ef3f7",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "5f99f184-c399-455e-9101-9b8e81944daf",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "2aa5ab8b-db48-4c13-ad15-081869465548",
  "1e01fcc2-ad61-48c5-af76-18c09f51dc53",
  "c2912335-b41b-4e79-b6e6-e2dc4a7e14f9",
  "d23218a2-b8ba-4591-bbd6-3dc7f64dac13",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "d6358eb1-5937-492d-a5bf-a63eff24f1e8",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "4a4a49e0-780b-4a26-9779-cc74df83f3b7",
  "93c97b07-e12d-42ef-a59a-3bb7c39cf592",
  "c2912335-b41b-4e79-b6e6-e2dc4a7e14f9",
  "e4f053ed-0cd8-48d0-b8f7-19d651acab14",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "2107c954-ffdc-4e6c-98d7-b32e778525ab",
  "edbe57ef-0877-4642-8d64-ea25b34c7627",
  "34619c4c-4386-4bc9-8a00-43d7e0feb803",
  "c2912335-b41b-4e79-b6e6-e2dc4a7e14f9",
  "05c42f51-f65f-4fd3-9d0e-34c57725072d",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "10d10817-d6ea-4d2b-84a7-723b5d3576b4",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "787fb7b3-0310-4e94-9fac-618144591380",
  "15ab20bd-4396-41e9-9976-6f16cbbc59ec",
  "c2912335-b41b-4e79-b6e6-e2dc4a7e14f9",
  "694089a2-c7e9-4015-aa19-1aedd9b7dc14",
  "627e0a1a-a42d-4ea4-a49d-916e714aae34",
  "d04ae4f2-cec3-438d-96cb-8d99d8d76568",
  "95e89ca9-5f42-4232-ad6c-93844e1d7e91",
  "f1c82a57-c732-46c0-857b-9fe9ac30dcc7",
  "a0b86c4a-324d-49b1-964b-bf79b7325288",
  "2b607bd0-a492-440d-9f8e-121fbc170071",
  "c2912335-b41b-4e79-b6e6-e2dc4a7e14f9",
]
export const NVIDIA_ASSETS_QUERY = `
  {
      apps(country: "US", language: "en_US", after: "") {
          numberReturned
          pageInfo {
              endCursor
              hasNextPage
          }
          items {
              id
              title
              sortName
              genres
              publisherName
              developerName
              supportedControls
              shortDescription
              longDescription
              maxLocalPlayers
              maxOnlinePlayers
              images {
                  KEY_ART
              }
              externalMappings {
                  id
                  system
              }
              variants {
                  id
                  appStore
                  gfn {
                      releaseDate
                      features {
                          ... on GfnSubscriptionFeatureValueList {
                              key
                              values
                          }
                          ... on GfnSubscriptionFeatureValue {
                              key
                              value
                          }
                      }
                  }
              }
          }
      }
  }
  `;

export const ARTSKINS = [
  "Kristen McClarty",
  "Chelsea Young",
  "Khwezi Strydom",
  "Maja Maljevic",
  "Pamela Sana King Mavuso",
  "Zolile Petshane",
]

export const PROSKINS = [
  "Paul Blomkamp",
  "Bevan de Wet",
  "Bontle Juku",
  "Shell Pink",
  "Blackberry",
  "Beach",
]

export const EXTENDERSKINS = [
  "Shell Pink",
  "Bontle Juku",
  "Bevan de Wet",
  "Sage",
  "Blackberry",
  "Beach",
]

export const FOURG_STANDALONE = "6cf4e840-00e3-4f8c-a0a0-d3baacc95fac";

export const RAIN_MOBILE_DISPLAY_NAME = "rain mobile";

export const PRIMARY_5G_DUO_PRODUCT_ID = "89dd4305-8f41-4d86-a097-416e1046e865";

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const SIM_SWAP_ADDRESS_FORM_FIELDS = [
  { name: 'streetNumber', label: 'Street Number', required: true },
  { name: 'streetName', label: 'Street Name', required: true },
  { name: 'suburb', label: 'Suburb', required: true },
  { name: 'city', label: 'City', required: true },
  { name: 'postalCode', label: 'Postal Code', required: true },
  { name: 'province', label: 'Province', required: true },
  { name: 'complexName', label: 'Complex name or business name', required: false },
  { name: 'aptFlatFloor', label: 'Apt, flat or floor number (optional)', required: false },
  { name: 'specialNotes', label: 'Special delivery notes (optional)', required: false },
  { name: 'address', label: 'address', required: false },
  { name: 'number', label: 'Phone Number', required: true  }
];

export const SIM_SWAP_ADDRESS_DEFAULTS = {
  streetNumber: "",
  streetName: "",
  suburb: "",
  city: "",
  postalCode: "",
  province: "", 
  complexName: "",
  aptFlatFloor: "",
  specialNotes: "",
}

export const SIM_SWAP_SIM_FORM_FIELDS = [
  { name: 'number', label: 'Phone Number', required: true },
  { name: 'comment', label: 'comment', required: true },
];

export const PHONE_NUMBER_VALIDATION_MESSAGES: ValidationErrors = {
  required: 'Phone number is required.',
  pattern: 'Please enter a valid phone number starting with "0"'
};

export const SIM_SWAP_ADDRESS_OMISSION_KEYS = ["specialNotes", "address"];

export const SIM_SWAP_BOTTOM_SHEET_MESSAGES = {
  comment: {
    message: "We'll contact you as soon as an agent becomes available."
  },
  delivery: {
    message: "Your SIM will delivered within 3-5 working days."
  },
  collect: {
    message: "Your SIM is waiting at your chosen store."
  }
}

export const SIM_SWAP_OPTIONS = [
  {value: 'delivery'},
  {value: 'collect'},
]

export const SIM_SWAP_SUBJECT_LINES = {
  1: 'SIM is not working',
  2: 'SIM is lost'
}

export const SOUTH_AFRICAN_NUMBER_REGEX = /^(\+27|0)[6-8][0-9]{8}$/;
export const SOUTH_AFRICAN_NUMBER_REGEX_NO_PREFIX = /^[0-9]{9}$/;

export const TICKET_QUEUES = {
  "CEC.TEAM.A": {
    isLegacy: true,
    subject: Object.values(SIM_SWAP_SUBJECT_LINES),
    "ticket_type_id": 94
  },
  "CEC.VOICE.A": {
    isLegacy: false,
    subject: [SIM_SWAP_SUBJECT_LINES[1]],
    "ticket_type_id": 131
  },
  "CEC.VOICESIMSWAP.A": {
    isLegacy: false,
    subject: [SIM_SWAP_SUBJECT_LINES[2]],
    "ticket_type_id": 133
  },
}

export const CACHE_SIM_SWOP_REQUEST = "simswop";

export const ASK_BOT_QUESTION = {
  coverageCheck: "I need to check if my address has 5G coverage for wifi",
  internationalDialling: "I want to make a call or send an SMS to an international number. I need to know how much it will cost and what the prefix is to contact the international number.",
  storeLocator: "Please give me a list of all of the physical rain stores",
  orders: "I need a list of my orders.",
  requestOlderInvoices:"I need my older invoices"
};
