import { AfterContentInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RainFlags } from 'src/app/core/interfaces/rain-flags.interface';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';

@Component({
  selector: 'rain-rain-one-modal-video',
  templateUrl: './rain-one-modal-video.component.html',
  styleUrls: ['./rain-one-modal-video.component.scss']
})
export class RainOneModalVideoComponent implements OnInit {
  @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
  @ViewChild('videoPlayerOne', { static: true}) videoPlayerOne: ElementRef<HTMLVideoElement>;

  constructor( public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
      this.videoPlayerOne.nativeElement.volume = 0.5;
      this.videoPlayerOne.nativeElement.play().then();
  }
}
