import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Position } from 'geojson';
import { isNil } from 'lodash';
import { of, Observable } from 'rxjs';

/**
 * $ @param {any}
 */
declare var $: any;

/**
 * SCROLL_KEY @param
 */
const SCROLL_KEY = 'scroll_key';

@Injectable({
  providedIn: 'root'
})
export class ElementService {
  constructor(private router: Router) {}

  getCurrentLatLng(): Observable<Position> {
    if ('geolocation' in navigator) {
      return Observable.create(obs => {
        navigator.geolocation.getCurrentPosition(position => {
          obs.next(position);
        });
      });
    } else {
      return of(null);
    }
  }

  scrollToShop = (offset: number = 5) => {
    $('body').addClass('home ');
    this.scrollToElement('/', '#shop', -100, 1000);
  };

  scrollToButtons = (offset: number = 5) => {
    this.scrollToElement('/', '#banner-buttons', -100, 1000);
  };
  scrollToFiveG = (offset: number = 5) => {
    this.scrollToElement('/', '#fiveg', offset, 1000);
  };

  scrollDown = () => {
    const position = document.documentElement.scrollTop;
    const offset = position - 40;
    this.scrollToElement('/', '#fiveg', offset, 1000);
  };

  slideToEsimIntro = () => {
    this.scrollToElement('/e-sim', '#about-e-sim', 10, 1000);
  };

  slideToEsimShop = () => {
    this.scrollToElement('/e-sim', '#e-sim-products', 10, 1000);
  };

  scrollToFiveGNotify = () => {
    this.scrollToElement('/', '#5g-notify', 10, 1000);
  };

  scrollToPayDates = () => {
    this.scrollToElement('/my-account', '#payDate', -60, 1000);
  };

  scrollToMigration = () => {
    $('body').addClass('home ');
    this.scrollToElement('/service', '#migrate', -60, 1000);
  };

  scrollToLinkedCustomers = () => {
    this.scrollToElement('/my-account', '#linked-customers', -60, 1000);
  };

  scrollToLegalPdfViewer = () => {
    this.scrollToElement('/legal', '#pdfViewer', -60, 1000);
  };

  scrollToLegalData = () => {
    this.scrollToElement('/legal', '#dataForm', -60, 1000);
  };

  scrollToOptIn = () => {
    this.scrollToElement('/notification', '#opt-in', 10, 1000);
  };

  scrollToNvidiaOptionns = (offset: number = -150) => {
    this.scrollToElement('/nvidia', '#tiers', offset, 1000);
  };
  

  scrollToHome = () => {
    this.router.navigateByUrl('/').then(
      () => {
        $('body').addClass('home');
        setTimeout(() => {
          $('html, body').animate({ scrollTop: 0 }, 500);
        }, 500);
      },
      error => {
        console.log('ERROR: ' + error);
      }
    );
  };

  scrollToQuickLinks = (offset: number = 5) => {
    this.scrollToElement('/', '#quicklinks', -100, 1000);
  };
  scrollToFaq = (offset: number = 5) => {
    this.scrollToElement('/', '#faq', -100, 1000);
  };

  scrollToElement = (url: string, selector: string, offset: number = 200, speed: number = 500) => {
    const toElement = $(selector);

    localStorage.setItem(SCROLL_KEY, selector);

    const focusElement = $(selector);

    if (toElement.length == 0) {
      this.router.navigateByUrl(url).then(
        () => {
          setTimeout(() => {
            let elName = localStorage.getItem(SCROLL_KEY);

            if (isNil(elName) === false) {
              $('html, body').animate(
                {
                  scrollTop: $(elName)?.offset()?.top + offset
                },
                speed
              );

              localStorage.removeItem(SCROLL_KEY);
            }
          }, 500);
        },
        error => {
          console.log('ERROR: ' + error);
        }
      );
    } else {
      $('html, body').animate(
        {
          scrollTop: toElement.offset().top + offset
        },
        speed
      );

      localStorage.removeItem(SCROLL_KEY);
    }

    if (focusElement) {
      $(focusElement).focus();
    }
  };
}
