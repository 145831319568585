import { BillingStoreService } from './../../store/billing-store.service';
import { CACHE_IDM_TOKEN } from './../../models/constants';
import { TokenService } from './../../services/token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from '@models/result';
import { AuthenticationService } from '@services/auth.service';
import { Observable, of } from 'rxjs';
import { ConfigService } from '@services/config.service';
import { map } from 'rxjs/operators';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class PayDateService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private tokenService: TokenService,
    private configService: ConfigService,
    private billingStore: BillingStoreService
  ) { }

  public getPaymentDates(): Observable<any> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
    const requestPath = `${this.configService.AXIOM_IDM_URL}/possiblepaymentdates`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + idmToken

      })
    };
    return this.http.get(requestPath, httpOptions);
  }

  public getCurrentPaymentDate() {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
    const requestPath = `${this.configService.AXIOM_IDM_URL}/recurringpaymentdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + idmToken
      })
    };
    return this.http.get(requestPath, httpOptions).pipe(

      map((res: any) => {
        if (res && res.value) {
          this.billingStore.paydate = +res.value;
          this.billingStore.displayDate = moment(this.billingStore.paydate, 'DD').format('Do');
        } else {
          this.billingStore.paydate = 1;
          this.billingStore.displayDate = '1st';
        }
        return res;
      })
    );
  }

  public updatePaymentDate(date: any, token?: string) {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }
    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
    const requestPath = `${this.configService.AXIOM_IDM_URL}/addpaymentdate?recurringPaymentDate=${date}&futurePaymentDate=${date}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (idmToken)
      })
    };
    return this.http.put(requestPath, '', httpOptions);
  }
}
