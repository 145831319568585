import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { TokenService } from './token.service';
import { CACHE_AUTH_TOKEN } from '@models/constants';
import { forkJoin, Observable, throwError } from 'rxjs';
import {
  PortInCreateReqRemote,
  IportInCreateRes,
  GetPortResponse,
  PortOutBody
} from '@pages/service-page/rain-one-actions/port-my-number/port-in.interface';
import { map, tap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortPendingModalComponent } from '@pages/service-page/rain-one-actions/port-my-number/port-in-modals/port-pending-modal/port-pending-modal.component';
import { RainNumberFailureModalComponent } from '@pages/service-page/rain-one-actions/port-my-number/port-in-modals/rain-number-failure-modal/rain-number-failure-modal.component';
import { Select, Store } from '@ngxs/store';
import { PortSimPending } from '../store/actions/number-porting-actions';
import { UIState } from '../shared/store/state/ui.state';
import { AuthState } from '../core/store/state/auth.state';
import { ShowBottomSheet } from '../v2/store/actions/bottom-sheet.actions';
import { CoreState } from '../core/store/state/core.state';
import { CancellationFailureModalComponent } from '../v2/pages/porting/number-porting-v2/modals/cancellation-failure-modal/cancellation-failure-modal.component';
import { CancellationSuccessModalComponent } from '../v2/pages/porting/number-porting-v2/modals/cancellation-success-modal/cancellation-success-modal.component';
import { ServiceObject } from '@models/serviceDetail';


@Injectable({
  providedIn: 'root'
})
export class NumberPortingService {
  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;

  constructor(
    private _http: HttpClient,
    private tokenSVC: TokenService,
    private configService: ConfigService,
    private ngModal: NgbModal,
    public activeModal: NgbActiveModal,
    private store: Store
  ) { }

  portInByMsisdn(rain_msisdn: string): Observable<PortInCreateReqRemote> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${rain_msisdn}/number-swap`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post<PortInCreateReqRemote>(url, httpOptions);
  }

  //port-in welcome message
  portInWelcomeMessage(id: string) {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}/send-welcome-message`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post(url, httpOptions);
  }
  //cancel number port in using id
  reversePortById(id: string): Observable<GetPortResponse> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}/cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post<GetPortResponse>(url, httpOptions);
  }
  //cancel number port in using id

  cancelPortInById(id: string): Observable<GetPortResponse> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}/cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };
    const portCancelation = {
      reason: 'Cancel Port In Request',
      ported_in_error: true
    };
    return this._http.post<GetPortResponse>(url, portCancelation, httpOptions).pipe(
      tap({
        next: () => this.handleCancellationSuccess(),
        error: (e) => this.handleCancellationError(e),
      })
    );
  }

  private handleCancellationSuccess(): void {
    this.activeBreakpoint$.subscribe(res => {
      if (res !== 'desktop') {
        this.store.dispatch(new ShowBottomSheet("port_cancellation_success"))
      } else {
        this.activeModal.close();
        this.ngModal.open(CancellationSuccessModalComponent, {
          centered: true,
          size: 'sm',
          windowClass: 'slideInUp d-flex'
        });
      }
    });
  }

  private handleCancellationError(error: any): void {
    this.activeBreakpoint$.subscribe(res => {
      if (res !== 'desktop') {
        this.store.dispatch(new ShowBottomSheet("port_cancellation_failure"));
      } else {
        this.activeModal.close();
        this.ngModal.open(CancellationFailureModalComponent, {
          centered: true,
          size: 'sm',
          windowClass: 'slideInUp d-flex'
        });
      }
    });
    throwError(error);
  }

  //validate port in OTP
  validatePortInOtp() {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/validate-otp`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post(url, httpOptions);
  }

  createPortInRequest(body: PortInCreateReqRemote): Observable<PortInCreateReqRemote> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/create-request`;

    const uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    const smeToken = this.store.selectSnapshot(AuthState.getSmeToken);
    const userToken = (uiMode !== 'consumer' && smeToken) ? smeToken : this.tokenSVC.get(CACHE_AUTH_TOKEN);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      })
    };

    return this._http.post<PortInCreateReqRemote>(url, body, httpOptions).pipe(
      tap({
        next: () => this.handleSuccess(body),
        error: (e) => this.handleError(e)
      })
    );
  }

  private handleSuccess(body: PortInCreateReqRemote): void {
    this.activeBreakpoint$.subscribe(res => {
      if (res !== 'desktop') {
        this.store.dispatch(new ShowBottomSheet('number_porting_success'));
      } else {
        this.activeModal.close();
        const modalRef = this.ngModal.open(PortPendingModalComponent, {
          centered: true,
          size: 'sm',
          windowClass: 'slideInUp d-flex'
        });
        this.store.dispatch(new PortSimPending(true));
        modalRef.componentInstance.id = body?.service_id;
      }
    });
  }

  private handleError(error: any): void {
    this.activeBreakpoint$.subscribe(res => {
      if (res !== 'desktop') {
        this.store.dispatch(new ShowBottomSheet('number_porting_failure'));
      } else {
        this.ngModal.open(RainNumberFailureModalComponent, {
          centered: true,
          size: 'sm',
          windowClass: 'slideInUp d-flex'
        });
      }
    });
    throwError(error);
  }


  getCurrentPortStatusByServiceId(id: string): Observable<GetPortResponse> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}/service`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<GetPortResponse>(url, httpOptions);
  }

  getCurrentPortStatusById(id: string): Observable<GetPortResponse> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<GetPortResponse>(url, httpOptions);
  }

  getPortInRainMsisdn(): Observable<IportInCreateRes> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/search`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<IportInCreateRes>(url, httpOptions);
  }
  portOutRequest(body: PortOutBody): Observable<PortOutBody> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-out/validate-otp`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };
    return this._http.post<PortOutBody>(url, body, httpOptions);
  }


  getPortOutById(id: string): Observable<any> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-out/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<any>(url, httpOptions)
  }

  public getAllServicesPortStatuses(services: ServiceObject[]) {
    const observables = services?.map(service => this.getCurrentPortStatusByServiceId(service?.id).pipe(map(res => {
      return {
        ...res,
        serviceId: service?.id
      }
    })));
    return forkJoin(observables).pipe(
      map(res => {
        const formattedPortResponses: Record<string, any> = {};
        res.forEach((portStatusObject: any) => {
          formattedPortResponses[portStatusObject?.serviceId] = portStatusObject?.result?.port_response?.status;
        })
        return formattedPortResponses
      })
    )
  }
}
