import { ScrollDownModule } from '@components/scroll-down/scroll-down.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageRoutingModule } from '@pages/home/home-page-routing';
import { ProductNavComponent } from './product-nav.component';



@NgModule({
    declarations: [
        ProductNavComponent
    ],
    imports: [
        CommonModule,
        HomePageRoutingModule
       
    ],
    exports: [ProductNavComponent],
    providers: [],
})


export class ProductNavModule { }
