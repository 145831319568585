<div class="modal-header border-0">
    <div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
            data-dismiss="modal" data-target="#thankYouModal" src="../../assets/svg/close.svg" />
    </div>
</div>

<div class="modal-body">
    <div class="text-align-left justify-content-center header-wrapper">
        <div class="header-description text-primary text-align-left mb-8">
            claim your <span class="font-weight-bold">FREE</span> rain<span class="font-weight-bold">one</span> upgrade!
        </div>

        <div class="text-description mb-10 text-align-left">
            <div>
                Please leave your details below and one of our agents will get in touch shortly.
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <form [formGroup]="upgradeContactForm" class="desk-60">
            <!-- name -->
            <div class="form-group">
                <rain-rain-input-sales-modal maxlength="24" [showLabel]="false" placeholder="Name" label="Name"
                    [control]="upgradeContactForm.get('name')" [type]="'text'" [messages]="nameValidationMessages">
                </rain-rain-input-sales-modal>
            </div>

            <!-- email -->
            <div class="form-group">
                <rain-rain-input-sales-modal label="email address" [showLabel]="false" placeholder="Email address"
                    [control]="upgradeContactForm.get('email')" [type]="'email'" [messages]="emailValidationMessages">
                </rain-rain-input-sales-modal>
            </div>

            <!-- cellNumber -->
            <div class="form-group">
                <rain-rain-input-sales-modal label="contact number" [showLabel]="false" placeholder="Contact number"
                    [control]="upgradeContactForm.get('number')" [type]="'tel'"
                    [messages]="cellNumberValidationMessages">
                </rain-rain-input-sales-modal>
            </div>

            <div class="d-flex justify-content-start mb-10">
                <label class="accept" [ngClass]="{'text-primary': whatsAppOptInCheck}"><span
                    class="opt-in-text">Opt in for WhatsApp for account updates and new product information<img class="ml-2 img-fluid"
                       width="20px" /></span>
                  <input type="checkbox" [checked]="whatsAppOptInCheck" (click)="whatsAppOptIn()">
                  <span class="checkmark"></span>
                </label>
            </div>
            <div class="d-flex justify-content-end my-5">
                <button [disabled]="upgradeContactForm.invalid || loading"
                    [className]="(upgradeContactForm.invalid ? ' btn-tertiary' : ' btn-primary text-white') + ' btn btn-md'"
                    (click)="submit()">
                    submit
                    <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
        </form>

    </div>
    <div class="text-disclaimer desk-60-top">
        By requesting a sales call, you consent to the call being recorded for quality purposes. This site is protected
        by reCAPTCHA and the Google <a href="/legal?section=policies" target="_blank"
            class="text-primary text-decoration-underline">Privacy Policy</a> and <a href="/legal?section=terms"
            target="_blank" class="text-primary text-decoration-underline">Terms of Service</a> apply.
    </div>
</div>
