import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-thank-survey',
  templateUrl: './thank-survey.component.html',
  styleUrls: ['./thank-survey.component.scss']
})
export class ThankSurveyComponent implements OnInit, OnDestroy {
  @Output() accepted: EventEmitter<any> = new EventEmitter();
  billingDate: Date;
  simName: any;
  @Input()
  type: string;
  isMobileScreen: boolean;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.isMobileScreen = window.innerWidth <= 500;
    this.dateGenerator();
  }

  show(simName) {
    this.simName = simName;

  }

  dateGenerator() {
    const date = new Date();
    this.billingDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  }

  ngOnDestroy(): void {
    this.accepted.next(true);
  }

}
