<div class="rain-101-sticky-nav">

    <div class="sticky-nav-grid">
        <div class="corner-left"></div>
        <ng-content select="[Title]"></ng-content>
        <ng-content select="[ButtonBlue]"></ng-content>
        <ng-content select="[ButtonBlueB]"></ng-content>
        <div class="corner-right"></div>
    </div>

</div>