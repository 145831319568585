import { IRemoteProduct, IRemoteProductItem, IRemoteProductAddon } from './remote/remoteProduct';
import { isNil, isEmpty, startCase, toLower, get, some } from 'lodash';
import { ModelHelper } from './modelHelper';
import moment from 'moment';
import { IDictionary } from './dictionary';
import { RICA_TYPE, RICA_TYPE_COURIER, RICA_TYPE_E_RICA, CONFIG_TARIF, DEFAULT_TARRIF } from './constants';

export enum ProductTypes {
  None = 0,
  Addon,
  Product,
  Variant,
  Bundle,
  ServiceFee,
  Delivery,
  Nvidia
}

export enum BillingTypes {
  None = 0,
  PerMonth,
  OnceOff,
  PerGig,
  PerMonthUpFront
}

export enum ProductCategories {
  None = 0,
  FiveG,
  FourG,
  Device,
  ServiceFee,
  RainMaker
}

export class ProductDetail {

  static getTariff(serviceProduct: ProductDetail): number {
    return parseInt(ProductDetail.getConfigValue(serviceProduct, CONFIG_TARIF, DEFAULT_TARRIF.toString()), 10)
  }

  static getConfigValue(obj: any, configName: string, defaultValue: any = null) {

    return get(obj, `config.${configName}.value`, defaultValue);
  }

  static containsConfig(obj: any, configName: string) {

    return isNil(get(obj, `config.${configName}`, null)) == false
  }

  static hasErica(product: IProductDetail, products: IProductDetail[]): boolean {

    const func = (obj: any) => ProductDetail.getConfigValue(obj, RICA_TYPE, RICA_TYPE_COURIER) == RICA_TYPE_E_RICA;

    if(product) {
      const productHasErica = func(product);

      if (product?.type == ProductTypes.Bundle && isEmpty(product.items) == false) {
  
        const itemProducts = product.items.map(x => products.find(y => y.id == x.id))
          .filter(x => isNil(x) == false);
  
        const itemsHasErica = some(itemProducts, itemProduct => func(itemProduct));
  
        return productHasErica || itemsHasErica;
      }
      return productHasErica;
    }

  }

  static adapt(remote: IRemoteProduct): IProductDetail {
    let type = ProductDetail.mapType(remote.type);

    const category = this.mapCategory(remote.category);

    const addons = this.mapAddons(remote.addons);

    return {
      id: ModelHelper.clean(remote.id),
      baseId: ModelHelper.clean(remote.baseId),
      name: ModelHelper.clean(remote.name),
      activeFrom: isNil(remote.activeFrom) ? null : moment.unix(remote.activeFrom).toDate(),
      activeTo: isNil(remote.activeTo) ? null : moment.unix(remote.activeTo).toDate(),
      currency: remote.currency,
      image: remote.image,
      cartImage: remote.cartImage,
      migrationImage: remote.migrationImage,
      migrationCornerImage: remote.migrationCornerImage,
      price: remote.price,
      sku: remote.sku,
      addons: addons,
      scheduledActionName: ModelHelper.clean(remote.scheduledActionName),
      billingType: ProductDetail.mapBillingType(remote.billingType),
      description: ModelHelper.clean(remote.description),
      silentSalesmanDescription: ModelHelper.clean(remote.silentSalesmanDescription),
      shortDescription: ModelHelper.clean(remote.shortDescription),
      config: isNil(remote.config) ? {} : remote.config,
      formattedPrice: this.mapPrice(remote.currency, remote.price),
      formattedBillingType: this.formatBillingType(remote.billingType, remote.price),
      productModalImage: remote.productModalImage,
      featured: remote.featured,
      items: this.mapItems(remote.items),
      category,
      available: isNil(remote.available) ? true : remote.available,
      type,
      cornerImage: remote.cornerImage,
      tag: ModelHelper.clean(remote.tag),
      available_balance: remote.available_balance || null,
      available_volume: remote.available_volume || null,
      bundle_expiry: remote.bundle_expiry || null,
      last_topup_date: remote.last_topup_date || null,
      last_topup_volume: remote.last_topup_volume || null,
      total_topup_volume: remote.total_topup_volume || null,
      total_usage: remote.total_usage || null,
      grandfather:remote?.grandfather,
      grandchild: remote?.grandchild,
      paymentType:remote?.paymentType
    } as IProductDetail;

  }

  private static mapAddons(addons: IRemoteProductAddon[]) {

    if (isNil(addons)) {
      return [];
    }

    return addons.map(remoteAddon => {
      const addon = {
        name: ModelHelper.clean(remoteAddon.name),
        id: ModelHelper.clean(remoteAddon.id),
        optional: isNil(remoteAddon.optional) ? true : remoteAddon.optional,
        tag: ModelHelper.clean(remoteAddon.tag)
      } as IProductAddonDetail;

      return addon;
    });
  }

  private static mapType(strType: string) {

    if (isNil(strType) || isEmpty(strType)) {
      return ProductTypes.None;
    }

    let typeStr = startCase(toLower(strType.toString()));

    let type = ProductTypes.None;

    if (isNil(typeStr) == false && isEmpty(typeStr) == false) {
      type = ProductTypes[typeStr];
      if (isNil(typeStr)) {
        type = ProductTypes.None;
      }
    }
    return type;
  }

  static mapCategory(category: string) {
    const strCategory = isNil(category) ? '' : category.toString();

    switch (strCategory) {
      case '4G':
        return ProductCategories.FourG;
      case '5G':
        return ProductCategories.FiveG;
      case 'device':
        return ProductCategories.Device;
      case 'serviceCredit':
        return ProductCategories.ServiceFee;
      case 'rainMaker':
        return ProductCategories.RainMaker;
      default:
        return ProductCategories.None;
    }
  }

  private static mapBillingType(strBillingType: string) {
    let typeStr = isNil(strBillingType) || isEmpty(strBillingType) ? '' : strBillingType.toString();

    switch (typeStr) {
      case 'perGig':
        return BillingTypes.PerGig;
      case 'perMonth':
        return BillingTypes.PerMonth;
      case 'perMonthUpFront':
        return BillingTypes.PerMonthUpFront;
      case 'onceOff':
        return BillingTypes.OnceOff;
      default:
        return BillingTypes.None;
    }
  }

  static mapItems(items: IRemoteProductItem[]): IProductItemDetail[] {

    if (isNil(items) || isEmpty(items)) {
      return [];
    }

    return items.map(remoteItem => {
      const category = this.mapCategory(remoteItem.category);

      const item = {
        id: ModelHelper.clean(remoteItem.id),
        formattedPrice: this.mapPrice(remoteItem.currency, remoteItem.price),
        formattedBillingType: this.formatBillingType(remoteItem.billingType, remoteItem.price),
        billingType: ProductDetail.mapBillingType(remoteItem.billingType),
        name: remoteItem.name,
        config: isNil(remoteItem.config) ? {} : remoteItem.config,
        currency: remoteItem.currency,
        description: ModelHelper.clean(remoteItem.description),
        shortDescription: ModelHelper.clean(remoteItem.shortDescription),
        image: remoteItem.image,
        price: remoteItem.price,
        sku: remoteItem.sku,
        available: isNil(remoteItem.available) ? true : remoteItem.available,
        category,
        tag: remoteItem.tag
      } as IProductItemDetail;

      return item;
    });
  }

  static mapPrice(currency: string, price: number): string {
    if (isNil(price)) {
      return '';
    }

    return (currency === 'ZAR' ? 'R ' : '') + price;
  }

  static formatBillingType(billingType: string, price: number): string {
    if (isNil(price)) {
      return '';
    }

    switch (billingType) {
      case 'perMonth':
      case 'perMonthUpFront':
        return 'month-to-month';
      case 'onceOff':
        return 'once-off';
      case 'perGig':
        return 'a gig ';
    }
  }
}

export interface IProductConfig {
  [key: string]: any;
}

export interface IProductItemDetail {
  id: string;
  formattedBillingType: string;
  formattedPrice: string;
  name: string;
  sku: string;
  description: string;
  shortDescription: string;
  price: number;
  image: string;
  billingType: BillingTypes;
  currency: string;
  category: ProductCategories;
  available: boolean;
  config: { [key: string]: IProductConfig };
  tag: string;
}

export interface IProductAddonDetail {
  name: string;
  id: string;
  optional: boolean;
}
 
export enum ProductPaymentTypes{
  UPFRONT = "upfront",
  POSTPAID = "postpaid",
  OTHER = "other"
}
export interface IProductDetail {
  formattedBillingType?: string;
  items: IProductItemDetail[];
  formattedPrice: string;
  id: string;
  baseId: string;
  name: string;
  sku: string;
  description: string;
  shortDescription: string;
  price: number;
  image: string;
  featured: boolean;
  activeFrom: Date;
  activeTo: Date;
  billingType: BillingTypes;
  currency: string;
  category: ProductCategories;
  config: IDictionary<IProductConfig>;
  cartImage?: string;
  migrationImage?: string;
  migrationCornerImage?: string;
  productModalImage: string;
  addons: IProductAddonDetail[];
  scheduledActionName: string;
  type: ProductTypes | string;
  available: boolean;
  tag?: string;
  silentSalesmanDescription: string;
  cornerImage: string;
  available_balance?: number,
  available_volume?: number,
  last_topup_date?: string,
  last_topup_volume?: number,
  total_topup_volume?: number,
  total_usage?: string,
  paymentType?: string,
  grandfather?:string,
  grandchild:string,
  recurringPrice?: number
}
export interface ProductViewModel {
  id?: string;
  position?: number;
  name?: string;
  description?: string;
  category?:string
  price?: number;
  path?: string;
  formattedBillingType?: string;
  image?: string;
  selected: boolean;
  type: string;
  imageFront?: string;
  imageBack?: string;
  frontLogo?: string;
  titleFront?: string;
  titleBack?: string;
  btnName?: string;
  route?: string;
  link?: string;
  background?: string;
  subHeading?: string;
  buttonFront?: string;
  centered?: string;
  frontClick?: string;
  is5G?: boolean;
  showCallButton?: boolean;
  backColor?: string;
  gradient?: string;
  center?: string;
  textAlign?: string;
  anchor?: string;
  marginBot?: string;
  removeFromMobile?: boolean;
  mobilePosition?: number;
  // showWebCall?: boolean;
  tag?: string;
  isBlackFriday?: boolean;
  isPromo?: boolean;
  isTwoInOnePromo?: boolean;
  silentSalesmanDescription?: string;
  GMAnalyticsName?: string;
  isProduct?: boolean;
  gmDataText?: string;
  productLineItemId?: string;
  cornerImage?: string;
  paymentType?: string;
  grandfather?: string;
  grandchild?: string
}

export  let fixedProducts: ProductViewModel[] = [
  {
    id: 'phone4G',
    imageBack: '',
    imageFront: '../../assets/images/product-images/fourg_phones.png',
    backColor: 'yes',
    titleFront: '4G for phones',
    subHeading: 'Unlimited data at its most affordable.',
    gradient: 'blue',
    titleBack: 'choose 4G for phones',
    description:
      'rain’s 4G for phones is perfectly designed to give your handset exactly the kind of unlimited data it needs.\n- affordable products\n- designed for phones only\n- for personal use, hotspotting not supported\n- 360p for small screen streaming',
    selected: false,
    type: 'fixed',
    btnName: '',
    route: '',
    position: 7,
    center: 'yes',
    textAlign: 'left',
    anchor: 'yes',
    background: 'bg-main-cat',
    removeFromMobile: true
  },


  {
    id: 'device4G',
    imageBack: '',
    imageFront: '../../assets/images/product-images/fourg_devices.png',
    backColor: 'yes',
    titleFront: '4G for any device',
    subHeading: 'Unlimited data for phones, tablets, MiFi’s & home routers.',
    gradient: 'blue',
    titleBack: 'choose 4G for any device',
    description:
      'Supercharge your gadgets with unlimited data designed to work in any 4G-enabled device, including MiFi and home routers. Now you can let the data flow, even when you’re on the go.\n- designed for any 4G device\n-	phone, home or on the go',
    selected: false,
    type: 'fixed',
    btnName: '',
    route: '',
    position: 4,
    center: 'yes',
    textAlign: 'left',
    anchor: 'yes',
    background: 'bg-main-cat',
    removeFromMobile: true
  },
  {
    id: 'home5G',
    imageBack: '',
    imageFront: '../../assets/images/product-images/fiveg_home.png',
    backColor: 'yes',
    titleFront: '5G for home',
    subHeading: 'Upgrade your internet to faster 5G.',
    gradient: 'blue',
    titleBack: 'choose 5G',
    description:
      'It’s time to upgrade your home internet to rain’s unlimited 5G. Just choose the speed you need and then, as soon as your 5G router arrives, you’re good to go. It’s faster data delivered fast.',
    selected: false,
    type: 'fixed',
    btnName: '',
    route: '',
    position: 1,
    center: 'yes',
    marginBot: 'noCenter',
    anchor: 'yes',
    background: 'bg-main-cat',
    removeFromMobile: true
  },
  {
    id: 'myRain',
    imageBack: '',
    imageFront: '../../assets/images/home-page/my-rain.png',
    titleBack: 'about my rain',
    description:
      'Once you’ve ordered and activated your SIM , my rain lets you see exactly how much data you use, manage your account and a whole lot more. You’re in charge!',
    selected: false,
    type: 'fixed',
    btnName: 'learn more',
    route: 'about-my-rain',
    background: 'bg-my-rain',
    titleFront: 'manage and take control of your account',
    subHeading: '',
    buttonFront: 'learn more',
    position: 10,
    mobilePosition: 7
  },
  {
    id: 'activateSIM',
    imageBack: '../../assets/logo/activate-sim-products.png',
    imageFront: '../../assets/images/home-page/oval-grey-act.png',
    titleFront: 'got your 4G SIM?',
    titleBack: 'got your SIM from one of our\n retail partners?',
    description: 'Just click the button below to select your plan, RICA online and activate.',
    selected: false,
    type: 'fixed',
    btnName: 'activate SIM',
    // route: 'activate-SIM',
    path: 'activate-SIM',
    position: 11,
    mobilePosition: 8
  },
  {
    id: 'checkCoverage',
    imageFront: '../../assets/images/home-page/Map.png',
    titleBack: '',
    titleFront: 'check coverage',
    description: '',
    selected: false,
    position: 6,
    type: 'fixed',
    btnName: 'order now',
    link: 'https://app.adjust.com/f32fpyx?deeplink=mrdfood%3A%2F%2F&fallback=https%3A%2F%2Fmrdfood.com',
    mobilePosition: 6
  },
  {
    id: 'takealot',
    imageFront: '../../assets/logo/Group 10.png',
    titleBack: 'add unlimited data to your cart',
    titleFront: 'add a 4G SIM while\n you shop online',
    description:
      'Top off your Takealot shop with a 4G SIM. Just add it to your cart, then select your plan and activate* at rain.co.za.',
    selected: false,
    type: 'fixed',
    btnName: 'order now',
    link: 'https://m.takealot.com/promotion/rain',
    position: 1,
    mobilePosition: 1
  },
  {
    id: 'hi-online',
    imageFront: '../../assets/logo/hi-logo-blue.svg',
    titleBack: 'add unlimited data to your cart',
    titleFront: 'add a 4G SIM while you\n shop online',
    description:
      'Top off your hi online order with a 4G SIM. Just add it to your cart, then select your plan and activate* at rain.co.za.',
    selected: false,
    type: 'fixed',
    btnName: 'order now',
    link: 'https://www.hi-online.co.za/pdp/rain-4g-sim/_/A-270092AAAE2',
    position: 2,
    mobilePosition: 2
  },
  {
    id: 'MrDFood',
    imageFront: '../../assets/logo/MrD.svg',
    titleBack: 'get your unlimited data to go',
    titleFront: 'get your 4G SIM\n delivered now',
    description:
      'Grab a quick 4G SIM with your next order from Mr D Food. Just add it to your order, then select your plan and activate* at rain.co.za. ',
    selected: false,
    type: 'fixed',
    btnName: 'order now',
    link: 'https://app.adjust.com/f32fpyx?deeplink=mrdfood%3A%2F%2F&fallback=https%3A%2F%2Fmrdfood.com',
    position: 3,
    mobilePosition: 3
  },
  {
    id: 'pargo',
    imageFront: '../../assets/logo/Group 13.png',
    titleBack: 'go pick up your own SIM today',
    titleFront: 'pick up a 4G\n SIM today',
    description:
      'Don’t want to wait for delivery? Go to one of 200+ participating Clicks stores and collect today. Then select your plan and activate* at rain.co.za.',
    selected: false,
    type: 'fixed',
    btnName: 'find a SIM',
    link: 'https://map.pargo.co.za/?token=pCVfU2oi18IXvHkhq93PI2YQzzDoPHjywzgmK0eSjh8rCyTU',
    position: 4,
    mobilePosition: 4
  },
  // {
  //   id: 'more4g',
  //   imageFront: '../../assets/images/4g-semi-transparent.png',
  //   titleBack: 'add unlimited data to your cart',
  //   titleFront: 'more about 4G',
  //   route: '/more-about-4g',
  //   description: '',
  //   selected: false,
  //   type: 'fixed',
  //   btnName: 'order now',
  //   link: 'https://m.takealot.com/promotion/rain',
  //   buttonFront: 'learn more',
  //   position: 16,
  //   mobilePosition: 13
  // },
  {
    id: 'more5g',
    imageFront: '../../assets/images/product-images/moreAbout5G.png',
    titleBack: 'add unlimited data to your cart',
    titleFront: 'more about 5G',
    description: '',
    selected: false,
    route: '/fiveg',
    type: 'fixed',
    btnName: 'order now',
    link: 'https://m.takealot.com/promotion/rain',
    buttonFront: 'learn more',
    position: 17,
    mobilePosition: 14
  },
  {
    id: 'chipin',
    imageFront: '../../assets/images/home-page/CHIP-IN-LOGO.png',
    titleBack: 'chipin',
    titleFront: 'help people\n stay connected',
    description: '',
    selected: false,
    route: '/chip-in',
    type: 'fixed',
    btnName: 'chipin',
    link: '',
    buttonFront: 'learn more',
    position: 18,
    mobilePosition: 15
  }

];