import {Injectable} from "@angular/core";
import {CACHE_IDM_TOKEN} from "@models/constants";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, timeout} from "rxjs/operators";
import {TokenService} from "@services/token.service";
import {ConfigService} from "@services/config.service";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DeviceServiceV2 {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        private configService: ConfigService,
    ) {
    }

    getDeviceImage(msisdn: string): Observable<any> {
        const requestPath = `${this.configService.SICAP}` + "/mobileinformationbymsisdn?MSISDN=" + msisdn;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.tokenService.get(CACHE_IDM_TOKEN)
            })
        };
        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((res: any) => {
                if (res && res.response) {
                    return res.response;
                }
            })
        );
    }
}