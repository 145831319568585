import { Observable, timer } from "rxjs";
import { first, scan, switchMapTo, tap } from "rxjs/operators";

// https://stackoverflow.com/questions/36007911/rxjs-poll-until-interval-done-or-correct-data-received
export interface PollOptions {
    pollInterval: number;
    maxAttempts: number;
    responsePredicate: (res: any) => boolean;

    initialDelay?: number;
    errorMessage?: string;
}

const checkAttempts = (maxAttempts: number, errorMsg?: string) => {
    return (attempts: number) => {
        if (attempts > maxAttempts) {
            throw new Error(errorMsg ? errorMsg : `Max attempts (${maxAttempts}) to fetch results reached.`);
        }
    };
}

export const pollUntil = (options: PollOptions) => {
    const {
        initialDelay,
        pollInterval,
        maxAttempts,
        errorMessage,
        responsePredicate
    } = options ?? {};

    return <T>(source$: Observable<T>): Observable<T> =>
        timer(initialDelay ?? 0, pollInterval)
            .pipe(
                scan(attempts => ++attempts, 0),
                tap(checkAttempts(maxAttempts, errorMessage)),
                switchMapTo(source$),
                first(responsePredicate)
            );
}
