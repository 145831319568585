import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from '@services/cache.service';
import moment from 'moment';
import { BaseComponent } from 'src/app/baseComponent';
import { ProductService } from '@services/product.service';
import { takeUntil } from 'rxjs/operators';
import { Statuses } from '@models/result';
import { UserService } from '@services/user.service';
import { ToastrService } from 'ngx-toastr';
import { IServiceDetail } from '@models/serviceDetail';
import { SurveyModalComponent } from '../cancel-modal/survey-modal/survey-modal.component';

@Component({
  selector: 'app-confirm-notify-me-modal',
  templateUrl: './confirm-notify-me-modal.component.html',
  styleUrls: ['./confirm-notify-me-modal.component.scss']
})
export class ConfirmNotifyMeModalComponent extends BaseComponent {

  private readonly type = '4G';
  service: IServiceDetail;
  private readonly address: any;
  currentLastDayOfTheMonthFullDate = moment().clone().endOf('month').format('DD MMMM yyyy');

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router
  ) {
    super();
    this.getServiceDetails();
  }

  static show(
    modalService: NgbModal,
    address: any
  ) {
    const options: NgbModalOptions = { size: <any>'survey', windowClass: 'slideInUp', centered: true };
    const modalRef = modalService.open(ConfirmNotifyMeModalComponent, options);
    modalRef.componentInstance.address = address;
  }

  getServiceDetails() {
    const serviceId = this.router.url.replace(/service|\/|/g, '');
    this.userService.getService(serviceId, true).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res.status !== Statuses.Success || !res?.value) {
        this.toastr.error('An error occurred while requesting the service details', null);
      } else {
        this.service = res.value;
      }
    });
  }

  confirm() {
    this.modalService.dismissAll();
    const serviceId = this.service.id;
    const productId = this.service.productId;
    const msisdn = this.service.msisdn || '';

    const options: NgbModalOptions = { size: <any>'confirm', windowClass: 'slideInUp', centered: true };
    const modalRef = this.modalService.open(SurveyModalComponent, options);
    modalRef.componentInstance.serviceId = serviceId;
    modalRef.componentInstance.simName = this.service.name;
    modalRef.componentInstance.msisdn = msisdn;
    modalRef.componentInstance.address = this.address;
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.productId = productId;
  }

}
