import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIG_COMMENTS, CONFIG_FRIENDLY_NAME } from '@models/constants';
import { IProductDetail, ProductCategories } from '@models/productDetail';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { CartService, ICartItemMapped } from '@services/cart.service';
import { DataLayerService } from '@services/data-layer.service';
import { ElementService } from '@services/element.service';
import { ProductService } from '@services/product.service';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { BillCycleOption } from 'src/app/core/interfaces/bill-cycle-option.interface';
import { BillingState } from 'src/app/core/store/state/billing.state';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { TotalDueNowPipe } from 'src/app/shared/pipes/total-due-now.pipe';
import { CartState } from 'src/app/store/state/cart.state';
import { ServicesState } from 'src/app/store/state/services.state';
import { parseBillingCycleDates } from 'src/app/utils/helpers/general.helper';

//TODO we must remove jquery
declare var $: any;

@Component({
  selector: 'app-fiveg-product-explainer-modal',
  templateUrl: './fiveg-product-explainer-modal.component.html',
  styleUrls: ['./fiveg-product-explainer-modal.component.scss']
})
export class FivegProductExplainerModalComponent extends BaseComponent implements OnInit {
  @Select(BillingState.getClosestBillCycleDate) closetsBillCycleDate$: Observable<String>;
  public product: IProductDetail;
  device: any;
  isPromo: boolean;
  gmData: string;
  @Input()
  fromGotSim: boolean;

  @Select(ServicesState.hasPostPaid)
  hasPostPaid$: Observable<boolean>;
  
  @Select(FirebaseConfigsState.isR1PromoActive) 
  isR1PromoActive$: Observable<boolean>;

  @Input()
  comments: string

  cheapestPrice = parseBillingCycleDates(this.store.selectSnapshot(BillingState.billCycleOptions)).sort((a, b) => {
    return (
      this.totalDueNowPipe.transform(this.getTotal(), +a.value) -
      this.totalDueNowPipe.transform(this.getTotal(), +b.value)
    );
  })[0].value;

  constructor(
    public productService: ProductService,
    private cartService: CartService,
    private router: Router,
    private elementService: ElementService,
    public activeModal: NgbActiveModal,
    private dataLayerService: DataLayerService,
    private store: Store,
    private totalDueNowPipe: TotalDueNowPipe
  ) {
    super();
  }

  scrollToFiveG() {
    this.activeModal.dismiss('close modal');
    this.elementService.scrollToShop();
  }

  public checkout() {
    let comments = null;
    if (this.comments) {
      comments = this.comments;
    }
    this.cartService
      .add(this.product.id, {
        [CONFIG_FRIENDLY_NAME]: '5G SIM',
        [CONFIG_COMMENTS]: comments
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.activeModal.close();
        this.dataLayerService.productAddToCart(this.product);
        this.router.navigate(['/cart']);
      });
  }


  ngOnInit() {
    setTimeout(() => {
      this.device = this.product.items.find(x => x.category === ProductCategories.Device);
      this.isPromo = get(this.product, 'config.promo.value', false);

    }, 500);
    let promo = ' - normal product - ';
    if (this.product?.config?.promo?.value) {
      promo = ' - promo product - ';
    } else if (this.product?.config?.winterPromo?.value) {
      promo = ' - winter promo product - ';
    }
    this.gmData = `R${this.product.price} ${promo} ${this.product.name.replace('\n', '')} - add to cart : after coverage check`;
    this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['successful-coverage-result']);    
  }

  getTotal() {
    const totalFiveG = this.store.selectSnapshot(CartState.fiveGProducts).reduce(
      (accumulator: number, currentValue: ICartItemMapped) =>
        accumulator + currentValue.product.price,
      0
    )
    const totalfourG = this.store.selectSnapshot(CartState.fourGProducts).reduce(
      (accumulator: number, currentValue: ICartItemMapped) =>
        accumulator + currentValue.product.price,
      0
    )
    const totalGotSim = this.store.selectSnapshot(CartState.gotSimProducts).reduce(
      (accumulator: number, currentValue: ICartItemMapped) =>
        accumulator + currentValue.product.price,
      0
    )
    return (totalFiveG + totalfourG + totalGotSim)
  }

  goTo(link) {
    this.activeModal.close();
    this.router.navigateByUrl(link);
  }
}
