import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_AUTH_TOKEN } from '@models/constants';
import { ConfigService } from '@services/config.service';
import { TokenService } from '@services/token.service';
import { Observable } from 'rxjs';
import { TopUpPurchasePayload, TopUpPurchaseResult, TopUpHistoryResults, TopUpStatusResult } from '../interfaces/top-up.interface';

@Injectable({
  providedIn: 'root'
})
export class TopUpService {

  constructor(
    private _http: HttpClient,
    private configSVC: ConfigService,
    private tokenSVC: TokenService
  ) { }

  public purchase(payload: TopUpPurchasePayload): Observable<TopUpPurchaseResult> {
    const url = `${this.configSVC.BASE_API_URL}/V1/rain-web/topup-service/topup`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.getTokenForAccountType()
      })
    };
    
    return this._http.post<TopUpPurchaseResult>(url, payload, httpOptions);
  }

  public status(id: string): Observable<TopUpStatusResult> {
    const url = `${this.configSVC.BASE_API_URL}/V1/rain-web/topup-service/topup/${id}/status`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

  return this._http.patch<TopUpStatusResult>(url, httpOptions);

  }

  public history(id: string): Observable<TopUpHistoryResults> {
    const url = `${this.configSVC.BASE_API_URL}/V1/rain-web/topup-service/topup/${id}/history`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<TopUpHistoryResults>(url, httpOptions);
  }
}
