export const GET_ALL_MESSAGES = '[INBOX] All Messages';
export const GET_ALL_CATEGORIES = '[INBOX] Get All Categories';
export const GET_MESSAGES_FOR_CATEGORY = '[INBOX] Get Category Unread Messages Count';
export const GET_TOTAL_UNREAD_MESSAGES_COUNT = '[INBOX] Get Total Unread Messages Count';
export const GET_CATEGORIES_UNREAD_MESSAGES_COUNT = '[INBOX] Get Category Unread Messages Count';
export const MARK_AS_READ = '[INBOX] Mark  Message As Read';
export const GET_CURRENT_MESSAGE = '[INBOX] Get Current Message';
export const SEARCH = '[INBOX] Search Current Category';
export const NAVIGATE_BACK = '[INBOX] Navigate Back';
export const SET_CURRENT_CATEGORY = '[INBOX] Set Current Category';
export const DELETE_MESSAGE = '[INBOX] Delete Message';
export const SET_SHOW_SEARCH = '[INBOX] Show Message Search';
export const SET_SHOW_CATEGORIES = '[INBOX] Show Message Categories';
export const SET_SHOW_MESSAGE_LIST = '[INBOX] Show Message List';
export const SET_SHOW_MESSAGE = '[INBOX] Show Message';
export const SET_SHOW_MESSAGE_SETTINGS = '[INBOX] Show Message Settings';
export const SET_SHOW_WEATHER = '[INBOX] Show Weather';
export const SHOW_BACK = '[INBOX] Show Back';
export const GET_ALL_NOTIFICATION_CHANNELS = '[INBOX] Get All Notification Channels Available to User';
export const GET_USER_PREFERRED_NOTIFICATION_CHANNEL = '[INBOX] Get User Preffered Notification Channels';
export const SET_USER_WHATSAPP_OPTIN = '[INBOX] Set User Whatsapp Optin';
export const SET_USER_PROMO_OPTIN = '[INBOX] Set User Promo Optin';
export const GET_USER_PROMO_OPTIN = '[INBOX] Get User Promo Optin';
export const SET_USER_PREFERRED_NOTIFICATION_CHANNEL = '[INBOX] Set User Preffered Notification Channels';
export const GET_USER_WHATSAPP_OPTIN = '[INBOX] Get User Whatsapp Optin';
export const SHOW_OPTIN_INFO = '[INBOX] Get User Whatsapp Optin';
export class GetAllMessages {
  public static type = GET_ALL_MESSAGES;
}

export class GetAllCategories {
  public static type = GET_ALL_CATEGORIES;
}

export class GetMessagesForCategory {
  public static type = GET_MESSAGES_FOR_CATEGORY;
  constructor(public categoryId: number) { }
}

export class GetTotalUnreadMessagesCount {
  public static type = GET_TOTAL_UNREAD_MESSAGES_COUNT;
}

export class MarkAsRead {
  public static type = MARK_AS_READ;
  constructor(public notificationId: string) { }
}

export class SetShowSearch {
  public static type = SET_SHOW_SEARCH;
  constructor(public showSearch: boolean) { }
}

export class SetShowCategories {
  public static type = SET_SHOW_CATEGORIES;
  constructor(public showCategories: boolean) { }
}

export class SetShowWeather {
  public static type = SET_SHOW_WEATHER;
  constructor(public showWeather: boolean) { }
}

export class SetShowMessageList {
  public static type = SET_SHOW_MESSAGE_LIST;
  constructor(public showMessageList: boolean) { }
}

export class SetShowMessage {
  public static type = SET_SHOW_MESSAGE;
  constructor(public showMessage: boolean) { }
}

export class SetShowMessageSettings {
  public static type = SET_SHOW_MESSAGE_SETTINGS;
  constructor(public showMessageSettings: boolean) { }
}
export class SetCurrentCategory {
  public static type = SET_CURRENT_CATEGORY;
  constructor(public categoryId: number) { }
}

export class GetCurrentMessage {
  public static type = GET_CURRENT_MESSAGE;
  constructor(public notificationId: string) { }
}

export class Search {
  public static type = SEARCH;
  constructor(public searchTerm: string) { }
}

export class SetShowBack {
  public static type = SHOW_BACK;
  constructor(public showBack: boolean) { }
}

export class SetShowOptinInfo {
  public static type = SHOW_OPTIN_INFO;
  constructor(public showOptinInfo: boolean) { }
}

export class DeleteMessage {
  public static type = DELETE_MESSAGE;
  constructor(public notificationId: string) { }
}
export class GetAllNotificationChannels {
  public static type = GET_ALL_NOTIFICATION_CHANNELS;
}

export class GetUserPreferredPromoNotificationChannel {
  public static type = GET_USER_PREFERRED_NOTIFICATION_CHANNEL;
}

export class SetUserPreferredPromoNotificationChannel {
  public static type = SET_USER_PREFERRED_NOTIFICATION_CHANNEL;
  constructor(public channelId: number) { }
}
export class SetUserWhatsappOptin {
  public static type = SET_USER_WHATSAPP_OPTIN;

  constructor(public optInWhatsApp: boolean) { }
}
export class GetUserWhatsappOptin {
  public static type = GET_USER_WHATSAPP_OPTIN;
}

export class SetUserPromoOptin {
  public static type = SET_USER_PROMO_OPTIN;

  constructor(public optInPromo: boolean) { }
}

export class GetUserPromoOptin {
  public static type = GET_USER_PROMO_OPTIN;
}
