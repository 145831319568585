/**
 *
 * @param objects
 * @param key
 */
 export function ToHashMap<T>(obj: any[], state: any, key: string): { [key: string]: T } {
    return obj.reduce(
        (entities: { [id: string]: any }, item: any) => {
            let param = item[key];
            if (Array.isArray(item[key])) {
                param = item[key][0];
            }
            return {
                ...entities,
                [param]: item,
            };
        },
        { ...state.entities }
    );

}

/**
 *
 *
 * @export
 * @param {*} entities
 * @returns {T[]}
 */
export function FromHashMap<T>(entities): T[] {

    if(entities) {
        return Object.keys(entities).map(key => {
            if (key) return entities[key];
        });
    }
}

/**
 *
 *
 * @export
 * @param {any[]} obj
 * @param {*} state
 * @param {string} key
 * @returns
 */
export function ToSortedHashMap<T>(obj: any[], state: any, key: string): { [key: string]: T } {
    return obj.reduce((r: any, a: any) => {
        if (a !== null) {
            r[a[key]] = r[a[key]] || [];
            r[a[key]].push(a);
        }
        return r;
    }, Object.create(null));
}

/**
 *
 * @export
 * @template T
 * @param {{[lang: string]: T[]}} dataSet
 * @returns {T[]}
 */
export function FlattenHashMapArrays<T>(dataSet: { [lang: string]: T[] }): T[] {
    const entities = FromHashMap<T[]>(dataSet);
    const flatArr = [];

    // joining arrays into 1
    entities.forEach(a => flatArr.push(...a));
    return flatArr;
}

export function FromMap(map) {
    let obj = {}
    for(let[k,v] of map) {
        v instanceof Map 
          ? obj[k] = FromMap(v)
          : obj[k] = v
    }
    return obj
  }