import { FulfillmentType } from "@models/orderDetail";
import { IServiceDetail, ServiceObject } from "@models/serviceDetail";
import { CancellationsRequest } from "@pages/service-page/plan-cancellation-page/cancellations.interface";
import { Utils } from "@Utils";


export class SetServiceCancellationDetails {
	static readonly type = Utils.Helpers.Type('[Cancel] set service details for to be cancelled service')
	constructor(public service: ServiceObject) {}
}

export class NavigateToCancellationSurvey {
	static readonly type = Utils.Helpers.Type('[Cancel] navigate to cancellation survey')
}

export class NavigateToCollectionConfirmation {
	static readonly type = Utils.Helpers.Type('[Cancel] navigate to collection confirmation')
}

export class CancelLegacyService {
	static readonly type = Utils.Helpers.Type('[Cancel] cancel legacy service')
	constructor(public question, public answer, public comments) {}
}

export class Revoke4GServiceCancellation {
	static readonly type = Utils.Helpers.Type('[Cancel] revoke legacy service cancellation')
	constructor(public serviceId: string) {}
}

export class CancelRainOneService {
	static readonly type = Utils.Helpers.Type('[Cancel] cancel rainone service')
	constructor(public question, public answer, public comments) {};
}

export class Cancel2For1Service {
	static readonly type = Utils.Helpers.Type('[Cancel] cancel 2 for 1 service')
}

export class RevokeCancel2For1Service {
	static readonly type = Utils.Helpers.Type('[Cancel] revoke 2 for 1 service cancellation')
}

export class PreCancel5GService {
	static readonly type = Utils.Helpers.Type('[Cancel] precancel rainone service')
	constructor(public fufillmentType: FulfillmentType, public collectionAddress: any, public userContactNumber: string) {}
}

export class RevokePreCancel5GService {
	static readonly type = Utils.Helpers.Type('[Cancel] revoke rainone service precancellation')
	constructor(public serviceId: string) {}
}

export class ShowCancellationSuccessMessage {
	static readonly type = Utils.Helpers.Type('[Cancel] show cancellation success message')
	constructor(public type: string) {}
}

export class OptForSimSwop {
	static readonly type = Utils.Helpers.Type('[Cancel] opt for sim swop')
	constructor(public proceed: boolean) {}
}

export class StoreCancellationSurveyRequest {
	static readonly type = Utils.Helpers.Type('[Cancel] store cancellation survey request')
	constructor(public payload: any) {}
}

export class ClearCancellationState {
	static readonly type = Utils.Helpers.Type('[Cancel] clear cancellation state')
}