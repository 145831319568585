import { PaymentStatusItem } from "./payment-status-item.interface";


export const PAYMENT_GENERIC_RESPONSE = "The transaction failed as it was rejected by the issuing bank, please contact them for a resolution";


export const PAYMENT_STATUS_MAPPING: { [status: string]: PaymentStatusItem } = {

    //ERROR CODES
    "800.100.155": {
        status: "Transaction declined - amount exceeds credit",
        successful: false,
        suggestion: {
            header: "Please be advised that your subscription payment failed due to insufficient funds / exceeding the credit available on your card/account. Please ensure you have funds in your bank accounts and that your account limit is sufficient for your subscription payment.",
            bulletPoints: [],
        },
        action: "Create new order once limit adjusted"
    },
    "100.396.103": {
        status: "Previously pending transaction timed out",
        successful: false,
        suggestion: {
            header: "Your subscription payment was declined for the following reason:",
            bulletPoints: [
                `Your bank has rejected the transaction/s for account-related reasons.`,
                "If you are using a Virtual Card, please ensure the card is enabled/active on your banking app.",
            ],
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.162": {
        status: "Transaction declined (Limit exceeded)",
        successful: false,
        suggestion: {
            header: "Please be advised that your subscription payment failed due to insufficient funds / exceeding the credit available on your card/account. Please ensure you have funds in your bank accounts and that your account limit is sufficient for your subscription payment.",
            bulletPoints: [],
        },
        action: "Create new order once limit adjusted"
    },
    "800.100.152": {
        status: "Transaction declined by authorization system",
        successful: false,
        suggestion: {
            header: "Your subscription payment was declined for the following reason:",
            bulletPoints: [
                "Your bank has rejected the transaction/s for account-related reasons.",
                "If you are using a Virtual Card, please ensure the card is enabled/active on your banking app.",
            ],
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.380.501": {
        status: "Risk management transaction timeout",
        successful: false,
        suggestion: {
            header: "Your transaction was declined for one of the following reasons:",
            bulletPoints: [
                `Possibly not entering in the OTP on time or`,
                `The bank card is not enabled for online transactions or`,
                `There could be an issue with the bank 3D server/transaction approval server`,
            ],
            summaryMessage: `Please try again if you know your bank card is activated for online transactions or contact your bank.`
        },
        action: "Create new order once issue sorted"
    },
    "100.150.203": {
        status: "Registration is not valid, probably initially rejected",
        successful: false,
        suggestion: {
            header: "Your subscription payment failed due to an issue with your card. Please ensure that your account is updated with your latest card details.",
            bulletPoints: [],
        },
        action: "Create new order once issue sorted"
    },
    "800.100.170": {
        status: "Transaction declined (Transaction not permitted)",
        successful: false,
        suggestion: {
            header: "The transaction was declined by your issuing bank.",
            bulletPoints: [
                "This means that your issuing bank could not process this transaction due to some rules applied to their system.",
            ],
            summaryMessage: `Please contact your bank, as further detail on the reason for the failed transaction can only be communicated/explained to a customer by the customer's bank.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.380.401": {
        status: "User Authentication Failed",
        successful: false,
        suggestion: {
            header: "Your transaction failed due to the OTP details either:",
            bulletPoints: [
                `Not entered correctly`,
                `There was a field or system error regarding the OTP`
            ],
        },
        action: "Create new order once issue sorted"
    },
    "100.390.107": {
        status: "Transaction rejected",
        successful: false,
        suggestion: {
            header: "Your subscription payment failed due to one of the following reasons:",
            bulletPoints: [
                `Either the cardholder or card issuing bank is not 3D enrolled`,
                `3D card authentication was unsuccessful, for example, the 3D Cardholder is not an enrolled`,
                `Card or issuing bank is not 3D Secure ready`
            ],
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.168": {
        status: "Transaction declined (restricted card)",
        successful: false,
        suggestion: {
            header: "Your subscription payment failed due to one of the following reasons:",
            bulletPoints: [
                `Your card issuer has declined the transaction as the credit card has some restrictions.`,
                `You would need to reach out to your issuing bank in order for them to provide you with a reason why this card has been flagged as a restricted card.`
            ],
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.396.101": {
        status: "Cancelled by User",
        successful: false,
        suggestion: {
            header: `Your payment failed due to one of the following reasons:`,
            bulletPoints: [
                `The authentication phase is taking too long`,
                `The payment method is taking too long to load`,
                `Accidentally closing the browser tab`
            ],
            summaryMessage: `The suggestion would be to not close the browser when transacting or to wait a while if your internet is too slow.`
        },
        action: "Create new order once issue sorted"
    },
    "800.100.151": {
        status: "Transaction declined (invalid card)",
        successful: false,
        suggestion: {
            header: "Your payment dailed due to one of the following reasons:",
            bulletPoints: [
                `The customer's card might be expired`,
                `The card is not registered to do online transactions`
            ],
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.158": {
        status: "Transaction declined (suspecting manipulation)",
        successful: false,
        suggestion: {
            header: "Your bank has blocked your subscription payment due to card manipulation, please reach out to your issuing bank for them to provide you with a reason why this card has been flagged as fraudulent.",
            bulletPoints: [],
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.390.111": {
        status: "Communication Error to Scheme Directory Server",
        successful: false,
        suggestion: {
            header: "This means that either your issuing bank, acquiring bank or a 3rd party involved in the online transaction process is experiencing downtime",
            bulletPoints: [],
            summaryMessage: `The suggestion would be wait for a while until one of the parties mentioned above make their system available again in order for you to transact further.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.100.303": {
        status: "Card expired",
        successful: false,
        suggestion: {
            header: `Your bank card has expired, please ensure your account is updated with your latest card details..`,
            bulletPoints: [],
        },
        action: "Create new order once card expiry is sorted"
    },
    "100.390.112": {
        status: "Technical Error in 3D system",
        successful: false,
        suggestion: {
            header: `Your payment has failed due to an issue with the 3DS server.`,
            bulletPoints: [],
            summaryMessage: `Please try again if you know your bank card is activated for online transactions or contact your bank.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.165": {
        status: "Transaction declined (card lost)",
        successful: false,
        suggestion: {
            header: `Your subscription payment has failed due to your card being lost/stolen. Please ensure your rain account is updated with your latest card details.`,
            bulletPoints: [],
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.171": {
        status: "Transaction declined (pick up card)",
        successful: false,
        suggestion: {
            header: `Your subscription payment has failed due to your card being lost/stolen. Please ensure your rain account is updated with your latest card details.`,
            bulletPoints: [],
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.157": {
        status: "The wrong expiry date entered",
        successful: false,
        suggestion: {
            header: `Your payment failed due to one of the following details being entered incorrectly:`,
            bulletPoints: [
                `Card Number`,
                `Expiry date`,
                `CVV`
            ],
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.100": {
        status: "Transaction Rejected by Issuing bank",
        successful: false,
        suggestion: {
            header: `The transaction was declined by your issuing bank.`,
            bulletPoints: [
                "This means that your issuing bank could not process this transaction due to some rules applied to their system.",
            ],
            summaryMessage: "Please contact your bank, as further detail on the reason for the failed transaction can only be communicated/explained to a customer by the customer's bank."
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.153": {
        status: "Transaction declined (invalid CVV)",
        successful: false,
        suggestion: {
            header: `Your payment failed due to one of the following details being entered incorrectly:`,
            bulletPoints: [
                `Card Number`,
                `Expiry date`,
                `CVV`
            ],
        },
        action: "Create new order once data entered correctly or issue sorted at bank"
    },
    "800.100.203": {
        status: "The card has insufficient funds",
        successful: false,
        suggestion: {
            header: `Please be advised that your subscription payment failed due to insufficient funds / exceeding the credit available on your card/account.`,
            bulletPoints: [],
            summaryMessage: `Please ensure you have funds in your bank accounts and that your account limit is sufficient for your subscription payment.`
        },
        action: "Create new order when funds available or issue sorted at bank"
    },
    "800.140.112": {
        status: "The maximum number of registrations of email per credit card number exceeded",
        successful: false,
        suggestion: {
            header: `Please note that your payment has been rejected, due to your card being associated with too many rain accounts.`,
            bulletPoints: [],
        },
        action: "Create new order once issue sorted"
    },
    "800.300.101": {
        status: "Blacklisted due to multiple invalid attempts",
        successful: false,
        suggestion: {
            header: `Your subscription payment failed due to your card being blocked, please ensure your rain account is updated with your latest payment details.`,
            bulletPoints: [],
        },
        action: "Create new order once issue sorted"
    },
    "800.100.202": {
        status: "Account Closed",
        successful: false,
        suggestion: {
            header: `Your subscription payment failed due to your account being closed, please ensure your rain account is updated with your latest payment details..`,
            bulletPoints: [],
        },
        action: "Don't attempt to create a new order until this is sorted."
    },
    "800.200.202": {
        status: "Account Closed",
        successful: false,
        suggestion: {
            header: `Your subscription payment failed due to your account being closed, please ensure your rain account is updated with your latest payment details.`,
            bulletPoints: [],
        },
        action: "Don't attempt to create a new order until this is sorted."
    },
    "800.200.208": {
        status: "Account or user is blacklisted (account blocked)",
        successful: false,
        suggestion: {
            header: "Your subscription payment failed due to your account being blocked, please ensure your rain account is updated with your latest payment details.",
            bulletPoints: [],
        },
        action: "Don't attempt to create a new order until this is sorted."
    },
    "800.200.159": {
        status: "Account or user is blacklisted (card stolen)",
        successful: false,
        suggestion: {
            header: "Your subscription payment failed due to your account being blocked, please ensure your rain account is updated with your latest payment details.",
            bulletPoints: [],
        },
        action: "Don't attempt to create a new order until this is sorted."
    },
    "800.100.160": {
        status: "Account or user is blacklisted (card stolen)",
        successful: false,
        suggestion: {
            header: "Your subscription payment has failed due to your card being lost/stolen. Please ensure your rain account is updated with your latest card details.",
            bulletPoints: [],
        },
        action: "Don't attempt to create a new order until this is sorted."
    },
    "800.200.220": {
        status: "Account or user is blacklisted (fraudulent transaction)",
        successful: false,
        suggestion: {
            header: "Your subscription payment failed due to your account being blocked, please ensure your rain account is updated with your latest payment details.",
            bulletPoints: [],
        },
        action: "Don't attempt to create a new order until this is sorted."
    },
    "800.100.161": {
        status: "Transaction declined (too many invalid tries)",
        successful: false,
        suggestion: {
            header: "Your subscription payment failed due to your account being blocked, please ensure your rain account is updated with your latest payment details.",
            bulletPoints: [],
        },
        action: "Don't attempt to create a new order until this is sorted."
    },
    "800.303.101": {
        status: "Blacklisted due to multiple invalid attempts",
        successful: false,
        suggestion: {
            header: `Your subscription payment failed due to your account being blocked, please ensure your rain account is updated with your latest payment details.`,
            bulletPoints: [],
        },
        action: "Don't attempt to create a new order until this is sorted."
    },

    //SUCCESS CODES
    "000.200.100": {
        status: "User successfully created checkout.",
        successful: true,
    },
    "000.200.000": {
        status: "User started the payment process.",
        successful: true,
    },
    "000.000.000": {
        status: "Transaction was successful.",
        successful: true,
    },
    "000.100.110": {
        status: "Request successfully processed in 'Merchant in Integrator Test Mode'",
        successful: true,
    },
};

export type PAYMENT_STATUS_TYPE = keyof typeof PAYMENT_STATUS_MAPPING;



