import { Injectable } from '@angular/core';
import { State, Selector, Store, Action, StateContext } from '@ngxs/store';
import { CartService } from '@services/cart.service';
import { tap } from 'rxjs/operators';
import { VoucherStatus } from '../../interfaces/voucher.interface';
import { VoucherService } from '../../services/voucher/voucher.service';
import * as fromVoucherActions from '../actions/voucher.actions';
import { CoreState } from './core.state';
import { BlackFridayVouchers } from '../../interfaces/voucher-redeem-payload.interface';

export interface VoucherStateModal {
  voucherNum: string;
  valid: boolean;
  loaded: boolean;
  loading: boolean;
  status: VoucherStatus;
  hasError: boolean;
  error: any;
  voucherPool : BlackFridayVouchers[];
  voucher_amount: number
}

@State<VoucherStateModal>({
  name: 'VoucherState',
  defaults: {
    voucherNum: null,
    valid: false,
    loaded: false,
    loading: false,
    status: null,
    hasError: false,
    error: null,
    voucherPool :  [],
    voucher_amount: null
  }
})
@Injectable()
export class VoucherState {
  constructor(private store: Store, private _vSvc: VoucherService, private cartSVC: CartService) {}

  @Selector()
  static isLoading(state: VoucherStateModal) {
    return state.loading;
  }

  @Selector()
  static isLoaded(state: VoucherStateModal) {
    return state.loaded;
  }

  @Selector()
  static isValid(state: VoucherStateModal) {
    return state.valid;
  }

  @Selector()
  static getVoucherAmount(state: VoucherStateModal): number {
    if (state.voucher_amount) {
      return state.voucher_amount
    }
  }

  @Selector()
  static getVoucherCode(state: VoucherStateModal): string {
    if (state.voucherNum) {
      return state.voucherNum;
    }
  }

  @Selector()
  static getBlackFridayVoucherPools(state: VoucherStateModal) {
    const pool = {
      BF_1000: state.voucherPool?.find((pool) => {return pool.pool_name === "BF_1000"}),
      BF_500:  state.voucherPool?.find((pool) => {return pool.pool_name === "BF_500"}),
      BF_300: state.voucherPool?.find((pool) => {return pool.pool_name === "BF_300"}),
      BF_150: state.voucherPool?.find((pool) => {return pool.pool_name === "BF_150"}),
    }
    return pool;
  }


  @Selector()
  static getErrorMessage(state: VoucherStateModal): string {
    return state.error;
  }

  @Selector()
  static hasError(state: VoucherStateModal): boolean {
    return state.hasError;
  }

  @Action(fromVoucherActions.ClaimVoucher)
  ClaimVoucher(ctx: StateContext<VoucherStateModal>, action: fromVoucherActions.ClaimVoucher) {
    const pool = action.payload.pool;
    const state = ctx.getState();
    this._vSvc.getSelectedVoucherPool(pool).subscribe(
      {
        next: (res: {voucher_code: string}) => {
          const amount = state.voucherPool.find((p) => p.pool_name === pool)?.voucherValue;
          ctx.dispatch(new fromVoucherActions.ClaimVoucherSuccess({voucher_code: res.voucher_code, amount: amount}))
        }
      }
    );
  }

  @Action(fromVoucherActions.ClaimVoucherSuccess)
  ClaimVoucherSuccess(ctx: StateContext<VoucherStateModal>, action: fromVoucherActions.ClaimVoucherSuccess) {
    const payload = action.payload;
    ctx.patchState({
      voucherNum: payload.voucher_code,
      voucher_amount: payload.amount
    })

  }
  @Action(fromVoucherActions.CheckVoucherStatus)
  CheckVoucherStatus(ctx: StateContext<VoucherStateModal>, action: fromVoucherActions.CheckVoucherStatus) {
    const voucher = action.payload;

    ctx.patchState({
      loaded: true,
      loading: false,
      voucherNum: voucher,
      hasError: false,
      error: null,
      valid: true
    });

    this._vSvc.validateVoucherStatus(voucher)
    .subscribe({
        next: (res) => ctx.dispatch(new fromVoucherActions.CheckVoucherStatusSuccess(res.result)),
        error: (err) => ctx.dispatch(new fromVoucherActions.CheckVoucherStatusFail(err))
    })
  }

  @Action(fromVoucherActions.CheckVoucherStatusSuccess)
  CheckVoucherStatusSuccess(ctx: StateContext<VoucherStateModal>, action: fromVoucherActions.CheckVoucherStatusSuccess) {
    const status = action.payload;
    const valid = Boolean(status.status.toLocaleLowerCase() === 'active');
    const isCurrentCustomer = this.store.selectSnapshot(CoreState.isCurrentCustomer);

    ctx.patchState({
      loaded: true,
      loading: false,
      valid,
      status
    });
  }

  @Action(fromVoucherActions.CheckVoucherStatusFail)
  CheckVoucherStatusFail(ctx: StateContext<VoucherStateModal>, action: fromVoucherActions.CheckVoucherStatusFail) {
    ctx.patchState({
      loaded: false,
      loading: false,
      valid: false,
      hasError: true,
      error: 'Invalid code',
      voucherNum: null
    });
  }

  @Action(fromVoucherActions.Clear)
  Clear(ctx: StateContext<VoucherStateModal>, action: fromVoucherActions.Clear) {
    ctx.patchState({
      loaded: false,
      loading: false,
      valid: false,
      voucherNum: null,
      status: null,
      hasError: false,
      error: null
    });
  }

  @Action(fromVoucherActions.GetBlackFridayVoucher)
  getAllBlackFridayVoucherPools(ctx : StateContext<VoucherStateModal>){
    return this._vSvc.getAllBlackFridayPools()
        .pipe(
            tap(res => {
              if(res && res.pools) {
                ctx.dispatch(new fromVoucherActions.GetBlackFridayVoucherSuccess(res.pools))
              }
            })
        );
  }

  @Action(fromVoucherActions.GetBlackFridayVoucherSuccess)
  GetBlackFridayVoucherSuccess(ctx : StateContext<VoucherStateModal>,action: fromVoucherActions.GetBlackFridayVoucherSuccess){
    const pools = action.payload
    ctx.patchState({
      voucherPool: pools
    })
    }
    
}
