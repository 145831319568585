<div class="modal-container">
    <div class="close-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <br />
    <div class="modal-title">
        <h2>Stand a chance to WIN R100 000!</h2>
    </div>

    <div class="scan" *ngIf="(ap$ | async)!=='mobile'">
        <span class="modal-description">
            Scan the QR code below to enter the competition.
        </span>

        <div class="qr-code">
            <img [src]="qrCode | safe: 'url'" alt="QR Code" />
        </div>

        <span class="modal-description">
            By scanning the QR code and entering the competition you agree to the <a (click)="gotoTerms()">Ts & Cs</a>
        </span>
    </div>

    <div class="mobile-view" *ngIf="(ap$ | async)==='mobile'">
        <span class="modal-description">
            Click below to enter the competition.
        </span>

        <button class="btn btn-md btn-primary button" (click)="openUrl()">Enter</button>

        <span class="modal-description">By clicking “enter” and entering the competition you agree to the <a
                (click)="gotoTerms()">Ts & Cs.</a> </span>
    </div>
    
</div>