import { CACHE_AUTH_TOKEN } from './../models/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { CACHE_IDM_TOKEN } from '@models/constants';
import { IRemoteCoverageResult } from '@models/remote/remoteCoverageResult';
import { Result, Statuses } from '@models/result';
import { AuthenticationService } from './auth.service';
import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { CoverageResultDetail, ICoverageDetail } from './coverageDetail';
import { INeighbourDetail, NeighbourDetail } from './neighbourDetail';
import { ServiceHelper } from './serviceHelper';
import { TokenService } from './token.service';


@Injectable({
  providedIn: 'root'
})
export class CoverageService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private cacheService: CacheService,
    private authService: AuthenticationService,
    private tokenService: TokenService
  ) { }

  public check(latitude: number, longitude: number, type = '4g' || '5g'): Observable<Result<boolean>> {

    return this.get(latitude, longitude, type)
      .pipe(
        map((result: Result<ICoverageDetail[]>) => {

          if (result.status == Statuses.Success) {

            const hasCoverage = isEmpty(result.value) == false;

            return Result.success(hasCoverage);
          }
          else {
            return Result.success(false);
          }
        }));
  }

  public get(latitude: number, longitude: number, type = '4g' || '5g'): Observable<Result<ICoverageDetail[]>> {

    const params = {
      "longitude": longitude.toString(),
      "latitude": latitude.toString(),
      "type": type.toString()
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)

      }),
      params
    };

    const url = `${this.configService.DEVOPS_URL}/map/coverage/`;

    return this.http.get(url, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((remote: IRemoteCoverageResult) => {

        const result = CoverageResultDetail.adapt(remote);

        return Result.success(result);
      }),
      catchError(result => ServiceHelper.handleError<ICoverageDetail[]>(result))
    );
  }


  public getNeighbours(siteId: string): Observable<Result<INeighbourDetail[]>> {

    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: "Bearer " + idmToken,
      })
    };

    const requestPath = `${this.configService.AXIOM_IDM_URL}/state/findneighbouring/${siteId}`;

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: []) => {

        const neighbours = result.map(x => NeighbourDetail.adapt(x));

        return Result.success(neighbours);
      }),
      catchError(result => ServiceHelper.handleError<INeighbourDetail[]>(result))
    );
  }
}
