<div class="port-cancellation-container">
    <div class="close-port-in-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')" />
    </div>
    <br>
    <div class="port-header">
        <h3>port <span class="bold-text">cancellation successful</span>
        </h3>
    </div>
    <div class="port-content">
        <p>Your porting request was cancelled successfully.</p>
    </div>
    <div class="port-in-status-button d-flex justify-content-center">
        <button-v2 [buttonClass]="'cancel-modal-btn'" [btnPrimary]="true" [text]="'got it '" (click)="close()"></button-v2>
    </div>
</div>