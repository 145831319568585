import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RainInputComponent } from './elements/rain-input/rain-input.component';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { ErrorKeysPipe } from './pipes';

import { RainCheckboxComponent } from './elements/rain-checkbox/rain-checkbox.component';
import { RainGooglePlacesInputComponent } from './elements/rain-google-places-input/rain-google-places-input.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { RainTextareaComponent } from './elements/rain-textarea/rain-textarea.component';
import { RainInputSalesModalComponent } from './elements/rain-input-sales-modal/rain-input-sales-modal.component';
import { NgxMaskModule } from 'ngx-mask';

const declarations = [
  RainInputComponent,
  RainGooglePlacesInputComponent,
  ErrorKeysPipe,
  RainCheckboxComponent,
  RainTextareaComponent,
  RainInputSalesModalComponent
]


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    declarations
  ],
  providers: [FormGroupDirective],
  exports: declarations
})
export class RainFormsModule { }
