export enum Statuses {
  None = 0,
  Success,
  NotFound,
  Error,
  Conflict,
  Invalid,
  Timeout
}

export class Result<T> {

  static timeout<U>(message: string): Result<U> {
    const result = new Result<U>();
    result.status = Statuses.Timeout;
    result.message = message;
    return result;
  }

  static invalid<U>(message: string): Result<U> {
    const result = new Result<U>();
    result.status = Statuses.Invalid;
    result.message = message;
    return result;
  }

  static conflict<U>(message: string = "Conflict"): Result<U> {
    const result = new Result<U>();
    result.status = Statuses.Conflict;
    result.message = message;
    return result;
  }

  value: T;

  status: Statuses;

  message: string;

  public static success<U>(value: U): Result<U> {
    const result = new Result<U>();
    result.status = Statuses.Success;
    result.message = "Success";
    result.value = value;
    return result;
  }

  public static error<U>(message: string): Result<U> {
    const result = new Result<U>();
    result.status = Statuses.Error;
    result.value = null;
    result.message = message;
    return result;
  }

  public static notFound<U>(): Result<U> {
    const result = new Result<U>();
    result.status = Statuses.NotFound;
    result.value = null;
    result.message = "Not Found";
    return result;
  }
}
