import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RainOneWifiLevel } from 'src/app/core/interfaces/rain-one-level.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ProductState } from 'src/app/store/state/product.state';
import { ServicesState } from 'src/app/store/state/services.state';

@Component({
  selector: 'rain-one-wifi-levels',
  templateUrl: './rain-one-wifi-levels.component.html',
  styleUrls: ['./rain-one-wifi-levels.component.scss']
})
export class RainOneWifiLevelsComponent implements OnInit {
  @Input() isSelectable: boolean;
  @Input() overrideSelectedLevel: string = null;
  @Output() selectedLevel: EventEmitter<RainOneWifiLevel> = new EventEmitter(null);
  @Input() levels: Array<RainOneWifiLevel>;
  @Select(CoreState.activeBreakpoint) public ap$: Observable<string>;
  public activeSlide = 0;
  private previousSlide = 0;
  public isWorkProduct;
  
  
  constructor(@Inject(DOCUMENT) private document: Document, private store: Store, ) {

  }

  ngOnInit(): void {
    const id = this.overrideSelectedLevel ? this.overrideSelectedLevel : '1';
    this.setSelectedLevel(id)
    const svc = this.store.selectSnapshot(ServicesState.SelectedService);
    const svcProduct = this.store.selectSnapshot(ProductState.GetProductById(svc.productId))
    this.isWorkProduct = svcProduct.name.includes('work');

  }

  public setSelectedLevel(id: string) {
    
    if (!this.isSelectable) return;
    
    this.levels = this.levels.map(l => {
      if(!l.isSelectable) return l;
      
      l.selected = l.id === id; 
      l.includedInSelection = l.id < id;
      return l;
    });
    
    const selectedLevel = this.levels.find(l => l.id === id);
    return this.selectedLevel.emit(selectedLevel);
    
  }

  public addLevel(id: string) {
    const selectedLevel = this.levels.find(l => l.id === id);
    return this.selectedLevel.emit(selectedLevel);
  }

  public prev(idOverride?: number) {
    
    this.previousSlide = this.activeSlide;
    if(idOverride) {
      this.activeSlide = idOverride;  

    } else {
      this.activeSlide--;
    }
    this.moveSliderRight();
    this.setSelectedLevel(this.activeSlide.toString());
    
  }

  public next(idOverride?: number) {
    this.previousSlide = this.activeSlide;
    if(idOverride) {
      this.activeSlide = idOverride;  
    } else {
      this.activeSlide++;
    }
    
    this.moveSliderLeft();
    this.setSelectedLevel(this.activeSlide.toString());
  }

  private getActiveSlide():HTMLLIElement {
    return this.document.getElementById(this.activeSlide.toString()) as HTMLLIElement;
  }

  public calcScale(index: string) {
    if(this.activeSlide.toString() === index) return '';
  }


  private moveSliderRight() {
    const wrapperEl = this.document.querySelector('.wifi-level-wrapper') as HTMLElement;
    if(wrapperEl) return wrapperEl.style.left = this.activeSlide === 0 ? '0px' : `-${this.activeSlide * 81.5}vw`;
  }

  private moveSliderLeft() {
    const wrapperEl = this.document.querySelector('.wifi-level-wrapper') as HTMLElement;

    if(wrapperEl) return wrapperEl.style.left = this.activeSlide === 0 ? '0px' : `-${this.activeSlide * 81.5}vw`;
  }

  public selectslide(id) {
    if(this.activeSlide === 0 || this.activeSlide < id) {

      return this.next(id);
    }

    if(this.activeSlide > id ) {
      return this.prev(id);
    }
  }

}

