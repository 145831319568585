

import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { AddTicketCreationTimer, GetTicketCreationTimerStatus } from "../actions/limit-ticket-creations.actions";
import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@components/sales-modal/sales.functions";
export interface LimitSalesTicketCreationStateModel {
  isTicketCreationAllowed: boolean;

}

@State<LimitSalesTicketCreationStateModel>({
  name: 'limit_sales_ticket_creation_state',
  defaults: {
    isTicketCreationAllowed:false
  }
})
@Injectable({
  providedIn: 'root'
})
export class LimitSalesTicketCreationState {
  constructor() {}

  @Selector()
  static isTicketCreationAllowed(state: LimitSalesTicketCreationStateModel) {
    return state.isTicketCreationAllowed;
  }


  @Action(AddTicketCreationTimer)
  addTicketCreationTimer(ctx: StateContext<LimitSalesTicketCreationStateModel>,action: AddTicketCreationTimer) {
    const { expirationInHours } = action;
    setLocalStorageWithExpiration('sales-ticket-created', true, expirationInHours);
  }

  @Action(GetTicketCreationTimerStatus)
  getTimerStatus(ctx: StateContext<LimitSalesTicketCreationStateModel>) {
  
    const isTicketCreationAllow = getLocalStorageWithExpiration('sales-ticket-created');
    ctx.patchState({
        isTicketCreationAllowed: isTicketCreationAllow
    })

  }
}