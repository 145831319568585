<div class="modal-container">
    <div>
        <div class="modal-img-close-btn z-index-5">
            <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')" />
        </div>
    </div>
    <div class="modal-body">
        <div class="description">
            <h2>add 4G phone line</h2>
            <p>This 4G phone SIM includes a FREE once-off gig<br>
                to get you started. You can buy more gigs, sms and minutes<br>
                when you need them on the myrain app.
            </p>
        </div>

        <div class="addon-wrapper card">
            <div class="top-row">
                <img src="assets/images/rain-one/rain-one-free-data-sim@2x.png" alt="Rain One 4G">
                <div class="pricing">
                    <span>R50</span>
                    <sub>once-off</sub>
                </div>
            </div>

            <div class="bottom-row">
                <ul>
                    <li>4G phone SIM</li>
                    <li>1 once-off gig included (no expiry)</li>
                    <li>Buy more gigs, minutes & sms anytime</li>
                </ul>
            </div>
        </div>
        <div class="actions-footer">
            <button class="btn btn-sm btn-primary text-white" (click)="addLine()">add to cart</button>
        </div>
    </div>
</div>