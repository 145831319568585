import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportButtonModule } from '@components/support-button/support-button.module';
import { LoginComponent } from './login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArrearsModalComponent } from './arrears-modal/arrears-modal.component';
import { PaymentRunModalComponent } from './payment-run-modal/payment-run-modal.component';
import { PayLaterModalComponent } from './arrears-modal/pay-later-modal/pay-later-modal.component';
import { WhatsappNotificationComponent } from './whatsapp-notification-modal/whatsapp-notification-modal.component';
import { RainFormsModule } from 'src/app/shared/components/rain-forms/rain-forms.module';
import { PromoQualifierComponent } from '../../pages/my-rain/promo-qualifier/promo-qualifier.component';
import { ServiceSelectionModalComponent } from '../../pages/my-rain/service-selection-modal/service-selection-modal.component';
import { SevenDaysProductModalComponent } from './seven-days-product-modal/seven-days-product-modal.component';
import { BlackFridayUpgradedModalModule } from '@components/black-friday-upgraded-modal/black-friday-upgraded-modal.module';
import { TakeMyMoneyComponent } from './take-my-money/take-my-money.component';
import { ProductNavModule } from "../../pages/home/product-nav/product-nav/product-nav.module";
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    LoginComponent,
    ArrearsModalComponent,
    WhatsappNotificationComponent,
    PaymentRunModalComponent,
    PayLaterModalComponent,
    SevenDaysProductModalComponent,
    TakeMyMoneyComponent,
  ],
  imports: [
    CommonModule, 
    SupportButtonModule, 
    FormsModule, 
    ReactiveFormsModule, 
    RouterModule, 
    RainFormsModule, 
    BlackFridayUpgradedModalModule, 
    ProductNavModule,
    SharedModule
  ],
  exports: [LoginComponent],
  providers: []
})
export class LoginModule {}
