import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import { UpdateRainOneBundleDescription } from "../actions/copy.actions";

export interface CopyStateModel {
    rain_one_bundle_description: {title: string, speedDescription: string, routerDescription: string}
}

@State<CopyStateModel>({
  name: 'copy',
  defaults: {
    rain_one_bundle_description: {
        title: 'unlimited 5G home wifi',
        speedDescription: 'Speeds up to 30Mbps',
        routerDescription: 'Free-to-use router'
    }
  }
})
@Injectable({ providedIn: 'root' })
export class CopyState {

  @Selector([CopyState])
  static GetRainOneBundleDescription(state: CopyStateModel) {
    return state.rain_one_bundle_description;
  }

  @Action(UpdateRainOneBundleDescription)
  UpdateRainOneBundleDescription(state: StateContext<CopyStateModel>, action: UpdateRainOneBundleDescription) {
    const payload = action.payload;
        state.patchState({
            rain_one_bundle_description: payload
        });
  }
 
}
