import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'rain-vas-removal-disclaimer-modal',
  templateUrl: './vas-removal-disclaimer-modal.component.html',
  styleUrls: ['./vas-removal-disclaimer-modal.component.scss']
})
export class VasRemovalDisclaimerModalComponent implements OnInit {
  @Select(CoreState.activeBreakpoint) ap$: Observable<string>;
  // @Output() hasAgreedToStillPurchase: EventEmitter<Boolean> =  new EventEmitter();
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

}
