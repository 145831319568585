export const returnUserIdFromTokens = (tokens: any): string => {

    const splitToken = tokens.idm.split('.');
    const user = JSON.parse(atob(splitToken[1]));

    if (!user?.user_id) {
        return;
    }

    return user?.user_id;
};