import * as NumberPortingActions from '../actions/number-porting-actions';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { NumberPortingService } from '@services/number-porting.service';
import { SetServiceId, SetMultiServiceIds, SetServiceType, ResetPortStatusById } from '../actions/number-porting-actions';
import {
  PortCancelled,
  PortInCreateReqRemote,
  PortInStatus,
  PortOutBody,
  PortOutResponse,
  PortStatus
} from '@pages/service-page/rain-one-actions/port-my-number/port-in.interface';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

export interface PortMyNumberStateModel {
  loaded: boolean,
  numberPorting: PortInCreateReqRemote;
  isPending: boolean;
  isCancelled: boolean;
  otp: string;
  id: string;
  portStatus: typeof PortStatus;
  serviceId: string;
  portDateString: string;
  servicesIdArray: {
    id: string,
    status: typeof PortStatus
    loaded: boolean
  }[]
  portOutStatus: PortOutResponse
}
@State<PortMyNumberStateModel>({
  name: 'PortMyNumberStatModel',
  defaults: {
    loaded: false,
    numberPorting: {
      service_id: '',
      from_msisdn: '',
      service_type: '',
      account_type: '',
      account_id: null,
      account_number: null,
      company_registration_number: '',
      contact_person: '',
      contact_number: null,
      account_category: ''
    },
    isPending: false,
    isCancelled: false,
    otp: '',
    id: '',
    portStatus: null,
    serviceId: '',
    portDateString: '',
    servicesIdArray: [],
    portOutStatus: {
      account_id: '',
      account_number: '',
      account_type: '',
      email: '',
      from_msisdn: '',
      id: '',
      inserted_at: '',
      port_reference: '',
      rain_msisdn: '',
      service_id: '',
      service_type: '',
      status: '',
      user_id: '',
    }
  }
})
@Injectable({ providedIn: 'root' })
export class NumberPortingState {
  constructor(private numberPortingService: NumberPortingService, public activeModal: NgbActiveModal) { }

  @Selector()
  static isLoaded(state: PortMyNumberStateModel) {
    return state.loaded;
  }
  @Selector()
  static getServiceId(state: PortMyNumberStateModel) {
    return state.serviceId;
  }

  @Selector()
  static getServiceType(state: PortMyNumberStateModel) {
    return state.numberPorting.service_type;
  }
  @Selector()
  static getServicesStatus(state: PortMyNumberStateModel) {
    return state.servicesIdArray;
  }

  @Selector()
  static getPortInStatus(state: PortMyNumberStateModel) {
    return state.isPending;
  }
  @Selector()
  static getCancellationStatus(state: PortMyNumberStateModel) {
    return state.isCancelled;
  }
  @Selector()
  static getPortOutId(state: PortMyNumberStateModel) {
    return state.id;
  }
  @Selector()
  static getPortOutOtp(state: PortMyNumberStateModel) {
    return state.otp;
  }
  @Selector()
  static getPortResponseStatus(state: PortMyNumberStateModel) {
    return state.portStatus;
  }

  @Selector()
  static getMultiServiceIds(state: PortMyNumberStateModel) {
    return state.servicesIdArray.map(services => {
      return services
    });
  }

  static fetchPortStatusById(serviceId: string) {
    return createSelector([NumberPortingState.getServicesStatus], (servicePortStatus: { id: string, status : typeof PortStatus }[]) => {
      return servicePortStatus.find(service => service.id === serviceId)?.status;
    });
  }

  @Selector()
  static portFailure(state: PortMyNumberStateModel) {
    return (state.portStatus.PORT_REQUEST_REJECTED || state.portStatus.PORT_REQUEST_REJECTED_MESSAGE_SENT) ? true : false;
  }

  @Selector()
  static getPortDateString(state: PortMyNumberStateModel) {
    return state.portDateString;
  }

  // Port out selectors 

  @Selector()
  static getPortOutStatusId(state: PortMyNumberStateModel) {
    return state.portOutStatus.id;
  }
  @Selector()
  static getPortOutStatusEmail(state: PortMyNumberStateModel) {
    return state.portOutStatus.email;
  }
  @Selector()
  static getPortOutStatusSertviceId(state: PortMyNumberStateModel) {
    return state.portOutStatus.service_id;
  }
  @Selector()
  static getPortOutStatus(state: PortMyNumberStateModel) {
    return state.portOutStatus;
  }
  /* -----*/

  @Action(NumberPortingActions.SetServiceId)
  setServiceId(ctx: StateContext<SetServiceId>, action: NumberPortingActions.SetServiceId) {
    ctx.patchState({
      serviceId: action.serviceId
    });
  }

  @Action(NumberPortingActions.PortSimPending)
  updatePortInStatus(ctx: StateContext<PortInStatus>, action: NumberPortingActions.PortSimPending) {
    ctx.patchState({
      isPending: action.pendingStatus
    });
  }

  @Action(NumberPortingActions.numberPortIsCancelled)
  portIsCancelled(ctx: StateContext<PortCancelled>, action: NumberPortingActions.numberPortIsCancelled) {
    ctx.patchState({
      isCancelled: action.cancelled
    });
  }
  @Action(NumberPortingActions.SetMultiServiceIds)
  SetMultiServiceIds(ctx: StateContext<SetMultiServiceIds>, action: NumberPortingActions.SetMultiServiceIds) {
    ctx.patchState({
      servicesIdArray: action.servicesIdArray
    });
  }

   @Action(NumberPortingActions.ResetPortStatusById)
   ResetPortStatusById(ctx: StateContext<PortMyNumberStateModel>, action: NumberPortingActions.ResetPortStatusById) {
    const updatedService = {
      id : action.serviceId,
      status: null,
      loaded : true
    }
    const updatedStatuses = ctx.getState().servicesIdArray?.map(service => {
      if (service.id === action.serviceId) {
        return updatedService
      }
      return service
    })
    ctx.patchState({
      servicesIdArray: updatedStatuses
    });
  }

  @Action(NumberPortingActions.SetServiceType)
  SetServiceType(ctx: StateContext<PortMyNumberStateModel>, action: SetServiceType) {
    ctx.patchState({
      numberPorting: {
        service_type: action.serviceType
      }
    });

    
  }

  @Action(NumberPortingActions.PortSim)
  PortSim(ctx: StateContext<PortMyNumberStateModel>, action: NumberPortingActions.PortSim) {
    const body: PortInCreateReqRemote = {
      service_id: action.payload.service_id,
      from_msisdn: action.payload.from_msisdn,
      service_type: action.payload.service_type,
      account_type: action.payload.account_type,
      account_id: action.payload.account_id,
      account_number: action.payload.account_number,
      company_registration_number: action.payload.company_registration_number,
      contact_person: action.payload.contact_person,
      contact_number: action.payload.contact_number,
      account_category: action.payload.account_category
    };
    return this.numberPortingService.createPortInRequest(body);
  }

  @Action(NumberPortingActions.CancelPortProcess)
  CancelPortProcess(ctx: StateContext<PortMyNumberStateModel>, action: NumberPortingActions.CancelPortProcess) {
    const serviceId = action.serviceId
    return this.numberPortingService.cancelPortInById(serviceId).subscribe(res => {
      ctx.patchState({
        portStatus: res?.result?.port_response?.status
      })
    })
  }

  @Action(NumberPortingActions.PortOut)
  PortOut(ctx: StateContext<PortMyNumberStateModel>, action: NumberPortingActions.PortOut) {
    const body: PortOutBody = {
      id: action.payload.id,
      otp: action.payload.otp
    };
    return this.numberPortingService.portOutRequest(body);
  }

  @Action(NumberPortingActions.GetPortStatusById)
  GetPortStatusById(ctx: StateContext<PortMyNumberStateModel>, action: NumberPortingActions.GetPortStatusById) {
    const { serviceId } = action;
    return this.numberPortingService.getCurrentPortStatusByServiceId(serviceId).pipe(
      take(1),
      tap({
        next: (res) => {
          const updatedService = {
            id: serviceId,
            status: res?.result?.port_response?.status,
            loaded : true
          }
          const updatedStatuses = ctx.getState().servicesIdArray?.map(service => {
            if (service.id === serviceId) {
              return updatedService
            }
            return service
          })


          ctx.patchState({
            portDateString: res?.result?.port_response?.inserted_at,
            portStatus: res?.result?.port_response?.status,
            loaded: true,
            servicesIdArray : updatedStatuses
          });
        },
        error: (err) => {
          console.log('Error fetching port status:', err);
          // if (err?.error && err?.error?.errors?.some((msg) => msg === "Port In Not Found")) {
            console.log("no port-in found");
            ctx.patchState({
              loaded: true
            });
          // }
        }
      }),
      catchError(error => {
        return EMPTY;
      })
    )
  }

  @Action(NumberPortingActions.GetPortOutStatusById)
  GetPortOutStatusById(ctx: StateContext<PortMyNumberStateModel>, action: NumberPortingActions.GetPortOutStatusById) {
    return this.numberPortingService.getPortOutById(action.serviceId).subscribe(
      res => {
        const portResponse = res?.result?.port_response;
        if (portResponse) {
          ctx.patchState({
            portOutStatus: {
              account_id: portResponse?.account_id,
              account_number: portResponse?.account_number,
              account_type: portResponse?.account_type,
              email: portResponse?.email,
              from_msisdn: portResponse?.from_msisdn,
              id: portResponse?.id,
              inserted_at: portResponse?.inserted_at,
              port_reference: portResponse?.port_reference,
              rain_msisdn: portResponse?.rain_msisdn,
              service_id: portResponse?.service_id,
              service_type: portResponse?.service_type,
              status: portResponse?.status,
              user_id: portResponse?.user_id
            }
          });
        } else {
          console.log('No port response found.');
        }
      },
      error => {
        console.error('Error fetching port out status:', error);
      }
    );
  }
  

  @Action(NumberPortingActions.ClearState)
  ClearState(ctx: StateContext<PortMyNumberStateModel>, action: NumberPortingActions.ClearState) {
    ctx.patchState({
      loaded: false,
      numberPorting: {
        service_id: '',
        from_msisdn: '',
        service_type: '',
        account_type: '',
        account_id: null,
        account_number: null,
        company_registration_number: '',
        contact_person: '',
        contact_number: null,
        account_category: ''
      },
      isPending: false,
      isCancelled: false,
      otp: '',
      id: '',
      portStatus: null,
      serviceId: '',
      portDateString: '',
      servicesIdArray : []
    })
  }
}
