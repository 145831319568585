import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {takeUntil} from 'rxjs/operators';
import {isNil} from 'lodash';
import {BaseComponent} from 'src/app/baseComponent';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IAddTicketRequest, SupportService} from '@services/support.service';
import {Store} from '@ngxs/store';
import {CoreState} from 'src/app/core/store/state/core.state';
import {ToastrService} from 'ngx-toastr';
import {ServicesState} from 'src/app/store/state/services.state';

@Component({
    selector: 'app-service-actions',
    templateUrl: './speed-up-actions.component.html',
    styleUrls: ['./speed-up-actions.component.scss']
})
export class SpeedUpActionsComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
    @Input() msisdn;

    loading: boolean;
    user
    serviceId: string;
    productId: string;
    service: any;
    userSpeedForm: FormGroup;
    ticketRequest: IAddTicketRequest;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private store: Store,
        private supportService: SupportService,
        private toastr: ToastrService,
        public activeModal: NgbActiveModal,
    ) {
        super();
    }

    ngOnInit() {


        this.user = this.store.selectSnapshot(CoreState.getUserProfile)
        this.service = this.store.selectSnapshot(ServicesState.SelectedService)
        this.userSpeedForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required]),
            contactNumber: new FormControl('', [Validators.required]),
            selectedSpeed: new FormControl('', [Validators.required])
        });

        if (this.user) {

            this.userSpeedForm.setValue({
                name: `${this.user.firstName + '' + this.user.lastName}`,
                email: `${this.user.email}`,
                contactNumber: `${this.user.phone}`,
                selectedSpeed: `30`

            });
        }
    }


    submit() {
        const selectedSpeed = this.userSpeedForm.controls.selectedSpeed.value;
        const subject = `wifi-speed-up to: ${selectedSpeed} Mbps for ${this.msisdn}`
        const body = {
            request: `I would please like to increase my wifi speed to ${selectedSpeed} Mbps`,
            contactNumber: this.userSpeedForm.get('contactNumber').value,
        }

        this.ticketRequest = {
            name: this.userSpeedForm.get('name').value,
            email: this.userSpeedForm.get('email').value,

            subject,
            referenceId: this.service.msisdn,
            body,
            captcha: '-'
        };
        this.submitForm()
    }

    openModal() {
        const modalRef = this.modalService.open(this.modalContent, {
            size: 'sm',
            windowClass: 'slideInUp',
            centered: true
        })

    }


    submitForm() {
        this.supportService.addTicket(this.ticketRequest)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result: any) => {
                this.loading = false;
                if (isNil(result) === false) {
                    if (!result?.ticket) {
                        setTimeout(() => this.toastr.error('An error occurred while submitting. Please try again', null));
                        return;
                    } else {
                        this.activeModal.close();
                        this.openModal()
                    }
                }

            });
    }

    dismiss() {
        this.modalService.dismissAll();
    }
}
