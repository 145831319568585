<div class="cancel-five-g-success p-15">
	<div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
          data-dismiss="modal" data-target="#surveyModal" src="../../assets/svg/close.svg" />
	</div>
	<div class="heading">
		<h1 class="text-primary text-center h1-text-transform">we're sorry to see you go</h1>
	</div>

	<div class="text-content text-center mt-10">
		<p>We’ve received your cancellation request. Please note that this  cancellation will only be finalised once your free-to-use 5G router has been returned to rain.</p>
	</div>

	<div class="actions d-flex justify-content-center mt-10">
		<button-v2 class="mx-2" [buttonClass]="'cancel-modal-btn'" [text]="'got it'" (click)="close()"></button-v2>
	</div>
</div>