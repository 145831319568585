import { IRemoteDevice } from './remote/remoteDevice';
import { ModelHelper } from './modelHelper';

/**
 * DeviceDetail @param
 */
export class DeviceDetail {
  static adapt(remote: IRemoteDevice): IDeviceDetail {
    /**
     * phoneDetail @param
     */
    const phoneDetail = {
      /**
       * brandName @param {null}
       */
      brandName: null,

      /**
       * model @param {null}
       */
      model: null,

      /**
       * brandLogo @param {ModelHelper.clean(remote.brand_logo)}
       */
      brandLogo: ModelHelper.clean(remote.brand_logo),

      /**
       * msisdn @param {ModelHelper.clean(remote.msisdn)}
       */
      msisdn: ModelHelper.clean(remote.msisdn),

      /**
       * image @param {ModelHelper.clean(remote.phone_image)}
       */
      image: ModelHelper.clean(remote.phone_image),

      /**
       * imei @param {ModelHelper.clean(remote.imei)}
       */
      imei: ModelHelper.clean(remote.imei)
    };

    return phoneDetail;
  }
}

/**
 * IDeviceDetail @param
 */
export interface IDeviceDetail {
  /**
   * msisdn @param {string}
   */
  msisdn: string;

  /**
   * brandName @param {string}
   */
  brandName: string;

  /**
   * brandLogo @param {string}
   */
  brandLogo: string;

  /**
   * model @param {string}
   */
  model: string;

  /**
   * image @param {string}
   */
  image: string;

  /**
   * imei @param {string}
   */
  imei: string;
}
