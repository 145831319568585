<div class="modal-container">
    <div class="modal-header border-0">
    </div>
    <div class="modal-body p-0  z-index-1">
        <div class="header">
            <div class="header-col-left">
                <h2>heads up</h2>
                <p>This rain<b>one work</b> will be registered in your name.<br>
                  Proceeding means that you accept the RICA <a (click)="viewTsAndCs()">Ts&Cs</a></p>
            </div>
        </div>

        <div class="actions-footer-btns">
            <button  class="btns rety-btn" (click)="retry(false)">retry</button>
            <button  class="btns proceed-btn" (click)="proceed(true)">proceed</button>
        </div>
    </div>
</div>
