import { Injectable } from "@angular/core";
import { RainAnalyticsTrackerClick } from "../store/interfaces/rain-analytics-tracker";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigService } from "./config.service";

@Injectable({
    providedIn: 'root'
})
export class RainAnalyticsTrackerService {

    constructor(
        private http: HttpClient,
        private configService: ConfigService) {  }



    pushButtonClick(payload: RainAnalyticsTrackerClick) {

        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              apiKey: this.configService.TOGGLE_API_KEY
            })
          };

        const url = `${this.configService.BASE_API_URL}/bss-api/v1/clicky-thingy/click-tracking`;

        return this.http.post<RainAnalyticsTrackerClick>(url, payload, httpOptions);

    }
}