<div class="modal-container" *ngIf="ap$ | async as ap" [class]="'is-' + ap" [class.is-purchase]="!isMigrating">
    <div class="modal-header border-0">
        <div class="modal-img-close-btn z-index-5">
            <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')" />
        </div>
    </div>
    <div class="modal-body p-0 z-index-1">
        <div class="header">
            <div class="header-col-left">
                <h2>level up <span [style.fontWeight]="300">your 4G mobile lines</span></h2>
                <p>Get more data & calls for <span [style.fontWeight]="700">R{{isSmeService ? 150 : 75}}</span> a level.</p>
            </div>
            <div class="header-col-right" *ngIf="selectedLevel">
                <div class="add-to-cart-card" >
                    <div class="content">
                        <div class="header">
                            <span *ngIf="selectedLevel">{{selectedLevel?.level}} levels</span>
                            <span *ngIf="!selectedLevel">select a level</span>
                        </div>
                        <div class="body">
                            <div class="col-left" *ngIf="selectedLevel">
                                <span>at R{{isSmeService ? 150 : 75}} per level</span>
                            </div>
                            <div class="col-right" *ngIf="selectedLevel">
                                <span>R{{selectedLevel?.cost}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="levels">
            <rain-one-levels [levels]="levels" [isSelectable]="true" [selectedLevelId]="'1'" (selectedLevel)="captureLevel($event)"></rain-one-levels>
        </div>
        <div class="actions-footer">
            <!-- <button (click)="removeLevels()" *ngIf="currLevel !== 0 && isMigrating" [class.invisible]="currLevel === 0"  class="btn btn-sm btn-primay btn-outline-primary footer-btn">move to rainOne</button> -->
            <button (click)="onLevelSelect()" [disabled]="!selectedLevel || selectedLevel?.name?.includes(currLevel)" class="btn btn-sm btn-primary footer-btn text-white"  [title]="isMigrating ? 'Cannot change levels while already migrating': '' ">confirm</button>
        </div>
    </div>
</div>


<ng-template #ConfirmModalContent>
     <div class="mb-4 modal-header border-0 ">
    <div class="modal-img-close-btn z-index-5">
      <img alt="Close" aria-label="Close" class="pointer" (click)="dismiss()" src="../../assets/svg/close.svg" />
    </div>
  </div>
  <div class="modal-body border-0 p-5 text-center">
    <div class="p-2">
      <h1 class="text-primary">confirm your level change</h1>
    </div>
   <div class="p-2 pl-20 text-left">
    <p>You are moving from <b><span class="text-primary">{{levelMap.get(currLevel) !== 'rainOne' ? 'level ' + levelMap.get(currLevel) : levelMap.get(currLevel)}}</span></b> to <b><span class="text-primary">{{baseLevelSelected ? 'rainOne' : 'level ' + levelMap.get(selectedLevel.level)}}</span></b></p>

    <ul class="pl-10">
        <li *ngIf="!baseLevelSelected">
            <p class="pl-5 d-inline-block">You will be billed <b><span class="text-primary">R{{selectedLevel.cost}}</span></b> month-to-month.</p>
        </li>
        <li >
            <p class="pl-5 d-inline-block"> Your level change will apply to your <b>{{selectedLevel?.maxLines === 1 ? '1 mobile phone line.' :  (isSmeService ? '5 mobile phone lines.' : '2 mobile phone lines.') }}</b></p>
        </li>
        <li *ngIf="(currLevel > selectedLevel?.level) || baseLevelSelected">
            <p class="pl-5 d-inline-block" >Your new level will take effect at your next bill.</p> 
        </li>
        <li *ngIf="isMigrating && (currLevel < selectedLevel?.level) && !baseLevelSelected">
            <p class="pl-5 d-inline-block" >Your new level and additional gigs and minutes will be activated immediately with no upfront charge.</p> 
        </li>

    </ul>
    

   </div>
  </div>
  
  <div class="p-8 mb-10 text-center" >
    
    <button class="btn btn-primary mx-5"
      (click)="confirm()">confirm</button>
  </div>
   
</ng-template>




 