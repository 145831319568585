import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { VerifySimComponent } from "./verify-sim.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CheckboxModule } from "@components/checkbox/checkbox.module";
import { InfoComponent } from "@pages/user-register-page/info-modal/info-modal.component";
import { TsAndCsComponent } from "@pages/user-register-page/ts-andcs-modal/ts-andcs-modal.component";
import { UserRegisterPageRoutingModule } from "@pages/user-register-page/user-register-page-routing.module";
import { UserRegisterPageComponent } from "@pages/user-register-page/user-register-page.component";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { RainFormsModule } from "src/app/shared/components/rain-forms/rain-forms.module";
import { VerifySimRoutingModule } from "./verify-sim-routing.module";
@NgModule({
    declarations: [
        VerifySimComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        VerifySimRoutingModule,
    ],
    exports: [],
    providers: [],
})
    export class VerifySimModule { }