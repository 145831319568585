
import { Utils } from "src/app/utils";

export class AddTicketCreationTimer {
    static type = Utils.Helpers.Type('[Sales Modal: Start] Start Ticket Creation Timer');
    constructor(public expirationInHours: number) { }
}

export class GetTicketCreationTimerStatus {
    static type = Utils.Helpers.Type('[Sales Modal: GET] Get Ticket Creation Timer');
}

