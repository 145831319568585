import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TokenService } from "./token.service";
import { CACHE_AUTH_TOKEN } from "@models/constants";
import { IntlCallingStateModel } from "../store/state/intl-calling.state";
import { IntlCallResAdapter, IntlCallResBodyRemote } from "@models/remote/remoteIntlRes";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class IntlCallingService {
    constructor(private configSVC: ConfigService, private tokenService: TokenService, private http: HttpClient){}

    getCountry(country: string, page?: number): Observable<IntlCallingStateModel>{
        const requestPath = `${this.configSVC.BASE_API_URL}/bss-api/v1/international-rate-service/rate/_search`
        const pageNumber = page? page : 1
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        const body = {
            "page": pageNumber,
            "page_size": 20,
            "criteria": {"country":`${country}`},
            "sort": {}
          }

        return this.http.post<any>(requestPath,body,httpOptions).pipe(map((res) => 
        {  
            let adaptedRes = IntlCallResAdapter.adapt(res)
            return adaptedRes
        }))
    }

}

