<div class="mb-10  modal-header border-0">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
      data-dismiss="modal" data-target="#retractCancelSim" src="../../assets/svg/close.svg" />
  </div>
</div>

<div class=" p-5 modal-body border-0 ">
  <div class="row">
    <div class="col-12">
      <p class="text-primary text-center h2 font-weight-bold modal-heading">your contact details
      </p>
      <p class="pl-0 pl-sm-10 pr-0 pr-sm-10 text-center">
        Confirm or update your contact details now.
      </p>

      <div class="form-group address-search pl-5 pl-sm-30 pr-5 pr-sm-30 mt-10" [formGroup]="userForm">
        <!-- name -->
        <div class="form-group mt-5  mb-10">
          <sf-form-input label="name" maxlength="24" [control]="userForm.get('firstName')" [type]="'text'"
            [messages]="nameValidationMessages">
          </sf-form-input>
        </div>

        <!-- lastName -->
        <div class="form-group mt-13  mb-10">
          <sf-form-input maxlength="24" label="last name" [control]="userForm.get('lastName')" [type]="'text'"
            [messages]="lastNameValidationMessages">
          </sf-form-input>
        </div>

        <!-- cellNumber -->
        <div class="form-group mt-13  mb-10">
          <sf-form-input label="cellphone number" maxlength="12" [control]="userForm.get('phoneNumber')" [type]="'tel'"
            [messages]="cellNumberValidationMessages">
          </sf-form-input>
        </div>

        <!-- email -->
        <div class="form-group mt-13  mb-10">
          <sf-form-input label="email" [control]="userForm.get('email')" [type]="'email'"
            [messages]="emailValidationMessages">
          </sf-form-input>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-10 mb-10 mt-sm-15">

        <button [className]="
                    (!userForm.invalid ? 'btn-primary ' : 'btn-tertiary ') +
                    'btn btn-sm  mb-xl-0 mb-8 font-weight-medium text-white'
                  " class="btn btn-sm btn-primary  mb-xl-0 mb-8 font-weight-medium text-white" id="submit-address-btn"
          (click)="submit()">
          submit
        </button>
      </div>
    </div>


  </div>

</div>