import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserComplaintModalComponent } from './user-complaint-modal/user-complaint-modal.component';
import { SimSwopModalComponent } from './sim-swop-modal/sim-swop-modal.component';
import { InvestigateModalComponent } from './investigate-modal/investigate-modal.component';
import { SupportButtonComponent } from './support-button.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { SalesModalComponent } from '@components/sales-modal/sales-modal.component';
import { RainFormsModule } from 'src/app/shared/components/rain-forms/rain-forms.module';

@NgModule({
    declarations: [
        SupportButtonComponent,
        InvestigateModalComponent,
        SimSwopModalComponent,
        UserComplaintModalComponent,
        ConfirmationModalComponent,
        SalesModalComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RainFormsModule,
        GooglePlaceModule
    ],
    exports: [
        SupportButtonComponent,
        InvestigateModalComponent,
        SimSwopModalComponent,
        UserComplaintModalComponent,
        ConfirmationModalComponent,
        SalesModalComponent
    ],
    providers: [],
})
export class SupportButtonModule { }