import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-timeout-modal',
  templateUrl: './payment-timeout-modal.component.html',
  styleUrls: ['./payment-timeout-modal.component.scss']
})
export class PaymentTimeoutModalComponent implements OnInit {

  @Input()
  message: string;

  @Input()
  routeText: string;

  @Input()
  route: string;

  static show(modalService: NgbModal, message: string, routeText: string = null, route: string = null): Observable<boolean> {

    const options: NgbModalOptions = { size: <any>'confirm', windowClass: 'slideInUp', centered: true };

    const modalRef = modalService.open(PaymentTimeoutModalComponent, options);

    modalRef.componentInstance.message = message;

    modalRef.componentInstance.routeText = routeText;
    modalRef.componentInstance.route = route;

    return from(modalRef.result);
  }

  constructor(public activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit() {
  }


  gotoOrders() {
    this.activeModal.close(false);
    this.router.navigate([this.route]);
  }
}
