export class GetCountryRates{
    public static type = "[Intl Calling] get provider rates"
    constructor(public searchableCountry: string) {
        
    }
}
export class GetMoreCountryRates{
    public static type = "[Intl Calling] get more provider rates"
    // constructor(public searchableCountry: string) {}
}

export class ClearCountryRates{
    public static type= "[Intl Calling] clear provider rates"
}