<div [class]="'barcode-payment-lockup is-'+(activeBreakpoint$ | async) " *ngIf="accountNumber">
    <p class="amount text-primary">total: <span>{{amount | currency:"R"}}</span></p>
    <div class="barcode-details">
        <ngx-barcode class="pl-10 pr-10" [bc-value]="accountNumber" [bc-display-value]="false"></ngx-barcode>
        <p class="ref-number">Reference no. <span>{{accountNumber}}</span></p>

        <div class="branding">
            <div class="branding-lockup">
                <img src="/assets/images/payment-page/powered-by-pay.png">
            </div>
        </div>
    </div>

    <div class="instructions-container">
        <rain-accordion [title]="'want us to SMS it to you? '">
            <div>
                <p>To make things easier, we can SMS this reference number to you or a friend or family member. Just
                    enter
                    the cell phone number below that you’d like the reference number to be sent to.</p>
                <div class="form-container">
                    <sf-form-input [control]="_smsForm.get('tel_number')" [label]="'cellphone number'" [type]="'tel'"
                        [autocomplete]="'tel'">
                    </sf-form-input>
                    <button class="btn btn-md" [ngClass]="{'btn-primary': _smsForm.valid, 'btn-dark': !_smsForm.valid}"
                        id="button-search" id="button-shop" [disabled]="!_smsForm.valid" (click)="sendRef()">send
                        SMS</button>
                </div>
                <div class="status-message" *ngIf="SMSStatus !== null">
                    <p *ngIf="SMSStatus else unSuccessful" class="success small-font-size">Successfully sent your
                        reference number</p>
                    <ng-template #unSuccessful>
                        <p class="small-font-size">We were unable to send your reference number.</p>
                    </ng-template>
                </div>
            </div>
        </rain-accordion>
        <rain-accordion [title]="'how does it work?'">
            <ol>
                <li>Present your <strong>pay@ reference number</strong> to the cashier at a selected retail store for
                    them to enter or
                    scan
                    the bar code.</li>
                <li>Review and confirm the amount before making payment.</li>
                <li>Make cash payment and get your receipt.</li>
                <li>Your account will now be settled, sign into myrain to make sure.</li>
            </ol>
        </rain-accordion>
    </div>
    <div class="outlet-lockup">
        <img *ngIf="!isMobileScreen; else mobileImage" class="mb-10" width="100%"
            src="/assets/images/cash_payment_desktop.png">
        <ng-template #mobileImage>
            <img class="mb-10" width="100%" src="/assets/images/cash_payment_mobi.png">
        </ng-template>
    </div>

</div>