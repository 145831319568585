<div class="d-flex  align-items-center fixed-top z-index-6 drop-shadow" id="header-menu">
    <div class="d-flex flex-grow-1 align-items-center">
        <div class="burger pl-lg-35 pl-5">
            <button class="navbar-toggler  p-0 border-0" id="menu-modal" type="button" (click)="openSideNav()">
                <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1H24.9783M1 9.64873H24.9783M1 18.3456H24.9783" stroke="#787878" stroke-width="2"
                          stroke-miterlimit="10" stroke-linecap="round"/>
                </svg>
            </button>
        </div>
        <div class=" pl-10 logo  flex-fill mx-auto">
            <a [routerLink]="['/']" (click)="clear()">
                <svg class="rain-logo" viewBox="0 0 393.8 183">
                    <g fill="#BBBCBC">
                        <path
                                d="M278,178.8h37.4v-68c0-7.7,0-26.1,21.4-26.1c19.6,0,19.6,17.1,19.6,25.9v68.3h37.4v-78.2 c0-24.6-7.7-34.5-14.4-40.3c-6.8-5.9-20.1-10.8-32-10.8c-22.3,0-30.2,11.5-34,17.6h-0.5V53.7H278V178.8z M262.3,178.8h-37.4v-125 h37.4V178.8z M107.7,116.6c0-14.2,9.5-32.2,30.9-32.2c21.2,0,31.5,16.7,31.5,31.8c0,12.2-7.9,32-31.3,32 C116.3,148.1,107.7,128.3,107.7,116.6 M207.5,53.7h-37.4V67h-0.5c-4.5-7.7-16-17.6-36.5-17.6c-33.3,0-62.9,25.7-62.9,66.7 c0,35.8,23.2,66.9,62.9,66.9c14.6,0,30.2-5.6,36.5-17.8h0.5v13.5h37.4V53.7z M0,53.7h35.1V67h0.5c3.2-6.1,9.7-17.6,31.3-17.6v37.6 c-17.3,0.4-29.5,4.1-29.5,23v68.7H0V53.7z">
                        </path>
                    </g>
                    <g fill="#0283cf">
                        <path
                                d="M265.8,22.3c0-12.3-10-22.3-22.3-22.3c-12.3,0-22.3,10-22.3,22.3c0,12.3,10,22.3,22.3,22.3 C255.9,44.6,265.8,34.6,265.8,22.3">
                        </path>
                    </g>
                </svg>
            </a>
        </div>
    </div>
    <nav class="d-flex">
        <div class="ml-3">
            <a data-id="nav-click" (click)="goToAskRain()" class="text-menu medium-font-size">
                askrain
            </a>
        </div>
        <div [class.selected]="checkRoute('/login/home')">
            <a id="signOut" class="text-menu medium-font-size" data-id="nav-click" data-text='sign out'
               *ngIf="isSignedIn" (click)="signOut()">sign out</a>

            <a *ngIf="!isSignedIn" id="signIn" data-id="nav-click" data-text="sign in" (click)="onClickSignIn()"
               class="text-menu medium-font-size">
                sign in
            </a>

        </div>

        <div class="border-left border-tertiary h-26" *ngIf="showMenuItems"></div>
        <div class="cart-wrapper" *ngIf="showMenuItems" (click)="openCart()" [class.selected]="checkRoute('/cart')">
            <a
                    class="text-menu d-md-block d-none medium-font-size ml-5 cart-menu-item">
                cart
            </a>
            <div class="pl-4 pr-15 ">
                <div class="cart-link">
                    <div class="cart-bubble" [innerHTML]="cartCount"></div>
                </div>
            </div>
        </div>      
    </nav>
</div>