import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-nvidia-error-modal',
  templateUrl: './nvidia-error-modal.component.html',
  styleUrls: ['./nvidia-error-modal.component.scss']
})
export class NvidiaErrorModalComponent {

  @Input() reason: string

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.close()
  }

}
