import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    CONFIG_COMMENTS,
    CONFIG_FRIENDLY_NAME,
    NO_MORE_RAIN_MOBILE_MESSAGE,
    NO_MORE_RAIN_ONE_MESSAGE,
    NO_MORE_RAIN_ONE_WORK_MESSAGE,
    RAINONE_5G_SIM
} from '@models/constants';
import {Navigate} from '@ngxs/router-plugin';
import {Select, Store} from '@ngxs/store';
import {CartService} from '@services/cart.service';
import {SupportService} from '@services/support.service';
import {UserService} from '@services/user.service';
import {Observable, Subject} from 'rxjs';
import {concatMap, map, takeUntil} from 'rxjs/operators';
import {RainOneProduct} from 'src/app/core/interfaces/rain-one-products.interface';
import {CoreState} from 'src/app/core/store/state/core.state';
import {MigratingToR1FiveG, StartRainOnePurchase} from 'src/app/store/actions/cart.action';
import {ProductState} from 'src/app/store/state/product.state';
import {ServicesState} from 'src/app/store/state/services.state';
import {ConfigService} from '@services/config.service';
import {CartState} from 'src/app/store/state/cart.state';
import {AuthenticationService} from '@services/auth.service';
import {OrderState} from 'src/app/store/state/order.state';
import {CartErrorModalComponent} from '@pages/cart-page/cart-error-modal/cart-error-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BillingState} from 'src/app/core/store/state/billing.state';
import {ImageViewModel} from '../../../shared/selectors/image-view-model';
import {LimitSalesTicketCreationState} from '@components/sales-modal/store/state/limit-ticket-creation.state';
import {GetTicketCreationTimerStatus} from '@components/sales-modal/store/actions/limit-ticket-creations.actions';
import {AddonStatus} from 'src/app/store/actions/services.actions';
import {CookieService} from '@services/cookie.service';
import {ServiceStatuses} from '@models/serviceDetail';
import {UIState} from 'src/app/shared/store/state/ui.state';

const NUMBER_REPLACE = '{NUMBER}';

@Component({
    selector: 'rain-one-bundle',
    templateUrl: './rain-one-bundle.component.html',
    styleUrls: ['./rain-one-bundle.component.scss']
})
export class RainOneBundleComponent implements OnInit, OnDestroy {
    @Select(LimitSalesTicketCreationState.isTicketCreationAllowed) isTicketCreationAllowed$: Observable<boolean>;

    @Select(CoreState.activeBreakpoint) ap$: Observable<string>;
    @Select(ImageViewModel.getJoinNowRightStar) image$: Observable<string>;
    @Input() currentProduct = undefined;
    @Input() hideCard = false;
    @Input() fromPage: string;
    @Input() subject: string = '';
    @Input() isUpsell: boolean = false;
    @Input() showJoinStar: boolean = false;

    @Output() accepted: EventEmitter<boolean> = new EventEmitter<boolean>();

    currentRoute: string;

    cartError: string;
    showBoost = false;
    siteKey = this.configService.SITE_KEY;

    public products: any;
    public isDisabled: Boolean = false;

    private rainOneL1Product: RainOneProduct;
    private ngDestroy: Subject<any> = new Subject();

    constructor(
        private store: Store,
        private cartService: CartService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private supportService: SupportService,
        private userService: UserService,
        private configService: ConfigService,
        private authSVC: AuthenticationService,
        private modalSVC: NgbModal,
        private cookieService: CookieService
    ) {
    }

    ngOnInit(): void {
        this.checkIfHasRainOne5G();
        this.store.selectSnapshot(ProductState.getRainOneProducts5G);

        this.currentRoute = window.location.href;
        setInterval(() => {
            this.store.dispatch(new GetTicketCreationTimerStatus());
        }, 30000);
        this.rainOneL1Product = this.store.selectSnapshot(ProductState.GetRainOneL1);
        this.showBoost = this.currentProduct?.productName?.includes('boost');
        this.products = [
            {
                id: '1',
                descriptions: this.getDescription(),
                image: !this.isUpsell ? 'assets/images/rain-one/cpe-sim-2.png' : 'assets/promos/r1-promo-cpe.png',
                type: 'device'
            },
            {
                id: '2',
                descriptions: ['2 <strong>FREE</strong> gigs', '60 <strong>FREE</strong> minutes', '100 <strong>FREE</strong> sms'],
                image: 'assets/images/rain-one/fourg-sim-only.png',
                type: 'sim'
            },
            {
                id: '3',
                descriptions: ['2 <strong>FREE</strong> gigs', '60 <strong>FREE</strong> minutes', '100 <strong>FREE</strong> sms'],
                image: 'assets/images/rain-one/fourg-sim-only.png',
                type: 'sim'
            }
        ];

        if (this.authSVC.isSignedIn) {
            this.store
                .select(BillingState.getUserBillingAccountStatus)
                .pipe(takeUntil(this.ngDestroy))
                .subscribe({
                    next: account => {
                        if (account?.accountState === 5) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    },
                    error: _ => (this.isDisabled = false)
                });
        } else {
            this.isDisabled = false;
        }
    }

    navigate() {
        this.router.navigateByUrl('/legal?section=promo');
    }

    getDescription() {
        if (this.isUpsell) {
            return ['Best effor 4G speeds', '5G ready free-to-use-router'];
        }
        if (!this.currentProduct) {
            return ['Speeds up to 30Mbps', 'Free-to-use router'];
        }
        if (this.currentProduct.productName.toLowerCase().includes('basic')) {
            return ['up to <strong>30Mbps</strong> download speed'];
        }
        if (this.currentProduct.productName.toLowerCase().includes('standard')) {
            return ['up to <strong>60Mbps</strong> download speed'];
        }
        if (this.currentProduct.productName.toLowerCase().includes('premium')) {
            return ['<strong>ultra-fast</strong> download speeds'];
        }
        return ['Speeds up to 30Mbps', 'Free-to-use router'];
    }

    public addToCart() {
        this.store.dispatch(new AddonStatus(false));

        if (this.checkIfHasRainOne5G()) {
            return this.showMaxRainOneModal();
        }
        const hasBundleInCart = this.store.selectSnapshot(CartState.GetSelectedRainOneBundle);

        if (hasBundleInCart) return this.store.dispatch(new Navigate(['/cart']));
        if (this.authSVC.isSignedIn) {
            if (this.isDisabled) {
                return;
            }
            const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);

            this.rainOneL1Product = isPostPaid
                ? this.store.selectSnapshot(ProductState.GetRainOneL1PP)
                : this.store.selectSnapshot(ProductState.GetRainOneL1);
        } else {
            this.rainOneL1Product = this.store.selectSnapshot(ProductState.GetRainOneL1);
        }

        this.cartService
            .add(this.rainOneL1Product.id, {
                [CONFIG_FRIENDLY_NAME]: '5G SIM',
                [CONFIG_COMMENTS]: undefined
            })
            .pipe(takeUntil(this.ngDestroy))
            .subscribe(() => {
                this.store.dispatch(new StartRainOnePurchase(this.rainOneL1Product.id));
                this.store.dispatch(new Navigate(['/speed-up']));
            });
        return;
    }

    public navToMigrate() {
        this.store.dispatch(new MigratingToR1FiveG('5G'));
        this.router.navigate(['../address'], {relativeTo: this.activatedRoute});
    }

    getGaTag() {
        return this.cookieService.getGATag();
    }

    private checkIfHasRainOne5G() {
        const isSignedIn = this.authSVC?.isSignedIn;

        if (!isSignedIn) return false;

        const allServices = this.store.selectSnapshot(ServicesState.getAllServices);

        const hasActiveRainONE = allServices?.filter(s => {
            return RAINONE_5G_SIM.includes(s.productId) && s.status === ServiceStatuses.Active;
        });

        if (hasActiveRainONE.length > 0) return true;

        const allOrders = this.store.selectSnapshot(OrderState.GetallNonCancelledOrders);

        let matchFound = false;

        if (allOrders && allOrders?.length > 0) {
            const rainOnder = allOrders?.find(o => {
                o?.items?.forEach(item => {
                    if (RAINONE_5G_SIM.includes(item.productId)) {
                        matchFound = true;
                    }
                });
            });
            return matchFound;
        }
    }

    //if has migrated - cannot purchase

    private showMaxRainOneModal() {
        let mode = this.store.selectSnapshot(UIState.GetUIMode)
        let message
        switch (mode) {
            case 'consumer':
                message = NO_MORE_RAIN_ONE_MESSAGE.replace(NUMBER_REPLACE, '0');
                break
            case 'sme':
                message = NO_MORE_RAIN_ONE_WORK_MESSAGE.replace(NUMBER_REPLACE, '0');
                break
            case 'mobile':
                message = NO_MORE_RAIN_MOBILE_MESSAGE.replace(NUMBER_REPLACE, '0');
        }

        CartErrorModalComponent.openModal(message, false, this.modalSVC);
    }

    public emitAcceptUpsell() {
        return this.accepted.emit(true);
    }

    public getCPEName() {
        if (!this.isUpsell) return 'unlimited<br>5G home wifi';

        return 'unlimited 4G<br>home wifi';
    }

    ngOnDestroy(): void {
        this.ngDestroy.next(null);
        this.ngDestroy.complete();
    }
}
