import { IAddress } from "@models/addressDetail";
import { FulfillmentType } from "@models/orderDetail";
import { Utils } from "@Utils";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { PickupPoint } from "src/app/core/interfaces/pickup-locations.interface";

export class SetSearchAddress {
	static readonly type = Utils.Helpers.Type("[Address] set search address payload")
	constructor(public address: Address) {}
}

export class SetAddressPayload {
	static readonly type = Utils.Helpers.Type("[Address] set delivery/collect address payload")
	constructor(public address: IAddress) {}
}
export class SetSelectedStore {
	static readonly type = Utils.Helpers.Type("[Address] set selected store")
	constructor(public store: PickupPoint) {}
}
export class ResetAddress {
	static readonly type = Utils.Helpers.Type("[Address] reset stored addresses")
}
export class SetFulfillmentMethod {
	static readonly type = Utils.Helpers.Type("[Address] set fulfillment method")
	constructor(public fulfillmentType: FulfillmentType) {}
}
export class ClearAddressPayload {
	static readonly type = Utils.Helpers.Type("[Address] clear address payload")
}