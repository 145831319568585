import { Injectable } from "@angular/core";
import { IAddress } from "@models/addressDetail";
import { FulfillmentType } from "@models/orderDetail";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Address as StoreAddress, PickupPoint } from "src/app/core/interfaces/pickup-locations.interface";
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ClearAddressPayload, ResetAddress, SetAddressPayload, SetFulfillmentMethod, SetSearchAddress, SetSelectedStore } from "../actions/address-payload.actions";

interface AddressPayloadStateModel {
	searchAddress: Address
	deliveryAddress: IAddress,
	store: PickupPoint
	fulfillmentType: FulfillmentType
}

@State<AddressPayloadStateModel>({
	name: 'AddressPayloadState',
	defaults: {
		searchAddress: null,
		deliveryAddress: null,
		store: null,
		fulfillmentType: null
	}
})

@Injectable({
	providedIn: 'root'
})
export class AddressPayloadState {
	
	@Selector()
	static getSearchAddress(state: AddressPayloadStateModel) {return state.searchAddress}
	
	@Selector()
	static getDeliveryAddress(state: AddressPayloadStateModel) {return state.deliveryAddress}
	
	@Selector()
	static getFulfillmentType(state: AddressPayloadStateModel) {return state.fulfillmentType}
	
	@Selector()
	static getSelectedStore(state: AddressPayloadStateModel) {return state.store}
	
	@Selector([AddressPayloadState.getSelectedStore])
	static getStoreAddress(state: AddressPayloadStateModel, store: PickupPoint) {return store.address}
	
	@Selector([AddressPayloadState.getFulfillmentType])
	static isStoreCollection(state: AddressPayloadStateModel, fulfillmentType: FulfillmentType) {
		return fulfillmentType === FulfillmentType.STORE || fulfillmentType === FulfillmentType.PICKUP;
	}
	
	@Selector([AddressPayloadState.getDeliveryAddress, AddressPayloadState.getStoreAddress, AddressPayloadState.isStoreCollection])
	static isAddressValid(state: AddressPayloadStateModel, deliveryAddress: IAddress, storeAddress: StoreAddress, isCollection: boolean) {
		if (isCollection) {
			const { street_name, street_number, suburb, city, province, postal_code } = storeAddress;
			const valid = (!!street_name.length && !!street_number.length && !!suburb.length && !!city.length && !!province.length && !!postal_code.length && storeAddress);
			return valid;
		}
		const { streetNumber, streetName, suburb, city, province, postalCode } = deliveryAddress;
		const valid = (!!streetNumber.length && !!streetName.length && !!suburb.length && !!city.length && !!province.length && !!postalCode.length && deliveryAddress);
		return valid;
	}
	
	@Selector([AddressPayloadState.getDeliveryAddress, AddressPayloadState.getStoreAddress, AddressPayloadState.isStoreCollection, AddressPayloadState.getFulfillmentType, AddressPayloadState.isAddressValid])
	static getFullAddressPayload(state: AddressPayloadStateModel, deliveryAddress: IAddress, storeAddress: StoreAddress, isCollection: boolean, fulfillmentType: FulfillmentType, isValid: boolean) {
		return {
			address: isCollection ? storeAddress : deliveryAddress, 
			fulfillmentType, 
			isValid
		}
	}
	

	@Action(SetSearchAddress)
	SetSearchAddress(ctx: StateContext<AddressPayloadStateModel>, action: SetSearchAddress) {
		return ctx.patchState({
			searchAddress: action?.address
		})
	}

	@Action(SetAddressPayload)
	setAddressPayload(ctx: StateContext<AddressPayloadStateModel>, action: SetAddressPayload) {
		return ctx.patchState({
			deliveryAddress: action?.address
		})
	}

	@Action(SetSelectedStore)
	setSelectedStore(ctx: StateContext<AddressPayloadStateModel>, action: SetSelectedStore) {
		return ctx.patchState({
			store: action?.store
		})
	}

	@Action(ResetAddress)
	ResetAddress(ctx: StateContext<AddressPayloadStateModel>) {
		return ctx.patchState({
			searchAddress: null,
			deliveryAddress: null,
			store: null
		})
	}
	
	@Action(SetFulfillmentMethod)
	setFulfillmentMethod(ctx: StateContext<AddressPayloadStateModel>, action: SetFulfillmentMethod) {
		return ctx.patchState({
			fulfillmentType: action?.fulfillmentType
		})
	}

	@Action(ClearAddressPayload)
	ClearAddressPayload(ctx: StateContext<AddressPayloadStateModel>) {
		return ctx.setState({
			searchAddress: null,
			deliveryAddress: null,
			store: null,
			fulfillmentType: null
		})
	}

}