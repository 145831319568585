<div class="d-flex align-items-lg-center row justify-content-lg-between position-relative flex-lg-row flex-column">
  <div class=" form-group full-width mb-md-0 mb-sm-0 mb-10">
    
    <form [formGroup]="frm" >
      <sf-google-placesform-input
        placeholder="search address"
        [control]="frm.get('address')"
        (onKeyUp)="searchChange()"
        (onAddressChange)="handleAddressChange($event)"
      >
      </sf-google-placesform-input>
     
    </form>
  </div>
</div>
