import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-create-account-coverage-notice-modal',
  templateUrl: './create-account-coverage-notice-modal.component.html',
  styleUrls: ['./create-account-coverage-notice-modal.component.scss']
})
export class CreateAccountCoverageNoticeModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public close() {
    localStorage.setItem('accpet5GCoverageDisclaimer', 'true')
    return this.activeModal.dismiss();
  }

}
