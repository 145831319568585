<div class="modal-content border-0 px-sm-20">
  <div class="modal-header border-0 mb-10">
    <div class="modal-img-close-btn z-index-5">
      <img src="../../assets/svg/close.svg" class="pointer" alt="Close" (click)="activeModal.dismiss('close click')" />
    </div>
  </div>

  <div>

    <div class="text-center modal-body ">
      <h2 class="text-primary">Terms & Conditions</h2>
    </div>

  </div>

</div>

<div class="full-height">
  <ng2-pdfjs-viewer id="pdfViewer" [pdfSrc]="pdfSrc" zoom="page-width" [externalWindow]="false"
    externalWindowOptions="'noopener', ">
  </ng2-pdfjs-viewer>
</div>