<div class="modal-header border-0">
  <div class="modal-img-close-btn z-index-5 ">
    <img
      src="../../assets/svg/close.svg"
      class="pointer"
      alt="Close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    />
  </div>
</div>
<div class=" px-10 pt-10">
  <p class="text-primary extra-large-font-size pb-5 text-center">RICA requirements</p>
  <p>
    RICA requires you to be present for face-to-face verification before your SIM can be activated.
  </p>
  <p>
    Our courier agent, RAM, will RICA you upon delivery by verifying and capturing images of the following documents:
  </p>
</div>
<ul id="list" class="ml-12 pb-10">
  <li>
    Original SA ID or passport document
  </li>
  <li>
    Proof of address not older than 3 months
  </li>
</ul>
