import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export interface OnErrorCodeRetryStrategyOptions {
    errorCodesToInclude?: number[]
    maxRetryAttempts?: number,
    delay?: number;
}

export const onErrorCodeRetryStrategy = <T extends { status: number }>({
    maxRetryAttempts = 3,
    delay = 1000,
    errorCodesToInclude = []
}: OnErrorCodeRetryStrategyOptions = {}) => (errors$: Observable<T>) => {

    return errors$
        .pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;

                const maxAttemptsReached = retryAttempt > maxRetryAttempts;
                const includesCode = errorCodesToInclude.some(code => code === error?.status);

                if (maxAttemptsReached || !includesCode) {
                    return throwError(error);
                }

                return timer(delay);
            })
        );
};