import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TsAndCsComponent } from '@pages/user-register-page/ts-andcs-modal/ts-andcs-modal.component';

@Component({
  selector: 'rain-create-work-account-notice-modal',
  templateUrl: './create-work-account-notice-modal.component.html',
  styleUrls: ['./create-work-account-notice-modal.component.scss']
})
export class CreateWorkAccountNoticeModalComponent {

  @Output() retryCompanyNumber: EventEmitter<any> = new EventEmitter();
  @Output() proceedWithoutCompanyNumber: EventEmitter<any> = new EventEmitter();
  

  constructor( public activeModal: NgbActiveModal,private modalService: NgbModal) { }



  retry(retry) {
    this.retryCompanyNumber.emit(retry);
    this.activeModal.dismiss();
  }

  proceed(proceed) {
    this.proceedWithoutCompanyNumber.emit(proceed);
    this.activeModal.dismiss();
  }

  viewTsAndCs() {
    const modalRef = this.modalService.open(TsAndCsComponent, { size: <any>'lg', windowClass: 'slideInUp' });
    modalRef.componentInstance.pdfSrc = encodeURIComponent('/assets/pdf/TC-V18-iter-1-V1-4-June-2024.pdf');
  }
}
