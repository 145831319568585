import { AssignSimForm } from "@pages/assign-sim/assign-sim.interface";
import { Utils } from "src/app/utils";
import { IAssignSim, IClaimSim, } from "../state/assign-claim-sim.state";
import { IAssociateStatus } from "@models/asociate-status.interface";

export class SetSaId {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] set Service Associate ID')
    constructor(public id: string){}
}
export class AssociateSim {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Associate SIM');
    constructor(public formPayload: AssignSimForm ){}
}

export class AssociateSimSuccess {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Associate SIM success');
    constructor(public body: IAssignSim ){}
}

export class AssociateSimFailure {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Associate SIM failure');
    constructor(public error: string ){}
}

export class DismissModals {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Set Show modals to false');
}

export class ClaimSim {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Claim SIM');
    constructor(public iccid : string){}
    //change type
}
export class ClaimSimSuccess {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Claim SIM success');
    constructor(public body : IClaimSim){}
}
export class ClaimSimFailure {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Claim SIM failure');
    constructor(public error : string){}
}



export class AssociateStatusBySaId {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Check pending associate status by SAID');
    constructor(public saId : string){}//said = Service Assoctiate ID
}
export class AssociateStatusByServiceId {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Check pending associate status by Service ID');
    constructor(public serviceId : string){}
}
export class CheckAssociateStatusSuccess {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Check pending associate status Success');
    constructor(public result : any){}
}
export class CheckAssociateStatusFailure {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Check pending associate status Failure');
    constructor(public error : string){}
}



export class RemovePendingAssociate {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Remove pending associate')
    constructor(public id : string){}
}
export class RemovePendingAssociateSuccess {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Remove pending associate Success')

}
export class RemovePendingAssociateFailure {
    public static type = Utils.Helpers.Type('[ASSIGN+CLAIMSIM] Remove pending associate Failure')
    constructor(public error : string){}
}