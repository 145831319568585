<div>
  <div class="mb-10 modal-header border-0 ">
    <div class="modal-img-close-btn z-index-5">
      <img alt="Close" aria-label="Close" class="pointer" (click)="close()" src="../../assets/svg/close.svg" />
    </div>
  </div>

  <div class="modal-body border-0 p-5 text-center">

    <div >
      <h2 class="text-primary font-weight-bold my-5">we’ve upgraded your streaming experience!</h2>

      <p>We’d love to find out how it’s going. Please give us a rating out of five. <br /></p>
    </div>
  </div>

  <div class="d-flex justify-content-center mb-10 mx-10">
    <div *ngFor="let row of ratings; let i = index">
      <div class="px-5 px-sm-8 pointer" (click)="rate(i)">
        <div>
          <img [src]="row.image" alt="" class="img-fluid mx-auto">
        </div>
        <div class="text-center">
          <h4 class="text-primary font-weight-bold">
            {{row.number}}
          </h4>
        </div>
      </div>
    </div>
  </div>

  <hr class="mx-10" />

  <div class="modal-footer border-top-0 d-flex mb-10 justify-content-center justify-content-sm-end">

    <button class="btn-width btn btn-sm btn-outline-blue m-2" (click)="close()">
      cancel
    </button>

    <button class="btn-width btn btn-sm btn-primary m-2" [attr.data-text]="'customer rated #:' + ratedNumber" data-id="btn-click" [disabled]="!ratedNumber || loading" (click)="submit()">
      submit
      <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    </button>


  </div>
</div>