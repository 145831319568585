import { Injectable } from '@angular/core';

export interface AccountDetails {
  accountState: string;
  amount: number;
  amountArrears: number;
  minimumPayment: number;
  amountCurrent: number;
  billingState: any;
  customerNodeId?: string;
  invoiceId: string;
  payPromiseDate: any;
  tenure: number;
  arrears?: any[];
  recurringPaymentDate?: string | number;
  flag?: string;
}


@Injectable({
  providedIn: 'root'
})
export class BillingStoreService {
  account: AccountDetails;
  paydate: number;
  displayDate: string;
  canChangePayDate = false;
  payDateString = '';
  constructor() { }



  clearAllValues() {
    this.paydate = 1;
    this.displayDate = '1st';
    this.canChangePayDate = false;
  }
}
