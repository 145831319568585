import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import MarkerClustered from '@google/markerclustererplus';
import { isNil } from 'lodash';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '@models/constants';
import { GeoService, CoverageMapModes } from '@services/geo.service';

const DEFAULT_ZOOM_LEVEL = 14;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {
  @Input()
  public mode: CoverageMapModes;
  markerCluster: MarkerClustered;

  @Input()
  set onlyLte(onlyLte: boolean) {
    this._onlyLte = onlyLte;
    this.update();
  }

  @Input()
  set latitude(latitude: number) {
    this._latitude = latitude;
  }

  @Input()
  set longitude(longitude: number) {
    this._longitude = longitude;
  }

  _onlyLte: boolean;

  _latitude: number = DEFAULT_LATITUDE;

  _longitude: number = DEFAULT_LONGITUDE;

  @ViewChild('btn4G', { static: true }) btn4G: ElementRef;
  @ViewChild('btn5G', { static: true }) btn5G: ElementRef;
  @ViewChild('btnBoth', { static: true }) btnBoth: ElementRef;

  @Input()
  zoom: number = DEFAULT_ZOOM_LEVEL;

  public minZoom: number = 5;
  public maxZoom: number = 16;
  public coverageMapModes = CoverageMapModes;

  constructor(private geoService: GeoService) { }

  map: google.maps.Map;
  showFourG = true;
  showFiveG = true;
  showLegend = true;

  styles = [
    {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  ngOnInit() {
    this.mode = isNil(this.mode) || Object.values(CoverageMapModes).includes(this.mode) == false ? CoverageMapModes.BothFourAndFiveG : this.mode;
  }


  ngOnChanges(changes: SimpleChanges): void {

    this.mode = changes.mode.currentValue
    this.update()
  }
  

  mapReady(map) {
    this.map = map;
    map.setOptions({ minZoom: this.minZoom, maxZoom: this.maxZoom });
    this.applyCoverageTile();
  }

  applyCoverageTile() {
    for (const layer of this.geoService.getCoverageLayers(this.mode)) {
      this.map?.overlayMapTypes.push(layer);
    }
  }

  updateSelectionBtn(e: Event, showMap: string) {

    this.btn4G.nativeElement.classList.remove('active');
    this.btnBoth.nativeElement.classList.remove('active');
    const _btn: HTMLElement = e.target as HTMLElement;
    if (_btn.classList.contains('active')) {
      _btn.classList.remove('active');
    } else {

      _btn.classList.add('active');
    }

    if (showMap === 'showFourG') {
      this.mode = CoverageMapModes.FourG;
      this.showLegend = false
    }
    else if (showMap === 'showFiveG') {
      this.mode = CoverageMapModes.FiveG;
    }
    else if (showMap === 'both') {
      this.mode = CoverageMapModes.BothFourAndFiveG;
      this.showLegend = true
    }

    this.update();
  }

  updateSelection() {
    if (!this.showFiveG && !this.showFourG) {
      this.map.overlayMapTypes.clear();
      return;
    }

    // SET MODE FOR COVERAGE LAYERS
    if (this.showFourG && this.showFiveG) {
      this.mode = CoverageMapModes.BothFourAndFiveG;
    } else if (this.showFourG) {
      this.mode = CoverageMapModes.FourG;
    } else {
      this.mode = CoverageMapModes.FiveG;
    }
    this.update();
  }

  update() {
    this.map?.overlayMapTypes.clear();
    this.applyCoverageTile();
  }

  addressChange() { }
}
