import { Component } from '@angular/core';
import { SalesModalComponent } from '@components/sales-modal/sales-modal.component';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rain-cancellation-failure-modal',
  templateUrl: './cancellation-failure-modal.component.html',
  styleUrls: ['./cancellation-failure-modal.component.scss']
})
export class CancellationFailureModalComponent {
  isPortingContent: boolean = true;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }


  close() {
    this.activeModal.close();
  }

  requestACall() {
    this.activeModal.close();
    let modalRef: NgbModalRef = this.modalService.open(SalesModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'slideInUp d-flex'
    })
    modalRef.componentInstance.isPortingContent = this.isPortingContent;
  }

}
