import { Injectable } from '@angular/core';
import { LECACY_PRODUCTS, RAINONE_101_5G_SIM, RAINONE_5G_SIM } from '@models/constants';
import { LogisticStatus } from '@models/logisticDetail';
import { IServiceDetail } from '@models/serviceDetail';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { OrderState } from 'src/app/store/state/order.state';
import { ServicesState } from 'src/app/store/state/services.state';

export interface LegacyCheckResult {
  can_purchase_nvidia_only: boolean;
  has_open_rain_one_order: boolean;
  has_pending_migrations: {
    true: boolean,
    eligible_services: Array<IServiceDetail>
  };
  is_in_coverage: boolean;
  has_legacy_4g: {
    true: boolean,
    eligible_services: Array<IServiceDetail>
  }
  has_legacy_5g: {
    true: boolean,
    eligible_services: Array<IServiceDetail>
  };
  has_rain_one: {
    true: boolean,
    eligible_services: Array<IServiceDetail>
  };
  has_rain_one_101: {
    true: boolean,
    eligible_services: Array<IServiceDetail>
  };
}

@Injectable({
  providedIn: 'root'
})
export class ServiceCoverageCheckService {
  constructor(private store: Store) { }

  public perform5GCheck(): Observable<LegacyCheckResult> {
    const services = this.store.selectSnapshot(ServicesState.getAllServices);
    const result: LegacyCheckResult = {
      can_purchase_nvidia_only: this.CanPurchaseNvidiaOnly(),
      has_open_rain_one_order: this.hasRainOneOrder(),
      is_in_coverage: Boolean(this.checkOrders() || this.hasLegacy4GServices(services) || this.hasLegacy5GServices(services) ||
      this.hasRainOneService(services) || this.hasRainOne101Service(services)),
      has_pending_migrations: this.hasPendingMigrations(services),
      has_legacy_4g: this.hasLegacy4GServices(services),
      has_legacy_5g: this.hasLegacy5GServices(services),
      has_rain_one: this.hasRainOneService(services),
      has_rain_one_101: this.hasRainOne101Service(services)
    }
    return of(result);
  }

  private checkOrders() {
    const orders = this.store.selectSnapshot(OrderState.GetallNonCancelledOrders)?.filter(o => o.status === 9 || o.status === 4 || o.status === LogisticStatus.PartiallyCollected || o.status === LogisticStatus.Delivered);
    const order = orders?.find(o => o.comments && o.comments.includes('isIn5G'));
    
    if (!order) return false;
    const comments = JSON.parse(order.comments);
    if (!comments) return false;
    
    if (comments?.['isIn5G'] === undefined || comments?.['isIn5G'] === null) { return false };
    return !Boolean(comments['isIn5G']);
  }

  private hasLegacy5GServices(services) {
  
    const nonRain101Service = services.filter((s: IServiceDetail) => !RAINONE_101_5G_SIM.includes(s.productId));
    const nonRainOneServices = nonRain101Service.filter((s: IServiceDetail) => !RAINONE_5G_SIM.includes(s.productId));
    const nonRain4GLegacyServices = nonRainOneServices.filter((s: IServiceDetail) => !LECACY_PRODUCTS.includes(s.productId) && !s.productName.includes('4G'));
    
    return {
      true: nonRain4GLegacyServices.length > 0,
      eligible_services: nonRain4GLegacyServices
    };
  }

  private hasLegacy4GServices(services) {
    const legacyServices = services.filter((s: IServiceDetail) => LECACY_PRODUCTS.includes(s.productId));
    return {
      true: legacyServices.length > 0,
      eligible_services: legacyServices
    };
  }

  private hasRainOneService(services) {
    const hasRain101Service = services.filter((s: IServiceDetail) => RAINONE_5G_SIM.includes(s.productId));
    return {
      true: (hasRain101Service.length > 0 || !this.checkCanPurchase()),
      eligible_services: hasRain101Service
    };
  }

  private hasRainOne101Service(services) {
    const hasRain101Service = services.filter((s: IServiceDetail) => RAINONE_101_5G_SIM.includes(s.productId));
    return {
      true: hasRain101Service.length > 0 || !this.checkCanPurchase(),
      eligible_services: hasRain101Service
    };
  }

  private hasPendingMigrations(services) {
    const migrations = services.filter(svc => svc?.pending_migration);
    return {
      true: migrations.length > 0,
      eligible_services: migrations
    };
  }

  private CanPurchaseNvidiaOnly() {
    return this.store.selectSnapshot(ServicesState.CanPurchaseNvidiaOnly);
  }

  private checkCanPurchase() {
    return this.store.selectSnapshot(ServicesState.CanPurchaseNvidiaWithRainOne);
  }

  private hasRainOneOrder() {
    return this.store.selectSnapshot(OrderState.hasRainOneOrderV2);
  }

}
