<div class="port-in-status-pending-container">
    <div class="close-port-in-modal">
        <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" />
      </div>
  <div class="port-in-pending-header">
    <h3>your number port is <span class="bold-text">pending</span></h3>
  </div>
  <div class="port-in-status-content">
    <p>Keep the SIM from your old network in your phone until the porting process is complete, so we can sms you.</p>
  </div>
  <div class="port-in-status-button">
    <button (click)="gotIt()">got it</button>
  </div>
</div>
