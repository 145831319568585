<div class="container main-wrapper">
    <div class="row sub-wrapper">
        <div class="col-12 col-lg-4 br-8 inner-wrapper">
            <ng-content select="[content1]"></ng-content>
        </div>
        <div class="col-12 col-lg-4 br-8 inner-wrapper">
            <ng-content select="[content2]"></ng-content>
        </div>
        <div class="col-12 col-lg-4 br-8 inner-wrapper">
            <ng-content select="[content3]"></ng-content>
        </div>
    </div>
</div>