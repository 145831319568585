import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { ConfigService } from "./config.service";
import { TokenService } from "./token.service";
import { CACHE_AUTH_TOKEN } from "@models/constants";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class SpeedUpService implements OnInit{
    constructor(
        private configService: ConfigService,
        private tokenService: TokenService,
        private _http: HttpClient,
    ){}

    ngOnInit(): void { }

    changeServiceSpeed(addonId: string, serviceId: string): Observable<any>{
        const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${serviceId}/_addon`
        const payload = {
            product_id: addonId,
            service_id :serviceId
        }
        const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.tokenService.getTokenForAccountType()}`
          }),
        };
        
        return this._http.patch(requestPath, payload, httpOptions as any)
    }

    revokeSpeedDownRequest(serviceId: string): Observable<any> {
        const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${serviceId}/_addon-revoke`
    
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.tokenService.getTokenForAccountType()}`
            }),
        };

        return this._http.post(requestPath, {}, httpOptions as any)
      }
}