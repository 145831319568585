<div class="mb-4 modal-header border-0 ">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="dismiss()" src="../../assets/svg/close.svg" />
  </div>
</div>
<div class="modal-body border-0 p-5 text-center">
  <div class="p-2">
    <h2 class="text-primary">speed up request</h2>
    <p>Please confirm your details below and we will get in contact to process your request as soon as possible.</p>
  </div>
  <div class="form-wrapper">
    <form [formGroup]="userSpeedForm" (ngSubmit)="submit()">
      <div class="field-input-wrapper">
        <input type="text" formControlName="name" placeholder="Full name" required />
        <input type="text" formControlName="email" placeholder="Email address" required />
        <input type="text" formControlName="contactNumber" placeholder="Contact number" required />
      </div>
      <div class="radio-wrapper">
        <h3>Please select your speed up request:</h3>
        <div>
          <div>
            <input type="radio" formControlName="selectedSpeed"  required value="30" id="30"  />
            <label for="30">30Mbps</label>
          </div>
          <div>
            <input type="radio" formControlName="selectedSpeed"  required  value="60" id="60"/>
            <label for="60">60Mbps</label>
          </div>
          <div>
            <input type="radio" formControlName="selectedSpeed"  required value="100+" id="100+"/>
            <label for="100+">100+Mbps</label>
          </div>

        </div>
      </div>
      
    </form>
  </div>

</div>


<div class="p-8 mb-10 text-center" >
  
  <button class="btn btn-sm btn-primary mx-5"
    (click)="submit()">submit</button>
</div>

<ng-template #modalContent > 
  <div class="mb-4 modal-header border-0 ">
    <div class="modal-img-close-btn z-index-5">
      <img alt="Close" aria-label="Close" class="pointer" (click)="dismiss()" src="../../assets/svg/close.svg" />
    </div>
  </div>
  <div class="modal-body border-0 p-5 text-center">
    <div class="p-2">
      <h2 class="text-primary">success</h2>
    </div>
   <div class="p-2">An agent will be in contact with you shortly.</div>
  </div>
  
  <div class="p-8 mb-10 text-center button-container" >
    
    <button class="btn btn-sm btn-primary mx-5"
      (click)="dismiss()">got it</button>
  </div>
</ng-template>


