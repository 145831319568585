import {CacheService} from '@services/cache.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, OnInit} from '@angular/core';
import {ConfigService} from '@services/config.service';
import {ToastrService} from 'ngx-toastr';
import {SupportService} from '@services/support.service';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from 'src/app/baseComponent';
import {UserService} from '@services/user.service';
import {Statuses} from '@models/result';
import {CookieService} from '@services/cookie.service';
import {
    CallbackRequestComponent
} from "@components/product/migration-modals/callback-request/callback-request.component";
import {CACHE_4GTO5G_MIGRATION} from "@models/constants";

@Component({
    selector: 'app-in-coverage',
    templateUrl: './in-coverage.component.html',
    styleUrls: ['./in-coverage.component.scss']
})
export class InCoverageComponent extends BaseComponent implements OnInit {
    product: any;
    siteKey: string;
    msisdn: string;
    serviceId: string;
    currentProductId: string;
    migrateProductId: string;
    loading = false;
    migrationType: string;
    userDetails: any;

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private userService: UserService,
        private toastr: ToastrService,
        private supportService: SupportService,
        private configService: ConfigService,
        private cacheService: CacheService,
        private cookieService: CookieService
    ) {
        super();
    }

    ngOnInit(): void {
        this.getUserDetails();
        this.siteKey = this.configService.SITE_KEY;
    }

    getUserDetails() {
        this.userService.get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result: any) => {
                if (result.status !== Statuses.Success) {
                    this.toastr.error('An error occurred while processing the request', null);
                    return;
                } else {
                    this.userDetails = result.value;
                }
            });
    }

    requestSwitch(type) {
        this.migrationType = type;
        this.submitTicket();
    }

    recordScheduledAction() {
        const action = {
            customerId: this.userService.getUserId(),
            transactionType: 'Migration',
            currentProduct: this.currentProductId,
            newProduct: this.migrateProductId || null,
            serviceId: this.serviceId,
            requestType: 'Request',
            source: 'Web'
        }
        this.userService.recordScheduleActions(action).subscribe((res) => {

        }, e => {

        });
    }

    submitTicket() {
        this.loading = true;

        let ticketRequest = {
            name: `${this.userDetails.firstName} ${this.userDetails.lastName}`,
            email: this.userDetails.email,
            subject: `rain sales support #${this.msisdn}`,
            referenceId: this.msisdn,
            body: {
                msisdn: this.msisdn
            },
            captcha: null,
            ga_tag: this.cookieService.getGATag()
        }

        this.supportService.addTicket(ticketRequest)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result: any) => {
                this.loading = false;
                if (!result?.ticket) {
                    setTimeout(() => this.toastr.error('An error occurred while submitting. Please try again', null));
                    return;
                } else {
                    this.recordScheduledAction()
                    this.activeModal.close();
                    const modalRef = this.modalService.open(CallbackRequestComponent, {
                        size: <any>'md',
                        windowClass: 'slideInUp',
                        centered: true
                    });
                    modalRef.componentInstance.product = this.product;
                    this.cacheService.set(CACHE_4GTO5G_MIGRATION, this.serviceId, 864000000);
                    this.userService.onServiceUpdated.next(this.serviceId);
                }

            });
    }
}
