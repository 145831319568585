import { Action, createSelector, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { CancelNvidiaService, ClearSelectedService, ClearServicesState, FetchServicesIfNotFetched, GetAllServicesV2, GetBucketBalances, GetServicePolicies, GetServicesNumberPortingStatus, GetServicesOnlineStatus, SetGroupedServices, SetSelectedService, RevokeNvidiaCancellation, GetAllUserResources } from '../actions/services.action';
import { CustomerResource, NvidiaCancellationRequest, ServiceObject, ServicePolicyRemote } from "@models/serviceDetail";
import { RAIN_MOBILE_DISPLAY_NAME } from "@models/constants";
import { UserService } from "@services/user.service";
import { tap } from "rxjs/operators";
import { GetDeviceDetails } from "../actions/device.action";
import { groupServices } from "../../utils/groupServices";
import { UIMode, UIState } from "src/app/shared/store/state/ui.state";
import { NumberPortingService } from "@services/number-porting.service";
import { ToastrService } from "ngx-toastr";
import { TransformDataServiceV2 } from "../service/tranform-data.service";
import { AuthState } from "src/app/core/store/state/auth.state";
import { NvidiaService } from "@services/nvidia.service";
import { error } from "console";
import { SuccessErrorHandler } from "./success-error-handler";

export interface ServicesStateModel {
    allServices: ServiceObject[];
    selectedService: ServiceObject;
    serviceConnectionStatuses: Record<string, any>;
    servicePolicies: Record<string, ServicePolicyRemote>;
    portStatuses: Record<string, { status: string }>
    resources: Record<string, CustomerResource>
}

@State<ServicesStateModel>({
    name: 'servicesV2',
    defaults: {
        allServices: [],
        selectedService: null,
        servicePolicies: {},
        serviceConnectionStatuses: {},
        portStatuses: {},
        resources: {}
    }

})
@Injectable({
    providedIn: 'root'
})
export class ServicesStateV2 {

    constructor(
        private userService: UserService,
        private transformDataService: TransformDataServiceV2,
        private toastrService: ToastrService,
        private numberPortingService: NumberPortingService,
        private nvidiaService: NvidiaService,
        private successErrorHadler : SuccessErrorHandler
    ) { }

    @Selector()
    static getAllServicesV2(state: ServicesStateModel) {
        return state.allServices;
    }

    @Selector()
    static getServicePolicies(state: ServicesStateModel) {
        return state.servicePolicies;
    }

    @Selector()
    static getServicePortStatuses(state: ServicesStateModel) {
        return state.portStatuses;
    }

    @Selector()
    static getServicesConnectionStatuses(state: ServicesStateModel) {
        return state.serviceConnectionStatuses;
    }

    @Selector()
    static getSelectedService(state: ServicesStateModel) {
        return state.selectedService;
    }

    @Selector()
    static getUserResources(state: ServicesStateModel) {
        return state.resources;
    }

    static fetchServiceById(serviceId: string) {
        return createSelector([ServicesStateV2.getAllServicesV2], (services: ServiceObject[]) => {
            return services.find(service => service.id === serviceId);
        });
    }

    @Selector([ServicesStateV2.getAllServicesV2, AuthState.getConsumerUserId, AuthState.getSMEUserId])
    static getGroupedServices(state: ServicesStateModel, allServices: ServiceObject[], consumerId: string, smeId: string): { consumerServices: any[], workServices: any[], mobileServices: any[], allServices: any[] } {
        const consumerService = allServices.filter(service => (!service?.account_type || service?.account_type?.toLocaleLowerCase() === "consumer" || service?.user_id === consumerId) && !service?.product?.config?.displayName?.toLocaleLowerCase()?.includes(RAIN_MOBILE_DISPLAY_NAME));
        const workServices = allServices.filter(service => service.account_type?.toLocaleLowerCase() === "sme" || service?.user_id === smeId);
        const mobileServices = allServices.filter(service => service?.product?.config?.displayName?.toLocaleLowerCase()?.includes(RAIN_MOBILE_DISPLAY_NAME));
        return {
            consumerServices: groupServices(consumerService),
            workServices: groupServices(workServices),
            mobileServices: groupServices(mobileServices),
            allServices: groupServices(allServices)
        }
    }

    @Selector([ServicesStateV2.getGroupedServices])
    static getServiceToggles(state: ServicesStateModel, groupedServices: { consumerServices: any[], workServices: any[], mobileServices: any[] }) {
        let serviceTypeCount = 0;
        const hasConsumerServices = groupedServices.consumerServices?.length;
        const hasWorkServices = groupedServices.workServices?.length;
        const hasMobileServices = groupedServices.mobileServices?.length;

        [hasConsumerServices, hasWorkServices, hasMobileServices].forEach(type => {
            if (type) {
                serviceTypeCount++
            }
        });

        return {
            hasConsumerServices,
            hasWorkServices,
            hasMobileServices,
            serviceTypeCount
        }
    }

    @Selector([ServicesStateV2.getServiceToggles])
    static getAvailableUiModesForServices(state: ServicesStateModel, serviceToggles: { hasConsumerServices: boolean, hasWorkServices: boolean, hasMobileServices: boolean, serviceTypeCount: number }) {
        let uiModes: UIMode[] = [];

        if (serviceToggles?.hasConsumerServices) {
            uiModes.push("consumer");
        }
        if (serviceToggles?.hasWorkServices) {
            uiModes.push("sme");
        }
        if (serviceToggles?.hasMobileServices) {
            uiModes.push("mobile");
        }

        return uiModes;
    }

    @Selector([UIState.GetUIMode, ServicesStateV2.getGroupedServices, ServicesStateV2.getServiceToggles])
    static getServiceForUiMode(state: ServicesStateModel, uiMode: UIMode, groupedServices: { consumerServices: any[], workServices: any[], mobileServices: any[], allServices: any[] }, serviceToggles: any) {
        let services;

        switch (uiMode) {
            case "consumer":
                services = groupedServices?.consumerServices
                break
            case "sme":
                services = groupedServices?.workServices
                break
            case "mobile":
                services = groupedServices?.mobileServices
                break
            default:
                services = groupedServices?.allServices
                break
        }

        return services;
    }

    @Action(FetchServicesIfNotFetched)
    FetchServicesIfNotFetched(ctx: StateContext<ServicesStateModel>) {
        const { allServices } = ctx.getState() ?? {};

        if (allServices && allServices?.length) {
            return
        }

        return ctx.dispatch(new GetAllServicesV2());
    }

    @Action(GetAllServicesV2)
    getAllServicesV2(ctx: StateContext<ServicesStateModel>) {
        return this.userService.getAllServices().pipe(
            tap((services) => {
                if (services && services?.length) {

                    const formattedData = this.transformDataService
                        ?.inputServicesDataV2(services)
                        ?.filterActiveAndSuspendedServicesV2()
                        ?.appendRainOneDetailsV2()
                        ?.appendProductDetailV2()
                        ?.services;

                    ctx.patchState({ allServices: formattedData });

                    formattedData.forEach((service) => {
                        ctx.dispatch(new GetDeviceDetails(service?.msisdn));
                    })

                    return ctx.dispatch([
                        new GetAllUserResources(),
                        new GetServicePolicies(formattedData),
                        new GetServicesNumberPortingStatus(formattedData)
                    ])
                } else {
                    return [];
                }
            })
        )
    }

    @Action(GetAllUserResources)
    GetAllUserResources(ctx: StateContext<ServicesStateModel>) {
        return this.userService.getUserResources().pipe(
            tap((res: Record<string, CustomerResource>) => {
                ctx.patchState({ resources: res });
                if (Object.keys(res)?.length) {
                    return ctx.dispatch(new GetServicesOnlineStatus(res))
                }
            })
        )
    }

    @Action(GetServicesOnlineStatus)
    GetServicesOnlineStatus(ctx: StateContext<ServicesStateModel>, action: GetServicesOnlineStatus) {
        return this.userService.getAllServicesOnlineStatuses(action?.resources).pipe(
            tap((res: any) => {
                ctx.patchState({
                    serviceConnectionStatuses: res
                })
            })
        );
    }

    @Action(GetServicesNumberPortingStatus)
    GetServicesNumberPortingStatus(ctx: StateContext<ServicesStateModel>, action: GetServicesNumberPortingStatus) {
        const services = action?.services.filter(service => service?.parent_service_id || service?.type?.toLowerCase() === 'assigned');

        return this.numberPortingService.getAllServicesPortStatuses(services).pipe(
            tap((res: any) => {
                ctx.patchState({
                    portStatuses: res
                })
            })
        );
    }

    @Action(GetServicePolicies)
    getServicePolicies(ctx: StateContext<ServicesStateModel>, action: GetServicePolicies) {
        return this.userService.getServicePolicySpeed().pipe(
            tap({
                next: (res: Record<string, ServicePolicyRemote>) => {
                    return ctx.patchState({ servicePolicies: res })
                },
                error: (err: unknown) => {
                    this.toastrService.error("Unable to fetch service policies");
                }
            })
        );
    }

    @Action(SetSelectedService)
    SetSelectedService(ctx: StateContext<ServicesStateModel>, action: SetSelectedService) {
        const { service } = action ?? {};

        ctx.patchState({
            selectedService: service
        });
    }

    @Action(CancelNvidiaService)
    CancelNvidiaService(ctx: StateContext<ServicesStateModel>, action: CancelNvidiaService) {
        const request: NvidiaCancellationRequest = {
            serviceId: action.serviceId,
            reason: 'User Request'
        }
        return this.nvidiaService.cancelService(request).pipe(
            tap({
                next: () => { 
                   this.successErrorHadler.nvidiaCancellationSuccess()
                },
                error: () => {
                   this.successErrorHadler.nvidiaError("cancellation")
                 }
            })
        )
    }

    @Action(RevokeNvidiaCancellation)
    RevokeNvidiaCancellation(ctx: StateContext<ServicesStateModel>, action: RevokeNvidiaCancellation) {
       const serviceId = action.serviceId
        return this.nvidiaService.revokeNvidiaCancellation(serviceId).pipe(
            tap({
                next: () => { 
                   this.successErrorHadler.revokeNvidiaCancellationSuccess()
                },
                error: () => {
                    this.successErrorHadler.nvidiaError("cancellation reversal")
                 }
            })
        )
    }

    @Action(ClearSelectedService)
    ClearSelectedService(ctx: StateContext<ServicesStateModel>) {
        return ctx.patchState({
            selectedService: null
        });
    }

    @Action(ClearServicesState)
    ClearServicesState(ctx: StateContext<ServicesStateModel>) {
        return ctx.setState({
            allServices: [],
            selectedService: null,
            serviceConnectionStatuses: {},
            servicePolicies: {},
            portStatuses: {},
            resources: {}
        })
    }
}