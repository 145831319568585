import { Utils } from "src/app/utils";
import { ConnectionStatus } from "../../interfaces/connection-status.interface";

export class CheckConnectionStatus {
    static readonly type = Utils.Helpers.Type('[Connection: Check] Check connection status');
    constructor(public readonly payload: {msisdn: string, name: string}) { }
}

export class CheckConnectionStatusSuccess {
    static readonly type = Utils.Helpers.Type('[Connection: Check] Check connection status success');
    constructor(public readonly payload: {connect: ConnectionStatus, name: string}) { }
}

export class CheckConnectionStatusFail {
    static readonly type = Utils.Helpers.Type('[Connection: Check] Check connection status fail');
    constructor(public readonly payload: any) { }
}

export class AssignProductType {
    static readonly type = Utils.Helpers.Type('[Connection: Assign] Assign product type');
    constructor(public readonly payload: {name: string, type: string}) { }
}
