import { CancellationsRequest } from "@pages/service-page/plan-cancellation-page/cancellations.interface";
import { Utils } from "src/app/utils";

export class GetPreCancellationsDate {
    public static type = Utils.Helpers.Type('[Services: Cancellations] returns the day in which a cancellation will take place')
    constructor(public serviceId: string) { }
} 
export class PreCancelRainOneService {
    public static type = Utils.Helpers.Type('[Services: Cancellations] Schedules a rain one cancellation')
    constructor(public cancellationsPayload: CancellationsRequest) { }
} 
export class PreCancellationsServiceId {
    public static type = Utils.Helpers.Type('[Services] Sets the service ID to be precancelled')
    constructor(public serviceId: string) { }
} 

export class RevokePreCancellation {
    public static type = Utils.Helpers.Type('[Services] Revokes the cancellation on a service')
    constructor(public serviceId: string) { }
} 


export class ReversedPreCancellation {
    public static type = Utils.Helpers.Type('[Services] checks if the cancellation was revoked')
    constructor(public reversedCancellation: boolean) { }
} 

export class CanPreCancel {
    public static type = Utils.Helpers.Type('[Services : Cancellations] checks if a precancellation can take place')
    constructor(public canPreCancel: boolean) { }
} 
