import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ProductState } from 'src/app/store/state/product.state';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IServiceDetail } from '@models/serviceDetail';
import { AddonStatus, InitMigrationWithNvidia } from 'src/app/store/actions/services.actions';
import { ClearCart, SetOrderType, StartRainOnePurchase } from 'src/app/store/actions/cart.action';
import { Navigate } from '@ngxs/router-plugin';
import { CONFIG_FRIENDLY_NAME, CONFIG_COMMENTS } from '@models/constants';
import { takeUntil } from 'rxjs/operators';
import { CartState } from 'src/app/store/state/cart.state';
import { ServicesState } from 'src/app/store/state/services.state';
import { CartService } from '@services/cart.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'rain-nvidia-upgrade-modal',
  templateUrl: './nvidia-upgrade-modal.component.html',
  styleUrls: ['./nvidia-upgrade-modal.component.scss']
})
export class NvidiaUpgradeModalComponent implements OnInit, OnDestroy {
  @Input() service: IServiceDetail;
  @Input() productType: '4G' | '5G'
  @Output() continuePurchase: EventEmitter<boolean> = new EventEmitter();
  public userName = this.store.selectSnapshot(CoreState.getUserProfile)?.firstName;
  private ngDestroy$: Subject<any> = new Subject();
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private store: Store,
    private modalService: NgbModal,
    private cartSVC: CartService
  ) { }

  ngOnInit(): void {
  }

  public onContinueToBuy() {
    this.close(true);
    
    if(this.productType === '4G') {
      this.modalService.dismissAll();
      return this.store.dispatch(new Navigate(['/purchase/purchase-wizard']));
    } else {
      this.store.dispatch(new InitMigrationWithNvidia({type: this.productType, serviceId: this.service.id, productId: this.service.productId})).subscribe({
        next: (res) => this.store.dispatch(new Navigate(['/cart']))
      });
    }
    
  }

  getProductByID(productId: string) {
    return this.store.selectSnapshot(ProductState.GetProductById(productId));
  }

  public close(override = false) {
    this.continuePurchase.emit(override);
    if(!override) {
      const orderType = this.store.selectSnapshot(CartState.GetOrderType);
      if(this.productType === '4G' && orderType === 'nvidia') {
        this.activeModal.close();
        this.modalService.dismissAll();
        return this.store.dispatch([new ClearCart(), new Navigate(['/my-rain'])]);
      }
      this.store.dispatch([new SetOrderType('nvidia'), new Navigate(['/cart'])]);
    }
    this.activeModal.close();
    this.modalService.dismissAll();
  }

  onOpenSupport() {
    this.store.dispatch([new ClearCart()]);
    this.activeModal.close();
  }

  navigate(){
    this.router.navigateByUrl('/legal');
    this.activeModal.close();
  }
  
  gotoTerms(){     
    this.activeModal.close();
    this.store.dispatch([new ClearCart()]);
    this.router.navigateByUrl('/legal?section=terms');
  } 

  public addToCart() {
    this.store.dispatch(new AddonStatus(false));

    const hasBundleInCart = this.store.selectSnapshot(CartState.GetSelectedRainOneBundle);
    let rainOneL1Product
    if (hasBundleInCart) return this.store.dispatch(new Navigate(['/skin-selector']));
    const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);

    rainOneL1Product = isPostPaid
      ? this.store.selectSnapshot(ProductState.GetRain101PPBase)
      : this.store.selectSnapshot(ProductState.GetRain101AUBase);

    this.cartSVC
      .add(rainOneL1Product.id, {
        [CONFIG_FRIENDLY_NAME]: '5G SIM',
        [CONFIG_COMMENTS]: undefined
      })
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.store.dispatch(new StartRainOnePurchase(rainOneL1Product.id));
        this.store.dispatch(new Navigate(['/skin-selector']));
      });
    return;
  }

  public onPurchaseReject() {
    this.modalService.dismissAll();
    const orderType = this.store.selectSnapshot(CartState.GetOrderType);
    if(this.productType === '4G' && orderType === 'nvidia') {
      this.activeModal.close();
      this.modalService.dismissAll();
      return this.store.dispatch([new ClearCart(), new Navigate(['/my-rain'])]);
    }
    
    let actions = orderType === 'nvidia' ? [new Navigate(['/cart'])] : [new ClearCart(), new Navigate(['/my-rain'])]
    this.store.dispatch(actions);
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }
}
