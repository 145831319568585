import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadsheddingFallbackCopy } from 'src/app/core/interfaces/loadshedding-fallbacl-copy.interface';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';

@Component({
  selector: 'rain-fallback-modal',
  templateUrl: './fallback-modal.component.html',
  styleUrls: ['./fallback-modal.component.scss']
})
export class FallbackModalComponent implements OnInit {
  @Select(FirebaseConfigsState.getFallbackCopy) fallbackCopy$: Observable<LoadsheddingFallbackCopy>;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store
  ) { }

  ngOnInit(): void {
  }

  public tellMore() {

  }

  public close() {
    localStorage.setItem('loadshedding_fallback_modal_accepted', 'true');
    // this.store.dispatch(new ToggleAdBlockerModal(false));
    return this.activeModal.close();
  }

}
