import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardV2Component} from "./card-v2.component";
import {MatIconModule} from "@angular/material/icon";
import { ButtonV2Module } from '../button/button-v2.module';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";

@NgModule({
    declarations: [CardV2Component],
    exports: [
        CardV2Component
    ],
    providers: [],
    imports: [
        CommonModule,
        MatIconModule,
        ButtonV2Module,
        NgxSkeletonLoaderModule
    ]
})
export class CardV2Module {
}
