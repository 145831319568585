import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { ElementService } from '@services/element.service';
import { Observable } from 'rxjs';
import { ServicesState } from 'src/app/store/state/services.state';

@Component({
  selector: 'app-5g-coverage-success-list-products-modal',
  templateUrl: './5g-coverage-success-list-products-modal.component.html',
  styleUrls: ['./5g-coverage-success-list-products-modal.component.scss']
})
export class FiveGCoverageSuccessListProductsModalComponent implements OnInit {
  public isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
  public scrollToFiveG: () => void;
  @Output() accepted: EventEmitter<any> = new EventEmitter();
  @Select(ServicesState.hasPostPaid)
  hasPostPaid$: Observable<boolean>;

  @Select(ServicesState.getAllServices)
  services$: Observable<boolean>;

  constructor(
    public activeModal: NgbActiveModal,
    private elementService: ElementService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.scrollToFiveG = this.elementService.scrollToFiveG;
  }

  public onGotToShopPage() {
    this.accepted.next(true);
    this.activeModal.close();
    this.scrollToFiveG();
  }

}
