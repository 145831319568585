import { Utils } from "src/app/utils";
import { BottomSheetName } from "../interfaces/bottom-sheet.interfaces";

export class ShowBottomSheet {
    static readonly type = Utils.Helpers.Type('[BottomSheet: Open] Open bottom sheet');
    constructor(public openSheetName: BottomSheetName, public data?: any) {}
}

export class ChangeOpenBottomSheet {
    static readonly type = Utils.Helpers.Type('[BottomSheet: Change] Change bottom sheet');
    constructor(public openSheetName: BottomSheetName, public data?: any) {}
}

export class CloseBottomSheet {
    static readonly type = Utils.Helpers.Type('[BottomSheet: Close] Close bottom sheet');
    constructor(public readonly clearAssignSimState?: boolean) {}
}
