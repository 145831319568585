<div class="rain-101-sticky-nav">

    <div class="sticky-nav-grid">
        <div class="corner-left"></div>
        <ng-content select="[Title]"></ng-content>
        <div class="justify-item-right pl-2">
            <button class="btn d-block btn-md btn-primary-outline mb-3 mt-3 button2" (click)="requestSalesCallFrom()"><span class="copy">request a call</span></button>
        </div>
        <ng-content select="[ButtonBlue]"></ng-content>
        <div class="corner-right"></div>
    </div>

</div>