import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Observable, Subject, throwError } from 'rxjs';
@Component({
    template: ''
})
export class FormHelper implements OnDestroy {
    public _control: FormControl;
    protected ngDestroy$: Subject<any> = new Subject();

    constructor() {
    }

    public InitFormControl(component_form: FormControl) {
        this._control = component_form;
    }

    public getCntrol() {
        return this._control;
    }

    /**
     * @returns {ValidationErrors}
     * @memberof FormHelper
     */
    public getErrors(): ValidationErrors {
        return this._control?.errors;
    }

    /**
   * @returns {ValidationErrors}
   * @memberof FormHelper
   */
    public isDirty(): boolean {
        return this._control?.dirty;
    }


    /**
     * @returns {boolean}
     * @memberof FormHelper
     */
    public isValid(): boolean {
        return this._control?.valid;
    }


    /**
     * @memberof FormHelper
     */
    public disable() {
        this._control.disable({ emitEvent: true });
    }


    /**
     * @memberof FormHelper
     */
    public enable(control_name: string, group_name?: string) {
        this._control.enable({ emitEvent: true });
    }


    public checkForErrors() {
        if (this._control) {
            if (this.isDirty() || this._control.touched) {
                return this._control.errors;
            }
        }
    }

    protected ValidateSelect(placeholder: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control?.value && control.value.toLowerCase().includes(placeholder)) {
                return { notSelected: true };
            }
            return null;
        }
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }

}